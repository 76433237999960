import styled from '@emotion/styled';
import { Button } from 'components/atoms';
import { ArrowLeftIcon } from 'components/icons';
import { Image } from 'components/atoms';

// Main Wrapper
export const MakeItRainWrapper = styled.section`
  position: relative;
  height: 90vh;
`;

export const MakeItRainTitleBox = styled.div`
  text-align: center;
  font-size: 2rem;
  font-weight: 700;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 3rem 0 2rem 0;
`;
export const MakeItRainTitle = styled.p``;

export const Goback = styled(ArrowLeftIcon)`
  width: 2rem;
  height: 1.5rem;
  cursor: pointer;
  ${({ ishidden }) => ishidden === 'init' && `visibility: hidden;`}
`;
export const Balance = styled.p`
  background: -webkit-linear-gradient(#fdb913, #f2994a);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 17px;
  display: flex;
  align-items: center;
  svg {
    margin-right: 0.5rem;
  }
`;

export const MakeItRainContent = styled.div`
  padding: 0 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;
export const CommentBox = styled.div`
  border: 1px solid #fdb913;
  border-radius: 1rem;
  padding: 1rem;
`;
export const InitMakeItRainWrapper = styled.div`
  display: block;
  width: 100%;
  padding: 0 2rem;
`;
export const RainAmountWrapper = styled.div`
  margin: 5rem 0;
  p {
    text-align: center;
    font-weight: 700;
    font-size: 1.75rem;
  }
`;

export const ActionButtonWrapper = styled.div`
  position: absolute;
  bottom: 0rem;
  width: 100%;
  padding: 0 4rem;
`;
export const MakeItRainActionButton = styled(Button)`
  display: block;
  width: 100%;
  marginbottom: 1.5rem;
  background: linear-gradient(270.02deg, #fdb913 0.02%, #f2994a 99.98%);
`;
export const AmountToRain = styled.div`
  background-color: #3b3b40;
  border-radius: 5rem;
  padding: 0.5rem 1rem;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem auto;
  position: relative;
  ${({ position }) => `left: ${position}%;`}
  &::after {
    content: '';
    width: 15px;
    height: 15px;
    position: absolute;
    top: 80%;
    background-color: #3b3b40;
    transform: rotateZ(45deg);
  }
`;

export const TimeStamp = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
`;

// Not enough funds component
export const NotEnoughFundsWrapper = styled.section`
  display: block;
  margin-bottom: 13rem;
`;

export const NotEnoughFundMessage = styled.p`
  margin: 2rem 0;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
`;

export const PricingBox = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 1rem;
`;
export const PricingCardWrapper = styled.div`
  text-align: center;
`;
export const PricingCard = styled.div`
  ${({ active }) =>
    active
      ? `border:1px solid #fdb913;
background-color:#272729;
`
      : `
background-color: #3B3B40;
border: 1px solid transparent;
`}
  height: 13rem;
  overflow: hidden;
  border-radius: 1rem;
  position: relative;
  cursor: pointer;

  &:hover {
    border-color: #fdb913;
    background-color: #272729;
  }
`;

export const MoneyIconWrapper = styled.div`
  height: 8rem;
  padding: 1rem;
`;
export const FundsValue = styled(Balance)`
  justify-content: center;
  font-weight: 700;
  font-size: 14px;
`;
export const PricingAmount = styled.p`
  position: absolute;
  text-align: center;
  right: 0;
  left: 0;
  bottom: 0;
  background: linear-gradient(to right, #fdb913, #f2994a);
`;
export const SavedAmount = styled.p`
  font-size: 13px;
  font-weight: 400;
  font-style: normal;
  margin-top: 0.5rem;
`;

// Payment method

export const PaymentMethodWrapper = styled.div`
  display: block;
  width: 100%;
  margin-bottom: 10rem;
`;
export const SavedPaymentMethods = styled.div``;
export const PaymentMethodCategory = styled.p`
  margin: 1rem 0;
`;
export const PaymentMethods = styled.div``;

export const PaymentMethodItem = styled.div`
  border: 1px solid #fff;
  border-radius: 0.5rem;
  padding: 1rem;
  margin-bottom: 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
`;
export const PaymentMethodImage = styled.img`
  margin: 0 1rem 0 0;
`;

// Payment confirmation

export const PaymentConfirmationWrapper = styled.section`
  display: block;
  width: 100%;
  margin-bottom: 18rem;
`;
export const PaymentConfirmationTitle = styled.p`
  margin: 1rem 0;
  font-size: 2rem;
  font-weight: 700;
`;

export const PaymentSuccessfulWrapper = styled.section`
  display: block;
  width: 100%;
  text-align: center;
  margin-bottom: 8rem;
  p {
    width: 60%;
    margin: 1rem auto;
  }
`;

export const MakeComment = styled.textarea`
  resize: none;
  border: 1px solid transparent;
  background-color: transparent;
  outline: none;
  padding: 5px 0;
  color: white;
  width: 100%;
  font-weight: 700;
`;

export const Comment = styled.div`
  display: grid;
  grid-template-columns: 1fr 11fr;
`;
export const CommentContent = styled.div``;
export const Username = styled.p`
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
`;
export const Avatar = styled(Image)`
  width: 25px;
  display: block;
  object-fit: cover;
  height: 25px;
  margin-right: 1.25rem;
  border-radius: 50%;
`;
