import React, { cloneElement, useState, useEffect } from 'react';
import styled from '@emotion/styled/macro';
import { css, keyframes } from '@emotion/core';
import { useMeasure } from 'react-use';

const slide = width => keyframes`
0% { 
  right: 0; 
}
10% { 
  right: 0; 
}
90% {
 right: ${width}px; 
}
100% {
  right: ${width}px; 
 }
`;

const Wrapper = styled.div`
  align-items: flex-start;
  display: flex;
  overflow: hidden;
  position: relative;
  width: 100%;

  > * {
    white-space: nowrap;
  }

  ${({ animate, width }) =>
    animate &&
    css`
      > * {
        animation: ${slide(width)} ${width / 8}s linear 1s infinite alternate;
        position: relative;
      }
    `}
`;

export const Marquee = ({ children }) => {
  const [ref, { width }] = useMeasure();
  const [childRef, { width: childWidth }] = useMeasure();
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    setAnimate(childWidth >= width);
  }, [width, childWidth]);

  return (
    <Wrapper animate={animate} width={childWidth - width} ref={ref}>
      {cloneElement(children, { ref: childRef })}
    </Wrapper>
  );
};
