import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';
import styled from '@emotion/styled/macro';
import { Typography, Button } from 'components/atoms';

const Wrapper = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.black};
  display: flex;
  height: calc(100vh - 6rem);
  justify-content: center;
  width: 100%;
`;

const Content = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  max-width: 40rem;
`;

const Title = styled(Typography.Heading)`
  color: ${({ theme }) => theme.colors.switchingText};
  margin-bottom: 2rem;
  text-align: center;
`;

const Subtitle = styled(Typography.Paragraph)`
  color: ${({ theme }) => theme.colors.switchingText};
  margin-bottom: 2rem;
  text-align: center;
`;

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: null };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope(scope => {
      scope.setExtras(errorInfo);
      Sentry.captureException(error);
    });
  }

  reloadPage = () => {
    window.location.reload();
  };

  render() {
    const { hasError } = this.state;
    const { children } = this.props;
    if (hasError) {
      return (
        <Wrapper>
          <Content>
            <Title type="h2">Something went wrong</Title>
            <Subtitle>We're really sorry about this, please reload the page.</Subtitle>
            <Button effect onClick={this.reloadPage}>
              Reload
            </Button>
          </Content>
        </Wrapper>
      );
    }

    return children;
  }
}

export default ErrorBoundary;
