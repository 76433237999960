import React from 'react';
import PropTypes from 'prop-types';

function SvgInformation({ fill, ...props }) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 294 1529" {...props}>
      <g data-name="Group 3253" transform="translate(-20430.779 -5263.358)" fill={fill}>
        <rect data-name="Rectangle 617" width={294} height={961} rx={147} transform="translate(20430.779 5831.358)" />
        <rect data-name="Rectangle 618" width={265} height={264} rx={132} transform="translate(20430.779 5263.358)" />
      </g>
    </svg>
  );
}

SvgInformation.propTypes = {
  fill: PropTypes.string,
};

SvgInformation.defaultProps = {
  fill: '#fff',
};

export default SvgInformation;
