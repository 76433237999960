import React from 'react';
import PropTypes from 'prop-types';

function SvgRemoveFromPlaylist({ fill, ...props }) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 205.251 155.184" {...props}>
      <g data-name="Group 1850" fill={fill}>
        <path
          data-name="Add to Playlist _ +"
          d="M16.145 83.856l10.754-10.753-10.783-10.759a3.8 3.8 0 015.38-5.368l10.779 10.755L43.03 56.976a3.8 3.8 0 115.374 5.374L37.656 73.098l10.75 10.727a3.8 3.8 0 01-5.38 5.367l-10.744-10.72-10.761 10.76a3.8 3.8 0 11-5.374-5.373z"
        />
        <path
          data-name="Path 888"
          d="M131.149 129.287H12.17c-5.949 0-9.915 5.178-9.915 12.948s3.966 12.948 9.915 12.948h118.979c5.949 0 9.916-5.179 9.916-12.948s-3.965-12.948-9.916-12.948z"
        />
        <path
          data-name="Path 889"
          d="M195.334 64.645H76.355c-5.949 0-9.915 5.179-9.915 12.948s3.966 12.948 9.915 12.948h118.979c5.949 0 9.915-5.178 9.915-12.948s-3.965-12.948-9.915-12.948z"
        />
        <path
          data-name="Path 890"
          d="M131.149.001H12.17c-5.949 0-9.915 5.178-9.915 12.948s3.966 12.948 9.915 12.948h118.979c5.949 0 9.915-5.178 9.915-12.948S137.099.001 131.149.001z"
        />
      </g>
    </svg>
  );
}

SvgRemoveFromPlaylist.propTypes = {
  fill: PropTypes.string,
};

SvgRemoveFromPlaylist.defaultProps = {
  fill: '#fff',
};
export default SvgRemoveFromPlaylist;
