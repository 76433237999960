import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled/macro';
import { Typography, Button } from 'components/atoms';
import { PlayIcon } from 'components/icons';
import { MagicContext } from 'contexts/MagicContext';

const Wrapper = styled.div`
  align-items: center;
  background-blend-mode: multiply;
  background-image: url(${({ artwork }) => artwork});
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  height: 15rem;
  justify-content: center;
  padding: 4rem;
  position: relative;
  width: 15rem;

  &:before {
    background-color: ${({ hex }) => hex};
    bottom: 0;
    content: '';
    left: 0;
    opacity: 0.7;
    position: absolute;
    right: 0;
    top: 0;
  }

  ${({ theme }) => theme.mq.sm`
    height: 20rem;
    width: 20rem;
  `}

  ${({ theme }) => theme.mq.md`
    height: 28rem;
    width: 24rem;
  `}
`;

const Title = styled(Typography.Heading)`
  font-weight: bold;
  margin-bottom: 1rem;
  position: relative;
  text-align: center;

  &,
  &:visited {
    line-height: 1.3;
  }
`;

const ActionButton = styled(Button)`
  flex-shrink: 0;
  min-width: 4rem;
  padding: 0.75rem 1.5rem;
  position: relative;
`;

export const UtilityTile = ({ item }) => {
  const { loadMagic } = useContext(MagicContext);
  const { artwork, name, link, hex } = item;
  const magic = link === '/magic-playlist';

  return (
    <Wrapper hex={hex} artwork={artwork}>
      <Title type="h3">{name}</Title>
      {magic ? (
        <ActionButton effect fontSize="tiny" fontWeight="bold" onClick={() => loadMagic('me')}>
          <PlayIcon style={{ marginRight: '0.5rem' }} fill="inherit" /> Play Now
        </ActionButton>
      ) : (
        <ActionButton effect as={Link} to={link} fontSize="tiny" fontWeight="bold">
          Explore
        </ActionButton>
      )}
    </Wrapper>
  );
};

UtilityTile.propTypes = {
  item: PropTypes.shape({
    artwork: PropTypes.string.isRequired,
    hex: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
};
