import styled from '@emotion/styled/macro';
import { Body } from './Body';
import { Header, NewPlaylistHeader, PlaylistHeader, PersonalPlaylistHeader, TrackHeader } from './Header';
import { Item } from './Item';
import { ItemNoLike } from './Item';
import { ItemNoOption } from './Item';
import { PlaylistItem } from './Item';

const TrackList = styled.div`
  background-color: #0000;
  margin-bottom: 4rem;
  padding: 0rem;
  position: relative;
  ${({ theme }) => theme.mq.md`
  margin-top: 0rem;
  `}
`;

TrackList.Body = Body;
TrackList.Header = Header;
TrackList.Item = Item;
TrackList.ItemNoLike = ItemNoLike;
TrackList.ItemNoOption = ItemNoOption;
TrackList.PlaylistHeader = PlaylistHeader;
TrackList.NewPlaylistHeader = NewPlaylistHeader;
TrackList.PersonalPlaylistHeader = PersonalPlaylistHeader;
TrackList.TrackHeader = TrackHeader;
TrackList.PlaylistItem = PlaylistItem;
export { TrackList };
