import React from 'react';
import PropTypes from 'prop-types';

function SvgEllipse({ fill, ...props }) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 20 20" {...props}>
      <circle data-name="Ellipse 27" cx={10} cy={10} r={10} fill={fill} />
    </svg>
  );
}

SvgEllipse.propTypes = {
  fill: PropTypes.string,
};

SvgEllipse.defaultProps = {
  fill: '#fcb316',
};

export default SvgEllipse;
