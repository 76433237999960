import PageError from './PageError';
import PropTypes from 'prop-types';
import React from 'react';
import { Shimmer } from 'components/atoms/Shimmer';

export const Loader = ({ error }) => <div>{error ? <PageError error={error} /> : <Shimmer />}</div>;

Loader.propTypes = {
  color: PropTypes.string,
  children: PropTypes.node,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  fit: PropTypes.bool,
  size: PropTypes.number,
  text: PropTypes.string,
};

Loader.defaultProps = {
  color: 'switchingBg',
  children: null,
  error: false,
  fit: false,
  size: 75,
  text: '',
};
