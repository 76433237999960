import api from 'services/api';
import * as t from '../types';

export const getCommentsAction = (id, target, subtype) => async (dispatch, getState) => {
  try {
    const prevComments = getState().comment.data[id] || []; // eslint-disable-next-line
    const size =
      subtype === 'livestreams'
        ? 50
        : prevComments.length > 20
        ? prevComments.length > 50
          ? 50
          : prevComments.length
        : 20;
    dispatch({ type: t.GET_COMMENTS_REQUEST, payload: { id } });
    const resp = await api.getComments({
      size,
      target,
      targetID: id,
    });
    if (resp.status === 200) {
      dispatch({ type: t.GET_COMMENTS_SUCCESS, payload: { id, data: resp.data } });
    } else {
      throw resp.data;
    }
  } catch (e) {
    dispatch({ type: t.GET_COMMENTS_FAILURE, payload: { id } });
  }
};

export const loadMoreCommentsAction = (id, target, from) => async (dispatch, getState) => {
  try {
    dispatch({ type: t.LOAD_MORE_COMMENTS_REQUEST, payload: { id } });
    const resp = await api.getComments({
      from,
      size: 20,
      target,
      targetID: id,
    });
    if (resp.status === 200) {
      const prevComments = getState().comment.data[id];
      prevComments.pop();
      const comments = [...prevComments, ...resp.data];
      dispatch({ type: t.LOAD_MORE_COMMENTS_SUCCESS, payload: { id, data: comments } });
    } else {
      throw resp.data;
    }
  } catch (e) {
    dispatch({ type: t.LOAD_MORE_COMMENTS_FAILURE, payload: { id } });
  }
};

export const createCommentAction = (id, target, comment, callback = () => {}) => async (dispatch, getState) => {
  try {
    const targetComments = getState().comment.data[id] || [];
    dispatch({ type: t.CREATE_COMMENT_REQUEST, payload: { id } });
    const resp = await api.createComment({
      targetID: id,
      target,
      comment,
    });
    if (resp.status === 200) {
      dispatch({
        type: t.CREATE_COMMENT_SUCCESS,
        payload: {
          id,
          data: [{ ...resp.data, replies: [], replyCount: 0, likes_count: 0, dislikes_count: 0 }, ...targetComments],
        },
      });

      callback(true, null);
    } else {
      throw resp.data;
    }
  } catch (e) {
    dispatch({ type: t.CREATE_COMMENT_FAILURE, payload: { id } });
    callback(null, e);
  }
};

export const deleteCommentAction = (id, targetID, target) => async dispatch => {
  try {
    dispatch({ type: t.DELETE_COMMENT_REQUEST, payload: { id } });
    const resp = await api.deleteComment(id);
    if (resp.status === 200) {
      dispatch(getCommentsAction(targetID, target));
      dispatch({ type: t.DELETE_COMMENT_SUCCESS, payload: { id } });
    } else {
      throw resp.data;
    }
  } catch (e) {
    dispatch({ type: t.DELETE_COMMENT_FAILURE, payload: { id } });
  }
};

export const replyCommentAction = (id, parent, comment, callback = () => {}) => async dispatch => {
  try {
    dispatch({ type: t.REPLY_COMMENT_REQUEST, payload: { id } });
    const resp = await api.createComment({
      targetID: id,
      target: 'comments',
      comment,
    });
    if (resp.status === 200) {
      await dispatch(getCommentsAction(parent.id, parent.target));
      dispatch({ type: t.REPLY_COMMENT_SUCCESS, payload: { id } });
      callback(true, null);
    } else {
      throw resp.data;
    }
  } catch (e) {
    dispatch({ type: t.REPLY_COMMENT_FAILURE, payload: { id } });
    callback(null, e);
  }
};

export const likeCommentAction = (id, parent, rootComment) => async (dispatch, getState) => {
  try {
    const prevComments = getState().comment.data[parent.id];

    if (rootComment) {
      const index = prevComments.findIndex(x => x.id === rootComment);
      const comment = { ...prevComments[index] };
      const replyIndex = comment.replies.findIndex(x => x.id === id);
      const reply = { ...comment.replies[replyIndex] };
      if (reply.liked) {
        delete reply.liked;
        reply.likes_count = reply.likes_count > 0 ? reply.likes_count - 1 : 0;
      } else {
        if (reply.disliked) {
          delete reply.disliked;
          reply.dislikes_count = reply.dislikes_count > 0 ? reply.dislikes_count - 1 : 0;
        }
        reply.liked = {
          likedAt: Date.now(),
        };
        reply.likes_count = (reply.likes_count || 0) + 1;
      }
      const replies = [...comment.replies];
      replies[replyIndex] = reply;
      comment.replies = replies;
      comment.former = { ...prevComments[index] };
      prevComments[index] = comment;
    } else {
      const index = prevComments.findIndex(x => x.id === id);
      const comment = { ...prevComments[index] };
      if (comment.liked) {
        delete comment.liked;
        comment.likes_count = comment.likes_count > 0 ? comment.likes_count - 1 : 0;
      } else {
        if (comment.disliked) {
          delete comment.disliked;
          comment.dislikes_count = comment.dislikes_count > 0 ? comment.dislikes_count - 1 : 0;
        }
        delete comment.disliked;
        comment.liked = {
          likedAt: Date.now(),
        };
        comment.likes_count = (comment.likes_count || 0) + 1;
      }
      comment.former = { ...prevComments[index] };
      prevComments[index] = comment;
    }

    dispatch({ type: t.LIKE_COMMENT_REQUEST, payload: { id, parentId: parent.id, data: prevComments } });
    const resp = await api.connectComment({
      name: 'like_comment',
      target: 'comments',
      targetId: id,
    });

    if (resp.status === 200 || resp.status === 201) {
      const currentComments = getState().comment.data[parent.id];
      if (rootComment) {
        const index = currentComments.findIndex(x => x.id === rootComment);
        const comment = { ...currentComments[index] };
        const replyIndex = comment.replies.findIndex(x => x.id === resp.data.id);
        const replies = [...comment.replies];
        replies[replyIndex] = resp.data;
        comment.replies = replies;
        currentComments[index] = comment;
      } else {
        const index = currentComments.findIndex(x => x.id === resp.data.id);
        currentComments[index] = resp.data;
      }

      dispatch({ type: t.LIKE_COMMENT_SUCCESS, payload: { id, parentId: parent.id, data: currentComments } });
    } else {
      throw resp.data;
    }
  } catch (error) {
    const prevComments = getState().comment.data[parent.id];
    const index = prevComments.findIndex(x => x.id === (rootComment || id));
    const oldcomment = { ...prevComments[index] };
    const { former: comment } = oldcomment;
    prevComments[index] = comment;
    dispatch({ type: t.LIKE_COMMENT_FAILURE, payload: { id, parentId: parent.id, data: prevComments } });
  }
};

export const dislikeCommentAction = (id, parent, rootComment) => async (dispatch, getState) => {
  try {
    const prevComments = getState().comment.data[parent.id];
    if (rootComment) {
      const index = prevComments.findIndex(x => x.id === rootComment);
      const comment = { ...prevComments[index] };
      const replyIndex = comment.replies.findIndex(x => x.id === id);
      const reply = { ...comment.replies[replyIndex] };
      if (reply.disliked) {
        delete reply.disliked;
        reply.dislikes_count = reply.dislikes_count > 0 ? reply.dislikes_count - 1 : 0;
      } else {
        if (reply.liked) {
          delete reply.liked;
          reply.likes_count = reply.likes_count > 0 ? reply.likes_count - 1 : 0;
        }
        reply.disliked = {
          dislikedAt: Date.now(),
        };
        reply.dislikes_count = (reply.dislikes_count || 0) + 1;
      }
      const replies = [...comment.replies];
      replies[replyIndex] = reply;
      comment.replies = replies;
      comment.former = { ...prevComments[index] };
      prevComments[index] = comment;
    } else {
      const index = prevComments.findIndex(x => x.id === id);
      const comment = { ...prevComments[index] };
      if (comment.disliked) {
        delete comment.disliked;
        comment.dislikes_count = comment.dislikes_count > 0 ? comment.dislikes_count - 1 : 0;
      } else {
        if (comment.liked) {
          delete comment.liked;
          comment.likes_count = comment.likes_count > 0 ? comment.likes_count - 1 : 0;
        }
        delete comment.liked;
        comment.disliked = {
          dislikedAt: Date.now(),
        };
        comment.dislikes_count = (comment.dislikes_count || 0) + 1;
      }
      comment.former = { ...prevComments[index] };
      prevComments[index] = comment;
    }

    dispatch({ type: t.DISLIKE_COMMENT_REQUEST, payload: { id, parentId: parent.id, data: prevComments } });
    const resp = await api.connectComment({
      name: 'dislike_comment',
      target: 'comments',
      targetId: id,
    });
    if (resp.status === 200 || resp.status === 201) {
      const currentComments = getState().comment.data[parent.id];
      if (rootComment) {
        const index = currentComments.findIndex(x => x.id === rootComment);
        const comment = { ...currentComments[index] };
        const replyIndex = comment.replies.findIndex(x => x.id === resp.data.id);
        const replies = [...comment.replies];
        replies[replyIndex] = resp.data;
        comment.replies = replies;
        currentComments[index] = comment;
      } else {
        const index = currentComments.findIndex(x => x.id === resp.data.id);
        currentComments[index] = resp.data;
      }
      dispatch({ type: t.DISLIKE_COMMENT_SUCCESS, payload: { id, parentId: parent.id, data: currentComments } });
    } else {
      throw resp.data;
    }
  } catch (error) {
    const prevComments = getState().comment.data[parent.id];
    const index = prevComments.findIndex(x => x.id === (rootComment || id));
    const oldcomment = { ...prevComments[index] };
    const { former: comment } = oldcomment;
    prevComments[index] = comment;
    dispatch({ type: t.DISLIKE_COMMENT_FAILURE, payload: { id, parentId: parent.id, data: prevComments } });
  }
};
