import { useDispatch, useSelector } from 'react-redux';

import { Link } from 'react-router-dom';
import { Poster, Wrapper, PlayBox, Details } from './styled';
import PropTypes from 'prop-types';
import React from 'react';
import { addToPlaylistAction } from 'redux/actions';
import { firstLetterUc } from 'lib/utils';
import styled from '@emotion/styled/macro';
import { useToast } from 'hooks';
import { useMeasure } from 'react-use';

const HeaderText = styled.h3`
  font-family: BwModelicaSS02, sans-serif;
  font-weight: 500;
  font-size: 1.8rem;
  @media (max-width: ${props => props.theme.breakpoints[1]}) {
    font-size: 1.4rem;
  }
  @media (max-width: 375px) {
    font-size: 1.2rem;
  }
`;
const MainText = styled.p`
  font-weight: 300;
  font-size: 1rem;
  opacity: 0.6;
  @media (max-width: ${props => props.theme.breakpoints[1]}) {
    font-size: 1rem;
  }
  @media (max-width: 375px) {
    font-size: 0.9rem;
  }
`;

export const PersonalPlaylistTile = ({ detailStyle, item, listen, height, ...props }) => {
  const { artwork, name, id, description } = item;
  const [ref, { width }] = useMeasure();

  return (
    <Wrapper as={Link} to={`/playlist/${id}`} height={listen ? [`${width}px`] : height} {...props} ref={ref}>
      <PlayBox>
        <Poster src={artwork} placeholder="playlist" alt={name} />
      </PlayBox>
      <Details style={detailStyle}>
        <HeaderText>{name}</HeaderText>
        <MainText style={{ textOverflow: 'ellipsis' }}>{description}</MainText>
      </Details>
    </Wrapper>
  );
};

export const PlayerPersonalPlaylistTile = ({ detailStyle, item, listen, height, ...props }) => {
  const { currentItem } = useSelector(({ player }) => player);
  const dispatch = useDispatch();
  const { artwork, name, description } = item;
  const [ref, { width }] = useMeasure();

  const { addToast } = useToast();
  const type = 'track';
  const addToPlaylist = (playlist, cb) => dispatch(addToPlaylistAction(currentItem.id, type, playlist, cb));

  const onSuccess = () => {
    addToast(`The ${type} has been added to your playlist!`, { type: 'success' });
  };

  const onAddError = e => {
    addToast((e && firstLetterUc(e.error)) || 'An error occured', { type: 'error' });
  };

  const callback = (success, e) => {
    if (success) {
      onSuccess();
    } else {
      onAddError(e);
    }
  };

  const onSelect = data => {
    addToPlaylist(data.id, callback);
  };

  return (
    <Wrapper height={listen ? [`${width}px`] : height} {...props} ref={ref} onClick={() => onSelect(item)}>
      <PlayBox>
        <Poster src={artwork} placeholder="playlist" alt={name} />
      </PlayBox>
      <Details style={detailStyle}>
        <HeaderText>{name}</HeaderText>
        <MainText style={{ textOverflow: 'ellipsis' }}>{description}</MainText>
      </Details>
    </Wrapper>
  );
};

PersonalPlaylistTile.propTypes = {
  detailStyle: PropTypes.object,
  height: PropTypes.array,
  item: PropTypes.shape({
    artwork: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    owner: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  }).isRequired,
};

PlayerPersonalPlaylistTile.propTypes = {
  detailStyle: PropTypes.object,
  height: PropTypes.array,
  item: PropTypes.shape({
    artwork: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    owner: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  }).isRequired,
};

PersonalPlaylistTile.defaultProps = {
  detailStyle: {},
  height: [],
};

PlayerPersonalPlaylistTile.defaultProps = {
  detailStyle: {},
  height: [],
};
