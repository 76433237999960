import * as t from '../types';

const INITIAL_STATE = {
  adding: {},
  byArtist: {},
  byTag: {},
  byUser: {},
  creating: false,
  data: {},
  deleting: false,
  fetching: {},
  fetchingArtistPlaylists: false,
  fetchingTracks: {},
  ordering: {},
  playlistArtists: {},
  removing: {},
  tracks: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case t.ADD_TO_PLAYLIST_REQUEST:
      return { ...state, adding: { ...state.adding, [action.payload]: true } };

    case t.ADD_TO_PLAYLIST_SUCCESS:
      return { ...state, adding: { ...state.adding, [action.payload]: false } };

    case t.ADD_TO_PLAYLIST_FAILURE:
      return { ...state, adding: { ...state.adding, [action.payload]: false } };

    case t.DELETE_PLAYLIST_REQUEST:
      return { ...state, deleting: true };

    case t.DELETE_PLAYLIST_SUCCESS:
      return { ...state, deleting: false, byUser: { ...state.byUser, user: action.payload } };

    case t.DELETE_PLAYLIST_FAILURE:
      return { ...state, deleting: false };

    case t.EDIT_OR_CREATE_REQUEST:
      return { ...state, creating: true };

    case t.EDIT_OR_CREATE_SUCCESS:
      return { ...state, creating: false };

    case t.EDIT_OR_CREATE_FAILURE:
      return { ...state, creating: false };

    case t.GET_ARTIST_PLAYLISTS_REQUEST:
      return { ...state, fetchingArtistPlaylists: true };
    case t.GET_ARTIST_PLAYLISTS_SUCCESS:
      return {
        ...state,
        byArtist: { ...state.byArtist, [action.payload.id]: action.payload.data },
        fetchingArtistPlaylists: false,
      };
    case t.GET_ARTIST_PLAYLISTS_FAILURE:
      return { ...state, fetchingArtistPlaylists: false };

    case t.GET_PLAYLIST_REQUEST:
      return { ...state, fetching: { ...state.fetching, [action.payload]: true } };

    case t.GET_PLAYLIST_SUCCESS:
      return {
        ...state,
        data: { ...state.data, [action.payload.id]: action.payload.data },
        fetching: { ...state.fetching, [action.payload.id]: false },
      };

    case t.GET_PLAYLIST_FAILURE:
      return { ...state, fetching: { ...state.fetching, [action.payload]: false } };

    case t.GET_PLAYLISTS_REQUEST:
      return { ...state, fetching: { ...state.fetching, all: true } };

    case t.GET_PLAYLISTS_SUCCESS:
      return {
        ...state,
        byTag: { ...state.byTag, all: action.payload },
        fetching: { ...state.fetching, all: false },
      };

    case t.GET_PLAYLISTS_FAILURE:
      return { ...state, fetching: { ...state.fetching, all: false } };

    case t.GET_PLAYLIST_TRACKS_REQUEST:
      return { ...state, fetchingTracks: { ...state.fetchingTracks, [action.payload]: true } };

    case t.GET_PLAYLIST_TRACKS_SUCCESS:
      return {
        ...state,
        fetchingTracks: { ...state.fetchingTracks, [action.payload.id]: false },
        tracks: { ...state.tracks, [action.payload.id]: action.payload.data },
      };

    case t.GET_PLAYLIST_TRACKS_FAILURE:
      return { ...state, fetchingTracks: { ...state.fetchingTracks, [action.payload]: false } };
    case t.GET_PLAYLIST_ARTIST_REQUEST:
      return { ...state, fetching: { ...state.fetching, fetching: true } };
    case t.GET_PLAYLIST_ARTIST_SUCCESS:
      return {
        ...state,
        fetching: { ...state.fetching, fetching: false },
        playlistArtists: { ...state.playlistArtists, [action.payload.id]: action.payload.data },
      };
    case t.GET_PLAYLIST_ARTIST_FAILURE:
      return { ...state, fetching: { ...state.fetching, fetching: false } };

    case t.GET_USER_PLAYLISTS_REQUEST:
      return { ...state, fetching: { ...state.fetching, byUser: true } };

    case t.GET_USER_PLAYLISTS_SUCCESS:
      return { ...state, fetching: { ...state.fetching, byUser: false }, byUser: action.payload };

    case t.GET_USER_PLAYLISTS_FAILURE:
      return { ...state, fetching: { ...state.fetching, byUser: false } };

    case t.ORDER_PLAYLIST_TRACKS_REQUEST:
      return { ...state, ordering: { ...state.ordering, [action.payload]: true } };

    case t.ORDER_PLAYLIST_TRACKS_SUCCESS:
      return {
        ...state,
        ordering: { ...state.ordering, [action.payload.id]: false },
        tracks: { ...state.tracks, [action.payload.id]: action.payload.tracks },
      };

    case t.ORDER_PLAYLIST_TRACKS_FAILURE:
      return { ...state, ordering: { ...state.ordering, [action.payload]: false } };

    case t.REMOVE_PLAYLIST_TRACKS_REQUEST:
      return { ...state, removing: { ...state.removing, [action.payload]: true } };

    case t.REMOVE_PLAYLIST_TRACKS_SUCCESS:
      return {
        ...state,
        removing: { ...state.removing, [action.payload.id]: false },
        tracks: { ...state.tracks, [action.payload.id]: action.payload.tracks },
      };

    case t.REMOVE_PLAYLIST_TRACKS_FAILURE:
      return { ...state, removing: { ...state.removing, [action.payload]: false } };

    case t.LOGOUT:
      return INITIAL_STATE;

    default:
      return { ...state };
  }
};
