import { Dnd } from './Dnd';
import { Metadata } from './Metadata';
import { Progress } from './Progress';
import { ReleaseType } from './ReleaseType';
import { Share } from './Share';
import { Timeline } from './Timeline';
import { ActionButtons } from './ActionButtons';
import { Order } from './Order';

export const Upload = {
  ActionButtons,
  Dnd,
  Metadata,
  Progress,
  ReleaseType,
  Share,
  Timeline,
  Order,
};
