export const GET_SCREEN_REQUEST = 'GET_SCREEN_REQUEST';
export const GET_SCREEN_SUCCESS = 'GET_SCREEN_SUCCESS';
export const GET_SCREEN_FAILURE = 'GET_SCREEN_FAILURE';

export const GET_SCREEN_SECTION_REQUEST = 'GET_SCREEN_SECTION_REQUEST';
export const GET_SCREEN_SECTION_SUCCESS = 'GET_SCREEN_SECTION_SUCCESS';
export const GET_SCREEN_SECTION_FAILURE = 'GET_SCREEN_SECTION_FAILURE';

export const GET_SCREEN_SECTION_BY_ID_REQUEST = 'GET_SCREEN_SECTION_BY_ID_REQUEST';
export const GET_SCREEN_SECTION_BY_ID_SUCCESS = 'GET_SCREEN_SECTION_BY_ID_SUCCESS';
export const GET_SCREEN_SECTION_BY_ID_FAILURE = 'GET_SCREEN_SECTION_BY_ID_FAILURE';

export const GET_ARTIST_SCREEN_REQUEST = 'GET_ARTIST_SCREEN_REQUEST';
export const GET_ARTIST_SCREEN_SUCCESS = 'GET_ARTIST_SCREEN_SUCCESS';
export const GET_ARTIST_SCREEN_FAILURE = 'GET_ARTIST_SCREEN_FAILURE';
