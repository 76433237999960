export const GET_MOOD_REQUEST = 'GET_MOOD_REQUEST';
export const GET_MOOD_SUCCESS = 'GET_MOOD_SUCCESS';
export const GET_MOOD_FAILURE = 'GET_MOOD_FAILURE';

export const GET_MOODS_REQUEST = 'GET_MOODS_REQUEST';
export const GET_MOODS_SUCCESS = 'GET_MOODS_SUCCESS';
export const GET_MOODS_FAILURE = 'GET_MOODS_FAILURE';

export const GET_MOOD_TRACKS_REQUEST = 'GET_MOOD_TRACKS_REQUEST';
export const GET_MOOD_TRACKS_SUCCESS = 'GET_MOOD_TRACKS_SUCCESS';
export const GET_MOOD_TRACKS_FAILURE = 'GET_MOOD_TRACKS_FAILURE';
