import * as t from '../types';

const INITIAL_STATE = {
  feeback: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case t.CREATE_FEEDBACK_SUCCESS:
      return { ...state, feeback: action.payload };
    case t.LOGOUT:
      return INITIAL_STATE;
    default:
      return { ...state };
  }
};
