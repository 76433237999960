import React from 'react';
import PropTypes from 'prop-types';

function SvgFacebook({ fill, ...props }) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 31.227 31.228" {...props}>
      <g data-name="Group 53">
        <g data-name="Group 52">
          <path
            d="M25.372 0H5.855A5.855 5.855 0 000 5.855v19.517a5.855 5.855 0 005.855 5.855h19.517a5.855 5.855 0 005.855-5.855V5.855A5.855 5.855 0 0025.372 0zM19.65 15.612h-2.559v9.368h-3.513v-9.366h-2.342v-3.228h2.342v-1.9c0-2.635.714-4.237 3.816-4.237h2.584v3.23h-1.62c-1.208 0-1.267.451-1.267 1.292v1.616h2.9z"
            data-name="Path 1030"
            fill={fill}
          />
        </g>
      </g>
    </svg>
  );
}

SvgFacebook.propTypes = {
  fill: PropTypes.string,
};

SvgFacebook.defaultProps = {
  fill: '#fff',
};

export default SvgFacebook;
