import { ExplicitIcon, LikeIcon } from 'components/icons';
import { Image, Typography } from 'components/atoms';
import React from 'react';
import { formatTime, isObjEmpty } from 'lib/utils';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from 'rebass';
// import { FullPlayerContext } from 'contexts/FullPlayerContext';
import { Link } from 'react-router-dom';
import { MoreOptions } from 'components/organisms/MoreOptions';
import PropTypes from 'prop-types';
import { connectAction, playTracksAction } from 'redux/actions';
import { css } from '@emotion/core';
import styled from '@emotion/styled/macro';
import { Marquee } from '../Marquee';

const Title = styled(Typography.Text)`
  color: ${({ theme }) => theme.colors.switchingText};
  font-size: ${({ theme }) => theme.font.size.tiny};
  transition: all 0.1s;
  opacity: 0.8;
  text-decoration: none !important;
  display: flex;
  align-items: center;
  font-weight: 500;
  svg {
    margin-left: 1rem;
  }
`;

const Artist = styled(Typography.Text)`
  color: ${({ theme }) => theme.colors['brown-grey']} !important;
  font-size: ${({ theme }) => theme.font.size.tiny};
  opacity: 0.8;
  display: flex;
  align-items: center;
  margin-top: 0.3rem;
  &:hover {
    text-decoration: underline;
  }
`;

const Artwork = styled(Image)`
  flex-shrink: 0;
  height: 4.8rem;
  width: 4.8rem;
  border-radius: 4.5px;
`;
const Number = styled(Typography.Text)`
  color: ${({ theme }) => theme.colors['brown-grey']};
  display: inline-block;
  width: 1.6rem;
`;

const Wrapper = styled(Box)`
  height: 5.5rem;
  align-items: center;
  cursor: pointer;
  border-radius: 4px;
  transition: all 0.1s;
  position: relative;
  margin-left: 1rem;

  &:after {
    background-color: rgba(255, 255, 255, 0.06);
    content: '';
    height: 1px;
    position: absolute;
    top: 100%;
    width: 96.5%;
  }

  &:hover {
    background-color: #272729;

    ${Title} {
      color: ${({ theme }) => theme.colors.primary};
    }
    ${Number} {
      color: white;
    }
  }

  ${({ active, theme }) =>
    active &&
    css`
      background-color: ${theme.colors.switchingBg}33;

      ${Title} {
        color: ${theme.colors.primary};
      }
    `}
`;

// const ExplicitIcon = styled.img`
//   width: 12px;
//   height: 12px;
//   margin-right: 0.5rem;
//   @media (max-width: 560px) {
//     width: 12px;
//     height: 12px;
//   }
// `;

export const TrackTile = ({ index, isOwner, item, tracks }) => {
  const dispatch = useDispatch();
  const currentItem = useSelector(state => state.player.currentItem);
  // const [player, setPlayer] = useContext(FullPlayerContext);
  const connect = () => dispatch(connectAction(item, 'track'));
  const { id, artist_name, artwork, duration, name, artist, parental_warning } = item;
  const playTrack = items => dispatch(playTracksAction(items, index, 'album', ''));
  const toPlay = !tracks.length ? [item] : tracks;
  const isCurrent = !isObjEmpty(currentItem) && id === currentItem?.id;

  const TrackPlay = () => {
    playTrack(toPlay);
    // if (window.innerWidth > 767) {
    //   setPlayer(true);
    // } else {
    //   setPlayer(false);
    // }
  };

  const isConnected = useSelector(
    state =>
      (state.connect.byTargetId.tracks &&
        state.connect.byTargetId.tracks[id] &&
        state.connect.byTargetId.tracks[id].active) ||
      false,
  );

  // const connecting = useSelector(state => state.connect.fetching[id] || false);

  return (
    <Wrapper onClick={() => TrackPlay()} active={isCurrent}>
      <Box mr={['1rem', '1.5rem']} flexShrink="0">
        <Artwork src={artwork} placeholder="track" spinnerSize="1rem" alt={name} />
      </Box>

      <Box flexDirection="column" mr="0.5rem">
        <Marquee>
          <Title>
            {name}
            {parental_warning === 'Explicit' && <ExplicitIcon />}
          </Title>
        </Marquee>

        <Marquee>
          <Artist as={Link} to={`/artists/${artist}`}>
            {artist_name} - {formatTime(duration)}
          </Artist>
        </Marquee>
      </Box>

      <Box ml="auto" flexShrink="0">
        <Box>
          <LikeIcon
            onClick={e => {
              e.stopPropagation();
              connect();
            }}
            fill={isConnected === true ? 'url(#paint0_linear)' : 'transparent'}
            style={{ cursor: 'pointer' }}
          />
        </Box>
        <Box flexShrink="0">
          <MoreOptions isOwner={isOwner} type="track" item={item} />
        </Box>
      </Box>
    </Wrapper>
  );
};

TrackTile.propTypes = {
  index: PropTypes.number,
  item: PropTypes.shape({
    album: PropTypes.string.isRequired,
    artist_name: PropTypes.string.isRequired,
    artist: PropTypes.string.isRequired,
    artwork: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    owner: PropTypes.string,
    owner_name: PropTypes.string,
    duration: PropTypes.number,
    parental_warning: PropTypes.string,
  }).isRequired,
  tracks: PropTypes.shape([]),
};

TrackTile.defaultProps = {
  index: 0,
  tracks: [],
};
