import React from 'react';
import PropTypes from 'prop-types';

function SvgShuffle({ fill, ...props }) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 23.247 18.252" {...props}>
      <path
        d="M9.972 15.012a.864.864 0 10-1.1-1.331 4.257 4.257 0 01-2.725.983H.928a.868.868 0 100 1.735h5.217a5.881 5.881 0 003.826-1.388m13.217-12.3a1.07 1.07 0 00-.046-.292.912.912 0 00-.213-.338L21.1.26a.842.842 0 00-1.217 0 .837.837 0 000 1.215v.347h-3.3a6.152 6.152 0 00-3.827 1.331.9.9 0 00-.116 1.215.908.908 0 00.7.347.709.709 0 00.522-.174 4.261 4.261 0 012.725-.983h3.3v.463a.837.837 0 000 1.215.87.87 0 00.638.232.887.887 0 00.58-.232l1.855-1.851a1.209 1.209 0 00.232-.636v-.036m-.046-.292a1.07 1.07 0 01.046.292.2.2 0 000-.022.81.81 0 00-.047-.269m-.185-.309l-.028-.029a.912.912 0 01.213.338.749.749 0 00-.185-.309m.29 13.421a.869.869 0 00-.278-.623.877.877 0 01.22.623 1.2 1.2 0 01-.229.63.869.869 0 00.286-.63m-.058 0a1.2 1.2 0 01-.229.63v.006L21.1 18.02a.748.748 0 01-.58.232.873.873 0 01-.638-.232.837.837 0 010-1.215v-.4h-3.244a6.142 6.142 0 01-6.145-6.133V7.954a4.4 4.4 0 00-4.406-4.4H.87A.89.89 0 010 2.69a.891.891 0 01.87-.868h5.217a6.142 6.142 0 016.145 6.133v2.314a4.4 4.4 0 004.406 4.4h3.247v-.409a.86.86 0 111.215-1.215l1.858 1.855.012.014a.877.877 0 01.22.623"
        fill={fill}
        fillRule="evenodd"
      />
    </svg>
  );
}

SvgShuffle.propTypes = {
  fill: PropTypes.string,
};

SvgShuffle.defaultProps = {
  fill: '#fefefe',
};

export default SvgShuffle;
