import React from 'react';
import PropTypes from 'prop-types';

function SvgAppStore({ fill, ...props }) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 31.994 28.337" {...props}>
      <defs>
        <style>{`.app-store_svg__cls-1{fill:${fill}}`}</style>
      </defs>
      <g id="app-store_svg__app-store">
        <g id="app-store_svg__Group_19" data-name="Group 19">
          <g id="app-store_svg__Group_18" data-name="Group 18">
            <path
              id="app-store_svg__Path_9"
              d="M106.155 106.745a2.346 2.346 0 00-4.3-.566l-.361.625-.361-.625a2.348 2.348 0 00-3.2-.859 2.348 2.348 0 00-.858 3.2l1.715 2.971-6.33 10.966h-4.614a2.346 2.346 0 000 4.691h17.813l-2.709-4.691h-5.073l8.044-13.933a2.33 2.33 0 00.234-1.779z"
              className="app-store_svg__cls-1"
              data-name="Path 9"
              transform="translate(-85.5 -105.006)"
            />
          </g>
        </g>
        <g id="app-store_svg__Group_21" data-name="Group 21" transform="translate(17.08 8.365)">
          <g id="app-store_svg__Group_20" data-name="Group 20">
            <path
              id="app-store_svg__Path_10"
              d="M280.116 203.251H275.5l-5.245-9.085-2.708 4.691 8.144 14.106a2.33 2.33 0 001.424 1.093 2.359 2.359 0 00.611.081 2.347 2.347 0 002.028-3.519l-1.545-2.675h1.906a2.346 2.346 0 000-4.691z"
              className="app-store_svg__cls-1"
              data-name="Path 10"
              transform="translate(-267.548 -194.166)"
            />
          </g>
        </g>
        <g id="app-store_svg__Group_23" data-name="Group 23" transform="translate(2.393 24.018)">
          <g id="app-store_svg__Group_22" data-name="Group 22">
            <path
              id="app-store_svg__Path_11"
              d="M111.778 361l-.461.8a2.346 2.346 0 004.063 2.345l1.815-3.145z"
              className="app-store_svg__cls-1"
              data-name="Path 11"
              transform="translate(-111.003 -361)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

SvgAppStore.propTypes = {
  fill: PropTypes.string,
};

SvgAppStore.defaultProps = {
  fill: '#fff',
};

export default SvgAppStore;
