import React, { useState } from 'react';
import styled from '@emotion/styled/macro';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import { Typography } from 'components/atoms';
import { Modal } from 'components/molecules';
import { Box } from 'rebass';
import { formatTime } from 'lib/utils';
import { v4 as uuidv4 } from 'uuid';

export const TrackInfoContext = React.createContext({});

const Info = styled(Box)`
  ${Typography.Text} {
    line-height: 1.2;
    &:first-child {
      margin-right: 2rem;
    }
  }

  span {
    color: ${({ theme }) => theme.colors.switchingText};
  }

  &:not(:last-child) {
    margin-bottom: 3rem;
  }
`;

const TrackInfoModal = ({ isOpen, toggle, track }) => {
  const renderInfo = () => {
    const {
      album_name = '-',
      artist_name = '-',
      composer = '-',
      duration = 0,
      genre_name = '-',
      name = '-',
      releasedate = 0,
      songwriter = '-',
    } = track;
    const item = {
      Title: name,
      Length: duration ? formatTime(duration) : '-',
      Artist: artist_name,
      Album: album_name,
      'Release date': releasedate ? format(releasedate, 'yyyy') : '-',
      Genre: genre_name,
      'Songwriter(s)': songwriter,
      Composer: composer,
    };

    return Object.entries(item).map(([key, value]) => (
      <Info alignItems="center" justifyContent="space-between" key={key}>
        <Typography.Text>{key}</Typography.Text>
        <Typography.Text size="big">{value}</Typography.Text>
      </Info>
    ));
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="md" showCloseIcon>
      <Modal.Header justifyContent="center">
        <Typography.Paragraph>Track Info</Typography.Paragraph>
      </Modal.Header>
      <Modal.Body>{renderInfo()}</Modal.Body>
    </Modal>
  );
};

export const TrackInfoProvider = ({ children }) => {
  const [modal, setModal] = useState(false);
  const [track, setTrack] = useState({});

  const toggleTrackInfo = item => {
    if (modal) {
      setTrack({});
      setModal(false);
    } else {
      setTrack(item);
      setModal(true);
    }
  };

  return (
    <TrackInfoContext.Provider
      value={{
        modal,
        setModal,
        toggleTrackInfo,
      }}
    >
      {children}
      <TrackInfoModal key={uuidv4()} isOpen={modal} toggle={toggleTrackInfo} track={track} />
    </TrackInfoContext.Provider>
  );
};

TrackInfoProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

TrackInfoModal.propTypes = {
  track: PropTypes.shape({
    album_name: PropTypes.string,
    artist_name: PropTypes.string,
    composer: PropTypes.string,
    duration: PropTypes.number,
    genre_name: PropTypes.string,
    name: PropTypes.string,
    releasedate: PropTypes.number,
    songwriter: PropTypes.string,
  }).isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
};
