import PropTypes from 'prop-types';
import React from 'react';

function SvgRepeatOne({ fill, size, ...props }) {
  return (
    <svg data-name="Group 1833" width={size} viewBox="0 0 70.043 64.234" {...props}>
      <path
        data-name="Path 1123"
        d="M46.613 60.152l-3.145 3.145a3.14 3.14 0 01-4.476 0 3.139 3.139 0 010-4.476l1.855-1.855.04-2.258H25.322A25.348 25.348 0 010 29.347a23.655 23.655 0 016.935-16.693 3.139 3.139 0 014.476 0 3.14 3.14 0 010 4.476A17.214 17.214 0 006.33 29.307a19.015 19.015 0 0019.033 19.034h15.524l-.04-2.258-1.855-1.855a3.139 3.139 0 010-4.476 3.124 3.124 0 013.79-.524 18.841 18.841 0 00-1.653 7.7 18.58 18.58 0 005.484 13.224z"
        fill={fill}
      />
      <path
        data-name="Path 1124"
        d="M70.043 34.925a22.787 22.787 0 01-1.089 7.016 19.234 19.234 0 00-5.363-5.161 14.271 14.271 0 00.081-1.855A19.015 19.015 0 0044.64 15.894h-15.2v2.5l1.573 1.573a3.14 3.14 0 010 4.476 3.14 3.14 0 01-4.476 0l-9.516-9.516c-.04-.04-1.452-1.169-1.452-2.258s1.411-2.177 1.452-2.218L26.537.935a3.14 3.14 0 014.476 0 3.14 3.14 0 010 4.476L29.44 6.984v2.54h15.242a25.417 25.417 0 0125.361 25.401z"
        fill={fill}
      />
      <path
        data-name="Path 1125"
        d="M53.671 30.556A16.382 16.382 0 0037.3 46.926a16.383 16.383 0 0016.371 16.37 16.383 16.383 0 0016.371-16.37 16.382 16.382 0 00-16.371-16.37zm3.911 25.6h-4.556V41.523H49.72v-3.75h7.863z"
        fill={fill}
      />
    </svg>
  );
}

SvgRepeatOne.propTypes = {
  fill: PropTypes.string,
  size: PropTypes.string,
};

SvgRepeatOne.defaultProps = {
  fill: '#fefefe',
  size: '1em',
};

export default SvgRepeatOne;
