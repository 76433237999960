import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled/macro';
import { Image, Button } from 'components/atoms';
import { Modal } from 'components/molecules';
import { banners } from 'lib/images';
import { Box } from 'rebass';
import { useHistory } from 'react-router-dom';

const Banner = styled(Image)`
  height: auto;
  width: 100%;
`;

const PromoBannerModal = ({ isOpen, toggle }) => {
  const { push } = useHistory();

  const onClick = () => {
    toggle();
    push('/livestreams');
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg" showCloseIcon>
      <Modal.Body py={0} px={0}>
        <Banner src={banners.promo} />

        <Box mt="1rem" mb="2rem" display="flex" justifyContent="center" onClick={onClick}>
          <Button effect fontSize="small">
            Watch Now
          </Button>
        </Box>
      </Modal.Body>
    </Modal>
  );
};

PromoBannerModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
};

export default PromoBannerModal;
