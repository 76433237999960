import React from 'react';
import PropTypes from 'prop-types';

function SvgTick() {
  return (
    <svg width="111" height="111" viewBox="0 0 111 111" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M55.5 111C86.1518 111 111 86.1518 111 55.5C111 24.8482 86.1518 0 55.5 0C24.8482 0 0 24.8482 0 55.5C0 86.1518 24.8482 111 55.5 111ZM84.9747 40.6598C85.9742 39.7073 86.0123 38.1249 85.0598 37.1253C84.1073 36.1258 82.5249 36.0877 81.5253 37.0402L48.3056 68.6966L29.4747 50.7519C28.4751 49.7994 26.8927 49.8375 25.9402 50.8371C24.9877 51.8366 25.0258 53.4191 26.0253 54.3716L46.5809 73.9598C47.5465 74.8801 49.0646 74.8801 50.0302 73.9598L84.9747 40.6598Z"
        fill="url(#paint0_radial)"
      />
      <defs>
        <radialGradient
          id="paint0_radial"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(86.4726 -78.625) rotate(109.468) scale(305.119 279.059)"
        >
          <stop offset="0.1875" stopColor="#4CAF50" />
          <stop offset="1" stopColor="#8EFC2D" />
        </radialGradient>
      </defs>
    </svg>
  );
}

SvgTick.propTypes = {
  fill: PropTypes.string,
};

SvgTick.defaultProps = {
  fill: '#fff',
};

export default SvgTick;
