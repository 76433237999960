import { ArtistPoster, Title } from './styled';

import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';
import { formatName } from 'lib/utils';
import styled from '@emotion/styled/macro';

const ArtistName = styled(Title)`
  text-align: center;
  margin: 1rem 0 !important;
  font-weight: 400;
  font-size: ${({ theme }) => theme.font.size.tiniest};
  &:hover {
    text-decoration: underline;
  }
  @media (min-width: ${props => props.theme.breakpoints[1]}) {
    font-size: ${({ theme }) => theme.font.size.tiny};
  }
`;

const AvatarContainer = styled.div`
  width: 25rem;
  height: 25rem;
  background: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  &:hover {
    opacity: 0.7;
    img {
      transform: scale(1.12);
      transition: transform 1s;
    }
  }
  @media (max-width: ${props => props.theme.breakpoints[1]}) {
    width: 15rem;
    height: 15rem;
  }
`;
const Image = styled(ArtistPoster)`
  border-radius: 50%;
  background-size: cover;
  object-fit: cover;
`;

export const ArtistTile = ({ item }) => {
  const { id } = item;
  const name = item.name || formatName(item);
  const artwork = item.artwork || item.avatar;

  return (
    <AvatarContainer as={Link} to={`/artists/${id}`}>
      <Image src={artwork} placeholder="artist" alt={name} useThumbnail={false}></Image>
      <ArtistName as={Link} to={`/artists/${id}`}>
        {name}
      </ArtistName>
    </AvatarContainer>
  );
};

ArtistTile.propTypes = {
  height: PropTypes.array,
  item: PropTypes.shape({
    artwork: PropTypes.string.isRequired,
    avatar: PropTypes.string,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  listen: PropTypes.bool.isRequired,
  type: PropTypes.string,
};

ArtistTile.defaultProps = {
  height: ['10rem', '13rem', '16rem'],
  type: 'artists',
};
