import * as t from '../types';

const INITIAL_STATE = {
  byTag: {},
  data: {},
  fetching: {},
  fetchingTracks: {},
  tracks: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case t.GET_MOOD_REQUEST:
      return { ...state, fetching: { ...state.fetching, [action.payload]: true } };

    case t.GET_MOOD_SUCCESS:
      return {
        ...state,
        data: { ...state.data, [action.payload.id]: action.payload.data },
        fetching: { ...state.fetching, [action.payload.id]: false },
      };

    case t.GET_MOOD_FAILURE:
      return { ...state, fetching: { ...state.fetching, [action.payload]: false } };

    case t.GET_MOODS_REQUEST:
      return { ...state, fetching: { ...state.fetching, all: true } };

    case t.GET_MOODS_SUCCESS:
      return {
        ...state,
        byTag: { ...state.byTag, all: action.payload },
        fetching: { ...state.fetching, all: false },
      };

    case t.GET_MOODS_FAILURE:
      return { ...state, fetching: { ...state.fetching, all: false } };

    case t.GET_MOOD_TRACKS_REQUEST:
      return { ...state, fetchingTracks: { ...state.fetchingTracks, [action.payload]: true } };

    case t.GET_MOOD_TRACKS_SUCCESS:
      return {
        ...state,
        fetchingTracks: { ...state.fetchingTracks, [action.payload.id]: false },
        tracks: { ...state.tracks, [action.payload.id]: action.payload.data },
      };

    case t.GET_MOOD_TRACKS_FAILURE:
      return { ...state, fetchingTracks: { ...state.fetchingTracks, [action.payload]: false } };

    case t.LOGOUT:
      return INITIAL_STATE;
    default:
      return { ...state };
  }
};
