import React, { memo } from 'react';
import { Button, OutlineButton, Typography } from 'components/atoms';
import { Modal } from 'components/molecules';
import styled from '@emotion/styled/macro';
import PropTypes from 'prop-types';
import { Box } from 'rebass';
import { logoutAction } from 'redux/actions';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

const LogoutButton = styled(Button)`
  color: white !important;
  background: linear-gradient(270.02deg, #fdb913 0.02%, #f2994a 99.98%);
  margin: 0.5rem;
  width: 100%;
`;
const CancelButton = styled(OutlineButton)`
  color: white !important;
  border: 2px solid #272729;
  border-radius: 175.5px;
  font-weight: 500;
  margin: 0.5rem;
  width: 100%;
`;

const LogoutModal = memo(({ close, isOpen, toggle }) => {
  const dispatch = useDispatch();
  const logoutUser = cb => dispatch(logoutAction(cb));
  const hist = useHistory();
  const logout = () => {
    logoutUser(() => {
      window.location.reload();
    });
    hist.replace('/explore');
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <Modal.Header>
        <Typography.Heading type="h5">Do you want to log out?</Typography.Heading>
      </Modal.Header>
      <Modal.Body>
        <Box display="flex">
          <LogoutButton
            size="small"
            effect
            onClick={() => {
              logout();
              close();
            }}
          >
            Logout
          </LogoutButton>
          <CancelButton effect size="small" onClick={close}>
            Cancel
          </CancelButton>
        </Box>
      </Modal.Body>
    </Modal>
  );
});

LogoutModal.propTypes = {
  close: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
};

export default LogoutModal;
