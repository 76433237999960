import React from 'react';
import PropTypes from 'prop-types';

function SvgTicket({ fill, ...props }) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16 0C16 0.3453 15.9562 0.68038 15.874 1H18C18.5523 1 19 1.44772 19 2V22C19 22.5523 18.5523 23 18 23H15.874C15.9562 23.3196 16 23.6547 16 24H18C19.1046 24 20 23.1046 20 22V2C20 0.895431 19.1046 0 18 0H16ZM8 24C8 23.6547 8.04375 23.3196 8.12602 23H6C5.44772 23 5 22.5523 5 22V2C5 1.44772 5.44771 1 6 1H8.12602C8.04375 0.68038 8 0.3453 8 0H6C4.89543 0 4 0.89543 4 2V22C4 23.1046 4.89543 24 6 24H8Z"
          fill={fill}
        />
        <circle cx="12" cy="24" r="3.5" stroke={fill} />
        <circle cx="12" r="3.5" stroke={fill} />
        <rect x="4" y="8" width="5" height="1" rx="0.5" fill={fill} />
        <rect x="15" y="8" width="5" height="1" rx="0.5" fill={fill} />
        <rect x="10" y="8" width="4" height="1" rx="0.5" fill={fill} />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

SvgTicket.propTypes = {
  fill: PropTypes.string,
};

SvgTicket.defaultProps = {
  fill: '#fff',
};

export default SvgTicket;
