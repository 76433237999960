import React from 'react';
import PropTypes from 'prop-types';

// function SvgSpeakerMute({ fill, ...props }) {
//   return (
//     <svg width="1em" height="1em" viewBox="0 0 50 50" {...props}>
//       <path
//         d="M29.0567 2.16528C28.4767 1.88861 27.7933 1.96195 27.2933 2.36528L11.08 15.3353H3.33333C1.49667 15.3353 0 16.8319 0 18.6686V32.0019C0 33.8419 1.49667 35.3353 3.33333 35.3353H11.08L27.29 48.3053C27.5933 48.5453 27.9633 48.6686 28.3333 48.6686C28.58 48.6686 28.8267 48.6119 29.0567 48.5019C29.6333 48.2253 30 47.6419 30 47.0019V3.66861C30 3.02861 29.6333 2.44528 29.0567 2.16528Z"
//         fill={fill}
//       />
//       <path d="M32 18L47 33" stroke={fill} strokeWidth="3" />
//       <path d="M32 33L47 18" stroke={fill} strokeWidth="3" />
//     </svg>
//   );
// }

// SvgSpeakerMute.propTypes = {
//   fill: PropTypes.string,
// };

// SvgSpeakerMute.defaultProps = {
//   fill: '#818181',
// };

// export default SvgSpeakerMute;

const SvgSpeakerMute = ({ fill, size, ...props }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g>
        <path
          d="M5.23077 6.46153H5C2.79086 6.46153 1 8.2524 1 10.4615V12.6154C1 14.8245 2.79086 16.6154 5 16.6154H5.23077"
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.23096 6.46154L9.52063 2.56184C11.4478 0.809908 14.5386 2.17723 14.5386 4.78166V18.2953C14.5386 20.8997 11.4478 22.267 9.52063 20.5151L5.23096 16.6154"
          stroke={fill}
        />
        <path d="M17.9229 9L22.9998 14.0769" stroke={fill} strokeLinecap="round" strokeLinejoin="round" />
        <path d="M23 9L17.9231 14.0769" stroke={fill} strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width={size} height={size} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

SvgSpeakerMute.propTypes = {
  fill: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

SvgSpeakerMute.defaultProps = {
  fill: '#818181',
  size: '21',
};

export default SvgSpeakerMute;
