import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import PushPermissionModal from 'components/organisms/PushPermissionModal';
import PushNotificationModal from 'components/organisms/PushNotificationModal';
import { useToast } from 'hooks';
import { setFcmTokenAction, subscribeToTopicAction } from 'redux/actions';
import { messaging } from 'services/firebase';

export const PushNotificationContext = React.createContext({});

export const PushNotificationProvider = ({ children }) => {
  const dispatch = useDispatch();
  const { addToast } = useToast();
  const [token, setToken] = useState('');
  const [permissionModal, setPermissionModal] = useState(false);
  const [pushModal, setPushModal] = useState(false);

  const setFcmToken = useCallback((data, cb) => dispatch(setFcmTokenAction(data, cb)), [dispatch]);
  const closePermissionModal = useCallback(() => setPermissionModal(false), []);
  const togglePermissionModal = useCallback(() => setPermissionModal(v => !v), []);
  const closePushModal = useCallback(() => setPushModal(false), []);
  const togglePushModal = useCallback(() => setPushModal(v => !v), []);

  const [pushData, setPushData] = useState({
    data: '',
    message: '',
    title: '',
  });

  const refreshNotificationToken = useCallback(() => {
    messaging
      .getToken()
      .then(currentToken => {
        if (currentToken) {
          setToken(currentToken);
          setFcmToken({
            messageToken: currentToken,
            deviceId: '',
          });
        } else {
          togglePermissionModal();
        }
      })
      .catch(err => {
        console.log('An error occurred while retrieving token. ', err);
      });
  }, [setFcmToken, togglePermissionModal]);

  const requestPermission = useCallback(async () => {
    try {
      if (messaging) {
        await messaging.requestPermission();
        const fcmToken = await messaging.getToken();
        setToken(fcmToken);
        setFcmToken({
          messageToken: fcmToken,
          deviceId: '',
        });
        localStorage.setItem('fcmToken', fcmToken);
        closePermissionModal();
        addToast('Subscription successful.', { type: 'success' });
        return true;
      }
    } catch (e) {
      addToast('Push notification permission denied.', { type: 'error' });
      return false;
    }
  }, [setFcmToken, closePermissionModal, addToast]);

  const subscribeTokenToTopic = useCallback((topic = 'general') => dispatch(subscribeToTopicAction({ token, topic })), [
    dispatch,
    token,
  ]);

  useEffect(() => {
    const handleNotification = payload => {
      const { data, image, message, title } = payload?.data;
      setPushData(prevState => ({
        ...prevState,
        data,
        message,
        title,
      }));
      togglePushModal();
      messaging.swRegistration.showNotification(title, {
        body: message || '',
        icon: image,
        data: {
          link: data,
        },
      });
    };

    if (messaging) messaging.onMessage(handleNotification);
    // eslint-disable-next-line
  }, [token, pushData]);

  useEffect(() => {
    if (token) {
      subscribeTokenToTopic();
      messaging.onTokenRefresh(() => {
        messaging
          .getToken()
          .then(refreshedToken => {
            setToken(refreshedToken);
            setFcmToken({
              deviceId: '',
              messageToken: refreshedToken,
            });
          })
          .catch(err => {
            console.log('Unable to retrieve refreshed token ', err);
          });
      });
    }
  }, [setFcmToken, subscribeTokenToTopic, token]);

  const memoizedValue = useMemo(
    () => ({
      permissionModal,
      refreshNotificationToken,
      requestPermission,
      togglePermissionModal,
      token,
      pushData,
      setToken,
    }),
    [permissionModal, refreshNotificationToken, requestPermission, togglePermissionModal, token, pushData, setToken],
  );

  return (
    <PushNotificationContext.Provider value={memoizedValue}>
      {children}
      <PushPermissionModal
        close={closePermissionModal}
        isOpen={permissionModal}
        requestPermission={requestPermission}
        toggle={togglePermissionModal}
      />
      <PushNotificationModal
        close={closePushModal}
        isOpen={pushModal}
        toggle={togglePushModal}
        notificationData={pushData}
      />
    </PushNotificationContext.Provider>
  );
};
