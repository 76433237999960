import React from 'react';
import styled from '@emotion/styled/macro';
import { css } from '@emotion/core';
import { Box } from 'rebass';
import PropTypes from 'prop-types';
import { Text } from './Typography';

const PlayerTabs = styled(Box)`
  align-items: center;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  padding: 0 0 2rem 0;
`;

const ItemWrapper = styled(Box)`
  align-items: center;
  cursor: pointer;
  // display: flex;
  // flex: 1;
  justify-content: center;
  // padding: 1rem 0.5rem;
  text-decoration: none;

  ${Text} {
    color: #fff;
    font-size: ${({ theme }) => theme.font.size.tiny};
    font-weight: ${({ theme }) => theme.font.weight.normal};
    text-decoration: none;
    text-transform: uppercase;
    user-select: none;
  }

  ${({ active, theme, activeTabColor, activeTabBorderColor }) =>
    active &&
    css`
      border-color: ${theme.colors[activeTabBorderColor]};
      ${Text} {
        color: ${theme.colors[activeTabColor]};
      }
    `}

  &.active {
    border-color: ${({ theme, activeTabBorderColor }) => theme.colors[activeTabBorderColor]};
    ${Text} {
      color: ${({ theme, activeTabColor }) => theme.colors[activeTabColor]};
    }
  }
`;

const Item = ({
  active,
  activeTabBorderColor,
  activeTabColor,
  borderWidth,
  children,
  inactiveTabBorderColor,
  inactiveTabColor,
  ...props
}) => {
  return (
    <ItemWrapper
      active={active}
      borderWidth={borderWidth}
      activeTabBorderColor={activeTabBorderColor}
      activeTabColor={activeTabColor}
      inactiveTabBorderColor={inactiveTabBorderColor}
      inactiveTabColor={inactiveTabColor}
      {...props}
    >
      <Text>{children}</Text>
    </ItemWrapper>
  );
};

Item.propTypes = {
  active: PropTypes.bool,
  activeTabBorderColor: PropTypes.string,
  activeTabColor: PropTypes.string,
  borderWidth: PropTypes.string,
  inactiveTabBorderColor: PropTypes.string,
  inactiveTabColor: PropTypes.string,
};

Item.defaultProps = {
  active: false,
  activeTabBorderColor: 'primary',
  activeTabColor: 'primary',
  borderWidth: '2px',
  inactiveTabBorderColor: 'brownish-grey-four',
  inactiveTabColor: 'brownish-grey-four',
};

PlayerTabs.Item = Item;

export { PlayerTabs };
