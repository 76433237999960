import React from 'react';

const SvgAdd = ({ ...props }) => {
  return (
    <svg width="2.8em" height="2.8em" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect x="8" y="12" width="24" height="1" rx="0.5" fill="white" />
      <rect x="8" y="17" width="24" height="1" rx="0.5" fill="white" />
      <rect x="8" y="22" width="14" height="1" rx="0.5" fill="white" />
      <rect x="8" y="27" width="14" height="1" rx="0.5" fill="white" />
      <path d="M25.75 27.5L23 24.75L25.75 22" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M23.9999 24.75C23.9999 24.75 26.3648 24.75 27.8801 24.75H30.666V20"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SvgAdd;
