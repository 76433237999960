import * as t from '../types';

const INITIAL_STATE = {
  currentIndex: 0,
  currentItem: {},
  error: null,
  magic: false,
  magicLoading: {},
  magicQueue: [],
  muted: false,
  playerState: '',
  playing: false,
  progress: {
    played: 0,
    playedSeconds: 0,
  },
  queue: [],
  queueLoading: false,
  repeat: 0,
  shuffle: false,
  shuffleQueue: [],
  volume: 1,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case t.PLAY:
      return { ...state, playing: true };
    case t.PAUSE:
      return { ...state, playing: false };
    case t.RESET_ITEM:
      return {
        ...state,
        currentItem: {},
        progress: {
          played: 0,
          playedSeconds: 0,
        },
        playing: false,
      };

    case t.TOGGLE_MUTE:
      return { ...state, muted: !state.muted };

    case t.VOLUME_CHANGE:
      return { ...state, volume: action.payload, muted: false };

    case t.TOGGLE_PLAY:
      return { ...state, playing: !state.playing };

    case t.PLAY_TRACK:
      return {
        ...state,
        currentIndex: 0,
        currentItem: action.payload,
        magic: false,
        magicQueue: [],
        playing: true,
        queue: [action.payload],
        shuffle: false,
      };

    case t.PLAY_TRACKS:
      return { ...state, ...action.payload };

    case t.SHUFFLE_TRACKS:
      return { ...state, ...action.payload };

    case t.TOGGLE_SHUFFLE:
      return { ...state, ...action.payload };

    case t.TOGGLE_REPEAT:
      return { ...state, repeat: action.payload };

    case t.REPEAT_TRACK:
      return { ...state, playing: true, currentItem: action.payload };

    case t.REPEAT_TRACKS:
      return { ...state, ...action.payload };

    case t.QUEUE_ENDED:
      return { ...state, ...action.payload };

    case t.NEXT_TRACK:
      return {
        ...state,
        progress: {
          played: 0,
          playedSeconds: 0,
        },
        ...action.payload,
      };

    case t.PREVIOUS_TRACK:
      return {
        ...state,
        progress: {
          played: 0,
          playedSeconds: 0,
        },
        ...action.payload,
      };

    case t.MAGIC_PLAYLIST_REQUEST:
      return {
        ...state,
        magicLoading: { ...state.magicLoading, [action.payload]: true },
      };

    case t.MAGIC_PLAYLIST_SUCCESS:
      return {
        ...state,
        magicLoading: { ...state.magicLoading, [action.payload.id]: false },
        ...action.payload.data,
      };

    case t.MAGIC_PLAYLIST_FAILURE:
      return {
        ...state,
        magicLoading: { ...state.magicLoading, [action.payload]: false },
      };

    case t.UPDATE_PROGRESS:
      return { ...state, progress: { ...state.progress, ...action.payload } };

    case t.VALID_PLAY:
      return {
        ...state,
        currentItem: { ...state.currentItem, validPlay: true },
      };

    case t.VALID_PLAY_FAILURE:
      return {
        ...state,
        currentItem: { ...state.currentItem, validPlay: false },
      };

    case t.PLAYER_STATE_CHANGE:
      return { ...state, playerState: action.payload };

    case t.ORDER_QUEUE:
      return { ...state, ...action.payload };

    case t.PLAY_NEXT:
    case t.ADD_TO_QUEUE:
    case t.REMOVE_FROM_QUEUE:
      return { ...state, ...action.payload };

    case t.CLEAR_QUEUE:
      return { ...state, ...INITIAL_STATE };

    case t.GET_THEN_ADD_REQUEST:
      return { ...state, queueLoading: true };

    case t.GET_THEN_ADD_SUCCESS:
    case t.GET_THEN_ADD_FAILURE:
      return { ...state, queueLoading: false };

    case t.LOGOUT:
      return INITIAL_STATE;

    default:
      return { ...state };
  }
};
