import { messaging } from 'services/firebase';

const registerFcmSW = () => {
  if ('serviceWorker' in navigator) {
    const appType = process.env.REACT_APP_TYPE;
    const url = appType === 'next' ? '/custom-service-worker.js' : '/custom-staging-service-worker.js';

    navigator.serviceWorker
      .register(url, {
        updateViaCache: 'none',
      })
      .then(function(registration) {
        messaging.useServiceWorker(registration);
      })
      .catch(function(err) {
        console.log('Service worker registration failed, error:', err);
      });
  }
};

export { registerFcmSW };
