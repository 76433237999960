import PropTypes from 'prop-types';
import React from 'react';

function SvgYellowTwitter({ fill, ...props }) {
  return (
    <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M21.3219 2.11613C20.5338 2.475 19.694 2.71288 18.8185 2.82838C19.7192 2.27013 20.4066 1.39287 20.7298 0.3355C19.89 0.85525 18.9629 1.22238 17.9747 1.42725C17.1773 0.545875 16.0409 0 14.8011 0C12.3957 0 10.4592 2.02675 10.4592 4.51137C10.4592 4.86888 10.4883 5.21263 10.5598 5.53988C6.94775 5.357 3.75158 3.55988 1.60447 0.82225C1.22962 1.49738 1.00974 2.27012 1.00974 3.102C1.00974 4.664 1.78461 6.04862 2.93963 6.85025C2.24158 6.8365 1.55678 6.62613 0.976626 6.29475C0.976626 6.3085 0.976626 6.32638 0.976626 6.34425C0.976626 8.536 2.48265 10.3565 4.45758 10.7759C4.10392 10.8763 3.71847 10.9244 3.31845 10.9244C3.04029 10.9244 2.75949 10.9079 2.4959 10.8474C3.05884 12.6335 4.65626 13.9466 6.55568 13.9893C5.07747 15.1896 3.20057 15.9129 1.16869 15.9129C0.81238 15.9129 0.470643 15.8964 0.128906 15.851C2.05349 17.1394 4.33439 17.875 6.7941 17.875C14.7892 17.875 19.1602 11 19.1602 5.04075C19.1602 4.84137 19.1536 4.64887 19.1443 4.45775C20.0066 3.8225 20.7311 3.02913 21.3219 2.11613Z"
        fill={fill}
      />
    </svg>
  );
}

SvgYellowTwitter.propTypes = {
  fill: PropTypes.string,
};

SvgYellowTwitter.defaultProps = {
  fill: 'FDB913',
};

export default SvgYellowTwitter;
