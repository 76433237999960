import styled from '@emotion/styled/macro';
import { css } from '@emotion/core';
import blackCaret from 'assets/img/icons/black-carat.svg';
import whiteCaret from 'assets/img/icons/down-carat.svg';

export const Select = styled.select`
  -moz-appearance: none;
  -webkit-appearance: none;
  appearence: none;
  background-color: ${props => props.theme.colors[props.backgroundColor]};
  background-image: ${({ theme }) => (theme.type === 'dark' ? `url(${whiteCaret})` : `url(${blackCaret})`)};
  background-position: calc(100% - 2rem) 50%;
  background-repeat: no-repeat;
  border-radius: 8px;
  border: none;
  color: ${props => props.theme.colors.inputText};
  display: block;
  font-size: 1.6rem;
  height: 4.5rem;
  margin: 0;
  outline: none;
  padding: 0.5rem 2rem;
  width: 100%;

  &:disabled {
    background-color: rgba(128, 128, 128, 0.12);
  }

  &::placeholder {
    color: ${props => props.theme.colors['brownish-grey-two']};
    font-size: 1.2rem;
  }

  ${props => {
    if (props.addonPosition) {
      return css`
        padding-${props.addonPosition}: 0.5rem;
      `;
    }
  }}

  ${({ size }) => {
    if (size === 'sm') {
      return css`
        height: 4rem;
      `;
    }
  }}
`;

Select.defaultProps = {
  backgroundColor: 'input',
  textColor: 'inputText',
};
