import api from 'services/api';
import * as t from '../types';

export const getMoodAction = (id, callback = () => {}) => async dispatch => {
  try {
    dispatch({ type: t.GET_MOOD_REQUEST, payload: id });
    const resp = await api.getMood(id);
    if (resp.status === 200 || resp.status === 201) {
      dispatch({ type: t.GET_MOOD_SUCCESS, payload: { id, data: resp.data } });
      callback(true, null);
    } else {
      throw resp.data;
    }
  } catch (e) {
    callback(null, e);
    dispatch({ type: t.GET_MOOD_FAILURE, payload: id });
  }
};

export const getMoodsAction = (callback = () => {}) => async dispatch => {
  try {
    dispatch({ type: t.GET_MOODS_REQUEST });
    const resp = await api.getMoods();
    if (resp.status === 200 || resp.status === 201) {
      dispatch({ type: t.GET_MOODS_SUCCESS, payload: resp.data });
      callback(true, null);
    } else {
      throw resp.data;
    }
  } catch (e) {
    callback(null, e);
    dispatch({ type: t.GET_MOODS_FAILURE });
  }
};

export const getMoodTracksAction = (id, callback = () => {}) => async dispatch => {
  try {
    dispatch({ type: t.GET_MOOD_TRACKS_REQUEST, payload: id });
    const resp = await api.getMoodTracks(id);
    if (resp.status === 200 || resp.status === 201) {
      dispatch({
        type: t.GET_MOOD_TRACKS_SUCCESS,
        payload: { id, data: resp.data },
      });
      callback(resp.data, null);
    } else {
      throw resp.data;
    }
  } catch (e) {
    callback(null, e);
    dispatch({ type: t.GET_MOOD_TRACKS_FAILURE, payload: id });
  }
};
