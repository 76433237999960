import styled from '@emotion/styled';
import { LikeIcon } from 'components/icons';
import React from 'react';

const AnimationWrapper = styled.section`
position: absolute;
height: 10px;
width: 10px;
}
`;
const LikingIcon = styled(LikeIcon)`
  position: absolute;
  left: 0;
  animation-name: liking;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
  opacity: 0;

  ${({ delay }) =>
    `animation-delay: ${delay}s;
`}
  @keyframes liking {
    0% {
      transform: translateY(-10vh);
      opacity: 1;
    }
    50% {
      transform: translateY(-30vh) scale(4) rotate(-40deg);
      opacity: 0.5;
    }
    75% {
      transform: translateY(-50vh) scale(6) rotate(0deg);
      opacity: 0.5;
    }
    100% {
      transform: translateY(-80vh) scale(8) rotateZ(40deg);
    }
  }
`;
const LikeAnimation = ({ show }) => {
  // const [likesCount] = useState([]);
  if (!show) {
    return <></>;
  }
  return (
    <AnimationWrapper>
      {/* {likesCount.map((_, i) => ( */}
      <LikingIcon key={Math.random()} />
      {/* ))} */}
    </AnimationWrapper>
  );
};

export default React.memo(LikeAnimation);
