import {
  BandBadgeIcon,
  CommentBadgeIcon,
  OvalBadgeIcon,
  BlueHeartBadgeIcon,
  UnionBadgeIcon,
  MoneyNoteIcon,
} from 'components/icons';
import React from 'react';
import { ActionButton, BadgeIconWrapper, BadgeMessage, BadgeValue, BadgeWrapper, PriceWon, Title } from './styled';

const LiveStreamBadge = () => {
  const getBadge = badge => {
    switch (badge) {
      case 'blue_heart':
        return <BlueHeartBadgeIcon />;
      case 'oval':
        return <OvalBadgeIcon />;
      case 'union':
        return <UnionBadgeIcon />;
      case 'comment':
        return <CommentBadgeIcon />;
      default:
        return;
    }
  };
  return (
    <BadgeWrapper>
      <Title>Congrats!</Title>
      <BadgeIconWrapper>
        <BandBadgeIcon />
        {getBadge('blue_heart')}
        <BadgeValue>5</BadgeValue>
      </BadgeIconWrapper>
      <PriceWon>
        <MoneyNoteIcon />
        <p>+{'100'}</p>
      </PriceWon>
      <BadgeMessage>You won the 5 likes badge!</BadgeMessage>
      <ActionButton textColor="white">Ok!</ActionButton>
    </BadgeWrapper>
  );
};
export default LiveStreamBadge;
