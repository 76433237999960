import api from 'services/api';
import * as t from '../types';

export const sendFeedbackAction = (data, callback = () => {}) => async dispatch => {
  try {
    dispatch({ type: t.CREATE_FEEDBACK_REQUEST });
    const resp = await api.createFeedback({
      feeback: data,
    });
    if (resp.status === 200 || resp.status === 201) {
      dispatch({ type: t.CREATE_FEEDBACK_SUCCESS, payload: resp.data });
      callback(resp.data, null);
    } else {
      throw resp.data;
    }
  } catch (e) {
    dispatch({ type: t.CREATE_FEEDBACK_FAILURE });
    callback(null, e);
  }
};
