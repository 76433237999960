import React from 'react';

const SVGMoney = () => (
  <svg width="50" height="28" viewBox="0 0 50 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M49.9777 26.5338C41.7496 24.3428 33.5216 25.4383 25.2936 26.5338C17.0656 27.6293 8.83756 28.7248 0.609537 26.5338C0.609537 26.4406 0.259905 26.1376 0.259905 26.0443C0.236596 17.9795 0.586228 10.171 0.586228 2.08285C8.81425 4.29719 17.019 3.15505 25.247 2.01292C33.4517 0.870788 41.6564 -0.271345 49.8844 1.91969C49.8844 10.0778 49.9777 18.399 49.9777 26.5338Z"
      fill="url(#paint0_linear)"
    />
    <path
      opacity="0.4"
      d="M49.7006 26.3046C49.7006 18.1465 49.7006 10.0117 49.7006 1.85359C47.7427 1.34079 45.808 0.991158 43.8501 0.804688C43.7335 3.998 44.293 20.4307 42.5215 25.1391C44.9223 25.3023 47.2998 25.6519 49.7006 26.3046Z"
      fill="url(#paint1_linear)"
    />
    <path
      d="M4.06055 10.1494C4.06055 13.2961 4.06055 16.4428 4.06055 19.5661C6.13503 19.8925 7.81327 21.6406 7.81327 23.7151C19.2579 24.4144 30.7259 20.6617 42.1705 21.3376C42.1705 19.2631 43.8487 17.6548 45.9232 17.9811C45.9232 14.8345 45.9232 11.6878 45.9232 8.56438C43.8487 8.23805 42.1705 6.48989 42.1705 4.4154C30.7259 3.71614 19.2579 7.46886 7.81327 6.7929C7.81327 8.84408 6.13503 10.4524 4.06055 10.1494Z"
      fill="url(#paint2_linear)"
    />
    <path
      d="M31.1926 13.2967C31.1926 16.6997 28.4188 19.7765 25.0157 20.2427C21.6126 20.7089 18.8389 18.2614 18.8389 14.835C18.8389 11.4319 21.6126 8.35518 25.0157 7.889C28.4188 7.42283 31.1926 9.87025 31.1926 13.2967Z"
      fill="url(#paint3_linear)"
    />
    <path
      d="M15.063 15.1839C15.063 16.8155 13.7344 18.2606 12.1028 18.2839C10.4712 18.3305 9.14258 16.9553 9.14258 15.3004C9.14258 13.6688 10.4712 12.3868 12.1028 12.3402C13.7344 12.3169 15.063 13.5289 15.063 15.1839Z"
      fill="url(#paint4_linear)"
    />
    <path
      d="M34.9453 12.9448C34.9453 14.5998 36.2739 15.8118 37.9055 15.7652C39.5372 15.7186 40.8658 14.4366 40.8658 12.805C40.8658 11.1734 39.5372 9.77483 37.9055 9.82145C36.2739 9.86806 34.9453 11.3132 34.9453 12.9448Z"
      fill="#BBBBBB"
    />
    <path
      d="M14.5959 3.32075C14.5959 3.20421 14.6192 3.08767 14.6192 2.97112C17.9757 2.76134 21.3555 2.31847 24.712 1.87561C24.8052 1.8523 24.8984 1.8523 24.9917 1.82899C25.015 1.82899 25.0616 1.82899 25.0849 1.80568C25.1082 1.80568 25.1082 1.80568 25.1315 1.80568C25.2714 1.78237 25.3879 1.78237 25.5278 1.75906C25.5977 1.75906 25.6443 1.73575 25.7143 1.73575C25.7376 1.73575 25.7609 1.73575 25.7842 1.73575L25.8075 1.73575C25.8308 1.73575 25.8541 1.73575 25.8774 1.73575C25.9473 1.73575 25.994 1.71244 26.0639 1.71244C26.0639 1.71244 26.0872 1.71244 26.1105 1.71244C26.1338 1.71244 26.1804 1.71244 26.2037 1.68914C30.4926 1.10641 34.828 0.570312 39.1401 0.570311C39.1868 0.570311 39.2101 0.570311 39.2567 0.570311C39.3033 0.570311 39.3499 0.570311 39.3965 0.570311C42.7763 0.570311 46.1794 0.919944 49.5592 1.80568C47.6479 1.31619 45.7599 0.98987 43.8485 0.8034C43.8485 0.896635 43.8485 0.98987 43.8485 1.10641C42.4034 0.966561 40.9582 0.919944 39.5131 0.919944C34.7581 0.919944 30.0031 1.54928 25.2481 2.17862C21.7051 2.6448 18.1622 3.11098 14.5959 3.32075ZM2.1024 2.27186C2.07909 2.27186 2.07909 2.27186 2.05578 2.27186C2.07909 2.27186 2.1024 2.27186 2.1024 2.27186ZM2.05578 2.27186C1.47306 2.15531 0.890338 2.01546 0.307617 1.8523C0.330926 1.8523 0.330926 1.8523 0.354235 1.87561C0.936956 2.01546 1.49637 2.132 2.05578 2.27186Z"
      fill="#A6A6A6"
    />
    <path
      d="M0.56499 26.349C0.471755 26.3257 0.401828 26.3024 0.308593 26.2791C0.308593 18.121 0.308594 9.98622 0.308594 1.82812C0.891315 1.99129 1.47404 2.13114 2.05676 2.24768C2.08007 2.24768 2.08007 2.24768 2.10337 2.24768C4.99367 2.85371 7.86066 3.0868 10.751 3.0868C12.0329 3.0868 13.3382 3.04018 14.6202 2.97026C14.6202 3.0868 14.5969 3.20335 14.5969 3.31989C13.4082 3.38982 12.1961 3.43643 11.0073 3.43643C7.53433 3.43643 4.03801 3.0868 0.564991 2.15445C0.564991 10.2426 0.56499 18.2842 0.56499 26.349Z"
      fill="#A6A6A6"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M27.2499 11.635L27.0905 14.0884C27.0449 14.5568 26.7944 15.0252 26.4299 15.382C26.0654 15.7389 25.5871 15.9619 25.1087 16.0065C24.6304 16.0511 24.152 15.9173 23.8103 15.6274C23.4686 15.3374 23.2636 14.8913 23.2408 14.4229L23.4003 11.858L21.783 11.9472L21.7374 12.7725L20.1884 12.8617L20.1429 13.5085L21.6919 13.4193L21.6691 13.91L20.0973 13.9769L20.0518 14.6237L21.6007 14.5122C21.6007 15.4043 21.9424 16.2296 22.5575 16.8095C23.1725 17.3671 24.0381 17.657 24.9493 17.5678C25.8376 17.5009 26.726 17.0771 27.4094 16.4303C28.0928 15.7835 28.5028 14.9136 28.6167 14.0438L29.9607 13.9546L30.0062 13.3301L28.6623 13.4193L28.685 12.9509L30.029 12.8617L30.0746 12.2372L28.7306 12.3264L28.7761 11.5234L27.2499 11.635Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="25.1076"
        y1="27.8154"
        x2="25.1076"
        y2="0.671824"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#BBBBBB" />
        <stop offset="1" stop-color="#A6A6A6" />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="44.6641"
        y1="13.5935"
        x2="49.5589"
        y2="13.477"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8D8D8D" stop-opacity="0" />
        <stop offset="0.6979" stop-color="#8D8D8D" />
      </linearGradient>
      <linearGradient
        id="paint2_linear"
        x1="25.0061"
        y1="23.8051"
        x2="25.0061"
        y2="4.32517"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#CDCDCD" />
        <stop offset="1" stop-color="#DFDFDF" />
      </linearGradient>
      <linearGradient
        id="paint3_linear"
        x1="50.0773"
        y1="26.4773"
        x2="0.336249"
        y2="1.85402"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#BBBBBB" />
        <stop offset="1" stop-color="#A6A6A6" />
      </linearGradient>
      <linearGradient
        id="paint4_linear"
        x1="47.5188"
        y1="32.7674"
        x2="-2.42021"
        y2="8.144"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#BBBBBB" />
        <stop offset="1" stop-color="#A6A6A6" />
      </linearGradient>
    </defs>
  </svg>
);

export default SVGMoney;
