import React from 'react';
import PropTypes from 'prop-types';

function SvgSoup({ fill, ...props }) {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M12 0.652832C5.37003 0.652832 0 6.02286 0 12.6528C0 19.2768 5.37003 24.6528 12 24.6528C18.63 24.6528 24 19.2768 24 12.6528C24.0001 6.02286 18.63 0.652832 12 0.652832ZM14.628 15.2808L4.79999 19.8528L9.372 10.0248L19.2 5.45282L14.628 15.2808Z"
        fill={fill}
      />
      <defs>
        <linearGradient id="paint0_linear" x1="12" y1="0.652832" x2="12" y2="24.6528" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FDB913" />
          <stop offset="1" stopColor="#F2994A" />
        </linearGradient>
      </defs>
    </svg>
  );
}

SvgSoup.propTypes = {
  fill: PropTypes.string,
};

SvgSoup.defaultProps = {
  fill: '#fcb316',
};

export default SvgSoup;
