export const CLEAR_NEW_TRACK = 'CLEAR_NEW_TRACK';

export const GET_ARTIST_TRACKS_REQUEST = 'GET_ARTIST_TRACKS_REQUEST';
export const GET_ARTIST_TRACKS_SUCCESS = 'GET_ARTIST_TRACKS_SUCCESS';
export const GET_ARTIST_TRACKS_FAILURE = 'GET_ARTIST_TRACKS_FAILURE';

export const GET_MY_TRACK_SUCCESS = 'GET_MY_TRACK_SUCCESS';

export const UPDATE_TRACK_REQUEST = 'UPDATE_TRACK_REQUEST';
export const UPDATE_TRACK_SUCCESS = 'UPDATE_TRACK_SUCCESS';
export const UPDATE_TRACK_FAILURE = 'UPDATE_TRACK_FAILURE';

export const GET_TRACK_REQUEST = 'GET_TRACK_REQUEST';
export const GET_TRACK_SUCCESS = 'GET_TRACK_SUCCESS';
export const GET_TRACK_FAILURE = 'GET_TRACKS_FAILURE';

export const GET_TRACKS_REQUEST = 'GET_TRACKS_REQUEST';
export const GET_TRACKS_SUCCESS = 'GET_TRACKS_SUCCESS';
export const GET_TRACKS_FAILURE = 'GET_TRACKS_FAILURE';

export const GET_TRACKS_BY_TAG_REQUEST = 'GET_TRACKS_BY_TAG_REQUEST';
export const GET_TRACKS_BY_TAG_SUCCESS = 'GET_TRACKS_BY_TAG_SUCCESS';
export const GET_TRACKS_BY_TAG_FAILURE = 'GET_TRACKS_BY_TAG_FAILURE';

export const GET_USER_TRACKS_REQUEST = 'GET_USER_TRACKS_REQUEST';
export const GET_USER_TRACKS_SUCCESS = 'GET_USER_TRACKS_SUCCESS';
export const GET_USER_TRACKS_FAILURE = 'GET_USER_TRACKS_FAILURE';

export const GET_ONBOARDING_TRACKS_REQUEST = 'GET_ONBOARDING_TRACKS_REQUEST';
export const GET_ONBOARDING_TRACKS_SUCCESS = 'GET_ONBOARDING_TRACKS_SUCCESS';
export const GET_ONBOARDING_TRACKS_FAILURE = 'GET_ONBOARDING_TRACKS_FAILURE';

export const SORT_TRACKS_BY_TITLE = 'SORT_TRACKS_BY_TITLE';
export const SORT_TRACKS_BY_ARTIST = 'SORT_TRACKS_BY_ARTIST';
// export const SORT_TRACKS_BY_RECENT = 'SORT_TRACKS_BY_RECENT';
