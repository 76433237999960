import firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/auth';
import 'firebase/messaging';
import 'firebase/performance';
import 'firebase/firestore';
import 'firebase/remote-config';
import { config } from 'config/firebase.config';
import { isObjEmpty } from 'lib/utils';

firebase.initializeApp(config);
firebase.performance();

const googleProvider = new firebase.auth.GoogleAuthProvider();
const fbProvider = new firebase.auth.FacebookAuthProvider();

let messaging;
if (firebase.messaging.isSupported()) {
  messaging = firebase.messaging();
  messaging.usePublicVapidKey(process.env.REACT_APP_FCM_KEY);
}

export const authWithGoogle = () => firebase.auth().signInWithPopup(googleProvider);

export const authWithFacebook = () => firebase.auth().signInWithPopup(fbProvider);

export const getToken = async () => {
  const currentUser = await firebase.auth().currentUser;
  if (currentUser && !isObjEmpty(currentUser)) {
    const token = await firebase.auth().currentUser.getIdToken(true);
    return token;
  }
  return '';
};

export const checkIfLoggedIn = () =>
  new Promise(resolve => {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        resolve(true);
      } else {
        resolve(false);
      }
    });
  });

export const remoteConfig = firebase.remoteConfig();

remoteConfig.settings = {
  minimumFetchIntervalMillis: 3600000,
};

export const setCommentListener = (targetId, listener) => {
  if (!targetId) return;
  firebase
    .firestore()
    .collection('comments')
    .where('target', '==', 'videos')
    .where('targetID', '==', targetId)
    .orderBy('created', 'desc')
    .onSnapshot(listener);
};

export const setCollectionListener = (collection = 'comments', target, targetId, listener) => {
  if (!target || !targetId) return;
  firebase
    .firestore()
    .collection(collection)
    .where(target, '==', targetId)
    // .orderBy('created', 'desc')
    .onSnapshot(listener);
};

export { firebase, messaging };
