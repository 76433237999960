import React from 'react';
import PropTypes from 'prop-types';

function SvgMix({ fill, ...props }) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M16.665 7.11367C17.2788 7.72676 17.7657 8.45482 18.0979 9.25622C18.4301 10.0576 18.6011 10.9166 18.6011 11.7842C18.6011 12.6517 18.4301 13.5107 18.0979 14.3121C17.7657 15.1135 17.2788 15.8416 16.665 16.4547M7.335 16.4437C6.72123 15.8306 6.23432 15.1025 5.9021 14.3011C5.56989 13.4997 5.3989 12.6407 5.3989 11.7732C5.3989 10.9056 5.56989 10.0466 5.9021 9.24521C6.23432 8.44382 6.72123 7.71576 7.335 7.10266M19.7787 4C21.8413 6.06325 23 8.86123 23 11.7787C23 14.6961 21.8413 17.4941 19.7787 19.5573M4.22134 19.5573C2.15872 17.4941 1 14.6961 1 11.7787C1 8.86123 2.15872 6.06325 4.22134 4"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.7002 14V10C10.7002 9.58798 11.1706 9.35279 11.5002 9.6L14.1669 11.6C14.4335 11.8 14.4335 12.2 14.1669 12.4L11.5002 14.4C11.1706 14.6472 10.7002 14.412 10.7002 14Z"
        fill="white"
        stroke="white"
      />
    </svg>
  );
}

SvgMix.propTypes = {
  fill: PropTypes.string,
};

SvgMix.defaultProps = {
  fill: '#fff',
};

export default SvgMix;
