import PropTypes from 'prop-types';
import React from 'react';

function SvgRecent({ fill, ...props }) {
  return (
    <svg width="2.8em" height="2.8em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12"
        stroke={fill}
        strokeLinecap="round"
      />
      <path d="M11.9999 6.07512V13.6905L6.923 17.9213" stroke={fill} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

SvgRecent.propTypes = {
  fill: PropTypes.string,
};

SvgRecent.defaultProps = {
  fill: '#fff',
};

export default SvgRecent;
