import styled from '@emotion/styled/macro';

export const FormGroup = styled.div`
  width: 100%;

  .react-tel-input {
    background-color: ${({ theme }) => theme.colors.input};
    height: 4.5rem;
    width: 100%;

    .form-control {
      background-color: transparent;
      border: none;
      color: ${({ theme }) => theme.colors.white};
      font-size: 1.6rem;
      height: 4.5rem;
      line-height: 1.2;
      width: 100%;
    }

    .flag-dropdown {
      background-color: transparent;
      border: none;
    }
  }

  &:not(:last-child) {
    margin-bottom: 2rem;
  }
`;
