import * as React from 'react';

function SvgCheckmark(props) {
  return (
    <svg height="1em" viewBox="0 -21 512.016 512" fill="#349D34" width="1em" {...props}>
      <path d="M234.668 469.34C105.281 469.34 0 364.063 0 234.676S105.281.008 234.668.008c44.992 0 88.766 12.82 126.59 37.097 7.426 4.782 9.601 14.68 4.82 22.125-4.797 7.446-14.676 9.598-22.121 4.82-32.64-20.972-70.441-32.042-109.289-32.042C122.922 32.008 32 122.93 32 234.676c0 111.742 90.922 202.664 202.668 202.664 111.742 0 202.664-90.922 202.664-202.664 0-6.68-.32-13.293-.937-19.797-.852-8.813 5.59-16.621 14.378-17.473 8.832-.812 16.618 5.59 17.473 14.38a239.63 239.63 0 011.086 22.89c0 129.387-105.277 234.664-234.664 234.664zm0 0" />
      <path d="M261.332 288.008c-4.094 0-8.191-1.559-11.305-4.692l-96-96c-6.25-6.253-6.25-16.386 0-22.636s16.383-6.25 22.633 0l84.695 84.695 223.336-223.34c6.254-6.25 16.387-6.25 22.637 0s6.25 16.383 0 22.633L272.66 283.336a16.03 16.03 0 01-11.328 4.672zm0 0" />
    </svg>
  );
}

export default SvgCheckmark;
