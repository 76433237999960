import album from './album.reducer';
import artist from './artist.reducer';
import auth from './auth.reducer';
import { combineReducers } from 'redux';
import comment from './comment.reducer';
import connect from './connect.reducer';
import feedback from './feedback.reducer';
import leaderboard from './leaderboard.reducer';
import livestream from './livestream.reducer';
import mood from './mood.reducer';
import onboarding from './onbaording.reducer';
import { persistReducer } from 'redux-persist';
import player from './player.reducer';
import playlist from './playlist.reducer';
import profile from './profile.reducer';
import remoteConfig from './remoteConfig.reducer';
import screen from './screen.reducer';
import search from './search.reducer';
import statistics from './statistics.reducer';
import storage from 'redux-persist/lib/storage';
import track from './track.reducer';
import upload from './upload.reducer';
import user from './user.reducer';
import video from './video.reducer';

const blacklists = {
  album: ['fetching', 'fetchingArtistAlbums', 'fetchingTracks', 'updating'],
  artist: ['fetching', 'fetchingSimilar'],
  auth: [],
  comment: ['creating', 'disliking', 'fetching', 'liking', 'replying'],
  connect: ['fetching'],
  mood: ['fetching', 'fetchingTracks'],
  player: ['error', 'magicLoading', 'playerState', 'playing', 'progress', 'queueLoading'],
  playlist: ['adding', 'creating', 'deleting', 'fetching', 'fetchingArtistPlaylists', 'fetchingTracks', 'removing'],
  profile: ['editing', 'fetching', 'fetchingActivity'],
  remoteConfig: ['fetching'],
  screen: ['fetching, artistScreen'],
  search: ['fetching'],
  statistics: ['fetching'],
  track: ['fetching', 'fetchingArtistTracks', 'newTrack', 'updatingTrack', 'onBoardingTracks'],
  upload: ['gettingUploads', 'uploadingTrack', 'uploadingTrackFailed'],
  user: ['fetching', 'updating'],
  video: ['fetching'],
  livestream: ['fetching', 'setReminder'],
  onbaording: ['onBoardingData'],
  leaderboard: ['leaderboard'],
  feedback: ['feedback'],
};

const persistConfig = key => ({
  key,
  storage,
  blacklist: blacklists[key],
});

const appReducer = combineReducers({
  album,
  artist,
  auth: persistReducer(persistConfig('auth'), auth),
  comment,
  connect,
  mood,
  player: persistReducer(persistConfig('player'), player),
  playlist,
  profile: persistReducer(persistConfig('profile'), profile),
  remoteConfig: persistReducer(persistConfig('remoteConfig'), remoteConfig),
  screen,
  search,
  statistics,
  track: persistReducer(persistConfig('track'), track),
  upload,
  user: persistReducer(persistConfig('user'), user),
  video,
  livestream: persistReducer(persistConfig('livestream'), livestream),
  onboarding,
  leaderboard,
  feedback,
});

export default appReducer;
