import React from 'react';
import PropTypes from 'prop-types';

function SvgDownIcon({ fill, ...props }) {
  return (
    <svg width="11" height="7" viewBox="0 0 11 7" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M0.916016 0.917969L5.49935 5.5013L10.0827 0.917969"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

SvgDownIcon.propTypes = {
  fill: PropTypes.string,
};

SvgDownIcon.defaultProps = {
  fill: '#fff',
};

export default SvgDownIcon;
