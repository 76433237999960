import PropTypes from 'prop-types';
import React from 'react';

function SvgPlaylistPlay({ fill, size, ...props }) {
  return (
    <svg width={size} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width="30" height="30" rx="15" fill="#272729" fillOpacity="0.8" />
      <rect x="13" y="19.5385" width="3.46154" height="3.46154" rx="1.73077" stroke="white" strokeLinecap="round" />
      <rect x="13" y="13.7693" width="3.46154" height="3.46154" rx="1.73077" stroke="white" strokeLinecap="round" />
      <rect x="13" y="8" width="3.46154" height="3.46154" rx="1.73077" stroke="white" strokeLinecap="round" />
    </svg>
  );
}

SvgPlaylistPlay.propTypes = {
  fill: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

SvgPlaylistPlay.defaultProps = {
  fill: '#fefefe',
  size: 30,
};

export default SvgPlaylistPlay;
