import { Image, Typography } from 'components/atoms';

import { Box } from 'rebass';
import { css } from '@emotion/core';
import styled from '@emotion/styled/macro';

export const Poster = styled(Image)`
  flex-shrink: 0;
  height: 13rem;
  object-fit: cover;
  width: 13rem;

  ${({ theme }) => theme.mq.sm`
    height: 16rem;
    width: 16rem;
  `}

  ${({ theme }) => theme.mq.md`
    height: 20rem;
    width: 20rem;
  `}
`;

export const ArtistPoster = styled(Image)`
  flex-shrink: 0;
  height: 10rem;
  object-fit: cover;
  width: 10rem;

  ${({ theme }) => theme.mq.sm`
    height: 12rem;
    width: 12rem;
  `}

  ${({ theme }) => theme.mq.md`
    height: 18rem;
    width: 18rem;
  `}
`;

export const PlaylistPoster = styled(Image)`
  flex-shrink: 0;
  height: 15rem;
  object-fit: cover;
  width: 15rem;

  ${({ theme }) => theme.mq.sm`
    width: 18rem;
  `}

  ${({ theme }) => theme.mq.md`
    height: 20rem;
    width: 20rem;
  `}
`;

export const PlayBox = styled.div`
  display: flex;
  position: relative;
  svg {
    left: 50%;
    opacity: 0;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: all 0.7s;
    height: 4rem;
    width: 4rem;
  }

  &:hover {
    cursor: pointer;
    svg {
      opacity: 1;
      cursor: pointer;
    }
  }
`;

export const DetailBox = styled(Box)`
  &:last-child {
    flex-shrink: 0;
    margin-left: auto;
  }

  &:first-child {
    flex-direction: column;
    margin-left: 0;
    margin-right: 1rem;
    width: 100%;
  }
`;

export const Details = styled(Box)`
  align-items: center;
  flex-direction: column;
  padding: 1.2rem;
  text-decoration: none;
  color: #fff;
  text-align: center;
  margin: 1rem auto;

  ${({
    float,
    theme: {
      colors: { switchingBg },
    },
  }) =>
    float &&
    css`
      background-color: ${switchingBg}66;
      bottom: 0;
      left: 0;
      padding: 1rem 1.2rem;
      position: absolute;
      width: 100%;
    `}

  @media (max-width: 700px) {
    margin: 0.4rem auto;
  }
  @media (max-width: 500px) {
    margin: 0rem auto;
  }
`;

export const Title = styled(Typography.Paragraph)`
  &,
  &:visited {
    color: ${({ theme }) => theme.colors.switchingText};
  }
  font-size: ${({ theme }) => theme.font.size.tiniest};
  font-weight: ${({ theme }) => theme.font.weight.bold};

  &:not(:last-child) {
    margin-bottom: 0.5rem;
  }

  &:last-child {
    margin: 0.5rem 0;
  }

  ${({ theme }) => theme.mq.md`
    font-size: ${theme.font.size.medium};
  `}
`;

export const Description = styled(Typography.Paragraph)`
  &,
  &:visited {
    color: ${({ theme }) => theme.colors.switchingText};
  }
  font-size: ${({ theme }) => theme.font.size.tiny};
  margin-bottom: 1rem;
  maring-top: 1rem;
  opacity: 0.7;

  ${({ theme }) => theme.mq.md`
  font-size: ${theme.font.size.small};
  `}
`;

export const Subtitle = styled(Typography.Paragraph)`
  &,
  &:visited {
    color: ${({ theme }) => theme.colors.switchingText};
  }
  font-size: ${({ theme }) => theme.font.size.tiniest};
  line-height: 1.2;

  ${({ theme }) => theme.mq.md`
font-size: ${theme.font.size.tiny};
`}
`;

export const Overlay = styled.div`
  background: rgba(0, 0, 0, 0.3);
  height: 100%;
  width: 100%;
  visibility: hidden;
  top: 0;
  right: 0;
  position: absolute;
  z-index: 1;
`;

export const Icons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: absolute;
  top: 16rem;
  padding: 0 1rem;
  display: none;
  z-index: 1;
  // svg {
  //   cursor: pointer;
  // }

  svg {
    cursor: pointer;
    &:hover {
      transform: scale(1.1);
      transition-duration: 0.15s;
    }
  }
  .more {
    &:hover {
      transform: scale(1.1);
      transition-duration: 0.15s;
    }
  }
`;

export const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.tileBg};
  border-radius: 0.8rem;
  display: inline-flex;
  flex-direction: column;
  align-items: ${({ alt }) => (alt ? 'center' : 'unset')};
  justify-content: ${({ alt }) => (alt ? 'center' : 'unset')};
  overflow: ${({ hideOverflow }) => (hideOverflow ? 'hidden' : 'unset')};
  text-decoration: none;
  width: ${({ width }) => width[0]};
  height: 24rem;
  position: relative;
    
  &:hover {
    ${Overlay} {
      visibility: visible;
    }
    ${Icons} {
      display: flex;
    }
  }
  

  ${Poster} {
    height: ${({ height }) => height[0]};
    width: ${({ width }) => width[0]};

    img{
      height: 100%;
      width: 100%;
    }
  }

  ${({ artist }) =>
    artist &&
    css`
      background-color: transparent;
      ${Poster} {
        border-radius: 50%;
      }
      ${Title} {
        text-align: center;
      }
    `}

  ${({ height, theme, width }) => theme.mq.sm`
    width: ${width[1]};
    height: 26rem;
    ${Poster}{
      height:${height[1]};
      width: ${width[1]};

      img{
        height: 100%;
        width: 100%;
      }
    }
  `}

  ${({ height, theme, width }) => theme.mq.md`
    width: ${width[2]};
    height: 30rem;

    ${Poster}{
      height:${height[2]};
      width: ${width[2]};

      img{
        height: 100%;
        width: 100%;
      }
    }
  `}

  ${({ height, theme, width }) =>
    height[3] &&
    width[3] &&
    theme.mq.lg`
    width: ${width[3]};
    ${Poster}{
      height:${height[3]};
      width: ${width[3]};

      img{
        height: 100%;
        width: 100%;
      }
    }
  `}
  @media (max-width: 500px){
    height: 22rem;
  }
`;

Wrapper.defaultProps = {
  height: ['14rem', '18rem', '20rem'],
  hideOverflow: true,
  width: ['14rem', '18rem', '20rem'],
};
