import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { Typography, Button, Form } from 'components/atoms';
import { Modal } from 'components/molecules';
import { Box } from 'rebass';
import { numbersOnly } from 'lib/utils';
import { useDispatch, useSelector } from 'react-redux';
import { claimRewardAction } from 'redux/actions';
import { useToast } from 'hooks';

const INITIAL_FORM = {
  phone: '+234',
  provider: '',
};

const PromoCreditModal = ({ isOpen, toggle }) => {
  const { addToast } = useToast();
  const dispatch = useDispatch();
  const claiming = useSelector(state => state.profile.claiming || false);
  const [form, setForm] = useState({ ...INITIAL_FORM });
  const [error, setError] = useState('');
  const claimReward = useCallback((data, cb) => dispatch(claimRewardAction(data, cb)), [dispatch]);

  const onNumberChange = phone => {
    setForm(v => ({
      ...v,
      phone,
    }));
  };

  const onChange = e => {
    const { name, value } = e.target;

    setForm(v => ({
      ...v,
      [name]: value,
    }));
  };

  const validateForm = () => {
    const { phone, provider } = form;

    if (!phone) {
      setError('Please type a phone number');
      return false;
    }
    if (!parsePhoneNumberFromString(phone, 'NG')?.isValid()) {
      setError('Please enter a valid phone number');
      return false;
    }
    if (!provider) {
      setError('Please select your provider');
      return false;
    }

    return true;
  };

  const getPhone = x => {
    let num = `${numbersOnly(x)}`;
    if (num.startsWith('2340')) {
      num = `${num.substr(3)}`;
      return num;
    }
    if (num.startsWith('234')) {
      num = `0${num.substr(3)}`;
      return num;
    }
    if (num.startsWith('8')) {
      num = `0${num}`;
      return num;
    }
    return num;
  };

  const onSubmit = e => {
    e.preventDefault();
    setError('');

    if (validateForm()) {
      const data = {
        type: 'airtime',
        provider: form.provider,
        phone: getPhone(form.phone),
      };

      claimReward(data, (success, err) => {
        if (success) {
          addToast('Airtime claimed successfully');
          toggle();
        } else {
          const message = err?.msg || 'An error occured';
          setError(message);
          //   addToast(message, { type: 'error' });
        }
      });
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="sm" showCloseIcon>
      <Modal.Header justifyContent="center">
        <Typography.Paragraph>GET FREE AIRTIME</Typography.Paragraph>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={onSubmit}>
          <Form.Group>
            <Form.Label required>Phone Number</Form.Label>
            <Form.Input
              name="phone"
              onChange={onNumberChange}
              onlyCountries={['ng']}
              placeholder="Phone Number"
              type="phone"
              country="ng"
              value={form.phone}
              required
            />
          </Form.Group>

          <Form.Group>
            <Form.Label required>Provider</Form.Label>
            <Form.Select name="provider" onChange={onChange} value={form.provider} required>
              <option value="">Select a network provider </option>
              <option value="MTN">MTN</option>
              <option value="9MOBILE">9MOBILE </option>
              <option value="GLO">GLO</option>
              <option value="AIRTEL">AIRTEL</option>
            </Form.Select>
          </Form.Group>

          <Form.Error error={error} />

          <Box mt="1rem" display="flex" justifyContent="center">
            <Button fontSize="small" onClick={onSubmit} loading={claiming}>
              Claim
            </Button>
          </Box>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

PromoCreditModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
};

export default PromoCreditModal;
