import { Box, Flex } from 'rebass';
import React, { useContext } from 'react';
import { YellowFacebook, YellowInsta, YellowTwitter } from 'components/icons';
import Apple from '../../../assets/img/apple.svg';
import Google from '../../../assets/img/google.svg';
import TextMe from '../../../assets/img/text-me.svg';
import { TextModalContext } from 'contexts/TextModalContext';
import styled from '@emotion/styled/macro';
import { useSelector } from 'react-redux';

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.footer};
`;

const SocialLinks = styled(Box)`
  align-items: center;
  display: flex;

  a {
    cursor: pointer;
    margin: 0 2rem;
    font-size: 1rem;
    svg {
      height: 1.5rem;
      width: 1.5rem;
    }
    &:not(:last-child) {
      margin-right: 1.5rem;
    }
  }
`;

const Badges = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  a {
    text-align: center;
  }
  @media (max-width: ${props => props.theme.breakpoints[0]}) {
    margin-bottom: 1rem;
  }
`;
const Store = styled.img`
  width: 13rem;
  margin-right: 1rem;
  cursor: pointer;
  @media (max-width: ${props => props.theme.breakpoints[1]}) {
    width: 7rem;
  }
`;

const MainContent = styled(Flex)`
  @media (max-width: ${props => props.theme.breakpoints[0]}) {
    flex-direction: column;
    align-items: center;
    justify-items: center;
  }
`;

const Appstore = styled.a``;
const Playstore = styled.a``;

const TextMeApp = styled.a``;

export const FooterSocial = () => {
  const { toggle } = useContext(TextModalContext);
  const userSub = useSelector(state => state.profile.user || {});
  return (
    <Wrapper style={{ paddingBottom: userSub?.subscription?.status !== 'ACTIVE' ? '8rem' : '2rem' }}>
      <MainContent justifyContent="space-between" alignItems="center" flexWrap="wrap">
        <Badges>
          <Playstore href="https://play.google.com/store/apps/details?id=ng.groove.udux">
            <Store src={Google}></Store>
          </Playstore>
          <Appstore href="https://apps.apple.com/app/udux/id1372897101">
            <Store src={Apple}></Store>
          </Appstore>
          <TextMeApp onClick={() => toggle('text')}>
            <Store src={TextMe}></Store>
          </TextMeApp>
        </Badges>
        <Box display="flex" alignItems="center" mb={['2rem', null, null, 0]}>
          <SocialLinks>
            <a
              href="https://www.facebook.com/uduXmusic/"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="uduX facebook link"
            >
              <YellowFacebook fill="#FDB913" />
            </a>
            <a
              href="https://twitter.com/uduXmusic/"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="uduX twitter link"
            >
              <YellowTwitter fill="#FDB913" />
            </a>
            <a
              href="https://www.instagram.com/uduxmusic/"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="uduX instagram link"
            >
              <YellowInsta fill="#FDB913" />
            </a>
          </SocialLinks>
        </Box>
      </MainContent>
    </Wrapper>
  );
};
