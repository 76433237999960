import React from 'react';
import PropTypes from 'prop-types';

function SvgMoneyBag({ fill, ...props }) {
  return (
    <svg width="60" height="59" viewBox="0 0 90 79" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M42.4674 31.9937L40.5388 44.3505C34.6379 46.683 22.9241 66.2863 18.5439 68.0449C12.8121 64.7411 0.401622 58.1513 0.401622 58.1513C0.31332 52.3736 0.535245 49.5785 0.535245 49.5785L2.16302 47.8905C2.16302 47.8905 40.8053 31.5245 42.4674 31.9937Z"
        fill="url(#paint0_linear)"
      />
      <path
        d="M40.5381 44.35C34.6927 46.6606 23.1579 65.8987 18.6699 67.9873C18.9339 66.7344 18.8734 64.0653 18.8734 64.0653C18.8734 64.0653 19.4479 63.0156 18.9606 63.1586L18.9724 61.3912L19.1743 60.2739L20.2905 58.7831L18.9573 59.7731C18.9573 59.7731 18.7057 58.1883 19.1644 56.5022C21.9828 54.2088 35.6937 37.2941 41.3054 32.2289C41.923 32.0408 42.3278 31.9542 42.4667 31.9932L40.5381 44.35Z"
        fill="#8A5321"
      />
      <path
        d="M1.58789 48.4872L2.16305 47.8905C2.16305 47.8905 2.48777 47.7472 3.07181 47.4909C6.72528 49.3358 21.9316 57.1571 21.9316 57.1571L19.1129 58.4941C17.7709 57.6683 3.62365 49.9224 1.58789 48.4872Z"
        fill="#8A5321"
      />
      <path
        d="M43.0146 31.6063C39.5378 29.9382 28.4077 25.2461 25.9966 25C20.7782 27.7387 9.28817 47.6671 0 46.2701C8.03693 51.9749 18.8504 57.9922 18.8504 57.9922C25.7634 53.197 35.1452 35.9447 43.0146 31.6063Z"
        fill="url(#paint1_linear)"
      />
      <path
        d="M36.3581 33.529C35.3018 32.5828 34.8448 31.7006 35.8752 31.2265C31.4296 29.4296 28.2025 27.9488 28.2025 27.9488C27.1729 29.4624 25.9508 29.9502 24.413 29.5776C21.0905 33.3217 12.8762 44.0172 7.42383 45.9598C8.95475 46.8976 7.56381 48.583 7.56381 48.583C10.047 50.26 13.9397 52.2397 16.3398 53.8545C18.445 51.3539 20.6208 53.1645 20.6208 53.1645C21.8998 51.3176 32.5961 38.1383 36.3581 33.529Z"
        fill="url(#paint2_linear)"
      />
      <path
        d="M14.6367 64.3463C14.7475 64.3779 14.8567 64.4087 14.9609 64.4346C14.8903 64.428 14.7775 64.3969 14.6367 64.3463Z"
        fill="#8A5321"
      />
      <path
        d="M13.6653 58.1998C9.74659 56.2611 0.696234 51.4318 0.696234 51.4318L0.58915 52.4507L0.486987 55.3552C0.486987 55.3552 5.14778 58.5059 5.46623 58.8711C4.27796 58.1346 1.00024 56.1439 0.381057 55.7683C0.37762 53.5862 0.419529 51.9934 0.460999 50.9712C0.98468 51.1838 11.3432 56.6073 13.6653 58.1998Z"
        fill="#8A5321"
      />
      <path
        d="M0.392052 57.434C0.387845 57.0488 0.384627 56.6756 0.382812 56.3189L0.501501 56.9731C0.501501 56.9731 12.264 63.5189 14.6364 64.3467C10.6608 63.2203 2.13379 58.6206 0.392052 57.434Z"
        fill="#8A5321"
      />
      <path
        d="M27.0726 53.7246C27.0726 52.194 26.9852 50.7296 26.7066 49.9434C26.7066 49.9434 26.65 49.9109 26.5449 49.8504H26.5452C26.6508 49.9112 26.7066 49.9434 26.7066 49.9434C26.9852 50.7296 27.0726 52.194 27.0726 53.7246Z"
        fill="#9A7F1F"
      />
      <path
        d="M26.5446 49.8506C26.544 49.8506 26.5435 49.85 26.543 49.8495C26.5435 49.8495 26.543 49.85 26.543 49.8495C26.5435 49.85 26.5446 49.85 26.5451 49.8506H26.5446Z"
        fill="#A58C1E"
      />
      <path
        d="M26.5431 49.8495C26.5021 49.8261 26.4537 49.7983 26.3984 49.7667C26.3987 49.7667 26.3987 49.7664 26.3987 49.7664C26.4543 49.7983 26.5024 49.8258 26.5431 49.8495C26.5431 49.8498 26.5434 49.8495 26.5431 49.8495Z"
        fill="#9A7F1F"
      />
      <path
        d="M27.694 31.5191C26.7087 32.0253 26.269 33.1347 26.7109 33.9935C27.1534 34.8548 28.3111 35.1425 29.2962 34.6354C30.2809 34.1299 30.7209 33.0206 30.279 32.1598C29.8365 31.2996 28.6791 31.0131 27.694 31.5191Z"
        fill="url(#paint3_linear)"
      />
      <path
        d="M15.8242 45.9668C14.6615 45.8873 13.7031 46.5861 13.6839 47.5258C13.6646 48.4657 14.5925 49.2926 15.7557 49.3707C16.9187 49.4505 17.8773 48.7528 17.8966 47.812C17.9153 46.8715 16.9877 46.046 15.8242 45.9668Z"
        fill="url(#paint4_linear)"
      />
      <path
        d="M21.2793 30.5566C23.3753 28.6239 25.5258 26.733 26.0577 26.4256C26.5893 26.1184 33.8775 28.9754 36.8261 30.0179C34.7218 28.2329 26.9883 25.5184 25.8297 25.8421C24.5468 26.3285 21.5422 30.005 21.2793 30.5566Z"
        fill="#F5DEA4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.8319 37.89L23.7069 39.355C24.0568 39.6603 24.2783 40.0868 24.3271 40.5492C24.3758 41.0116 24.2482 41.4757 23.9698 41.8486C23.6913 42.2216 23.2826 42.4759 22.8254 42.5606C22.3682 42.6454 21.8963 42.5543 21.504 42.3056L19.5583 40.8001L18.6317 41.9861L20.5706 43.5009C21.2721 43.9914 22.1355 44.1936 22.9829 44.0658C23.8303 43.9379 24.5971 43.4898 25.1252 42.8138C25.6534 42.1378 25.9027 41.2854 25.8217 40.4322C25.7407 39.5791 25.3357 38.7902 24.69 38.2282L24.6684 38.2113L25.6046 37.0131L25.1252 36.6386L24.1891 37.8368L23.8296 37.556L24.7658 36.3578L24.2864 35.9833L23.3503 37.1815L22.7511 36.7134L21.8319 37.89ZM18.2943 43.6526L19.2306 42.4542L19.7099 42.8287L18.7736 44.027L18.2943 43.6526ZM19.1331 44.3079L20.0694 43.1096L20.5487 43.4841L19.6125 44.6824L19.1331 44.3079Z"
        fill="url(#paint5_linear)"
      />
      <path
        d="M9.91387 42.0113L9.90039 42.0331C12.9884 43.1165 24.4044 49.6563 26.0536 50.6045C28.3432 47.7162 30.7266 44.4957 33.1142 41.5084C30.4192 40.0951 19.0702 35.2375 17.1516 34.3603C14.8817 37.0022 12.4221 39.7673 9.91387 42.0113Z"
        fill="#8A5321"
      />
      <path
        d="M33.505 51.233C33.5342 48.3326 33.5089 41.8107 33.4836 41.1683C30.9008 39.7804 18.3853 34.4358 17.2124 33.869C13.0313 37.0095 10.1543 41.6314 10.1543 41.6314C13.4856 42.8002 26.514 50.3219 26.514 50.3219C27.2645 52.4408 26.6287 59.4824 26.6287 59.4824C26.6287 59.4824 32.3533 53.2911 33.505 51.233Z"
        fill="#494949"
      />
      <path
        d="M26.6286 59.4824C26.6286 59.4824 26.88 56.6972 26.88 54.1032C26.88 52.5725 26.7925 51.1082 26.5139 50.3219C26.5139 50.3219 26.4581 50.2898 26.3525 50.229C26.3522 50.2287 26.3517 50.2287 26.3514 50.2284C26.3107 50.2048 26.2626 50.1773 26.207 50.1454C28.3798 48.9027 32.1841 43.3315 33.4714 41.1617L33.4835 41.1683C33.5008 41.605 33.5179 44.7579 33.5182 47.6434C33.5182 47.6476 33.5182 47.6517 33.5182 47.6558C33.5182 47.6599 33.5182 47.6641 33.5182 47.6682C33.5182 49.0193 33.5143 50.3096 33.505 51.233C32.3533 53.2911 26.6286 59.4824 26.6286 59.4824Z"
        fill="#272729"
      />
      <path
        d="M20.1133 36.0501C21.5884 36.9981 31.0769 40.7359 31.0976 41.3057C31.1174 41.8763 31.1174 41.8763 31.1174 41.8763C31.1174 41.8763 26.9569 48.2087 26.1127 48.9512C27.9092 48.4402 31.9531 42.1573 32.3439 41.3859C31.6654 40.432 22.9738 36.689 20.1133 36.0501Z"
        fill="#868687"
      />
      <path
        d="M65.4674 36.9937L63.5388 49.3505C57.6379 51.683 45.9241 71.2863 41.5439 73.0449C35.8121 69.7411 23.4016 63.1513 23.4016 63.1513C23.3133 57.3736 23.5352 54.5785 23.5352 54.5785L25.163 52.8905C25.163 52.8905 63.8053 36.5245 65.4674 36.9937Z"
        fill="url(#paint6_linear)"
      />
      <path
        d="M63.5381 49.35C57.6927 51.6606 46.1579 70.8987 41.6699 72.9873C41.9339 71.7344 41.8734 69.0653 41.8734 69.0653C41.8734 69.0653 42.4479 68.0156 41.9606 68.1586L41.9724 66.3912L42.1743 65.2739L43.2905 63.7831L41.9573 64.7731C41.9573 64.7731 41.7057 63.1883 42.1644 61.5022C44.9828 59.2088 58.6937 42.2941 64.3054 37.2289C64.923 37.0408 65.3278 36.9542 65.4667 36.9932L63.5381 49.35Z"
        fill="#8A5321"
      />
      <path
        d="M24.5879 53.4872L25.1631 52.8905C25.1631 52.8905 25.4878 52.7472 26.0718 52.4909C29.7253 54.3358 44.9316 62.1571 44.9316 62.1571L42.1129 63.4941C40.7709 62.6683 26.6237 54.9224 24.5879 53.4872Z"
        fill="#8A5321"
      />
      <path
        d="M66.0146 36.6063C62.5378 34.9382 51.4077 30.2461 48.9966 30C43.7782 32.7387 32.2882 52.6671 23 51.2701C31.0369 56.9749 41.8504 62.9922 41.8504 62.9922C48.7634 58.197 58.1452 40.9447 66.0146 36.6063Z"
        fill="url(#paint7_linear)"
      />
      <path
        d="M59.3581 38.529C58.3018 37.5828 57.8448 36.7006 58.8752 36.2265C54.4296 34.4296 51.2025 32.9488 51.2025 32.9488C50.1729 34.4624 48.9508 34.9502 47.413 34.5776C44.0905 38.3217 35.8762 49.0172 30.4238 50.9598C31.9547 51.8976 30.5638 53.583 30.5638 53.583C33.047 55.26 36.9397 57.2397 39.3398 58.8545C41.445 56.3539 43.6208 58.1645 43.6208 58.1645C44.8998 56.3176 55.5961 43.1383 59.3581 38.529Z"
        fill="url(#paint8_linear)"
      />
      <path
        d="M37.6367 69.3463C37.7475 69.3779 37.8567 69.4087 37.9609 69.4346C37.8903 69.428 37.7775 69.3969 37.6367 69.3463Z"
        fill="#8A5321"
      />
      <path
        d="M36.6653 63.1998C32.7466 61.2611 23.6962 56.4318 23.6962 56.4318L23.5891 57.4507L23.487 60.3552C23.487 60.3552 28.1478 63.5059 28.4662 63.8711C27.278 63.1346 24.0002 61.1439 23.3811 60.7683C23.3776 58.5862 23.4195 56.9934 23.461 55.9712C23.9847 56.1838 34.3432 61.6073 36.6653 63.1998Z"
        fill="#8A5321"
      />
      <path
        d="M23.3921 62.434C23.3878 62.0488 23.3846 61.6756 23.3828 61.3189L23.5015 61.9731C23.5015 61.9731 35.264 68.5189 37.6364 69.3467C33.6608 68.2203 25.1338 63.6206 23.3921 62.434Z"
        fill="#8A5321"
      />
      <path
        d="M50.0726 58.7246C50.0726 57.194 49.9852 55.7296 49.7066 54.9434C49.7066 54.9434 49.65 54.9109 49.5449 54.8504H49.5452C49.6508 54.9112 49.7066 54.9434 49.7066 54.9434C49.9852 55.7296 50.0726 57.194 50.0726 58.7246Z"
        fill="#9A7F1F"
      />
      <path
        d="M49.5446 54.8506C49.544 54.8506 49.5435 54.85 49.543 54.8495C49.5435 54.8495 49.543 54.85 49.543 54.8495C49.5435 54.85 49.5446 54.85 49.5451 54.8506H49.5446Z"
        fill="#A58C1E"
      />
      <path
        d="M49.5431 54.8495C49.5021 54.8261 49.4537 54.7983 49.3984 54.7667C49.3987 54.7667 49.3987 54.7664 49.3987 54.7664C49.4543 54.7983 49.5024 54.8258 49.5431 54.8495C49.5431 54.8498 49.5434 54.8495 49.5431 54.8495Z"
        fill="#9A7F1F"
      />
      <path
        d="M50.694 36.5191C49.7087 37.0253 49.269 38.1347 49.7109 38.9935C50.1534 39.8548 51.3111 40.1425 52.2962 39.6354C53.2809 39.1299 53.7209 38.0206 53.279 37.1598C52.8365 36.2996 51.6791 36.0131 50.694 36.5191Z"
        fill="url(#paint9_linear)"
      />
      <path
        d="M38.8242 50.9668C37.6615 50.8873 36.7031 51.5861 36.6839 52.5258C36.6646 53.4657 37.5925 54.2926 38.7557 54.3707C39.9187 54.4505 40.8773 53.7528 40.8966 52.812C40.9153 51.8715 39.9877 51.046 38.8242 50.9668Z"
        fill="url(#paint10_linear)"
      />
      <path
        d="M44.2793 35.5566C46.3753 33.6239 48.5258 31.733 49.0577 31.4256C49.5893 31.1184 56.8775 33.9754 59.8261 35.0179C57.7218 33.2329 49.9883 30.5184 48.8297 30.8421C47.5468 31.3285 44.5422 35.005 44.2793 35.5566Z"
        fill="#F5DEA4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.8319 42.89L46.7069 44.355C47.0568 44.6603 47.2783 45.0868 47.3271 45.5492C47.3758 46.0116 47.2482 46.4757 46.9698 46.8486C46.6913 47.2216 46.2826 47.4759 45.8254 47.5606C45.3682 47.6454 44.8963 47.5543 44.504 47.3056L42.5583 45.8001L41.6317 46.9861L43.5706 48.5009C44.2721 48.9914 45.1355 49.1936 45.9829 49.0658C46.8303 48.9379 47.5971 48.4898 48.1252 47.8138C48.6534 47.1378 48.9027 46.2854 48.8217 45.4322C48.7407 44.5791 48.3357 43.7902 47.69 43.2282L47.6684 43.2113L48.6046 42.0131L48.1252 41.6386L47.1891 42.8368L46.8296 42.556L47.7658 41.3578L47.2864 40.9833L46.3503 42.1815L45.7511 41.7134L44.8319 42.89ZM41.2943 48.6526L42.2306 47.4542L42.7099 47.8287L41.7736 49.027L41.2943 48.6526ZM42.1331 49.3079L43.0694 48.1096L43.5487 48.4841L42.6125 49.6824L42.1331 49.3079Z"
        fill="url(#paint11_linear)"
      />
      <path
        d="M32.9139 47.0113L32.9004 47.0331C35.9884 48.1165 47.4044 54.6563 49.0536 55.6045C51.3432 52.7162 53.7266 49.4957 56.1142 46.5084C53.4192 45.0951 42.0702 40.2375 40.1516 39.3603C37.8817 42.0022 35.4221 44.7673 32.9139 47.0113Z"
        fill="#8A5321"
      />
      <path
        d="M56.505 56.233C56.5342 53.3326 56.5089 46.8107 56.4836 46.1683C53.9008 44.7804 41.3853 39.4358 40.2124 38.869C36.0313 42.0095 33.1543 46.6314 33.1543 46.6314C36.4856 47.8002 49.514 55.3219 49.514 55.3219C50.2645 57.4408 49.6287 64.4824 49.6287 64.4824C49.6287 64.4824 55.3533 58.2911 56.505 56.233Z"
        fill="#494949"
      />
      <path
        d="M49.6286 64.4824C49.6286 64.4824 49.88 61.6972 49.88 59.1032C49.88 57.5725 49.7925 56.1082 49.5139 55.3219C49.5139 55.3219 49.4581 55.2898 49.3525 55.229C49.3522 55.2287 49.3517 55.2287 49.3514 55.2284C49.3107 55.2048 49.2626 55.1773 49.207 55.1454C51.3798 53.9027 55.1841 48.3315 56.4714 46.1617L56.4835 46.1683C56.5008 46.605 56.5179 49.7579 56.5182 52.6434C56.5182 52.6476 56.5182 52.6517 56.5182 52.6558C56.5182 52.6599 56.5182 52.6641 56.5182 52.6682C56.5182 54.0193 56.5143 55.3096 56.505 56.233C55.3533 58.2911 49.6286 64.4824 49.6286 64.4824Z"
        fill="#272729"
      />
      <path
        d="M43.1133 41.0501C44.5884 41.9981 54.0769 45.7359 54.0976 46.3057C54.1174 46.8763 54.1174 46.8763 54.1174 46.8763C54.1174 46.8763 49.9569 53.2087 49.1127 53.9512C50.9092 53.4402 54.9531 47.1573 55.3439 46.3859C54.6654 45.432 45.9738 41.689 43.1133 41.0501Z"
        fill="#868687"
      />
      <path
        d="M88.4674 41.9937L86.5388 54.3505C80.6379 56.683 68.9241 76.2863 64.5439 78.0449C58.8121 74.7411 46.4016 68.1513 46.4016 68.1513C46.3133 62.3736 46.5352 59.5785 46.5352 59.5785L48.163 57.8905C48.163 57.8905 86.8053 41.5245 88.4674 41.9937Z"
        fill="url(#paint12_linear)"
      />
      <path
        d="M86.5381 54.35C80.6927 56.6606 69.1579 75.8987 64.6699 77.9873C64.9339 76.7344 64.8734 74.0653 64.8734 74.0653C64.8734 74.0653 65.4479 73.0156 64.9606 73.1586L64.9724 71.3912L65.1743 70.2739L66.2905 68.7831L64.9573 69.7731C64.9573 69.7731 64.7057 68.1883 65.1644 66.5022C67.9828 64.2088 81.6937 47.2941 87.3054 42.2289C87.923 42.0408 88.3278 41.9542 88.4667 41.9932L86.5381 54.35Z"
        fill="#8A5321"
      />
      <path
        d="M47.5879 58.4872L48.1631 57.8905C48.1631 57.8905 48.4878 57.7472 49.0718 57.4909C52.7253 59.3358 67.9316 67.1571 67.9316 67.1571L65.1129 68.4941C63.7709 67.6683 49.6237 59.9224 47.5879 58.4872Z"
        fill="#8A5321"
      />
      <path
        d="M89.0146 41.6063C85.5378 39.9382 74.4077 35.2461 71.9966 35C66.7782 37.7387 55.2882 57.6671 46 56.2701C54.0369 61.9749 64.8504 67.9922 64.8504 67.9922C71.7634 63.197 81.1452 45.9447 89.0146 41.6063Z"
        fill="url(#paint13_linear)"
      />
      <path
        d="M82.3581 43.529C81.3018 42.5828 80.8448 41.7006 81.8752 41.2265C77.4296 39.4296 74.2025 37.9488 74.2025 37.9488C73.1729 39.4624 71.9508 39.9502 70.413 39.5776C67.0905 43.3217 58.8762 54.0172 53.4238 55.9598C54.9547 56.8976 53.5638 58.583 53.5638 58.583C56.047 60.26 59.9397 62.2397 62.3398 63.8545C64.445 61.3539 66.6208 63.1645 66.6208 63.1645C67.8998 61.3176 78.5961 48.1383 82.3581 43.529Z"
        fill="url(#paint14_linear)"
      />
      <path
        d="M60.6367 74.3463C60.7475 74.3779 60.8567 74.4087 60.9609 74.4346C60.8903 74.428 60.7775 74.3969 60.6367 74.3463Z"
        fill="#8A5321"
      />
      <path
        d="M59.6653 68.1998C55.7466 66.2611 46.6962 61.4318 46.6962 61.4318L46.5891 62.4507L46.487 65.3552C46.487 65.3552 51.1478 68.5059 51.4662 68.8711C50.278 68.1346 47.0002 66.1439 46.3811 65.7683C46.3776 63.5862 46.4195 61.9934 46.461 60.9712C46.9847 61.1838 57.3432 66.6073 59.6653 68.1998Z"
        fill="#8A5321"
      />
      <path
        d="M46.3921 67.434C46.3878 67.0488 46.3846 66.6756 46.3828 66.3189L46.5015 66.9731C46.5015 66.9731 58.264 73.5189 60.6364 74.3467C56.6608 73.2203 48.1338 68.6206 46.3921 67.434Z"
        fill="#8A5321"
      />
      <path
        d="M73.0726 63.7246C73.0726 62.194 72.9852 60.7296 72.7066 59.9434C72.7066 59.9434 72.65 59.9109 72.5449 59.8504H72.5452C72.6508 59.9112 72.7066 59.9434 72.7066 59.9434C72.9852 60.7296 73.0726 62.194 73.0726 63.7246Z"
        fill="#9A7F1F"
      />
      <path
        d="M72.5446 59.8506C72.544 59.8506 72.5435 59.85 72.543 59.8495C72.5435 59.8495 72.543 59.85 72.543 59.8495C72.5435 59.85 72.5446 59.85 72.5451 59.8506H72.5446Z"
        fill="#A58C1E"
      />
      <path
        d="M72.5431 59.8495C72.5021 59.8261 72.4537 59.7983 72.3984 59.7667C72.3987 59.7667 72.3987 59.7664 72.3987 59.7664C72.4543 59.7983 72.5024 59.8258 72.5431 59.8495C72.5431 59.8498 72.5434 59.8495 72.5431 59.8495Z"
        fill="#9A7F1F"
      />
      <path
        d="M73.694 41.5191C72.7087 42.0253 72.269 43.1347 72.7109 43.9935C73.1534 44.8548 74.3111 45.1425 75.2962 44.6354C76.2809 44.1299 76.7209 43.0206 76.279 42.1598C75.8365 41.2996 74.6791 41.0131 73.694 41.5191Z"
        fill="url(#paint15_linear)"
      />
      <path
        d="M61.8242 55.9668C60.6615 55.8873 59.7031 56.5861 59.6839 57.5258C59.6646 58.4657 60.5925 59.2926 61.7557 59.3707C62.9187 59.4505 63.8773 58.7528 63.8966 57.812C63.9153 56.8715 62.9877 56.046 61.8242 55.9668Z"
        fill="url(#paint16_linear)"
      />
      <path
        d="M67.2793 40.5566C69.3753 38.6239 71.5258 36.733 72.0577 36.4256C72.5893 36.1184 79.8775 38.9754 82.8261 40.0179C80.7218 38.2329 72.9883 35.5184 71.8297 35.8421C70.5468 36.3285 67.5422 40.005 67.2793 40.5566Z"
        fill="#F5DEA4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M67.8319 47.89L69.7069 49.355C70.0568 49.6603 70.2783 50.0868 70.3271 50.5492C70.3758 51.0116 70.2482 51.4757 69.9698 51.8486C69.6913 52.2216 69.2826 52.4759 68.8254 52.5606C68.3682 52.6454 67.8963 52.5543 67.504 52.3056L65.5583 50.8001L64.6317 51.9861L66.5706 53.5009C67.2721 53.9914 68.1355 54.1936 68.9829 54.0658C69.8303 53.9379 70.5971 53.4898 71.1252 52.8138C71.6534 52.1378 71.9027 51.2854 71.8217 50.4322C71.7407 49.5791 71.3357 48.7902 70.69 48.2282L70.6684 48.2113L71.6046 47.0131L71.1252 46.6386L70.1891 47.8368L69.8296 47.556L70.7658 46.3578L70.2864 45.9833L69.3503 47.1815L68.7511 46.7134L67.8319 47.89ZM64.2943 53.6526L65.2306 52.4542L65.7099 52.8287L64.7736 54.027L64.2943 53.6526ZM65.1331 54.3079L66.0694 53.1096L66.5487 53.4841L65.6125 54.6824L65.1331 54.3079Z"
        fill="url(#paint17_linear)"
      />
      <path
        d="M55.9139 52.0113L55.9004 52.0331C58.9884 53.1165 70.4044 59.6563 72.0536 60.6045C74.3432 57.7162 76.7266 54.4957 79.1142 51.5084C76.4192 50.0951 65.0702 45.2375 63.1516 44.3603C60.8817 47.0022 58.4221 49.7673 55.9139 52.0113Z"
        fill="#8A5321"
      />
      <path
        d="M79.505 61.233C79.5342 58.3326 79.5089 51.8107 79.4836 51.1683C76.9008 49.7804 64.3853 44.4358 63.2124 43.869C59.0313 47.0095 56.1543 51.6314 56.1543 51.6314C59.4856 52.8002 72.514 60.3219 72.514 60.3219C73.2645 62.4408 72.6287 69.4824 72.6287 69.4824C72.6287 69.4824 78.3533 63.2911 79.505 61.233Z"
        fill="#494949"
      />
      <path
        d="M72.6286 69.4824C72.6286 69.4824 72.88 66.6972 72.88 64.1032C72.88 62.5725 72.7925 61.1082 72.5139 60.3219C72.5139 60.3219 72.4581 60.2898 72.3525 60.229C72.3522 60.2287 72.3517 60.2287 72.3514 60.2284C72.3107 60.2048 72.2626 60.1773 72.207 60.1454C74.3798 58.9027 78.1841 53.3315 79.4714 51.1617L79.4835 51.1683C79.5008 51.605 79.5179 54.7579 79.5182 57.6434C79.5182 57.6476 79.5182 57.6517 79.5182 57.6558C79.5182 57.6599 79.5182 57.6641 79.5182 57.6682C79.5182 59.0193 79.5143 60.3096 79.505 61.233C78.3533 63.2911 72.6286 69.4824 72.6286 69.4824Z"
        fill="#272729"
      />
      <path
        d="M66.1133 46.0501C67.5884 46.9981 77.0769 50.7359 77.0976 51.3057C77.1174 51.8763 77.1174 51.8763 77.1174 51.8763C77.1174 51.8763 72.9569 58.2087 72.1127 58.9512C73.9092 58.4402 77.9531 52.1573 78.3439 51.3859C77.6654 50.432 68.9738 46.689 66.1133 46.0501Z"
        fill="#868687"
      />
      <path
        d="M52.4674 17.9937L50.5388 30.3505C44.6379 32.683 32.9241 52.2863 28.5439 54.0449C22.8121 50.7411 10.4016 44.1513 10.4016 44.1513C10.3133 38.3736 10.5352 35.5785 10.5352 35.5785L12.163 33.8905C12.163 33.8905 50.8053 17.5245 52.4674 17.9937Z"
        fill="url(#paint18_linear)"
      />
      <path
        d="M50.5381 30.35C44.6927 32.6606 33.1579 51.8987 28.6699 53.9873C28.9339 52.7344 28.8734 50.0653 28.8734 50.0653C28.8734 50.0653 29.4479 49.0156 28.9606 49.1586L28.9724 47.3912L29.1743 46.2739L30.2905 44.7831L28.9573 45.7731C28.9573 45.7731 28.7057 44.1883 29.1644 42.5022C31.9828 40.2088 45.6937 23.2941 51.3054 18.2289C51.923 18.0408 52.3278 17.9542 52.4667 17.9932L50.5381 30.35Z"
        fill="#8A5321"
      />
      <path
        d="M11.5879 34.4872L12.1631 33.8905C12.1631 33.8905 12.4878 33.7472 13.0718 33.4909C16.7253 35.3358 31.9316 43.1571 31.9316 43.1571L29.1129 44.4941C27.7709 43.6683 13.6237 35.9224 11.5879 34.4872Z"
        fill="#8A5321"
      />
      <path
        d="M53.0146 17.6063C49.5378 15.9382 38.4077 11.2461 35.9966 11C30.7782 13.7387 19.2882 33.6671 10 32.2701C18.0369 37.9749 28.8504 43.9922 28.8504 43.9922C35.7634 39.197 45.1452 21.9447 53.0146 17.6063Z"
        fill="url(#paint19_linear)"
      />
      <path
        d="M46.3581 19.529C45.3018 18.5828 44.8448 17.7006 45.8752 17.2265C41.4296 15.4296 38.2025 13.9488 38.2025 13.9488C37.1729 15.4624 35.9508 15.9502 34.413 15.5776C31.0905 19.3217 22.8762 30.0172 17.4238 31.9598C18.9547 32.8976 17.5638 34.583 17.5638 34.583C20.047 36.26 23.9397 38.2397 26.3398 39.8545C28.445 37.3539 30.6208 39.1645 30.6208 39.1645C31.8998 37.3176 42.5961 24.1383 46.3581 19.529Z"
        fill="url(#paint20_linear)"
      />
      <path
        d="M24.6367 50.3463C24.7475 50.3779 24.8567 50.4087 24.9609 50.4346C24.8903 50.428 24.7775 50.3969 24.6367 50.3463Z"
        fill="#8A5321"
      />
      <path
        d="M23.6653 44.1998C19.7466 42.2611 10.6962 37.4318 10.6962 37.4318L10.5891 38.4507L10.487 41.3552C10.487 41.3552 15.1478 44.5059 15.4662 44.8711C14.278 44.1346 11.0002 42.1439 10.3811 41.7683C10.3776 39.5862 10.4195 37.9934 10.461 36.9712C10.9847 37.1838 21.3432 42.6073 23.6653 44.1998Z"
        fill="#8A5321"
      />
      <path
        d="M10.3921 43.434C10.3878 43.0488 10.3846 42.6756 10.3828 42.3189L10.5015 42.9731C10.5015 42.9731 22.264 49.5189 24.6364 50.3467C20.6608 49.2203 12.1338 44.6206 10.3921 43.434Z"
        fill="#8A5321"
      />
      <path
        d="M37.0726 39.7246C37.0726 38.194 36.9852 36.7296 36.7066 35.9434C36.7066 35.9434 36.65 35.9109 36.5449 35.8504H36.5452C36.6508 35.9112 36.7066 35.9434 36.7066 35.9434C36.9852 36.7296 37.0726 38.194 37.0726 39.7246Z"
        fill="#9A7F1F"
      />
      <path
        d="M36.5446 35.8506C36.544 35.8506 36.5435 35.85 36.543 35.8495C36.5435 35.8495 36.543 35.85 36.543 35.8495C36.5435 35.85 36.5446 35.85 36.5451 35.8506H36.5446Z"
        fill="#A58C1E"
      />
      <path
        d="M36.5431 35.8495C36.5021 35.8261 36.4537 35.7983 36.3984 35.7667C36.3987 35.7667 36.3987 35.7664 36.3987 35.7664C36.4543 35.7983 36.5024 35.8258 36.5431 35.8495C36.5431 35.8498 36.5434 35.8495 36.5431 35.8495Z"
        fill="#9A7F1F"
      />
      <path
        d="M37.694 17.5191C36.7087 18.0253 36.269 19.1347 36.7109 19.9935C37.1534 20.8548 38.3111 21.1425 39.2962 20.6354C40.2809 20.1299 40.7209 19.0206 40.279 18.1598C39.8365 17.2996 38.6791 17.0131 37.694 17.5191Z"
        fill="url(#paint21_linear)"
      />
      <path
        d="M25.8242 31.9668C24.6615 31.8873 23.7031 32.5861 23.6839 33.5258C23.6646 34.4657 24.5925 35.2926 25.7557 35.3707C26.9187 35.4505 27.8773 34.7528 27.8966 33.812C27.9153 32.8715 26.9877 32.046 25.8242 31.9668Z"
        fill="url(#paint22_linear)"
      />
      <path
        d="M31.2793 16.5566C33.3753 14.6239 35.5258 12.733 36.0577 12.4256C36.5893 12.1184 43.8775 14.9754 46.8261 16.0179C44.7218 14.2329 36.9883 11.5184 35.8297 11.8421C34.5468 12.3285 31.5422 16.005 31.2793 16.5566Z"
        fill="#F5DEA4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.8319 23.89L33.7069 25.355C34.0568 25.6603 34.2783 26.0868 34.3271 26.5492C34.3758 27.0116 34.2482 27.4757 33.9698 27.8486C33.6913 28.2216 33.2826 28.4759 32.8254 28.5606C32.3682 28.6454 31.8963 28.5543 31.504 28.3056L29.5583 26.8001L28.6317 27.9861L30.5706 29.5009C31.2721 29.9914 32.1355 30.1936 32.9829 30.0658C33.8303 29.9379 34.5971 29.4898 35.1252 28.8138C35.6534 28.1378 35.9027 27.2854 35.8217 26.4322C35.7407 25.5791 35.3357 24.7902 34.69 24.2282L34.6684 24.2113L35.6046 23.0131L35.1252 22.6386L34.1891 23.8368L33.8296 23.556L34.7658 22.3578L34.2864 21.9833L33.3503 23.1815L32.7511 22.7134L31.8319 23.89ZM28.2943 29.6526L29.2306 28.4542L29.7099 28.8287L28.7736 30.027L28.2943 29.6526ZM29.1331 30.3079L30.0694 29.1096L30.5487 29.4841L29.6125 30.6824L29.1331 30.3079Z"
        fill="url(#paint23_linear)"
      />
      <path
        d="M19.9139 28.0113L19.9004 28.0331C22.9884 29.1165 34.4044 35.6563 36.0536 36.6045C38.3432 33.7162 40.7266 30.4957 43.1142 27.5084C40.4192 26.0951 29.0702 21.2375 27.1516 20.3603C24.8817 23.0022 22.4221 25.7673 19.9139 28.0113Z"
        fill="#8A5321"
      />
      <path
        d="M43.505 37.233C43.5342 34.3326 43.5089 27.8107 43.4836 27.1683C40.9008 25.7804 28.3853 20.4358 27.2124 19.869C23.0313 23.0095 20.1543 27.6314 20.1543 27.6314C23.4856 28.8002 36.514 36.3219 36.514 36.3219C37.2645 38.4408 36.6287 45.4824 36.6287 45.4824C36.6287 45.4824 42.3533 39.2911 43.505 37.233Z"
        fill="#494949"
      />
      <path
        d="M36.6286 45.4824C36.6286 45.4824 36.88 42.6972 36.88 40.1032C36.88 38.5725 36.7925 37.1082 36.5139 36.3219C36.5139 36.3219 36.4581 36.2898 36.3525 36.229C36.3522 36.2287 36.3517 36.2287 36.3514 36.2284C36.3107 36.2048 36.2626 36.1773 36.207 36.1454C38.3798 34.9027 42.1841 29.3315 43.4714 27.1617L43.4835 27.1683C43.5008 27.605 43.5179 30.7579 43.5182 33.6434C43.5182 33.6476 43.5182 33.6517 43.5182 33.6558C43.5182 33.6599 43.5182 33.6641 43.5182 33.6682C43.5182 35.0193 43.5143 36.3096 43.505 37.233C42.3533 39.2911 36.6286 45.4824 36.6286 45.4824Z"
        fill="#272729"
      />
      <path
        d="M30.1133 22.0501C31.5884 22.9981 41.0769 26.7359 41.0976 27.3057C41.1174 27.8763 41.1174 27.8763 41.1174 27.8763C41.1174 27.8763 36.9569 34.2087 36.1127 34.9512C37.9092 34.4402 41.9531 28.1573 42.3439 27.3859C41.6654 26.432 32.9738 22.689 30.1133 22.0501Z"
        fill="#868687"
      />
      <path
        d="M77.4674 24.9937L75.5388 37.3505C69.6379 39.683 57.9241 59.2863 53.5439 61.0449C47.8121 57.7411 35.4016 51.1513 35.4016 51.1513C35.3133 45.3736 35.5352 42.5785 35.5352 42.5785L37.163 40.8905C37.163 40.8905 75.8053 24.5245 77.4674 24.9937Z"
        fill="url(#paint24_linear)"
      />
      <path
        d="M75.5381 37.35C69.6927 39.6606 58.1579 58.8987 53.6699 60.9873C53.9339 59.7344 53.8734 57.0653 53.8734 57.0653C53.8734 57.0653 54.4479 56.0156 53.9606 56.1586L53.9724 54.3912L54.1743 53.2739L55.2905 51.7831L53.9573 52.7731C53.9573 52.7731 53.7057 51.1883 54.1644 49.5022C56.9828 47.2088 70.6937 30.2941 76.3054 25.2289C76.923 25.0408 77.3278 24.9542 77.4667 24.9932L75.5381 37.35Z"
        fill="#8A5321"
      />
      <path
        d="M36.5879 41.4872L37.1631 40.8905C37.1631 40.8905 37.4878 40.7472 38.0718 40.4909C41.7253 42.3358 56.9316 50.1571 56.9316 50.1571L54.1129 51.4941C52.7709 50.6683 38.6237 42.9224 36.5879 41.4872Z"
        fill="#8A5321"
      />
      <path
        d="M78.0146 24.6063C74.5378 22.9382 63.4077 18.2461 60.9966 18C55.7782 20.7387 44.2882 40.6671 35 39.2701C43.0369 44.9749 53.8504 50.9922 53.8504 50.9922C60.7634 46.197 70.1452 28.9447 78.0146 24.6063Z"
        fill="url(#paint25_linear)"
      />
      <path
        d="M71.3581 26.529C70.3018 25.5828 69.8448 24.7006 70.8752 24.2265C66.4296 22.4296 63.2025 20.9488 63.2025 20.9488C62.1729 22.4624 60.9508 22.9502 59.413 22.5776C56.0905 26.3217 47.8762 37.0172 42.4238 38.9598C43.9547 39.8976 42.5638 41.583 42.5638 41.583C45.047 43.26 48.9397 45.2397 51.3398 46.8545C53.445 44.3539 55.6208 46.1645 55.6208 46.1645C56.8998 44.3176 67.5961 31.1383 71.3581 26.529Z"
        fill="url(#paint26_linear)"
      />
      <path
        d="M49.6367 57.3463C49.7475 57.3779 49.8567 57.4087 49.9609 57.4346C49.8903 57.428 49.7775 57.3969 49.6367 57.3463Z"
        fill="#8A5321"
      />
      <path
        d="M48.6653 51.1998C44.7466 49.2611 35.6962 44.4318 35.6962 44.4318L35.5891 45.4507L35.487 48.3552C35.487 48.3552 40.1478 51.5059 40.4662 51.8711C39.278 51.1346 36.0002 49.1439 35.3811 48.7683C35.3776 46.5862 35.4195 44.9934 35.461 43.9712C35.9847 44.1838 46.3432 49.6073 48.6653 51.1998Z"
        fill="#8A5321"
      />
      <path
        d="M35.3921 50.434C35.3878 50.0488 35.3846 49.6756 35.3828 49.3189L35.5015 49.9731C35.5015 49.9731 47.264 56.5189 49.6364 57.3467C45.6608 56.2203 37.1338 51.6206 35.3921 50.434Z"
        fill="#8A5321"
      />
      <path
        d="M62.0726 46.7246C62.0726 45.194 61.9852 43.7296 61.7066 42.9434C61.7066 42.9434 61.65 42.9109 61.5449 42.8504H61.5452C61.6508 42.9112 61.7066 42.9434 61.7066 42.9434C61.9852 43.7296 62.0726 45.194 62.0726 46.7246Z"
        fill="#9A7F1F"
      />
      <path
        d="M61.5446 42.8506C61.544 42.8506 61.5435 42.85 61.543 42.8495C61.5435 42.8495 61.543 42.85 61.543 42.8495C61.5435 42.85 61.5446 42.85 61.5451 42.8506H61.5446Z"
        fill="#A58C1E"
      />
      <path
        d="M61.5431 42.8495C61.5021 42.8261 61.4537 42.7983 61.3984 42.7667C61.3987 42.7667 61.3987 42.7664 61.3987 42.7664C61.4543 42.7983 61.5024 42.8258 61.5431 42.8495C61.5431 42.8498 61.5434 42.8495 61.5431 42.8495Z"
        fill="#9A7F1F"
      />
      <path
        d="M62.694 24.5191C61.7087 25.0253 61.269 26.1347 61.7109 26.9935C62.1534 27.8548 63.3111 28.1425 64.2962 27.6354C65.2809 27.1299 65.7209 26.0206 65.279 25.1598C64.8365 24.2996 63.6791 24.0131 62.694 24.5191Z"
        fill="url(#paint27_linear)"
      />
      <path
        d="M50.8242 38.9668C49.6615 38.8873 48.7031 39.5861 48.6839 40.5258C48.6646 41.4657 49.5925 42.2926 50.7557 42.3707C51.9187 42.4505 52.8773 41.7528 52.8966 40.812C52.9153 39.8715 51.9877 39.046 50.8242 38.9668Z"
        fill="url(#paint28_linear)"
      />
      <path
        d="M56.2793 23.5566C58.3753 21.6239 60.5258 19.733 61.0577 19.4256C61.5893 19.1184 68.8775 21.9754 71.8261 23.0179C69.7218 21.2329 61.9883 18.5184 60.8297 18.8421C59.5468 19.3285 56.5422 23.005 56.2793 23.5566Z"
        fill="#F5DEA4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M56.8319 30.89L58.7069 32.355C59.0568 32.6603 59.2783 33.0868 59.3271 33.5492C59.3758 34.0116 59.2482 34.4757 58.9698 34.8486C58.6913 35.2216 58.2826 35.4759 57.8254 35.5606C57.3682 35.6454 56.8963 35.5543 56.504 35.3056L54.5583 33.8001L53.6317 34.9861L55.5706 36.5009C56.2721 36.9914 57.1355 37.1936 57.9829 37.0658C58.8303 36.9379 59.5971 36.4898 60.1252 35.8138C60.6534 35.1378 60.9027 34.2854 60.8217 33.4322C60.7407 32.5791 60.3357 31.7902 59.69 31.2282L59.6684 31.2113L60.6046 30.0131L60.1252 29.6386L59.1891 30.8368L58.8296 30.556L59.7658 29.3578L59.2864 28.9833L58.3503 30.1815L57.7511 29.7134L56.8319 30.89ZM53.2943 36.6526L54.2306 35.4542L54.7099 35.8287L53.7736 37.027L53.2943 36.6526ZM54.1331 37.3079L55.0694 36.1096L55.5487 36.4841L54.6125 37.6824L54.1331 37.3079Z"
        fill="url(#paint29_linear)"
      />
      <path
        d="M44.9139 35.0113L44.9004 35.0331C47.9884 36.1165 59.4044 42.6563 61.0536 43.6045C63.3432 40.7162 65.7266 37.4957 68.1142 34.5084C65.4192 33.0951 54.0702 28.2375 52.1516 27.3603C49.8817 30.0022 47.4221 32.7673 44.9139 35.0113Z"
        fill="#8A5321"
      />
      <path
        d="M68.505 44.233C68.5342 41.3326 68.5089 34.8107 68.4836 34.1683C65.9008 32.7804 53.3853 27.4358 52.2124 26.869C48.0313 30.0095 45.1543 34.6314 45.1543 34.6314C48.4856 35.8002 61.514 43.3219 61.514 43.3219C62.2645 45.4408 61.6287 52.4824 61.6287 52.4824C61.6287 52.4824 67.3533 46.2911 68.505 44.233Z"
        fill="#494949"
      />
      <path
        d="M61.6286 52.4824C61.6286 52.4824 61.88 49.6972 61.88 47.1032C61.88 45.5725 61.7925 44.1082 61.5139 43.3219C61.5139 43.3219 61.4581 43.2898 61.3525 43.229C61.3522 43.2287 61.3517 43.2287 61.3514 43.2284C61.3107 43.2048 61.2626 43.1773 61.207 43.1454C63.3798 41.9027 67.1841 36.3315 68.4714 34.1617L68.4835 34.1683C68.5008 34.605 68.5179 37.7579 68.5182 40.6434C68.5182 40.6476 68.5182 40.6517 68.5182 40.6558C68.5182 40.6599 68.5182 40.6641 68.5182 40.6682C68.5182 42.0193 68.5143 43.3096 68.505 44.233C67.3533 46.2911 61.6286 52.4824 61.6286 52.4824Z"
        fill="#272729"
      />
      <path
        d="M55.1133 29.0501C56.5884 29.9981 66.0769 33.7359 66.0976 34.3057C66.1174 34.8763 66.1174 34.8763 66.1174 34.8763C66.1174 34.8763 61.9569 41.2087 61.1127 41.9512C62.9092 41.4402 66.9531 35.1573 67.3439 34.3859C66.6654 33.432 57.9738 29.689 55.1133 29.0501Z"
        fill="#868687"
      />
      <path
        d="M68.4674 6.99366L66.5388 19.3505C60.6379 21.683 48.9241 41.2863 44.5439 43.0449C38.8121 39.7411 26.4016 33.1513 26.4016 33.1513C26.3133 27.3736 26.5352 24.5785 26.5352 24.5785L28.163 22.8905C28.163 22.8905 66.8053 6.52451 68.4674 6.99366Z"
        fill="url(#paint30_linear)"
      />
      <path
        d="M66.5381 19.35C60.6927 21.6606 49.1579 40.8987 44.6699 42.9873C44.9339 41.7344 44.8734 39.0653 44.8734 39.0653C44.8734 39.0653 45.4479 38.0156 44.9606 38.1586L44.9724 36.3912L45.1743 35.2739L46.2905 33.7831L44.9573 34.7731C44.9573 34.7731 44.7057 33.1883 45.1644 31.5022C47.9828 29.2088 61.6937 12.2941 67.3054 7.22891C67.923 7.04081 68.3278 6.95418 68.4667 6.99323L66.5381 19.35Z"
        fill="#8A5321"
      />
      <path
        d="M27.5879 23.4872L28.1631 22.8905C28.1631 22.8905 28.4878 22.7472 29.0718 22.4909C32.7253 24.3358 47.9316 32.1571 47.9316 32.1571L45.1129 33.4941C43.7709 32.6683 29.6237 24.9224 27.5879 23.4872Z"
        fill="#8A5321"
      />
      <path
        d="M69.0146 6.60631C65.5378 4.93816 54.4077 0.246132 51.9966 7.62939e-06C46.7782 2.73872 35.2882 22.6671 26 21.2701C34.0369 26.9749 44.8504 32.9922 44.8504 32.9922C51.7634 28.197 61.1452 10.9447 69.0146 6.60631Z"
        fill="url(#paint31_linear)"
      />
      <path
        d="M62.3581 8.52905C61.3018 7.58278 60.8448 6.70058 61.8752 6.22648C57.4296 4.42964 54.2025 2.94877 54.2025 2.94877C53.1729 4.46236 51.9508 4.95021 50.413 4.57759C47.0905 8.3217 38.8762 19.0172 33.4238 20.9598C34.9547 21.8976 33.5638 23.583 33.5638 23.583C36.047 25.26 39.9397 27.2397 42.3398 28.8545C44.445 26.3539 46.6208 28.1645 46.6208 28.1645C47.8998 26.3176 58.5961 13.1383 62.3581 8.52905Z"
        fill="url(#paint32_linear)"
      />
      <path
        d="M40.6367 39.3463C40.7475 39.3779 40.8567 39.4087 40.9609 39.4346C40.8903 39.428 40.7775 39.3969 40.6367 39.3463Z"
        fill="#8A5321"
      />
      <path
        d="M39.6653 33.1998C35.7466 31.2611 26.6962 26.4318 26.6962 26.4318L26.5891 27.4507L26.487 30.3552C26.487 30.3552 31.1478 33.5059 31.4662 33.8711C30.278 33.1346 27.0002 31.1439 26.3811 30.7683C26.3776 28.5862 26.4195 26.9934 26.461 25.9712C26.9847 26.1838 37.3432 31.6073 39.6653 33.1998Z"
        fill="#8A5321"
      />
      <path
        d="M26.3921 32.434C26.3878 32.0488 26.3846 31.6756 26.3828 31.3189L26.5015 31.9731C26.5015 31.9731 38.264 38.5189 40.6364 39.3467C36.6608 38.2203 28.1338 33.6206 26.3921 32.434Z"
        fill="#8A5321"
      />
      <path
        d="M53.0726 28.7246C53.0726 27.194 52.9852 25.7296 52.7066 24.9434C52.7066 24.9434 52.65 24.9109 52.5449 24.8504H52.5452C52.6508 24.9112 52.7066 24.9434 52.7066 24.9434C52.9852 25.7296 53.0726 27.194 53.0726 28.7246Z"
        fill="#9A7F1F"
      />
      <path
        d="M52.5446 24.8506C52.544 24.8506 52.5435 24.85 52.543 24.8495C52.5435 24.8495 52.543 24.85 52.543 24.8495C52.5435 24.85 52.5446 24.85 52.5451 24.8506H52.5446Z"
        fill="#A58C1E"
      />
      <path
        d="M52.5431 24.8495C52.5021 24.8261 52.4537 24.7983 52.3984 24.7667C52.3987 24.7667 52.3987 24.7664 52.3987 24.7664C52.4543 24.7983 52.5024 24.8258 52.5431 24.8495C52.5431 24.8498 52.5434 24.8495 52.5431 24.8495Z"
        fill="#9A7F1F"
      />
      <path
        d="M53.694 6.51907C52.7087 7.02535 52.269 8.13469 52.7109 8.99351C53.1534 9.85481 54.3111 10.1425 55.2962 9.63537C56.2809 9.12992 56.7209 8.02058 56.279 7.15983C55.8365 6.29963 54.6791 6.01307 53.694 6.51907Z"
        fill="url(#paint33_linear)"
      />
      <path
        d="M41.8242 20.9668C40.6615 20.8873 39.7031 21.5861 39.6839 22.5258C39.6646 23.4657 40.5925 24.2926 41.7557 24.3707C42.9187 24.4505 43.8773 23.7528 43.8966 22.812C43.9153 21.8715 42.9877 21.046 41.8242 20.9668Z"
        fill="url(#paint34_linear)"
      />
      <path
        d="M47.2793 5.55664C49.3753 3.62395 51.5258 1.73305 52.0577 1.4256C52.5893 1.11843 59.8775 3.9754 62.8261 5.01792C60.7218 3.2329 52.9883 0.518386 51.8297 0.84206C50.5468 1.32853 47.5422 5.00499 47.2793 5.55664Z"
        fill="#F5DEA4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.8319 12.89L49.7069 14.355C50.0568 14.6603 50.2783 15.0868 50.3271 15.5492C50.3758 16.0116 50.2482 16.4757 49.9698 16.8486C49.6913 17.2216 49.2826 17.4759 48.8254 17.5606C48.3682 17.6454 47.8963 17.5543 47.504 17.3056L45.5583 15.8001L44.6317 16.9861L46.5706 18.5009C47.2721 18.9914 48.1355 19.1936 48.9829 19.0658C49.8303 18.9379 50.5971 18.4898 51.1252 17.8138C51.6534 17.1378 51.9027 16.2854 51.8217 15.4322C51.7407 14.5791 51.3357 13.7902 50.69 13.2282L50.6684 13.2113L51.6046 12.0131L51.1252 11.6386L50.1891 12.8368L49.8296 12.556L50.7658 11.3578L50.2864 10.9833L49.3503 12.1815L48.7511 11.7134L47.8319 12.89ZM44.2943 18.6526L45.2306 17.4542L45.7099 17.8287L44.7736 19.027L44.2943 18.6526ZM45.1331 19.3079L46.0694 18.1096L46.5487 18.4841L45.6125 19.6824L45.1331 19.3079Z"
        fill="url(#paint35_linear)"
      />
      <path
        d="M35.9139 17.0113L35.9004 17.0331C38.9884 18.1165 50.4044 24.6563 52.0536 25.6045C54.3432 22.7162 56.7266 19.4957 59.1142 16.5084C56.4192 15.0951 45.0702 10.2375 43.1516 9.3603C40.8817 12.0022 38.4221 14.7673 35.9139 17.0113Z"
        fill="#8A5321"
      />
      <path
        d="M59.505 26.233C59.5342 23.3326 59.5089 16.8107 59.4836 16.1683C56.9008 14.7804 44.3853 9.4358 43.2124 8.86902C39.0313 12.0095 36.1543 16.6314 36.1543 16.6314C39.4856 17.8002 52.514 25.3219 52.514 25.3219C53.2645 27.4408 52.6287 34.4824 52.6287 34.4824C52.6287 34.4824 58.3533 28.2911 59.505 26.233Z"
        fill="#494949"
      />
      <path
        d="M52.6286 34.4824C52.6286 34.4824 52.88 31.6972 52.88 29.1032C52.88 27.5725 52.7925 26.1082 52.5139 25.3219C52.5139 25.3219 52.4581 25.2898 52.3525 25.229C52.3522 25.2287 52.3517 25.2287 52.3514 25.2284C52.3107 25.2048 52.2626 25.1773 52.207 25.1454C54.3798 23.9027 58.1841 18.3315 59.4714 16.1617L59.4835 16.1683C59.5008 16.605 59.5179 19.7579 59.5182 22.6434C59.5182 22.6476 59.5182 22.6517 59.5182 22.6558C59.5182 22.6599 59.5182 22.6641 59.5182 22.6682C59.5182 24.0193 59.5143 25.3096 59.505 26.233C58.3533 28.2911 52.6286 34.4824 52.6286 34.4824Z"
        fill="#272729"
      />
      <path
        d="M46.1133 11.0501C47.5884 11.9981 57.0769 15.7359 57.0976 16.3057C57.1174 16.8763 57.1174 16.8763 57.1174 16.8763C57.1174 16.8763 52.9569 23.2087 52.1127 23.9512C53.9092 23.4402 57.9531 17.1573 58.3439 16.3859C57.6654 15.432 48.9738 11.689 46.1133 11.0501Z"
        fill="#868687"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="21.4241"
          y1="68.0449"
          x2="21.4241"
          y2="31.9838"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDB913" />
          <stop offset="1" stopColor="#F2994A" />
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="21.5073"
          y1="57.9922"
          x2="21.5073"
          y2="25"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDB913" />
          <stop offset="1" stopColor="#F2994A" />
        </linearGradient>
        <linearGradient
          id="paint2_linear"
          x1="21.891"
          y1="53.8545"
          x2="21.891"
          y2="27.9488"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FACB5B" />
          <stop offset="1" stopColor="#F5DEA4" />
        </linearGradient>
        <linearGradient
          id="paint3_linear"
          x1="28.4949"
          y1="34.8857"
          x2="28.4949"
          y2="31.2691"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDB913" />
          <stop offset="1" stopColor="#F2994A" />
        </linearGradient>
        <linearGradient
          id="paint4_linear"
          x1="15.7902"
          y1="49.377"
          x2="15.7902"
          y2="45.9606"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDB913" />
          <stop offset="1" stopColor="#F2994A" />
        </linearGradient>
        <linearGradient
          id="paint5_linear"
          x1="14.1725"
          y1="49.2402"
          x2="33.1586"
          y2="31.3547"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDB913" />
          <stop offset="0.984375" stopColor="#F2994A" />
        </linearGradient>
        <linearGradient
          id="paint6_linear"
          x1="44.4241"
          y1="73.0449"
          x2="44.4241"
          y2="36.9838"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDB913" />
          <stop offset="1" stopColor="#F2994A" />
        </linearGradient>
        <linearGradient
          id="paint7_linear"
          x1="44.5073"
          y1="62.9922"
          x2="44.5073"
          y2="30"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDB913" />
          <stop offset="1" stopColor="#F2994A" />
        </linearGradient>
        <linearGradient
          id="paint8_linear"
          x1="44.891"
          y1="58.8545"
          x2="44.891"
          y2="32.9488"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FACB5B" />
          <stop offset="1" stopColor="#F5DEA4" />
        </linearGradient>
        <linearGradient
          id="paint9_linear"
          x1="51.4949"
          y1="39.8857"
          x2="51.4949"
          y2="36.2691"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDB913" />
          <stop offset="1" stopColor="#F2994A" />
        </linearGradient>
        <linearGradient
          id="paint10_linear"
          x1="38.7902"
          y1="54.377"
          x2="38.7902"
          y2="50.9606"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDB913" />
          <stop offset="1" stopColor="#F2994A" />
        </linearGradient>
        <linearGradient
          id="paint11_linear"
          x1="37.1725"
          y1="54.2402"
          x2="56.1586"
          y2="36.3547"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDB913" />
          <stop offset="0.984375" stopColor="#F2994A" />
        </linearGradient>
        <linearGradient
          id="paint12_linear"
          x1="67.4241"
          y1="78.0449"
          x2="67.4241"
          y2="41.9838"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDB913" />
          <stop offset="1" stopColor="#F2994A" />
        </linearGradient>
        <linearGradient
          id="paint13_linear"
          x1="67.5073"
          y1="67.9922"
          x2="67.5073"
          y2="35"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDB913" />
          <stop offset="1" stopColor="#F2994A" />
        </linearGradient>
        <linearGradient
          id="paint14_linear"
          x1="67.891"
          y1="63.8545"
          x2="67.891"
          y2="37.9488"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FACB5B" />
          <stop offset="1" stopColor="#F5DEA4" />
        </linearGradient>
        <linearGradient
          id="paint15_linear"
          x1="74.4949"
          y1="44.8857"
          x2="74.4949"
          y2="41.2691"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDB913" />
          <stop offset="1" stopColor="#F2994A" />
        </linearGradient>
        <linearGradient
          id="paint16_linear"
          x1="61.7902"
          y1="59.377"
          x2="61.7902"
          y2="55.9606"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDB913" />
          <stop offset="1" stopColor="#F2994A" />
        </linearGradient>
        <linearGradient
          id="paint17_linear"
          x1="60.1725"
          y1="59.2402"
          x2="79.1586"
          y2="41.3547"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDB913" />
          <stop offset="0.984375" stopColor="#F2994A" />
        </linearGradient>
        <linearGradient
          id="paint18_linear"
          x1="31.4241"
          y1="54.0449"
          x2="31.4241"
          y2="17.9838"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDB913" />
          <stop offset="1" stopColor="#F2994A" />
        </linearGradient>
        <linearGradient
          id="paint19_linear"
          x1="31.5073"
          y1="43.9922"
          x2="31.5073"
          y2="11"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDB913" />
          <stop offset="1" stopColor="#F2994A" />
        </linearGradient>
        <linearGradient
          id="paint20_linear"
          x1="31.891"
          y1="39.8545"
          x2="31.891"
          y2="13.9488"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FACB5B" />
          <stop offset="1" stopColor="#F5DEA4" />
        </linearGradient>
        <linearGradient
          id="paint21_linear"
          x1="38.4949"
          y1="20.8857"
          x2="38.4949"
          y2="17.2691"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDB913" />
          <stop offset="1" stopColor="#F2994A" />
        </linearGradient>
        <linearGradient
          id="paint22_linear"
          x1="25.7902"
          y1="35.377"
          x2="25.7902"
          y2="31.9606"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDB913" />
          <stop offset="1" stopColor="#F2994A" />
        </linearGradient>
        <linearGradient
          id="paint23_linear"
          x1="24.1725"
          y1="35.2402"
          x2="43.1586"
          y2="17.3547"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDB913" />
          <stop offset="0.984375" stopColor="#F2994A" />
        </linearGradient>
        <linearGradient
          id="paint24_linear"
          x1="56.4241"
          y1="61.0449"
          x2="56.4241"
          y2="24.9838"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDB913" />
          <stop offset="1" stopColor="#F2994A" />
        </linearGradient>
        <linearGradient
          id="paint25_linear"
          x1="56.5073"
          y1="50.9922"
          x2="56.5073"
          y2="18"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDB913" />
          <stop offset="1" stopColor="#F2994A" />
        </linearGradient>
        <linearGradient
          id="paint26_linear"
          x1="56.891"
          y1="46.8545"
          x2="56.891"
          y2="20.9488"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FACB5B" />
          <stop offset="1" stopColor="#F5DEA4" />
        </linearGradient>
        <linearGradient
          id="paint27_linear"
          x1="63.4949"
          y1="27.8857"
          x2="63.4949"
          y2="24.2691"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDB913" />
          <stop offset="1" stopColor="#F2994A" />
        </linearGradient>
        <linearGradient
          id="paint28_linear"
          x1="50.7902"
          y1="42.377"
          x2="50.7902"
          y2="38.9606"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDB913" />
          <stop offset="1" stopColor="#F2994A" />
        </linearGradient>
        <linearGradient
          id="paint29_linear"
          x1="49.1725"
          y1="42.2402"
          x2="68.1586"
          y2="24.3547"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDB913" />
          <stop offset="0.984375" stopColor="#F2994A" />
        </linearGradient>
        <linearGradient
          id="paint30_linear"
          x1="47.4241"
          y1="43.0449"
          x2="47.4241"
          y2="6.98376"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDB913" />
          <stop offset="1" stopColor="#F2994A" />
        </linearGradient>
        <linearGradient
          id="paint31_linear"
          x1="47.5073"
          y1="32.9922"
          x2="47.5073"
          y2="7.62939e-06"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDB913" />
          <stop offset="1" stopColor="#F2994A" />
        </linearGradient>
        <linearGradient
          id="paint32_linear"
          x1="47.891"
          y1="28.8545"
          x2="47.891"
          y2="2.94877"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FACB5B" />
          <stop offset="1" stopColor="#F5DEA4" />
        </linearGradient>
        <linearGradient
          id="paint33_linear"
          x1="54.4949"
          y1="9.88574"
          x2="54.4949"
          y2="6.26915"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDB913" />
          <stop offset="1" stopColor="#F2994A" />
        </linearGradient>
        <linearGradient
          id="paint34_linear"
          x1="41.7902"
          y1="24.377"
          x2="41.7902"
          y2="20.9606"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDB913" />
          <stop offset="1" stopColor="#F2994A" />
        </linearGradient>
        <linearGradient
          id="paint35_linear"
          x1="40.1725"
          y1="24.2402"
          x2="59.1586"
          y2="6.35473"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDB913" />
          <stop offset="0.984375" stopColor="#F2994A" />
        </linearGradient>
      </defs>
    </svg>
  );
}

SvgMoneyBag.propTypes = {
  fill: PropTypes.string,
};

SvgMoneyBag.defaultProps = {
  fill: '#fcb316',
};

export default SvgMoneyBag;
