import {
  AlbumIcon2,
  ArtistIcon,
  ExploreIcon,
  HomeIcon,
  LivestreamIcon,
  LogoIcon,
  PlaylistIcon,
  PremiumIcon,
  SearchIcon2,
  SongIcon,
} from 'components/icons';
import { NavLink, useHistory, Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { Typography } from 'components/atoms';
import { css } from '@emotion/core';
import styled from '@emotion/styled/macro';
import { useSelector } from 'react-redux';
import { useMedia } from 'react-use';
import { useBreakpoints, useUser } from 'hooks';
import { goToGames } from 'lib/utils';

const Wrapper = styled.div`
  -ms-overflow-style: none;
  background-color: #030303;
  height: calc(100vh - 6rem);
  left: -100%;
  overflow-y: auto;
  // padding: 3rem;
  position: fixed;
  top: 0rem;
  transition: all 0.8s;
  width: 50%;
  height: 100vh;
  z-index: 4; 
  &::-webkit-scrollbar {
    display: none;
  } 

  ${({ playerVisible }) =>
    playerVisible &&
    css`
      height: calc(100vh - 8rem);
    `}

    ${({ theme }) => theme.mq.sm`    
    width: 18rem;
    `}

  ${({ theme }) => theme.mq.lg`
  left: -22rem;
  width: 18rem;
  `}

  ${({ active, theme }) =>
    active &&
    css`
      left: 0;

      ${theme.mq.lg`
        left: 0;
      `}
    `}
`;

const List = styled.ul`
  position: relative;
  & + & {
    margin-top: 4rem;
  }

  hr {
    border: 0;
    width: 1px;
    height: 87vh;
    position: absolute;
    right: 0;
    background-image: linear-gradient(
      to top,
      rgba(112, 112, 112, 0.2),
      rgba(112, 112, 112, 0.75),
      rgba(112, 112, 112, 0.75),
      rgba(112, 112, 112, 0.2)
    );

    @media (max-width: 1024px) {
      height: 80vh;
    }
  }
`;

const ListItem = styled.li`
  &:not(:last-child) {
    // margin-bottom: 3rem;
  }
`;

const ListTitle = styled(Typography.Paragraph)`
  color: ${({ theme }) => theme.colors.sidebarText};
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  padding: 1rem 3rem;
`;

const ListLink = styled(Typography.Paragraph)`
  font-family: BwModelicaSS02, sans-serif;
  cursor: pointer;
  display: inline-flex;
  line-height: 2rem;
  text-decoration: none;
  transition: all 0.2s;
  user-select: none;
  color: #868687 !important;
  padding: 1rem 3rem;
  margin-bottom: 0rem;
  font-weight: 800;
  text-transform: uppercase;
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 1.2rem !important;
  &.active {
    &,
    &:visited {
      // color: white !important;
      // background: -webkit-linear-gradient(91.02deg, #ffc83e 0.02%, #aa6121 67.98%);
      // -webkit-text-fill-color: transparent;
      // -webkit-background-clip: text;
      color: ${({ theme }) => theme.colors.primary} !important;
    }
  }

  &:hover {
    background-color: #131516;
    border-radius: 4px;
  }

  svg {
    height: 1.5rem;
    margin-right: 1rem;
    width: 1.5rem;
  }
  @media (max-width: ${props => props.theme.breakpoints[0]}) {
    font-size: ${props => props.theme.font.size.tiny} !important;
    svg {
      height: 1.3rem;
      width: 1.3rem;
    }
  }
`;

const Logo = styled(LogoIcon)`
  margin: 2rem 0 0rem 0;
  cursor: pointer;
  width: 10rem;
  @media (max-width: 450px) {
    width: 8rem;
  }
`;

ListTitle.defaultProps = {
  size: 'normal',
};

ListLink.defaultProps = {
  as: NavLink,
  size: 'medium',
  weight: 'bold',
  color: 'switchingText',
  to: '#',
};

export const Sidebar = memo(({ active, closeSidebar, loggedIn, playerVisible, showTheSideBar }) => {
  const [showGradient, setShowGradient] = React.useState(true);
  const { user } = useUser();
  const currentUser = useSelector(state => state.profile.user);
  const { subscription } = currentUser;
  const [userSub, setUserSub] = React.useState(false);
  const premium = subscription?.plan?.title;
  // const isWide = useMedia('(max-width: 1160px)');
  const logo = useMedia('(max-width: 1160px)');
  const hist = useHistory();
  const path = hist.location.pathname.split('/')[1];
  const pathName = hist.location.pathname;
  const [xs, md, lg] = useBreakpoints();

  React.useEffect(() => {
    if (premium?.includes('Premium') || premium?.includes('premium')) {
      setUserSub(true);
    } else {
      setUserSub(false);
    }
    // eslint-disable-next-line
  }, []);

  // const onClick = () => {
  //   closeSidebar();
  // };

  const onClick = () => {
    if (xs || md || lg) {
      closeSidebar();
    }
  };

  React.useEffect(() => {
    if (path === 'videos') {
      closeSidebar();
    }
    // eslint-disable-next-line
  }, [path]);

  const { pathname } = useLocation();
  React.useEffect(() => {
    setShowGradient(pathname === '/');
    // eslint-disable-next-line
  }, [pathname]);

  return (
    <Wrapper
      active={active}
      playerVisible={playerVisible}
      as="aside"
      showGradient={showGradient}
      style={{
        height: path === 'livestreams' ? '100vh' : null,
        overflowY: 'scroll',
      }}
    >
      <List>
        <ListItem>
          <Link
            to="/"
            style={{ display: 'flex', padding: logo ? '5rem 3rem 2rem' : '1.3rem 3rem 3rem' }}
            aria-label="Home link"
          >
            <Logo />
          </Link>
        </ListItem>
        <hr />

        <ListItem style={{ display: user === null ? 'none' : 'block' }}>
          <ListLink to="/" exact onClick={onClick}>
            {pathName === '/' ? <HomeIcon /> : <HomeIcon fill="#868687" />}
            Home
          </ListLink>
        </ListItem>

        <ListItem>
          <ListLink to="/explore" onClick={onClick}>
            {pathName === '/explore' ? <ExploreIcon /> : <ExploreIcon fill="#868687" />}
            Explore
          </ListLink>
        </ListItem>

        {/* <ListItem>
          <ListLink to="/livestream" onClick={onClick}>
            {pathName === '/livestream' ? <LivestreamIcon /> : <LivestreamIcon fill="#868687" />}
            Livestreams
          </ListLink>
        </ListItem> */}

        <ListItem>
          <ListLink to="/search" onClick={onClick}>
            {pathName === '/search' ? <SearchIcon2 /> : <SearchIcon2 fill="#868687" />}
            Search
          </ListLink>
        </ListItem>

        {/* {!userSub && (
          <ListItem>
            <ListLink to="/premium" onClick={onClick}>
              {pathName === '/premium' ? <PremiumIcon /> : <PremiumIcon fill="#868687" />}
              Premium
            </ListLink>
          </ListItem>
        )} */}
      </List>

      {loggedIn && (
        <List>
          <ListItem>
            <ListTitle style={{ opacity: '0.7', fontWeight: '900' }}>Library</ListTitle>
          </ListItem>

          <ListItem>
            <ListLink to="/library/me/favorites/tracks" onClick={onClick}>
              {pathName === '/library/me/favorites/tracks' ? <SongIcon /> : <SongIcon fill="#868687" />}
              Songs
            </ListLink>
          </ListItem>

          <ListItem>
            <ListLink to="/library/me/favorites/albums" onClick={onClick}>
              {pathName === '/library/me/favorites/albums' ? <AlbumIcon2 /> : <AlbumIcon2 fill="#868687" />}
              Albums
            </ListLink>
          </ListItem>

          <ListItem>
            <ListLink to="/library/me/favorites/artists" onClick={onClick}>
              {pathName === '/library/me/favorites/artists' ? <ArtistIcon /> : <ArtistIcon fill="#868687" />}
              Artists
            </ListLink>
          </ListItem>

          <ListItem>
            <ListLink to="/library/me/favorites/playlists" onClick={onClick}>
              {pathName === '/library/me/favorites/playlists' ? <PlaylistIcon /> : <PlaylistIcon fill="#868687" />}
              Playlists
            </ListLink>
          </ListItem>
          <ListItem onClick={goToGames}>
            <ListLink onClick={onClick}>MTN Games</ListLink>
          </ListItem>
        </List>
      )}
    </Wrapper>
  );
});

Sidebar.propTypes = {
  active: PropTypes.bool.isRequired,
  closeSidebar: PropTypes.func.isRequired,
  loggedIn: PropTypes.bool.isRequired,
  playerVisible: PropTypes.bool.isRequired,
};
