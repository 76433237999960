import React from 'react';
import PropTypes from 'prop-types';

function SvgQueue({ fill, ...props }) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M4.38462 4.69231C4.38462 5.62694 3.62694 6.38462 2.69231 6.38462C1.75767 6.38462 1 5.62694 1 4.69231C1 3.75767 1.75767 3 2.69231 3C3.62694 3 4.38462 3.75767 4.38462 4.69231Z"
        stroke={fill}
      />
      <path
        d="M4.38462 12.3075C4.38462 13.2422 3.62694 13.9998 2.69231 13.9998C1.75767 13.9998 1 13.2422 1 12.3075C1 11.3729 1.75767 10.6152 2.69231 10.6152C3.62694 10.6152 4.38462 11.3729 4.38462 12.3075Z"
        stroke={fill}
      />
      <path
        d="M4.38462 19.9228C4.38462 20.8574 3.62694 21.6151 2.69231 21.6151C1.75767 21.6151 1 20.8574 1 19.9228C1 18.9881 1.75767 18.2305 2.69231 18.2305C3.62694 18.2305 4.38462 18.9881 4.38462 19.9228Z"
        stroke={fill}
      />
      <path d="M8.61539 19.9238H23" stroke={fill} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8.61538 12.3086L23 12.3086" stroke={fill} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8.61539 4.69141L23 4.69141" stroke={fill} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

SvgQueue.propTypes = {
  fill: PropTypes.string,
};

SvgQueue.defaultProps = {
  fill: '#fff',
};

export default SvgQueue;
