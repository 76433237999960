import axiosRetry from 'axios-retry';
import AppConfig from 'config/app.config';
import apisauce from 'apisauce';
import axios from 'axios';
import { firebase } from 'services/firebase';

const create = () => {
  const axiosInstance = axios.create({
    baseURL: AppConfig.baseURL,
    headers: {
      'Cache-Control': 'public, max-age=18000, stale-while-revalidate=1200',
      'x-api-key': AppConfig.apiKey,
      'X-DNS-Prefetch-Control': 'on',
    },
    timeout: 20000,
  });
  const api = apisauce.create({ axiosInstance });
  axiosRetry(api.axiosInstance, {
    retries: AppConfig.maxRetries,
    shouldResetTimeout: true,
    retryDelay: retryCount => {
      return retryCount * 1000; // time interval between retries
    },
    retryCondition: response => {
      return (
        response.code === 'ECONNABORTED' ||
        ['get', 'head', 'options', 'delete', 'put'].includes(response.config.method) ||
        (response.response && [100, 199, 400, 401, 403, 429, 500, 599, 404].includes(response.response.status))
      );
    },
  });

  api.addAsyncRequestTransform(request => async () => {
    const { currentUser } = firebase.auth();
    if (currentUser) {
      const token = await currentUser.getIdToken();
      request.headers.Authorization = `Bearer ${token}`;
    }
  });

  const claimReward = data => api.post(`rewards`, data);
  const connect = data => api.post(`connections/connect`, data);
  const connectAlt = data => api.post(`connections/vote`, data);
  const connectComment = data => api.post(`comments/connect`, data);
  const connectionsByTarget = target =>
    api.get(`connections`, {
      size: 1000,
      where: `[["target", "==", "${target}"]]`,
      me: 'yes',
    });
  /**
   * createAlbum - Creates an album and optionally uploads artwork
   * for the album
   *
   * @param {Object} data
   * @param {Function} onUploadProgress
   */
  const createAlbum = (data, onUploadProgress) => {
    if (data.poster) {
      const file = data.poster;
      delete data.poster;
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress,
        timeout: 3600000,
      };

      const formData = new FormData();
      formData.append('file', file);
      Object.keys(data).forEach(field => {
        formData.append(field, data[field]);
      });
      return api.post('albums', formData, config);
    }
    return api.post(`albums`, data);
  };
  const createComment = data => api.post(`comments`, data);
  const createFeedback = data => api.post(`feedback`, data);
  const createPlaylist = data => api.post('playlists', data);
  const createStatistic = data => api.post(`statistics`, data);
  const deauthorize = () => api.setHeader('Authorization', null);
  const deleteComment = id => api.delete(`comments/byId/${id}`);
  const deletePlaylist = id => api.delete(`playlists/${id}`);
  const disconnect = id => api.put(`connections/disconnect/${id}`, {});
  const editPlaylist = (id, data) => api.put(`playlists/${id}`, data);
  const getAlbum = id => api.get(`albums/${id}`);
  const getAlbums = params => api.get('albums', params);
  const getAlbumTracks = id => api.get(`tracks/byAlbum/${id}`);
  const getArtist = (id, params) => api.get(`artists/byId/${id}`, params);
  const getArtistRelatedPlaylist = id => api.get(`artists/${id}/related-playlists`);
  const getArtists = params => api.get('artists', params);
  const getArtistScreen = id => api.get(`screens/artists?id=${id}`);
  const getBanners = params => api.get('banners', params);
  const getBTSVideosScreen = () => api.get('videos?qs=bts');
  const getCharts = params => api.get('charts', params);
  const getComment = id => api.get(`comments/byId/${id}`);
  const getComments = params => api.get(`comments`, params);
  const deleteTrackFromPlaylist = (id, data) => api.put(`playlists/${id}`, data);
  const getDiscoverScreen = () => api.get('discover?size=20');
  const getDynamicScreen = (screen, params) => api.get(`screens/${screen}`, params);
  const getEntity = (entity, params) => api.get(`${entity}`, params);
  const getFeaturedVideos = params => api.get('videos/featured', params);
  const getListeningActivity = params => api.get('activities/listening', params);
  const getMixes = params => api.get('mixes', params);
  const getMood = id => api.get(`moods/byId/${id}`);
  const getMoods = params => api.get('moods', params);
  const getMoodTracks = id => api.get(`tracks/byMood/${id}`);
  const getMusicVideosScreen = () => api.get('videos?qs=mvideos');
  const getMyAlbum = id => api.get(`albums/me/${id}`);
  const getMyTrack = id => api.get(`tracks/me/${id}`);
  const getMyPlaylists = () => api.get('playlists', { me: 'yes' });
  const getPlaylist = id => api.get(`playlists/byId/${id}`);
  const getPlaylists = params => api.get('playlists', params);
  const getPlaylistArtists = id => api.get(`playlists/${id}/artists`);
  const getPlaylistTracks = id => api.get(`tracks/byPlaylist/${id}`);
  const getProfile = () => api.get(`users/me`);
  const getRecommendedArtists = params => api.get('artists/recommended', params);
  const getRecommendedGenres = params => api.get('genres/recommended', params);
  const getRecommendedVideos = params => api.get('videos/recommended', params);
  const getRelatedArtists = (entity, id) => api.get(`${entity}/${id}/artists`);
  const getScreenEntity = (entity, from, size = 20, screen = 'discover') =>
    api.get(`${entity}?screen=${screen}&from=${from}&size=${size}`);
  const getSectionById = id => api.get(`featured/byId/${id}`);
  const getSearchHistory = () => api.get('search/history');
  const getScreenSectionById = (id, target) => api.get(`featured/byId/${id}?id=${target}`);
  const getSimilarArtists = params => api.get('artists/similar', params);
  const getSimilarVideos = params => api.get('videos/similar', params);
  const getTopMusicVideos = params => api.get('videos/top_music', params);
  const getTopShowsVideos = params => api.get('videos/top_shows', params);
  const getTopTracks = params => api.get('tracks/top', params);
  const getTrack = id => api.get(`tracks/byId/${id}`);
  const getTracks = params => api.get('tracks', params);
  const getTracksByIds = tracks => api.post(`tracks/byIds`, { tracks });
  const getTrendingTracks = params => api.get('tracks/trending', params);
  const getTrendingVideos = params => api.get('videos/trending', params);
  const getUserAlbums = () => api.get('albums/myalbums/me');
  const getUserArtists = () => api.get('artists/me');
  const getUserPlaylists = () => api.get('playlists/me');
  const getUserTracks = () => api.get('tracks/me');
  const getGenres = () => api.get('genres');
  const getUserById = id => api.get(`users/byId/${id}`);
  const getVideo = id => api.get(`videos/byId/${id}`);
  const getVideoAlbums = () => api.get('videos?qs=events');
  const getVideoPlaylist = id => api.get(`videos/playlists/byId/${id}`);
  const getVideos = params => api.get('videos', params);
  const getVideoScreen = () => api.get('videos');
  const magicForAlbum = (id, params) => api.get(`magic/byAlbum/${id}`, params);
  const magicForArtist = (id, params) => api.get(`magic/byArtist/${id}`, params);
  const magicForGenre = (id, params) => api.get(`magic/byGenre/${id}`, params);
  const magicForMood = (id, params) => api.get(`magic/byMood/${id}`, params);
  const magicForTrack = (id, params) => api.get(`magic/byTrack/${id}`, params);
  const magicForUser = params => api.get('magic/forUser', params);
  const modifyPlaylist = (id, data) => api.put(`playlists/${id}`, data);
  const bulkToPlaylist = (id, data) => api.put(`playlists/${id}/tracks`, data);
  const onboarding = data => api.post(`boarding/board`, data);
  const removePhoto = () => api.delete('users/avatar');
  const resendVerification = () => api.get('users/resend-email');
  const search = request => api.post('search', request);
  const senderror = data => api.post(`errorreport`, data);
  const setFcmToken = data => api.post(`clients`, data);
  const sendFcmToken = data => api.post(`push/freemium`, data);
  const subscribe = data => api.post(`subscription/subscribe`, data);
  const subscribeToTopic = data => api.post(`users/fcm/subscribe`, data);
  const resetPassword = email => api.put(`users/reset-password`, { email });
  const textApp = data => api.post(`share/sms`, data);
  const updateProfile = data => api.put(`users/me`, data);
  const getOnboardingTracks = () => api.get(`tracks/trending`);
  const buyEvent = data => api.post(`events/buy`, data);
  const eventsPurchased = () => api.get(`events/purchased`);
  const walletPurchase = productId => api.post(`wallets/fund`, { productId });
  const getBills = () => api.get(`wallets/bills`);
  const getStudioxBeats = () => api.get(`studiox/beats`);
  const joinStudioxCompetition = () => api.put(`studiox/join`);
  const likeLivestream = payload => api.post(`statistics`, payload);
  const getLeaderboard = () => api.get(`leaderboard`);
  const setReminder = id => api.put(`events/${id}`);
  const updateAlbum = (data, onUploadProgress) => {
    if (data.poster) {
      const file = data.poster;
      delete data.poster;
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress,
        timeout: 3600000,
      };

      const formData = new FormData();
      formData.append('file', file);
      Object.keys(data).forEach(field => {
        formData.append(field, data[field]);
      });
      return api.put(`albums/${data.id}`, formData, config);
    }
    return api.put(`albums/${data.id}`, data);
  };
  const updateTrack = (data, onUploadProgress) => {
    if (data.poster) {
      const file = data.poster;
      delete data.poster;
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress,
        timeout: 3600000,
      };

      const formData = new FormData();
      formData.append('file', file);
      Object.keys(data).forEach(field => {
        formData.append(field, data[field]);
      });
      return api.put(`tracks/${data.id}`, formData, config);
    }
    return api.put(`tracks/${data.id}`, data);
  };
  const uploadByMe = () => api.get(`uploads/me`);
  const uploadTrack = (file, { album, track, studiox }, onUploadProgress) => {
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress,
      timeout: 3600000,
    };

    const data = new FormData();
    data.append('file', file);
    let path = 'uploads/track';
    if (studiox) path = `${path}?store=${studiox}`;
    if (album) path = `${path}?album=${album}`;
    if (track) path = `${path}?track=${track}`;
    return api.post(path, data, config);
  };

  const uploadPhoto = (file, id, entity = 'users') => {
    const data = new FormData();
    data.append('file', file);
    return api.post(`uploads/${entity}/${id}`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Cache-Control': 'no-cache',
      },
    });
  };

  return {
    bulkToPlaylist,
    buyEvent,
    claimReward,
    connect,
    connectAlt,
    connectComment,
    connectionsByTarget,
    createAlbum,
    createComment,
    createFeedback,
    createPlaylist,
    createStatistic,
    deauthorize,
    deleteComment,
    deletePlaylist,
    deleteTrackFromPlaylist,
    disconnect,
    editPlaylist,
    eventsPurchased,
    getAlbum,
    getAlbums,
    getAlbumTracks,
    getArtist,
    getArtistRelatedPlaylist,
    getArtists,
    getArtistScreen,
    getBanners,
    getBills,
    getBTSVideosScreen,
    getCharts,
    getComment,
    getComments,
    getDiscoverScreen,
    getDynamicScreen,
    getEntity,
    getFeaturedVideos,
    getGenres,
    getLeaderboard,
    getListeningActivity,
    getMixes,
    getMood,
    getMoods,
    getMoodTracks,
    getMusicVideosScreen,
    getMyAlbum,
    getMyPlaylists,
    getMyTrack,
    getOnboardingTracks,
    getPlaylist,
    getPlaylistArtists,
    getPlaylists,
    getPlaylistTracks,
    getProfile,
    getRecommendedArtists,
    getRecommendedGenres,
    getRecommendedVideos,
    getRelatedArtists,
    getScreenEntity,
    getScreenSectionById,
    getSectionById,
    getSearchHistory,
    getSimilarArtists,
    getSimilarVideos,
    getStudioxBeats,
    getTopMusicVideos,
    getTopShowsVideos,
    getTopTracks,
    getTrack,
    getTracks,
    getTracksByIds,
    getTrendingTracks,
    getTrendingVideos,
    getUserAlbums,
    getUserArtists,
    getUserById,
    getUserPlaylists,
    getUserTracks,
    getVideo,
    getVideoAlbums,
    getVideoPlaylist,
    getVideos,
    getVideoScreen,
    joinStudioxCompetition,
    likeLivestream,
    magicForAlbum,
    magicForArtist,
    magicForGenre,
    magicForMood,
    magicForTrack,
    magicForUser,
    modifyPlaylist,
    onboarding,
    removePhoto,
    resendVerification,
    resetPassword,
    search,
    senderror,
    setFcmToken,
    sendFcmToken,
    setReminder,
    subscribe,
    subscribeToTopic,
    textApp,
    updateAlbum,
    updateProfile,
    updateTrack,
    uploadByMe,
    uploadPhoto,
    uploadTrack,
    walletPurchase,
  };
};

const api = create();

export default api;
