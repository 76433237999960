import React, { useContext, useCallback } from 'react';

import { FullPlayerContext } from 'contexts/FullPlayerContext';
import { QueueIcon2 } from 'components/icons';

const Tooltip = ({ children }) => {
  const [player, setPlayer] = useContext(FullPlayerContext);

  const close = useCallback(
    () => setPlayer(false),
    // eslint-disable-next-line
    [],
  );

  const toggle = useCallback(
    () => setPlayer(v => !v),
    // eslint-disable-next-line
    [],
  );

  return (
    <>
      {children(player, close)}
      <QueueIcon2 className="xs-hide" onClick={toggle} fill="#fff" />
    </>
  );
};

export default Tooltip;
