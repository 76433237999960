import React from 'react';

function SvgDocument({ fill, ...props }) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M14.1026 19.1667L14.1026 18.75L14.1026 19.1667ZM17.0192 5.76924L17.0192 15.8333H17.8526L17.8526 5.76924H17.0192ZM6.66668 19.5833H14.1026L14.1026 18.75L6.66668 18.75L6.66668 19.5833ZM2.91666 5.76924L2.91668 15.8334L3.75001 15.8334L3.75 5.76924L2.91666 5.76924ZM3.75 5.76924C3.75 4.44164 4.82623 3.3654 6.15384 3.3654L6.15384 2.53206C4.366 2.53206 2.91666 3.9814 2.91666 5.76924L3.75 5.76924ZM6.66668 18.75C5.05585 18.75 3.75001 17.4442 3.75001 15.8334L2.91668 15.8334C2.91668 17.9044 4.59561 19.5833 6.66668 19.5833L6.66668 18.75ZM17.0192 15.8333C17.0192 17.4442 15.7134 18.75 14.1026 18.75L14.1026 19.5833C16.1736 19.5833 17.8526 17.9044 17.8526 15.8333H17.0192ZM17.8526 5.76924C17.8526 3.9814 16.4032 2.53206 14.6154 2.53206L14.6154 3.3654C15.943 3.3654 17.0192 4.44164 17.0192 5.76924H17.8526Z"
        fill={fill}
      />
      <path
        d="M12.6283 2.24357C12.1681 2.24357 11.8127 1.83983 11.556 1.45786C11.3028 1.08117 10.8727 0.833313 10.3847 0.833313C9.8967 0.833313 9.4666 1.08117 9.21342 1.45786C8.95668 1.83983 8.60135 2.24357 8.14111 2.24357H6.98726C6.52703 2.24357 6.15393 2.61667 6.15393 3.0769V4.23075C6.15393 4.69099 6.52703 5.06408 6.98726 5.06408H13.7821C14.2424 5.06408 14.6155 4.69099 14.6155 4.23075V3.0769C14.6155 2.61667 14.2424 2.24357 13.7821 2.24357H12.6283Z"
        stroke="white"
        stroke-width="0.833333"
      />
    </svg>
  );
}

export default SvgDocument;
