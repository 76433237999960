import React, { useEffect, useContext } from 'react';
import styled from '@emotion/styled/macro';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from 'rebass';
import { useTheme } from 'emotion-theming';
import { Typography, Image, Spinner } from 'components/atoms';
import { Modal } from 'components/molecules';
import { PlusIcon } from 'components/icons';
import { PlaylistContext } from 'contexts/PlaylistContext';
import { getUserPlaylistsAction, addToPlaylistAction } from 'redux/actions';
import { useToast } from 'hooks';
import { firstLetterUc } from 'lib/utils';

const Artwork = styled(Image)`
  border-radius: 0.5rem;
  height: 4rem;
  width: 4rem;

  img {
    margin-left: 0;
  }
`;

const Wrapper = styled(Box)`
  align-items: center;
  cursor: pointer;
  display: flex;
  position: relative;

  p {
    line-height: 1.2;
  }

  &:not(:last-child) {
    margin-bottom: 2rem;
  }
`;

const PlaylistItem = ({ create, item: { name, artwork }, onClick }) => {
  const { switchingText } = useTheme().colors;
  return (
    <Wrapper onClick={onClick}>
      {create ? <PlusIcon fill={switchingText} width="4rem" height="4rem" /> : <Artwork src={artwork} alt={name} />}
      <Box flexDirection="column" ml="1.5rem">
        <Typography.Paragraph color="switchingText" ellipsize>
          {name}
        </Typography.Paragraph>
      </Box>
    </Wrapper>
  );
};

const SelectPlaylistModal = ({ isOpen, toggle, item, type }) => {
  const dispatch = useDispatch();
  const { addToast } = useToast();
  const { toggleCreatePlaylist, setSelectModal } = useContext(PlaylistContext);
  const getPlaylists = () => dispatch(getUserPlaylistsAction());
  const addToPlaylist = (playlist, cb) => dispatch(addToPlaylistAction(item.id, type, playlist, cb));
  const data = useSelector(state => state.playlist.byUser.user || []);
  const loading = useSelector(state => state.playlist.fetching.byUser || false);

  useEffect(() => {
    if (isOpen) {
      getPlaylists();
    }
    // eslint-disable-next-line
  }, [isOpen]);

  const onSuccess = () => {
    addToast(`The ${type} has been added to your playlist!`, { type: 'success' });
  };

  const onError = e => {
    addToast((e && firstLetterUc(e.error)) || 'An error occured', { type: 'error' });
  };

  const callback = (success, e) => {
    if (success) {
      onSuccess();
    } else {
      onError(e);
    }
  };

  const onSelect = id => {
    addToPlaylist(id, callback);
    toggle();
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="sm" showCloseIcon>
      <Modal.Header justifyContent="center">
        <Typography.Paragraph>Select playlist</Typography.Paragraph>
      </Modal.Header>
      <Modal.Body>
        <div style={{ maxHeight: '50rem', overflowY: 'scroll' }}>
          <PlaylistItem
            create
            item={{ name: 'Create Playlist' }}
            onClick={() => {
              setSelectModal(false);
              toggleCreatePlaylist();
            }}
          />
          {loading && (
            <Box justifyContent="center" alignItems="center">
              <Spinner />
            </Box>
          )}
          {data.map(playlist => (
            <PlaylistItem item={playlist} onClick={() => onSelect(playlist.id)} key={playlist.id} />
          ))}
        </div>
      </Modal.Body>
    </Modal>
  );
};

// PlaylistItem.propTypes = {
//   create: PropTypes.bool.isRequired,
//   onClick: PropTypes.func.isRequired,
//   item: PropTypes.shape({
//     name: PropTypes.string,
//     artwork: PropTypes.string,
//   }).isRequired,
// };

SelectPlaylistModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
};

export default SelectPlaylistModal;
