import React from 'react';
import PropTypes from 'prop-types';

function SvgMusic({ fill, ...props }) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 12.631 14.532" {...props}>
      <path
        d="M9.921 1.477l2.006.951-3.615.573zm2.71-1.048v-.05A.377.377 0 0012.2.001L5.024 1.096a.378.378 0 00-.321.374v1.022l.232-.221 2.005.951-2.24.357v6.95a2.525 2.525 0 00-1.393-.38 3.671 3.671 0 00-1.345.267 3.418 3.418 0 00-1.594 1.24 1.847 1.847 0 00-.257 1.725 2.074 2.074 0 002.037 1.152 3.671 3.671 0 001.352-.267 3.422 3.422 0 001.594-1.24 2.031 2.031 0 00.369-1.188V4.063l6.415-.976v6.351a2.533 2.533 0 00-1.393-.38 3.685 3.685 0 00-1.345.266 3.416 3.416 0 00-1.594 1.24 1.845 1.845 0 00-.257 1.725 2.072 2.072 0 002.037 1.152 3.69 3.69 0 001.345-.266 3.422 3.422 0 001.594-1.24 2.037 2.037 0 00.369-1.188V2.101l-.166.158L9.8 1.001 7.665 3.024 4.948 1.735l.1-.1L9.615.907l.185.094.146-.139zm-2.66 1.365l-.9.855 2.029-.322zm-2.42.517l-.929-.44 1.678-.27zm-1.9-.536l1.964.931 1.575-1.5zm6.98-1.112l-2.181.348 1.964.932.217-.206v-.456l-.28.266-.929-.441 1.209-.193zM4.986 2.588l-.286.269v.489l1.41-.224zm2.565-.277l-.929-.44 1.678-.27zm-1.9-.536l1.964.931 1.575-1.5zm4.321.018l-.9.855 2.028-.321zm0 0l-.9.855 2.028-.321z"
        fill={fill}
        fillRule="evenodd"
      />
      <path
        data-name="Subtraction 1"
        d="M7.628 2.699L5.663 1.78 9.2 1.213 7.628 2.699zm.671-1.094l-1.695.264.947.441.748-.705z"
        fill={fill}
      />
    </svg>
  );
}

SvgMusic.propTypes = {
  fill: PropTypes.string,
};

SvgMusic.defaultProps = {
  fill: '#fcb316',
};

export default SvgMusic;

export function SvgMusic1({ fill, ...props }) {
  return (
    <svg width="152" height="157" viewBox="0 0 152 157" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.5">
        <ellipse cx="25.4613" cy="130.766" rx="22.4613" ry="23.2308" stroke="#868687" strokeWidth="5" />
        <ellipse cx="126.541" cy="113.351" rx="22.4613" ry="23.2308" stroke="#868687" strokeWidth="5" />
        <path
          d="M47.9189 127.865V32.0385L148.995 3V107.538"
          stroke="#868687"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}
