import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Flex, Box } from 'rebass';
import { Typography, Form, Button } from 'components/atoms';
import { Modal } from 'components/molecules';
import { CameraIcon } from 'components/icons';
import { isObjEmpty } from 'lib/utils';
import { createPlaylistAction, editPlaylistAction, addToPlaylistAction } from 'redux/actions';
import { useToast } from 'hooks';
import { AvatarWrapper, EditArtwork, AvatarOverlay } from './styled';

const INITIAL_FORM = {
  artwork: '',
  name: '',
  public: false,
  description: '',
  id: '',
};

const CreatePlaylistModal = ({ isOpen, toggle, playlist, type, item }) => {
  const posterRef = useRef();
  const dispatch = useDispatch();
  const { push } = useHistory();
  const [poster, setPoster] = useState(null);
  const [error, setError] = useState('');
  const editMode = !!playlist.id;
  const [form, setForm] = useState(INITIAL_FORM);
  const { addToast } = useToast();
  const loading = useSelector(state => state.playlist.creating || false);

  const addToPlaylist = (playlistId, cb) => dispatch(addToPlaylistAction(item.id, type, playlistId, cb));
  const createPlaylist = cb => dispatch(createPlaylistAction(form, poster, cb));

  const editPlaylist = (data, cb) => dispatch(editPlaylistAction(data, poster, cb));

  useEffect(() => {
    if (isOpen) {
      const { artwork = '', name = '', public: publicVal, description = '', id } = playlist;
      setForm(v => ({
        ...v,
        artwork,
        name,
        public: publicVal,
        description,
        id,
      }));
    }
  }, [isOpen, playlist]);

  useEffect(() => {
    if (!isOpen) {
      setPoster(null);
      setForm(v => ({ ...v, ...INITIAL_FORM }));
      setError(false);
    }
  }, [isOpen]);

  const onRadioChange = value => {
    setForm(v => ({
      ...v,
      public: value,
    }));
  };

  const changePoster = e => {
    const { files } = e.target;
    if (files.length) {
      const file = Object.assign(files[0], {
        preview: URL.createObjectURL(files[0]),
      });
      setPoster(file);
    }
  };

  const onChange = e => {
    const { name, value } = e.target;
    setForm(v => ({
      ...v,
      [name]: value,
    }));
  };

  const validateForm = () => {
    const { name } = form;
    if (!name) {
      setError('Enter your playlist name');
      return false;
    }
    if (name.length < 4) {
      setError('Playlist name too short');
      return false;
    }
    return true;
  };

  const onSuccess = data => {
    if (type && !isObjEmpty(item)) {
      addToPlaylist(data.id, success => {
        if (success) {
          addToast(`The ${type} has been added to your playlist!`, { type: 'success' });
        }
      });
    }
    toggle();
  };

  const onError = e => {
    setError(e?.error || 'An error occured', { type: 'error' });
  };

  const callback = (data, e) => {
    if (data) {
      addToast(`Playlist has been ${editMode ? 'edited' : 'created'} successfully!`, { type: 'success' });
      onSuccess(data);
      if (playlist.goToPlaylist) {
        push(`/playlists/${data.id}`);
      }
    } else {
      onError(e);
    }
  };

  const onSubmit = e => {
    e.preventDefault();
    if (validateForm()) {
      if (editMode) {
        const data = { id: form.id };
        if (form.name !== playlist.name) {
          data.name = form.name.trim();
        }
        if (form.artwork !== playlist.artwork) {
          data.artwork = form.artwork;
        }
        data.description = form.description.trim();
        if (form.public !== playlist.public) {
          data.public = form.public;
        }
        editPlaylist(data, callback);
      } else {
        createPlaylist(callback);
      }
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg" showCloseIcon>
      <Modal.Header justifyContent="center">
        <Typography.Paragraph>{editMode ? 'Edit your' : 'Create a'} playlist</Typography.Paragraph>
      </Modal.Header>
      <Modal.Body>
        <Flex mx="-1.5rem" flexWrap={['wrap', 'nowrap', 'nowrap', 'nowrap']}>
          <Box
            px="1.5rem"
            mb={['3rem', null, '3rem', 0]}
            flexShrink={0}
            flexGrow={['1', '1', '1', '0']}
            justifyContent={['center', 'flex-start']}
          >
            <AvatarWrapper>
              <EditArtwork
                src={(poster && poster.preview) || form.artwork}
                placeholder="playlist"
                alt={`${type} artwork`}
              />
              <AvatarOverlay onClick={() => posterRef.current.click()}>
                <input ref={posterRef} type="file" accept="image/*" onChange={changePoster} />
                <CameraIcon />
              </AvatarOverlay>
            </AvatarWrapper>
          </Box>

          <Box px="1.5rem" flexGrow={['1', '1,', '1', '1', 0]}>
            <Form onSubmit={onSubmit}>
              <Flex flexWrap="wrap" mx="-1.5rem">
                <Box px="1.5rem" mb="2rem" width={1}>
                  <Form.Group>
                    <Form.Label required>Name</Form.Label>
                    <Form.Input name="name" value={form.name} onChange={onChange} />
                  </Form.Group>
                </Box>

                <Box px="1.5rem" mb="2rem" width={1}>
                  <Form.Group>
                    <Form.Label>Description</Form.Label>
                    <Form.Textarea name="description" rows={5} value={form.description} onChange={onChange} />
                  </Form.Group>
                </Box>

                <Box px="1.5rem" mb="2rem" width={1}>
                  <Form.Group>
                    <Form.Label>Mode</Form.Label>
                    <Form.Radio text="Private" selected={!form.public} onClick={() => onRadioChange(false)} />
                    <Form.Radio text="Public" selected={form.public} onClick={() => onRadioChange(true)} />
                  </Form.Group>
                </Box>
              </Flex>
              <Form.Error error={error} />
              <Box>
                <Button effect fontSize="small" onClick={onSubmit} loading={loading} style={{ width: '100%' }}>
                  {editMode ? 'Save' : 'Create'}
                </Button>
              </Box>
            </Form>
          </Box>
        </Flex>
      </Modal.Body>
    </Modal>
  );
};

// CreatePlaylistModal.propTypes = {
//   item: PropTypes.shape({
//     id: PropTypes.string,
//     name: PropTypes.string,
//   }).isRequired,
//   playlist: PropTypes.shape({
//     artwork: PropTypes.string.isRequired,
//     description: PropTypes.string.isRequired,
//     goToPlaylist: PropTypes.bool,
//     id: PropTypes.string,
//     name: PropTypes.string,
//     public: PropTypes.bool.isRequired,
//   }).isRequired,
//   id: PropTypes.string.isRequired,
//   isOpen: PropTypes.bool.isRequired,
//   toggle: PropTypes.func.isRequired,
//   type: PropTypes.string.isRequired,
// };

export default CreatePlaylistModal;
