import React from 'react';
import PropTypes from 'prop-types';

function SvgInstagram({ fill, ...props }) {
  return (
    <svg
      id="instagram_svg___034-instagram"
      data-name="034-instagram"
      width="1em"
      height="1em"
      viewBox="0 0 31 31"
      {...props}
    >
      <defs>
        <style>{`.instagram_svg__cls-1{fill:${fill}}`}</style>
      </defs>
      <path
        id="instagram_svg__Path_1032"
        data-name="Path 1032"
        className="instagram_svg__cls-1"
        d="M21.312 0H9.687A9.688 9.688 0 000 9.687v11.625A9.688 9.688 0 009.687 31h11.625A9.688 9.688 0 0031 21.312V9.687A9.688 9.688 0 0021.312 0zm6.781 21.312a6.789 6.789 0 01-6.781 6.781H9.687a6.789 6.789 0 01-6.781-6.781V9.687a6.789 6.789 0 016.781-6.781h11.625a6.789 6.789 0 016.781 6.781z"
      />
      <path
        id="instagram_svg__Path_1033"
        data-name="Path 1033"
        className="instagram_svg__cls-1"
        d="M135.75 128a7.75 7.75 0 107.75 7.75 7.75 7.75 0 00-7.75-7.75zm0 12.594a4.844 4.844 0 114.844-4.844 4.85 4.85 0 01-4.844 4.844z"
        transform="translate(-120.25 -120.25)"
      />
      <circle
        id="instagram_svg__Ellipse_1"
        data-name="Ellipse 1"
        className="instagram_svg__cls-1"
        cx={1.033}
        cy={1.033}
        r={1.033}
        transform="translate(22.798 6.136)"
      />
    </svg>
  );
}

SvgInstagram.propTypes = {
  fill: PropTypes.string,
};

SvgInstagram.defaultProps = {
  fill: '#fff',
};

export default SvgInstagram;
