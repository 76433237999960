import styled from '@emotion/styled/macro';
import { css, keyframes } from '@emotion/core';
import PropTypes from 'prop-types';

const rotate = keyframes`
from {
  transform: rotate(0deg);
}
to {
  transform: rotate(360deg);
}
`;

export const ButtonTemplate = styled.button`
  -webkit-appearance: none;
  align-items: center;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  display: inline-flex;
  font-weight: ${props => (props.bold ? 'bold' : 'normal')};
  justify-content: center;
  letter-spacing: ${props => props.letterSpacing};
  line-height: 1;
  outline: none;
  overflow: hidden;
  padding: 0;
  text-decoration: none;
  transition: all 0.3s;
  user-select: none;
  white-space: nowrap;
  text-transform: uppercase;
  ${({ effect }) =>
    effect &&
    css`
      &:hover {
        transition: all 0.2s ease-in-out;
        transform: scale(1.01);
      }
    `}
  }

  ${({ size, theme, fontWeight, fontSize }) =>
    size &&
    css`
      font-size: ${(fontSize && theme.font.size[fontSize]) || theme.buttons[size].fontSize};
      font-weight: ${theme.font.weight[fontWeight]};
      padding: ${theme.buttons[size].padding};
    `}


  ${({ block }) =>
    block &&
    css`
      width: 100%;
    `}

  ${({ rounded }) =>
    rounded &&
    css`
      border-radius: 175.5px;
    `}


    ${({ borderRadius }) =>
      borderRadius &&
      css`
        border-radius: ${borderRadius};
      `}


      &:disabled{
        cursor: not-allowed;
      }


  ${({ cornered }) =>
    cornered &&
    css`
      border-radius: 1rem;
    `}

  ${({ loading }) =>
    loading &&
    css`
      color: transparent !important;
      pointer-events: none;
      position: relative;

      &::after {
        content: '';
        animation: ${rotate} 500ms infinite linear;
        border: 2px solid #fff;
        border-radius: 50%;
        border-right-color: transparent !important;
        border-top-color: transparent !important;
        height: 1.2em;
        width: 1.2em;
        position: absolute;
        left: calc(50% - (1.2em / 2));
        top: calc(50% - (1.2em / 2));
      }
    `}

    svg{
      width: 1.8rem;
    }
`;

export const Button = styled(ButtonTemplate)`
  background: ${props => props.theme.colors[props.color]};
  font-weight: 500;
  &,
  &:visited {
    color: #fff;
  }

  &:hover{
    background-color: ${props => props.theme.colors[props.color]}80;
  }
  

  ${({ color, theme }) => {
    if (color.includes('black') || color.includes('dark')) {
      return css`
        &,
        &:visited {
          color: ${theme.colors.white};
        }
      `;
    }
  }}

  ${({ color, faded, theme }) =>
    faded &&
    css`
      background-color: ${theme.colors[color]}30;
      &,
      &:visited {
        color: ${theme.colors[color]};
      }
    `}



    ${({ link, theme, color }) =>
      link &&
      css`
        background-color: transparent;
        border-radius: 0;
        padding: 0;
        text-decoration: underline;

        &,
        &:visited {
          color: ${theme.colors[color]};
        }
      `}
`;

export const OutlineButton = styled(ButtonTemplate)`
  font-weight: 500;
  border: 1px solid ${props => props.theme.colors[props.color]};
  &,
  &:visited {
    color: ${props => props.theme.colors[props.textColor]};
  }

  ${({ noBorder }) =>
    noBorder &&
    css`
      border: none;
    `}

  &:disabled {
    border-color: #f0f0f0;
  }
  &:hover {
    background: linear-gradient(270.02deg, #fdb913 0.02%, #f2994a 99.98%);
  }
`;

ButtonTemplate.defaultProps = {
  letterSpacing: '0',
  size: 'normal',
};

ButtonTemplate.propTypes = {
  size: PropTypes.oneOf(['small', 'normal', 'large']).isRequired,
};

Button.propTypes = {
  color: PropTypes.string,
  textColor: PropTypes.string,
};
Button.defaultProps = {
  color: 'primary',
  cornered: false,
  fontWeight: 'medium',
  rounded: true,
  size: 'large',
  textColor: 'almost-black',
};

OutlineButton.propTypes = {
  color: PropTypes.string,
};

OutlineButton.defaultProps = {
  color: 'primary',
  fontWeight: 'medium',
  textColor: 'primary',
};
