import * as t from '../types';

const INITIAL_STATE = {
  file: {},
  gettingUploads: false,
  uploadedTrack: null,
  uploadingTrack: false,
  uploadingTrackFailed: false,
  uploads: [],
};

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case t.CLEAR_UPLOAD_REQUEST:
      return { ...INITIAL_STATE };
    case t.UPLOAD_TRACK_REQUEST:
      return { ...state, uploadingTrack: true, uploadedTrack: null, file: payload.file, uploadingTrackFailed: false };
    case t.UPLOAD_TRACK_SUCCESS:
      return {
        ...state,
        file: payload.file,
        uploadedTrack: payload.data,
        uploadingTrack: false,
        uploadingTrackFailed: false,
      };
    case t.UPDATE_TRACK_SUCCESS:
      return {
        ...state,
        uploadedTrack: { ...state.uploadedTrack, ...payload.data },
      };
    case t.UPLOAD_TRACK_FAILURE:
      return { ...state, uploadingTrack: false, file: payload.file, uploadingTrackFailed: true };
    case t.GET_UPLOADS_REQUEST:
      return { ...state, gettingUploads: true };
    case t.GET_UPLOADS_SUCCESS:
      return {
        ...state,
        uploads: payload.data,
        gettingUploads: false,
      };
    case t.GET_UPLOADS_FAILURE:
      return { ...state, gettingUploads: false };

    case t.LOGOUT:
      return INITIAL_STATE;

    default:
      return { ...state };
  }
};
