import React from 'react';
import PropTypes from 'prop-types';

function SvgX({ fill, ...props }) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 79 78" {...props}>
      <defs>
        <clipPath id="x_svg__a">
          <path d="M0 0h79v78H0z" />
        </clipPath>
      </defs>
      <g data-name="Group 94" clipPath="url(#x_svg__a)">
        <path
          data-name="Add to Playlist _ +"
          d="M2.99 63.225l24.212-24.21L2.99 14.801A8.563 8.563 0 1115.1 2.692l24.212 24.212L63.524 2.692a8.563 8.563 0 1112.11 12.11L51.422 39.014l24.211 24.211a8.563 8.563 0 11-12.11 12.11L39.312 51.124 15.1 75.335a8.564 8.564 0 11-12.11-12.11z"
          fill={fill}
        />
      </g>
    </svg>
  );
}

SvgX.propTypes = {
  fill: PropTypes.string,
};

SvgX.defaultProps = {
  fill: '#fff',
};

export default SvgX;
