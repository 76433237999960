const objectToGetParams = object => {
  const params = Object.entries(object)
    .filter(([, value]) => value !== undefined && value !== null)
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`);
  return params.length > 0 ? `?${params.join('&')}` : '';
};

const isMobileOrTablet = () => {
  return /(android|iphone|ipad|mobile)/i.test(navigator.userAgent);
};

export const facebookShare = (url, quote) => {
  return `https://www.facebook.com/sharer/sharer.php${objectToGetParams({
    u: url,
    quote,
  })}`;
};

export const twitterShare = (url, title) => {
  return `https://twitter.com/share${objectToGetParams({
    url,
    text: title,
  })}`;
};

export const whatsappShare = (url, title, separator = '') => {
  return `https://${isMobileOrTablet() ? 'api' : 'web'}.whatsapp.com/send${objectToGetParams({
    text: title ? title + separator + url : url,
  })}`;
};

export const openInNewTab = url => {
  window.open(url, '_blank');
};
