import React from 'react';

function SvgExternalRedirect({ ...props }) {
  return (
    <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M9.00155 6.19436C9.55384 6.19436 10.0016 5.74665 10.0016 5.19436C10.0016 4.64208 9.55384 4.19436 9.00156 4.19436L9.00155 6.19436ZM22.8047 17.9975C22.8047 17.4452 22.357 16.9975 21.8047 16.9975C21.2524 16.9975 20.8047 17.4452 20.8047 17.9975L22.8047 17.9975ZM4.99959 25.9994L4.99959 24.9994L4.99959 25.9994ZM17.8047 25.9994L17.8047 24.9994L17.8047 25.9994ZM-0.000398811 9.19435L-0.00039937 21.9994L1.9996 21.9994L1.9996 9.19435L-0.000398811 9.19435ZM17.8047 24.9994L4.99959 24.9994L4.99959 26.9994L17.8047 26.9994L17.8047 24.9994ZM9.00156 4.19436L4.99961 4.19435L4.99961 6.19435L9.00155 6.19436L9.00156 4.19436ZM20.8047 17.9975L20.8047 21.9994L22.8047 21.9994L22.8047 17.9975L20.8047 17.9975ZM1.9996 9.19435C1.9996 7.53749 3.34275 6.19434 4.99961 6.19435L4.99961 4.19435C2.23819 4.19434 -0.00039869 6.43292 -0.000398811 9.19435L1.9996 9.19435ZM-0.00039937 21.9994C-0.000399491 24.7609 2.23817 26.9994 4.99959 26.9994L4.99959 24.9994C3.34274 24.9994 1.9996 23.6563 1.9996 21.9994L-0.00039937 21.9994ZM17.8047 26.9994C20.5661 26.9994 22.8047 24.7608 22.8047 21.9994L20.8047 21.9994C20.8047 23.6563 19.4615 24.9994 17.8047 24.9994L17.8047 26.9994Z"
        fill="white"
      />
      <path
        d="M21.8047 5.19434L9.80175 17.1973"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.4047 5.19492L21.8063 5.20664L21.8063 11.5964"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgExternalRedirect;
