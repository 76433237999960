import React from 'react';
import PropTypes from 'prop-types';

function SvgFacebookColored({ fill, ...props }) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 14.083 25.999" {...props}>
      <path
        d="M10.291 4.333h3.25a.542.542 0 00.542-.542V.542A.542.542 0 0013.541 0h-3.25a5.965 5.965 0 00-5.958 5.958V9.75H.541a.542.542 0 00-.542.542v3.25a.542.542 0 00.542.542h3.792v11.373a.542.542 0 00.542.542h3.25a.542.542 0 00.542-.542V14.083h3.792a.542.542 0 00.513-.37l1.083-3.25a.542.542 0 00-.513-.713H8.667V5.958a1.625 1.625 0 011.624-1.625z"
        data-name="063-facebook"
        fill={fill}
      />
    </svg>
  );
}

SvgFacebookColored.propTypes = {
  fill: PropTypes.string,
};

SvgFacebookColored.defaultProps = {
  fill: '#2196f3',
};

export default SvgFacebookColored;
