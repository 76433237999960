import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useSlider } from 'react-use';
import { SpeakerIcon, SpeakerMuteIcon } from 'components/icons';
import { useSelector, useDispatch } from 'react-redux';
import styled from '@emotion/styled/macro';
import { toggleMuteAction, volumeChangeAction } from 'redux/actions';

const Svg = styled.svg``;

const Thumb = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 50%;
  bottom: ${({ pos }) => pos}%;
  cursor: grab;
  height: 1rem;
  left: 50%;
  opacity: 0;
  position: absolute;
  transform: translate(-50%, 50%);
  transition: all 0.1s;
  width: 1rem;
`;

const HoverWrapper = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors['almost-black-two']};
  border-radius: 50rem;
  bottom: 0;
  box-shadow: 0 -1px 4px ${({ theme }) => theme.colors.switchingText}4D;
  display: flex;
  height: 10rem;
  justify-content: center;
  left: 50%;
  pointer-events: none;
  position: absolute;
  transform: translateX(-50%);
  transition: all 0.2s;
  visibility: hidden;
  width: 2rem;
  z-index: 6;
`;

const Wrapper = styled.div`
  cursor: pointer;
  display: none;
  margin-right: 1rem;
  margin-left: 1rem;
  margin-top: -1rem;
  padding-top: 1rem;
  position: relative;

  ${({ theme }) => theme.mq.sm`
    display: inline-flex;
      `}

  &:hover {
    ${HoverWrapper} {
      bottom: 100%;
      pointer-events: auto;
      visibility: visible;

      ${Thumb} {
        opacity: 1;
      }
    }

    ${Svg} {
      &,
      * {
        stroke: ${({ theme }) => theme.colors.primary};
      }
    }
  }

  ${({ theme }) => theme.mq.sm`
      margin-right: 3rem;
      `}
`;

const Slider = styled.div`
  background-color: ${({ theme }) => theme.colors['brown-grey']};
  border-radius: 5px;
  cursor: grab;
  height: 8rem;
  overflow: hidden;
  position: relative;
  width: 2px;

  &:after {
    background-color: ${({ theme }) => theme.colors.primary};
    bottom: 0;
    content: '';
    height: ${({ pos }) => pos}%;
    left: 0;
    position: absolute;
    width: 2px;
  }
`;

const SliderWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 8rem;
  justify-content: center;
  position: relative;
  width: 100%;

  &:hover {
    ${Thumb} {
      box-shadow: 1px 1px 1px rgba(255, 255, 255, 0.1);
      height: 1.3rem;
      width: 1.3rem;
    }
  }
`;

export const Volume = ({ className }) => {
  const node = useRef();
  const dispatch = useDispatch();
  const muted = useSelector(state => state.player.muted);
  const volume = useSelector(state => state.player.volume);
  const setVolume = v => dispatch(volumeChangeAction(v));
  const toggleMute = () => dispatch(toggleMuteAction());
  useSlider(node, { vertical: true, reverse: true, onScrub: setVolume });
  const calcVal = muted ? 0 : Math.round(volume * 100);

  return (
    <Wrapper className={className}>
      <Svg
        as={muted || calcVal === 0 ? SpeakerMuteIcon : SpeakerIcon}
        fill="#fff"
        // full={calcVal > 50}
        onClick={toggleMute}
        size={19}
      />
      <HoverWrapper>
        <SliderWrapper ref={node}>
          <Slider pos={calcVal} />
          <Thumb pos={calcVal} />
        </SliderWrapper>
      </HoverWrapper>
    </Wrapper>
  );
};

Volume.propTypes = {
  className: PropTypes.string,
};

Volume.defaultProps = {
  className: '',
};
