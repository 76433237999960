import React from 'react';
import PropTypes from 'prop-types';

function SvgCommentIcon({ fill, ...props }) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <mask id="path-1-inside-1" fill={fill}>
        <path d="M2.97988e-06 19.9344L3.63985e-06 4.83594C3.73642e-06 2.6268 1.79087 0.83594 4.00001 0.835944L17 0.83597C19.2091 0.835974 21 2.62683 21 4.83597L21 13.947C21 16.1562 19.2091 17.947 17 17.947L5.65385 17.947L1.56672 20.7583C0.903235 21.2147 2.94468e-06 20.7397 2.97988e-06 19.9344Z" />
      </mask>
      <path
        d="M17 0.83597L17 -0.16403L17 0.83597ZM4.00001 0.835944L4.00001 1.83594L4.00001 0.835944ZM5.65385 17.947L5.65385 16.947L5.34313 16.947L5.08713 17.1231L5.65385 17.947ZM1.56672 20.7583L2.13343 21.5822L2.13343 21.5822L1.56672 20.7583ZM17 17.947L17 16.947L17 16.947L17 17.947ZM-0.999996 4.83594L-0.999997 19.9344L1 19.9344L1 4.83594L-0.999996 4.83594ZM20 4.83597L20 13.947L22 13.947L22 4.83597L20 4.83597ZM17 -0.16403L4.00001 -0.164056L4.00001 1.83594L17 1.83597L17 -0.16403ZM5.08713 17.1231L1 19.9344L2.13343 21.5822L6.22056 18.771L5.08713 17.1231ZM17 16.947L5.65385 16.947L5.65385 18.947L17 18.947L17 16.947ZM22 4.83597C22 2.07455 19.7614 -0.164025 17 -0.16403L17 1.83597C18.6569 1.83597 20 3.17912 20 4.83597L22 4.83597ZM1 4.83594C1 3.17909 2.34315 1.83594 4.00001 1.83594L4.00001 -0.164056C1.23858 -0.164061 -0.999996 2.07452 -0.999996 4.83594L1 4.83594ZM-0.999997 19.9344C-0.999997 21.545 0.806468 22.4949 2.13343 21.5822L1 19.9344L1 19.9344L-0.999997 19.9344ZM20 13.947C20 15.6039 18.6569 16.947 17 16.947L17 18.947C19.7614 18.947 22 16.7085 22 13.947L20 13.947Z"
        fill={fill}
        mask={fill}
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="21"
          y1="11.3359"
          x2="3.35573e-06"
          y2="11.3359"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDB913" />
          <stop offset="1" stopColor="#F2994A" />
        </linearGradient>
      </defs>
    </svg>
  );
}

SvgCommentIcon.propTypes = {
  fill: PropTypes.string,
};

SvgCommentIcon.defaultProps = {
  fill: '#ffa700',
};

export default SvgCommentIcon;
