import { Button, Typography } from 'components/atoms';
import React from 'react';
import { Flex } from 'rebass';
import { LockIcon } from 'components/icons';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { useHistory } from 'react-router-dom';

const Wrapper = styled(Flex)`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.tileBg};
  border-radius: 13px;
  box-shadow: 0 2px 2px ${({ theme }) => theme.colors.switchingBg}1A;
  flex-direction: column;
  justify-content: center;
  padding: 3rem 2rem;

  svg {
    fill: ${({ theme }) => theme.colors.switchingText};
    margin-bottom: 2rem;
  }

  span {
    margin-bottom: 2rem;
  }
`;

const Buttons = styled(Flex)`
  align-items: center;

  button:not(:last-child) {
    margin-right: 0.5rem;
  }
`;

const MainWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
`;

export const LoginPrompt = ({ text }) => {
  const history = useHistory();
  const handleLoginRedirect = () => {
    const path = history.location.pathname;
    history.push(`/auth/login?redirect=${path}`);
  };
  const handleSignupRedirect = () => {
    const path = history.location.pathname;
    history.push(`/auth/signup?redirect=${path}`);
  };

  return (
    <MainWrapper>
      <Wrapper>
        <LockIcon width="6rem" height="6rem" fill="#fcb316" />
        <Typography.Text color="switchingText" align="center" size="small">
          {text}
        </Typography.Text>

        <Buttons>
          <Button
            effect
            onClick={handleLoginRedirect}
            fontSize="small"
            style={{ background: 'linear-gradient(270.02deg, #FDB913 0.02%, #F2994A 99.98%)' }}
            aria-label="login"
          >
            Login
          </Button>
          <Button
            effect
            fontSize="small"
            onClick={handleSignupRedirect}
            style={{ background: 'linear-gradient(270.02deg, #FDB913 0.02%, #F2994A 99.98%)' }}
            aria-label="register"
          >
            Register
          </Button>
        </Buttons>
      </Wrapper>
    </MainWrapper>
  );
};

LoginPrompt.propTypes = {
  text: PropTypes.string,
};

LoginPrompt.defaultProps = {
  text: 'View this content and more.',
};
