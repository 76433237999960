import styled from '@emotion/styled/macro';
import { css } from '@emotion/core';

export const Content = styled.div`
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
  width: 100%;

  @media (min-width: ${props => props.theme.breakpoints[1]}) {
    padding-left: 3rem;
    padding-right: 3rem;

    ${({ fluid }) =>
      fluid &&
      css`
        padding-left: 0rem;
        padding-right: 0rem;
      `}
    ${({ page }) =>
      page &&
      css`
        padding-left: 1.5rem;
        padding-right: 1.5rem;
      `}
  }
`;
