import React, { useCallback } from 'react';
import styled from '@emotion/styled/macro';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { Box } from 'rebass';
import { Button, Typography } from 'components/atoms';
import { UploadIcon } from 'components/icons';

const Wrapper = styled(Box)`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.metadataBg};
  border-radius: 0.5rem;
  flex-direction: column;
  justify-content: center;
  padding: 3rem 2rem;
  outline: none !important;

  ${({ theme }) => theme.mq.sm`
  padding: 3rem 5rem;
  `}

  ${({ theme }) => theme.mq.sm`
  padding: 3rem 10rem;
  `}
`;

const UploadButton = styled(Button)`
  background-image: linear-gradient(136deg, #fcb316 34%, #ff9d00 98%);
  border-radius: 0.5rem;
  margin-bottom: 3rem;
  padding: 2rem;

  ${Typography.Text} {
    font-weight: bold;
    display: inline-block;
  }
`;

const Heading = styled(Typography.Paragraph)`
  margin-bottom: 3rem;
  text-align: center;
`;

export const Dnd = ({ onDrop, style }) => {
  const drop = useCallback(onDrop, []);
  const { getRootProps, getInputProps } = useDropzone({ onDrop: drop, maxSize: 250000000, multiple: false });

  const { onClick, ...restOfGetRootProps } = getRootProps();

  return (
    <Wrapper style={style} {...restOfGetRootProps}>
      <input {...getInputProps()} accept=".mp3,.flac,.wav,.aiff,.ogg,.m4a" />
      <Heading color="switchingText">Browse for files or drag & drop here</Heading>
      <UploadButton effect onClick={onClick}>
        <UploadIcon width="5rem" height="5rem" />

        <Box flexDirection="column" ml="1rem">
          <Typography.Text>Select File</Typography.Text>
          <Typography.Text>To Upload</Typography.Text>
        </Box>
      </UploadButton>
      <Typography.Paragraph style={{ marginBottom: '1rem' }} size="tiny" align="center" color="switchingText">
        Accepted file types are MP3, FLAC, WAV, AIFF, OGG, & M4A. Limit of 250MB per file.
      </Typography.Paragraph>

      <Typography.Paragraph size="tiny" align="center" color="brown-grey-two">
        Uploading constitutes your acceptance of our Terms of Service and Privacy Policy. Uploading music is reserved
        for Artists, DJs, and Labels. uduX is not for storing or sharing your personal music collection or files. DO NOT
        upload any content which infringes on the rights of 3rd parties. Users who upload 3rd party content will be
        banned from uduX immediately.
      </Typography.Paragraph>
    </Wrapper>
  );
};

Dnd.propTypes = {
  onDrop: PropTypes.func,
};

Dnd.defaultProps = {
  onDrop: acceptedFiles => console.log(acceptedFiles),
};
