import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lib/utils';
import { Item, ItemIcon, Menu, ItemWrapper } from './styled';

const IconStyle = {
  cursor: 'pointer',
  width: '2rem',
  height: '2rem',
  marginRight: '1rem',
};

export const Popover = ({ close, coords, handleSelect, list, updateTooltipCoords }) => {
  const node = useRef();
  const updateCoords = debounce(updateTooltipCoords, 100);

  useEffect(() => {
    window.addEventListener('resize', updateCoords);
    // window.addEventListener('scroll', updateCoords);
    return () => {
      window.removeEventListener('resize', updateCoords);
      // window.removeEventListener('scroll', updateCoords);
    };
    // eslint-disable-next-line
  }, []);

  const handleClick = e => {
    if (node && node.current && !node.current.contains(e.target)) {
      close();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <ItemWrapper>
      <Menu style={coords} ref={node}>
        {list.map(({ code, icon, text }) => (
          <Item key={text} onClick={e => handleSelect(e, code)}>
            <ItemIcon as={icon} style={IconStyle} />
            {text}
          </Item>
        ))}
      </Menu>
    </ItemWrapper>
  );
};

Popover.propTypes = {
  close: PropTypes.func.isRequired,
  coords: PropTypes.shape({
    left: PropTypes.number,
    top: PropTypes.number,
  }).isRequired,
  handleSelect: PropTypes.func.isRequired,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string,
      text: PropTypes.string,
      icon: PropTypes.func,
    }),
  ).isRequired,
  updateTooltipCoords: PropTypes.func.isRequired,
};
