import { OptionsIcon, PlaylistOption } from 'components/icons';
import React, { useContext, useEffect, useRef, useState, memo, useCallback } from 'react';
import { Trigger, Wrapper, Sort } from './styled';
import {
  addToQueueAction,
  connectAction,
  deleteCommentAction,
  getThenAddAction,
  playNextAction,
  removeFromQueueAction,
  sortTracksByTitleAction,
  sortTracksByArtistAction,
  // sortTracksByRecentAction,
  sortAlbumsByArtistAction,
  sortAlbumsByTitleAction,
} from 'redux/actions';
import {
  albumOptions,
  commentOptions,
  playlistOptions,
  queueTrackOptions,
  trackOptions,
  queueOptions,
  trackUploadOptions,
  userPlaylistOptions,
  sortOptions,
} from './data';
import { useDispatch, useSelector } from 'react-redux';

import { PlaylistContext } from 'contexts/PlaylistContext';
import { Popover } from './Popover';
import Portal from 'components/organisms/Portal';
import PropTypes from 'prop-types';
import { ShareContext } from 'contexts/ShareContext';
import { useHistory } from 'react-router-dom';
import { useTheme } from 'emotion-theming';
import { useToast } from 'hooks';
import { FullPlayerContext } from 'contexts/FullPlayerContext';

export const MoreOptions = memo(({ alternate, sort, fill, item, type, isOwner, ...props }) => {
  const [active, setActive] = useState(false);
  const [coords, setCoords] = useState({});
  const [list, setList] = useState([]);
  const { switchingText } = useTheme().colors;
  const node = useRef();
  const trigger = useRef();
  const { toggleCreatePlaylist, toggleDeletePlaylist, toggleManageTracks, toggleSelectPlaylist } = useContext(
    PlaylistContext,
  );
  const { toggleShareModal } = useContext(ShareContext);
  const dispatch = useDispatch();
  const { push } = useHistory();
  const connect = () => dispatch(connectAction(item, type));
  const deleteComment = () => dispatch(deleteCommentAction(item.id, item.parent.id, item.parent.target));
  const doPlayNext = (tracks, source, sourceID) => dispatch(playNextAction(tracks, source, sourceID));
  const doAddToQueue = (tracks, source, sourceID) => dispatch(addToQueueAction(tracks, source, sourceID));
  const getThenAdd = (entity, source, sourceID, mode) =>
    dispatch(getThenAddAction(item, entity, source, sourceID, mode));
  const sortTracks = items => dispatch(sortTracksByTitleAction(items));
  const sortArtist = items => dispatch(sortTracksByArtistAction(items));
  const sortAlbumTitle = items => dispatch(sortAlbumsByTitleAction(items));
  const sortAlbumArtist = items => dispatch(sortAlbumsByArtistAction(items));
  // const sortRecent = items => dispatch(sortTracksByRecentAction(items));
  const { addToast } = useToast();
  const removeFromQueue = () => dispatch(removeFromQueueAction(item));
  const isConnected = useSelector(
    state =>
      (state.connect.byTargetId[`${type}s`] &&
        state.connect.byTargetId[`${type}s`][item.id] &&
        state.connect.byTargetId[`${type}s`][item.id].active) ||
      false,
  );

  const getEntity = () => {
    if (type.includes('album')) {
      return 'album';
    }

    if (type.includes('track')) {
      return 'track';
    }

    if (type.includes('playlist')) {
      return 'playlist';
    }

    if (type.includes('mood')) {
      return 'mood';
    }
    return '';
  };

  const getSource = () => {
    if (type.includes('album')) {
      return 'album';
    }

    if (type.includes('playlist')) {
      return 'playlist';
    }

    if (type.includes('mood')) {
      return 'mood';
    }
    return 'album';
  };

  const playNext = () => {
    let tracks = [];
    if (getEntity() === 'track') {
      tracks = [item];
      doPlayNext(tracks, getSource(), item.id);
    } else {
      getThenAdd(getEntity(), getSource(), item.id, 'next');
    }
  };

  const addToQueue = () => {
    let tracks = [];
    if (tracks.length > 1) {
      addToast(`Track has been added to queue!`, { type: 'success' });
    } else {
      addToast(`Tracks have been added to queue!`, { type: 'success' });
    }
    if (getEntity() === 'track') {
      tracks = [item];
      doAddToQueue(tracks, getSource(), item.id);
    } else {
      getThenAdd(getEntity(), getSource(), item.id, 'last');
    }
  };

  const sortByTitle = () => {
    switch (type) {
      case 'sort-track':
        return sortTracks(item);
      case 'sort-album':
        return sortAlbumTitle(item);
      default:
        break;
    }
  };
  const sortByArtist = () => {
    switch (type) {
      case 'sort-track':
        return sortArtist(item);
      case 'sort-album':
        return sortAlbumArtist(item);
      default:
        break;
    }
  };
  // const sortByRecent = () => {
  //   return sortRecent(item);
  // };

  const getList = () => {
    switch (type) {
      case 'track':
        return trackOptions(isConnected, isOwner);
      case 'album':
        return albumOptions(isConnected, isOwner);
      case 'comment':
        return commentOptions(isOwner);
      case 'queue':
        return queueOptions();
      case 'mood':
      case 'playlist':
        return playlistOptions(isConnected);
      case 'queue-track':
        return queueTrackOptions;
      case 'track-upload':
        return trackUploadOptions;
      case 'user-playlist':
        return userPlaylistOptions;
      case 'sort-album':
        return sortOptions;
      case 'sort-track':
        return sortOptions;
      default:
        return trackOptions(isConnected);
    }
  };

  const [player, setPlayer] = useContext(FullPlayerContext); // eslint-disable-line

  const togglePlayer = useCallback(
    () => setPlayer(v => !v),
    // eslint-disable-next-line
    [],
  );

  useEffect(() => {
    setList(getList(type));
    // eslint-disable-next-line
  }, [type, isConnected]);

  const handleSelect = (e, value) => {
    e.stopPropagation();
    switch (value) {
      case 'add-playlist':
        toggleSelectPlaylist(item, type);
        break;
      case 'add-queue-last':
        addToQueue();
        break;
      case 'add-queue-next':
        playNext();
        break;
      case 'connect':
        connect();
        break;
      case 'delete-comment':
        deleteComment();
        break;
      case 'delete-playlist':
        toggleDeletePlaylist(item);
        break;
      case 'edit-album':
        push(`/upload/album/${item.id}`);
        break;
      case 'edit-playlist':
        toggleCreatePlaylist(item);
        break;
      case 'edit-track':
        push(`/upload/track/${item.id}`);
        break;
      case 'go-album':
        push(`/albums/${item.album}`);
        break;
      case 'go-artist':
        push(`/artists/${item.artist}`);
        break;
      case 'manage-tracks':
        toggleManageTracks(item);
        break;
      case 'q-go-album':
        push(`/albums/${item.album}`);
        togglePlayer();
        break;
      case 'q-go-artist':
        push(`/artists/${item.artist}`);
        togglePlayer();
        break;
      case 'q-add-playlist':
        toggleSelectPlaylist(item, 'track');
        togglePlayer();
        break;
      case 'remove-queue':
        removeFromQueue();
        break;
      case 'share':
        toggleShareModal(item, type);
        break;
      case 'q-share':
        toggleShareModal(item, type);
        togglePlayer();
        break;
      case 'by-title':
        sortByTitle();
        break;
      case 'by-artist':
        sortByArtist();
        break;
      default:
        break;
      // case 'by-recent':
      //   sortByRecent();
      // case 'track-info':
      //   toggleTrackInfo(item);
      //   break;
    }
    setActive(false);
  };

  const toggle = () => setActive(v => !v);

  const updateTooltipCoords = button => {
    const rect = button.getBoundingClientRect();
    const d = document.documentElement;
    // if (rect.y < 0 || rect.y > d.clientHeight) {
    //   setActive(false);
    //   return;
    // }

    const pos = {
      left: rect.x + rect.width / 2,
      top: rect.y,
    };

    let left = Math.min(d.clientWidth - button.clientWidth - 110, pos.left);
    let top = Math.min(d.clientHeight - button.clientHeight, pos.top);
    left = Math.max(0, left);
    top = Math.max(260, top);

    setCoords({ top, left });
  };

  const triggerClick = e => {
    e.stopPropagation();
    updateTooltipCoords(e.target);
    toggle();
  };

  const renderTrigger = () => {
    if (alternate) {
      return <PlaylistOption size="2rem" />;
    } else if (sort) {
      return <Sort>Sort</Sort>;
    } else {
      return (
        <OptionsIcon
          fill={fill || switchingText}
          style={{ cursor: 'pointer' }}
          height="1.6rem"
          width="1.6rem"
          vertical="true"
        />
      );
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      setActive(false);
    };

    document.addEventListener('scroll', handleScroll);
    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Wrapper ref={node} {...props}>
      <Trigger ref={trigger} color="transparent" size="small" onClick={triggerClick} aria-label="more-options">
        {renderTrigger()}
      </Trigger>

      {active && (
        <Portal>
          <Popover
            close={() => setActive(false)}
            coords={coords}
            handleSelect={handleSelect}
            list={list}
            updateTooltipCoords={() => updateTooltipCoords(trigger.current)}
          />
        </Portal>
      )}
    </Wrapper>
  );
});

MoreOptions.propTypes = {
  fill: PropTypes.string,
  isOwner: PropTypes.bool.isRequired,
  item: PropTypes.shape({
    album: PropTypes.string,
    artist: PropTypes.string,
    id: PropTypes.string,
    parent: PropTypes.shape({
      id: PropTypes.string,
      target: PropTypes.string,
    }),
  }).isRequired,
  type: PropTypes.string.isRequired,
};

MoreOptions.defailtProps = {
  fill: '',
};
