import React from 'react';
import PropTypes from 'prop-types';

function SvgTick() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="12" fill="url(#paint0_radial)" />
      <path d="M6 11.3646L10.4444 15.5999L18 8.3999" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
      <defs>
        <radialGradient
          id="paint0_radial"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(18.6968 -17) rotate(109.468) scale(65.9717 60.3371)"
        >
          <stop offset="0.1875" stop-color="#4CAF50" />
          <stop offset="1" stop-color="#8EFC2D" />
        </radialGradient>
      </defs>
    </svg>
  );
}

SvgTick.propTypes = {
  fill: PropTypes.string,
};

SvgTick.defaultProps = {
  fill: '#fff',
};

export default SvgTick;
