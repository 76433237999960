import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled/macro';
import { Box } from 'rebass';
import { Button } from 'components/atoms';

export const ButtonsWrapper = styled(Box)`
  align-items: center;
  justify-content: center;

  ${Button} {
    font-size: 1.2rem;
    font-weight: bold;
    &:not(:last-child) {
      margin-right: 1rem;
    }
  }

  &:not(:last-child) {
    margin-bottom: 2rem;
  }
`;

export const ActionButtons = ({ backClick, canContinue, canGoBack, continueClick, loading, ...props }) => {
  return (
    <ButtonsWrapper alignItems="center" {...props}>
      {canGoBack && (
        <Button effect onClick={backClick} color="searchbar" textColor="switchingText">
          BACK
        </Button>
      )}
      {canContinue && (
        <Button effect onClick={continueClick} loading={loading}>
          CONTINUE
        </Button>
      )}
    </ButtonsWrapper>
  );
};

ActionButtons.propTypes = {
  backClick: PropTypes.func.isRequired,
  continueClick: PropTypes.func.isRequired,
};

ActionButtons.defaultProps = {
  canContinue: true,
  canGoBack: true,
};
