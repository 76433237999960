import api from 'services/api';
import { firebase } from 'services/firebase';
import * as t from '../types';

export const getProfileAction = (callback = () => {}) => async (dispatch, getState) => {
  try {
    dispatch({ type: t.GET_PROFILE_REQUEST });
    const resp = await api.getProfile();
    if (resp.status === 200 || resp.status === 201) {
      const { data } = resp;
      callback(data, null);
      const { loggedIn } = getState().auth;
      dispatch({
        type: t.GET_PROFILE_SUCCESS,
        payload: loggedIn ? data : {},
      });
    } else {
      throw resp.data;
    }
  } catch (e) {
    dispatch({ type: t.GET_PROFILE_FAILURE });
    callback(null, e);
  }
};

export const getListeningActivityAction = () => async dispatch => {
  try {
    dispatch({ type: t.GET_LISTENING_ACTIVITY_REQUEST });
    const resp = await api.getListeningActivity({ size: 20 });
    if (resp.status === 200 || resp.status === 201) {
      const { data } = resp;
      dispatch({
        type: t.GET_LISTENING_ACTIVITY_SUCCESS,
        payload: data,
      });
    } else {
      throw resp.data;
    }
  } catch (e) {
    dispatch({ type: t.GET_LISTENING_ACTIVITY_FAILURE });
  }
};

export const updateProfileAction = (data, callback = () => {}) => async dispatch => {
  try {
    dispatch({ type: t.UPDATE_PROFILE_REQUEST });
    const resp = await api.updateProfile(data);
    if (resp.status === 200 || resp.status === 201) {
      dispatch({
        type: t.UPDATE_PROFILE_SUCCESS,
        payload: resp.data,
      });
      callback(resp.data, null);
    } else {
      throw resp.data;
    }
  } catch (e) {
    dispatch({ type: t.UPDATE_PROFILE_FAILURE });
    callback(null, e);
  }
};

export const changePasswordAction = ({ password, newPassword }, callback = () => {}) => async dispatch => {
  try {
    dispatch({ type: t.CHANGE_PASSWORD_REQUEST });
    const user = firebase.auth().currentUser;
    const cred = firebase.auth.EmailAuthProvider.credential(user.email, password);
    await user.reauthenticateWithCredential(cred);
    await user.updatePassword(newPassword);
    callback(true, null);
    dispatch({ type: t.CHANGE_PASSWORD_SUCCESS });
  } catch (e) {
    dispatch({ type: t.CHANGE_PASSWORD_FAILURE });
    callback(null, e);
  }
};

export const uploadAvatarAction = (avatar, callback = () => {}) => async (dispatch, getState) => {
  try {
    dispatch({ type: t.UPDATE_USER_AVATAR_REQUEST });
    const { id } = getState().profile.user;
    const resp = await api.uploadPhoto(avatar, id, 'users');
    if (resp.status === 200 || resp.status === 201) {
      dispatch({ type: t.UPDATE_USER_AVATAR_SUCCESS, payload: resp.data });
      callback(resp.data, null);
    } else {
      throw resp.data;
    }
  } catch (e) {
    dispatch({ type: t.UPDATE_USER_AVATAR_FAILURE });
    callback(null, e);
  }
};

export const claimRewardAction = (data, callback = () => {}) => async dispatch => {
  try {
    dispatch({ type: t.CLAIM_REWARD_REQUEST });
    const resp = await api.claimReward(data);
    if (resp.status === 200 || resp.status === 201) {
      dispatch({ type: t.CLAIM_REWARD_SUCCESS, payload: resp.data });
      dispatch(getProfileAction());
      callback(resp.data, null);
    } else {
      throw resp.data;
    }
  } catch (e) {
    dispatch({ type: t.CLAIM_REWARD_FAILURE });
    callback(null, e);
  }
};

export const setFcmTokenAction = (data, callback = () => {}) => async dispatch => {
  try {
    dispatch({ type: t.SET_FCM_TOKEN_REQUEST });
    const resp = await api.setFcmToken(data);
    if (resp.ok) {
      dispatch({ type: t.SET_FCM_TOKEN_SUCCESS });
      callback(resp.data, null);
    } else {
      throw resp.data;
    }
  } catch (e) {
    dispatch({ type: t.SET_FCM_TOKEN_FAILURE });
    callback(null, e);
  }
};

export const subscribeToTopicAction = (data, callback = () => {}) => async dispatch => {
  try {
    dispatch({ type: t.SUBSCRIBE_TO_TOPIC_REQUEST });
    const resp = await api.subscribeToTopic(data);
    if (resp.ok) {
      dispatch({ type: t.SUBSCRIBE_TO_TOPIC_SUCCESS });
      callback(resp.data, null);
    } else {
      throw resp.data;
    }
  } catch (e) {
    dispatch({ type: t.SUBSCRIBE_TO_TOPIC_FAILURE });
    callback(null, e);
  }
};
