import React from 'react';
import PropTypes from 'prop-types';

function SvgEdit({ fill, ...props }) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 204.451 204.451" {...props}>
      <defs>
        <filter id="edit_svg__a" x={0} y={0} width={204.451} height={204.451} filterUnits="userSpaceOnUse">
          <feOffset dy={1} />
          <feGaussianBlur stdDeviation={10} result="blur" />
          <feFlood floodOpacity={0.102} />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g transform="translate(0 -.002)" filter="url(#edit_svg__a)">
        <path
          data-name="icon-edit"
          d="M149.194 29h-97.57A21.638 21.638 0 0030 50.624v101.2a21.638 21.638 0 0021.624 21.624h101.2a21.638 21.638 0 0021.624-21.624V57.891l-14.444 21.67v72.266a7.194 7.194 0 01-7.18 7.18h-101.2a7.194 7.194 0 01-7.18-7.18V50.624a7.194 7.194 0 017.18-7.18h72.3L145.56 29zm10.1 5.1a7.222 7.222 0 1110.214 10.214L108.212 105.6l-15.319 5.106L98 95.389z"
          fill={fill}
          fillRule="evenodd"
        />
      </g>
    </svg>
  );
}

SvgEdit.propTypes = {
  fill: PropTypes.string,
};

SvgEdit.defaultProps = {
  fill: '#fff',
};

export default SvgEdit;
