import React from 'react';

function SvgApple({ ...props }) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M16.721 12.6832C16.7096 10.7159 17.5937 9.2333 19.3791 8.14007C18.3805 6.69782 16.8698 5.90458 14.8784 5.7517C12.9928 5.60171 10.9299 6.85935 10.1745 6.85935C9.37625 6.85935 7.55079 5.80362 6.11445 5.80362C3.15021 5.84977 0 8.18622 0 12.9399C0 14.3446 0.254649 15.7955 0.763948 17.2897C1.44492 19.2569 3.89986 24.0769 6.46066 23.9991C7.79971 23.9673 8.74678 23.0414 10.4893 23.0414C12.1803 23.0414 13.0558 23.9991 14.5494 23.9991C17.133 23.9616 19.3534 19.58 20 17.607C16.535 15.96 16.721 12.7841 16.721 12.6832ZM13.7139 3.88543C15.1645 2.14895 15.0329 0.568247 14.99 0C13.7082 0.0749971 12.226 0.879774 11.382 1.86916C10.4521 2.93066 9.90558 4.2431 10.0229 5.72285C11.4077 5.82958 12.6724 5.11134 13.7139 3.88543Z"
        fill="black"
      />
    </svg>
  );
}

export default SvgApple;
