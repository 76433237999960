import api from 'services/api';
import * as t from '../types';

export const uploadTrackAction = (file, progress, callback = () => {}, target) => async dispatch => {
  try {
    dispatch({ type: t.UPLOAD_TRACK_REQUEST, payload: { file } });
    const resp = await api.uploadTrack(file, target, progress);
    if (resp.status === 200 || resp.status === 201) {
      dispatch({ type: t.UPLOAD_TRACK_SUCCESS, payload: { file, data: resp.data } });
      callback(resp.data, null);
    } else {
      throw resp.data;
    }
  } catch (e) {
    dispatch({ type: t.UPLOAD_TRACK_FAILURE, payload: { file } });
    callback(null, e);
  }
};

export const uploadStudioxTrack = async (file, progress, callback = () => {}, target) => {
  try {
    const resp = await api.uploadTrack(file, target, progress);
    if (resp.status === 200 || resp.status === 201) {
      callback(resp.data, null);
    } else {
      throw resp.data;
    }
  } catch (e) {
    callback(null, e);
  }
};

export const myUploadsAction = () => async dispatch => {
  try {
    dispatch({ type: t.GET_UPLOADS_REQUEST });
    const resp = await api.uploadByMe();
    if (resp.status === 200 || resp.status === 201) {
      dispatch({ type: t.GET_UPLOADS_SUCCESS, payload: { data: resp.data } });
    } else {
      throw resp.data;
    }
  } catch (e) {
    dispatch({ type: t.GET_UPLOADS_FAILURE, payload: { error: e } });
  }
};
export const clearUploadAction = () => async dispatch => {
  dispatch({ type: t.CLEAR_UPLOAD_REQUEST });
};
