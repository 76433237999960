import React from 'react';
import PropTypes from 'prop-types';

function SvgMove({ fill, ...props }) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect y="7" width="24" height="1" rx="0.5" fill={fill} />
      <rect y="12" width="24" height="1" rx="0.5" fill={fill} />
      <rect y="17" width="24" height="1" rx="0.5" fill={fill} />
    </svg>
  );
}

SvgMove.propTypes = {
  fill: PropTypes.string,
};

SvgMove.defaultProps = {
  fill: '#fff',
};

export default SvgMove;
