import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import CreatePlaylistModal from 'components/organisms/CreatePlaylistModal';

import SelectPlaylistModal from 'components/organisms/SelectPlaylistModal';
import DeletePlaylistModal from 'components/organisms/DeletePlaylistModal';
import { useUser } from 'hooks';

import { AuthModalContext } from './AuthModalContext';
export const PlaylistContext = React.createContext({});

export const PlaylistProvider = ({ children }) => {
  const [createModal, setCreateModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [manageModal, setManageModal] = useState(false);
  const [selectModal, setSelectModal] = useState(false);
  const [playlist, setPlaylist] = useState({});
  const [item, setItem] = useState({});
  const [type, setType] = useState('');
  const { user } = useUser();
  const { toggleAuthModal } = useContext(AuthModalContext);

  const onClose = () => {
    setPlaylist({});
    setItem({});
    setType('');
  };

  const toggleCreatePlaylist = (v = {}) => {
    if (!user) return toggleAuthModal();
    if (createModal) {
      setCreateModal(false);
      onClose();
    } else {
      setPlaylist(v);
      setCreateModal(true);
    }
  };

  const toggleDeletePlaylist = (v = {}) => {
    if (!user) return toggleAuthModal();
    if (deleteModal) {
      setDeleteModal(false);
      onClose();
    } else {
      setPlaylist(v);
      setDeleteModal(true);
    }
  };

  const toggleManageTracks = (v = {}) => {
    if (!user) return toggleAuthModal();
    if (manageModal) {
      setManageModal(false);
      onClose();
    } else {
      setPlaylist(v);
      setManageModal(true);
    }
  };

  const toggleSelectPlaylist = (currentItem, entity) => {
    if (!user) return toggleAuthModal();
    if (selectModal) {
      setSelectModal(false);
      onClose();
    } else {
      setItem(currentItem);
      setType(entity);
      setSelectModal(true);
    }
  };
  return (
    <PlaylistContext.Provider
      value={{
        setCreateModal,
        setDeleteModal,
        setManageModal,
        setSelectModal,
        toggleCreatePlaylist,
        toggleDeletePlaylist,
        toggleManageTracks,
        toggleSelectPlaylist,
        playlist,
      }}
    >
      {children}
      <CreatePlaylistModal
        isOpen={createModal}
        item={item}
        playlist={playlist}
        toggle={toggleCreatePlaylist}
        type={type}
      />
      <DeletePlaylistModal isOpen={deleteModal} toggle={toggleDeletePlaylist} playlist={playlist} />
      <SelectPlaylistModal isOpen={selectModal} item={item} toggle={toggleSelectPlaylist} type={type} />
    </PlaylistContext.Provider>
  );
};

PlaylistProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
