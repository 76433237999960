import React from 'react';
import PropTypes from 'prop-types';

function SvgHideComment({ fill, ...props }) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 1L23 23" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.9848 18.5847C22.1888 17.8953 23 16.5979 23 15.1111V6.00003C23 3.79089 21.2091 2.00003 19 2.00003L6.00001 2C5.52176 2 5.06312 2.08393 4.638 2.23786L5.45037 3.05024C5.62856 3.01724 5.81227 3 6.00001 3L19 3.00003C20.6569 3.00003 22 4.34317 22 6.00003V15.1111C22 16.3247 21.2793 17.3701 20.2426 17.8425L20.9848 18.5847ZM4.19985 3.59989C3.47127 4.14722 3 5.01857 3 6L3 21.0984L7.08713 18.2872L7.34313 18.1111H7.65385L18.7111 18.1111L19.6573 19.0574C19.4435 19.0927 19.2239 19.1111 19 19.1111L7.65384 19.1111L3.56671 21.9224C2.90323 22.3787 2 21.9037 2 21.0984V6C2 4.74261 2.58017 3.62073 3.48741 2.88745L4.19985 3.59989Z"
        fill="white"
      />
    </svg>
  );
}

SvgHideComment.propTypes = {
  fill: PropTypes.string,
};

SvgHideComment.defaultProps = {
  fill: '#fcb316',
};

export default SvgHideComment;
