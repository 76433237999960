import React from 'react';

function SvgSun(props) {
  return (
    <svg height="1em" viewBox="0 0 496 496" width="1em" {...props}>
      <g fill="#ffec8f">
        <path d="M480 264h-24c-8.837 0-16-7.164-16-16s7.163-16 16-16h24c8.837 0 16 7.164 16 16s-7.163 16-16 16zM248 56c-8.837 0-16-7.164-16-16V16c0-8.836 7.163-16 16-16s16 7.164 16 16v24c0 8.836-7.163 16-16 16zM383.563 112.24c-6.246-6.25-6.243-16.381.007-22.627l17.07-17.06c6.251-6.247 16.381-6.244 22.628.006 6.246 6.25 6.243 16.381-.007 22.627l-17.07 17.06c-6.249 6.245-16.379 6.248-22.628-.006zM100.77 117.08a15.95 15.95 0 01-11.311-4.683L72.39 95.337c-6.25-6.247-6.253-16.377-.006-22.627 6.246-6.25 16.377-6.253 22.627-.006l17.069 17.06c10.118 10.112 2.812 27.316-11.31 27.316zM40 265H16c-8.837 0-16-7.164-16-16s7.163-16 16-16h24c8.837 0 16 7.164 16 16s-7.163 16-16 16zM400.989 424.027l-17.069-17.06c-6.25-6.247-6.253-16.377-.006-22.627 6.246-6.25 16.377-6.253 22.627-.006l17.069 17.06c6.25 6.247 6.253 16.377.006 22.627-6.244 6.248-16.375 6.254-22.627.006zM248 496c-8.837 0-16-7.164-16-16v-24c0-8.836 7.163-16 16-16s16 7.164 16 16v24c0 8.836-7.163 16-16 16zM72.254 423.88c-6.247-6.25-6.244-16.381.006-22.627l17.069-17.06c6.249-6.247 16.38-6.245 22.627.006 6.247 6.25 6.244 16.381-.006 22.627l-17.069 17.06c-6.248 6.245-16.38 6.247-22.627-.006z" />
      </g>
      <circle cx={248.5} cy={248.505} fill="#FCB316" r={155.5} />
      <path
        d="M361.645 258.983c-25.264-63.124-84.001-109.009-151.953-117.357-23.844-2.929-26.222-36.489-3.08-42.934 99.519-27.718 198.863 48.313 197.366 152.421-.346 24.062-33.392 30.213-42.333 7.87z"
        fill="#ffec8f"
      />
    </svg>
  );
}

export default SvgSun;
