import api from 'services/api';
import * as t from '../types';

export const getVideoScreenAction = (callback = () => {}) => async dispatch => {
  try {
    dispatch({ type: t.GET_VIDEO_SCREEN_REQUEST });
    const resp = await api.getVideoScreen();
    if (resp.status === 200 || resp.status === 201) {
      const { featured_videos, trending_videos, top_videos, playlists } = resp.data;

      dispatch({
        type: t.GET_VIDEO_BY_TAG_SUCCESS,
        payload: {
          tag: 'featured',
          data: featured_videos.data,
        },
      });

      dispatch({
        type: t.GET_VIDEO_BY_TAG_SUCCESS,
        payload: {
          tag: 'trending',
          data: trending_videos.data,
        },
      });

      dispatch({
        type: t.GET_VIDEO_BY_TAG_SUCCESS,
        payload: {
          tag: 'top',
          data: top_videos.data,
        },
      });

      dispatch({
        type: t.GET_VIDEO_BY_TAG_SUCCESS,
        payload: {
          tag: 'playlists',
          data: playlists
            .filter(v => !v.error)
            .map(v => ({
              id: v.id,
              name: v.name,
              artwork: v.artwork,
              data: v.data,
              // albumId: v.paging.first.split('/me/albums/')[1].split('/')[0] || '',
            })),
        },
      });

      dispatch({
        type: t.GET_VIDEO_SCREEN_SUCCESS,
      });
      callback(resp.data, null);
    } else {
      throw resp.data;
    }
  } catch (e) {
    callback(null, e);
    dispatch({ type: t.GET_VIDEO_SCREEN_FAILURE });
  }
};

export const getVideoAction = (id, callback = () => {}) => async dispatch => {
  try {
    dispatch({ type: t.GET_VIDEO_REQUEST, payload: id });
    const resp = await api.getVideo(id);
    if (resp.status === 200 || resp.status === 201) {
      dispatch({ type: t.GET_VIDEO_SUCCESS, payload: { id, data: resp.data } });
      callback(true, null);
    } else {
      throw resp.data;
    }
  } catch (e) {
    callback(null, e);
    dispatch({ type: t.GET_VIDEO_FAILURE, payload: id });
  }
};

export const getVideoPlaylistAction = (id, callback = () => {}) => async dispatch => {
  try {
    dispatch({ type: t.GET_VIDEO_PLAYLIST_REQUEST, payload: id });
    const resp = await api.getVideoPlaylist(id);
    if (resp.status === 200 || resp.status === 201) {
      dispatch({ type: t.GET_VIDEO_PLAYLIST_SUCCESS, payload: { id, data: resp.data } });
      callback(true, null);
    } else {
      throw resp.data;
    }
  } catch (e) {
    callback(null, e);
    dispatch({ type: t.GET_VIDEO_PLAYLIST_FAILURE, payload: id });
  }
};
