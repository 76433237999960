import React from 'react';
import PropTypes from 'prop-types';

function SvgExplore({ fill, ...props }) {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M12.0002 13.9721C12.7322 13.9721 13.3202 13.3781 13.3202 12.6521C13.3202 11.926 12.7322 11.332 12.0002 11.332C11.2742 11.332 10.6802 11.926 10.6802 12.6521C10.6802 13.3781 11.2742 13.9721 12.0002 13.9721Z"
        fill={fill}
      />
      <path
        d="M12 0.652344C5.37003 0.652344 0 6.02237 0 12.6523C0 19.2764 5.37003 24.6523 12 24.6523C18.63 24.6523 24 19.2764 24 12.6523C24.0001 6.02237 18.63 0.652344 12 0.652344ZM14.628 15.2803L4.79999 19.8524L9.372 10.0243L19.2 5.45233L14.628 15.2803Z"
        fill={fill}
      />
    </svg>
  );
}

SvgExplore.propTypes = {
  fill: PropTypes.string,
};

SvgExplore.defaultProps = {
  fill: '#fcb316',
};

export default SvgExplore;
