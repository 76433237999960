import React from 'react';

function SvgUser(props) {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="20.1536" cy="14.0769" r="5.07692" stroke="white" strokeWidth="1" fill="#272729" />
      <path
        d="M30.2994 27.0125L29.8013 27.0553V27.0553L30.2994 27.0125ZM10.0082 27.0125L10.5064 27.0553L10.0082 27.0125ZM29.4245 28.8678L29.6883 29.2925V29.2925L29.4245 28.8678ZM10.8832 28.8678L11.147 28.4431H11.147L10.8832 28.8678ZM10.9191 23.9424L10.467 23.7287L10.9191 23.9424ZM29.3886 23.9424L28.9366 24.1561L29.3886 23.9424ZM29.1606 28.4431C27.8976 29.2277 25.0919 30.5 20.1538 30.5V31.5C25.2739 31.5 28.2631 30.1779 29.6883 29.2925L29.1606 28.4431ZM20.1538 30.5C15.2157 30.5 12.41 29.2277 11.147 28.4431L10.6194 29.2925C12.0445 30.1779 15.0338 31.5 20.1538 31.5V30.5ZM9.51007 26.9698C9.4323 27.8758 9.80903 28.7892 10.6194 29.2925L11.147 28.4431C10.6995 28.1651 10.4563 27.6397 10.5064 27.0553L9.51007 26.9698ZM29.8013 27.0553C29.8514 27.6397 29.6082 28.1651 29.1606 28.4431L29.6883 29.2925C30.4986 28.7892 30.8754 27.8758 30.7976 26.9698L29.8013 27.0553ZM28.9366 24.1561C29.3215 24.9703 29.7127 26.0237 29.8013 27.0553L30.7976 26.9698C30.6944 25.7675 30.2478 24.59 29.8406 23.7287L28.9366 24.1561ZM10.5064 27.0553C10.595 26.0237 10.9862 24.9703 11.3711 24.1561L10.467 23.7287C10.0599 24.59 9.61326 25.7675 9.51007 26.9698L10.5064 27.0553ZM27.0113 22.0385H13.2964V23.0385H27.0113V22.0385ZM11.3711 24.1561C11.7022 23.4557 12.4508 23.0385 13.2964 23.0385V22.0385C12.1375 22.0385 10.9926 22.6169 10.467 23.7287L11.3711 24.1561ZM29.8406 23.7287C29.3151 22.6169 28.1702 22.0385 27.0113 22.0385V23.0385C27.8569 23.0385 28.6055 23.4557 28.9366 24.1561L29.8406 23.7287Z"
        fill="white"
      />
    </svg>
  );
}

export default SvgUser;
