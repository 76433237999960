import * as t from '../types';

const INITIAL_STATE = {
  byArtist: {},
  byTag: {},
  byUser: [],
  data: {},
  fetching: {},
  fetchingArtistTracks: false,
  newTrack: {},
  tracks: {},
  updatingTrack: false,
  onBoardingTracks: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case t.CLEAR_NEW_TRACK:
      return { ...state, newTrack: {} };
    case t.GET_ARTIST_TRACKS_REQUEST:
      return { ...state, fetchingArtistTracks: true };
    case t.GET_ARTIST_TRACKS_SUCCESS:
      return {
        ...state,
        byArtist: { ...state.byArtist, [action.payload.id]: action.payload.data },
        fetchingArtistTracks: false,
      };
    case t.GET_ARTIST_TRACKS_FAILURE:
      return { ...state, fetchingArtistTracks: false };

    case t.GET_TRACK_REQUEST:
      return { ...state, fetching: { ...state.fetching, [action.payload]: true } };

    case t.GET_TRACK_SUCCESS:
      return {
        ...state,
        data: { ...state.data, [action.payload.id]: action.payload.data },
        fetching: { ...state.fetching, [action.payload.id]: false },
      };
    case t.GET_MY_TRACK_SUCCESS:
      return {
        ...state,
        newTrack: action.payload.data,
      };
    case t.GET_TRACK_FAILURE:
      return { ...state, fetching: { ...state.fetching, [action.payload]: false } };

    case t.GET_TRACKS_BY_TAG_REQUEST:
      return { ...state, fetching: { ...state.fetching, [action.payload]: true } };

    case t.GET_TRACKS_BY_TAG_SUCCESS:
      return {
        ...state,
        fetching: { ...state.fetching, [action.payload.tag]: false },
        byTag: { ...state.byTag, [action.payload.tag]: action.payload.data },
      };

    case t.GET_TRACKS_BY_TAG_FAILURE:
      return { ...state, fetching: { ...state.fetching, [action.payload]: false } };

    case t.GET_USER_TRACKS_REQUEST:
      return { ...state, fetching: { ...state.fetching, byUser: true } };

    case t.GET_USER_TRACKS_SUCCESS:
      return { ...state, fetching: { ...state.fetching, byUser: false }, byUser: action.payload };

    case t.GET_USER_TRACKS_FAILURE:
      return { ...state, fetching: { ...state.fetching, byUser: false } };

    case t.UPDATE_TRACK_REQUEST:
      return { ...state, updatingTrack: true };
    case t.UPDATE_TRACK_SUCCESS:
      return {
        ...state,
        updatingTrack: false,
        newTrack: {},
      };
    case t.UPDATE_TRACK_FAILURE:
      return { ...state, updatingTrack: false };
    case t.GET_ONBOARDING_TRACKS_REQUEST:
      return { ...state, fetching: { ...state.fetching, onBoardingTracks: true } };
    case t.GET_ONBOARDING_TRACKS_SUCCESS:
      return { ...state, fetching: { ...state.fetching, onBoardingTracks: false }, onBoardingTracks: action.payload };
    case t.GET_CONNECTIONS_FAILURE:
      return { ...state, fetching: { ...state.fetching, onBoardingTracks: false } };
    case t.SORT_TRACKS_BY_TITLE:
      return { ...state, byUser: action.payload };
    case t.SORT_TRACKS_BY_ARTIST:
      return { ...state, byUser: action.payload };
    // case t.SORT_TRACKS_BY_RECENT:
    //   return { ...state, byUser: action.payload };
    case t.LOGOUT:
      return INITIAL_STATE;
    default:
      return { ...state };
  }
};
