import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useMeasure } from 'react-use';
import { MoreOptions } from 'components/organisms/MoreOptions';
import { Wrapper, Poster, Details, PlayBox, Overlay, Icons } from './styled';
import styled from '@emotion/styled/macro';
import { PlaylistPlay } from 'components/icons';
import { useDispatch, useSelector } from 'react-redux';
import { getPlaylistTracksAction, playTracksAction } from 'redux/actions';

const HeaderText = styled.h3`
  font-family: BwModelicaSS02, sans-serif;
  font-weight: 500;
  font-size: 1.8rem;
  opacity: 0.8;
  @media (max-width: ${props => props.theme.breakpoints[1]}) {
    font-size: 1.4rem;
  }
  @media (max-width: 375px) {
    font-size: 1.2rem;
  }
`;
const MainText = styled.p`
  font-weight: 300;
  font-size: 1rem;
  opacity: 0.6;
  overflow: hidden;
  text-overflow: ellipsis;
  @media (max-width: ${props => props.theme.breakpoints[1]}) {
    font-size: 0.9rem;
  }
`;

const More = styled(MoreOptions)``;

export const PlaylistTile = ({ detailStyle, height, item, listen, ...props }) => {
  const { artwork, name, id, description } = item;
  const [ref, { width }] = useMeasure();

  const type = 'playlist';
  const dispatch = useDispatch();
  const playTracks = () => dispatch(playTracksAction(tracks, 0, type, id));
  const getPlaylistTracks = cb => dispatch(getPlaylistTracksAction(id, cb));
  const tracks = useSelector(state => state.playlist.tracks[id] || []);

  const playPlaylist = () => {
    getPlaylistTracks((success, e) => {
      if (success) {
        playTracks();
        console.log('success getPlaylistTracks', id);
      } else {
        console.log('error getPlaylistTracks', id);
      }
    });
  };

  return (
    <Wrapper height={listen ? [`${width}px`] : height} {...props} ref={ref}>
      <Overlay as={Link} to={`/playlists/${id}`}></Overlay>
      <PlayBox as={Link} to={`/playlists/${id}`}>
        <Poster src={artwork} placeholder="playlist" alt={name} />
      </PlayBox>
      <Icons>
        <PlaylistPlay className="play" onClick={playPlaylist} />
        <More className="more" alternate type="playlist" item={item} />
      </Icons>
      <Details style={detailStyle}>
        <HeaderText>{name}</HeaderText>
        <MainText style={{ textOverflow: 'ellipsis' }}>{description}</MainText>
      </Details>
    </Wrapper>
  );
};

PlaylistTile.propTypes = {
  detailStyle: PropTypes.object,
  height: PropTypes.array,
  item: PropTypes.shape({
    artwork: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    owner: PropTypes.string.isRequired,
  }).isRequired,
  // listen: PropTypes.bool.isRequired,
};

PlaylistTile.defaultProps = {
  detailStyle: {},
  height: [],
};
