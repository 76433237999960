import React, { memo, useContext, useEffect, useState, useCallback } from 'react';
import { Artist, Artwork, ControlWrapper, InfoWrapper, Main, RightWrapper, Seeker, Title, Wrapper } from './styled';
import {
  LikeIcon,
  // LyricsIcon,
  NextIcon,
  PauseIcon,
  PlayIcon,
  PlaylistIcon2,
  PreviousIcon,
  RepeatIcon2,
  RepeatOneIcon,
  ShuffleIcon2,
  ExplicitIcon,
} from 'components/icons';
import { Link, useHistory } from 'react-router-dom';
import {
  connectAction,
  nextTrackAction,
  pauseAction,
  playAction,
  previousTrackAction,
  togglePlayAction,
  toggleRepeatAction,
  toggleShuffleAction,
  validPlayAction,
  playTracksAction,
} from 'redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from 'rebass';
import Helmet from 'react-helmet';
import { Marquee } from 'components/molecules';
import { PlayerContext } from 'contexts/PlayerContext';
import { PlaylistContext } from 'contexts/PlaylistContext';
import PropTypes from 'prop-types';
import { Queue } from './Queue';
import { SpacebarContext } from 'contexts/SpacebarContext';
import { Spinner } from 'components/atoms';
import { SubscribeAlert } from 'components/atoms/SubscribeAlert';
import { Volume } from './Volume';
import appConfig from 'config/app.config';
import { isObjEmpty } from 'lib/utils';
import styled from '@emotion/styled/macro';
import { useUser } from 'hooks';
import { FullPlayerContext } from 'contexts/FullPlayerContext';
import { useMedia } from 'react-use';

const Alert = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 80px;
  z-index: 4;
`;

const Player = memo(({ visible }) => {
  const dispatch = useDispatch();
  const [hasPlayed, setHasPlayed] = useState(false);
  const { playerRef, state } = useContext(PlayerContext);
  const hist = useHistory();
  const path = hist.location.pathname.split('/')[1];
  const isWide = useMedia('(max-width: 572px)');
  const {
    currentIndex,
    currentItem,
    magic,
    magicQueue,
    playing,
    queue: normalQueue,
    repeat,
    shuffle,
    shuffleQueue,
  } = useSelector(({ player }) => player);
  const isConnected = useSelector(
    ({ connect }) =>
      (connect.byTargetId.tracks &&
        connect.byTargetId.tracks[currentItem?.id] &&
        connect.byTargetId.tracks[currentItem?.id].active) ||
      false,
  );
  const { toggleSelectPlaylist } = useContext(PlaylistContext);

  // eslint-disable-next-line no-nested-ternary
  const queue = magic ? magicQueue : shuffle ? shuffleQueue : normalQueue;

  const connect = () => dispatch(connectAction(currentItem, 'track'));
  const nextTrack = () => dispatch(nextTrackAction());
  const pause = () => dispatch(pauseAction());
  const play = () => dispatch(playAction());
  const previousTrack = () => dispatch(previousTrackAction());
  const togglePlay = () => dispatch(togglePlayAction());
  const toggleRepeat = () => dispatch(toggleRepeatAction());
  const toggleShuffle = () => dispatch(toggleShuffleAction());
  const validPlay = () => dispatch(validPlayAction());
  const [value] = useContext(SpacebarContext);
  const userSub = useSelector(({ profile }) => profile.user || {});
  const { user } = useUser();
  const [player, setPlayer] = useContext(FullPlayerContext); // eslint-disable-line
  const replayCurrentTrack = index => dispatch(playTracksAction(queue, index, currentItem?.id));
  // const togglePlayer = useCallback(
  //   () => setPlayer(v => !v),
  //   // eslint-disable-next-line
  //   [],
  // );

  const togglePlayer = useCallback(
    () => setPlayer(v => !v),
    // eslint-disable-next-line
    [],
  );

  useEffect(() => {
    if (state.playedSeconds > 30 && !currentItem?.validPlay) {
      validPlay();
    }
    // eslint-disable-next-line
  }, [state.playedSeconds]);

  const canGo = () => {
    let next = false;
    let prev = false;
    const item = queue[currentIndex] || {};
    const { index } = item;
    if (index !== 0 && index < queue.length) {
      next = true;
      prev = true;
    }
    if (index === 0) {
      next = true;
    }
    if (index >= queue.length - 1) {
      next = false;
    }
    return {
      next,
      prev,
    };
  };

  useEffect(() => {
    (async () => {
      if (!hasPlayed && playing) {
        setHasPlayed(true);
        await pause();
        await play();
      }
    })();
    // eslint-disable-next-line
  }, [playing, hasPlayed]);

  const goToNextTrack = () => {
    if (canGo().next) {
      nextTrack();
    }
  };

  const goToPreviousTrack = () => {
    if (canGo().prev && state.playedSeconds <= 3) {
      previousTrack();
    } else if (state.playedSeconds >= 3) {
      replayCurrentTrack(currentItem.index);
    }
  };

  const KeyboardControl = () => {
    document.body.onkeyup = function(e) {
      e.preventDefault();
      if ((e.keyCode === 32 || e.code === 'Space') && value === false && e.target.nodeName !== 'INPUT') {
        togglePlay();
      } else if ((e.keyCode === 37 || e.code === 'ArrowLeft') && e.target.nodeName !== 'INPUT') {
        goToPreviousTrack();
      } else if ((e.keyCode === 39 || e.code === 'ArrowRight') && e.target.nodeName !== 'INPUT') {
        goToNextTrack();
      }
    };
  };

  useEffect(() => {
    KeyboardControl();
    // eslint-disable-next-line
  }, []);

  const thumbnail = (url, size) => {
    if (url) {
      if (url.includes(appConfig.imageServer)) {
        const replaced = url.replace(appConfig.imageServer, '');
        const filename = replaced.split('?')[0];
        return `${appConfig.baseURL}thumbnailer/${size}/${filename}?api=${appConfig.imageApiKey}`;
      }
      return url;
    }
    return '';
  };

  const renderPlayIcon = () => {
    return playing ? (
      <PauseIcon onClick={pause} size="2.3rem" className="pause" />
    ) : (
      <PlayIcon onClick={play} size="2.3rem" className="play" />
    );
  };

  const renderSpinner = () => {
    if (state.isBuffering) {
      return <Spinner style={{ marginRight: '2.7rem' }} />;
    }
    return renderPlayIcon();
  };

  const addToPlaylist = () => {
    toggleSelectPlaylist(currentItem, 'track');
  };

  useEffect(() => {
    if ('mediaSession' in navigator) {
      navigator.mediaSession.metadata = new window.MediaMetadata({
        title: currentItem?.title,
        artist: currentItem?.artist_name,
        album: currentItem?.album_name,
        artwork: [
          { src: `${thumbnail(currentItem?.artwork, '96x96')}`, sizes: '96x96' },
          { src: `${thumbnail(currentItem?.artwork, '128x128')}`, sizes: '128x128' },
          { src: `${thumbnail(currentItem?.artwork, '192x192')}`, sizes: '192x192' },
          { src: `${thumbnail(currentItem?.artwork, '256x256')}`, sizes: '256x256' },
          { src: `${thumbnail(currentItem?.artwork, '384x384')}`, sizes: '384x384' },
          { src: `${thumbnail(currentItem?.artwork, '512x512')}`, sizes: '512x512' },
        ],
      });
      navigator.mediaSession.setActionHandler('play', play);
      navigator.mediaSession.setActionHandler('pause', pause);
      navigator.mediaSession.setActionHandler('previoustrack', goToPreviousTrack);
      navigator.mediaSession.setActionHandler('nexttrack', goToNextTrack);
    }
    // eslint-disable-next-line
  }, [currentItem]);

  const renderRepeatIcon = () => {
    switch (repeat) {
      case 0:
        return <RepeatIcon2 size="1.8rem" onClick={toggleRepeat} className="xs-hide repeat" />;
      case 1:
        return <RepeatIcon2 size="1.8rem" onClick={toggleRepeat} className="xs-hide repeat" fill="#ffa700" />;
      case 2:
        return <RepeatOneIcon size="1.8rem" onClick={toggleRepeat} className="xs-hide repeat" fill="#ffa700" />;
      default:
        return <RepeatIcon2 size="1.8rem" onClick={toggleRepeat} className="xs-hide repeat" />;
    }
  };

  const memorizedSeek = useCallback(x => {
    if (playerRef && playerRef.current) {
      playerRef.current.seekTo(x / 100, 'fraction');
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Main style={{ display: path === 'livestreams' || path === 'videos' ? 'none' : 'block' }}>
      <Alert
        visible={visible}
        style={{
          bottom: visible ? '80px' : '0',
        }}
      >
        {user !== null && !isObjEmpty(userSub) && userSub?.subscription?.status !== 'ACTIVE' && (
          <SubscribeAlert style={{ display: path === '/premium' || '/livestream' ? 'none' : 'block' }} />
        )}
      </Alert>

      {playing && !isObjEmpty(currentItem) && (
        <Helmet>
          {currentItem?.name} · {currentItem?.artist_name}
        </Helmet>
      )}

      <Wrapper
        visible={visible}
        style={{
          background:
            currentItem?.hasColor || currentItem?.color
              ? `rgb(${currentItem?.color?.r}, ${currentItem?.color?.g}, ${currentItem?.color?.b})`
              : '#131516',
        }}
      >
        <Seeker
          video={false}
          duration={currentItem?.duration || state.duration}
          onSeek={memorizedSeek}
          played={state.playedSeconds}
          progress={state.played * 100}
          seekable
          textColor="switchingText"
        />
        <InfoWrapper maxWidth={[null, null, '20rem', '30rem']} flexGrow={1}>
          {currentItem?.artwork && (
            <Artwork src={currentItem?.artwork} onClick={togglePlayer} placeholder="track" alt={currentItem?.name} />
          )}
          <Box flexGrow="1" display="flex" flexDirection="column" width="4rem" style={{ overflow: 'hidden' }}>
            <Marquee>
              <Title as={Link} to={`/albums/${currentItem?.album}`}>
                {currentItem?.name}
                {currentItem?.parental_warning === 'Explicit' && <ExplicitIcon size="1rem" />}
              </Title>
            </Marquee>

            <Marquee>
              <Artist
                aria-label={`${currentItem?.artist_name} artist link`}
                as={Link}
                to={`/artists/${currentItem?.artist}`}
              >
                {currentItem?.artist_name}
              </Artist>
            </Marquee>
          </Box>
          <LikeIcon
            size="0.4em"
            onClick={e => {
              e.stopPropagation();
              connect();
            }}
            style={{ cursor: 'pointer', marginLeft: '1rem', marginRight: isWide ? '2rem' : '4rem' }}
            fill={isConnected === true ? 'url(#paint0_linear)' : 'transparent'}
          />
          {/* <Duration>{formatTime(state.playedSeconds)}</Duration>
          <Duration style={{ marginRight: '2.8rem' }}>{formatTime(state.duration)}</Duration> */}
        </InfoWrapper>

        <ControlWrapper>
          {!magic && (
            <ShuffleIcon2
              onClick={toggleShuffle}
              className="xs-hide shuffle"
              fill={shuffle ? '#FFA700' : '#fff'}
              size="1.8rem"
            />
          )}
          {magic ? (
            <PreviousIcon onClick={goToNextTrack} style={{ padding: '0 1px' }} fill="#fff" className="prev" />
          ) : (
            <PreviousIcon
              onClick={goToPreviousTrack}
              fill="#fff"
              style={{ padding: '0 1px' }}
              size="1.5rem"
              className="prev"
            />
          )}
          {renderSpinner()}
          <NextIcon onClick={goToNextTrack} fill="#fff" style={{ padding: '0 1px' }} className="next" />
          {renderRepeatIcon()}
        </ControlWrapper>

        <RightWrapper>
          {/* <Duration>{formatTime(state?.playedSeconds)}</Duration>
          <span style={{ color: 'white', fontSize: '12px' }}>/</span>
          <Duration style={{ marginRight: '2.8rem' }}>{formatTime(state?.duration)}</Duration> */}

          <Volume style={{ marginRight: '3rem', marginLeft: '1rem' }} />
          <PlaylistIcon2
            className="xs-hide"
            height="2rem"
            onClick={addToPlaylist}
            style={{ transform: 'translateY(2px)' }}
            width="2rem"
            fill="#fff"
          />
          {/* <LyricsIcon /> */}
          {!magic && <Queue queue={queue} />}
        </RightWrapper>
      </Wrapper>
    </Main>
  );
});

Player.propTypes = {
  visible: PropTypes.bool.isRequired,
};

export default Player;
