import React from 'react';
import PropTypes from 'prop-types';

function SvgAddMoney({ fill, ...props }) {
  return (
    <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path
          d="M30.9533 9.3938C29.8074 9.09369 28.6752 8.90271 27.5293 8.80722C27.5293 8.73901 27.5293 8.68445 27.5293 8.62988C28.6479 8.73901 29.7528 8.92999 30.8714 9.21646H30.8851H30.8987C30.8987 9.21646 30.8987 9.21646 30.9124 9.21646H30.926C30.9396 9.21646 30.9396 9.21646 30.9533 9.21646C30.9533 9.28467 30.9533 9.33923 30.9533 9.3938Z"
          fill="#F2994A"
        />
        <path
          d="M31.1172 23.6899C26.3018 22.4076 21.4864 23.0488 16.671 23.6899C11.8556 24.3311 7.04021 24.9722 2.2248 23.6899C2.2248 23.6354 2.02018 23.458 2.02018 23.4035C2.00654 18.6835 2.21116 14.1137 2.21116 9.38012C7.02657 10.676 11.8283 10.0076 16.6437 9.33919C21.4455 8.67077 26.2473 8.00234 31.0627 9.28463C31.0627 14.0591 31.1172 18.9291 31.1172 23.6899Z"
          fill="url(#paint0_linear)"
        />
        <path
          opacity="0.4"
          d="M30.9555 23.5535C30.9555 18.7791 30.9555 14.0182 30.9555 9.24374C29.8096 8.94363 28.6773 8.73901 27.5315 8.62988C27.4633 10.4988 27.7907 20.1159 26.7539 22.8715C28.159 22.967 29.5504 23.1716 30.9555 23.5535Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M4.24414 14.1002C4.24414 15.9418 4.24414 17.7834 4.24414 19.6113C5.45822 19.8023 6.4404 20.8254 6.4404 22.0395C13.1383 22.4487 19.8499 20.2525 26.5478 20.6481C26.5478 19.434 27.53 18.4927 28.7441 18.6837C28.7441 16.8421 28.7441 15.0005 28.7441 13.1726C27.53 12.9816 26.5478 11.9585 26.5478 10.7444C19.8499 10.3352 13.1383 12.5315 6.4404 12.1359C6.4404 13.3363 5.45822 14.2776 4.24414 14.1002Z"
          fill="url(#paint2_linear)"
        />
        <path
          d="M20.1245 15.9413C20.1245 17.9329 18.5011 19.7336 16.5095 20.0064C14.5179 20.2793 12.8945 18.8469 12.8945 16.8416C12.8945 14.85 14.5179 13.0493 16.5095 12.7765C18.5011 12.5037 20.1245 13.936 20.1245 15.9413Z"
          fill="url(#paint3_linear)"
        />
        <path
          d="M10.6837 17.0473C10.6837 18.0021 9.9061 18.8479 8.9512 18.8616C7.99631 18.8888 7.21875 18.084 7.21875 17.1155C7.21875 16.1606 7.99631 15.4103 8.9512 15.383C9.9061 15.3694 10.6837 16.0787 10.6837 17.0473Z"
          fill="url(#paint4_linear)"
        />
        <path
          d="M22.3203 15.7368C22.3203 16.7053 23.0979 17.4147 24.0528 17.3874C25.0077 17.3601 25.7852 16.6099 25.7852 15.655C25.7852 14.7001 25.0077 13.8816 24.0528 13.9089C23.0979 13.9361 22.3203 14.7819 22.3203 15.7368Z"
          fill="#FDB913"
        />
        <path
          d="M10.409 10.1038C10.409 10.0356 10.4227 9.96741 10.4227 9.8992C12.387 9.77643 14.365 9.51724 16.3294 9.25806C16.384 9.24442 16.4385 9.24442 16.4931 9.23077C16.5067 9.23077 16.534 9.23077 16.5477 9.21713C16.5613 9.21713 16.5613 9.21713 16.5749 9.21713C16.6568 9.20349 16.725 9.20349 16.8068 9.18985C16.8478 9.18985 16.875 9.17621 16.916 9.17621C16.9296 9.17621 16.9433 9.17621 16.9569 9.17621L16.9705 9.17621C16.9842 9.17621 16.9978 9.17621 17.0115 9.17621C17.0524 9.17621 17.0797 9.16257 17.1206 9.16257C17.1206 9.16257 17.1342 9.16257 17.1479 9.16257C17.1615 9.16257 17.1888 9.16257 17.2024 9.14893C19.7125 8.80789 22.2497 8.49414 24.7734 8.49414C24.8007 8.49414 24.8143 8.49414 24.8416 8.49414C24.8689 8.49414 24.8962 8.49414 24.9235 8.49414C26.9015 8.49414 28.8931 8.69876 30.8711 9.21713C29.7525 8.93066 28.6476 8.73968 27.529 8.63055C27.529 8.68512 27.529 8.73968 27.529 8.80789C26.6832 8.72604 25.8374 8.69876 24.9917 8.69876C22.2088 8.69876 19.426 9.06708 16.6431 9.43539C14.5697 9.70822 12.4962 9.98105 10.409 10.1038ZM3.09726 9.48996C3.08362 9.48996 3.08362 9.48996 3.06998 9.48996C3.08362 9.48996 3.09726 9.48996 3.09726 9.48996ZM3.06998 9.48996C2.72894 9.42175 2.38791 9.33991 2.04687 9.24442C2.06052 9.24442 2.06052 9.24442 2.07416 9.25806C2.41519 9.33991 2.74259 9.40811 3.06998 9.48996Z"
          fill="#F2994A"
        />
        <path
          d="M2.19888 23.5812C2.14432 23.5676 2.10339 23.5539 2.04883 23.5403C2.04883 18.7658 2.04883 14.0049 2.04883 9.23047C2.38986 9.32596 2.7309 9.40781 3.07193 9.47601C3.08557 9.47601 3.08557 9.47601 3.09921 9.47601C4.79074 9.83069 6.46863 9.9671 8.16016 9.9671C8.91044 9.9671 9.67436 9.93982 10.4246 9.8989C10.4246 9.9671 10.411 10.0353 10.411 10.1035C9.71528 10.1444 9.00593 10.1717 8.31022 10.1717C6.27765 10.1717 4.23145 9.9671 2.19888 9.42145C2.19888 14.155 2.19888 18.8613 2.19888 23.5812Z"
          fill="#F2994A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.8162 14.9705L17.7229 16.4064C17.6962 16.6805 17.5496 16.9546 17.3363 17.1635C17.123 17.3723 16.843 17.5029 16.5631 17.529C16.2831 17.5551 16.0032 17.4768 15.8032 17.3071C15.6032 17.1374 15.4832 16.8763 15.4699 16.6022L15.5632 15.1011L14.6167 15.1533L14.59 15.6363L13.6835 15.6885L13.6568 16.067L14.5634 16.0148L14.55 16.302L13.6302 16.3411L13.6035 16.7197L14.51 16.6544C14.51 17.1765 14.71 17.6595 15.07 17.9989C15.4299 18.3252 15.9365 18.4949 16.4698 18.4427C16.9897 18.4035 17.5096 18.1555 17.9095 17.777C18.3095 17.3984 18.5494 16.8894 18.6161 16.3803L19.4026 16.3281L19.4293 15.9626L18.6428 16.0148L18.6561 15.7407L19.4426 15.6885L19.4693 15.323L18.6828 15.3752L18.7094 14.9053L17.8162 14.9705Z"
          fill="white"
        />
        <g clipPath="url(#clip1)">
          <circle cx="27.5" cy="21" r="5" fill="white" />
          <path d="M30.228 21L24.7734 21" stroke="#FDB913" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M27.5 23.727V18.2725" stroke="#FDB913" strokeLinecap="round" strokeLinejoin="round" />
        </g>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="16.5622"
          y1="24.44"
          x2="16.5622"
          y2="8.55432"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDB913" />
          <stop offset="1" stopColor="#F2994A" />
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="28.0078"
          y1="16.1145"
          x2="30.8725"
          y2="16.0463"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF7900" stopOpacity="0" />
          <stop offset="0.6979" stopColor="#FF7900" />
        </linearGradient>
        <linearGradient
          id="paint2_linear"
          x1="16.5024"
          y1="22.0922"
          x2="16.5024"
          y2="10.6916"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FACB5B" />
          <stop offset="1" stopColor="#F5DEA4" />
        </linearGradient>
        <linearGradient
          id="paint3_linear"
          x1="31.1767"
          y1="23.6552"
          x2="2.06597"
          y2="9.24457"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDB913" />
          <stop offset="1" stopColor="#F2994A" />
        </linearGradient>
        <linearGradient
          id="paint4_linear"
          x1="29.6782"
          y1="27.3379"
          x2="0.451689"
          y2="12.9272"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDB913" />
          <stop offset="1" stopColor="#F2994A" />
        </linearGradient>
        <clipPath id="clip0">
          <rect width="32" height="32" fill="white" transform="translate(0.5)" />
        </clipPath>
        <clipPath id="clip1">
          <rect width="10" height="10" fill="white" transform="translate(22.5 16)" />
        </clipPath>
      </defs>
    </svg>
  );
}

SvgAddMoney.propTypes = {
  fill: PropTypes.string,
};

SvgAddMoney.defaultProps = {
  fill: '#fff',
};

export default SvgAddMoney;
