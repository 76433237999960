import * as React from 'react';

function SvgLeft(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M11.67 2.39609L9.9 0.682495L0 10.267L9.9 19.8515L11.67 18.1379L3.54 10.267L11.67 2.39609Z"
        fill="white"
      />
    </svg>
  );
}

export default SvgLeft;
