import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ShareModal from 'components/organisms/ShareModal';

export const ShareContext = React.createContext({});

export const ShareProvider = ({ children }) => {
  const [modal, setModal] = useState(false);
  const [item, setItem] = useState({});
  const [type, setType] = useState('');

  const toggle = (currentItem, entity) => {
    if (modal) {
      setModal(false);
      setItem({});
      setType('');
    } else {
      setModal(true);
      setItem(currentItem);
      setType(entity);
    }
  };

  return (
    <ShareContext.Provider
      value={{
        setModal,
        modal,
        toggleShareModal: toggle,
      }}
    >
      {children}
      <ShareModal isOpen={modal} toggle={toggle} item={item} type={type} />
    </ShareContext.Provider>
  );
};

ShareProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
