import { PlayBox, Poster, Subtitle, Title } from './styled';
import React, { useEffect, useState } from 'react';
import { getAlbumAction, getAlbumTracksAction, playTracksAction } from 'redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { PlayIcon } from 'components/icons';
import PropTypes from 'prop-types';
import { ellipsize } from 'lib/utils';
import styled from '@emotion/styled/macro';
import { useUser } from 'hooks';

const AlbumTitle = styled(Title)`
  font-weight: 450;
  margin-top: 0.4rem;
  opacity: 0.8;
  margin-bottom: 0.4rem !important;
  color: ${({ detColor }) => detColor};
`;

const Image = styled(Poster)`
  border-radius: 8px;
  background-size: cover;
  object-fit: cover;
`;

const AlbumContainer = styled.div`
  width: 21rem;
  text-align: left;
  cursor: pointer;
  text-decoration: none;
  &:hover {
    img {
      transform: scale(1.12);
      transition: transform 1s ease-out;
    }
  }
  &:hover {
    ${AlbumTitle} {
      color: ${({ theme }) => theme.colors.primary};
    }
  }
  @media (max-width: ${props => props.theme.breakpoints[1]}) {
    width: 13rem;
  }
  ${Image} {
    &:hover {
      filter: brightness(0.8);
    }
  }
`;

const ArtistName = styled(Subtitle)`
  margin-top: 0rem;
  font-weight: 300;
  color: ${({ titleColor }) => titleColor};
  opacity: 0.6;
  :hover {
    text-decoration: underline;
  }
`;

export const AlbumTile = ({ detailStyle, height, item, ...props }) => {
  const { artwork, name, artist_name, artist, id } = item;
  const dispatch = useDispatch();
  const [setError] = useState('');
  const { user } = useUser();
  const getAlbum = cb => dispatch(getAlbumAction(id, cb));
  const getAlbumTracks = () => dispatch(getAlbumTracksAction(id));
  const type = 'album';
  const tracks = useSelector(state => state.album.tracks[id] || []);
  const playTracks = () => dispatch(playTracksAction(tracks, 0, type, id));

  useEffect(() => {
    getAlbum((success, e) => {
      if (success) {
        getAlbumTracks();
      } else if (e) {
        setError(e?.error || 'An error occured');
      }
    });
    // eslint-disable-next-line
  }, [id, user]);

  return (
    <AlbumContainer as={Link} to={`/albums/${id}`} {...props}>
      <PlayBox position="relative">
        <Image src={artwork} placeholder="album" alt={name} />
        <PlayIcon onClick={playTracks} fill="#FFA700" width="6rem" height="6rem" />
      </PlayBox>
      <AlbumTitle ellipsize>{ellipsize(name, 20)}</AlbumTitle>
      <ArtistName as={Link} to={`/artists/${artist}`} ellipsize>
        {ellipsize(artist_name, 34)}
      </ArtistName>
    </AlbumContainer>
  );
};

AlbumTile.propTypes = {
  detailStyle: PropTypes.object,
  height: PropTypes.array,
  item: PropTypes.shape({
    artist_name: PropTypes.string,
    artist: PropTypes.string.isRequired,
    artwork: PropTypes.string.isRequired,
    by: PropTypes.string,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    owner_name: PropTypes.string,
    owner: PropTypes.string,
  }),
};

AlbumTile.defaultProps = {
  detailStyle: {},
  height: [],
  item: {
    artist_name: '',
    by: '',
  },
};
