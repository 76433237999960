import React from 'react';

function SvgPhone({ ...props }) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M10.4459 3.93798L7.0746 0.566645C6.76165 0.253701 6.24186 0.266111 5.91361 0.59436L3.56608 2.94189C0.594367 5.9136 5.08114 11.5891 8.47107 14.979C11.8407 18.3486 17.5649 22.8842 20.5366 19.9124L22.8842 17.5649C23.2124 17.2367 23.2248 16.7169 22.9119 16.4039L19.5406 13.0326C19.2276 12.7197 18.7078 12.7321 18.3796 13.0603L16.0321 15.4078C16.0321 15.4078 14.901 15.4056 11.457 12.0215C8.01311 8.63735 8.07071 7.44646 8.07071 7.44646L10.4182 5.09896C10.7465 4.77071 10.7589 4.25092 10.4459 3.93798Z"
        fill="white"
      />
    </svg>
  );
}

export default SvgPhone;
