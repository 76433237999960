import { DoneIcon, ExplicitIcon, LikeIcon, PlusIcon } from 'components/icons';
import { Image, Typography } from 'components/atoms';
import React, { memo, useContext, useState, useCallback } from 'react';
import { formatTime, isObjEmpty } from 'lib/utils';
import { useDispatch, useSelector } from 'react-redux';
import { AddSongContext } from 'contexts/AddSongContext';
import { Box } from 'rebass';
// import { FullPlayerContext } from 'contexts/FullPlayerContext';
import { Link } from 'react-router-dom';
import { Marquee } from '../Marquee';
import { MoreOptions } from 'components/organisms/MoreOptions';
import PropTypes from 'prop-types';
import { Subtitle } from '../Tile/styled';
import { connectAction, pauseAction, playAction } from 'redux/actions';
import { css } from '@emotion/core';
import styled from '@emotion/styled/macro';
import { useToast } from 'hooks';
import { PauseIcon, PlayIcon } from 'components/icons';

const Title = styled(Typography.Text)`
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.font.size.tiny};
  text-decoration: none;
  transition: all 0.1s;
  opacity: 0.8;
  display: flex;
  align-items: baseline;
  font-weight: 500;
  svg {
    margin-left: 1rem;
  }
`;

const Artist = styled(Subtitle)`
  font-weight: 300;
  color: ${({ titleColor }) => titleColor} !important;
  opacity: 0.6;
  display: flex;
  align-items: center;
  margin-top: 0.3rem;
  :hover {
    text-decoration: underline;
  }
`;

const Artwork = styled(Image)`
  flex-shrink: 0;
  height: 4rem;
  width: 4rem;
  border-radius: 4.5px;
`;

// const Duration = styled(Typography.Text)`
//   color: ${({ theme }) => theme.colors['brown-grey']};
//   display: inline-block;
//   min-width: 4.3rem;
//   text-align: right;
// `;

const Number = styled(Typography.Text)`
  color: #fff;
  display: inline-block;
  width: 1.6rem;
  font-size: 1.3rem;
  color: ${({ theme }) => theme.colors.switchingText};
  opacity: 0.7;
`;

const AlbumTrackItemWrapper = styled(Box)`
  align-items: center;
  cursor: pointer;
  transition: all 0.1s;
  position: relative;
  border-radius: 4px;
  z-index: 1;
  &:after {
    background-color: rgba(255, 255, 255, 0.06);
    content: '';
    height: 1px;
    position: absolute;
    top: 100%;
    width: 99%;
  }

  .play {
    &:hover {
      transform: scale(1.4);
      transition-duration: 0.15s;
    }
  }
  .pause {
    &:hover {
      transform: scale(1.4);
      transition-duration: 0.15s;
    }
  }

  &:hover {
    background-color: #131516;
    transition: all 0.3s ease-in;

    ${Title} {
      color: ${({ theme }) => theme.colors.primary};
    }
    ${Number} {
      color: ${({ theme }) => theme.colors.primary};
      font-weight: 500;
    }
  }

  ${({ active, theme }) =>
    active &&
    css`
      background-color: ${theme.colors.switchingBg}33;

      ${Title} {
        color: ${theme.colors.primary};
      }
    `}
`;
const Wrapper = styled(Box)`
  align-items: center;
  cursor: pointer;
  height: 5.5rem;
  transition: all 0.1s;
  position: relative;
  border-radius: 4px;
  z-index: 1;
  &:after {
    background-color: rgba(255, 255, 255, 0.06);
    content: '';
    height: 1px;
    position: absolute;
    top: 100%;
    width: 99%;
  }

  .play {
    &:hover {
      transform: scale(1.4);
      transition-duration: 0.15s;
    }
  }
  .pause {
    &:hover {
      transform: scale(1.4);
      transition-duration: 0.15s;
    }
  }

  &:hover {
    background-color: #131516;

    ${Title} {
      color: ${({ theme }) => theme.colors.primary};
    }
    ${Number} {
      color: ${({ theme }) => theme.colors.primary};
      font-weight: 500;
    }
  }

  ${({ active, theme }) =>
    active &&
    css`
      background-color: ${theme.colors.switchingBg}33;

      ${Title} {
        color: ${theme.colors.primary};
      }
    `}
`;

// const ExplicitIcon = styled.img`
//   width: 12px;
//   height: 12px;
//   margin-right: 0.5rem;
//   @media (max-width: 560px) {
//     width: 12px;
//     height: 12px;
//   }
// `;

export const Item = memo(({ index, isOwner, item, showArtwork, onPlay }) => {
  const { id, artist_name, artist, artwork, duration, name, parental_warning } = item;
  // const link = type === 'artists' ?  : `/users/${id}`;
  const dispatch = useDispatch();
  const currentItem = useSelector(state => state.player.currentItem);
  const isCurrent = !isObjEmpty(currentItem) && id === currentItem?.id;
  const connect = () => dispatch(connectAction(item, 'track'));
  // const [setPlayer] = useContext(FullPlayerContext);
  const { playing } = useSelector(({ player }) => player);
  const pause = () => dispatch(pauseAction());
  const play = () => dispatch(playAction());

  // const TrackPlay = () => {
  //   onPlay();
  //   if (window.innerWidth > 767) {
  //     setPlayer(true);
  //   } else {
  //     setPlayer(false);
  //   }
  // };

  const isConnected = useSelector(
    state =>
      (state.connect.byTargetId.tracks &&
        state.connect.byTargetId.tracks[id] &&
        state.connect.byTargetId.tracks[id].active) ||
      false,
  );

  const renderPlayIcon = () => {
    if (playing) {
      return <PauseIcon onClick={pause} size="1.3rem" fill="#FFA700" className="pause" />;
    } else {
      return <PlayIcon onClick={play} size="1.3rem" fill="#FFA700" className="play" />;
    }
  };
  return (
    <AlbumTrackItemWrapper active={isCurrent} style={{ padding: '0 1rem' }}>
      <Box mr={['1rem', '1.5rem']} flexShrink="0">
        <Number as="div">{isCurrent ? renderPlayIcon() : index}</Number>
      </Box>
      {showArtwork && (
        <Box mr={['1rem', '1.5rem']} flexShrink="0">
          <Artwork src={artwork} placeholder="track" spinnerSize="1rem" alt={name} />
        </Box>
      )}
      <Box onClick={onPlay} width="100%" py="1.2rem">
        <Box flexDirection="column" mr="0.5rem">
          <Marquee>
            <Title ellipsize>
              {name}
              {parental_warning === 'Explicit' && <ExplicitIcon size="1rem" />}
            </Title>
          </Marquee>
          <Marquee>
            <Artist as={Link} to={`/artists/${artist}`}>
              {artist_name} - {formatTime(duration)}
            </Artist>
          </Marquee>
        </Box>

        <Box ml="auto" flexShrink="0">
          <Box>
            <LikeIcon
              onClick={e => {
                e.stopPropagation();
                connect();
              }}
              fill={isConnected === true ? 'url(#paint0_linear)' : 'transparent'}
              style={{ cursor: 'pointer' }}
            />
          </Box>
          <Box flexShrink="0">
            <MoreOptions isOwner={isOwner} type="track" item={item} />
          </Box>
        </Box>
      </Box>
    </AlbumTrackItemWrapper>
  );
});

export const ItemNoLike = memo(({ isOwner, item, onPlay }) => {
  const { id, artist_name, artwork, duration, name, parental_warning } = item;
  const currentItem = useSelector(state => state.player.currentItem);
  const isCurrent = !isObjEmpty(currentItem) && id === currentItem.id;

  return (
    <Wrapper onClick={onPlay} active={isCurrent}>
      <Box mr={['1rem', '1.5rem']} flexShrink="0">
        <Artwork src={artwork} placeholder="track" spinnerSize="1rem" alt={name} />
      </Box>

      <Box flexDirection="column" mr="5rem">
        <Title ellipsize>
          {name}
          {parental_warning === 'Explicit' && <ExplicitIcon />}
        </Title>
        <Artist ellipsize>
          {artist_name} - {formatTime(duration)}
        </Artist>
      </Box>
      <Box ml="auto" mr={['1rem', '1.5rem']} flexShrink="0" />

      <Box flexShrink="0">
        <MoreOptions isOwner={isOwner} type="track" item={item} />
      </Box>
    </Wrapper>
  );
});

export const ItemNoOption = memo(({ item }) => {
  const { id, artist_name, artwork, duration, name, parental_warning } = item;
  const dispatch = useDispatch();
  const currentItem = useSelector(state => state.player.currentItem);
  const isCurrent = !isObjEmpty(currentItem) && id === currentItem.id;
  const connect = () => dispatch(connectAction(item, 'track'));

  const isConnected = useSelector(
    state =>
      (state.connect.byTargetId.tracks &&
        state.connect.byTargetId.tracks[id] &&
        state.connect.byTargetId.tracks[id].active) ||
      false,
  );

  return (
    <Wrapper
      onClick={e => {
        e.stopPropagation();
        connect();
      }}
      active={isCurrent}
      style={{ padding: '0' }}
    >
      <Box mr={['1rem', '1.5rem']} flexShrink="0">
        <Artwork src={artwork} placeholder="track" spinnerSize="1rem" alt={name} />
      </Box>

      <Box flexDirection="column" mr="5rem">
        <Title ellipsize>
          {name}
          {parental_warning === 'Explicit' && <ExplicitIcon />}
        </Title>
        <Artist ellipsize style={{ textDecoration: 'none' }}>
          {artist_name} - {formatTime(duration)}
        </Artist>
      </Box>
      <Box ml="auto" mr={['1rem', '1.5rem']} flexShrink="0" />

      <Box ml="auto" mr={['1rem', '1.5rem']} flexShrink="0">
        <LikeIcon
          onClick={e => {
            e.stopPropagation();
            connect();
          }}
          style={{ cursor: 'pointer' }}
          fill={isConnected === true ? 'url(#paint0_linear)' : 'transparent'}
        />
      </Box>
    </Wrapper>
  );
});

export const PlaylistItem = memo(({ item }) => {
  const { id, artist_name, artist, artwork, duration, name, parental_warning } = item;
  const currentItem = useSelector(state => state.player.currentItem);
  const isCurrent = !isObjEmpty(currentItem) && id === currentItem.id;

  const { addToast } = useToast();
  const [songsToAdd, setSongsToAddSongs] = useContext(AddSongContext); // eslint-disable-line
  const [clicked, setClicked] = useState(false);

  // const add = () => {
  //   setSongsToAddSongs(songsToAdd => [...songsToAdd, item.id]);
  //   setClicked(true);
  // };

  const add = useCallback(() => {
    setSongsToAddSongs(songsToAdd => [...songsToAdd, item.id]);
    setClicked(true);
    // eslint-disable-next-line
  }, [item]);

  const alreadyAdded = () => {
    addToast(`Already added to playlist`, { type: 'info' });
  };

  return (
    <Wrapper active={isCurrent} style={{ padding: '0' }}>
      <Box mr={['1rem', '1.5rem']} flexShrink="0">
        <Artwork src={artwork} placeholder="track" spinnerSize="1rem" alt={name} />
      </Box>

      <Box flexDirection="column" mr="5rem">
        <Marquee>
          <Title ellipsize>
            {name}
            {parental_warning === 'Explicit' && <ExplicitIcon />}
          </Title>
        </Marquee>
        <Marquee>
          <Artist ellipsize as={Link} to={`/artists/${artist}`}>
            {artist_name} - {formatTime(duration)}
          </Artist>
        </Marquee>
      </Box>
      <Box ml="auto" mr={['1rem', '1.5rem']} flexShrink="0" />

      <Box ml="auto" mr={['1rem', '1.5rem']} flexShrink="0">
        {clicked === false ? (
          <PlusIcon onClick={() => add()} style={{ cursor: 'pointer' }} />
        ) : (
          <DoneIcon onClick={alreadyAdded} style={{ cursor: 'pointer' }} />
        )}
      </Box>
    </Wrapper>
  );
});

Item.propTypes = {
  isOwner: PropTypes.bool,
  item: PropTypes.shape({
    artist_name: PropTypes.string.isRequired,
    artist: PropTypes.string.isRequired,
    parental_warning: PropTypes.string.isRequired,
    artwork: PropTypes.string.isRequired,
    connected: PropTypes.bool.isRequired,
    duration: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  index: PropTypes.number.isRequired,
  onPlay: PropTypes.func.isRequired,
  showArtwork: PropTypes.bool.isRequired,
};
ItemNoLike.propTypes = {
  isOwner: PropTypes.bool,
  item: PropTypes.shape({
    artist_name: PropTypes.string.isRequired,
    artist: PropTypes.string.isRequired,
    parental_warning: PropTypes.string.isRequired,
    artwork: PropTypes.string.isRequired,
    connected: PropTypes.bool.isRequired,
    duration: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,

  onPlay: PropTypes.func.isRequired,
};
ItemNoOption.propTypes = {
  isOwner: PropTypes.bool,
  item: PropTypes.shape({
    artist_name: PropTypes.string.isRequired,
    artist: PropTypes.string.isRequired,
    parental_warning: PropTypes.string.isRequired,
    artwork: PropTypes.string.isRequired,
    connected: PropTypes.bool.isRequired,
    duration: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  onPlay: PropTypes.func.isRequired,
};
PlaylistItem.propTypes = {
  isOwner: PropTypes.bool,
  item: PropTypes.shape({
    artist_name: PropTypes.string.isRequired,
    artist: PropTypes.string.isRequired,
    parental_warning: PropTypes.string.isRequired,
    artwork: PropTypes.string.isRequired,
    connected: PropTypes.bool.isRequired,
    duration: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
};

MoreOptions.defaultProps = {
  isOwner: false,
};
