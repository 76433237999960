import api from 'services/api';
import * as t from '../types';
import sortBy from 'lodash.sortby';

export const clearNewTrackAction = () => async dispatch => {
  dispatch({ type: t.CLEAR_NEW_TRACK });
};

export const getTrackAction = (id, callback = () => {}) => async dispatch => {
  try {
    dispatch({ type: t.GET_TRACK_REQUEST, payload: id });
    const resp = await api.getTrack(id);
    if (resp.status === 200 || resp.status === 201) {
      dispatch({ type: t.GET_TRACK_SUCCESS, payload: { id, data: resp.data } });
      callback(resp.data, null);
    } else {
      throw resp.data;
    }
  } catch (e) {
    callback(null, e);
    dispatch({ type: t.GET_TRACK_FAILURE, payload: id });
  }
};

export const getArtistTracksAction = id => async dispatch => {
  try {
    dispatch({ type: t.GET_ARTIST_TRACKS_REQUEST });
    const resp = await api.getTopTracks({ artist: id });
    if (resp.status === 200 || resp.status === 201) {
      dispatch({
        type: t.GET_ARTIST_TRACKS_SUCCESS,
        payload: { id, data: resp.data },
      });
    } else {
      throw resp.data;
    }
  } catch (e) {
    dispatch({ type: t.GET_ARTIST_TRACKS_FAILURE });
  }
};

export const getMyTrackAction = (id, callback = () => {}) => async dispatch => {
  try {
    dispatch({ type: t.GET_TRACK_REQUEST, payload: id });
    const resp = await api.getMyTrack(id);
    if (resp.status === 200 || resp.status === 201) {
      dispatch({
        type: t.GET_MY_TRACK_SUCCESS,
        payload: { id, data: resp.data },
      });
      // dispatch({ type: t.GET_TRACK_SUCCESS, payload: { id, data: resp.data } });
      callback(true, null);
    } else {
      throw resp.data;
    }
  } catch (e) {
    callback(null, e);
    dispatch({ type: t.GET_TRACK_FAILURE, payload: id });
  }
};
export const getTracksByTagAction = tag => async dispatch => {
  try {
    dispatch({ type: t.GET_TRACKS_BY_TAG_REQUEST, payload: tag });

    const resp = await api.getTracks({ screen: tag });
    if (resp.status === 200 || resp.status === 201) {
      dispatch({
        type: t.GET_TRACKS_BY_TAG_SUCCESS,
        payload: { tag, data: resp.data },
      });
    } else {
      throw resp.data;
    }
  } catch (e) {
    dispatch({ type: t.GET_TRACKS_BY_TAG_FAILURE, payload: tag });
  }
};

export const updateTrackAction = (trackObject, callback = () => {}) => async dispatch => {
  try {
    const track = { ...trackObject };
    dispatch({ type: t.UPDATE_TRACK_REQUEST });
    delete track.source;
    delete track.preview;
    delete track.metadata;
    delete track.owner_name;
    const resp = await api.updateTrack(track);
    if (resp.status === 200 || resp.status === 201) {
      dispatch({ type: t.UPDATE_TRACK_SUCCESS, payload: { data: resp.data } });
      callback(resp.data, null);
    } else {
      dispatch({ type: t.UPDATE_TRACK_FAILURE });
      callback(false, resp.data);
    }
  } catch (e) {
    dispatch({ type: t.UPDATE_TRACK_FAILURE });
    callback(false, null);
  }
};

export const getUserTracksAction = (callback = () => {}) => async dispatch => {
  try {
    dispatch({ type: t.GET_USER_TRACKS_REQUEST });
    const resp = await api.getUserTracks();
    if (resp.status === 200 || resp.status === 201) {
      dispatch({ type: t.GET_USER_TRACKS_SUCCESS, payload: resp.data });
      callback(true, null);
    } else {
      throw resp.data;
    }
  } catch (e) {
    callback(null, e);
    dispatch({ type: t.GET_USER_TRACKS_FAILURE });
  }
};

export const getOnboardingTrackAction = (callback = () => {}) => async dispatch => {
  try {
    dispatch({ type: t.GET_ONBOARDING_TRACKS_REQUEST });
    const responds = await api.getOnboardingTracks();
    if (responds.status === 200 || responds.status === 201) {
      dispatch({ type: t.GET_ONBOARDING_TRACKS_SUCCESS, payload: responds.data });
      callback(true, null);
    } else {
      throw responds.data;
    }
  } catch (e) {
    callback(null, e);
    dispatch({ type: t.GET_CONNECTIONS_FAILURE });
  }
};

export const getAllGenres = async (callback = () => {}) => {
  try {
    const res = await api.getGenres();
    if (res.status === 200 || res.status === 201) {
      callback(res.data, null);
    } else {
      throw res.data;
    }
  } catch (e) {
    callback(null, e);
  }
};

export const sortTracksByTitleAction = item => async dispatch => {
  const sortTitle = sortBy(item, ['name']);
  dispatch({ type: t.SORT_TRACKS_BY_TITLE, payload: sortTitle });
};
export const sortTracksByArtistAction = item => async dispatch => {
  const sortArtist = sortBy(item, ['artist_name']);
  dispatch({ type: t.SORT_TRACKS_BY_ARTIST, payload: sortArtist });
};
// export const sortTracksByRecentAction = item => async dispatch => {
//   dispatch(getUserTracksAction());
// };
