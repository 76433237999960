import React from 'react';
import PropTypes from 'prop-types';

function SvgMoneyBundles({ fill, ...props }) {
  return (
    <svg width="64" height="65" viewBox="0 0 64 65" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M63.1856 10.4054L60.3162 28.7907C51.5364 32.2612 34.1077 61.4283 27.5906 64.0449C19.0624 59.1293 0.597299 49.3245 0.597299 49.3245C0.465917 40.728 0.796111 36.5693 0.796111 36.5693L3.21802 34.0579C3.21802 34.0579 60.7126 9.7074 63.1856 10.4054Z"
        fill="url(#paint0_linear)"
      />
      <path
        d="M60.3163 28.7899C51.6191 32.2277 34.4568 60.8514 27.7793 63.959C28.1721 62.0948 28.0821 58.1235 28.0821 58.1235C28.0821 58.1235 28.9368 56.5617 28.2118 56.7745L28.2294 54.1448L28.5297 52.4824L30.1905 50.2643L28.2069 51.7373C28.2069 51.7373 27.8325 49.3793 28.515 46.8707C32.7085 43.4583 53.1085 18.2916 61.4578 10.7553C62.3768 10.4754 62.9791 10.3465 63.1857 10.4046L60.3163 28.7899Z"
        fill="#8A5321"
      />
      <path
        d="M2.36328 34.945L3.21904 34.0571C3.21904 34.0571 3.70218 33.8439 4.57116 33.4626C10.007 36.2077 32.6321 47.8446 32.6321 47.8446L28.4381 49.834C26.4414 48.6053 5.39223 37.0804 2.36328 34.945Z"
        fill="#8A5321"
      />
      <path
        d="M64 9.8292C58.827 7.34723 42.267 0.366112 38.6794 -8.7738e-05C30.9152 4.07475 13.8196 33.7255 0 31.6469C11.9579 40.135 28.0469 49.0879 28.0469 49.0879C38.3325 41.9533 52.2914 16.2841 64 9.8292Z"
        fill="url(#paint1_linear)"
      />
      <path
        d="M54.0973 12.6901C52.5257 11.2822 51.8456 9.96957 53.3788 9.26417C46.7642 6.59071 41.9627 4.38737 41.9627 4.38737C40.4308 6.6394 38.6125 7.36525 36.3245 6.81084C31.381 12.3816 19.1593 28.2951 11.0469 31.1854C13.3247 32.5807 11.2551 35.0884 11.2551 35.0884C14.9499 37.5835 20.7416 40.529 24.3127 42.9316C27.4449 39.2111 30.6821 41.9051 30.6821 41.9051C32.5852 39.1571 48.4999 19.548 54.0973 12.6901Z"
        fill="url(#paint2_linear)"
      />
      <path
        d="M21.7773 58.5425C21.9422 58.5895 22.1047 58.6354 22.2597 58.6738C22.1546 58.664 21.9868 58.6178 21.7773 58.5425Z"
        fill="#8A5321"
      />
      <path
        d="M20.3319 49.3967C14.5014 46.5121 1.03564 39.3269 1.03564 39.3269L0.876314 40.8428L0.72431 45.1644C0.72431 45.1644 7.65895 49.8521 8.13276 50.3955C6.36477 49.2998 1.48797 46.3378 0.566701 45.7789C0.561586 42.5322 0.623942 40.1624 0.685643 38.6415C1.46481 38.9578 16.877 47.0273 20.3319 49.3967Z"
        fill="#8A5321"
      />
      <path
        d="M0.58406 48.2569C0.5778 47.6837 0.573013 47.1284 0.570312 46.5977L0.746906 47.5711C0.746906 47.5711 18.2479 57.3104 21.7778 58.542C15.8625 56.8661 3.17553 50.0224 0.58406 48.2569Z"
        fill="#8A5321"
      />
      <path
        d="M40.2793 42.7383C40.2793 40.4609 40.1492 38.2821 39.7347 37.1123C39.7347 37.1123 39.6504 37.064 39.4941 36.974H39.4946C39.6517 37.0644 39.7347 37.1123 39.7347 37.1123C40.1492 38.2821 40.2793 40.4609 40.2793 42.7383Z"
        fill="#9A7F1F"
      />
      <path
        d="M39.4946 36.9746C39.4938 36.9746 39.493 36.9738 39.4922 36.973C39.493 36.973 39.4922 36.9738 39.4922 36.973C39.493 36.9738 39.4946 36.9738 39.4954 36.9746H39.4946Z"
        fill="#A58C1E"
      />
      <path
        d="M39.4926 36.9735C39.4316 36.9387 39.3596 36.8973 39.2773 36.8503C39.2778 36.8503 39.2778 36.8499 39.2778 36.8499C39.3604 36.8973 39.432 36.9383 39.4926 36.9735C39.493 36.9735 39.4926 36.9739 39.4926 36.9735Z"
        fill="#9A7F1F"
      />
      <path
        d="M41.2048 9.69885C39.7387 10.4521 39.0845 12.1027 39.742 13.3805C40.4004 14.662 42.1229 15.09 43.5885 14.3355C45.0538 13.5834 45.7084 11.9329 45.0509 10.6522C44.3925 9.37235 42.6704 8.94599 41.2048 9.69885Z"
        fill="url(#paint3_linear)"
      />
      <path
        d="M23.5443 31.1957C21.8144 31.0774 20.3885 32.1171 20.3598 33.5152C20.3312 34.9137 21.7117 36.1441 23.4424 36.2603C25.1728 36.3789 26.5991 35.3409 26.6278 33.9412C26.6556 32.5418 25.2755 31.3135 23.5443 31.1957Z"
        fill="url(#paint4_linear)"
      />
      <path
        d="M31.6602 8.26758C34.7788 5.39199 37.9784 2.57858 38.7698 2.12114C39.5607 1.66411 50.4047 5.9149 54.7917 7.46604C51.6608 4.81017 40.1544 0.771326 38.4306 1.25291C36.5218 1.97672 32.0513 7.4468 31.6602 8.26758Z"
        fill="#F5DEA4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.4827 19.1791L35.2725 21.3588C35.7932 21.813 36.1227 22.4477 36.1952 23.1356C36.2678 23.8236 36.0779 24.5141 35.6636 25.069C35.2493 25.6239 34.6412 26.0023 33.961 26.1284C33.2807 26.2544 32.5785 26.1189 31.9949 25.749L29.0999 23.5089L27.7213 25.2735L30.6061 27.5274C31.6498 28.2572 32.9345 28.558 34.1953 28.3678C35.4561 28.1776 36.597 27.5109 37.3828 26.5051C38.1687 25.4993 38.5395 24.231 38.4191 22.9616C38.2986 21.6923 37.6959 20.5185 36.7353 19.6823L36.703 19.6571L38.096 17.8743L37.3828 17.3171L35.9898 19.0999L35.455 18.6821L36.8479 16.8991L36.1348 16.3419L34.7418 18.1249L33.8505 17.4285L32.4827 19.1791ZM27.2195 27.753L28.6125 25.9701L29.3257 26.5273L27.9327 28.3102L27.2195 27.753ZM28.4675 28.728L29.8605 26.945L30.5737 27.5022L29.1807 29.2852L28.4675 28.728Z"
        fill="url(#paint5_linear)"
      />
      <path
        d="M14.7505 25.3092L14.7305 25.3416C19.3249 26.9537 36.3105 36.6839 38.7642 38.0947C42.1709 33.7973 45.7171 29.0056 49.2695 24.5609C45.2597 22.4582 28.374 15.2308 25.5192 13.9255C22.142 17.8563 18.4825 21.9705 14.7505 25.3092Z"
        fill="#8A5321"
      />
      <path
        d="M49.8502 39.0307C49.8936 34.7152 49.8559 25.0115 49.8183 24.0557C45.9754 21.9907 27.354 14.0386 25.609 13.1954C19.3881 17.868 15.1074 24.7448 15.1074 24.7448C20.064 26.4837 39.4485 37.6751 39.4485 37.6751C40.5651 40.8277 39.6191 51.3047 39.6191 51.3047C39.6191 51.3047 48.1366 42.0928 49.8502 39.0307Z"
        fill="#494949"
      />
      <path
        d="M39.6175 51.3047C39.6175 51.3047 39.9915 47.1607 39.9915 43.3011C39.9915 41.0237 39.8613 38.8449 39.4469 37.6751C39.4469 37.6751 39.3638 37.6272 39.2067 37.5368C39.2063 37.5364 39.2055 37.5364 39.205 37.536C39.1445 37.5008 39.0729 37.4599 38.9902 37.4124C42.223 35.5634 47.8834 27.2742 49.7987 24.0459L49.8167 24.0557C49.8424 24.7055 49.8678 29.3965 49.8682 33.6899C49.8682 33.696 49.8682 33.7021 49.8682 33.7083C49.8682 33.7144 49.8682 33.7206 49.8682 33.7267C49.8682 35.7369 49.8625 37.6567 49.8486 39.0307C48.135 42.0928 39.6175 51.3047 39.6175 51.3047Z"
        fill="#272729"
      />
      <path
        d="M29.9258 16.4397C32.1205 17.8501 46.2382 23.4115 46.2689 24.2592C46.2984 25.1083 46.2984 25.1083 46.2984 25.1083C46.2984 25.1083 40.1082 34.53 38.8521 35.6348C41.5251 34.8745 47.5418 25.5264 48.1233 24.3787C47.1139 22.9593 34.1819 17.3902 29.9258 16.4397Z"
        fill="#868687"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="31.876"
          y1="64.0449"
          x2="31.876"
          y2="10.3907"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDB913" />
          <stop offset="1" stopColor="#F2994A" />
        </linearGradient>
        <linearGradient id="paint1_linear" x1="32" y1="49.0879" x2="32" y2="-8.7738e-05" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FDB913" />
          <stop offset="1" stopColor="#F2994A" />
        </linearGradient>
        <linearGradient
          id="paint2_linear"
          x1="32.5721"
          y1="42.9316"
          x2="32.5721"
          y2="4.38737"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FACB5B" />
          <stop offset="1" stopColor="#F5DEA4" />
        </linearGradient>
        <linearGradient
          id="paint3_linear"
          x1="42.3964"
          y1="14.708"
          x2="42.3964"
          y2="9.327"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDB913" />
          <stop offset="1" stopColor="#F2994A" />
        </linearGradient>
        <linearGradient
          id="paint4_linear"
          x1="23.4938"
          y1="36.2695"
          x2="23.4938"
          y2="31.1865"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDB913" />
          <stop offset="1" stopColor="#F2994A" />
        </linearGradient>
        <linearGradient
          id="paint5_linear"
          x1="21.0866"
          y1="36.0667"
          x2="49.3355"
          y2="9.45541"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDB913" />
          <stop offset="0.984375" stopColor="#F2994A" />
        </linearGradient>
      </defs>
    </svg>
  );
}

SvgMoneyBundles.propTypes = {
  fill: PropTypes.string,
};

SvgMoneyBundles.defaultProps = {
  fill: '#fcb316',
};

export default SvgMoneyBundles;
