import * as t from '../types';

const INITIAL_STATE = {
  loggedIn: false,
  user: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case t.LOGGED_IN:
      return { ...state, loggedIn: true };

    case t.UPDATE_PROFILE_SUCCESS:
    case t.COMPLETE_REGISTRATION_SUCCESS:
    case t.GET_PROFILE_SUCCESS:
      return { ...state, user: { ...action.payload }, loggedIn: true };
    case t.LOGOUT:
      return INITIAL_STATE;
    default:
      return { ...state };
  }
};
