import * as React from 'react';

function SvgOvalBadge(props) {
  return (
    <svg width="103" height="71" viewBox="0 0 103 71" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M51.379 68.9902C65.0401 68.9902 77.3048 62.3405 86.0969 54.967C90.5038 51.2711 94.0848 47.3557 96.5772 43.9205C97.8226 42.2041 98.8125 40.5851 99.4976 39.1541C100.163 37.7639 100.614 36.4019 100.614 35.2451C100.614 34.0884 100.163 32.7264 99.4976 31.3362C98.8125 29.9052 97.8226 28.2862 96.5772 26.5697C94.0848 23.1346 90.5038 19.2191 86.0969 15.5233C77.3048 8.14976 65.0401 1.5 51.379 1.5C37.7179 1.5 25.4532 8.14976 16.6611 15.5233C12.2542 19.2191 8.67314 23.1346 6.18075 26.5697C4.93535 28.2862 3.94545 29.9052 3.26041 31.3362C2.59488 32.7264 2.14355 34.0884 2.14355 35.2451C2.14355 36.4019 2.59488 37.7639 3.26041 39.1541C3.94545 40.5851 4.93535 42.2041 6.18075 43.9205C8.67314 47.3557 12.2542 51.2711 16.6611 54.967C25.4532 62.3405 37.7179 68.9902 51.379 68.9902Z"
        fill="url(#paint0_linear)"
        stroke="black"
        strokeWidth="3"
      />
      <defs>
        <linearGradient id="paint0_linear" x1="51.8336" y1="67.5565" x2="52.7429" y2="3" gradientUnits="userSpaceOnUse">
          <stop stopColor="#8365BD" />
          <stop offset="1" stopColor="#2F2640" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default SvgOvalBadge;
