import { LivestreamContext } from 'contexts/LivestreamContext';
import { WalletContext } from 'contexts/WalletContext';
import { getHighResolutionPicture } from 'lib/utils';
import { getDayAndMonth } from 'lib/utils';
import React, { useCallback, useContext, useEffect } from 'react';
import { MAKE_PAYMENT } from 'redux/types';
import {
  Balance,
  PaymentMethodCategory,
  PaymentMethodImage,
  PaymentMethodItem,
  PaymentMethods,
} from '../MakeItRain/styled';
import { CoverImage, LiveStreamCover, LiveStreamDetails } from '../ShareLivestream/styled';
import { ActionButton, BuyTicketWrapper, EventTitle } from './styled';
import { useToast } from 'hooks';
import { Spinner } from 'components/atoms';
import { useSelector } from 'react-redux';
const BuyTicket = () => {
  const {
    LivestreamModel: { ticketPrice, event = { id: '', artists: [] } },
    hasTicketHandler,
    updateSidePanelContent,
    buyTicketHandler,
  } = useContext(LivestreamContext);
  const {
    WalletModel: { paymentMethod, currency },
    updateBalance,
    updatePaymentLink,
  } = useContext(WalletContext);
  const artistName = event?.artists[0]?.name;
  const { addToast } = useToast();

  const { tickets, loading: ticketLoading } = useSelector(({ livestream: { tickets, loading } }) => ({
    tickets,
    loading,
  }));
  const hasTicket = useCallback(() => {
    if (ticketPrice === 'Free') {
      return true;
    } else {
      return tickets.indexOf(event.id) >= 0;
    }
  }, [event.id, ticketPrice, tickets]);
  const buyTicket = () => {
    const price = ticketPrice === 'FREE' ? 0 : ticketPrice;

    buyTicketHandler(event, price, (error, res) => {
      if (error) {
        if (error.paymentLink) {
          setTimeout(() => {
            updatePaymentLink(error.paymentLink);
            updateSidePanelContent(MAKE_PAYMENT);
          }, 1000);
          return;
        }
        return addToast(error || 'An error occured', { type: 'error' });
      }
      if (res) {
        hasTicketHandler(true);
        updateBalance(-price);
      }
    });
  };

  useEffect(() => {
    if (hasTicket()) {
      setTimeout(() => {
        hasTicketHandler(true);
      }, 10);
    }
  }, [event.id, hasTicket, hasTicketHandler]);
  return (
    <BuyTicketWrapper>
      <LiveStreamCover>
        <EventTitle>Event</EventTitle>
        <CoverImage src={getHighResolutionPicture(event?.pictures)?.link} alt={`${event.name}'s cover`} />
        <LiveStreamDetails>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            {event.name} <Balance>{ticketPrice === 'FREE' ? ticketPrice : `${currency + ticketPrice}`}</Balance>
          </div>
          <span>
            {artistName} - {getDayAndMonth(event.startDate || Date.now())}
          </span>
        </LiveStreamDetails>
      </LiveStreamCover>
      <PaymentMethods>
        <PaymentMethodCategory>Payment method</PaymentMethodCategory>
        <PaymentMethodItem onClick={() => {}}>
          <PaymentMethodImage src={paymentMethod.img} />
          {paymentMethod.value}
        </PaymentMethodItem>
      </PaymentMethods>

      <ActionButton effect onClick={buyTicket}>
        {ticketLoading && <Spinner color="secondary" />}
        {!ticketLoading && `${ticketPrice === 'FREE' ? 'GET' : 'BUY'} TICKET`}
      </ActionButton>
    </BuyTicketWrapper>
  );
};
export default BuyTicket;
