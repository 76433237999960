import React from 'react';
import PropTypes from 'prop-types';

const SvgStudioX = ({ fill, ...props }) => {
  return (
    <svg width="24" height="18" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.3078 3.26552C17.1084 3.06612 16.838 2.9541 16.556 2.9541C16.274 2.9541 16.0035 3.06612 15.8042 3.26552C15.6048 3.46491 15.4927 3.73535 15.4927 4.01734V14.2091L10.9292 9.64393C10.8305 9.54491 10.7132 9.46635 10.584 9.41275C10.4548 9.35915 10.3163 9.33156 10.1765 9.33156C10.0366 9.33156 9.89814 9.35915 9.76896 9.41275C9.63979 9.46635 9.52246 9.54491 9.42369 9.64393C9.32468 9.74269 9.24612 9.86002 9.19252 9.9892C9.13892 10.1184 9.11133 10.2569 9.11133 10.3967C9.11133 10.5366 9.13892 10.675 9.19252 10.8042C9.24612 10.9334 9.32468 11.0507 9.42369 11.1495L15.8031 17.5289C15.9019 17.628 16.0192 17.7065 16.1484 17.7601C16.2776 17.8137 16.4161 17.8413 16.5559 17.8413C16.6958 17.8413 16.8343 17.8137 16.9634 17.7601C17.0926 17.7065 17.2099 17.628 17.3087 17.5289L23.6882 11.1495C23.8878 10.9498 24 10.6791 24 10.3967C24 10.1144 23.8878 9.84358 23.6882 9.64393C23.4885 9.44428 23.2177 9.33212 22.9354 9.33212C22.653 9.33212 22.3823 9.44428 22.1826 9.64393L17.6192 14.209V4.01734C17.6192 3.73535 17.5072 3.46491 17.3078 3.26552Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.69219 15.2286C6.89159 15.428 7.16203 15.54 7.44402 15.54C7.72601 15.54 7.99645 15.428 8.19585 15.2286C8.39524 15.0292 8.50726 14.7588 8.50726 14.4768L8.50726 4.285L13.0708 8.85021C13.1695 8.94923 13.2868 9.02779 13.416 9.08139C13.5452 9.13499 13.6837 9.16258 13.8235 9.16258C13.9634 9.16258 14.1019 9.13499 14.231 9.08139C14.3602 9.02779 14.4775 8.94923 14.5763 8.85021C14.6753 8.75145 14.7539 8.63412 14.8075 8.50494C14.8611 8.37577 14.8887 8.23729 14.8887 8.09744C14.8887 7.95758 14.8611 7.8191 14.8075 7.68993C14.7539 7.56076 14.6753 7.44343 14.5763 7.34466L8.19685 0.965204C8.09808 0.866188 7.98075 0.78763 7.85158 0.734029C7.72241 0.680429 7.58393 0.652837 7.44407 0.652837C7.30422 0.652837 7.16574 0.680429 7.03657 0.734029C6.9074 0.78763 6.79006 0.866188 6.6913 0.965204L0.311845 7.34466C0.112196 7.54431 3.52859e-05 7.81509 3.52859e-05 8.09744C3.52859e-05 8.37978 0.112196 8.65056 0.311845 8.85021C0.511493 9.04986 0.782274 9.16202 1.06462 9.16202C1.34697 9.16202 1.61775 9.04986 1.8174 8.85021L6.38078 4.2851L6.38078 14.4768C6.38078 14.7588 6.4928 15.0292 6.69219 15.2286Z"
        fill={fill}
      />
    </svg>
  );
};

SvgStudioX.propTypes = {
  fill: PropTypes.string,
};

SvgStudioX.defaultProps = {
  fill: '#fcb316',
};
export default SvgStudioX;
