import React from 'react';
import PropTypes from 'prop-types';

function SvgDownload({ fill, ...props }) {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M19.9997 9.00002L19.9997 25.9231M19.9997 25.9231L25.0767 20.8462M19.9997 25.9231L14.9228 20.8462"
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
        fill={fill}
      />
      <path
        d="M31 23.3846V27.6154C31 29.4847 29.4847 31 27.6154 31H12.3846C10.5153 31 9 29.4847 9 27.6154V23.3846"
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

SvgDownload.propTypes = {
  fill: PropTypes.string,
};

SvgDownload.defaultProps = {
  fill: '#ffa700',
};

export default SvgDownload;
