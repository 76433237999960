import React from 'react';

const SVGComponent = ({ point }) => (
  <svg width={150} height={150} viewBox="0 0 134 97" fill="none">
    <g opacity={0.5}>
      <path
        d="M73.3379 90.9941L113.086 47.868C123.205 37.698 123.193 21.2424 113.05 11.0875C102.893 0.918764 86.4248 0.918763 76.2681 11.0875L71.4996 15.8615L66.7312 11.0875C56.5745 0.918773 40.106 0.918766 29.9493 11.0875C19.8064 21.2424 19.7943 37.698 29.913 47.868L69.6613 90.9941L71.4996 92.9886L73.3379 90.9941Z"
        fill="url(#paint0_linear)"
        stroke="url(#paint1_linear)"
        strokeWidth={5}
      />
      <path d="M133.95 52.5039L125.645 43.7827L133.95 35.0588H67.2529V52.5039H133.95Z" fill="url(#paint2_linear)" />
      <path
        d="M0.597198 35.5039L8.9017 44.2251L0.597198 52.949L67.2939 52.949V35.5039L0.597198 35.5039Z"
        fill="url(#paint3_linear)"
      />
      <path
        d="M72.6026 89.7499L112.364 46.6097C122.108 36.8314 122.1 20.9972 112.342 11.2277C102.576 1.45013 86.7417 1.45013 76.9756 11.2277L71.4996 16.7101L66.0237 11.2277C56.2576 1.45014 40.4229 1.45013 30.6568 11.2277C20.8989 20.9972 20.8917 36.8314 30.6353 46.6097L70.3967 89.7499L71.4996 90.9466L72.6026 89.7499Z"
        fill="url(#paint4_linear)"
        stroke="#080808"
        strokeWidth={3}
      />
      <text x="53%" y="50%" dominantBaseline="middle" textAnchor="middle" fontWeight={600} fill="#A6A6A6">
        {point}
      </text>
    </g>
    <defs>
      <linearGradient
        id="paint0_linear"
        x1={71.4996}
        y1={5.99258}
        x2={71.6607}
        y2={88.8476}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4A4A4A" />
        <stop offset={1} stopColor="#242424" />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1={71.4996}
        y1={100.072}
        x2={71.4996}
        y2={5.57186}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4A4A4A" />
        <stop offset={1} stopColor="#242424" />
      </linearGradient>
      <linearGradient
        id="paint2_linear"
        x1={141.241}
        y1={43.6472}
        x2={112.579}
        y2={43.6614}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#515151" />
        <stop offset={0.71875} stopColor="#BBBBBB" />
      </linearGradient>
      <linearGradient
        id="paint3_linear"
        x1={-6.69372}
        y1={44.3606}
        x2={21.9682}
        y2={44.3464}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#515151" />
        <stop offset={0.71875} stopColor="#BBBBBB" />
      </linearGradient>
      <linearGradient
        id="paint4_linear"
        x1={71.4996}
        y1={5.42617}
        x2={71.6607}
        y2={88.2812}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4A4A4A" />
        <stop offset={1} stopColor="#242424" />
      </linearGradient>
      <radialGradient
        id="paint5_radial"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(70.5 44.7655) rotate(180) scale(47 202.227)"
      >
        <stop stopColor="#BBBBBB" />
        <stop offset={1} stopColor="#A6A6A6" />
      </radialGradient>
    </defs>
  </svg>
);

export default SVGComponent;
