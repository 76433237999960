import React, { createContext, useContext, useEffect, useMemo, useRef, useState, memo } from 'react';
import { onTrackEndedAction, stateChangeAction, updateProgressAction } from 'redux/actions';
import { useAlert, useUser } from 'hooks';
import { useDispatch, useSelector } from 'react-redux';

import { BroadcastContext } from 'contexts/BroadcastContext';
import ReactPlayer from 'react-player/lazy';

const INITIAL_STATE = {
  buffering: true,
  isBuffering: false,
  duration: 0,
  played: 0,
  playedSeconds: 0,
};
export const PlayerContext = createContext();

export const PlayerProvider = memo(({ children }) => {
  const dispatch = useDispatch();
  const { user } = useUser();
  const { addAlert } = useAlert();
  const [state, setState] = useState({ ...INITIAL_STATE });
  const [isCurrentTab, setIsCurrentTab] = useState(true);
  const { currentItem, muted, playing, progress, volume } = useSelector(({ player }) => player);
  const { tabId } = useContext(BroadcastContext);
  const playerRef = useRef(null);
  const onTrackEnded = () => dispatch(onTrackEndedAction());
  const onStateChange = newState => dispatch(stateChangeAction(newState));
  const updateProgress = progressData => dispatch(updateProgressAction(progressData));
  const updateState = newState => setState(v => ({ ...v, ...newState }));

  useEffect(() => {
    setState(INITIAL_STATE);
  }, [currentItem, currentItem]);

  useEffect(() => {
    const currentTab = localStorage.getItem('currentTab');
    if (tabId && currentTab) {
      setIsCurrentTab(tabId === currentTab);
    }
  }, [playing, tabId]);

  useEffect(() => {
    if (!isCurrentTab) {
      setState(progress);
    }
  }, [isCurrentTab, progress]);

  const stateChange = type => {
    if (user) {
      onStateChange(type);
    }
  };

  const handleDuration = duration => {
    setState(v => ({ ...v, duration }));
  };

  const onBuffer = () => {
    setState(v => ({ ...v, isBuffering: true }));
  };

  const onBufferEnd = () => {
    setState(v => ({ ...v, isBuffering: false }));
  };

  const handleEnded = () => {
    stateChange('stop');
    setState(v => ({ ...INITIAL_STATE, duration: v.duration }));
    onTrackEnded();
  };

  const onError = data => {
    let message = '';
    updateState({ buffering: false });

    // if (data) {
    //   addToast('Sorry, error occured while trying to play this track', { type: 'error' });
    // }

    const { type = '', details = '' } = data;

    if (type.toLowerCase() === 'networkerror') {
      switch (details.toLowerCase()) {
        case 'fragloaderror':
        case 'fragloadtimeout':
          message =
            state.playedSeconds > 5
              ? 'Can not play rest of track, please check your network connection.'
              : 'Could not play track, please check your network connection.';
          break;

        default:
          message = 'An error occured, please check your network connection.';
          break;
      }
    }

    if (message) {
      addAlert({
        message,
        type: 'error',
        dismissable: true,
        duration: 10000,
      });
    }
  };

  const onPause = () => {
    stateChange('pause');
  };

  const onPlay = () => {
    stateChange('play');
  };

  const onReady = () => {
    updateState({ buffering: false });
  };

  const handleProgress = ({ playedSeconds, played }) => {
    updateProgress({ playedSeconds, played, playing });
    setState(v => ({ ...v, playedSeconds, played }));
  };

  const value = useMemo(
    () => ({
      playerRef,
      state,
    }),
    [playerRef, state],
  );

  return (
    <PlayerContext.Provider value={value}>
      {isCurrentTab && (
        <ReactPlayer
          height={0}
          muted={muted}
          onDuration={handleDuration}
          onEnded={handleEnded}
          onError={onError}
          onPause={onPause}
          onPlay={onPlay}
          onBuffer={onBuffer}
          onBufferEnd={onBufferEnd}
          onProgress={handleProgress}
          playbackRate={1}
          playing={playing}
          playsinline
          ref={playerRef}
          onReady={onReady}
          url={currentItem?.source || null}
          volume={volume}
          width={0}
          config={{
            file: {
              forceAudio: true,
              attributes: {
                controlsList: 'nodownload',
              },
            },
          }}
        />
      )}
      {children}
    </PlayerContext.Provider>
  );
});
