import React from 'react';
import PropTypes from 'prop-types';

function SvgHash({ fill, ...props }) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 15.766 14.429" {...props}>
      <path
        d="M9.64 9.444H5.225l.9-4.459h4.415zm5.04-6.631h-1.486L13.5 1.3a1.086 1.086 0 00-2.13-.429l-.392 1.942H6.563L6.869 1.3A1.086 1.086 0 104.74.871l-.393 1.942H2.424a1.086 1.086 0 100 2.172h1.485l-.9 4.459H1.086a1.086 1.086 0 000 2.172h1.485l-.305 1.512a1.087 1.087 0 00.849 1.279 1.129 1.129 0 00.216.021 1.089 1.089 0 001.069-.87l.392-1.941H9.2l-.3 1.512a1.086 1.086 0 00.849 1.279 1.131 1.131 0 00.217.021 1.089 1.089 0 001.063-.872l.392-1.941h1.924a1.086 1.086 0 000-2.172h-1.489l.9-4.459h1.924a1.086 1.086 0 100-2.172z"
        fill={fill}
        fillRule="evenodd"
      />
    </svg>
  );
}

SvgHash.propTypes = {
  fill: PropTypes.string,
};

SvgHash.defaultProps = {
  fill: '#fcb316',
};

export default SvgHash;
