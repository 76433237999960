import React from 'react';
import PropTypes from 'prop-types';

function SvgMore({ fill, style, vertical, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 18 4"
      {...(vertical ? { style: { transform: 'rotate(90deg)', ...style } } : style)}
      {...props}
    >
      <defs>
        <clipPath id="more_svg__a">
          <path fill="none" d="M0 0h18v4H0z" />
        </clipPath>
      </defs>
      <g data-name="Repeat Grid 27" clipPath="url(#more_svg__a)">
        <circle data-name="Ellipse 2" cx={2} cy={2} r={2} fill={fill} />
        <circle data-name="Ellipse 2" cx={2} cy={2} r={2} transform="translate(7)" fill={fill} />
        <circle data-name="Ellipse 2" cx={2} cy={2} r={2} transform="translate(14)" fill={fill} />
      </g>
    </svg>
  );
}

SvgMore.propTypes = {
  fill: PropTypes.string,
  vertical: PropTypes.bool,
};

SvgMore.defaultProps = {
  fill: '#fff',
  vertical: false,
};

export default SvgMore;
