import React from 'react';

const SVGComponent = props => (
  <svg width={30} height={17} viewBox="0 0 30 17" fill="none" {...props}>
    <path
      d="M29.1167 15.9966C24.3013 14.7143 19.4859 15.3554 14.6705 15.9966C9.85513 16.6377 5.03972 17.2788 0.224317 15.9966C0.224317 15.942 0.0196959 15.7647 0.0196959 15.7101C0.00605456 10.9902 0.210675 6.42032 0.210675 1.68676C5.02608 2.98269 9.82784 2.31426 14.6432 1.64584C19.445 0.977408 24.2468 0.30898 29.0622 1.59127C29.0622 6.36575 29.1167 11.2357 29.1167 15.9966Z"
      fill="url(#paint0_linear)"
    />
    <path
      opacity={0.4}
      d="M28.9545 15.8612C28.9545 11.0867 28.9545 6.32584 28.9545 1.55136C27.8086 1.25125 26.6764 1.04663 25.5305 0.9375C25.4623 2.80637 25.7897 12.4235 24.7529 15.1791C26.158 15.2746 27.5494 15.4792 28.9545 15.8612Z"
      fill="url(#paint1_linear)"
    />
    <path
      d="M2.24414 6.40686C2.24414 8.24844 2.24414 10.09 2.24414 11.918C3.45822 12.109 4.4404 13.1321 4.4404 14.3461C11.1383 14.7554 17.8499 12.5591 24.5478 12.9547C24.5478 11.7406 25.53 10.7994 26.7441 10.9904C26.7441 9.14877 26.7441 7.30719 26.7441 5.47924C25.53 5.28826 24.5478 4.26516 24.5478 3.05108C17.8499 2.64184 11.1383 4.8381 4.4404 4.4425C4.4404 5.64294 3.45822 6.58419 2.24414 6.40686Z"
      fill="url(#paint2_linear)"
    />
    <path
      d="M18.123 8.24892C18.123 10.2406 16.4997 12.0412 14.508 12.3141C12.5164 12.5869 10.8931 11.1545 10.8931 9.14925C10.8931 7.15761 12.5164 5.35695 14.508 5.08412C16.4997 4.8113 18.123 6.24364 18.123 8.24892Z"
      fill="url(#paint3_linear)"
    />
    <path
      d="M8.68317 9.35194C8.68317 10.3068 7.90561 11.1526 6.95072 11.1662C5.99582 11.1935 5.21826 10.3887 5.21826 9.42015C5.21826 8.46525 5.99582 7.71498 6.95072 7.68769C7.90561 7.67405 8.68317 8.3834 8.68317 9.35194Z"
      fill="url(#paint4_linear)"
    />
    <path
      d="M20.3193 8.04345C20.3193 9.01199 21.0969 9.72134 22.0518 9.69406C23.0067 9.66678 23.7842 8.9165 23.7842 7.9616C23.7842 7.00671 23.0067 6.18822 22.0518 6.21551C21.0969 6.24279 20.3193 7.08855 20.3193 8.04345Z"
      fill="#FDB913"
    />
    <path
      d="M8.40953 2.41046C8.40953 2.34226 8.42317 2.27405 8.42317 2.20584C10.3875 2.08307 12.3655 1.82388 14.3299 1.5647C14.3844 1.55106 14.439 1.55106 14.4936 1.53741C14.5072 1.53741 14.5345 1.53742 14.5481 1.52377C14.5618 1.52377 14.5618 1.52377 14.5754 1.52377C14.6573 1.51013 14.7255 1.51013 14.8073 1.49649C14.8483 1.49649 14.8755 1.48285 14.9165 1.48285C14.9301 1.48285 14.9437 1.48285 14.9574 1.48285L14.971 1.48285C14.9847 1.48285 14.9983 1.48285 15.012 1.48285C15.0529 1.48285 15.0802 1.46921 15.1211 1.46921C15.1211 1.46921 15.1347 1.46921 15.1484 1.46921C15.162 1.46921 15.1893 1.46921 15.2029 1.45557C17.7129 1.11453 20.2502 0.800781 22.7739 0.800781C22.8012 0.800781 22.8148 0.800781 22.8421 0.800781C22.8694 0.800781 22.8967 0.800781 22.9239 0.800781C24.9019 0.800781 26.8936 1.0054 28.8716 1.52377C27.753 1.2373 26.648 1.04633 25.5295 0.937194C25.5295 0.99176 25.5295 1.04633 25.5295 1.11453C24.6837 1.03268 23.8379 1.0054 22.9922 1.0054C20.2093 1.0054 17.4265 1.37372 14.6436 1.74204C12.5701 2.01486 10.4967 2.28769 8.40953 2.41046ZM1.09775 1.7966C1.08411 1.7966 1.08411 1.7966 1.07047 1.7966C1.08411 1.7966 1.09775 1.7966 1.09775 1.7966ZM1.07047 1.7966C0.729432 1.72839 0.388398 1.64655 0.0473633 1.55106C0.0610046 1.55106 0.0610046 1.55106 0.074646 1.5647C0.41568 1.64655 0.743073 1.71475 1.07047 1.7966Z"
      fill="#F2994A"
    />
    <path
      d="M0.198395 15.8859C0.143829 15.8722 0.102905 15.8586 0.0483394 15.845C0.0483396 11.0705 0.0483397 6.30964 0.0483398 1.53516C0.389374 1.63065 0.730408 1.71249 1.07144 1.7807C1.08508 1.7807 1.08508 1.7807 1.09873 1.7807C2.79026 2.13538 4.46814 2.27179 6.15967 2.27179C6.90995 2.27179 7.67387 2.24451 8.42414 2.20358C8.42414 2.27179 8.4105 2.34 8.4105 2.4082C7.71479 2.44913 7.00544 2.47641 6.30973 2.47641C4.27717 2.47641 2.23096 2.27179 0.198395 1.72614C0.198395 6.45969 0.198395 11.166 0.198395 15.8859Z"
      fill="#F2994A"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.8152 7.2762L15.7219 8.71206C15.6953 8.98618 15.5486 9.2603 15.3353 9.46915C15.122 9.678 14.8421 9.80854 14.5621 9.83464C14.2821 9.86075 14.0022 9.78243 13.8022 9.61274C13.6022 9.44305 13.4823 9.18198 13.4689 8.90786L13.5622 7.40674L12.6157 7.45895L12.5891 7.94192L11.6825 7.99413L11.6559 8.37268L12.5624 8.32047L12.5491 8.60764L11.6292 8.6468L11.6025 9.02534L12.5091 8.96008C12.5091 9.48221 12.709 9.96518 13.069 10.3046C13.4289 10.6309 13.9355 10.8006 14.4688 10.7484C14.9887 10.7092 15.5086 10.4612 15.9086 10.0827C16.3085 9.70411 16.5485 9.19503 16.6151 8.68596L17.4017 8.63374L17.4283 8.26825L16.6418 8.32047L16.6551 8.04635L17.4417 7.99413L17.4683 7.62864L16.6818 7.68086L16.7084 7.21094L15.8152 7.2762Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1={14.5617}
        y1={16.7466}
        x2={14.5617}
        y2={0.860965}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FDB913" />
        <stop offset={1} stopColor="#F2994A" />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1={26.0069}
        y1={8.4221}
        x2={28.8715}
        y2={8.35389}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF7900" stopOpacity={0} />
        <stop offset={0.6979} stopColor="#FF7900" />
      </linearGradient>
      <linearGradient
        id="paint2_linear"
        x1={14.5024}
        y1={14.3988}
        x2={14.5024}
        y2={2.99827}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FACB5B" />
        <stop offset={1} stopColor="#F5DEA4" />
      </linearGradient>
      <linearGradient
        id="paint3_linear"
        x1={29.1752}
        y1={15.9628}
        x2={0.0645072}
        y2={1.55218}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FDB913" />
        <stop offset={1} stopColor="#F2994A" />
      </linearGradient>
      <linearGradient
        id="paint4_linear"
        x1={27.6777}
        y1={19.6426}
        x2={-1.5488}
        y2={5.2319}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FDB913" />
        <stop offset={1} stopColor="#F2994A" />
      </linearGradient>
    </defs>
  </svg>
);

export default SVGComponent;
