import * as t from '../types';

const INITIAL_STATE = {
  data: {},
  section: {},
  screenSection: {},
  artistScreen: {},
  fetching: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case t.GET_SCREEN_REQUEST:
      return { ...state, fetching: { ...state.fetching, [action.payload]: true } };
    case t.GET_SCREEN_SUCCESS:
      return {
        ...state,
        fetching: { ...state.fetching, [action.payload.id]: false },
        data: action.payload.data ? { ...state.data, [action.payload.id]: action.payload.data } : { ...state.data },
      };
    case t.GET_SCREEN_FAILURE:
      return { ...state, fetching: { ...state.fetching, [action.payload]: false } };

    case t.GET_SCREEN_SECTION_REQUEST:
      return { ...state, fetching: { ...state.fetching, [action.payload]: true } };
    case t.GET_SCREEN_SECTION_SUCCESS:
      return {
        ...state,
        fetching: { ...state.fetching, [action.payload.id]: false },
        section: action.payload.data
          ? { ...state.section, [action.payload.id]: action.payload.data }
          : { ...state.section },
      };
    case t.GET_SCREEN_SECTION_FAILURE:
      return { ...state, fetching: { ...state.fetching, [action.payload]: false } };
    case t.GET_SCREEN_SECTION_BY_ID_REQUEST:
      return { ...state, fetching: { ...state.fetching, [action.payload]: true } };
    case t.GET_SCREEN_SECTION_BY_ID_SUCCESS:
      return {
        ...state,
        fetching: { ...state.fetching, [action.payload.id]: false },
        screenSection: action.payload.data,
      };
    case t.GET_SCREEN_SECTION_BY_ID_FAILURE:
      return { ...state, fetching: { ...state.fetching, [action.payload]: false } };
    case t.GET_ARTIST_SCREEN_REQUEST:
      return { ...state, fetching: { ...state.fetching, [action.payload]: true } };
    case t.GET_ARTIST_SCREEN_SUCCESS:
      return {
        ...state,
        fetching: { ...state.fetching, [action.payload.id]: false },
        artistScreen: action.payload.data
          ? { ...state.artistScreen, [action.payload.id]: action.payload.data }
          : { ...state.artistScreen },
      };
    case t.GET_ARTIST_SCREEN_FAILURE:
      return { ...state, fetching: { ...state.fetching, [action.payload]: false } };
    case t.LOGOUT:
      return INITIAL_STATE;
    default:
      return { ...state };
  }
};
