import { Button } from 'components/atoms';
import styled from '@emotion/styled/macro';

export const Menu = styled.ul`
  max-width: calc(150px);
  background-color: #131516;
  border-radius: 5px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
  opacity: 1;
  overflow: hidden;
  pointer-events: auto;
  position: fixed;
  right: 0;
  transform: translate(-100px, -100%);
  transition: all 0.2s;
  z-index: 20;
`;

export const Wrapper = styled.div`
  display: inline-flex;
  position: relative;
`;

export const Trigger = styled(Button)`
  padding: 0;
  svg {
    width: 2.9rem;
  }
`;

export const ItemIcon = styled.svg`
  height: 3rem;
  margin-right: 1rem;
  width: 3rem;
`;

export const Item = styled.p`
  align-items: center;
  cursor: pointer;
  display: flex;
  font-size: ${props => props.theme.font.size.tiniest};
  font-weight: 500;
  color: white;
  text-decoration: none;
  transition: all 0.1s;
  user-select: none;
  white-space: nowrap;
  padding: 0.5rem;

  &,
  &:visited {
    color: ${props => props.theme.colors.white};
  }
  :hover {
    background: #3b3b40;
    border-radius: 3px;
    cursor: pointer;
  }
  @media (max-width: ${props => props.theme.breakpoints[1]}) {
    font-size: ${props => props.theme.font.size.tiniest};
  }
`;

export const ItemWrapper = styled.p`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Sort = styled.p`
  color: ${({ theme }) => theme.colors.primary};
  font-size: ${({ theme }) => theme.font.size.small};
  text-transform: capitalize;
`;

ItemIcon.defaultProps = {
  fill: '#fff',
};

Item.defaultProps = {
  as: 'li',
};
