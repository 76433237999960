import React from 'react';

function SvgPlayNext({ ...props }) {
  return (
    <svg width="2.8em" height="2.8em" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect x="18" y="12" width="14" height="1" rx="0.5" fill="white" />
      <rect x="18" y="17" width="14" height="1" rx="0.5" fill="white" />
      <rect x="8" y="22" width="24" height="1" rx="0.5" fill="white" />
      <rect x="8" y="27" width="24" height="1" rx="0.5" fill="white" />
      <path d="M14.416 12L17.166 14.75L14.416 17.5" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M16.1662 14.75C16.1662 14.75 13.8012 14.75 12.2859 14.75H9.5V19.5"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgPlayNext;
