import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { uploadStudioxTrack } from 'redux/actions';
import { getAllGenres } from 'redux/actions';
import { useDispatch } from 'react-redux';
import { updateTrackAction } from 'redux/actions';
import { getTrackAction } from 'redux/actions';
import { getBeatsAction } from 'redux/actions';

export const StudioxContext = React.createContext({});

export const StudioxContextProvider = ({ children }) => {
  const dispatch = useDispatch();
  const getStudioxBeatsHandler = cb => getBeatsAction(cb);
  const $updateSongDetails = (payload, cb) => dispatch(updateTrackAction(payload, cb));
  const $fetchSongById = (id, cb) => dispatch(getTrackAction(id, cb));
  const fetchGenres = useCallback(cb => {
    getAllGenres(cb);
  }, []);
  const uploadSongHandler = (file, progress, cb) => uploadStudioxTrack(file, progress, cb, { studiox: 'studiox' });
  const [StudioxModel, SetModel] = useState({
    songFile: {},
    genres: [
      {
        name: 'Hip Hop',
        id: 'xxxxxx',
      },
    ],
    songDetails: {
      genre: 'Hip Hop',
    },
  });

  const setSongFile = file => {
    SetModel({
      ...StudioxModel,
      songFile: file,
      songDetails: { ...StudioxModel.songDetails, title: file.name },
    });
  };
  const setSongDetails = details => {
    SetModel({
      ...StudioxModel,
      songDetails: { ...StudioxModel.songDetails, ...details },
    });
  };

  const getGenres = cb => {
    fetchGenres((data, err) => {
      // SetModel({
      //   ...StudioxModel,
      //   genres: data,
      // });
      cb(data, err);
    });
  };
  const uploadStudioxSong = (progress, cb) => {
    uploadSongHandler(StudioxModel.songFile, progress, cb);
  };
  const updateSongDetails = (id, cb = () => {}) => {
    const payload = { ...StudioxModel.songDetails };
    payload.id = id;
    $updateSongDetails(payload, (data, err) => {
      cb();
    });
  };

  const fetchSongById = (id, cb) => {
    if (!id) {
      return cb(null, { msg: 'Id is required' });
    }
    $fetchSongById(id, (data, err) => {
      setSongDetails(data);
      cb(data, err);
    });
  };
  return (
    <StudioxContext.Provider
      value={{
        StudioxModel,
        setSongFile,
        setSongDetails,
        uploadStudioxSong,
        getGenres,
        updateSongDetails,
        fetchSongById,
        getStudioxBeatsHandler,
      }}
    >
      {children}
    </StudioxContext.Provider>
  );
};

StudioxContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
