import React from 'react';
import styled from '@emotion/styled/macro';
import { Box } from 'rebass';
import PropTypes from 'prop-types';
import { Typography } from 'components/atoms';
import { CheckBox } from './CheckBox';

const Wrapper = styled(Box)`
  align-items: center;
  border-radius: 0.5rem;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  &:not(:last-child) {
    margin-bottom: 1rem;
  }
`;

const Checker = styled(CheckBox)`
  margin-right: 1rem;
`;

const Text = styled(Typography.Text)`
  color: ${({ theme }) => theme.colors.switchingText};
  display: inline-block;
  user-select: none;
`;

export const Radio = ({ onClick, selected, text }) => {
  return (
    <Wrapper onClick={onClick}>
      <Checker checked={selected} />
      <Text>{text}</Text>
    </Wrapper>
  );
};

Radio.propTypes = {
  onClick: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
};
