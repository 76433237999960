import * as t from '../types';

const INITIAL_STATE = {
  claiming: false,
  editing: {},
  fetching: false,
  fetchingActivity: false,
  listeningActivity: [],
  user: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case t.CHANGE_PASSWORD_REQUEST:
      return { ...state, editing: { ...state.editing, password: true } };

    case t.CHANGE_PASSWORD_SUCCESS:
    case t.CHANGE_PASSWORD_FAILURE:
      return { ...state, editing: { ...state.editing, password: false } };

    case t.UPDATE_PROFILE_REQUEST:
      return { ...state, editing: { ...state.editing, info: true } };

    case t.UPDATE_PROFILE_SUCCESS:
      return { ...state, editing: { ...state.editing, info: false }, user: { ...state.user, ...action.payload } };

    case t.UPDATE_PROFILE_FAILURE:
      return { ...state, editing: { ...state.editing, info: false } };

    case t.GET_PROFILE_REQUEST:
      return { ...state, fetching: true };
    case t.COMPLETE_REGISTRATION_SUCCESS:
    case t.GET_PROFILE_SUCCESS:
      return { ...state, user: action.payload, fetching: false };
    case t.GET_PROFILE_FAILURE:
      return { ...state, fetching: false };
    case t.GET_LISTENING_ACTIVITY_REQUEST:
      return { ...state, fetchingActivity: true };
    case t.GET_LISTENING_ACTIVITY_SUCCESS:
      return { ...state, listeningActivity: action.payload, fetchingActivity: false };
    case t.GET_LISTENING_ACTIVITY_FAILURE:
      return { ...state, fetchingActivity: false };

    case t.UPDATE_USER_AVATAR_REQUEST:
      return { ...state, editing: { ...state.editing, avatar: true } };

    case t.UPDATE_USER_AVATAR_SUCCESS:
      return {
        ...state,
        editing: { ...state.editing, avatar: false },
        user: { ...state.user, avatar: action.payload.avatar },
      };
    case t.UPDATE_USER_AVATAR_FAILURE:
      return { ...state, editing: { ...state.editing, avatar: false } };

    case t.CLAIM_REWARD_REQUEST:
      return { ...state, claiming: true };

    case t.CLAIM_REWARD_SUCCESS:
    case t.CLAIM_REWARD_FAILURE:
      return { ...state, claiming: false };

    case t.LOGOUT:
      return INITIAL_STATE;
    default:
      return { ...state };
  }
};
