import React from 'react';
import PropTypes from 'prop-types';

function SvgManageTracks({ fill, ...props }) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 195.569 186.629" {...props}>
      <g fill={fill}>
        <path
          data-name="Path 1037"
          d="M160.021 124.418a8.068 8.068 0 01-6.221-2.666 8.591 8.591 0 010-12.442l26.661-26.662a8.591 8.591 0 0112.442 0 8.591 8.591 0 010 12.442l-26.661 26.661a8.068 8.068 0 01-6.221 2.666z"
        />
        <path
          data-name="Path 1038"
          d="M186.682 97.76a8.068 8.068 0 01-6.221-2.666L153.8 68.432a8.591 8.591 0 010-12.442 8.591 8.591 0 0112.442 0l26.661 26.661a8.591 8.591 0 010 12.442 8.068 8.068 0 01-6.221 2.667z"
        />
        <path
          data-name="Path 1039"
          d="M8.887 97.76a8.068 8.068 0 01-6.221-2.666 8.591 8.591 0 010-12.442L29.327 55.99a8.591 8.591 0 0112.442 0 8.591 8.591 0 010 12.442L15.108 95.093a8.068 8.068 0 01-6.221 2.666z"
        />
        <path
          data-name="Path 1040"
          d="M35.548 124.421a8.068 8.068 0 01-6.221-2.666L2.666 95.093a8.591 8.591 0 010-12.442 8.591 8.591 0 0112.442 0l26.661 26.661a8.591 8.591 0 010 12.442 8.068 8.068 0 01-6.221 2.667z"
        />
        <path
          data-name="Path 1041"
          d="M186.257 97.758H9.313C3.725 97.758 0 94.203 0 88.871s3.725-8.887 9.313-8.887h176.944c5.588 0 9.313 3.555 9.313 8.887s-3.726 8.887-9.313 8.887z"
        />
        <path
          data-name="Path 1042"
          d="M96.588 186.631a8.068 8.068 0 01-6.221-2.666l-26.661-26.662a8.591 8.591 0 010-12.442 8.591 8.591 0 0112.442 0l26.661 26.661a8.591 8.591 0 010 12.442 8.068 8.068 0 01-6.221 2.667z"
        />
        <path
          data-name="Path 1043"
          d="M96.086 186.631a8.068 8.068 0 01-6.221-2.666 8.591 8.591 0 010-12.442l26.661-26.662a8.591 8.591 0 0112.442 0 8.591 8.591 0 010 12.442l-26.661 26.661a8.068 8.068 0 01-6.221 2.666z"
        />
        <path
          data-name="Path 1044"
          d="M122.747 44.437a8.068 8.068 0 01-6.221-2.666L89.865 15.109a8.591 8.591 0 010-12.442 8.591 8.591 0 0112.442 0l26.661 26.661a8.591 8.591 0 010 12.442 8.068 8.068 0 01-6.221 2.667z"
        />
        <path
          data-name="Path 1045"
          d="M69.927 44.437a8.068 8.068 0 01-6.221-2.666 8.591 8.591 0 010-12.442L90.367 2.667a8.591 8.591 0 0112.442 0 8.591 8.591 0 010 12.442L76.148 41.77a8.068 8.068 0 01-6.221 2.666z"
        />
        <path
          data-name="Path 1046"
          d="M96.333 186.63c-5.332 0-8.887-3.555-8.887-8.887V8.888c0-5.332 3.555-8.887 8.887-8.887s8.887 3.555 8.887 8.887v168.855c0 5.332-3.555 8.887-8.887 8.887z"
        />
      </g>
    </svg>
  );
}

SvgManageTracks.propTypes = {
  fill: PropTypes.string,
};

SvgManageTracks.defaultProps = {
  fill: '#fff',
};

export default SvgManageTracks;
