import * as t from '../types';

const INITIAL_STATE = {
  tickets: [],
  setReminder: '',
  loading: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case t.GET_EVENT_REQUEST:
      return { ...state, loading: true };
    case t.GET_EVENT_SUCCESS:
      return { ...state, loading: false, tickets: action.payload };
    case t.GET_EVENT_FAILURE:
      return { ...state, loading: false };
    case t.BUY_EVENT_REQUEST:
      return { ...state, loading: true };
    case t.BUY_EVENT_SUCCESSFUL:
      return { ...state, loading: false, tickets: [...state.tickets, action.payload.id] };
    case t.BUY_EVENT_FAILURE:
      return { ...state, loading: false };
    case t.SET_REMINDER_REQUEST:
      return { ...state, loading: true };
    case t.SET_REMINDER_SUCCESS:
      return { ...state, loading: false, setReminder: action.payload };
    case t.SET_REMINDER_FAILURE:
      return { ...state, loading: false };
    case t.LOGOUT:
      return INITIAL_STATE;
    default:
      return { ...state };
  }
};
