import React, { memo, useEffect, useRef, useState, useContext } from 'react';
import { Image, Typography } from 'components/atoms';
import { TextModalContext } from 'contexts/TextModalContext';
import { Box } from 'rebass';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import styled from '@emotion/styled/macro';
import { PowerIcon, SettingsIcon, ProfileIcon } from 'components/icons';

const Avatar = styled(Image)`
  height: 4rem;
  object-fit: cover;
  width: 4rem;
  border-radius: 4rem;
  flex-shrink: 0;
`;

const ProfileMenuItem = styled.li`
  display: block;
  // &:not(:last-of-type) {
  //   border-bottom: 1px solid ${({ theme }) => theme.colors['greyish-brown-three']}40;
  // }
`;

const ProfileMenuLink = styled(Typography.Paragraph)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 1.5rem 2rem;
  text-decoration: none;
  text-align: left;
  color: white;
  font-size: 1.2rem !important;
  font-weight: bold;
  border-radius: 5px;
  svg {
    margin-rignt: 1rem;
  }
  &:hover {
    color: ${({ theme }) => theme.colors.glo};
    background-color: ${({ theme }) => theme.colors.sidebar};
    svg {
      fill: ${({ theme }) => theme.colors.glo};
    }
  }
`;

ProfileMenuLink.defaultProps = {
  as: Link,
  size: 'small',
  to: '#',
};

const ProfileMenu = styled.ul`
  background-color: ${({ theme }) => theme.colors['dark-two']};
  border-radius: 0.5rem;
  left: -35%;
  margin-top: 0.5rem;
  min-width: 17rem;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 109%;
  transform: translateX(-50%);
  transition: all 0.2s;
  z-index: 1;
`;

const ProfileWrapper = styled(Box)`
  align-items: center;
  cursor: pointer;
  display: flex;
  position: relative;
  margin-right: 2rem;
  ${Typography.Paragraph} {
    display: none;

    ${({ theme }) => theme.mq.md`
      display: block;
  `}
  }

  ${({ theme }) => theme.mq.md`
  ${Avatar} {
      margin-right: 1rem;
      img{
        margin-right: 0;
      }
  }
  `}

  ${({ active }) =>
    active &&
    css`
      ${ProfileMenu} {
        opacity: 1;
        pointer-events: auto;
      }
    `}
`;

export const ProfileComponent = memo(({ avatar, name }) => {
  const [active, setActive] = useState(false);
  const toggleActive = () => setActive(v => !v);
  const node = useRef();
  const { toggleLogoutModal } = useContext(TextModalContext);

  const handleClick = e => {
    if (!node.current.contains(e.target)) {
      setActive(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  return (
    <ProfileWrapper onClick={toggleActive} active={active} ref={node}>
      <Avatar size="50x50" src={avatar} spinnerSize="1rem" alt={`${name}'s avatar`} useThumbnailer={false} />

      <ProfileMenu>
        <ProfileMenuItem>
          <ProfileMenuLink
            color="switchingText"
            size="small"
            ellipsize="true"
            style={{ textTransform: 'uppercase', fontWeight: 'bold' }}
            to="/profile/me"
          >
            {name}
          </ProfileMenuLink>
        </ProfileMenuItem>
        <ProfileMenuItem>
          <ProfileMenuLink to="/profile/me">
            <Box mr="1rem">
              <ProfileIcon fill="white" size="2rem" />
            </Box>
            Profile
          </ProfileMenuLink>
        </ProfileMenuItem>
        <ProfileMenuItem>
          <ProfileMenuLink to="/edit-profile">
            <Box mr="1rem">
              <SettingsIcon fill="white" size="2rem" />
            </Box>
            Edit Profile
          </ProfileMenuLink>
        </ProfileMenuItem>
        {/* <ProfileMenuItem>
          <ProfileMenuLink to="/change-password">Change Password</ProfileMenuLink>
        </ProfileMenuItem> */}
        {/* <ProfileMenuItem>
            <ProfileMenuLink>Help & Support</ProfileMenuLink>
          </ProfileMenuItem> */}
        <ProfileMenuItem>
          <ProfileMenuLink onClick={toggleLogoutModal}>
            <Box mr="1rem">
              <PowerIcon fill="white" size="2rem" />
            </Box>
            Logout
          </ProfileMenuLink>
        </ProfileMenuItem>
      </ProfileMenu>
    </ProfileWrapper>
  );
});

ProfileComponent.propTypes = {
  avatar: PropTypes.string.isRequired,
  logout: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
};
