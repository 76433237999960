import { css, keyframes } from '@emotion/core';

import styled from '@emotion/styled/macro';

const move = keyframes`
0% {
  background-position: 0 0;
}
100% {
  background-position: 50px 50px;
}
`;

export const Progressbar = styled.div`
  background: ${({ theme }) => theme.colors.progressBar};
  border-radius: ${props => (props.video ? '6rem' : '0rem')};
  height: ${props => (props.video ? '1rem' : '0.3rem')};
  overflow: hidden;
  position: relative;
  width: 100%;
  cursor: pointer;
  &:hover {
    height: ${props => (props.video ? '1rem' : '0.5rem')};
  }
  &:after {
    background: ${({ theme }) => theme.colors.primary};
    opacity: 1;
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    top: 0;
    width: ${({ progress }) => progress}%;
    transition: all 0.1s;
    border-top-right-radius: 8rem;
    border-bottom-right-radius: 8rem;
    ${({
      animate,
      theme: {
        colors: { primary },
      },
    }) =>
      animate &&
      css`
        background: linear-gradient(
          -45deg,
          ${primary} 25%,
          transparent 25%,
          transparent 50%,
          ${primary} 50%,
          ${primary} 75%,
          transparent 75%,
          transparent
        );
        background-size: 50px 50px;
        animation: ${move} 1s linear infinite;
      `}
  }
  @media (max-width: 706px) {
    height: 0.5rem;
  }
`;
