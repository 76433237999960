import React from 'react';
import PropTypes from 'prop-types';

function SvgSong({ fill, ...props }) {
  return (
    <svg width="11" height="25" viewBox="0 0 11 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="5.19048" cy="19.4623" r="4.19048" fill={fill} stroke={fill} strokeWidth="2" />
      <path
        d="M9.38086 18.9385V1.65283"
        stroke={fill}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill={fill}
      />
    </svg>
  );
}

SvgSong.propTypes = {
  fill: PropTypes.string,
};

SvgSong.defaultProps = {
  fill: '#fcb316',
};

export default SvgSong;
