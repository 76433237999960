import React, { useContext } from 'react';
import styled from '@emotion/styled/macro';
import { useTheme } from 'emotion-theming';
import { Box } from 'rebass';
import PropTypes from 'prop-types';
import bg from 'assets/img/hero-bg.jpg';
import { Typography, Button, IconButton } from 'components/atoms';
import { LogoIcon, PlaystoreIcon, AppStoreIcon, TextBubbleIcon, PlayIcon } from 'components/icons';
import { TextModalContext } from 'contexts/TextModalContext';
import { useUser } from 'hooks';
import { MagicContext } from 'contexts/MagicContext';
import { AuthModalContext } from 'contexts/AuthModalContext';

const Wrapper = styled(Box)`
  background-repeat: no-repeat;
  background-size: 100%, cover;
  display: flex;
  flex-direction: column;
  
  overflow-x: hidden;
  position: relative;
  background-image: ${({
    poster,
    theme: {
      colors: { switchingBg },
    },
  }) => `linear-gradient(to right,  ${switchingBg}c4, ${switchingBg}c4),url(${poster})`};

  &:after {
          background-image: ${({
            theme: {
              colors: { switchingBg },
            },
          }) => `linear-gradient(to bottom, ${switchingBg} , ${switchingBg}00)`};
          content: '';
          height: 3rem;
          left: 0;
          position: absolute;
          top: 0;
          width: 100%;   
        }
  

  ${({
    theme: {
      colors: { switchingBg },
      mq,
    },
    poster,
  }) => mq.sm`
    background: ${switchingBg};
    &:before{
      background-image: ${`linear-gradient(to right,  ${switchingBg} 0rem, ${switchingBg}00), url(${poster})`};
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      content: '';
      height: 100%;
      position: absolute;
      right: 0;
      top:0;
      width: calc(100% - 22rem);
    }
  `}

  ${({
    theme: {
      colors: { switchingBg },
      mq,
    },
    poster,
  }) => mq.md`
    &:before{
      background-image: ${`linear-gradient(to right,  ${switchingBg} 0rem, ${switchingBg}00), url(${poster})`};    
      right:0;
      width: calc(100% - 25rem);
    }
  `}

  ${({
    theme: {
      colors: { switchingBg },
      mq,
    },
    poster,
  }) => mq.lg`
    &:before{
      background-image: ${`linear-gradient(to right,  ${switchingBg} 0rem, ${switchingBg}00), url(${poster})`};    
      right: -1rem;
      width: calc(100% - 32rem);
    }
  `}
`;

const HeroContent = styled(Box)`
  align-items: center;
  display: flex;
  flex: 1;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 10rem;
  padding-bottom: 5rem;
  width: 100%;

  ${({ theme }) => theme.mq.md`
  padding-left: 9rem;
  padding-right: 9rem;
  padding-top: 20rem;
  padding-bottom: 20rem;
  `}
`;

const Title = styled(Typography.Heading)`
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 1.1 !important;
  margin-bottom: 2rem;
`;

const Subtitle = styled(Typography.Paragraph)`
  line-height: 1.2;
  margin-bottom: 2rem;
  opacity: 0.6;
  position: relative;
`;

const More = styled(Box)`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: auto;
  padding: 2rem;
  position: relative;
  width: 100%;

  background-image: ${({
    theme: {
      colors: { switchingBg },
    },
  }) => `linear-gradient(to top, ${switchingBg} 25%, ${switchingBg}00)`};
`;

const ActionButtons = styled(Box)`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  button,
  a {
    margin-bottom: 1rem;
    min-width: 14rem;

    &:nth-child(odd) {
      margin-right: 1.5rem;
    }

    &:last-child {
      margin-right: 0;
    }

    @media screen and (min-width: 28.125rem) {
      &:not(:last-child) {
        margin-right: 1.5rem;
      }
    }

    ${({ theme }) => theme.mq.sm`
    min-width: unset;
    `}
  }
`;

const CTA = styled(Button)`
  font-size: 1.8rem;
  letter-spacing: 1px;
  padding: 1.4rem 1.6rem;
  position: relative;

  svg {
    margin-right: 1.2rem;
  }
`;

export const HeroBanner = ({ title, subtitle, cta }) => {
  const { toggle } = useContext(TextModalContext);
  const { loadMagic } = useContext(MagicContext);
  const { toggleAuthModal } = useContext(AuthModalContext);
  const { user } = useUser();

  const onGetStarted = () => {
    if (user) {
      loadMagic('me');
    } else {
      toggleAuthModal({ link: 'magic', isFunction: true });
    }
  };

  return (
    <Wrapper poster={bg}>
      <HeroContent as={Box} mb={['4rem']} width={[1, null, null, '84rem']}>
        <Box display="flex" flexDirection="column">
          <Title color="switchingText">{title}</Title>
          <Subtitle color="switchingText" size="big">
            {subtitle}
          </Subtitle>
          <Box>
            <CTA onClick={onGetStarted} fontSize="small" fontWeight="bold" aria-label="cta">
              <PlayIcon style={{ marginRight: '0.5rem' }} fill="inherit" /> {cta.text}
            </CTA>
          </Box>
        </Box>
      </HeroContent>
      <More>
        <Box display="flex" alignItem="center" justifyContent="center" flexDirection="column" mb="1.5rem">
          <Typography.Paragraph align="center" color="switchingText">
            Enjoy the best of unlimited music on{' '}
            <LogoIcon
              fill={useTheme().colors.switchingText}
              width="7rem"
              height="2.5rem"
              style={{ marginLeft: '1rem', verticalAlign: 'middle' }}
            />
          </Typography.Paragraph>
        </Box>

        <ActionButtons>
          <IconButton
            as="a"
            target="_blank"
            rel="noopener noreferrer"
            href="https://play.google.com/store/apps/details?id=ng.groove.udux"
            icon={PlaystoreIcon}
            title="Get it on"
            subtitle="Google Play"
          />
          <IconButton
            as="a"
            target="_blank"
            rel="noopener noreferrer"
            href="https://apps.apple.com/app/udux/id1372897101"
            icon={AppStoreIcon}
            title="Download on the"
            subtitle="App Store"
          />
          <IconButton icon={TextBubbleIcon} title="Text me" subtitle="The App" onClick={() => toggle('text')} />
        </ActionButtons>
      </More>
    </Wrapper>
  );
};

HeroBanner.defaultProps = {
  title: 'Follow your Rhythm on uduX',
  subtitle:
    'uduX is for music lovers who want amazing curated music from around the world and also for artistes, labels and content owners who want their Music to reach the world - upload unlimited contents!',
  cta: {
    text: 'Get started',
    link: '#',
  },
};

HeroBanner.propTypes = {
  cta: PropTypes.shape({
    link: PropTypes.string,
    text: PropTypes.string,
  }),
  subtitle: PropTypes.string,
  title: PropTypes.string,
};
