import React from 'react';
import PropTypes from 'prop-types';

function SvgPlaylist({ fill, ...props }) {
  return (
    <svg width="2.8em" height="2.8em" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect x="8" y="12" width="24" height="1" rx="0.5" fill={fill} />
      <rect x="8" y="17" width="24" height="1" rx="0.5" fill={fill} />
      <rect x="8" y="22" width="14" height="1" rx="0.5" fill={fill} />
      <rect x="8" y="27" width="14" height="1" rx="0.5" fill={fill} />
      <rect x="24" y="24" width="7" height="1" rx="0.5" fill={fill} />
      <path
        d="M27.5 28C27.2239 28 27 27.7761 27 27.5V21.5C27 21.2239 27.2239 21 27.5 21C27.7761 21 28 21.2239 28 21.5V27.5C28 27.7761 27.7761 28 27.5 28Z"
        fill={fill}
      />
    </svg>
  );
}

SvgPlaylist.propTypes = {
  fill: PropTypes.string,
};

SvgPlaylist.defaultProps = {
  fill: '#fff',
};

export default SvgPlaylist;
