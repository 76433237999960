import React from 'react';
import styled from '@emotion/styled/macro';
import PropTypes from 'prop-types';
import { Typography } from 'components/atoms';
import { Box } from 'rebass';

const Header = styled(Box)`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.tileBg};
  border-radius: 0.5rem;
  display: flex;
  justify-content: space-between;
  padding: 1rem 2rem;
  width: 100%;

  svg {
    height: 2.5rem;
    width: 2.5rem;
  }
`;

const Title = styled(Typography.Paragraph)`
  align-items: center;
  color: ${({ theme }) => theme.colors.switchingText};
  display: inline-flex;
  font-size: ${({ theme }) => theme.font.size.normal};
  font-weight: ${({ theme }) => theme.font.weight.bold};
`;

export const EntityHeader = ({ icon: Icon, title, mb, ...props }) => {
  return (
    <Header as="header" mb={mb} {...props}>
      <Title>
        <Icon fill="#FCB316" style={{ marginRight: '1rem' }} />
        {title}
      </Title>
    </Header>
  );
};

EntityHeader.defaultProps = {
  mb: '3rem',
};

EntityHeader.propTypes = {
  icon: PropTypes.func.isRequired,
  mb: PropTypes.string,
  title: PropTypes.string.isRequired,
};
