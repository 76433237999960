import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { registerFcmSW } from 'fcmSW';
// import * as Sentry from '@sentry/browser';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import * as serviceWorker from './serviceWorker';
import App from './App';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_ERROR_TRACKER,
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  beforeSend(event, hint) {
    const { message } = hint.originalException;
    if (message && message.match(/resizeobserver/i)) {
      return null;
    }

    if (message && message.match(/FirebaseError/i)) {
      return null;
    }
    if (message && message.match(/network error/i)) {
      return null;
    }
    return event;
  },
});

ReactDOM.render(<App />, document.getElementById('root'));
serviceWorker.register();
registerFcmSW();
