import * as t from '../types';

import api from 'services/api';

export const buyEventAction = (
  product,
  amount,
  itemType = 'livestream',
  pinnedComment = null,
  callback = () => {},
) => async dispatch => {
  try {
    dispatch({ type: t.BUY_EVENT_REQUEST });
    // Comment out to avoid spending real money
    // setTimeout(() => {
    //   callback(null, true);
    // }, 1000);
    // return;
    const resp = await api.buyEvent({ eventId: product?.id, itemType, amount, pinnedComment });
    if (resp.status === 200 || resp.status === 201) {
      dispatch({ type: t.BUY_EVENT_SUCCESSFUL, payload: { id: product?.id, data: resp.data } });
      callback(null, resp.data);
    } else {
      throw resp.data;
    }
  } catch (e) {
    console.log(e);
    if (e?.msg.includes('Insufficient fund')) {
      callback('Insufficient fund, opening payment portal...');
      dispatch({ type: t.BUY_EVENT_FAILURE });
      try {
        const resp1 = await api.walletPurchase(product.priceId);
        if (resp1.status === 200 || resp1.status === 201) {
          const paymentLink = resp1?.data?.data?.authorization_url;
          callback({ paymentLink });
        } else {
          throw resp1.data;
        }
      } catch (error) {
        callback('Something went wrong trying to get a payment link');
      }
    } else {
      callback(e?.msg || 'An  error occured');
      dispatch({ type: t.BUY_EVENT_FAILURE });
    }
  }
};

export const getPurchasedEventsAction = (callback = () => {}) => async dispatch => {
  try {
    dispatch({ type: t.GET_EVENT_REQUEST });
    const resp = await api.eventsPurchased();
    if (resp.status === 200 || resp.status === 201) {
      if (resp.data[0]) {
        dispatch({ type: t.GET_EVENT_SUCCESS, payload: resp.data[0].items });
        callback(null, resp.data);
        return;
      } else {
        throw resp.data;
      }
    } else {
      throw resp.data;
    }
  } catch (e) {
    callback(e || 'An error has occured');
    console.error(e);
    dispatch({ type: t.GET_EVENT_FAILURE });
  }
};

export const getBillItems = async (callback = () => {}) => {
  try {
    const resp = await api.getBills();
    if (resp.status === 200 || resp.status === 201) {
      callback(null, resp.data);
    } else {
      throw resp.data;
    }
  } catch (e) {
    callback(e || 'An error has occured');
    console.error(e);
  }
};

export const fundWallet = async (product, callback = () => {}) => {
  try {
    const resp = await api.walletPurchase(product.productId);
    if (resp.status === 200 || resp.status === 201) {
      callback(null, resp?.data?.data);
    } else {
      throw resp.data;
    }
  } catch (error) {
    callback(error?.msg || error);
  }
};

export const livestreamActions = (payload, callback = () => {}) => async dispatch => {
  try {
    const $payload = {
      name: 'like_livestream',
      target: 'videos',
      description: 'user like live',
      ...payload,
    };
    const resp = await api.likeLivestream($payload);
    if (resp.status === 200 || resp.status === 201) {
      callback(null, resp?.data?.data);
    } else {
      throw resp.data;
    }
  } catch (error) {
    callback(error?.msg || error);
  }
};

export const createLivestreamCommentAction = (id, comment, callback = () => {}) => async () => {
  try {
    const resp = await api.createComment({
      targetID: id,
      target: 'videos',
      comment,
      name: 'livestream_comment',
    });

    if (resp.status === 200) {
      callback(true, null);
    } else {
      throw resp.data;
    }
  } catch (e) {
    callback(null, e);
  }
};

export const setReminderAction = (videoId, callback = () => {}) => async dispatch => {
  try {
    dispatch({ type: t.SET_REMINDER_REQUEST });
    const resp = await api.setReminder(videoId);
    if (resp.status === 200 || resp.status === 201) {
      dispatch({ type: t.SET_REMINDER_SUCCESS, payload: { data: resp.data.msg } });
    } else {
      dispatch({ type: t.SET_REMINDER_FAILURE });
      callback(false, resp.data);
    }
  } catch (e) {
    callback(false, e);
    dispatch({ type: t.SET_REMINDER_FAILURE });
  }
};
