import React, { useState } from 'react';
import styled from '@emotion/styled/macro';
// import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from 'rebass';
import { Typography, Form, Button } from 'components/atoms';
import { Modal } from 'components/molecules';
import { deletePlaylistAction } from 'redux/actions';
import { useToast } from 'hooks';

const ActionButtons = styled(Box)`
  align-items: center;
  justify-content: center;
  button {
    padding: 0.75rem 1.5rem;
    svg {
      margin-right: 0.5rem;
    }
    &:not(:last-child) {
      margin-right: 1rem;
    }
  }
`;

const DeletePlaylistModal = ({ isOpen, toggle, playlist }) => {
  const dispatch = useDispatch();
  const [error, setError] = useState('');
  const deletePlaylist = cb => dispatch(deletePlaylistAction(playlist.id, cb));
  const deleting = useSelector(state => state.playlist.deleting || false);
  const { addToast } = useToast();

  const onSuccess = () => {
    toggle();
  };

  const onError = e => {
    setError(e?.error || 'An error occured', { type: 'error' });
  };

  const callback = (success, e) => {
    if (success) {
      addToast(`${playlist.name} deleted successfully`, { type: 'success' });
      onSuccess();
    } else {
      onError(e);
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="sm" showCloseIcon>
      <Modal.Header justifyContent="center">
        <Typography.Paragraph>Delete playlist</Typography.Paragraph>
      </Modal.Header>
      <Modal.Body>
        <Typography.Paragraph align="center" color="switchingText" style={{ marginBottom: '2rem' }}>
          Are you sure you want to delete playlist `{playlist.name}` ?
        </Typography.Paragraph>

        <Form.Error error={error} />

        <ActionButtons>
          <Button
            effect
            color="primary"
            fontWeight="bold"
            fontSize="tiny"
            loading={deleting}
            onClick={() => deletePlaylist(callback)}
          >
            Yes
          </Button>

          <Button effect color="white" fontWeight="bold" fontSize="tiny" onClick={toggle}>
            Cancel
          </Button>
        </ActionButtons>
      </Modal.Body>
    </Modal>
  );
};

// DeletePlaylistModal.propTypes = {
//   isOpen: PropTypes.bool.isRequired,
//   playlist: PropTypes.object.isRequired,
//   toggle: PropTypes.func.isRequired,
//   type: PropTypes.string.isRequired,
// };

export default DeletePlaylistModal;
