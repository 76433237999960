import React, { useCallback, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import {
  OPEN_CART,
  OPEN_COMMENTS,
  MAKE_IT_RAIN,
  MAKE_PAYMENT,
  OPEN_SHARE,
  OPEN_STREAMERS,
  OPEN_BADGE,
  BUY_TICKET,
} from 'redux/types';
import LiveStreamComment from 'components/organisms/LivestreamSidePanels/Comments';
import ShareLiveStream from 'components/organisms/LivestreamSidePanels/ShareLivestream';
import LiveStreamCart from 'components/organisms/LivestreamSidePanels/Cart';
import LiveStreamBadge from 'components/organisms/LivestreamSidePanels/Badge';
import MakeItRain from 'components/organisms/LivestreamSidePanels/MakeItRain';
import BuyTicket from 'components/organisms/LivestreamSidePanels/BuyTicket/ButTicket';
// import ExternalPaymentHandler from 'components/organisms/LivestreamSidePanels/MakeItRain/ExternalPaymentHandler';
import { useDispatch, useSelector } from 'react-redux';
import { getPurchasedEventsAction, buyEventAction, getProfileAction } from 'redux/actions';
import ExternalPaymentHandler from 'components/organisms/ExternalPaymentHandler';
import { createCommentAction } from 'redux/actions';
import { setCommentListener, setCollectionListener } from 'services/firebase';
import { WalletContext } from './WalletContext';
import * as utils from './../lib/utils';
export const LivestreamContext = React.createContext({});

export const LivestreamProvider = ({ children }) => {
  const dispatch = useDispatch();
  const { setSprayMoneyStatus } = useContext(WalletContext);
  const buyTicket = useCallback((id, amount, cb) => dispatch(buyEventAction(id, amount, 'livestream', null, cb)), [
    dispatch,
  ]);
  const { tickets } = useSelector(({ livestream: { tickets } }) => ({
    tickets,
  }));
  const getTickets = useCallback(cb => dispatch(getPurchasedEventsAction(cb)), [dispatch]);
  const getProfile = () => dispatch(getProfileAction());
  // const { firstName, lastName } = useSelector(state => state.profile.user);
  const createCommentHandler = (id, type, comment, callback) =>
    dispatch(createCommentAction(id, type, comment, callback));

  const [sidePanelContent, setSidePanelContent] = useState({
    key: BUY_TICKET,
    component: <BuyTicket />,
  });
  const [sidePanelWidth, setsidePanelWidth] = useState(0);

  const [LivestreamModel, SetLivestreamModel] = useState({
    ticketPrice: 0,
    allComments: null,
    comment: '',
    event: {
      comments: [],
      artists: {},
    },
    sprayMoney: false,
    hasTicket: false,
  });

  const [magicComment, setMagicComment] = useState({
    isOpened: false,
    user: '',
    text: '',
  });

  const [streamers, setStreamers] = useState([]);
  const [currentCount, setCurrentCount] = useState(0);
  const [showLove, setShowLove] = useState(false);
  const [loveList, setLoveList] = useState([]);

  const [livestreamStats, setLivestreamStats] = useState({
    created: 0,
    likes: 0,
    shares: 0,
    views: 0,
    comments: 0,
  });

  const resetLoveList = () => {
    setLoveList([]);
  };
  const setTicketPrice = ticketPrice => {
    SetLivestreamModel({
      ...LivestreamModel,
      ticketPrice,
    });
  };
  const hideSideBar = () => {
    // const sidebar = document.querySelector('.live-stream-side-bar');
    // console.log(sidebar.style.right = "-400px")
    // sidebar.style
  };
  const updateSidePanelContent = key => {
    const component = getSidePanelContentHandler(key);
    setSidePanelContent({ key, component });
  };

  const hasTicketChecker = id => {
    return tickets.indexOf(id || LivestreamModel.event.id) >= 0;
  };
  const hasTicketHandler = hasTicket => {
    SetLivestreamModel({
      ...LivestreamModel,
      hasTicket,
    });
  };

  const addComment = comment => {
    const prevComment = LivestreamModel.event.comments;
    const updatedComment = [comment, ...prevComment];
    SetLivestreamModel({
      ...LivestreamModel,
      event: { ...LivestreamModel.event, comments: updatedComment },
    });
  };

  const setLivestreamEvent = (status, ticketPrice, event) => {
    if (!ticketPrice && !event) {
      return SetLivestreamModel({
        ...LivestreamModel,
        hasTicket: false,
        ticketPrice: 0,
        event: { comments: [], artists: {} },
      });
    }
    SetLivestreamModel({
      ...LivestreamModel,
      hasTicket: status,
      ticketPrice,
      event: { ...LivestreamModel.event, ...event },
    });
  };

  const setComment = comment => {
    SetLivestreamModel({
      ...LivestreamModel,
      comment,
    });
  };
  const hideMagicComment = () => {
    setMagicComment({
      isOpened: false,
      user: null,
      text: null,
    });
  };
  const createComment = (cb = () => {}) => {
    const {
      event: { id },
      comment,
    } = LivestreamModel;
    cb(true, false);
    // return;
    createCommentHandler(id, 'livestream', comment, (data, error) => {
      // createMagicComment(`${firstName} ${lastName}`, comment);
      cb(data, error);
    });
  };

  const createMagicComment = (user, text, userImage, duration) => {
    setMagicComment({
      isOpened: true,
      user,
      text,
      userImage,
    });
    setTimeout(() => {
      hideMagicComment();
    }, duration);
  };
  const getSidePanelContentHandler = key => {
    switch (key) {
      case OPEN_STREAMERS:
        return <h1>T</h1>;
      case OPEN_COMMENTS:
        return <LiveStreamComment />;
      case OPEN_CART:
        return <LiveStreamCart />;
      case OPEN_SHARE:
        return <ShareLiveStream />;
      case OPEN_BADGE:
        return <LiveStreamBadge />;
      case MAKE_IT_RAIN:
        return <MakeItRain />;
      case BUY_TICKET:
        return <BuyTicket />;
      case MAKE_PAYMENT:
        return <ExternalPaymentHandler />;
      default:
        return null;
    }
  };

  const buyTicketHandler = (id, price, cb) => {
    buyTicket(id, price, (err, success) => {
      if (success) {
        getProfile();
      }
      cb(err, success);
    });
  };

  const getLiveStreamComment = id => {
    setCommentListener(id, querySnapshot => {
      const comments = [];
      querySnapshot.forEach(doc => {
        comments.push(doc.data());
      });
      comments.reverse();
      SetLivestreamModel({
        ...LivestreamModel,
        allComments: comments,
      });
    });
  };

  const setStreamersHandler = (allStreams = []) => {
    const keepTract = [];
    const uniqueList = allStreams.filter(x => {
      if (keepTract.indexOf(x.owner) < 0) {
        keepTract.push(x.owner);
        return true;
      }
      return false;
    });
    setStreamers(uniqueList.reverse());
  };
  let makeItRainInfo = [];
  let timeinterval;
  let sprayMoney = false;
  const subscribeToMakeItRainComments = targetId => {
    setCollectionListener('makeItRain', 'eventId', targetId, snapShot => {
      let makeItRainData = [];
      snapShot.forEach(doc => {
        makeItRainData.push(doc.data());
      });
      if (makeItRainData.length < 1) return;
      makeItRainData = makeItRainData.sort((a, b) => a.created - b.created);
      setStreamersHandler(makeItRainData);
      const newComment = makeItRainData[makeItRainData.length - 1];
      if (sprayMoney === true || sprayMoney === undefined) {
        if (!newComment.id) return;
        return makeItRainData.length && makeItRainInfo.push(newComment);
      }

      if (!newComment.id) return;
      clearInterval(timeinterval);
      handleMakeItRainQueue(makeItRainData);
    });
  };

  const handleMakeItRainQueue = (makeItRainData = []) => {
    let unplayed = makeItRainData.filter(d => {
      return d.endTime > Date.now();
    });
    makeItRainInfo.length < 1 && setSprayMoneyStatus(false);
    makeItRainInfo = unplayed;
    if (unplayed.length < 1) return;
    const { pinnedComment, firstName, lastName, avatar, endTime } = unplayed[0];
    const duration = endTime - Date.now();
    if (duration < 0) return;
    setSprayMoneyStatus(true);
    sprayMoney = true;
    createMagicComment(`${firstName} ${lastName}`, pinnedComment, avatar, duration);
    timeinterval = setTimeout(() => {
      // setSprayMoneyStatus(false);
      sprayMoney = false;
      makeItRainInfo.shift();
      handleMakeItRainQueue(makeItRainInfo);
    }, duration);
  };

  let showLike = false;
  const subscribeToLivestreamLikes = targetId => {
    setCollectionListener('likes', 'targetID', targetId, _ => {
      if (!showLike) return (showLike = true);
      handleLikeAnimation();
    });
  };

  const [defaultLists, setDefaultList] = useState([]);

  const getLoveContainer = useCallback(() => {
    for (let i = 0; i < 200; i++) {
      defaultLists.push(true);
      setDefaultList(defaultLists);
    }
  }, [defaultLists]);

  const handleLikeAnimation = () => {
    loveList.push(true);
    setShowLove(true);
    setLoveList(loveList);
    console.log(currentCount);
    setCurrentCount(loveList.length);
    if (loveList.length > 200) {
      setShowLove(false);
      setLoveList([]);
    }
  };

  const subscribeToLivestreamStats = targetId => {
    setCollectionListener('live_stats', 'id', targetId, snapShot => {
      const x = [];
      snapShot.forEach(doc => {
        x.push(doc.data());
      });
      setLivestreamStats({
        likes: utils.commarize(x[0]?.likes || 0),
        shares: utils.commarize(x[0]?.shares || 0),
        views: utils.commarize(x[0]?.views || 0),
      });
    });
  };

  const subscribeToLivestreamData = id => {};

  return (
    <LivestreamContext.Provider
      value={{
        sidePanelWidth,
        setsidePanelWidth,
        sidePanelContent,
        LivestreamModel,
        magicComment,
        defaultLists,
        loveList,
        showLove,
        livestreamStats,
        streamers,
        currentCount,
        updateSidePanelContent,
        setLivestreamEvent,
        setTicketPrice,
        hasTicketHandler,
        addComment,
        buyTicketHandler,
        getTickets,
        setComment,
        createComment,
        hideMagicComment,
        getLiveStreamComment,
        subscribeToMakeItRainComments,
        subscribeToLivestreamLikes,
        getLoveContainer,
        handleLikeAnimation,
        subscribeToLivestreamStats,
        resetLoveList,
        hasTicketChecker,
        subscribeToLivestreamData,
        hideSideBar,
      }}
    >
      {children}
    </LivestreamContext.Provider>
  );
};

LivestreamProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
