import * as t from '../types';

import api from 'services/api';
import { firebase } from 'services/firebase';
import { getAlbumTracksAction } from './album.actions';
import { getMoodTracksAction } from './mood.actions';
import { getPlaylistTracksAction } from './playlist.actions';
import { playerBC } from 'contexts/BroadcastContext';
import { shuffleArray } from 'lib/utils';
import { statiscticsAction } from './statistics.actions';
import { trackToPlayer } from 'lib/player';

export const playAction = () => async dispatch => {
  const sessionId = sessionStorage.getItem('tabId');
  const action = { type: t.PLAY };
  dispatch(action);
  localStorage.setItem('currentTab', sessionId);
  playerBC.postMessage(action);
};

export const pauseAction = () => async dispatch => {
  const sessionId = sessionStorage.getItem('tabId');
  const action = { type: t.PAUSE };
  dispatch(action);
  localStorage.setItem('currentTab', sessionId);
  playerBC.postMessage(action);
};

export const toggleMuteAction = () => async dispatch => {
  const action = { type: t.TOGGLE_MUTE };
  dispatch(action);
  playerBC.postMessage(action);
};

export const volumeChangeAction = volume => async dispatch => {
  const action = { type: t.VOLUME_CHANGE, payload: volume };
  dispatch(action);
  playerBC.postMessage(action);
};

export const togglePlayAction = () => async dispatch => {
  const action = { type: t.TOGGLE_PLAY };
  dispatch(action);
  playerBC.postMessage(action);
};

export const playTrackAction = currentItem => async dispatch => {
  await dispatch({ type: t.RESET_ITEM });

  const action = {
    type: t.PLAY_TRACK,
    payload: trackToPlayer({ ...currentItem, _source: 'album', sourceID: currentItem.album }),
  };
  dispatch(action);
  playerBC.postMessage(action);
};

export const playTracksAction = (tracks, currentIndex = 0, _source = '', sourceID = '') => async dispatch => {
  await dispatch({ type: t.RESET_ITEM });
  const newTracks = tracks.map((track, i) =>
    trackToPlayer({ ...track, _source, sourceID: sourceID || track.sourceID || track.album || '' }, i),
  );

  const action = {
    type: t.PLAY_TRACKS,
    payload: {
      currentIndex,
      currentItem: newTracks[currentIndex],
      magic: false,
      magicQueue: [],
      playing: true,
      queue: newTracks,
      shuffle: false,
    },
  };
  dispatch(action);
  playerBC.postMessage(action);
};

const onShuffle = (tracks, currentIndex) => {
  const newTracks = tracks.map((track, i) => trackToPlayer(track, i));
  const tracksToShuffle = [...newTracks];
  const newItem = tracksToShuffle.splice(currentIndex, 1)[0];
  let shuffledTracks = [newItem, ...shuffleArray(tracksToShuffle)];
  shuffledTracks = shuffledTracks.map((track, i) => trackToPlayer(track, i));

  return {
    currentIndex: 0,
    currentItem: newItem,
    magic: false,
    magicQueue: [],
    queue: newTracks,
    shuffleQueue: shuffledTracks,
  };
};

export const shufflePlayTracksAction = (tracks, _source = '', sourceID = '') => async dispatch => {
  const rand = Math.round(Math.random() * tracks.length - 1);
  const newTracks = tracks.map(track => ({
    ...track,
    _source,
    sourceID: sourceID || track.sourceID || track.album || '',
  }));

  const action = {
    type: t.SHUFFLE_TRACKS,
    payload: {
      ...onShuffle(newTracks, rand),
      playing: true,
      shuffle: true,
    },
  };
  dispatch(action);
  playerBC.postMessage(action);
};

export const toggleShuffleAction = () => async (dispatch, getState) => {
  const {
    player: { shuffle, currentItem, queue, currentIndex },
  } = getState();

  if (!shuffle) {
    dispatch({
      type: t.TOGGLE_SHUFFLE,
      payload: {
        ...onShuffle(queue, currentIndex),
        playing: true,
        shuffle: true,
      },
    });
  } else {
    const index = queue.findIndex(x => x.id === currentItem.id);

    const action = {
      type: t.TOGGLE_SHUFFLE,
      payload: {
        currentIndex: index,
        currentItem: queue[index],
        magic: false,
        magicQueue: [],
        playing: true,
        queue,
        shuffle: false,
      },
    };
    dispatch(action);
    playerBC.postMessage(action);
  }
};

export const toggleRepeatAction = () => async (dispatch, getState) => {
  const {
    player: { repeat },
  } = getState();
  const newRepeat = repeat > 1 ? 0 : repeat + 1;

  const action = { type: t.TOGGLE_REPEAT, payload: newRepeat };
  dispatch(action);
  playerBC.postMessage(action);
};

export const previousTrackAction = () => async (dispatch, getState) => {
  const {
    player: { queue: normalQueue, shuffleQueue, shuffle, currentIndex },
  } = getState();

  const queue = shuffle ? shuffleQueue : normalQueue;
  await dispatch({ type: t.RESET_ITEM });
  const newIndex = currentIndex - 1;

  const action = {
    type: t.NEXT_TRACK,
    payload: {
      currentItem: queue[newIndex],
      currentIndex: newIndex,
      playing: true,
    },
  };
  dispatch(action);
  playerBC.postMessage(action);
};

export const nextTrackAction = () => async (dispatch, getState) => {
  const {
    player: { queue: normalQueue, shuffleQueue, shuffle, currentIndex, magic, magicQueue },
  } = getState();
  // eslint-disable-next-line no-nested-ternary
  const queue = magic ? magicQueue : shuffle ? shuffleQueue : normalQueue;
  await dispatch({ type: t.RESET_ITEM });
  const newIndex = currentIndex + 1;

  const action = {
    type: t.NEXT_TRACK,
    payload: {
      currentItem: queue[newIndex],
      currentIndex: newIndex,
      playing: true,
    },
  };
  dispatch(action);
  playerBC.postMessage(action);
};

const repeatTracksAction = () => async (dispatch, getState) => {
  const {
    player: { queue: normalQueue, shuffleQueue, shuffle },
  } = getState();
  await dispatch({ type: t.RESET_ITEM });
  const queue = shuffle ? shuffleQueue : normalQueue;

  const action = {
    type: t.REPEAT_TRACKS,
    payload: { currentIndex: 0, currentItem: queue[0], playing: true },
  };
  dispatch(action);
  playerBC.postMessage(action);
};

const repeatTrackAction = () => async (dispatch, getState) => {
  const {
    player: { currentItem },
  } = getState();
  await dispatch({ type: t.RESET_ITEM });

  const action = { type: t.REPEAT_TRACK, payload: currentItem };
  dispatch(action);
  playerBC.postMessage(action);
};

const queueEndedAction = () => async (dispatch, getState) => {
  const {
    player: { queue: normalQueue, shuffleQueue, shuffle, magic, magicQueue },
  } = getState();
  await dispatch({ type: t.RESET_ITEM });
  // eslint-disable-next-line no-nested-ternary
  const queue = magic ? magicQueue : shuffle ? shuffleQueue : normalQueue;

  const action = {
    type: t.QUEUE_ENDED,
    payload: {
      currentIndex: 0,
      currentItem: queue[0],
      playing: false,
    },
  };
  dispatch(action);
  playerBC.postMessage(action);
};

export const stateChangeAction = state => async (dispatch, getState) => {
  const {
    player: { currentItem },
  } = getState();
  const { _source, sourceID, id, name, album } = currentItem;
  const data = {
    name: `${state}_track`,
    target: 'track',
    targetID: id,
    description: `user ${state !== 'pause' ? `${state}ed` : `${state}d`} ${name}`,
    source: `${_source}_${state}`,
    sourceID: sourceID || album,
  };

  dispatch({ type: t.PLAYER_STATE_CHANGE, payload: state });
  dispatch(statiscticsAction(data));
  firebase.analytics().logEvent(`${state}_track`, data);
};

export const onTrackEndedAction = () => async (dispatch, getState) => {
  const {
    player: { repeat, currentItem, queue: normalQueue, shuffleQueue, shuffle, magic, magicQueue },
  } = getState();
  // eslint-disable-next-line no-nested-ternary
  const queue = magic ? magicQueue : shuffle ? shuffleQueue : normalQueue;
  if (repeat === 1 && currentItem.index === queue.length - 1) {
    return dispatch(repeatTracksAction());
  }
  if (repeat === 2) {
    return dispatch(repeatTrackAction());
  }
  if (currentItem.index === queue.length - 1) {
    dispatch(stateChangeAction('stop'));
    return dispatch(queueEndedAction());
  }
  return dispatch(nextTrackAction());
};

export const updateProgressAction = data => async dispatch => {
  const action = { type: t.UPDATE_PROGRESS, payload: data };
  dispatch(action);
  playerBC.postMessage(action);
};

export const validPlayAction = () => async (dispatch, getState) => {
  const {
    player: { currentItem },
  } = getState();
  dispatch({ type: t.VALID_PLAY });
  const { id, name, _source = 'album', sourceID, album } = currentItem;
  const data = {
    name: `valid_play_track`,
    target: 'track',
    targetID: id,
    description: `user played ${name} for at least 30secs`,
    source: `${_source}_play`,
    sourceID: sourceID || album,
  };
  dispatch(
    statiscticsAction(data, (_, error) => {
      if (error) {
        dispatch({ type: t.VALID_PLAY_FAILURE });
      }
    }),
  );
};

export const orderQueueAction = queue => async (dispatch, getState) => {
  const {
    player: { currentItem, shuffle },
  } = getState();

  const newQueue = queue.map((track, i) => trackToPlayer(track, i));
  const currentIndex = newQueue.findIndex(track => track.id === currentItem.id);
  const payload = {
    currentIndex,
  };
  if (shuffle) {
    payload.shuffleQueue = newQueue;
  } else {
    payload.queue = newQueue;
  }

  const action = { type: t.ORDER_QUEUE, payload };
  dispatch(action);
  playerBC.postMessage(action);
};

export const clearQueueAction = () => dispatch => {
  const action = { type: t.CLEAR_QUEUE };
  dispatch(action);
  playerBC.postMessage(action);
};

const formatMagicData = responseData => dispatch => {
  const data = {
    magic: true,
    playing: true,
  };
  if (responseData.length) {
    dispatch({ type: t.RESET_ITEM });
    data.magicQueue = responseData.map((track, i) => trackToPlayer(track, i));
    // eslint-disable-next-line
    data.currentItem = data.magicQueue[0];
    data.queue = [];
    data.shuffleQueue = [];
  } else {
    data.currentItem = {};
    data.magic = false;
    data.magicQueue = [];
    data.playing = false;
    data.queue = [];
    data.shuffleQueue = [];
  }

  return data;
};

export const userMagicPlaylistAction = (callback = () => {}) => async dispatch => {
  try {
    dispatch({ type: t.MAGIC_PLAYLIST_REQUEST, payload: 'me' });
    const resp = await api.magicForUser({ size: 100 });
    if (resp.status === 200 || resp.status === 201) {
      const data = dispatch(formatMagicData(resp.data.tracks));

      const action = { type: t.MAGIC_PLAYLIST_SUCCESS, payload: { id: 'me', data } };
      dispatch(action);
      playerBC.postMessage(action);
      callback(true, null);
    } else {
      throw resp.data;
    }
  } catch (e) {
    dispatch({ type: t.MAGIC_PLAYLIST_FAILURE, payload: 'me' });
    callback(null, e);
  }
};

export const artistMagicPlaylistAction = (id, callback = () => {}) => async dispatch => {
  try {
    dispatch({ type: t.MAGIC_PLAYLIST_REQUEST, payload: id });
    const resp = await api.magicForArtist(id, { size: 100 });
    if (resp.status === 200 || resp.status === 201) {
      const data = dispatch(formatMagicData(resp.data.tracks));

      const action = { type: t.MAGIC_PLAYLIST_SUCCESS, payload: { id, data } };
      dispatch(action);
      playerBC.postMessage(action);
      callback(true, null);
    } else {
      throw resp.data;
    }
  } catch (e) {
    dispatch({ type: t.MAGIC_PLAYLIST_FAILURE, payload: id });
    callback(null, e);
  }
};

export const playNextAction = (tracks, source = '', sourceID = '') => async (dispatch, getState) => {
  const {
    player: { shuffled, queue: normalQueue, shuffleQueue, shuffle, magic, magicQueue, currentIndex },
  } = getState();
  // eslint-disable-next-line no-nested-ternary
  const queue = magic ? magicQueue : shuffle ? shuffleQueue : normalQueue;

  let newQueue = [...queue];
  newQueue.splice(currentIndex + 1, 0, ...tracks);
  newQueue = newQueue.map((track, index) =>
    trackToPlayer(
      { ...track, _source: source || track.source || '', sourceID: sourceID || track.sourceID || track.album || '' },
      index,
    ),
  );

  const payload = {};

  if (queue.length === 0) {
    payload.currentItem = newQueue[0];
    payload.currentIndex = 0;
  }

  if (magic) {
    payload.magicQueue = newQueue;
  } else if (shuffled) {
    payload.shuffleQueue = newQueue;
  } else {
    payload.queue = newQueue;
  }

  const action = { type: t.PLAY_NEXT, payload };
  dispatch(action);
  playerBC.postMessage(action);
};

export const addToQueueAction = (tracks, source = '', sourceID = '') => async (dispatch, getState) => {
  const {
    player: { shuffled, queue: normalQueue, shuffleQueue, shuffle, magic, magicQueue },
  } = getState();
  // eslint-disable-next-line no-nested-ternary
  const queue = magic ? magicQueue : shuffle ? shuffleQueue : normalQueue;

  let newQueue = [...queue];
  newQueue.push(...tracks);
  newQueue = newQueue.map((track, index) =>
    trackToPlayer(
      { ...track, _source: source || track.source || '', sourceID: sourceID || track.sourceID || track.album || '' },
      index,
    ),
  );

  const payload = {};

  if (queue.length === 0) {
    payload.currentItem = newQueue[0];
    payload.currentIndex = 0;
  }

  if (magic) {
    payload.magicQueue = newQueue;
  } else if (shuffled) {
    payload.shuffleQueue = newQueue;
  } else {
    payload.queue = newQueue;
  }

  const action = { type: t.ADD_TO_QUEUE, payload };
  dispatch(action);
  playerBC.postMessage(action);
};

export const removeFromQueueAction = item => async (dispatch, getState) => {
  const {
    player: { shuffled, queue: normalQueue, shuffleQueue, shuffle, magic, magicQueue, currentIndex },
  } = getState();
  // eslint-disable-next-line no-nested-ternary
  const queue = magic ? magicQueue : shuffle ? shuffleQueue : normalQueue;

  let newQueue = [...queue];
  const ix = newQueue.findIndex(x => x.id === item.id);
  newQueue.splice(ix, 1);
  newQueue = newQueue.map((track, index) => trackToPlayer(track, index));

  const payload = {};

  if (queue.length === 0) {
    payload.currentItem = newQueue[0];
    payload.currentIndex = 0;
  } else if (item.index < currentIndex) {
    payload.currentItem = newQueue[currentIndex - 1];
    payload.currentIndex = currentIndex - 1;
  } else if (item.index === currentIndex) {
    payload.currentItem = newQueue[currentIndex];
    payload.currentIndex = currentIndex;
  }

  if (magic) {
    payload.magicQueue = newQueue;
  } else if (shuffled) {
    payload.shuffleQueue = newQueue;
  } else {
    payload.queue = newQueue;
  }

  const action = { type: t.REMOVE_FROM_QUEUE, payload };
  dispatch(action);
  playerBC.postMessage(action);
};

export const getThenAddAction = (item, type, source = '', sourceID = '', mode) => async (dispatch, getState) => {
  try {
    dispatch({ type: t.GET_THEN_ADD_REQUEST });
    const state = getState();
    let tracks = [];

    if (type === '') return;

    const actions = {
      album: getAlbumTracksAction,
      mood: getMoodTracksAction,
      playlist: getPlaylistTracksAction,
    };

    const add = {
      next: playNextAction,
      last: addToQueueAction,
    };

    tracks = state[type].tracks[item.id];

    if (!tracks) {
      dispatch(
        actions[type](item.id, (data, error) => {
          if (data?.length) {
            dispatch(add[mode](data, source, sourceID));
          } else {
            throw error;
          }
        }),
      );
    } else {
      dispatch(add[mode](tracks));
    }

    const action = { type: t.GET_THEN_ADD_SUCCESS };

    dispatch(action);
    playerBC.postMessage(action);
  } catch (e) {
    dispatch({ type: t.GET_THEN_ADD_FAILURE });
  }
};
