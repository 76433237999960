import React from 'react';
import PropTypes from 'prop-types';

function SvgOrder({ fill, ...props }) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 7.325 14.94" {...props}>
      <defs>
        <style>{`.order_svg__cls-1{fill:${fill}}`}</style>
      </defs>
      <g id="order_svg__arrow-left-and-right" transform="rotate(-90 -57.33 72.27)">
        <g id="order_svg__Group_62" data-name="Group 62" transform="translate(0 129.601)">
          <g id="order_svg__Group_61" data-name="Group 61">
            <path
              id="order_svg__Path_1132"
              d="M9.8 224.177H1.592l1.071-1.071a.467.467 0 00-.66-.66l-1.867 1.867a.471.471 0 000 .663l1.8 1.8a.467.467 0 00.66-.66l-1-1H9.8a.467.467 0 000-.934z"
              className="order_svg__cls-1"
              data-name="Path 1132"
              transform="translate(0 -219.586)"
            />
            <path
              id="order_svg__Path_1133"
              d="M169.048 131.535l-1.8-1.8a.467.467 0 00-.66.66l1 1h-8.208a.467.467 0 000 .934h8.212l-1.07 1.071a.467.467 0 10.66.66l1.866-1.867a.471.471 0 000-.663z"
              className="order_svg__cls-1"
              data-name="Path 1133"
              transform="translate(-154.244 -129.601)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

SvgOrder.propTypes = {
  fill: PropTypes.string,
};

SvgOrder.defaultProps = {
  fill: '#fff',
};

export default SvgOrder;
