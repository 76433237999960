import React from 'react';
import PropTypes from 'prop-types';

function SvgMoneyNote({ fill, ...props }) {
  return (
    <svg width="51" height="28" viewBox="0 0 51 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M50.4247 1.67541C48.4668 1.16262 46.5322 0.836294 44.5742 0.673132C44.5742 0.556588 44.5742 0.463353 44.5742 0.370117C46.4855 0.556588 48.3736 0.882912 50.2849 1.3724H50.3082H50.3315C50.3315 1.3724 50.3315 1.3724 50.3548 1.3724H50.3781C50.4014 1.3724 50.4014 1.3724 50.4247 1.3724C50.4247 1.48894 50.4247 1.58218 50.4247 1.67541Z"
        fill="#F2994A"
      />
      <path
        d="M50.7052 26.1022C42.4772 23.9111 34.2492 25.0067 26.0211 26.1022C17.7931 27.1977 9.5651 28.2932 1.33708 26.1022C1.33708 26.0089 0.987444 25.7059 0.987444 25.6127C0.964135 17.5478 1.31377 9.73937 1.31377 1.65121C9.54179 3.86555 17.7465 2.72341 25.9745 1.58128C34.1792 0.439147 42.3839 -0.702986 50.612 1.48804C50.612 9.64614 50.7052 17.9674 50.7052 26.1022Z"
        fill="url(#paint0_linear)"
      />
      <path
        opacity="0.4"
        d="M50.4291 25.87C50.4291 17.7119 50.4291 9.57711 50.4291 1.41902C48.4712 0.906221 46.5365 0.556588 44.5786 0.370117C44.4621 3.56343 45.0215 19.9962 43.25 24.7045C45.6508 24.8677 48.0283 25.2173 50.4291 25.87Z"
        fill="url(#paint1_linear)"
      />
      <path
        d="M4.78711 9.71676C4.78711 12.8635 4.78711 16.0101 4.78711 19.1335C6.8616 19.4599 8.53983 21.208 8.53983 23.2825C19.9845 23.9818 31.4524 20.229 42.8971 20.905C42.8971 18.8305 44.5753 17.2222 46.6498 17.5485C46.6498 14.4018 46.6498 11.2551 46.6498 8.13176C44.5753 7.80544 42.8971 6.05727 42.8971 3.98279C31.4524 3.28352 19.9845 7.03624 8.53983 6.36029C8.53983 8.41147 6.8616 10.0198 4.78711 9.71676Z"
        fill="url(#paint2_linear)"
      />
      <path
        d="M31.922 12.8621C31.922 16.2652 29.1483 19.3419 25.7452 19.8081C22.3421 20.2743 19.5684 17.8269 19.5684 14.4005C19.5684 10.9974 22.3421 7.92061 25.7452 7.45443C29.1483 6.98826 31.922 9.43568 31.922 12.8621Z"
        fill="url(#paint3_linear)"
      />
      <path
        d="M15.7915 14.7522C15.7915 16.3838 14.4629 17.829 12.8313 17.8523C11.1997 17.8989 9.87109 16.5237 9.87109 14.8688C9.87109 13.2371 11.1997 11.9552 12.8313 11.9085C14.4629 11.8852 15.7915 13.0973 15.7915 14.7522Z"
        fill="url(#paint4_linear)"
      />
      <path
        d="M35.6738 12.5132C35.6738 14.1681 37.0024 15.3802 38.6341 15.3336C40.2657 15.2869 41.5943 14.005 41.5943 12.3733C41.5943 10.7417 40.2657 9.34319 38.6341 9.38981C37.0024 9.43642 35.6738 10.8816 35.6738 12.5132Z"
        fill="#FDB913"
      />
      <path
        d="M15.3215 2.88814C15.3215 2.77159 15.3448 2.65505 15.3448 2.53851C18.7013 2.32873 22.0811 1.88586 25.4376 1.44299C25.5308 1.41968 25.624 1.41968 25.7173 1.39637C25.7406 1.39637 25.7872 1.39637 25.8105 1.37306C25.8338 1.37306 25.8338 1.37306 25.8571 1.37306C25.997 1.34975 26.1135 1.34975 26.2534 1.32645C26.3233 1.32645 26.3699 1.30314 26.4398 1.30314C26.4631 1.30314 26.4865 1.30314 26.5098 1.30314L26.5331 1.30314C26.5564 1.30314 26.5797 1.30314 26.603 1.30314C26.6729 1.30314 26.7195 1.27983 26.7895 1.27983C26.7895 1.27983 26.8128 1.27983 26.8361 1.27983C26.8594 1.27983 26.906 1.27983 26.9293 1.25652C31.2181 0.673798 35.5536 0.137694 39.8657 0.137694C39.9123 0.137694 39.9357 0.137694 39.9823 0.137694C40.0289 0.137694 40.0755 0.137694 40.1221 0.137694C43.5019 0.137694 46.905 0.487327 50.2848 1.37306C48.3735 0.883577 46.4854 0.557253 44.5741 0.370782C44.5741 0.464018 44.5741 0.557253 44.5741 0.673797C43.129 0.533944 41.6838 0.487327 40.2387 0.487327C35.4837 0.487327 30.7287 1.11667 25.9737 1.746C22.4307 2.21218 18.8878 2.67836 15.3215 2.88814ZM2.82798 1.83924C2.80467 1.83924 2.80467 1.83924 2.78137 1.83924C2.80467 1.83924 2.82798 1.83924 2.82798 1.83924ZM2.78137 1.83924C2.19865 1.7227 1.61592 1.58284 1.0332 1.41968C1.05651 1.41968 1.05651 1.41968 1.07982 1.44299C1.66254 1.58284 2.22195 1.69939 2.78137 1.83924Z"
        fill="#F2994A"
      />
      <path
        d="M1.29351 25.9164C1.20027 25.8931 1.13034 25.8698 1.03711 25.8465C1.03711 17.6884 1.03711 9.5536 1.03711 1.39551C1.61983 1.55867 2.20255 1.69852 2.78527 1.81507C2.80858 1.81507 2.80858 1.81507 2.83189 1.81507C5.72219 2.4211 8.58917 2.65418 11.4795 2.65418C12.7615 2.65418 14.0667 2.60757 15.3487 2.53764C15.3487 2.65418 15.3254 2.77073 15.3254 2.88727C14.1367 2.9572 12.9246 3.00382 11.7359 3.00382C8.26285 3.00382 4.76652 2.65418 1.29351 1.72183C1.29351 9.81 1.29351 17.8515 1.29351 25.9164Z"
        fill="#F2994A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.9775 11.2043L27.818 13.6577C27.7725 14.1261 27.5219 14.5945 27.1574 14.9514C26.793 15.3082 26.3146 15.5313 25.8363 15.5759C25.3579 15.6205 24.8795 15.4866 24.5378 15.1967C24.1962 14.9067 23.9911 14.4607 23.9684 13.9923L24.1278 11.4273L22.5105 11.5165L22.4649 12.3418L20.916 12.431L20.8704 13.0778L22.4194 12.9886L22.3966 13.4793L20.8249 13.5462L20.7793 14.193L22.3283 14.0815C22.3283 14.9737 22.67 15.7989 23.285 16.3788C23.9 16.9364 24.7656 17.2264 25.6768 17.1371C26.5652 17.0702 27.4536 16.6465 28.1369 15.9996C28.8203 15.3528 29.2303 14.483 29.3442 13.6131L30.6882 13.5239L30.7338 12.8994L29.3898 12.9886L29.4126 12.5202L30.7565 12.431L30.8021 11.8065L29.4581 11.8957L29.5037 11.0928L27.9775 11.2043Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="25.8351"
          y1="27.3838"
          x2="25.8351"
          y2="0.240183"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDB913" />
          <stop offset="1" stopColor="#F2994A" />
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="45.3926"
          y1="13.159"
          x2="50.2874"
          y2="13.0424"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF7900" stopOpacity="0" />
          <stop offset="0.6979" stopColor="#FF7900" />
        </linearGradient>
        <linearGradient
          id="paint2_linear"
          x1="25.7326"
          y1="23.3725"
          x2="25.7326"
          y2="3.89256"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FACB5B" />
          <stop offset="1" stopColor="#F5DEA4" />
        </linearGradient>
        <linearGradient
          id="paint3_linear"
          x1="50.8068"
          y1="26.0427"
          x2="1.06574"
          y2="1.41945"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDB913" />
          <stop offset="1" stopColor="#F2994A" />
        </linearGradient>
        <linearGradient
          id="paint4_linear"
          x1="48.2473"
          y1="32.3358"
          x2="-1.69169"
          y2="7.71236"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDB913" />
          <stop offset="1" stopColor="#F2994A" />
        </linearGradient>
      </defs>
    </svg>
  );
}

SvgMoneyNote.propTypes = {
  fill: PropTypes.string,
};

SvgMoneyNote.defaultProps = {
  fill: '#fefefe',
};

export default SvgMoneyNote;
