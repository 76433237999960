import styled from '@emotion/styled/macro';
import { css } from '@emotion/core';

export const Textarea = styled.textarea`
  -moz-appearance: none;
  -webkit-appearance: none;
  appearence: none;
  background-color: #272729;
  border-radius: 8px;
  border: none;
  color: ${props => props.theme.colors.inputText};
  display: block;
  font-size: 1.6rem;
  margin: 0;
  outline: none;
  padding: 0.5rem 2rem;
  resize: vertical;
  width: 100%;

  &:focus {
    background-color: #3b3b40;
  }

  &:disabled {
    background-color: rgba(128, 128, 128, 0.12);
  }

  &::placeholder {
    color: ${props => props.theme.colors['brownish-grey-two']};
    font-size: 1.2rem;
  }

  ${props => {
    if (props.addonPosition) {
      return css`
        padding-${props.addonPosition}: 0.5rem;
      `;
    }
  }}
`;

Textarea.defaultProps = {
  backgroundColor: 'input',
  textColor: 'inputText',
};
