import React from 'react';
import PropTypes from 'prop-types';

function SvgLivestream({ fill, ...props }) {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M11.9998 14.6314C13.2151 14.6314 14.2003 13.6462 14.2003 12.4309C14.2003 11.2157 13.2151 10.2305 11.9998 10.2305C10.7845 10.2305 9.79932 11.2157 9.79932 12.4309C9.79932 13.6462 10.7845 14.6314 11.9998 14.6314Z"
        fill={fill}
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.665 7.76601C17.2788 8.3791 17.7657 9.10716 18.0979 9.90856C18.4301 10.71 18.6011 11.569 18.6011 12.4365C18.6011 13.304 18.4301 14.1631 18.0979 14.9645C17.7657 15.7659 17.2788 16.4939 16.665 17.107M7.335 17.096C6.72123 16.4829 6.23432 15.7548 5.9021 14.9535C5.56989 14.1521 5.3989 13.293 5.3989 12.4255C5.3989 11.558 5.56989 10.699 5.9021 9.89756C6.23432 9.09616 6.72123 8.3681 7.335 7.75501M19.7787 4.65234C21.8413 6.71559 23 9.51358 23 12.431C23 15.3484 21.8413 18.1464 19.7787 20.2097M4.22134 20.2097C2.15872 18.1464 1 15.3484 1 12.431C1 9.51358 2.15872 6.71559 4.22134 4.65234"
        stroke={fill}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

SvgLivestream.propTypes = {
  fill: PropTypes.string,
};

SvgLivestream.defaultProps = {
  fill: '#fcb316',
};

export default SvgLivestream;
