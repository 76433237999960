import React from 'react';
import styled from '@emotion/styled/macro';
import { WarningIcon } from 'components/icons';
import { Button } from 'components/atoms';
import { Typography } from 'components/atoms';
import { useHistory, useLocation } from 'react-router-dom';
import { useMedia } from 'react-use';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  padding: 0 6rem;
`;

export const RedirectButton = styled(Button)`
  color: white !important;
  background: linear-gradient(270.02deg, #fdb913 0.02%, #f2994a 99.98%);
  @media (max-width: 440px) {
    width: 100%;
  }
`;

const PageError = ({ error }) => {
  const history = useHistory();
  const location = useLocation();
  const currentPath = location.pathname;
  const isWide = useMedia('(max-width: 400px)');

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  return (
    <div>
      {currentPath === '/' ? (
        <Container>
          <WarningIcon height="13rem" width="13rem" style={{ marginBottom: '2rem' }} />
          <Typography.Heading style={{ marginBottom: '2rem', fontWeight: 'bold' }} color="switchingText">
            Seems like you are new here
          </Typography.Heading>
          <Typography.Paragraph style={{ marginBottom: '2rem' }} color="switchingText">
            We would like to know what you like
          </Typography.Paragraph>
          <RedirectButton effect size={isWide ? 'small' : 'normal'} onClick={() => history.replace('/explore')}>
            Listen and Explore
          </RedirectButton>
        </Container>
      ) : (
        <Container>
          <WarningIcon height="13rem" width="13rem" style={{ marginBottom: '2rem' }} />
          <Typography.Heading style={{ marginBottom: '2rem', fontWeight: 'bold' }}>Oops!</Typography.Heading>
          {error ? (
            <Typography.Paragraph style={{ marginBottom: '2rem' }} color="switchingText">
              {capitalizeFirstLetter(error)}
            </Typography.Paragraph>
          ) : (
            <Typography.Paragraph style={{ marginBottom: '2rem' }} color="switchingText">
              It appears that this content is empty. Kindly go back and click on a different one.
            </Typography.Paragraph>
          )}

          <RedirectButton
            effect
            size={isWide ? 'small' : 'large'}
            onClick={() => history.goBack()}
            aria-label="go-back"
          >
            Go Back
          </RedirectButton>
        </Container>
      )}
    </div>
  );
};

export default PageError;
