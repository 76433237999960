import React from 'react';
import styled from '@emotion/styled/macro';
import { css, keyframes } from '@emotion/core';
import PropTypes from 'prop-types';

const marqueeAnimation = keyframes`
  0%   { transform: translateX(100%); }
  100% { transform: translateX(-100%); }
`;

const HeadingTag = ({ type: Component, children, className, ...props }) => {
  return (
    <Component className={className} {...props}>
      {children}
    </Component>
  );
};

export const Heading = styled(HeadingTag)`
  letter-spacing: 0.6px;
  color: ${props => props.theme.colors[props.color]};
  &:visited {
    color: ${props => props.theme.colors[props.color]};
  }
  display: block;
  font-weight: ${props => props.theme.font.weight[props.weight]};
  margin: 0;
  position: relative;
  text-align: ${props => props.theme.font.align[props.align]};
  ${props => {
    return css`
      font-size: ${props.theme.heading.mobile[props.type]};
      line-height: ${props.theme.heading.mobile[props.type]};
      @media screen and (min-width: 577px) {
        font-size: ${props.theme.heading.desktop[props.type]};
        line-height: ${props.theme.heading.desktop[props.type]};
      }
    `;
  }}

  ${({ ellipsize }) =>
    ellipsize &&
    css`
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    `}
`;

export const Paragraph = styled.p`
  color: ${props => props.theme.colors[props.color]};
  &:visited {
    color: ${props => props.theme.colors[props.color]};
  }
  display: block;
  text-decoration: none;
  font-size: ${props => props.theme.font.size[props.size]};
  font-weight: ${props => props.theme.font.weight[props.weight]};
  line-height: 1.2;
  margin: 0;
  text-align: ${props => props.theme.font.align[props.align]};
  text-decoration: ${props => props.decoration};
  text-transform: ${props => props.textTransform};

  ${({ ellipsize }) =>
    ellipsize &&
    css`
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    `}

  ${({ marqueee }) =>
    marqueee &&
    css`
      animation: ${marqueeAnimation} 15s linear;
      animation-delay: 5s;
      position: relative;
      white-space: nowrap;
    `}
`;

export const Text = styled.span`
  color: ${props => props.theme.colors[props.color]};
  &:visited {
    color: ${props => props.theme.colors[props.color]};
  }
  display: inline;
  font-size: ${props => props.theme.font.size[props.size]};
  font-weight: ${props => props.theme.font.weight[props.weight]};
  line-height: 1.2;
  text-align: ${props => props.theme.font.align[props.align]};
  text-decoration: ${props => props.decoration};

  ${({ ellipsize }) =>
    ellipsize &&
    css`
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    `}
`;

HeadingTag.propTypes = {
  className: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4']).isRequired,
};

Heading.defaultProps = {
  align: 'initial',
  color: 'white',
  type: 'h1',
  weight: 'normal',
};

Paragraph.defaultProps = {
  align: 'initial',
  color: 'white',
  decoration: 'none',
  size: 'normal',
  weight: 'normal',
};

Text.defaultProps = {
  align: 'initial',
  color: 'inherit',
  decoration: 'none',
  size: 'inherit',
  weight: 'normal',
};

export default {
  Heading,
  Paragraph,
  Text,
};
