import api from 'services/api';

export const textAppAction = (phone, callback = () => {}) => async () => {
  try {
    const resp = await api.textApp({ phone });
    if (resp.status === 200 || resp.status === 201) {
      callback(resp, null);
    } else {
      throw resp.data;
    }
  } catch (e) {
    callback(null, e);
  }
};
