import React from 'react';

function SvgCloudUpload(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 25 19" {...props}>
      <g data-name="Group 3">
        <g data-name="Group 2">
          <path
            d="M20.487 9.456l-.161.016a8.017 8.017 0 00.161-1.592 7.88 7.88 0 00-15.759 0l.008.079a5.5 5.5 0 00.78 10.953h3.952a1.562 1.562 0 001.563-1.563v-4.74H7.687l4.92-6.3 4.92 6.3h-3.344v4.74a1.561 1.561 0 001.563 1.563h4.74a4.728 4.728 0 000-9.456z"
            data-name="Path 3"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgCloudUpload;
