import React from 'react';
import styled from '@emotion/styled/macro';
import { css } from '@emotion/core';
import { Box } from 'rebass';
import PropTypes from 'prop-types';
import { Typography } from 'components/atoms';

const Wrapper = styled.div`
  display: flex;
  margin-bottom: 2rem;
  position: relative;
  width: 100%;

  ${({ theme }) => theme.mq.md`
    flex-direction: column;
    margin-bottom: 0;
  `}
`;

const Text = styled(Typography.Text)`
  color: ${({ theme }) => theme.colors['greyish-brown-two']};
  display: none;
  font-size: ${({ theme }) => theme.font.size.small};
  text-align: center;
  @media screen and (min-width: 25em) {
    display: inline-block;
  }
  ${({ theme }) => theme.mq.md`
    text-align:left;
  `}
`;

const Circle = styled.div`
  align-items: center;
  border-radius: 50%;
  border: 0.2rem solid ${({ theme }) => theme.colors['greyish-brown-two']};
  cursor: pointer;
  display: flex;
  height: 2rem;
  justify-content: center;
  margin-bottom: 1rem;
  position: relative;
  transition: all 0.1s;
  width: 2rem;

  ${({ theme }) => theme.mq.md`
  margin-right: 1rem;
  margin-bottom: 0;
  `}

  &::after {
    background-color: ${({ theme }) => theme.colors['greyish-brown-two']};
    border-radius: 50%;
    content: '';
    display: block;
    height: 1.2rem;
    left: 50%;
    opacity: 1;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 1.2rem;
  }
`;

const Step = styled(Box)`
  align-items: center;
  flex-direction: column;
  flex: 1;
  position: relative;

  &:before{
    background-color: ${({ theme }) => theme.colors['greyish-brown-two']};
    content: '';
    height: 0.3rem;
    left: 0;
    position: absolute;
    top: 0.9rem;
    width: 100%;
  }


  ${({ active, theme }) =>
    active &&
    css`
      ${Circle} {
        border-color: ${theme.colors.primary};
        &::after {
          background-color: ${theme.colors.primary};
        }
      }
      &:before {
        background-color: ${theme.colors.primary} !important;
      }
    `}


  ${({ theme }) => theme.mq.md`
    flex-direction: row;
    flex:0;
    &:first-child{
      &:before{
        display: none;
      }
    }
    &:not(:first-child) {
      margin-top: 3rem;
      &::before {
        background-color: ${theme.colors['greyish-brown-two']};
        bottom: 100%;
        content: '';
        height: 3rem;
        left: 0.9rem;
        position: absolute;
        top: unset;
        width: 0.2rem;
      }
  `}
  }

  ${({ active, theme }) =>
    active &&
    css`
      ${theme.mq.md`
        &:not(:last-child) {
          &::before {
            background-color: ${theme.colors.primary};
          }
        }
        ${Text} {
          color: ${theme.colors.primary};
        }
    `}
    `}
`;

export const Timeline = ({ active, steps }) => {
  return (
    <Wrapper>
      {steps.map((x, i) => (
        <Step active={i <= active}>
          <Circle />
          <Text>{x}</Text>
        </Step>
      ))}
    </Wrapper>
  );
};

Timeline.propTypes = {
  active: PropTypes.number,
  steps: PropTypes.arrayOf(PropTypes.string),
};

Timeline.defaultProps = {
  active: 0,
  steps: ['Add Music', 'Basic Information', 'Metadata', 'Release', 'Finish'],
};
