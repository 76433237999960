import api from 'services/api';
import * as t from '../types';

export const statiscticsAction = (data, callback = () => {}) => async dispatch => {
  try {
    dispatch({ type: t.STATISTICS_REQUEST });
    const resp = await api.createStatistic(data);
    if (resp.status === 200 || resp.status === 201) {
      dispatch({ type: t.STATISTICS_SUCCESS, payload: resp.data });
      callback(resp.data, null);
    } else {
      throw resp.data;
    }
  } catch (e) {
    dispatch({ type: t.STATISTICS_FAILURE });
    callback(null, e);
  }
};
