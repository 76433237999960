import React from 'react';

const SVGComponent = ({ point }) => (
  <svg width={150} height={150} viewBox="0 0 134 75" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.5">
      <path
        d="M67.378 71.9902C81.3587 71.9902 93.8439 65.1926 102.739 57.7332C107.204 53.9883 110.842 50.0135 113.386 46.5078C114.656 44.7568 115.681 43.0854 116.399 41.5859C117.084 40.1544 117.613 38.6286 117.613 37.2451C117.613 35.8617 117.084 34.3359 116.399 32.9044C115.681 31.4049 114.656 29.7335 113.386 27.9825C110.842 24.4767 107.204 20.5019 102.739 16.7571C93.8439 9.29762 81.3587 2.5 67.378 2.5C53.3973 2.5 40.9121 9.29762 32.0175 16.7571C27.5522 20.5019 23.914 24.4767 21.3704 27.9825C20.0999 29.7335 19.0753 31.4049 18.3575 32.9044C17.6722 34.3359 17.1426 35.8617 17.1426 37.2451C17.1426 38.6286 17.6722 40.1544 18.3575 41.5859C19.0753 43.0854 20.0999 44.7568 21.3704 46.5078C23.914 50.0135 27.5522 53.9883 32.0175 57.7332C40.9121 65.1926 53.3973 71.9902 67.378 71.9902Z"
        fill="url(#paint0_linear)"
        stroke="url(#paint1_linear)"
        stroke-width="5"
      />
      <path d="M133.902 44.9492L126.173 36.8333L133.902 28.715H71.834V44.9492H133.902Z" fill="url(#paint2_linear)" />
      <path
        d="M0.549625 28.9492L8.27774 37.0651L0.549625 45.1835L62.6172 45.1835V28.9492L0.549625 28.9492Z"
        fill="url(#paint3_linear)"
      />
      <path
        d="M67.378 70.9902C81.0391 70.9902 93.3038 64.3405 102.096 56.967C106.503 53.2711 110.084 49.3557 112.576 45.9205C113.822 44.2041 114.812 42.5851 115.497 41.1541C116.162 39.7639 116.613 38.4019 116.613 37.2451C116.613 36.0884 116.162 34.7264 115.497 33.3362C114.812 31.9052 113.822 30.2862 112.576 28.5697C110.084 25.1346 106.503 21.2191 102.096 17.5233C93.3038 10.1498 81.0391 3.5 67.378 3.5C53.7169 3.5 41.4522 10.1498 32.6601 17.5233C28.2532 21.2191 24.6722 25.1346 22.1798 28.5697C20.9344 30.2862 19.9445 31.9052 19.2594 33.3362C18.5939 34.7264 18.1426 36.0884 18.1426 37.2451C18.1426 38.4019 18.5939 39.7639 19.2594 41.1541C19.9445 42.5851 20.9344 44.2041 22.1798 45.9205C24.6722 49.3557 28.2532 53.2711 32.6601 56.967C41.4522 64.3405 53.7169 70.9902 67.378 70.9902Z"
        fill="url(#paint4_linear)"
        stroke="black"
        stroke-width="3"
      />
      <ellipse cx="67.6946" cy="36.2473" rx="20.8645" ry="20.8645" fill="url(#paint5_linear)" />
      <text x="50%" y="50%" dominantBaseline="middle" textAnchor="middle" fontWeight={600} fill="#A6A6A6">
        {point}
      </text>
    </g>
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="9.4988"
        y1="37.2451"
        x2="115.113"
        y2="37.2451"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#898989" />
        <stop offset="1" stop-color="#242424" />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="68.0145"
        y1="-1.36472"
        x2="68.0145"
        y2="76.2849"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#2B2B2B" />
        <stop offset="1" stop-color="#707070" />
      </linearGradient>
      <linearGradient
        id="paint2_linear"
        x1="140.686"
        y1="36.7072"
        x2="114.014"
        y2="36.7205"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#515151" />
        <stop offset="0.71875" stop-color="#BBBBBB" />
      </linearGradient>
      <linearGradient
        id="paint3_linear"
        x1="-6.23525"
        y1="37.1912"
        x2="20.4373"
        y2="37.178"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#515151" />
        <stop offset="0.71875" stop-color="#BBBBBB" />
      </linearGradient>
      <linearGradient id="paint4_linear" x1="67.8326" y1="69.5565" x2="68.7419" y2="5" gradientUnits="userSpaceOnUse">
        <stop stop-color="#727272" />
        <stop offset="1" stop-color="#2A2A2A" />
      </linearGradient>
      <linearGradient
        id="paint5_linear"
        x1="66.7422"
        y1="-2.99906"
        x2="66.7422"
        y2="75.9235"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#727272" />
        <stop offset="1" stop-color="#2A2A2A" stop-opacity="0" />
      </linearGradient>
      <radialGradient
        id="paint6_radial"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(68.5 38.2108) rotate(180) scale(47 202.227)"
      >
        <stop stop-color="#BBBBBB" />
        <stop offset="1" stop-color="#A6A6A6" />
      </radialGradient>
    </defs>
  </svg>
);

export default SVGComponent;
