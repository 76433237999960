import api from 'services/api';
import * as t from '../types';

export const updateOnboardingData = (data, callback = () => {}) => async dispatch => {
  try {
    const resp = await api.onboarding(data);
    if (resp.status === 200 || resp.status === 201) {
      dispatch({ type: t.ADD_ONBAORDING_DATA_REQUEST, payload: { data: resp.data } });
      callback(resp.data, null);
    }
  } catch (e) {
    callback(false, null);
  }
};
