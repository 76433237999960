import React from 'react';
import PropTypes from 'prop-types';

function SvgUser(props) {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.9682 10.8619C29.1336 11.2051 29.1711 11.6171 29.0154 12.0322L22.7776 28.6665C22.3154 29.8989 20.6034 29.981 20.0254 28.7985L17.0958 22.8047C17.0756 22.7631 17.0541 22.7223 17.0317 22.6821L28.9682 10.8619ZM28.2633 10.1525L16.3769 21.9231C16.2786 21.8473 16.1744 21.7786 16.065 21.7177L10.7711 18.7729C9.65088 18.1498 9.77777 16.4997 10.98 16.0552L27.0908 10.0986C27.5076 9.94448 27.9204 9.98447 28.2633 10.1525ZM15.6557 22.6373C15.6306 22.6214 15.605 22.6061 15.5789 22.5916L10.285 19.6468C8.41799 18.6083 8.62948 15.8581 10.6333 15.1172L26.744 9.16065C28.7521 8.41821 30.7035 10.3787 29.9518 12.3833L23.7139 29.0177C22.9436 31.0717 20.0903 31.2085 19.127 29.2377L16.1974 23.2438C16.1813 23.2109 16.1641 23.1787 16.1458 23.1472L15.6482 22.6447L15.6557 22.6373Z"
        fill="white"
      />
    </svg>
  );
}

export default SvgUser;

export function SvgSend1({ fill, ...props }) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.9682 2.86191C21.1336 3.20513 21.1711 3.61709 21.0154 4.03219L14.7776 20.6665C14.3154 21.8989 12.6034 21.981 12.0254 20.7985L9.09585 14.8047C9.07555 14.7631 9.05414 14.7223 9.03167 14.6821L20.9682 2.86191ZM20.2633 2.15253L8.37691 13.9231C8.27864 13.8473 8.17445 13.7786 8.06504 13.7177L2.77106 10.7729C1.65088 10.1498 1.77777 8.49968 2.98004 8.05517L19.0908 2.09859C19.5076 1.94448 19.9204 1.98447 20.2633 2.15253ZM7.65566 14.6373C7.6306 14.6214 7.60501 14.6061 7.57894 14.5916L2.28495 11.6468C0.41799 10.6083 0.629477 7.85807 2.63326 7.11722L18.744 1.16065C20.7521 0.418209 22.7035 2.37871 21.9518 4.38332L15.7139 21.0177C14.9436 23.0717 12.0903 23.2085 11.127 21.2377L8.19742 15.2438C8.18135 15.2109 8.16412 15.1787 8.14578 15.1472L7.64818 14.6447L7.65566 14.6373Z"
        fill="white"
      />
    </svg>
  );
}

SvgSend1.propTypes = {
  fill: PropTypes.string,
};

SvgSend1.defaultProps = {
  fill: '#fff',
};
