import React from 'react';
import PropTypes from 'prop-types';

const SvgSearchIcon = ({ fill, ...props }) => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M22.2935 24.3606C22.6844 24.7508 23.3176 24.7502 23.7078 24.3593C24.0979 23.9684 24.0973 23.3352 23.7065 22.9451L22.2935 24.3606ZM17.6065 18.2693L16.8999 17.5616L17.6065 18.2693ZM10.7308 20.1144C5.9089 20.1144 2 16.2055 2 11.3836H0C0 17.31 4.80433 22.1144 10.7308 22.1144V20.1144ZM2 11.3836C2 6.56173 5.9089 2.65283 10.7308 2.65283V0.652832C4.80433 0.652832 0 5.45716 0 11.3836H2ZM10.7308 2.65283C15.5526 2.65283 19.4615 6.56173 19.4615 11.3836H21.4615C21.4615 5.45716 16.6572 0.652832 10.7308 0.652832V2.65283ZM19.4615 11.3836C19.4615 13.7971 18.4836 15.9802 16.8999 17.5616L18.3131 18.9769C20.2571 17.0356 21.4615 14.3493 21.4615 11.3836H19.4615ZM16.8999 17.5616C15.3192 19.1401 13.1397 20.1144 10.7308 20.1144V22.1144C13.6909 22.1144 16.3727 20.9145 18.3131 18.9769L16.8999 17.5616ZM16.9 18.977L22.2935 24.3606L23.7065 22.9451L18.313 17.5615L16.9 18.977Z"
        fill={fill}
      />
    </svg>
  );
};

SvgSearchIcon.propTypes = {
  fill: PropTypes.string,
};

SvgSearchIcon.defaultProps = {
  fill: '#fcb316',
};
export default SvgSearchIcon;
