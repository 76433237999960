import React, { memo } from 'react';
import { FooterLinks } from './Links';
import styled from '@emotion/styled/macro';

const Wrapper = styled.footer`
  margin-top: auto;
  position: relative;
`;

export const Footer = memo(() => {
  return (
    <Wrapper as="footer">
      {/* {showBanner && <FooterBanner />} */}
      <FooterLinks />
    </Wrapper>
  );
});
