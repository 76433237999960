import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ThemeProvider } from 'contexts/ThemeContext';
import { Global } from '@emotion/core';
import globalStyle from 'GlobalStyle';
import Router from './Router';
import getStore from './redux/store';

const { store, persistor } = getStore();

const App = () => {
  useEffect(() => {
    window.addEventListener('keydown', function(e) {
      if ((e.keyCode || e.key || e.code) === 32 && e.target === document.body) {
        e.preventDefault();
      }
    });
  }, []);
  return (
    <React.StrictMode>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ThemeProvider>
            <Global styles={globalStyle} />
            <Router />
          </ThemeProvider>
        </PersistGate>
      </Provider>
    </React.StrictMode>
  );
};

export default App;
