import React from 'react';
import PropTypes from 'prop-types';

function SvgFlag({ fill, ...props }) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 34.958 29.959" {...props}>
      <path
        d="M29.643,18.297A25.566,25.566 0,0 1,25.16 12.751a0.525,0.525 0,0 1,0.01 -0.556,75.621 75.621,0 0,0 4.709,-9.078c0.224,-0.521 -0.021,-0.767 -0.438,-0.487 -8.161,5.423 -16.064,-6.191 -24.211,-1.477a2.19,2.19 0,0 1,0.045 0.492l-0.253,16.261c8.134,-4.631 16.016,6.919 24.161,1.508 0.417,-0.28 0.676,-0.94 0.464,-1.118Z"
        fill={fill}
      />
      <path
        d="M3.938,2.624l0.008,-0.569a1.658,1.658 0,0 0,-0.017 -0.2c-0.086,-0.093 -0.175,-0.189 -0.261,-0.281 -0.213,-0.225 -0.413,-0.463 -0.663,-0.694 -0.018,0.921 -0.035,1.8 -0.052,2.727ZM3.862,2.235a2.349,2.349 0,0 1,-0.634 0.6c0.008,-0.4 0.016,-0.8 0.025,-1.241C3.484,1.814 3.672,2.024 3.862,2.236Z"
        fill={fill}
      />
      <path d="M3.472,0.861a1.691,1.691 0,0 0,-0.368 -0.278l0.666,0.717A1.677,1.677 0,0 0,3.472 0.861Z" fill={fill} />
      <path d="M3.911,4.323 L3.918,3.877l-0.2,0.2C3.766,4.154 3.836,4.238 3.911,4.323Z" fill={fill} />
      <path d="M3.858,7.779l-0.119,0.107 0.116,0.122Z" fill={fill} />
      <path d="M3.741,15.341l0.006,-0.377 -0.19,0.184Z" fill={fill} />
      <path d="M3.796,11.825l0.006,-0.429 -0.206,0.2C3.651,11.665 3.722,11.745 3.796,11.825Z" fill={fill} />
      <path
        d="M3.768,13.616l0.008,-0.5 -0.99,-1.029c-0.016,0.825 -0.032,1.678 -0.048,2.5ZM3.634,13.329 L3.014,13.913 3.037,12.713C3.261,12.935 3.451,13.139 3.634,13.329Z"
        fill={fill}
      />
      <path d="M3.682,19.224l0.008,-0.554 -0.287,0.276Z" fill={fill} />
      <path d="M3.678,19.517l-0.564,-0.586 0.581,-0.559 0.011,-0.7L2.486,18.846l1.183,1.231Z" fill={fill} />
      <path
        d="M3.712,17.263l0.008,-0.553 -0.967,-0.995a0.021,0.021 0,0 0,-0.018 0c-0.006,0 -0.01,0.007 -0.016,0.011 -0.047,0.319 -0.073,2.43 -0.038,2.53ZM3.545,16.98 L2.945,17.561c0.008,-0.367 0.015,-0.757 0.023,-1.147A2.732,2.732 0,0 1,3.545 16.976Z"
        fill={fill}
      />
      <path
        d="M3.826,9.9l0.008,-0.6 -0.972,-1c-0.018,0.9 -0.034,1.751 -0.05,2.578ZM3.426,9.315 L3.726,9.609 3.111,10.216a5.842,5.842 0,0 1,0.013 -1.205C3.232,9.12 3.328,9.218 3.426,9.315Z"
        fill={fill}
      />
      <path d="M3.849,8.31l-0.434,-0.458 0.448,-0.444 0.008,-0.58 -1.054,1.048 1.022,1.063Z" fill={fill} />
      <path
        d="M3.878,6.455l0.011,-0.719c-0.1,-0.106 -0.2,-0.215 -0.3,-0.32 -0.213,-0.224 -0.413,-0.463 -0.661,-0.692 -0.018,0.92 -0.035,1.8 -0.053,2.724ZM3.809,6.089 L3.156,6.738c0.008,-0.451 0.016,-0.853 0.024,-1.247Z"
        fill={fill}
      />
      <path
        d="M3.791,12.195c-0.245,-0.252 -0.477,-0.5 -0.561,-0.613l0.579,-0.564 0.011,-0.706 -1.2,1.158 1.16,1.207Z"
        fill={fill}
      />
      <path d="M3.736,15.708l-0.558,-0.581 0.576,-0.556 0.008,-0.538L2.591,15.158l1.136,1.177Z" fill={fill} />
      <path
        d="M0.274,15.797 L0.237,18.176 1.447,17.011ZM1.588,17.638a3.187,3.187 0,0 1,0.584 0.584l-0.606,0.584C0.399,17.197 0.407,16.813 0.414,16.426Z"
        fill={fill}
      />
      <path
        d="M0.066,29.312 L0.033,31.691l1.172,-1.165ZM0.124,32.306 L0.747,32.895 0.1,33.536C0.142,30.7 0.149,30.299 0.157,29.923Z"
        fill={fill}
      />
      <path
        d="M0.313,21.791l1.051,-1.013 -1.147,-1.193 -0.07,4.522 1.2,-1.2ZM0.347,20.112c0.224,0.211 0.408,0.419 0.589,0.6l-0.613,0.594C0.33,20.929 0.338,20.54 0.347,20.113ZM0.305,22.24 L0.929,22.911 0.281,23.481C0.285,23.09 0.296,22.688 0.305,22.241Z"
        fill={fill}
      />
      <path
        d="M0.124,25.545l-0.037,2.391L1.26,26.775A13.621,13.621 0,0 0,0.124 25.545ZM0.205,27.345c0.008,-0.413 0.016,-0.815 0.025,-1.263l0.617,0.664c-0.2,0.2 -0.389,0.386 -0.578,0.571A0.246,0.246 0,0 1,0.209 27.347Z"
        fill={fill}
      />
      <path
        d="M0,33.581a1.32,1.32 0,0 0,0.064 0.408,1.456 1.456,0 0,1 0.023,-0.213l0.614,0.588 -0.243,0.241a1.328,1.328 0,0 0,0.257 0.179l0.413,-0.41 -1.121,-1.2Z"
        fill={fill}
      />
      <path d="M1.956,1.067c0,-0.24 0.009,-0.47 0.013,-0.7a1.656,1.656 0,0 0,-0.524 0.15Z" fill={fill} />
      <path
        d="M0.445,4.661l-0.04,2.589L1.677,5.986ZM0.645,5.361a0.159,0.159 0,0 1,0.049 0.025c0.182,0.192 0.362,0.386 0.555,0.592l-0.61,0.608A4.913,4.913 0,0 1,0.646 5.36Z"
        fill={fill}
      />
      <path
        d="M0.33,12.094l-0.037,2.444L1.512,13.363ZM0.489,12.754 L1.046,13.354 0.466,13.92Q0.477,13.382 0.489,12.754Z"
        fill={fill}
      />
      <path d="M3.474,32.832l0.01,-0.682 -0.321,0.319A4.081,4.081 0,0 0,3.474 32.832Z" fill={fill} />
      <path d="M3.531,29.089l0.009,-0.607 -0.332,0.293Z" fill={fill} />
      <path d="M3.59,25.176l0.009,-0.613 -0.3,0.3Z" fill={fill} />
      <path
        d="M0.775,1.091a1.671,1.671 0,0 0,-0.289 0.912l-0.022,1.417 1.29,-1.28ZM0.716,1.532 L1.329,2.126 0.714,2.739A4.928,4.928 0,0 1,0.716 1.535Z"
        fill={fill}
      />
      <path d="M3.631,22.355l0.015,-0.976 -0.485,0.469Z" fill={fill} />
      <path
        d="M0.387,8.398l-0.037,2.44 1.243,-1.2C1.2,9.242 0.81,8.824 0.387,8.398ZM0.56,9.033a1.943,1.943 0,0 1,0.312 0.3c0.1,0.1 0.195,0.2 0.28,0.291l-0.615,0.591C0.545,9.839 0.553,9.451 0.561,9.036Z"
        fill={fill}
      />
      <path d="M3.906,4.694l-0.566,-0.607 0.584,-0.58 0.008,-0.51 -1.161,1.154 1.124,1.21Z" fill={fill} />
      <path
        d="M3.503,30.896l0.008,-0.514A11.621,11.621 0,0 0,2.448 29.3c-0.018,0.881 -0.035,1.761 -0.052,2.69ZM3.333,30.641 L2.689,31.198a7.268,7.268 0,0 1,0.022 -1.157Z"
        fill={fill}
      />
      <path d="M1.069,11.585l0.666,0.69c0.008,-0.444 0.018,-0.913 0.026,-1.354Z" fill={fill} />
      <path d="M1.147,3.977l0.677,0.723a0.241,0.241 0,0 0,0.06 0.03c0.009,-0.5 0.019,-0.98 0.029,-1.513Z" fill={fill} />
      <path
        d="M3.46,33.634l0.006,-0.42c-0.258,-0.272 -0.525,-0.567 -0.669,-0.75l0.691,-0.687 0.008,-0.51L2.223,32.537l1.217,1.308A1.382,1.382 0,0 0,3.46 33.634Z"
        fill={fill}
      />
      <path
        d="M2.608,34.864a6.583,6.583 0,0 1,0.029 -0.977l0.555,0.528a1.337,1.337 0,0 0,0.161 -0.278L2.372,33.091c-0.012,0.634 -0.024,1.243 -0.035,1.846A1.321,1.321 0,0 0,2.608 34.864Z"
        fill={fill}
      />
      <path d="M1.12,7.846l0.673,0.693a6.3,6.3 0,0 0,0.012 -1.375Z" fill={fill} />
      <path
        d="M1.331,31.633 L0.613,32.347a4.212,4.212 0,0 0,0.723 0.755c0.01,-0.485 0.019,-0.966 0.028,-1.448Z"
        fill={fill}
      />
      <path
        d="M2.746,0.428a1.669,1.669 0,0 0,-0.455 -0.078h-0.063c-0.008,0.439 -0.017,0.873 -0.025,1.307a9.975,9.975 0,0 1,-1 -1,1.679 1.679,0 0,0 -0.264,0.231L2.114,2.143l-1.656,1.646 -0.007,0.492L2.04,5.994l-1.64,1.631 -0.006,0.4 1.575,1.64L0.34,11.231l-0.008,0.534 1.56,1.623L0.287,14.943l-0.008,0.49 1.546,1.609 -1.594,1.533 -0.009,0.595 1.531,1.592 -1.082,1.041 1.039,1.118L0.14,24.479l-0.01,0.666L1.64,26.763l-1.554,1.546 -0.01,0.621 1.491,1.6 -1.539,1.53 -0.011,0.73 1.467,1.578c-0.033,0.039 -0.058,0.074 -0.088,0.1l-0.416,0.413a1.321,1.321 0,0 0,0.348 0.057l0.168,0 0.064,-0.064v0.065l0.54,0.008h0C2.176,29.856 2.54,10.862 2.746,0.428ZM0.785,3.97 L2.151,2.612 2.183,2.634c-0.018,0.909 -0.035,1.818 -0.054,2.777ZM0.769,7.826 L2.108,6.499c-0.018,0.909 -0.035,1.769 -0.052,2.634A12.7,12.7 0,0 1,0.77 7.826ZM0.681,11.607 L2.034,10.307c-0.017,0.857 -0.034,1.737 -0.051,2.623A15.7,15.7 0,0 1,0.682 11.607ZM0.625,15.156 L1.951,13.881c0.032,0.105 0.007,2.23 -0.038,2.613ZM0.568,18.956 L1.888,17.678c-0.016,0.857 -0.033,1.737 -0.051,2.628ZM1.446,21.84 L2.002,21.34c0,0.15 -0.006,0.319 -0.009,0.489s-0.007,0.347 -0.01,0.516ZM1.692,26.15L0.383,24.743 1.74,23.391a0.337,0.337 0,0 1,0.032 0.011s0.013,0.008 0.013,0.012c0,0.059 0,0.117 0,0.175L1.74,26.127ZM0.304,28.66 L1.704,27.271c-0.018,0.962 -0.036,1.872 -0.055,2.83ZM0.232,32.36 L1.606,30.997c0.042,0.152 0,2.531 -0.045,2.789Z"
        fill={fill}
      />
      <path d="M0.898,18.975a4.582,4.582 0,0 0,0.694 0.7c0.009,-0.469 0.018,-0.937 0.028,-1.411Z" fill={fill} />
      <path d="M0.744,24.746l0.729,0.781a8.977,8.977 0,0 0,0.011 -1.518Z" fill={fill} />
      <path d="M0.992,15.16l0.673,0.7c0.009,-0.448 0.018,-0.918 0.027,-1.372Z" fill={fill} />
      <path d="M0.668,28.663l0.732,0.784a8.071,8.071 0,0 0,0.006 -1.515Z" fill={fill} />
      <path d="M3.524,29.486l-0.663,-0.711 0.685,-0.682 0.008,-0.581 -1.224,1.219 1.186,1.275Z" fill={fill} />
      <path
        d="M3.56,27.14l0.008,-0.552a11.567,11.567 0,0 0,-1.045 -1.064c-0.018,0.884 -0.035,1.764 -0.052,2.7ZM2.839,26.265c0.181,0.191 0.36,0.385 0.557,0.6 -0.2,0.213 -0.406,0.413 -0.617,0.617a5.8,5.8 0,0 1,0.011 -1.235C2.807,26.251 2.828,26.253 2.839,26.265Z"
        fill={fill}
      />
      <path
        d="M3.585,25.55c-0.215,-0.232 -0.428,-0.464 -0.649,-0.7l0.67,-0.665 0.008,-0.509 -1.246,1.24 1.207,1.3Z"
        fill={fill}
      />
      <path
        d="M3.619,23.305l0.008,-0.578 -0.827,-0.89 0.853,-0.823 0.008,-0.512 -1.013,-1.049c-0.04,0.175 -0.12,4.739 -0.075,4.89ZM2.905,20.114c0.217,0.192 0.412,0.4 0.589,0.582l-0.631,0.605C2.868,20.912 2.881,20.52 2.905,20.119ZM3.479,23.008 L2.862,23.623 2.83,23.599c0.008,-0.4 0.016,-0.8 0.023,-1.2Z"
        fill={fill}
      />
    </svg>
  );
}

SvgFlag.propTypes = {
  fill: PropTypes.string,
};

SvgFlag.defaultProps = {
  fill: '#fcfcfc',
};

export default SvgFlag;
