import React, { useEffect, useState, memo } from 'react';
import styled from '@emotion/styled/macro';
import { css } from '@emotion/core';
import { Link, useLocation } from 'react-router-dom';
import { Box } from 'rebass';
import { Content, Button, Hamburger } from 'components/atoms';
import { Searchbar } from 'components/atoms/Form/Searchbar';
import PropTypes from 'prop-types';
import { formatName } from 'lib/utils';
import { useSelector } from 'react-redux';
import { useAlert } from 'hooks';
import { ProfileComponent } from './ProfileComponent';
import { useHistory } from 'react-router-dom';
import { SearchIcon } from 'components/icons';
import { useMedia } from 'react-use';

const Wrapper = styled(Content)`
  align-items: center;
  left: 0;
  position: fixed;
  top: 0;
  transition: all 0.2s;
  width: 100%;
  z-index: 3;

  @media (max-width: 1160px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 5;
  }

  ${({ alertAvailable }) =>
    alertAvailable &&
    css`
      top: 4rem;
    `}
`;

const Ham = styled(Hamburger)`
  left: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`;

const LogoWrapper = styled(Box)`
  display: flex;
  svg {
    height: 2rem;
    width: 7rem;
    ${({ theme }) => theme.mq.sm`
      height: 3rem;
      width: 10rem;
  `}
  }
`;

const FloatingBox = styled(Box)`
  left: -6px;
  padding: 1rem 1.5rem;
  padding-top: 0;
  position: fixed;
  top: -5rem;
  transform: scale(0.5);
  transition: all 0.5s;
  width: 100%;
  z-index: 2;

  ${({ active }) =>
    active &&
    css`
      top: 7rem;
      transform: scale(1);
      transition: all 0.2s;
    `}
`;

const SubWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 24rem;
  padding: 1.5rem 0;
  @media (max-width: 991px) {
    margin-left: 10rem;
  }

  @media (max-width: 550px) {
    margin-left: 0rem;
  }
`;

const HoverButton = styled(Button)`
  background: transparent;
  color: ${({ theme }) => theme.colors.switchingText} !important;
  margin-right: 1rem;
  &:hover {
    background: linear-gradient(270.02deg, #fdb913 0.02%, #f2994a 99.98%) !important;
    transition: all 0.7s ease-in-out;
  }
`;

export const Navbar = memo(({ loggedIn, logout, searchBar, toggleSearchBar, toggleSidebar, showSidebar }) => {
  const user = useSelector(state => state.profile.user);
  const [showGradient, setShowGradient] = useState(true);
  // const isWide = useMedia('(min-width: 1160px)');
  const { alert } = useAlert();
  const [navbar, setNavbar] = useState(false);
  const history = useHistory();
  const isSmall = useMedia('(max-width: 450px)');
  const handleRedirect = () => {
    const path = history.location.pathname;
    history.push(`/auth/login?redirect=${path}`);
  };

  const { pathname } = useLocation();
  useEffect(() => {
    setShowGradient(pathname === '/');
  }, [pathname]);

  const changeBg = () => {
    if (window.scrollY >= 10) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  window.addEventListener('scroll', changeBg);

  return (
    <>
      <Wrapper
        alertAvailable={alert.message}
        fluid
        searchBarActive={searchBar}
        as="nav"
        showGradient={showGradient}
        style={{ backgroundColor: !navbar ? 'transparent' : '#000' }}
      >
        <LogoWrapper pl={['2.5rem', '3rem', 0]} flexShrink="0" mr="1rem">
          <Ham onClick={toggleSidebar} showSidebar={showSidebar} />
        </LogoWrapper>

        <SubWrapper>
          <Box maxWidth="35rem" mr="1rem" display={['none', 'flex']}>
            <Searchbar />
          </Box>

          {/* {!isWide && <LogoIcon style={IconStyle} />} */}
          {pathname === '/search' && (
            <Box mr={loggedIn ? '3rem' : '0rem'} display={['flex', 'none']}>
              <SearchIcon width={15} height={16} style={{ cursor: 'pointer' }} onClick={toggleSearchBar} />
            </Box>
          )}
          {!loggedIn ? (
            <>
              <Box display={['flex', 'none']} mr="1rem" alignItems="center">
                <HoverButton size={isSmall ? 'small' : 'normal'} effect as={Link} to="/auth/signup">
                  SIGN UP
                </HoverButton>
                <Button
                  size={isSmall ? 'small' : 'normal'}
                  effect
                  onClick={handleRedirect}
                  style={{ color: 'white', background: 'linear-gradient(270.02deg, #FDB913 0.02%, #F2994A 99.98%)' }}
                >
                  LOG IN
                </Button>
              </Box>
              <Box display={['none', 'flex']} justifyContent="space-between" style={{ marginRight: '3rem' }}>
                <HoverButton size={isSmall ? 'small' : 'normal'} effect as={Link} to="/auth/signup">
                  SIGN UP
                </HoverButton>
                <Button
                  size={isSmall ? 'small' : 'normal'}
                  effect
                  onClick={handleRedirect}
                  style={{ color: 'white', background: 'linear-gradient(270.02deg, #FDB913 0.02%, #F2994A 99.98%)' }}
                >
                  LOG IN
                </Button>
              </Box>
            </>
          ) : (
            <>
              <Box alignItems="center">
                <ProfileComponent logout={logout} avatar={user.avatar} name={formatName(user)} />
              </Box>
            </>
          )}
        </SubWrapper>
      </Wrapper>
      <FloatingBox display={['flex', 'none']} loggedIn={loggedIn} active={searchBar}>
        <Searchbar style={{ width: '100%' }} />
      </FloatingBox>
    </>
  );
});

Navbar.propTypes = {
  loggedIn: PropTypes.bool.isRequired,
  logout: PropTypes.func.isRequired,
  searchBar: PropTypes.bool.isRequired,
  toggleSearchBar: PropTypes.func.isRequired,
  toggleSidebar: PropTypes.func.isRequired,
};

// import React, { useEffect, useRef, useState, memo } from 'react';
// import styled from '@emotion/styled/macro';
// import { css } from '@emotion/core';
// import { useTheme } from 'emotion-theming';
// import { Link, useLocation } from 'react-router-dom';
// import { Box } from 'rebass';
// import { Content, Button, Hamburger, Image, Typography } from 'components/atoms';
// import { Searchbar } from 'components/atoms/Form/Searchbar';
// import { LogoIcon, /* CloudUploadIcon, */ SearchIcon } from 'components/icons';
// import PropTypes from 'prop-types';
// import { formatName } from 'lib/utils';
// import { useSelector } from 'react-redux';
// import { useAlert } from 'hooks';

// const Wrapper = styled(Content)`
//   align-items: center;
//   background-color: ${({ theme }) => theme.colors.switchingBg};
//   display: flex;
//   height: 6rem;
//   justify-content: space-between;
//   left: 0;
//   padding-bottom: 1rem;
//   padding-top: 1rem;
//   position: fixed;
//   top: 0;
//   transition: all 0.2s;
//   width: 100%;
//   z-index: 3;

//   ${({ alertAvailable }) =>
//     alertAvailable &&
//     css`
//       top: 4rem;
//     `}
// `;

// // const UploadButton = styled(Button)`
// //   padding-left: 1.5rem;
// //   padding-right: 1.5rem;
// //   margin-right: 1rem;

// //   svg {
// //     ${({ theme }) => theme.mq.md`
// //     margin-left: 0.5rem;
// //   `}
// //   }

// //   ${Typography.Text} {
// //     display: none;

// //     ${({ theme }) => theme.mq.md`
// //       display: inline;
// //   `}
// //   }
// // `;

// const Ham = styled(Hamburger)`
//   left: 0;
//   position: absolute;
//   top: 50%;
//   transform: translateY(-50%);
// `;

// const Avatar = styled(Image)`
//   height: 4rem;
//   object-fit: cover;
//   width: 4rem;
//   border-radius: 4rem;
//   flex-shrink: 0;
// `;

// const ProfileMenuItem = styled.li`
//   display: block;
//   &:not(:last-of-type) {
//     border-bottom: 1px solid ${({ theme }) => theme.colors['greyish-brown-three']}40;
//   }
// `;

// const ProfileMenuLink = styled(Typography.Paragraph)`
//   display: block;
//   padding: 1rem 0.5rem;
//   text-decoration: none;
//   &,
//   &:visited {
//     color: ${({ theme }) => theme.colors.white};
//   }
// `;

// ProfileMenuLink.defaultProps = {
//   as: Link,
//   size: 'small',
//   to: '#',
// };

// const ProfileMenu = styled.ul`
//   background-color: ${({ theme }) => theme.colors['dark-two']};
//   border-radius: 0.5rem;
//   left: 50%;
//   margin-top: 0.5rem;
//   min-width: 17rem;
//   opacity: 0;
//   padding: 1.5rem;
//   pointer-events: none;
//   position: absolute;
//   top: 90%;
//   transform: translateX(-50%);
//   transition: all 0.2s;
//   z-index: 1;

//   &:before {
//     border-bottom: 12px solid ${({ theme }) => theme.colors['dark-two']};
//     border-left: 12px solid transparent;
//     border-right: 12px solid transparent;
//     content: '';
//     left: 50%;
//     position: absolute;
//     top: -1rem;
//     transform: translateX(-50%);
//   }
// `;

// const ProfileWrapper = styled(Box)`
//   align-items: center;
//   cursor: pointer;
//   display: flex;
//   position: relative;

//   ${Typography.Paragraph} {
//     display: none;

//     ${({ theme }) => theme.mq.md`
//       display: block;
//   `}
//   }

//   ${({ theme }) => theme.mq.md`
//   ${Avatar} {
//       margin-right: 1rem;
//       img{
//         margin-right: 0;
//       }
//   }
//   `}

//   ${({ active }) =>
//     active &&
//     css`
//       ${ProfileMenu} {
//         opacity: 1;
//         pointer-events: auto;
//       }
//     `}
// `;

// const LogoWrapper = styled(Box)`
//   display: flex;
//   svg {
//     height: 2rem;
//     width: 7rem;
//     ${({ theme }) => theme.mq.sm`
//       height: 3rem;
//       width: 10rem;
//   `}
//   }
// `;

// const FloatingBox = styled(Box)`
//   background-color: ${({ theme }) => theme.colors.switchingBg};
//   left: 0;
//   padding: 1rem 1.5rem;
//   padding-top: 0;
//   position: fixed;
//   top: -5rem;
//   transform: scale(0.5);
//   transition: all 0.5s;
//   width: 100%;
//   z-index: 2;

//   ${({ active }) =>
//     active &&
//     css`
//       top: 6rem;
//       transform: scale(1);
//       transition: all 0.2s;
//     `}
// `;

// const ProfileComponent = memo(({ avatar, name, logout }) => {
//   const [active, setActive] = useState(false);
//   const toggleActive = () => setActive(v => !v);
//   const node = useRef();

//   const handleClick = e => {
//     if (!node.current.contains(e.target)) {
//       setActive(false);
//     }
//   };

//   useEffect(() => {
//     document.addEventListener('mousedown', handleClick);
//     return () => {
//       document.removeEventListener('mousedown', handleClick);
//     };
//   }, []);

//   return (
//     <ProfileWrapper onClick={toggleActive} active={active} ref={node}>
//       <Avatar size="50x50" src={avatar} spinnerSize="1rem" alt={`${name}'s avatar`} useThumbnailer={false} />
//       <Typography.Paragraph color="switchingText" size="tiny">
//         {name}
//       </Typography.Paragraph>
//       <ProfileMenu>
//         <ProfileMenuItem>
//           <ProfileMenuLink to="/profile/me">Profile</ProfileMenuLink>
//         </ProfileMenuItem>
//         <ProfileMenuItem>
//           <ProfileMenuLink to="/edit-profile">Edit Profile</ProfileMenuLink>
//         </ProfileMenuItem>
//         {/* <ProfileMenuItem>
//           <ProfileMenuLink>Help & Support</ProfileMenuLink>
//         </ProfileMenuItem> */}
//         <ProfileMenuItem>
//           <ProfileMenuLink onClick={logout}>Logout</ProfileMenuLink>
//         </ProfileMenuItem>
//       </ProfileMenu>
//     </ProfileWrapper>
//   );
// });

// ProfileComponent.propTypes = {
//   avatar: PropTypes.string.isRequired,
//   logout: PropTypes.func.isRequired,
//   name: PropTypes.string.isRequired,
// };

// export const Navbar = memo(
//   ({ /* active, */ loggedIn, logout, searchBar, toggleAuthModal, toggleSearchBar, toggleSidebar }) => {
//     const user = useSelector(state => state.profile.user);

//     const [showGradient, setShowGradient] = useState(true);
//     const { switchingText } = useTheme().colors;
//     const { alert } = useAlert();

//     const { pathname } = useLocation();
//     useEffect(() => {
//       setShowGradient(pathname === '/');
//     }, [pathname]);

//     return (
//       <>
//         <Wrapper alertAvailable={alert.message} fluid searchBarActive={searchBar} as="nav" showGradient={showGradient}>
//           <LogoWrapper pl={['2.5rem', '3rem', 0]} flexShrink="0" mr="1rem">
//             <Ham onClick={toggleSidebar} />
//             <Link to="/" style={{ display: 'flex' }} aria-label="Home link">
//               <LogoIcon />
//             </Link>
//           </LogoWrapper>

//           <Box display="flex" alignItems="center">
//             <Box maxWidth="35rem" mr="1rem" display={['none', 'flex']}>
//               <Searchbar />
//             </Box>
//             <Box display={['flex', 'none']} mr="2rem">
//               <SearchIcon
//                 fill={switchingText}
//                 width={15}
//                 height={16}
//                 style={{ cursor: 'pointer' }}
//                 onClick={toggleSearchBar}
//               />
//             </Box>
//             {!loggedIn ? (
//               <>
//                 {/* <UploadButton
//                   textColor="white"
//                   color="secondary"
//                   onClick={() => toggleAuthModal({link:'/upload'})}
//                   aria-label="Upload link"
//                 >
//                   <Typography.Text size="tiny" weight="medium">
//                     Upload
//                   </Typography.Text>
//                   <CloudUploadIcon />
//                 </UploadButton> */}

//                 <Box display={['flex', 'none']} onClick={() => toggleAuthModal()}>
//                   <Avatar src={null} spin={false} spinnerSize="1rem" alt="Default avatar" />
//                 </Box>
//                 <Box display={['none', 'flex']}>
//                   <Button
//                     color="switchingText"
//                     fontSize="tiny"
//                     link
//                     onClick={() => toggleAuthModal()}
//                     style={{ lineHeight: 1.2 }}
//                   >
//                     Sign In/Sign Up
//                   </Button>
//                 </Box>
//               </>
//             ) : (
//               <>
//                 {/* <UploadButton textColor="white" color="secondary" as={Link} to="/upload" aria-label="Upload link">
//                   <Typography.Text size="tiny" weight="medium">
//                     Upload
//                   </Typography.Text>
//                   <CloudUploadIcon />
//                 </UploadButton> */}

//                 <ProfileComponent logout={logout} avatar={user.avatar} name={formatName(user)} />
//               </>
//             )}
//           </Box>
//         </Wrapper>

//         <FloatingBox display={['flex', 'none']} active={searchBar}>
//           <Searchbar style={{ width: '100%' }} />
//         </FloatingBox>
//       </>
//     );
//   },
// );

// Navbar.propTypes = {
//   loggedIn: PropTypes.bool.isRequired,
//   logout: PropTypes.func.isRequired,
//   toggleAuthModal: PropTypes.func.isRequired,
//   searchBar: PropTypes.bool.isRequired,
//   toggleSearchBar: PropTypes.func.isRequired,
//   toggleSidebar: PropTypes.func.isRequired,
//   user: PropTypes.object.isRequired,
// };
