import React from 'react';
import PropTypes from 'prop-types';

function SvgAlbum({ fill, ...props }) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 71.215 58.09" {...props}>
      <path
        d="M61.26 0H0v49.777h8.224V6.493H61.26zm3.059 55.765a11.761 11.761 0 006.117-10.558 10.911 10.911 0 00-9.338-11.4l-2.5-.323-.019 2.565c2.191.154 8.761 1.2 9.543 8.149.908 8.079-8.067 11.161-9.692 11.651L58.417 58h.4a19.7 19.7 0 005.502-2.235zm4.4-22.269a7.918 7.918 0 012.494 2.816v-5.358a7.135 7.135 0 01-2.492 2.546zm-1.456 10.8c-.713-6.343-6.64-7.262-8.689-7.389l-.016 2.117a6.818 6.818 0 014.051 1.62 5.8 5.8 0 011.765 4.244c.051.452.469 5.522-5.92 7.927l-.016 2.126c1.999-.641 9.621-3.581 8.827-10.648zm-3.749.69v-.05a4.98 4.98 0 00-1.487-3.654 5.957 5.957 0 00-3.471-1.387l-.09 11.986c5.667-2.308 5.057-6.851 5.05-6.898zM61.665 58h9.55V46.81c-.732 6.633-5.957 9.79-9.55 11.19zM58.782 9.616c2.249.56 9.66 3.066 9.25 11.738a9.574 9.574 0 01-2.4 6.425 9.839 9.839 0 01-7.007 2.839l-.02 2.67 2.492-.341c10.686-1.462 9.4-11.957 9.345-12.4v-.032c-.559-9.36-6.97-11.7-10.222-12.288h-1.427zM47.024 18.48a1 1 0 00-1.149-.981L27 20.48a.992.992 0 00-.839.981v19.554a6.723 6.723 0 00-4.471-1.669 6.232 6.232 0 00-6.459 5.962 6.232 6.232 0 006.459 5.962 6.242 6.242 0 006.45-5.823c0-.013.008-.025.008-.038V28.271L45.037 25.6v9.449a6.721 6.721 0 00-4.471-1.669 6.232 6.232 0 00-6.459 5.962 6.232 6.232 0 006.459 5.962 6.232 6.232 0 006.459-5.962V18.48zM57.551 58H9.955V8.225h47.972zM63.1 33.431a11.529 11.529 0 018.116 10.108v-4.984c-1.1-3.638-3.474-4.636-3.584-4.68l-.992-.4.989-.4a6.863 6.863 0 003.587-4.183v-4.98c-.452 3.288-2.17 7.938-8.116 9.519zm1.9-6.244a8.813 8.813 0 002.167-5.816v-.04c.385-7.934-6.109-10.264-8.391-10.836L58.757 13a7.89 7.89 0 015.812 7.869c0 .061-.073 6.036-5.92 6.379l-.019 2.5A8.988 8.988 0 0065 27.187zm6.216-7.729V8.225h-8.04c3.33 1.339 7.293 4.375 8.039 11.233zM63.7 20.871a6.918 6.918 0 00-4.95-6.971l-.094 12.488c4.986-.336 5.044-5.301 5.044-5.517zM58.793 8.225h1.423a12.812 12.812 0 00-1.422-.178zm-.377 49.865V58h.4c-.156.038-.291.068-.4.09z"
        fill={fill}
        fillRule="evenodd"
      />
    </svg>
  );
}

SvgAlbum.propTypes = {
  fill: PropTypes.string,
};

SvgAlbum.defaultProps = {
  fill: '#fcb316',
};

export default SvgAlbum;
