import * as t from '../types';

const INITIAL_STATE = {
  byUser: [],
  data: {},
  fetching: {},
  fetchingSimilar: false,
  similar: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case t.GET_ARTIST_REQUEST:
      return { ...state, fetching: { ...state.fetching, [action.payload]: true } };
    case t.GET_ARTIST_SUCCESS:
      return {
        ...state,
        fetching: { ...state.fetching, [action.payload.id]: false },
        data: { ...state.data, [action.payload.id]: action.payload.data },
      };
    case t.GET_ARTIST_FAILURE:
      return { ...state, fetching: { ...state.fetching, [action.payload]: false } };

    case t.GET_SIMILAR_ARTIST_REQUEST:
      return { ...state, fetchingSimilar: true };
    case t.GET_SIMILAR_ARTIST_SUCCESS:
      return {
        ...state,
        fetchingSimilar: false,
        similar: { ...state.similar, [action.payload.id]: action.payload.data },
      };
    case t.GET_SIMILAR_ARTIST_FAILURE:
      return { ...state, fetchingSimilar: false };

    case t.GET_USER_ARTISTS_REQUEST:
      return { ...state, fetching: { ...state.fetching, byUser: true } };

    case t.GET_USER_ARTISTS_SUCCESS:
      return { ...state, fetching: { ...state.fetching, byUser: false }, byUser: action.payload };

    case t.GET_USER_ARTISTS_FAILURE:
      return { ...state, fetching: { ...state.fetching, byUser: false } };

    case t.LOGOUT:
      return INITIAL_STATE;

    default:
      return { ...state };
  }
};
