import PropTypes from 'prop-types';
import React from 'react';

function SvgRepeat({ fill, size, ...props }) {
  return (
    <svg width={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M10.4834 3L14.6982 7.2148L10.4834 11.4296" stroke={fill} strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M13.9956 7.21484H5.9492C3.21583 7.21484 1 9.43067 1 12.164V12.164C1 14.8974 3.21583 17.1132 5.9492 17.1132H6.81132"
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5166 21.2656L9.30176 17.0508L13.5166 12.836"
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.0044 17.0508H18.0508C20.7842 17.0508 23 14.835 23 12.1016V12.1016C23 9.36822 20.7842 7.15239 18.0508 7.15239H17.1887"
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="14.6982"
          y1="7.2148"
          x2="10.4834"
          y2="7.2148"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={fill} />
          <stop offset="1" stopColor={fill} />
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="7.49782"
          y1="7.21484"
          x2="7.49782"
          y2="17.1132"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={fill} />
          <stop offset="1" stopColor={fill} />
        </linearGradient>
        <linearGradient
          id="paint2_linear"
          x1="9.30176"
          y1="17.0508"
          x2="13.5166"
          y2="17.0508"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={fill} />
          <stop offset="1" stopColor={fill} />
        </linearGradient>
        <linearGradient
          id="paint3_linear"
          x1="16.5022"
          y1="17.0508"
          x2="16.5022"
          y2="7.15239"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={fill} />
          <stop offset="1" stopColor={fill} />
        </linearGradient>
      </defs>
    </svg>
  );
}

SvgRepeat.propTypes = {
  fill: PropTypes.string,
  size: PropTypes.string,
};

SvgRepeat.defaultProps = {
  fill: '#fff',
  size: 24,
};

export default SvgRepeat;
