import React from 'react';
import PropTypes from 'prop-types';

function SvgCasette({ fill, ...props }) {
  return (
    <svg width="24" height="19" viewBox="0 0 24 19" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M16.665 4.7665C17.2788 5.37959 17.7657 6.10765 18.0979 6.90905C18.4301 7.71045 18.6011 8.56947 18.6011 9.43699C18.6011 10.3045 18.4301 11.1635 18.0979 11.9649C17.7657 12.7663 17.2788 13.4944 16.665 14.1075M7.335 14.0965C6.72123 13.4834 6.23432 12.7553 5.9021 11.9539C5.56989 11.1525 5.3989 10.2935 5.3989 9.42599C5.3989 8.55846 5.56989 7.69944 5.9021 6.89805C6.23432 6.09665 6.72123 5.36859 7.335 4.75549M19.7787 1.65283C21.8413 3.71608 23 6.51406 23 9.43149C23 12.3489 21.8413 15.1469 19.7787 17.2102M4.22134 17.2102C2.15872 15.1469 1 12.3489 1 9.43149C1 6.51406 2.15872 3.71608 4.22134 1.65283"
        stroke={fill}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill={fill}
      />
    </svg>
  );
}

SvgCasette.propTypes = {
  fill: PropTypes.string,
};

SvgCasette.defaultProps = {
  fill: '#fcb316',
};

export default SvgCasette;
