import React from 'react';
import styled from '@emotion/styled/macro';
import { keyframes } from '@emotion/core';
import { Box } from 'rebass';
import { useMedia } from 'react-use';

const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.footer};
  width: 100%;
`;

const TopSection = styled.div`
  padding: 8rem 0 4rem 6rem;
  width: 100vw;
  @media (max-width: 560px) {
    padding: 8rem 0 0rem 2rem;
  }
`;

const fullView = keyframes`
  100% {
    width: 100%;
  }
`;

const shimmer = keyframes`
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
`;

const FirstColumn = styled.div`
  background: linear-gradient(90deg, #272729 5%, #3b3b40 52.01%, #272729 100%);
  height: 60px;
  width: 30%;
  margin-bottom: 6rem;
  border-radius: 6px;
  animation: ${shimmer} 2s infinite linear;
  background-size: 1000px 100%;
  @media (max-width: 560px) {
    height: 30px;
    width: 85%;
  }
`;

const SecondColumn = styled.div`
  margin-bottom: 10rem;
  animation: ${fullView} 0.5s forwards cubic-bezier(0.25, 0.46, 0.45, 0.94);
`;

const ThreeDivs = styled.div`
  height: 20px;
  width: 50%;
  margin-bottom: 1rem;
  background: linear-gradient(90deg, #272729 5%, #3b3b40 52.01%, #272729 100%);
  border-radius: 6px;
  animation: ${shimmer} 2s infinite linear;
  background-size: 1000px 100%;
  @media (max-width: 560px) {
    height: 12px;
    width: 61%;
  }
`;

const SecondRow = styled.div`
  margin: 6rem;
  margin-top: 6rem;

  @media (max-width: 560px) {
    margin-left: 2rem;
    margin-top: 8rem;
  }
`;

const Top = styled.div`
  background: linear-gradient(90deg, #272729 5%, #3b3b40 52.01%, #272729 100%);
  height: 2rem;
  width: 15%;
  margin-bottom: 6rem;
  border-radius: 6px;
  animation: ${shimmer} 2s infinite linear;
  background-size: 1000px 100%;
  @media (max-width: 560px) {
    height: 1rem;
    width: 13%;
    margin-bottom: 2rem;
  }
`;
const TopRight = styled.div`
  background: linear-gradient(90deg, #272729 5%, #3b3b40 52.01%, #272729 100%);
  height: 10px;
  width: 10%;
  margin-bottom: 6rem;
  margin-right: 3rem;
  border-radius: 6px;
  animation: ${shimmer} 2s infinite linear;
  background-size: 1000px 100%;
  @media (max-width: 560px) {
    height: 1rem;
    width: 5rem;
    margin-bottom: 2rem;
  }
`;

const ForthColumn = styled.div`
  display: flex;
  animation: ${fullView} 0.5s forwards cubic-bezier(0.25, 0.46, 0.45, 0.94);
`;

const Columns = styled.div`
  width: 20rem;
  height: 20rem;
  background: linear-gradient(90deg, #272729 5%, #3b3b40 52.01%, #272729 100%);
  border-radius: 8px;
  margin: 0 1rem 0 1rem;
  animation: ${shimmer} 2s infinite linear;
  background-size: 1000px 100%;
  @media (max-width: 560px) {
    width: 13rem;
    height: 13rem;
    margin: 0 2rem 0 0rem;
  }
`;

export const Shimmer = () => {
  const isWide = useMedia('(max-width: 760px)');
  return (
    <Container>
      <TopSection>
        <FirstColumn></FirstColumn>
        <SecondColumn>
          <ThreeDivs />
          <ThreeDivs />
          <ThreeDivs />
        </SecondColumn>
      </TopSection>

      <SecondRow>
        <Box display="flex" justifyContent="space-between">
          <Top />
          <TopRight />
        </Box>
        <ForthColumn>
          {isWide ? (
            <>
              <Columns />
              <Columns />
              <Columns />
            </>
          ) : (
            <>
              <Columns />
              <Columns />
              <Columns />
              <Columns />
              <Columns />
              <Columns />
            </>
          )}
        </ForthColumn>
      </SecondRow>

      <SecondRow>
        <Box display="flex" justifyContent="space-between">
          <Top />
          <TopRight />
        </Box>
        <ForthColumn>
          {isWide ? (
            <>
              <Columns />
              <Columns />
              <Columns />
            </>
          ) : (
            <>
              <Columns />
              <Columns />
              <Columns />
              <Columns />
              <Columns />
              <Columns />
            </>
          )}
        </ForthColumn>
      </SecondRow>
      <SecondRow>
        <Box display="flex" justifyContent="space-between">
          <Top />
          <TopRight />
        </Box>
        <ForthColumn>
          {isWide ? (
            <>
              <Columns />
              <Columns />
              <Columns />
            </>
          ) : (
            <>
              <Columns />
              <Columns />
              <Columns />
              <Columns />
              <Columns />
              <Columns />
            </>
          )}
        </ForthColumn>
      </SecondRow>
      <SecondRow>
        <Box display="flex" justifyContent="space-between">
          <Top />
          <TopRight />
        </Box>
        <ForthColumn>
          {isWide ? (
            <>
              <Columns />
              <Columns />
              <Columns />
            </>
          ) : (
            <>
              <Columns />
              <Columns />
              <Columns />
              <Columns />
              <Columns />
              <Columns />
            </>
          )}
        </ForthColumn>
      </SecondRow>
    </Container>
  );
};
