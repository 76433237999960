import React from 'react';
import PropTypes from 'prop-types';

function SvgPerson({ fill, ...props }) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 217.703 268.066" {...props}>
      <path
        d="M44.03 176.212c6.509-.23 11.729-6.528 11.729-14.281a16.373 16.373 0 00-1.339-6.523 14.914 14.914 0 006.052-12.377 16.536 16.536 0 00-.821-5.15c6.346-.44 11.381-6.647 11.381-14.26 4.5 0 8.422-2.915 10.5-7.231a10.389 10.389 0 003.767.717c4.958 0 9.214-3.531 11.081-8.581a11.494 11.494 0 009.45 5.389 11.686 11.686 0 009.952-6.185c1.978 4.714 6.077 7.961 10.828 7.961a11.351 11.351 0 009.03-4.81c0 .093-.012.185-.012.278 0 7.9 5.415 14.3 12.095 14.3.037 0 .073-.007.11-.007.654 6.024 4.469 10.874 9.421 12.138.266 6.767 4.5 12.295 10.015 13.412 2.051 8.365 7.23 15.149 13.955 18.449-.794 19.572-9.035 24.075-9.324 24.227l-1.948.893-.682 2.335c-4.194 14.36-17.259 34.779-18.889 37.3-9.783 11.486-20.951 18.824-33.2 21.811a41.216 41.216 0 01-15.69 1.05c-36.347-9.057-48.705-52.513-48.825-52.943l-.431-1.586-1.119-1.012c-8.052-7.286-8.436-17.471-7.087-25.312m-44.03-66.897A31.093 31.093 0 007.75 130.26a32.752 32.752 0 00-2.567 12.771c0 15.8 10.829 28.606 24.189 28.606a20.651 20.651 0 004.96-.607 13.655 13.655 0 001.163 1.427c-2.25 11.528-1.521 26.057 9.059 36.507 2.709 8.676 17.264 49.192 55.2 58.562l.156.036a34.156 34.156 0 006.372.507c10.2 0 31-3.3 50.679-26.566l.369-.5c.607-.923 14.4-21.98 19.852-38.645 3.813-2.822 11.734-11.028 12.951-30.746.121 0 .236.021.356.021 13.359 0 24.189-12.808 24.189-28.606a32.474 32.474 0 00-3.33-14.436 31.511 31.511 0 006.354-19.277c0-13-7.334-23.951-17.371-27.434a33.067 33.067 0 001.821-10.879c0-15.8-10.83-28.606-24.189-28.606 0-15.8-10.83-28.606-24.19-28.606a20.849 20.849 0 00-8.663 1.922c-3.989-9.31-12.14-15.71-21.573-15.71a22.1 22.1 0 00-15.982 7.173A22.1 22.1 0 0091.573.001c-9.182 0-17.168 6.05-21.266 14.962a20.723 20.723 0 00-6.811-1.17c-13.36 0-24.19 12.808-24.19 28.606-13.359.002-24.188 12.808-24.188 28.602a33.053 33.053 0 001.859 11C7.14 85.634-.001 96.489-.001 109.317"
        fill={fill}
        fillRule="evenodd"
      />
    </svg>
  );
}

SvgPerson.propTypes = {
  fill: PropTypes.string,
};

SvgPerson.defaultProps = {
  fill: '#fefefe',
};

export default SvgPerson;
