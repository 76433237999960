import * as React from 'react';

function SvgCommentBadge(props) {
  return (
    <svg width="100" height="99" viewBox="0 0 100 99" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask
        id="path-1-outside-1"
        maskUnits="userSpaceOnUse"
        x="-0.249516"
        y="0.335689"
        width="100"
        height="99"
        fill="black"
      >
        <rect fill="white" x="-0.249516" y="0.335689" width="100" height="99" />
        <path d="M3.41726 94.7674L3.41726 7.3357C3.41726 5.12656 5.20813 3.33569 7.41727 3.3357L92.7505 3.33586C94.9596 3.33587 96.7505 5.12673 96.7505 7.33586L96.7505 75.385C96.7505 77.5941 94.9596 79.385 92.7505 79.385L28.5454 79.385L4.98397 95.5913C4.32048 96.0477 3.41726 95.5727 3.41726 94.7674Z" />
      </mask>
      <path
        d="M3.41726 94.7674L3.41726 7.3357C3.41726 5.12656 5.20813 3.33569 7.41727 3.3357L92.7505 3.33586C94.9596 3.33587 96.7505 5.12673 96.7505 7.33586L96.7505 75.385C96.7505 77.5941 94.9596 79.385 92.7505 79.385L28.5454 79.385L4.98397 95.5913C4.32048 96.0477 3.41726 95.5727 3.41726 94.7674Z"
        fill="url(#paint0_linear)"
      />
      <path
        d="M96.7505 7.33586L93.7505 7.33586L96.7505 7.33586ZM96.7505 75.385L99.7505 75.385L96.7505 75.385ZM92.7505 3.33586L92.7505 6.33586L92.7505 3.33586ZM7.41727 3.3357L7.41726 6.3357L7.41727 3.3357ZM28.5454 79.385L28.5454 76.385L27.6133 76.385L26.8453 76.9133L28.5454 79.385ZM4.98397 95.5913L3.28382 93.1196L3.28382 93.1196L4.98397 95.5913ZM92.7505 79.385L92.7505 76.385L92.7505 76.385L92.7505 79.385ZM0.417259 7.3357L0.417255 94.7674L6.41726 94.7674L6.41726 7.3357L0.417259 7.3357ZM93.7505 7.33586L93.7505 75.385L99.7505 75.385L99.7505 7.33586L93.7505 7.33586ZM92.7505 0.335863L7.41727 0.335697L7.41726 6.3357L92.7505 6.33586L92.7505 0.335863ZM26.8453 76.9133L3.28382 93.1196L6.68411 98.063L30.2456 81.8567L26.8453 76.9133ZM92.7505 76.385L28.5454 76.385L28.5454 82.385L92.7505 82.385L92.7505 76.385ZM99.7505 7.33586C99.7505 3.46988 96.6165 0.335871 92.7505 0.335863L92.7505 6.33586C93.3028 6.33586 93.7505 6.78358 93.7505 7.33586L99.7505 7.33586ZM6.41726 7.3357C6.41726 6.78341 6.86497 6.3357 7.41726 6.3357L7.41727 0.335697C3.55128 0.33569 0.417259 3.4697 0.417259 7.3357L6.41726 7.3357ZM0.417255 94.7674C0.417255 97.9885 4.03017 99.8885 6.68412 98.063L3.28382 93.1196C4.6108 92.2068 6.41726 93.1568 6.41726 94.7674L0.417255 94.7674ZM93.7505 75.385C93.7505 75.9373 93.3028 76.385 92.7505 76.385L92.7505 82.385C96.6165 82.385 99.7505 79.251 99.7505 75.385L93.7505 75.385Z"
        fill="black"
        mask="url(#path-1-outside-1)"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="50.0839"
          y1="-6.58096"
          x2="50.0839"
          y2="96.6689"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1EAE5A" />
          <stop offset="1" stopColor="#082E17" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default SvgCommentBadge;
