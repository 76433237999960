import React, { useState } from 'react';
import PropTypes from 'prop-types';
import EditTrackModal from 'components/organisms/EditTrackModal';

export const TrackContext = React.createContext({});

export const TrackProvider = ({ children }) => {
  const [editModal, setEditModal] = useState(false);
  const [, setDeleteModal] = useState(false);
  const [track, setTrack] = useState({});
  const [item, setItem] = useState({});
  const [type, setType] = useState('');

  const onClose = () => {
    setTrack({});
    setItem({});
    setType('');
  };

  const toggleEditTrack = (v = {}) => {
    if (editModal) {
      setEditModal(false);
      onClose();
    } else {
      setTrack(v);
      setEditModal(true);
    }
  };

  const toggleDeleteTrack = (v = {}) => {
    // if (deleteModal) {
    //   setDeleteModal(false);
    //   onClose();
    // } else {
    //   setTrack(v);
    //   setDeleteModal(true);
    // }
  };

  return (
    <TrackContext.Provider
      value={{
        setEditModal,
        setDeleteModal,
        toggleEditTrack,
        toggleDeleteTrack,
      }}
    >
      {children}
      <EditTrackModal isOpen={editModal} item={item} track={track} toggle={toggleEditTrack} type={type} />
    </TrackContext.Provider>
  );
};

TrackProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
