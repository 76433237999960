import PropTypes from 'prop-types';
import React from 'react';

function SvgExplicit({ fill, size, ...props }) {
  return (
    <svg
      width={size}
      height={size}
      x={0}
      y={0}
      viewBox="0 0 384 384"
      style={{
        enableBackground: 'new 0 0 512 512',
      }}
      xmlSpace="preserve"
      className=""
      {...props}
    >
      <g>
        <g xmlns="http://www.w3.org/2000/svg">
          <g>
            <path
              d="M341.333,0H42.667C19.093,0,0,19.093,0,42.667v298.667C0,364.907,19.093,384,42.667,384h298.667    C364.907,384,384,364.907,384,341.333V42.667C384,19.093,364.907,0,341.333,0z M256,128h-85.333v42.667H256v42.667h-85.333V256    H256v42.667H128V85.333h128V128z"
              fill="#565656"
              data-original="#000000"
              style={{}}
              className=""
            />
          </g>
        </g>
        <g xmlns="http://www.w3.org/2000/svg" />
        <g xmlns="http://www.w3.org/2000/svg" />
        <g xmlns="http://www.w3.org/2000/svg" />
        <g xmlns="http://www.w3.org/2000/svg" />
        <g xmlns="http://www.w3.org/2000/svg" />
        <g xmlns="http://www.w3.org/2000/svg" />
        <g xmlns="http://www.w3.org/2000/svg" />
        <g xmlns="http://www.w3.org/2000/svg" />
        <g xmlns="http://www.w3.org/2000/svg" />
        <g xmlns="http://www.w3.org/2000/svg" />
        <g xmlns="http://www.w3.org/2000/svg" />
        <g xmlns="http://www.w3.org/2000/svg" />
        <g xmlns="http://www.w3.org/2000/svg" />
        <g xmlns="http://www.w3.org/2000/svg" />
        <g xmlns="http://www.w3.org/2000/svg" />
      </g>
    </svg>
  );
}

SvgExplicit.propTypes = {
  fill: PropTypes.string,
  size: PropTypes.string,
};

SvgExplicit.defaultProps = {
  fill: '#fcb316',
  size: '1.2rem',
};

export default SvgExplicit;

// import PropTypes from 'prop-types';
// import React from 'react';

// function SvgExplicit({ fill, ...props }) {
//   return (
//     <svg width="37" height="12" viewBox="0 0 37 12" fill="none" xmlns="http://www.w3.org/2000/svg">
//       <rect x="1" y="1" width="35" height="10" rx="1" stroke="#868687" />
//       <path
//         d="M6.05173 7.298V6.098H8.03173V5.402H6.05173V4.262H8.22373V3.56H5.20573V8H8.27173V7.298H6.05173ZM12.7201 8L11.1061 5.702L12.6001 3.56H11.6221L10.6381 5.042L9.65408 3.56H8.67608L10.1701 5.702L8.56208 8H9.54008L10.6381 6.356L11.7421 8H12.7201ZM15.0272 3.56H13.2272V8H14.0732V6.488H14.9972C15.9512 6.488 16.5812 5.888 16.5812 4.994C16.5812 4.136 15.9632 3.56 15.0272 3.56ZM14.9372 5.786H14.0732V4.262H14.9372C15.4052 4.262 15.7112 4.568 15.7112 5.024C15.7112 5.48 15.4172 5.786 14.9372 5.786ZM18.122 7.298V3.56H17.276V8H20.084V7.298H18.122ZM20.7038 8H21.5498V3.56H20.7038V8ZM24.6961 8.06C25.5061 8.06 26.1541 7.748 26.5801 7.166L26.0101 6.656C25.7281 7.088 25.3141 7.358 24.7141 7.358C23.8201 7.358 23.2141 6.728 23.2141 5.774C23.2141 4.832 23.8081 4.202 24.7021 4.202C25.2301 4.202 25.6561 4.43 25.9081 4.754L26.4421 4.214C26.0041 3.74 25.4161 3.5 24.6901 3.5C23.2801 3.5 22.3381 4.406 22.3381 5.768C22.3381 7.142 23.2861 8.06 24.6961 8.06ZM27.2135 8H28.0595V3.56H27.2135V8ZM32.2318 3.56H28.6798V4.262H30.0298V8H30.8758V4.262H32.2318V3.56Z"
//         fill="#868687"
//       />
//     </svg>
//   );
// }

// SvgExplicit.propTypes = {
//   fill: PropTypes.string,
// };

// SvgExplicit.defaultProps = {
//   fill: '#fcb316',
// };

// export default SvgExplicit;
