import { useEffect, useState } from 'react';

import { useAlert } from 'hooks';

const NetworkDetector = () => {
  const [isDisconnected, setIsDisconnected] = useState(false);
  const [hasGoneOffline, setHasGoneOffline] = useState(false);
  const { addAlert } = useAlert();

  const handleConnectionChange = () => {
    const condition = navigator.onLine ? 'online' : 'offline';
    if (condition === 'online') {
      const webPing = setInterval(() => {
        fetch('//google.com', {
          mode: 'no-cors',
        })
          .then(() => {
            setIsDisconnected(false);

            return clearInterval(webPing);
          })
          .catch(() => {
            setIsDisconnected(true);
            if (!hasGoneOffline) setHasGoneOffline(true);
          });
      }, 2000);
      return;
    }

    setIsDisconnected(true);
    if (!hasGoneOffline) setHasGoneOffline(true);
  };

  useEffect(() => {
    handleConnectionChange();
    window.addEventListener('online', handleConnectionChange);
    window.addEventListener('offline', handleConnectionChange);

    return () => {
      window.removeEventListener('online', handleConnectionChange);
      window.removeEventListener('offline', handleConnectionChange);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isDisconnected) {
      setTimeout(() => {
        addAlert({
          message: "Opps! No network connection detected. We'll keep trying to reconnect...",
          type: 'error',
          dismissable: true,
          duration: 99999999999,
        });
      }, 0);
    } else if (hasGoneOffline) {
      setTimeout(() => {
        addAlert({
          message: 'Network connection restored!',
          type: 'success',
          dismissable: true,
          duration: 5000,
        });
      }, 0);
    }
    // eslint-disable-next-line
  }, [isDisconnected]);
  return null;
};

export default NetworkDetector;
