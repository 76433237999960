import * as t from '../types';

const INITIAL_STATE = {
  data: {},
  fetching: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case t.SEARCH_REQUEST:
      return { ...state, fetching: true };

    case t.SEARCH_SUCCESS:
      return { ...state, fetching: false, data: { ...state.data, [action.payload.query]: action.payload.data } };

    case t.SEARCH_FAILURE:
      return { ...state, fetching: false };
    case t.LOGOUT:
      return INITIAL_STATE;

    default:
      return { ...state };
  }
};
