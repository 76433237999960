import PropTypes from 'prop-types';
import React from 'react';

function SvgLyrics({ fill, ...props }) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g>
        <circle cx="12.1905" cy="18.8096" r="4.19048" stroke={fill} />
        <path d="M16.3809 18.2857V1" stroke={fill} strokeLinecap="round" strokeLinejoin="round" />
        <path
          d="M11.346 12H12.399L8.187 2.38H7.251L3.039 12H4.092L5.171 9.491H10.267L11.346 12ZM5.535 8.659L7.732 3.576L9.916 8.659H5.535Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="24" height="24" fill={fill} />
        </clipPath>
      </defs>
    </svg>
  );
}

SvgLyrics.propTypes = {
  fill: PropTypes.string,
};

SvgLyrics.defaultProps = {
  fill: '#fff',
};

export default SvgLyrics;
