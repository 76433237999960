import * as t from '../types';

const INITIAL_STATE = {
  byArtist: {},
  byUser: [],
  data: {},
  fetching: {},
  fetchingArtistAlbums: false,
  fetchingTracks: {},
  tracks: {},
  updating: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case t.GET_ARTIST_ALBUMS_REQUEST:
      return { ...state, fetchingArtistAlbums: true };
    case t.GET_ARTIST_ALBUMS_SUCCESS:
      return {
        ...state,
        byArtist: { ...state.byArtist, [action.payload.id]: action.payload.data },
        fetchingArtistAlbums: false,
      };
    case t.GET_ARTIST_ALBUMS_FAILURE:
      return { ...state, fetchingArtistAlbums: false };

    case t.GET_ALBUM_REQUEST:
      return { ...state, fetching: { ...state.fetching, [action.payload]: true } };

    case t.GET_ALBUM_SUCCESS:
      return {
        ...state,
        data: { ...state.data, [action.payload.id]: action.payload.data },
        fetching: { ...state.fetching, [action.payload.id]: false },
      };

    case t.GET_ALBUM_FAILURE:
      return { ...state, fetching: { ...state.fetching, [action.payload]: false } };

    case t.GET_ALBUM_TRACKS_REQUEST:
      return { ...state, fetchingTracks: { ...state.fetchingTracks, [action.payload]: true } };

    case t.GET_ALBUM_TRACKS_SUCCESS:
      return {
        ...state,
        fetchingTracks: { ...state.fetchingTracks, [action.payload.id]: false },
        tracks: { ...state.tracks, [action.payload.id]: action.payload.data },
      };

    case t.GET_ALBUM_TRACKS_FAILURE:
      return { ...state, fetchingTracks: { ...state.fetchingTracks, [action.payload]: false } };
    case t.GET_USER_ALBUMS_REQUEST:
      return { ...state, fetching: { ...state.fetching, byUser: true } };

    case t.GET_USER_ALBUMS_SUCCESS:
      return { ...state, fetching: { ...state.fetching, byUser: false }, byUser: action.payload };

    case t.GET_USER_ALBUMS_FAILURE:
      return { ...state, fetching: { ...state.fetching, byUser: false } };

    case t.UPDATE_ALBUM_REQUEST:
      return { ...state, updating: true };

    case t.UPDATE_ALBUM_SUCCESS:
      return {
        ...state,
        data: { ...state.data, [action.payload.id]: action.payload.data },
        updating: false,
      };
    case t.UPDATE_ALBUM_FAILURE:
      return { ...state, updating: false };

    case t.SORT_ALBUMS_BY_TITLE:
      return { ...state, byUser: action.payload };
    case t.SORT_ALBUMS_BY_ARTIST:
      return { ...state, byUser: action.payload };

    case t.LOGOUT:
      return INITIAL_STATE;
    default:
      return { ...state };
  }
};
