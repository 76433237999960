import React from 'react';
import styled from '@emotion/styled/macro';

const Container = styled.div`
  background: rgba(39, 39, 41, 0.8);
  height: 3rem;
  width: 4.5rem;
  display: flex;
  border-radius: 10px;
  position: relative;
  top: -4rem;
  left: -1.3rem;
  align-items: center;
  justify-content: center;
  color: #fff;
  @media (max-width: 706px) {
    display: none;
  }
`;

const Content = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  font-size: ${({ theme }) => theme.font.size.small};
  font-weight: ${({ theme }) => theme.font.size.thin};
`;

export const TimeBubble = ({ time }) => {
  return (
    <Container>
      <Content>{time}</Content>
    </Container>
  );
};
