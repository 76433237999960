import styled from '@emotion/styled';
import { Image } from 'components/atoms';
import { Box } from 'rebass';

export const Wrapper = styled.section``;
export const ShareStreamBox = styled.div`
  width: 100%;
  height: 90vh;
  padding: 5rem 2rem;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const ComponentTitle = styled.p`
  text-align: center;
  font-size: 2rem;
  font-weight: 700;
`;

export const LiveStreamCover = styled.div`
  // padding: 2rem;
  margin: 3rem 0 2rem 0;
`;
export const CoverImage = styled(Image)`
  width: 100%;
  height: 250px;
  object-fix: cover;
  margin-bottom: 1rem;
`;
export const LiveStreamDetails = styled.div`
  p,
  span {
    font-size: 17px;
    font-weight: 500;
    font-style: normal;
    line-height: 20.4px;
    text-transform: uppercase;
  }
  span {
    opacity: 0.5;
    display: block;
    margin: 1rem 0;
  }
`;

export const ButtonsBox = styled(Box)`
  display: flex;
  flex-direction: column;
`;

export const SocialButton = styled.button`
  color: white;
  border: none;
  border-radius: 2rem;
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  width: 100%;
  font-weight: 600;
  margin: 0 0 1rem 0;
  cursor: pointer;
  ${({ color }) => `
background-color: ${color}`}
`;

export const CopyFeedback = styled.span`
  font-size: 10px;
  color: green;
  margin-left: 3rem;
`;
