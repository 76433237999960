import styled from '@emotion/styled';
import { Button } from 'components/atoms';

export const BadgeWrapper = styled.section``;

export const Title = styled.p`
  text-align: center;
  font-size: 2rem;
  font-weight: 700;
`;
export const BadgeMessage = styled.p`
  text-align: center;
  margin: 2rem auto;
  width: 40%;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
`;
export const ActionButton = styled(Button)`
  width: 100%;
  display: block;
  margin: 3rem auto;
  color: white;
`;

export const PriceWon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2rem 0;

  p {
    margin-left: 1rem;
    font-size: 2.5rem;
    font-weight: 700;
    background: -webkit-linear-gradient(#fdb913, #f2994a);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;
export const BadgeIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 13rem;
  width: 13rem;
  margin: 0 auto;
  margin-top: 12rem;

  svg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  svg:nth-child(2) {
    margin-left: 0.3rem;
  }
`;
export const BadgeValue = styled.p`
  z-index: 6;
  font-size: 3rem;
  font-weight: 600;
  background: -webkit-linear-gradient(#fdb913, #f2994a);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;
