import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTimer } from 'hooks';
import { ItemWrapper, ItemText } from './styled';
import { ExclamIcon, InfoIcon, SuccessIcon, TimesIcon } from 'components/icons';
import { Box } from 'rebass';
import styled from '@emotion/styled/macro';

const CloseButton = styled(TimesIcon)`
  &:hover {
    background: #ffffffe6;
    padding: 0.1rem;
    border-radius: 6px;
    transition: background 0.8s ease-in-out;
  }
`;

const colorMap = {
  default: 'dark',
  error: 'alert-red',
  info: 'alert-info',
  primary: 'primary',
  secondary: 'secondary',
  success: 'alert-green',
};

export const Item = ({ text, options, remove }) => {
  const { autoClose, type, closeOnClick } = options;
  const node = useRef();
  const removeRef = useRef();
  removeRef.current = remove;

  const Timer = useTimer(autoClose, () => {
    if (autoClose) {
      removeRef.current();
    }
  });

  useEffect(() => {
    if (Timer) {
      Timer.start();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const { current } = node;
    const onEnter = () => {
      if (Timer) {
        Timer.pause();
      }
    };
    const onLeave = () => {
      if (Timer) {
        Timer.start();
      }
    };
    current.addEventListener('mouseenter', onEnter);
    current.addEventListener('mouseleave', onLeave);

    return () => {
      current.removeEventListener('mouseenter', onEnter);
      current.removeEventListener('mouseleave', onLeave);
    };
    // eslint-disable-next-line
  }, []);

  const handleWrapperClick = () => {
    if (closeOnClick) {
      remove();
    }
  };

  const renderIcon = () => {
    switch (type) {
      case 'success':
        return <SuccessIcon fill="#23825F" />;
      case 'error':
        return <ExclamIcon fill="#D13A06" />;
      case 'info':
        return <InfoIcon fill="#026DD6" />;
      default:
        return <InfoIcon fill="#026DD6" />;
    }
  };
  const renderColor = () => {
    switch (type) {
      case 'success':
        return '#23825F';
      case 'error':
        return '#D13A06';
      case 'info':
        return '#026DD6';
      default:
        return '#026DD6';
    }
  };

  return (
    <ItemWrapper ref={node} color={colorMap[type]} onClick={handleWrapperClick}>
      <Box mr="1rem">{renderIcon()}</Box>
      <ItemText colorShade={renderColor}>{text}</ItemText>
      <CloseButton onClick={remove} size="2.5rem" fill={renderColor} />
    </ItemWrapper>
  );
};

Item.propTypes = {
  remove: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  options: PropTypes.shape({
    autoClose: PropTypes.number,
    closeOnClick: PropTypes.bool,
    pauseOnHover: PropTypes.bool,
    type: PropTypes.string,
  }).isRequired,
};
