import React from 'react';
import PropTypes from 'prop-types';

function SvgShare({ fill, ...props }) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 560.257 614.73" {...props}>
      <g
        data-name="Group 1848"
        transform="translate(35.001 35)"
        strokeWidth={70}
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      >
        <circle data-name="Ellipse 58" cx={81.71} cy={81.71} r={81.71} transform="translate(326.838)" />
        <circle data-name="Ellipse 59" cx={81.71} cy={81.71} r={81.71} transform="translate(0 190.656)" />
        <circle data-name="Ellipse 60" cx={81.71} cy={81.71} r={81.71} transform="translate(326.838 381.311)" />
        <path data-name="Line 40" d="M152.252 313.492l186.025 108.401" />
        <path data-name="Line 41" d="M338.005 122.837L152.252 231.238" />
      </g>
    </svg>
  );
}

SvgShare.propTypes = {
  fill: PropTypes.string,
};

SvgShare.defaultProps = {
  fill: '#fff',
};

export default SvgShare;
