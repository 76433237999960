import React from 'react';
import styled from '@emotion/styled/macro';
import { Button } from './Button';
import { goToPayment } from 'lib/utils';
import { useMedia } from 'react-use';

const AlertContainer = styled.div`
  background: linear-gradient(270.02deg, rgba(255, 219, 132, 0.5) 0.02%, rgba(245, 190, 141, 0.5) 99.98%);
  backdrop-filter: blur(30px);
  width: 100%;
`;

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
`;

const Text = styled.div`
  color: ${({ theme }) => theme.colors.switchingText};
  font-size: ${props => props.theme.font.size.small};
  ${({ theme }) => theme.mq.md`
    font-size: ${theme.font.size.normal} !important;
  `}
`;

export const SubscribeAlert = () => {
  const isWide = useMedia('(max-width: 400px)');
  return (
    <AlertContainer>
      <Content>
        <Text>Get access to all premium content </Text>
        <Button
          aria-label="subscribe-button"
          size={isWide ? 'small' : 'normal'}
          effect
          onClick={goToPayment}
          style={{ color: 'white', background: 'linear-gradient(270.02deg, #FDB913 0.02%, #F2994A 99.98%)' }}
        >
          Subscribe
        </Button>
      </Content>
    </AlertContainer>
  );
};
