import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from 'components/atoms';
import { Modal, Upload } from 'components/molecules';

const DeletePlaylistModal = ({ isOpen, toggle, item, type }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg" showCloseIcon>
      <Modal.Header justifyContent="center">
        <Typography.Paragraph>Share</Typography.Paragraph>
      </Modal.Header>
      <Modal.Body style={{ padding: 0 }}>
        <Upload.Share {...item} content={type} />
      </Modal.Body>
    </Modal>
  );
};

DeletePlaylistModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  item: PropTypes.object.isRequired,
  toggle: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};

export default DeletePlaylistModal;
