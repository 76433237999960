import { Box } from 'rebass';
import { Image } from 'components/atoms';
import { MainSeekbar } from 'components/molecules/MainSeekbar';
import { Subtitle } from 'components/molecules/Tile/styled';
import { Typography } from 'components/atoms';
import { animated } from 'react-spring';
import { css } from '@emotion/core';
import styled from '@emotion/styled/macro';

export const Wrapper = styled(animated.div)`
  border-radius: 0.5rem;
  bottom: 9rem;
  box-shadow: 0 0 5px rgba(255, 255, 255, 0.1);
  flex-direction: column;
  padding: 3rem 4rem 3rem 4rem;
  position: fixed;
  right: 0;
  transition: all 0.2s;
  z-index: 15;
  overflow: auto;
  opacity: 1;
  justify-content: center;
  width: 100%;
  transform: translateX(0%);
  width: 100vw;
  height: 100vh;
  display: flex;
  flex: 1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  @media (max-width: 480px) {
    padding: 5rem 1rem 1rem 1rem;
  }
`;

export const Title = styled(Typography.Paragraph)`
  color: ${({ theme }) => theme.colors.switchingText};
  font-size: ${({ theme }) => theme.font.size.small};
  text-decoration: none;
  transition: all 0.1s;
  display: flex;
  align-items: baseline;
  font-weight: 500;
  svg {
    margin-left: 1rem;
  }
  &:hover {
    text-decoration: underline;
  }
`;

export const Artwork = styled(Image)`
  flex-shrink: 0;
  height: 4.8rem;
  width: 4.8rem;
`;
export const Artist = styled(Subtitle)`
  margin-top: 0rem;
  font-weight: 300;
  font-size: 1.3rem;
  margin-top: 1rem;
  color: ${({ titleColor }) => titleColor};
  opacity: 0.5;
  :hover {
    text-decoration: underline;
  }
`;

export const Header = styled(Box)`
  align-items: center;
  justify-content: space-between;
  margin-bottom: 6rem;
  button {
    padding: 1rem 3rem;

    span {
      margin-right: 0 !important;
    }

    &:not(:last-child) {
      margin-right: 1rem;
    }
  }
`;

export const Body = styled(Box)`
  flex-direction: column;
  height: 100%;
  overflow-y: scroll;
`;

export const ItemArtwork = styled(Image)`
  border-radius: 0.5rem;
  flex-shrink: 0;
  height: 3rem;
  width: 3rem;
  img {
    margin-left: 0;
  }
`;

export const ItemWrapper = styled(Box)`
  align-items: center;
  cursor: pointer;
  flex-shrink: 0;
  padding: 1rem 0;
  position: relative;
  width: 100%;

  ${({ prev }) =>
    prev &&
    css`
      opacity: 0.5;
    `}

  ${({ active, theme }) =>
    active &&
    css`
      background-color: ${theme.colors['dark-two']}50;
    `}

  &:after {
    background-color: ${({ theme }) => theme.colors['brown-grey-two']};
    bottom: 0;
    content: '';
    height: 1px;
    opacity: 0.2;
    position: absolute;
    width: 100%;
  }
`;

export const Close = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: rgba(39, 39, 41, 0.8);
  border-color: none;
  padding-top: 1.2rem;
  padding-left: 0.9rem;
  align-text: center;
  border: none;
  cursor: pointer;
  margin-right: 1rem;
  @media (max-width: 480px) {
    width: 30px;
    height: 30px;
    padding-top: 0.7rem;
    padding-left: 0.8rem;
    svg {
      width: 1.3rem;
    }
  }
`;

export const PlayerContainer = styled.div`
  width: 40rem;
  flex: 1;
  @media (max-width: 950px) {
    width: 70%;
    flex-direction: column;
    display: flex;
    margin-left: 0rem;
  }
`;

export const PlayerArtwork = styled(Image)`
  border-radius: 0.5rem;
  box-shadow: 0 2px 30px rgba(0, 0, 0, 0.15);
  flex-shrink: 0;
  height: unset;
  margin-bottom: 2rem;
  object-fit: cover;
  width: 100%;
  align-self: center;
  img {
    box-shadow: none;
    width: 100% !important;
    height: 100% !important;
  }

  // ${({ theme }) => theme.mq.sm`
  //   // margin-right: 2rem;
  //   width: 20rem;
  //   height: 20rem;
  //   margin: 2.5rem auto;
  // `}

  // ${({ theme }) => theme.mq.md`
  //   height: 40rem;
  //   width: 40rem;
  //   margin: 2.5rem auto;

  //   img {
  //     margin-top: 0;
  //   }

  // `}
`;

export const TrackDetails = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const Seeker = styled(MainSeekbar)`
  flex-direction: column;
  flex-shrink: 0;
  margin: 0 auto;
  padding: 0 2rem;
  width: 100%;
`;

export const ControlWrapper = styled(Box)`
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  width: 100%;
  .xs-hide {
    display: none;

    ${({ theme }) => theme.mq.sm`
    display: block;
      `}
  }

  > svg {
    cursor: pointer;
    font-size: 1.5rem;
    &:not(:last-child) {
      margin-right: 2rem;
      ${({ theme }) => theme.mq.sm`
      margin-right: 3rem;
      `}
    }
    ${({ theme }) => theme.mq.sm`
    font-size: 2.5rem;
    `}
  }

  .play {
    &:hover {
      transform: scale(1.4);
      transition-duration: 0.15s;
    }
  }
  .pause {
    &:hover {
      transform: scale(1.4);
      transition-duration: 0.15s;
    }
  }
  .next {
    &:hover {
      transform: scale(1.4);
      transition-duration: 0.15s;
    }
  }
  .prev {
    &:hover {
      transform: scale(1.4);
      transition-duration: 0.15s;
    }
  }
  .shuffle {
    &:hover {
      transform: scale(1.4);
      transition-duration: 0.15s;
    }
  }
  .repeat {
    &:hover {
      transform: scale(1.4);
      transition-duration: 0.15s;
    }
  }
`;

export const RightSide = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 6rem;
  flex: 2;
  @media (max-width: 950px) {
    margin-top: 2rem;
  }
`;

export const TabHead = styled(Box)`
  align-items: center;
  padding-bottom: 0;
  // padding: 1.5rem 2rem;
  position: relative;

  h1,
  h2,
  h3,
  p {
    color: ${({ theme }) => theme.colors.switchingText};
  }

  // ${({ theme }) => theme.mq.md`
  // padding: 0rem 3.5rem 2rem;
  // `}
`;

export const TabTitle = styled.div`
  display: flex;
  align-items: center;
  border-radius: 1.4rem;
  padding: 0.9rem 1rem;
  justify-content: center;
  width: 17rem;
  font-weight: 600;
  @media (max-width: 480px) {
    padding: 0.5rem 0.3rem;
    width: 13rem;
    font-size: 1rem;
    svg {
      width: 1.3rem;
    }
  }
`;

export const NowPlaying = styled.div`
  color: #fff;
  font-size: ${({ theme }) => theme.font.size.larger};
  font-weight: 600;
  @media (max-width: 950px) {
    font-size: ${({ theme }) => theme.font.size.normal};
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 202px repeat(2, 200px) 208px;
  grid-gap: 1rem;
`;

export const InnerWrapper = styled.div`
  display: flex;
  width: 100%;
  @media (max-width: 950px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const Next = styled.div`
  padding: 0.9rem 2rem;
  margin-bottom: 2rem;
  // margin-left: 8rem;
  color: #fff;
  font-size: ${({ theme }) => theme.font.size.large};
  font-weight: 600;
  @media (max-width: 950px) {
    // margin-left: 5rem;
    font-size: ${({ theme }) => theme.font.size.normal};
  }
`;
