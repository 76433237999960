import api from 'services/api';
import { filterPlainArray, sortArrFromObjIndex } from 'lib/utils';
import * as t from '../types';

export const getUserPlaylistsAction = (callback = () => {}) => async dispatch => {
  try {
    dispatch({ type: t.GET_USER_PLAYLISTS_REQUEST });
    const resp = await api.getUserPlaylists();
    if (resp.status === 200 || resp.status === 201) {
      dispatch({ type: t.GET_USER_PLAYLISTS_SUCCESS, payload: resp.data });
      callback(true, null);
    } else {
      throw resp.data;
    }
  } catch (e) {
    callback(null, e);
    dispatch({ type: t.GET_USER_PLAYLISTS_FAILURE });
  }
};

export const createPlaylistAction = (data, poster, callback = () => {}) => async dispatch => {
  try {
    dispatch({ type: t.EDIT_OR_CREATE_REQUEST });
    const resp = await api.createPlaylist({
      name: data.name,
      description: data.description,
      public: data.public,
    });
    if (resp.status !== 200) {
      throw resp.data;
    }
    if (poster) {
      await api.uploadPhoto(poster, resp.data.id, 'playlists');
      // if (upload.status !== 200) {
      //   throw upload.data;
      // }
    }

    dispatch(getUserPlaylistsAction());
    callback(resp.data, null);
    dispatch({ type: t.EDIT_OR_CREATE_SUCCESS });
  } catch (e) {
    callback(null, e);
    dispatch({ type: t.EDIT_OR_CREATE_FAILURE });
  }
};

export const deletePlaylistAction = (id, callback = () => {}) => async (dispatch, getState) => {
  try {
    dispatch({ type: t.DELETE_PLAYLIST_REQUEST });
    const resp = await api.deletePlaylist(id);
    if (resp.status === 200 || resp.status === 201) {
      let userPlaylists = getState().playlist.byUser.user || [];
      userPlaylists = userPlaylists.filter(x => x.id !== id);
      dispatch({ type: t.DELETE_PLAYLIST_SUCCESS, payload: userPlaylists });
      callback(true, null);
    } else {
      throw resp.data;
    }
  } catch (e) {
    callback(null, e);
    dispatch({ type: t.DELETE_PLAYLIST_FAILURE });
  }
};

export const editPlaylistAction = (data, poster, callback = () => {}) => async dispatch => {
  try {
    dispatch({ type: t.EDIT_OR_CREATE_REQUEST });
    const resp = await api.editPlaylist(data.id, {
      name: data.name,
      description: data.description,
      public: data.public,
    });
    if (resp.status !== 200) {
      throw resp.data;
    }
    if (poster) {
      const upload = await api.uploadPhoto(poster, resp.data.id, 'playlists');
      if (upload.status !== 200) {
        throw upload.data;
      }
    }
    dispatch(getUserPlaylistsAction());
    callback(resp.data, null);
    dispatch({ type: t.EDIT_OR_CREATE_SUCCESS });
  } catch (e) {
    callback(null, e);
    dispatch({ type: t.EDIT_OR_CREATE_FAILURE });
  }
};

export const getArtistPlaylistsAction = id => async dispatch => {
  try {
    dispatch({ type: t.GET_ARTIST_PLAYLISTS_REQUEST });
    const resp = await api.getArtistRelatedPlaylist(id);
    if (resp.status === 200 || resp.status === 201) {
      dispatch({
        type: t.GET_ARTIST_PLAYLISTS_SUCCESS,
        payload: { id, data: resp.data },
      });
    } else {
      throw resp.data;
    }
  } catch (e) {
    dispatch({ type: t.GET_ARTIST_PLAYLISTS_FAILURE });
  }
};

export const getPlaylistAction = (id, callback = () => {}) => async dispatch => {
  try {
    dispatch({ type: t.GET_PLAYLIST_REQUEST, payload: id });
    const resp = await api.getPlaylist(id);
    if (resp.status === 200 || resp.status === 201) {
      dispatch({
        type: t.GET_PLAYLIST_SUCCESS,
        payload: { id, data: resp.data },
      });
      callback(true, null);
    } else {
      throw resp.data;
    }
  } catch (e) {
    callback(null, e);
    dispatch({ type: t.GET_PLAYLIST_FAILURE, payload: id });
  }
};

// export const addAlbumToPlaylistAction = (id, type, playlist, callback = () => {}) => async dispatch => {
//   try {
//     dispatch({ type: t.ADD_TO_PLAYLIST_REQUEST, payload: id });
//     const resp = await api.albumToPlaylist(playlist, {
//       [type]: id,
//     });
//     if (resp.status === 200 || resp.status === 201) {
//       dispatch({ type: t.ADD_TO_PLAYLIST_SUCCESS, payload: id });
//       callback(resp.data, null);
//     } else {
//       throw resp.data;
//     }
//   } catch (e) {
//     dispatch({ type: t.ADD_TO_PLAYLIST_FAILURE, payload: id });
//     callback(null, e);
//   }
// };

export const getPlaylistsAction = (callback = () => {}) => async dispatch => {
  try {
    dispatch({ type: t.GET_PLAYLISTS_REQUEST });
    const resp = await api.getPlaylists();
    if (resp.status === 200 || resp.status === 201) {
      dispatch({ type: t.GET_PLAYLISTS_SUCCESS, payload: resp.data });
      callback(true, null);
    } else {
      throw resp.data;
    }
  } catch (e) {
    callback(null, e);
    dispatch({ type: t.GET_PLAYLISTS_FAILURE });
  }
};

export const getPlaylistTracksAction = (id, callback = () => {}) => async dispatch => {
  try {
    dispatch({ type: t.GET_PLAYLIST_TRACKS_REQUEST, payload: id });
    const resp = await api.getPlaylistTracks(id);
    if (resp.status === 200 || resp.status === 201) {
      dispatch({
        type: t.GET_PLAYLIST_TRACKS_SUCCESS,
        payload: { id, data: resp.data },
      });
      callback(resp.data, null);
    } else {
      throw resp.data;
    }
  } catch (e) {
    callback(null, e);
    dispatch({ type: t.GET_PLAYLIST_TRACKS_FAILURE, payload: id });
  }
};

export const addToPlaylistAction = (id, type, playlist, callback = () => {}) => async dispatch => {
  try {
    dispatch({ type: t.ADD_TO_PLAYLIST_REQUEST, payload: id });
    const resp = await api.modifyPlaylist(playlist, {
      [type]: id,
    });
    if (resp.status === 200 || resp.status === 201) {
      dispatch({ type: t.ADD_TO_PLAYLIST_SUCCESS, payload: id });
      callback(resp.data, null);
    } else {
      throw resp.data;
    }
  } catch (e) {
    dispatch({ type: t.ADD_TO_PLAYLIST_FAILURE, payload: id });
    callback(null, e);
  }
};

export const bulkToPlaylistAction = (id, playlist, callback = () => {}) => async dispatch => {
  try {
    dispatch({ type: t.ADD_TO_PLAYLIST_REQUEST, payload: id });
    const resp = await api.bulkToPlaylist(playlist, id);
    if (resp.status === 200 || resp.status === 201) {
      dispatch({ type: t.ADD_TO_PLAYLIST_SUCCESS, payload: id });
      callback(resp.data, null);
    } else {
      throw resp.data;
    }
  } catch (e) {
    dispatch({ type: t.ADD_TO_PLAYLIST_FAILURE, payload: id });
    callback(null, e);
  }
};

export const removeTracksFromPlaylistAction = (tracks, id, callback = () => {}) => async (dispatch, getState) => {
  try {
    const allTracks = getState().playlist.tracks[id] || [];
    dispatch({ type: t.REMOVE_PLAYLIST_TRACKS_REQUEST, payload: id });
    const resp = await api.deleteTrackFromPlaylist(id, {
      remove_tracks: tracks,
    });
    if (resp.status === 200 || resp.status === 201) {
      dispatch({
        type: t.REMOVE_PLAYLIST_TRACKS_SUCCESS,
        payload: { id, tracks: filterPlainArray(allTracks, 'id', tracks) },
      });
      callback(resp.data, null);
    } else {
      throw resp.data;
    }
  } catch (e) {
    dispatch({ type: t.REMOVE_PLAYLIST_TRACKS_FAILURE, payload: id });
    callback(null, e);
  }
};

export const orderPlaylistTracksAction = (tracks, id, callback = () => {}) => async (dispatch, getState) => {
  try {
    const allTracks = getState().playlist.tracks[id] || [];
    dispatch({ type: t.ORDER_PLAYLIST_TRACKS_REQUEST, payload: id });
    const resp = await api.deleteTrackFromPlaylist(id, {
      order: tracks,
    });
    if (resp.status === 200 || resp.status === 201) {
      dispatch({
        type: t.ORDER_PLAYLIST_TRACKS_SUCCESS,
        payload: { id, tracks: sortArrFromObjIndex(allTracks, tracks) },
      });
      callback(resp.data, null);
    } else {
      throw resp.data;
    }
  } catch (e) {
    dispatch({ type: t.ORDER_PLAYLIST_TRACKS_FAILURE, payload: id });
    callback(null, e);
  }
};

export const getPlaylistArtistsAction = (id, callback = () => {}) => async dispatch => {
  try {
    dispatch({ type: t.GET_PLAYLIST_ARTIST_SUCCESS, payload: id });
    const resp = await api.getPlaylistArtists(id);
    if (resp.status === 200 || resp.status === 201) {
      dispatch({ type: t.GET_PLAYLIST_ARTIST_SUCCESS, payload: { id, data: resp.data } });
      callback(resp.data, null);
    } else {
      throw resp.data;
    }
  } catch (e) {
    callback(null, e);
    dispatch({ type: t.GET_PLAYLIST_ARTIST_FAILURE, payload: id });
  }
};
