import React from 'react';
import PropTypes from 'prop-types';

function SvgComment({ fill, ...props }) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 13.662 11.48" {...props}>
      <path
        data-name="Union 4"
        d="M8.543 10.392q-.239.054-.483.093a8.547 8.547 0 01-2.7 0 8.055 8.055 0 01-1.26-.309 7.43 7.43 0 01-1.14-.488 6.7 6.7 0 01-.993-.647 5.861 5.861 0 01-.819-.784 5.08 5.08 0 01-.618-.9 4.514 4.514 0 01-.391-.994 4.249 4.249 0 010-2.135 4.507 4.507 0 01.391-.994 5.065 5.065 0 01.618-.9 5.841 5.841 0 01.819-.784A6.674 6.674 0 012.958.9 7.4 7.4 0 014.1.416 8.016 8.016 0 015.357.108a8.554 8.554 0 012.7 0A8.024 8.024 0 019.32.416 7.416 7.416 0 0110.459.9a6.683 6.683 0 01.993.647 5.831 5.831 0 01.819.784 5.068 5.068 0 01.618.9 4.5 4.5 0 01.391.994 4.248 4.248 0 010 2.135 4.5 4.5 0 01-.391.994 5.081 5.081 0 01-.618.9l-.075.085 1.465 3.137s-3.538-.232-5.118-1.084zM3.958 7.211a.342.342 0 00-.2.2.439.439 0 00-.028.158.365.365 0 00.386.386H8.75a.442.442 0 00.158-.028.342.342 0 00.2-.2.464.464 0 000-.316.342.342 0 00-.2-.2.438.438 0 00-.158-.028H4.116a.44.44 0 00-.158.028zm0-1.931a.342.342 0 00-.2.2.438.438 0 00-.028.158.365.365 0 00.386.386H8.75A.443.443 0 008.908 6a.342.342 0 00.2-.2.464.464 0 000-.316.343.343 0 00-.2-.2.44.44 0 00-.158-.028H4.116a.44.44 0 00-.158.024zm-.173-1.852a.619.619 0 00-.055.28.619.619 0 00.055.28.168.168 0 00.146.106h2.414a.168.168 0 00.146-.106.619.619 0 00.055-.28.619.619 0 00-.055-.28.167.167 0 00-.146-.106H3.931a.168.168 0 00-.146.106z"
        fill={fill}
      />
    </svg>
  );
}

SvgComment.propTypes = {
  fill: PropTypes.string,
};

SvgComment.defaultProps = {
  fill: '#ffa700',
};

export default SvgComment;
