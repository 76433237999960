import React from 'react';
import PropTypes from 'prop-types';

function SvgSee({ fill, ...props }) {
  return (
    <svg width="80%" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.02879 21.4926C2.02879 21.351 2.09847 21.0337 2.3398 20.5262C2.57021 20.0417 2.9215 19.457 3.39045 18.8063C4.32746 17.5063 5.69766 15.9936 7.40097 14.5556C10.8202 11.6689 15.4751 9.17027 20.551 9.17027C25.627 9.17027 30.2819 11.6689 33.7011 14.5556C35.4044 15.9936 36.7746 17.5063 37.7116 18.8063C38.1806 19.457 38.5319 20.0417 38.7623 20.5262C39.0036 21.0337 39.0733 21.351 39.0733 21.4926C39.0733 21.6343 39.0036 21.9516 38.7623 22.4591C38.5319 22.9436 38.1806 23.5283 37.7116 24.1789C36.7746 25.479 35.4044 26.9917 33.7011 28.4297C30.2819 31.3163 25.627 33.815 20.551 33.815C15.4751 33.815 10.8202 31.3163 7.40097 28.4297C5.69766 26.9917 4.32746 25.479 3.39045 24.1789C2.9215 23.5283 2.57021 22.9436 2.3398 22.4591C2.09847 21.9516 2.02879 21.6343 2.02879 21.4926ZM20.551 7.46545C14.9266 7.46545 9.89426 10.2195 6.30121 13.2529C4.4984 14.7749 3.03124 16.3891 2.00743 17.8095C1.49597 18.5192 1.08598 19.1931 0.800208 19.7941C0.525366 20.372 0.323975 20.967 0.323975 21.4926C0.323975 22.0183 0.525366 22.6132 0.800208 23.1912C1.08598 23.7921 1.49597 24.4661 2.00743 25.1757C3.03124 26.5962 4.4984 28.2103 6.30121 29.7324C9.89426 32.7658 14.9266 35.5198 20.551 35.5198C26.1755 35.5198 31.2078 32.7658 34.8009 29.7324C36.6037 28.2103 38.0709 26.5962 39.0947 25.1757C39.6061 24.4661 40.0161 23.7921 40.3019 23.1912C40.5767 22.6132 40.7781 22.0183 40.7781 21.4926C40.7781 20.967 40.5767 20.372 40.3019 19.7941C40.0161 19.1931 39.6061 18.5192 39.0947 17.8095C38.0709 16.3891 36.6037 14.7749 34.8009 13.2529C31.2078 10.2195 26.1755 7.46545 20.551 7.46545ZM12.8784 21.4932C12.8784 17.2558 16.3135 13.8208 20.5509 13.8208C24.7882 13.8208 28.2233 17.2558 28.2233 21.4932C28.2233 25.7306 24.7882 29.1657 20.5509 29.1657C16.3135 29.1657 12.8784 25.7306 12.8784 21.4932ZM20.5509 12.116C15.3719 12.116 11.1736 16.3143 11.1736 21.4932C11.1736 26.6721 15.3719 30.8705 20.5509 30.8705C25.7298 30.8705 29.9281 26.6721 29.9281 21.4932C29.9281 16.3143 25.7298 12.116 20.5509 12.116Z"
        fill="white"
      />
    </svg>
  );
}

SvgSee.defaultProps = {
  fill: '#fff',
};

SvgSee.propTypes = {
  fill: PropTypes.string,
};

export default SvgSee;
