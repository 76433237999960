import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'components/molecules';
import styled from '@emotion/styled';
import ExternalPaymentHandler from './ExternalPaymentHandler';

const Wrapper = styled.section`
  width: 100%;
`;

const MakePaymentModal = memo(({ close, isOpen, toggle }) => {
  return (
    <Modal isOpen={isOpen} close={close} toggle={toggle} size="md" showCloseIcon>
      <Modal.Body>
        <Wrapper>
          <ExternalPaymentHandler />
        </Wrapper>
      </Modal.Body>
    </Modal>
  );
});

MakePaymentModal.propTypes = {
  close: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
};

export default MakePaymentModal;
