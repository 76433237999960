import * as t from '../types';

const INITIAL_STATE = {
  creating: {},
  data: {},
  fetching: {},
  liking: {},
  replying: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case t.CREATE_COMMENT_REQUEST:
      return { ...state, creating: { ...state.creating, [action.payload.id]: true } };

    case t.CREATE_COMMENT_SUCCESS:
      return {
        ...state,
        creating: { ...state.creating, [action.payload.id]: false },
        data: { ...state.data, [action.payload.id]: action.payload.data },
      };

    case t.CREATE_COMMENT_FAILURE:
      return { ...state, creating: { ...state.creating, [action.payload.id]: false } };

    case t.LIKE_COMMENT_REQUEST:
    case t.DISLIKE_COMMENT_REQUEST:
      return {
        ...state,
        data: { ...state.data, [action.payload.parentId]: action.payload.data },
        liking: { ...state.liking, [action.payload.id]: true },
      };

    case t.LIKE_COMMENT_SUCCESS:
    case t.DISLIKE_COMMENT_SUCCESS:
      return {
        ...state,
        data: { ...state.data, [action.payload.parentId]: action.payload.data },
        liking: { ...state.liking, [action.payload.id]: false },
      };

    case t.LIKE_COMMENT_FAILURE:
    case t.DISLIKE_COMMENT_FAILURE:
      return {
        ...state,
        data: { ...state.data, [action.payload.parentId]: action.payload.data },
        liking: { ...state.liking, [action.payload.id]: false },
      };

    case t.REPLY_COMMENT_REQUEST:
      return { ...state, replying: { ...state.replying, [action.payload.id]: true } };

    case t.REPLY_COMMENT_SUCCESS:
      return {
        ...state,
        data: { ...state.data, [action.payload.id]: action.payload.data },
        replying: { ...state.replying, [action.payload.id]: false },
      };

    case t.REPLY_COMMENT_FAILURE:
      return { ...state, replying: { ...state.replying, [action.payload.id]: false } };

    case t.GET_COMMENTS_REQUEST:
    case t.LOAD_MORE_COMMENTS_REQUEST:
      return { ...state, fetching: { ...state.fetching, [action.payload.id]: true } };

    case t.GET_COMMENTS_SUCCESS:
    case t.LOAD_MORE_COMMENTS_SUCCESS:
      return {
        ...state,
        data: { ...state.data, [action.payload.id]: action.payload.data },
        fetching: { ...state.fetching, [action.payload.id]: false },
      };

    case t.GET_COMMENTS_FAILURE:
    case t.LOAD_MORE_COMMENTS_FAILURE:
      return { ...state, fetching: { ...state.fetching, [action.payload.id]: false } };

    case t.LOGOUT:
      return INITIAL_STATE;

    default:
      return { ...state };
  }
};
