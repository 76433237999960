export const GET_ARTIST_PLAYLISTS_REQUEST = 'GET_ARTIST_PLAYLISTS_REQUEST';
export const GET_ARTIST_PLAYLISTS_SUCCESS = 'GET_ARTIST_PLAYLISTS_SUCCESS';
export const GET_ARTIST_PLAYLISTS_FAILURE = 'GET_ARTIST_PLAYLISTS_FAILURE';

export const GET_PLAYLIST_REQUEST = 'GET_PLAYLIST_REQUEST';
export const GET_PLAYLIST_SUCCESS = 'GET_PLAYLIST_SUCCESS';
export const GET_PLAYLIST_FAILURE = 'GET_PLAYLIST_FAILURE';

export const GET_PLAYLIST_ARTIST_REQUEST = 'GET_PLAYLIST_ARTYIST_REQUEST';
export const GET_PLAYLIST_ARTIST_SUCCESS = 'GET_PLAYLIST_ARTYIST_SUCCESS';
export const GET_PLAYLIST_ARTIST_FAILURE = 'GET_PLAYLIST_ARTYIST_FAILURE';

export const GET_PLAYLISTS_REQUEST = 'GET_PLAYLISTS_REQUEST';
export const GET_PLAYLISTS_SUCCESS = 'GET_PLAYLISTS_SUCCESS';
export const GET_PLAYLISTS_FAILURE = 'GET_PLAYLISTS_FAILURE';

export const GET_PLAYLIST_TRACKS_REQUEST = 'GET_PLAYLIST_TRACKS_REQUEST';
export const GET_PLAYLIST_TRACKS_SUCCESS = 'GET_PLAYLIST_TRACKS_SUCCESS';
export const GET_PLAYLIST_TRACKS_FAILURE = 'GET_PLAYLIST_TRACKS_FAILURE';

export const GET_USER_PLAYLISTS_REQUEST = 'GET_USER_PLAYLISTS_REQUEST';
export const GET_USER_PLAYLISTS_SUCCESS = 'GET_USER_PLAYLISTS_SUCCESS';
export const GET_USER_PLAYLISTS_FAILURE = 'GET_USER_PLAYLISTS_FAILURE';

export const EDIT_OR_CREATE_REQUEST = 'EDIT_OR_CREATE_REQUEST';
export const EDIT_OR_CREATE_SUCCESS = 'EDIT_OR_CREATE_SUCCESS';
export const EDIT_OR_CREATE_FAILURE = 'EDIT_OR_CREATE_FAILURE';

export const DELETE_PLAYLIST_REQUEST = 'DELETE_PLAYLIST_REQUEST';
export const DELETE_PLAYLIST_SUCCESS = 'DELETE_PLAYLIST_SUCCESS';
export const DELETE_PLAYLIST_FAILURE = 'DELETE_PLAYLIST_FAILURE';

export const ADD_TO_PLAYLIST_REQUEST = 'ADD_TO_PLAYLIST_REQUEST';
export const ADD_TO_PLAYLIST_SUCCESS = 'ADD_TO_PLAYLIST_SUCCESS';
export const ADD_TO_PLAYLIST_FAILURE = 'ADD_TO_PLAYLIST_FAILURE';

export const ORDER_PLAYLIST_TRACKS_REQUEST = 'ORDER_PLAYLIST_TRACKS_REQUEST';
export const ORDER_PLAYLIST_TRACKS_SUCCESS = 'ORDER_PLAYLIST_TRACKS_SUCCESS';
export const ORDER_PLAYLIST_TRACKS_FAILURE = 'ORDER_PLAYLIST_TRACKS_FAILURE';

export const REMOVE_PLAYLIST_TRACKS_REQUEST = 'REMOVE_PLAYLIST_TRACKS_REQUEST';
export const REMOVE_PLAYLIST_TRACKS_SUCCESS = 'REMOVE_PLAYLIST_TRACKS_SUCCESS';
export const REMOVE_PLAYLIST_TRACKS_FAILURE = 'REMOVE_PLAYLIST_TRACKS_FAILURE';
