import React from 'react';
import PropTypes from 'prop-types';

function SvgQueue({ fill, ...props }) {
  return (
    <svg data-name="Group 69" width="1em" height="1em" viewBox="0 0 16.892 12.752" {...props}>
      <path
        data-name="Union 3"
        d="M9.252 9.584a3.369 3.369 0 013.062-1.333V.9a.9.9 0 01.9-.9h3.677v1.785h-2.785v8.074a.888.888 0 01-.145.488 3.564 3.564 0 01-2.25 2.253 2.625 2.625 0 01-.859.152 1.962 1.962 0 01-1.6-3.168z"
        fill={fill}
      />
      <path
        data-name="Path 888"
        d="M7.055 9.212H.746a.913.913 0 000 1.795h6.309c.352 0 1.171-.03 1.149-.9s-.797-.895-1.149-.895z"
        fill={fill}
      />
      <path data-name="Path 889" d="M10.535 4.725H.81a.9.9 0 000 1.795h9.725a.9.9 0 000-1.795z" fill={fill} />
      <path data-name="Path 890" d="M10.535.237H.81a.9.9 0 000 1.795h9.725a.9.9 0 000-1.795z" fill={fill} />
    </svg>
  );
}

SvgQueue.propTypes = {
  fill: PropTypes.string,
};

SvgQueue.defaultProps = {
  fill: '#fcb316',
};

export default SvgQueue;
