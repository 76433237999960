import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Tooltip from './Tooltip';
import FullScreenPlayer from './FullScreenPlayer';

export const Queue = memo(({ queue }) => {
  return <Tooltip>{(active, close) => <FullScreenPlayer active={active} close={close} queue={queue} />}</Tooltip>;
});

Queue.propTypes = {
  queue: PropTypes.array.isRequired,
};
