import React from 'react';
import PropTypes from 'prop-types';

function SvgCamera({ fill, ...props }) {
  return (
    <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40 80C62.0914 80 80 62.0914 80 40C80 17.9086 62.0914 0 40 0C17.9086 0 0 17.9086 0 40C0 62.0914 17.9086 80 40 80ZM58.6433 55.6422L58.6433 33.4225C58.6433 31.7657 57.3001 30.4225 55.6433 30.4225H53.0495C51.1556 30.4225 49.4243 29.3525 48.5774 27.6586L46.2555 23.015C45.7474 21.9986 44.7086 21.3566 43.5723 21.3566L36.4287 21.3566C35.2923 21.3566 34.2536 21.9986 33.7454 23.0149L31.4236 27.6586C30.5766 29.3525 28.8453 30.4225 26.9514 30.4225H24.3577C22.7008 30.4225 21.3577 31.7656 21.3577 33.4225L21.3577 55.6422C21.3577 57.299 22.7008 58.6422 24.3577 58.6422H55.6433C57.3001 58.6422 58.6433 57.299 58.6433 55.6422ZM60.6433 33.4225L60.6433 55.6422C60.6433 58.4036 58.4047 60.6422 55.6433 60.6422H24.3577C21.5962 60.6422 19.3577 58.4036 19.3577 55.6422V33.4225C19.3577 30.6611 21.5962 28.4225 24.3577 28.4225H26.9514C28.0878 28.4225 29.1265 27.7805 29.6347 26.7641L31.9565 22.1205C32.8035 20.4266 34.5348 19.3566 36.4287 19.3566L43.5723 19.3566C45.4661 19.3566 47.1974 20.4266 48.0444 22.1206L50.3662 26.7642C50.8744 27.7805 51.9132 28.4225 53.0495 28.4225H55.6433C58.4047 28.4225 60.6433 30.6611 60.6433 33.4225ZM40.4066 37.0686C36.6196 37.0686 33.5495 40.1387 33.5495 43.9258C33.5495 47.7128 36.6196 50.7829 40.4066 50.7829C44.1937 50.7829 47.2638 47.7128 47.2638 43.9258C47.2638 40.1387 44.1937 37.0686 40.4066 37.0686ZM31.5495 43.9258C31.5495 39.0341 35.515 35.0686 40.4066 35.0686C45.2983 35.0686 49.2638 39.0341 49.2638 43.9258C49.2638 48.8174 45.2983 52.7829 40.4066 52.7829C35.515 52.7829 31.5495 48.8174 31.5495 43.9258Z"
        fill="url(#paint0_linear)"
      />
      <defs>
        <linearGradient id="paint0_linear" x1="40" y1="0" x2="40" y2="80" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FDB913" />
          <stop offset="1" stopColor="#F2994A" />
        </linearGradient>
      </defs>
    </svg>
  );
}

SvgCamera.propTypes = {
  fill: PropTypes.string,
};

SvgCamera.defaultProps = {
  fill: '#fff',
};

export default SvgCamera;
