import React from 'react';
import PropTypes from 'prop-types';

// function SvgSpeaker({ fill, full, ...props }) {
//   return (
//     <svg width="1em" height="1em" viewBox="0 0 50 50" {...props}>
//       <path
//         d="M29.0567 1.8313C28.4767 1.55463 27.7933 1.62796 27.2933 2.0313L11.08 15.0013H3.33333C1.49667 15.0013 0 16.498 0 18.3346V31.668C0 33.508 1.49667 35.0013 3.33333 35.0013H11.08L27.29 47.9713C27.5933 48.2113 27.9633 48.3346 28.3333 48.3346C28.58 48.3346 28.8267 48.278 29.0567 48.168C29.6333 47.8913 30 47.308 30 46.668V3.33463C30 2.69463 29.6333 2.1113 29.0567 1.8313Z"
//         fill={fill}
//       />
//       <path
//         d="M35.3019 11.5475C34.5378 10.8093 33.3121 10.8207 32.5596 11.5627C31.8071 12.3123 31.8148 13.5146 32.5751 14.2566C35.5075 17.0951 37.1211 20.9114 37.1211 25.0017C37.1211 29.092 35.5075 32.9083 32.5751 35.7468C31.8148 36.4811 31.8071 37.6873 32.5596 38.4369C32.9397 38.8136 33.4401 39 33.9366 39C34.4292 39 34.9218 38.8174 35.3019 38.4483C38.9791 34.8983 41 30.1193 41 25.0017C41 19.8841 38.9791 15.1051 35.3019 11.5475Z"
//         fill={fill}
//       />
//       {full && (
//         <path
//           d="M40.5145 5.57031C39.7022 4.80403 38.3993 4.81189 37.5953 5.5821C36.7954 6.3523 36.8037 7.59799 37.6077 8.36033C42.2957 12.789 44.8768 18.6991 44.8768 25.0022C44.8768 31.3053 42.2957 37.2115 37.6077 41.6402C36.8037 42.4065 36.7954 43.6521 37.5953 44.4223C38.0035 44.8074 38.5313 45 39.059 45C39.5827 45 40.1104 44.8114 40.5145 44.4302C45.9901 39.2628 49 32.3624 49 25.0022C49 17.6421 45.9901 10.7417 40.5145 5.57031Z"
//           fill={fill}
//         />
//       )}
//     </svg>
//   );
// }

const SvgSpeaker = ({ fill, full, size, ...props }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g>
        <path
          d="M18.769 4.39182C21.3076 5.95583 22.9998 8.76105 22.9998 11.9615C22.9998 15.162 21.3076 17.9672 18.769 19.5312"
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.9229 9.84616C18.4306 10.2832 18.769 11.0672 18.769 11.9615C18.769 12.8559 18.4306 13.6399 17.9229 14.0769"
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.23077 6.46153H5C2.79086 6.46153 1 8.2524 1 10.4615V12.6154C1 14.8245 2.79086 16.6154 5 16.6154H5.23077"
          stroke={fill}
          strokeLinecap="round"
          strokeinejoin="round"
        />
        <path
          d="M5.23096 6.46154L9.52063 2.56184C11.4478 0.809908 14.5386 2.17723 14.5386 4.78166V18.2953C14.5386 20.8997 11.4478 22.267 9.52063 20.5151L5.23096 16.6154"
          stroke={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width={size} height={size} fill={fill} />
        </clipPath>
      </defs>
    </svg>
  );
};

SvgSpeaker.propTypes = {
  fill: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  full: PropTypes.bool,
};

SvgSpeaker.defaultProps = {
  fill: '#818181',
  size: '21',
  full: true,
};

export default SvgSpeaker;
