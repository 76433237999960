import React from 'react';
import PropTypes from 'prop-types';

function SvgEye() {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M23.7883 12.5661L22.4444 13.9101L19.6892 11.155L21.0332 9.811C21.1714 9.67557 21.3571 9.5997 21.5506 9.5997C21.7441 9.5997 21.9299 9.67557 22.068 9.811L23.7883 11.5313C24.0706 11.8135 24.0706 12.2839 23.7883 12.5661ZM10.7518 20.079L18.8963 11.9345L21.6514 14.6896L13.5204 22.8475H10.7518V20.079ZM10.7518 13.4397C4.81142 13.4397 0 15.8454 0 18.8156V21.5036H8.06384V18.9635L13.4397 13.5876C12.5527 13.4801 11.6522 13.4397 10.7518 13.4397ZM10.7518 0C9.32601 0 7.95863 0.566387 6.95045 1.57456C5.94228 2.58274 5.37589 3.95012 5.37589 5.37589C5.37589 6.80167 5.94228 8.16905 6.95045 9.17722C7.95863 10.1854 9.32601 10.7518 10.7518 10.7518C12.1776 10.7518 13.5449 10.1854 14.5531 9.17722C15.5613 8.16905 16.1277 6.80167 16.1277 5.37589C16.1277 3.95012 15.5613 2.58274 14.5531 1.57456C13.5449 0.566387 12.1776 0 10.7518 0Z"
        fill="url(#paint0_linear)"
      />
      <defs>
        <linearGradient id="paint0_linear" x1="12" y1="0" x2="12" y2="22.8475" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FDB913" />
          <stop offset="1" stopColor="#F2994A" />
        </linearGradient>
      </defs>
    </svg>
  );
}

SvgEye.propTypes = {
  fill: PropTypes.string,
};

SvgEye.defaultProps = {
  fill: '#fff',
};

export default SvgEye;
