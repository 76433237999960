import React from 'react';

const SvgPlaylist = ({ fill, ...props }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect y="4" width="24" height="1" rx="0.5" fill={fill} />
      <rect y="9" width="24" height="1" rx="0.5" fill={fill} />
      <rect y="14" width="14" height="1" rx="0.5" fill={fill} />
      <rect y="19" width="14" height="1" rx="0.5" fill={fill} />
      <rect x="16" y="16" width="7" height="1" rx="0.5" fill={fill} />
      <path
        d="M19.5 20C19.2239 20 19 19.7761 19 19.5V13.5C19 13.2239 19.2239 13 19.5 13C19.7761 13 20 13.2239 20 13.5V19.5C20 19.7761 19.7761 20 19.5 20Z"
        fill={fill}
      />
    </svg>
  );
};

export default SvgPlaylist;
