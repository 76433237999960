import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled/macro';
import { useSelector, useDispatch } from 'react-redux';
import { Flex, Box } from 'rebass';
import { Typography, Form, Image, Button } from 'components/atoms';
import { Modal } from 'components/molecules';
import { CameraIcon } from 'components/icons';
import { updateTrackAction } from 'redux/actions';
import { v4 as uuidv4 } from 'uuid';

const PosterPicker = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.secondary};
  border-radius: 0.5rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  left: 50%;
  padding: 0.75rem 0.75rem;
  position: absolute;
  top: calc(100% - 1.5rem);
  transform: translateX(-50%);

  ${Typography.Text} {
    white-space: nowrap;
  }
`;

const PosterWrapper = styled.div`
  height: 15rem;
  position: relative;
  width: 15rem;
  input {
    display: none;
  }
`;

const Poster = styled(Image)`
  border-radius: 0.5rem;
  height: 15rem;
  width: 15rem;
`;

const INITIAL_FORM = {
  artwork: '',
  name: '',
  public: false,
  description: '',
  id: '',
};

const EditTrackModal = ({ allowPoster, isOpen, toggle, track, fields }) => {
  const posterRef = useRef();
  const dispatch = useDispatch();
  const [poster, setPoster] = useState(null);
  const [error, setError] = useState('');
  const [form, setForm] = useState(INITIAL_FORM);

  const loading = useSelector(state => state.track.updatingTrack || false);
  const editTrack = (data, cb) => dispatch(updateTrackAction(data, cb));

  useEffect(() => {
    if (isOpen) {
      const { artwork = '', name = '', description = '', id } = track;
      setForm(v => ({
        ...v,
        artwork,
        name,
        description,
        id,
      }));
    }
  }, [isOpen, track]);

  useEffect(() => {
    if (!isOpen) {
      setPoster(null);
      setForm(v => ({ ...v, ...INITIAL_FORM }));
      setError(false);
    }
  }, [isOpen]);

  const changePoster = e => {
    const { files } = e.target;
    if (files.length) {
      const file = Object.assign(files[0], {
        preview: URL.createObjectURL(files[0]),
      });
      setPoster(file);
    }
  };

  const onChange = e => {
    const { name, value } = e.target;
    setForm(v => ({
      ...v,
      [name]: value,
    }));
  };

  const validateForm = () => {
    const { name } = form;
    if (!name) {
      setError('Enter your track name');
      return false;
    }
    if (name.length < 1) {
      setError('Track name too short');
      return false;
    }
    return true;
  };

  const onSuccess = data => {
    // if (type && !isObjEmpty(item)) {
    //   addToTrack(data.id);
    // }
    toggle();
  };

  const onError = e => {
    setError(e?.error || 'An error occured');
  };

  const callback = (data, e) => {
    if (data) {
      onSuccess(data);
    } else {
      onError(e);
    }
  };

  const onSubmit = e => {
    e.preventDefault();
    if (validateForm()) {
      const data = { id: form.id };
      fields
        .map(v => v.field)
        .forEach(field => {
          if (form[field] !== track[field]) data[field] = form[field];
        });
      editTrack(data, callback);
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg" showCloseIcon>
      <Modal.Header justifyContent="center">
        <Typography.Paragraph>Edit your track</Typography.Paragraph>
      </Modal.Header>
      <Modal.Body>
        <Flex mx="-1.5rem" flexWrap={['wrap', 'nowrap', 'nowrap', 'nowrap']}>
          {allowPoster && (
            <Box
              px="1.5rem"
              mb={['3rem', null, '3rem', 0]}
              flexShrink={0}
              flexGrow={['1', '1', '1', '0']}
              justifyContent={['center', 'flex-start']}
            >
              <PosterWrapper>
                <input type="file" accept="image/*" ref={posterRef} onChange={changePoster} />
                <Poster src={(poster && poster.preview) || form.artwork} placeholder="album" alt="Track poster" />
                <PosterPicker onClick={() => posterRef.current.click()}>
                  <CameraIcon style={{ marginRight: '0.5rem' }} />
                  <Typography.Text size="tiny" color="white">
                    Change Photo
                  </Typography.Text>
                </PosterPicker>
              </PosterWrapper>
            </Box>
          )}

          <Box px="1.5rem" flexGrow={['1', '1,', '1', '1', 0]}>
            <Form onSubmit={onSubmit}>
              <Flex flexWrap="wrap" mx="-1.5rem">
                {fields.map(({ title, field }) => (
                  <Box px="1.5rem" mb="2rem" width={1} key={uuidv4()}>
                    <Form.Group>
                      <Form.Label required>{title}</Form.Label>
                      <Form.Input {...{ name: field, value: form[field], onChange }} />
                    </Form.Group>
                  </Box>
                ))}
              </Flex>
              <Form.Error error={error} />
              <Box>
                <Button effect fontSize="small" onClick={onSubmit} loading={loading}>
                  Save
                </Button>
              </Box>
            </Form>
          </Box>
        </Flex>
      </Modal.Body>
    </Modal>
  );
};

EditTrackModal.propTypes = {
  allowPoster: PropTypes.bool,
  fields: PropTypes.array,
  track: PropTypes.object.isRequired,
  // id: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
};

EditTrackModal.defaultProps = {
  allowPoster: false,
  fields: [
    { title: 'Artist Name', field: 'artist_name' },
    { title: 'Featuring', field: 'featuring' },
    { title: 'Song Title', field: 'name' },
    { title: 'Album Title', field: 'album_name' },
    { title: 'Producer(s)', field: 'producer_name' },
    { title: 'Genre(s)', field: 'genre_name' },
  ],
};

export default EditTrackModal;
