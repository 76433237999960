import React from 'react';
import PropTypes from 'prop-types';

function SvgTwitter({ fill, ...props }) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 38.449 31.231" {...props}>
      <g data-name="Group 54">
        <path
          d="M38.449 3.695a15.751 15.751 0 01-4.529 1.242A7.92 7.92 0 0037.387.576a15.9 15.9 0 01-5.012 1.915 7.894 7.894 0 00-13.44 7.191 22.393 22.393 0 01-16.256-8.24A7.9 7.9 0 005.12 11.97a7.888 7.888 0 01-3.573-.99v.1a7.9 7.9 0 006.326 7.734 7.989 7.989 0 01-2.074.275 7.57 7.57 0 01-1.485-.147 7.892 7.892 0 007.366 5.478 15.825 15.825 0 01-9.8 3.371 16.763 16.763 0 01-1.881-.108 22.3 22.3 0 0012.09 3.549c14.509 0 22.44-12.018 22.44-22.44l-.03-1.022a15.75 15.75 0 003.95-4.075z"
          data-name="Path 1031"
          fill={fill}
        />
      </g>
    </svg>
  );
}

SvgTwitter.propTypes = {
  fill: PropTypes.string,
};

SvgTwitter.defaultProps = {
  fill: '#fff',
};

export default SvgTwitter;
