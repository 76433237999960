import React from 'react';
import PropTypes from 'prop-types';

function SvgHeadphones({ fill, ...props }) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 14.392 12.481" {...props}>
      <path
        d="M3.087 8.963a.843.843 0 00-.527-.717l.008 1.448a.865.865 0 00.519-.731zm-.328 3.518H3.3a.355.355 0 00.355-.356v-.89a1.436 1.436 0 01-.897 1.246zm.364-1.216a.858.858 0 00-.551-.728l.008 1.47a.865.865 0 00.542-.742zm.375-.15V11.1c.016-.8-.877-.929-.927-.936v.191c.118.047.73.318.712.909a1.043 1.043 0 01-.7.908v.2a1.263 1.263 0 00.917-1.257zm-.018-2.1a1.19 1.19 0 00-.922-1.19v.246a1.03 1.03 0 01.688.9 1.044 1.044 0 01-.679.9v.266-.124a1.007 1.007 0 00.912-1.001zm7.491.035a.99.99 0 00.89.98v.082-.222A1.028 1.028 0 0111.2 9a1.014 1.014 0 01.677-.886v-.24a1.156 1.156 0 00-.907 1.173zM2.562 7.656c.012 0 1.172.3 1.077 1.37a1.169 1.169 0 01-.739 1.065 1.044 1.044 0 01.761 1v-3.1a.355.355 0 00-.361-.352h-.743v.037zm-.14 4.824L2.4 7.639h-.04V5.506a.208.208 0 000-.021 4.84 4.84 0 019.678 0v1.288l-.03 5.707a2.421 2.421 0 00.736-4.713V5.48v-.033a5.55 5.55 0 00-11.1 0v2.32a2.421 2.421 0 00.774 4.714zm8.9-1.217a.85.85 0 00.531.728l.008-1.442a.842.842 0 00-.54.715zm-.529-.158a1.026 1.026 0 01.747-1 1.152 1.152 0 01-.729-1.048c-.094-1.057 1.048-1.342 1.06-1.345v.016-.089h-.781a.355.355 0 00-.355.356v4.131a.355.355 0 00.355.356h.632a1.413 1.413 0 01-.93-1.376zM11.356 9a.85.85 0 00.508.718l.008-1.418a.826.826 0 00-.516.7zm.506 1.188v.185c-.118.047-.718.315-.7.9a1.028 1.028 0 00.691.894v.2a1.241 1.241 0 01-.9-1.243v-.01c-.016-.79.847-.914.909-.927z"
        fill={fill}
        fillRule="evenodd"
      />
    </svg>
  );
}

SvgHeadphones.propTypes = {
  fill: PropTypes.string,
};

SvgHeadphones.defaultProps = {
  fill: '#fefefe',
};

export default SvgHeadphones;
