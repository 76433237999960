import PropTypes from 'prop-types';
import React from 'react';

function SvgCloseModal({ fill, size, ...props }) {
  return (
    <svg
      width={size}
      xmlns="http://www.w3.org/2000/svg"
      enable-background="new 0 0 24 24"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="#fff"
        d="M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M15.7,14.3c0.4,0.4,0.4,1,0,1.4c-0.4,0.4-1,0.4-1.4,0L12,13.4l-2.3,2.3c-0.4,0.4-1,0.4-1.4,0c-0.4-0.4-0.4-1,0-1.4l2.3-2.3L8.3,9.7c-0.4-0.4-0.4-1,0-1.4c0.4-0.4,1-0.4,1.4,0l2.3,2.3l2.3-2.3c0.4-0.4,1-0.4,1.4,0c0.4,0.4,0.4,1,0,1.4L13.4,12L15.7,14.3z"
      />
    </svg>
  );
}

SvgCloseModal.propTypes = {
  fill: PropTypes.string,
  size: PropTypes.string,
};

SvgCloseModal.defaultProps = {
  fill: '#fff',
  size: '4em',
};

export default SvgCloseModal;
