import { OutlineButton } from 'components/atoms';
import { css } from '@emotion/core';
import { Box } from 'rebass';
import { Image } from 'components/atoms';
import { Typography } from 'components/atoms';
import styled from '@emotion/styled/macro';

// const rotate = keyframes`
// from {
//   transform: rotate(0deg);
// }
// to {
//   transform: rotate(360deg);
// }
// `;

export const Body = styled(Box)`
  flex-direction: column;
  margin: 12rem auto 0 auto;
  padding: 0 1rem;
  @media (max-width: 767px) {
    margin: 6rem auto 0 auto;
  }
`;

export const Title = styled(Typography.Text)`
  color: ${({ theme }) => theme.colors.switchingText};
  font-size: ${({ theme }) => theme.font.size.tiny};
  text-decoration: none;
  transition: all 0.1s;
  &:hover {
    text-decoration: underline;
  }
`;

export const Artist = styled(Typography.Text)`
  color: ${({ theme }) => theme.colors['brown-grey']} !important;
  font-size: ${({ theme }) => theme.font.size.tiny};
  text-decoration: none !important;
  &:hover {
    text-decoration: underline;
  }
`;

export const Artwork = styled(Image)`
  flex-shrink: 0;
  height: 4.8rem;
  width: 4.8rem;
`;

export const Number = styled(Typography.Text)`
  color: ${({ theme }) => theme.colors['brown-grey']};
  display: inline-block;
  width: 1.6rem;
`;

export const Wrapper = styled(Box)`
  align-items: center;
  cursor: pointer;
  height: 5.5rem;
  padding: 0rem 1rem;
  transition: all 0.1s;
  position: relative;

  &:after {
    background-color: rgba(255, 255, 255, 0.06);
    content: '';
    height: 1px;
    position: absolute;
    top: 100%;
    width: 99%;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.sidebar};

    ${Title} {
      color: ${({ theme }) => theme.colors.primary};
    }
    ${Number} {
      color: white;
    }
  }

  ${({ active, theme }) =>
    active &&
    css`
      background-color: ${theme.colors.switchingBg}33;

      ${Title} {
        color: ${theme.colors.primary};
      }
    `}

  ${({ theme }) => theme.mq.sm`
    padding: 3.3rem 1rem;
    `}
`;

export const Description = styled(Typography.Text)`
  color: #868687;
  display: inline-block;
  margin-top: 0.4rem;
  font-size: ${({ theme }) => theme.font.size.tiny};
  line-height: ${({ theme }) => theme.font.size.tiny};
  margin-bottom: 0.2rem;
  text-align: left;

  ${({ theme }) => theme.mq.md`
  text-align: center;
`}
`;

export const ToggleButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  align-item: center;
  margin-bottom: 3rem;
  justify-content: space-between;

  @media (max-width: 800px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const PublicButton = styled.button`
  padding: 1.5rem 6rem;
  color: white;
  background: linear-gradient(180deg, #fdb913 0%, #f2994a 100%);
  border-radius: 3px;
  border: none;

  @media (max-width: 800px) {
    padding: 0.5rem 2rem;
    font-size: 1rem;
  }
`;

export const PrivateButton = styled.button`
  padding: 1.5rem 6rem;
  color: white;
  background: linear-gradient(180deg, #fdb913 0%, #f2994a 100%);
  border-radius: 3px;
  border: none;

  @media (max-width: 800px) {
    padding: 0.5rem 2rem;
    font-size: 1rem;
  }
`;

export const SaveButton = styled(OutlineButton)`
  color: ${({ theme }) => theme.colors.switchingText} !important;
  border: 2px solid #272729;
  border-radius: 175.5px;
  font-weight: 500;
  font-size: 15px;
  @media (max-width: 440px) {
    width: 100%;
  }
`;

export const Wrap = styled(Box)`
  align-items: center;
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0;
  right: 0;
  top: 13px;
  margin: 0 auto;
  ${({ theme }) => theme.mq.md`
    flex-direction: row;
  `}
`;

export const AvatarOverlay = styled.div`
  border-radius: 0.5rem;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white}80;
  cursor: pointer;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0px;
  transition: all 0.2s;
  width: 100%;
  height: 17rem;
  width: 17rem;

  ${({ theme }) => theme.mq.sm`
   
    width: 20rem;
    height: 20rem;
  `}

  ${({ theme }) => theme.mq.md`
    height: 34rem;
    width: 34rem;
  `}

  input {
    display: none;
  }
  svg {
    width: 20%;
  }
`;

export const AvatarWrapper = styled.div`
  position: relative;
  border-radius: 0.5rem;
  display: inline-block;
  overflow: hidden;
  // &:hover {
  //   ${AvatarOverlay} {
  //     top: 0px;
  //   }
  // }
`;

export const EditArtwork = styled(Image)`
  border-radius: 0.5rem;
  box-shadow: 0 2px 30px rgba(0, 0, 0, 0.15);
  flex-shrink: 0;
  height: 17rem;

  object-fit: cover;
  width: 17rem;

  img {
    box-shadow: none;
  }

  ${({ theme }) => theme.mq.sm`
    // margin-right: 2rem;
    width: 20rem;
    height: 20rem;
  `}

  ${({ theme }) => theme.mq.md`
    height: 34rem;
    margin-right: 3rem;
    
    width: 34rem;

    img {
      margin-top: 0;
    }

  `}
`;

export const Details = styled(Box)`
  align-items: center;
  flex-direction: column;
  margin-bottom: 1rem;
  text-align: center;
  width: 50%;
  @media (max-width: 767px) {
    width: 100%;
  }
  ${({ theme }) => theme.mq.md`
    align-items: flex-start;
    margin-bottom: 0;
  `}
`;

export const Name = styled(Typography.Paragraph)`
  color: ${({ theme }) => theme.colors.switchingText};
  font-weight: ${({ theme }) => theme.font.weight.medium};
  font-size: ${({ theme }) => theme.font.size.big};
  line-height: 1.2;
  text-align: center;
  margin-bottom: 2rem;
  ${({ theme }) => theme.mq.md`
  font-size: ${theme.font.size.large};
  text-align: left;
`}
`;

export const Suggest = styled(Typography.Paragraph)`
  color: ${({ theme }) => theme.colors.switchingText};
  font-weight: 500;
  font-size: ${({ theme }) => theme.font.size.small};
  line-height: 1.2;
  margin: 3rem 0 2rem 0;
  text-align: center;

  ${({ theme }) => theme.mq.md`
  font-size: ${theme.font.size.medium};
  line-height: ${theme.font.size.big};
  text-align: left;
`}
`;

export const BlurBackground = styled(Image)`
  z-index: 0;
  width: 100%;
  object-fit: cover;
  filter: blur(3.3rem);
  height: 20rem;
  top: -79px;
  ${({ theme }) => theme.mq.sm`
  height: 25rem;
`}
`;

export const PictureAndLeft = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Left = styled.div`
  margin-bottom: 8rem;
  border: none;
  @media (max-width: 767px) {
    display: none;
  }
`;

export const TrackDetails = styled.div`
  align-self: end;
  margin-bottom: 11rem;
  margin-top: 6rem;
  @media (max-width: 767px) {
    align-self: center;
    margin-bottom: 1rem;
    margin-top: 1rem;
  }
`;

export const DeleteButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 3rem;
  padding-right: 2rem;
  svg {
    width: 2.2rem;
    height: 2.2rem;
    cursor: pointer;
  }
`;

export const AddSongsContainer = styled.div`
  margin-top: 5rem;
`;

export const SearchComponent = styled.div`
  width: 100%;
  ${({ theme }) => theme.mq.md`
  width: 35%;
  `}
`;

export const Toggles = styled.div``;

export const Tracks = styled.div``;
