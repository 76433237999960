import React from 'react';
import { Paragraph } from '../Typography';
import { FormGroup } from './Group';

export const FormError = ({ error, ...props }) => {
  return (
    (error && (
      <FormGroup {...props}>
        <Paragraph color="error" size="tiny" style={{ wordBreak: 'break-word' }}>
          {error}
        </Paragraph>
      </FormGroup>
    )) ||
    null
  );
};
