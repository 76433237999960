import React from 'react';

function SvgRight() {
  return (
    <svg width="13" height="21" viewBox="0 0 13 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.83 18.6638L2.6 20.4338L12.5 10.5338L2.6 0.633838L0.830001 2.40384L8.96 10.5338L0.83 18.6638Z"
        fill="url(#paint0_linear)"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="6.665"
          y1="20.4338"
          x2="6.665"
          y2="0.633838"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDB913" />
          <stop offset="1" stopColor="#F2994A" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default SvgRight;
