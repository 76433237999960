import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box } from 'rebass';
import { Item } from './Item';
import PropTypes from 'prop-types';
import { playTracksAction } from 'redux/actions';
import styled from '@emotion/styled/macro';

const Wrapper = styled(Box)`
  flex-direction: column;
  margin: 20rem auto 0 auto;
  @media (max-width: 767px) {
    margin: 10rem auto 0 auto;
  }
`;

export const Body = memo(({ item, tracks, type }) => {
  const dispatch = useDispatch();
  const playTracks = (index = 0) => dispatch(playTracksAction(tracks, index, type, item.id));
  const user = useSelector(state => state.profile.user || {});
  const isOwner = (userId, trackOwner) => {
    if (userId && trackOwner) {
      return userId === trackOwner;
    }
    return false;
  };

  const renderItems = () => {
    if (tracks.length)
      return tracks.map((track, index) => (
        <Item
          key={track.id}
          index={index + 1}
          onPlay={() => playTracks(index)}
          showArtwork={['playlist', 'mood'].includes(type)}
          item={track}
          isOwner={isOwner(user.id, track.owner)}
        />
      ));
    return null;
  };

  return <Wrapper style={{ display: tracks.length === 0 ? 'none' : null }}>{renderItems()}</Wrapper>;
});

Body.propTypes = {
  item: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  tracks: PropTypes.arrayOf(PropTypes.object).isRequired,
  type: PropTypes.string.isRequired,
};
