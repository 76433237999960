import React from 'react';

const SVGComponent = ({ point }) => (
  <svg width={150} height={150} viewBox="0 0 134 116" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.5">
      <g opacity="0.5">
        <mask
          id="path-1-outside-1"
          maskUnits="userSpaceOnUse"
          x="14.5"
          y="0.335938"
          width="112"
          height="115"
          fill="black"
        >
          <rect fill="white" x="14.5" y="0.335938" width="112" height="115" />
          <path d="M20.6433 41.982L119.456 5.44839C120.861 4.92868 122.227 6.30103 121.701 7.70426L83.4498 109.707C82.9107 111.145 80.9133 111.241 80.239 109.861L59.3872 67.1991C59.2301 66.8777 58.9783 66.6122 58.6656 66.4383L20.3995 45.1527C19.0926 44.4258 19.2406 42.5006 20.6433 41.982Z" />
        </mask>
        <path
          d="M119.456 5.44839L120.973 9.5519L119.456 5.44839ZM20.6433 41.982L22.1605 46.0855H22.1605L20.6433 41.982ZM20.3995 45.1527L18.2727 48.976H18.2727L20.3995 45.1527ZM58.6656 66.4383L56.5389 70.2616L56.5389 70.2616L58.6656 66.4383ZM59.3872 67.1991L55.4565 69.1202L55.4566 69.1203L59.3872 67.1991ZM80.239 109.861L84.1696 107.94V107.94L80.239 109.861ZM83.4498 109.707L87.5463 111.244L83.4498 109.707ZM121.701 7.70426L125.797 9.24042V9.24041L121.701 7.70426ZM117.938 1.34488L19.1261 37.8785L22.1605 46.0855L120.973 9.5519L117.938 1.34488ZM18.2727 48.976L56.5389 70.2616L60.7923 62.6149L22.5262 41.3294L18.2727 48.976ZM55.4566 69.1203L76.3084 111.783L84.1696 107.94L63.3178 65.278L55.4566 69.1203ZM87.5463 111.244L125.797 9.24042L117.605 6.16809L79.3534 108.171L87.5463 111.244ZM76.3084 111.783C78.6684 116.611 85.6592 116.276 87.5463 111.244L79.3534 108.171C80.1622 106.015 83.1582 105.871 84.1696 107.94L76.3084 111.783ZM56.5389 70.2616C56.0699 70.0007 55.6922 69.6024 55.4565 69.1202L63.3178 65.278C62.768 64.153 61.8866 63.2236 60.7923 62.6149L56.5389 70.2616ZM19.1261 37.8785C14.2168 39.6936 13.6987 46.4317 18.2727 48.976L22.5262 41.3294C24.4865 42.4198 24.2644 45.3076 22.1605 46.0855L19.1261 37.8785ZM120.973 9.5519C118.864 10.3315 116.815 8.27293 117.605 6.1681L125.797 9.24041C127.639 4.32913 122.858 -0.474092 117.938 1.34488L120.973 9.5519Z"
          fill="url(#paint0_linear)"
          mask="url(#path-1-outside-1)"
        />
      </g>
      <path d="M133.901 54.5078L126.173 46.3919L133.901 38.2736H71.834V54.5078H133.901Z" fill="url(#paint1_linear)" />
      <path
        d="M0.54987 38.5078L8.27795 46.6237L0.54987 54.742L62.6172 54.742V38.5078L0.54987 38.5078Z"
        fill="url(#paint2_linear)"
      />
      <mask id="path-5-outside-2" maskUnits="userSpaceOnUse" x="16.5" y="2.33594" width="108" height="112" fill="black">
        <rect fill="white" x="16.5" y="2.33594" width="108" height="112" />
        <path d="M20.6433 41.982L119.456 5.44839C120.861 4.92868 122.227 6.30103 121.701 7.70426L83.4498 109.707C82.9107 111.145 80.9133 111.241 80.239 109.861L59.3872 67.1991C59.2301 66.8777 58.9783 66.6122 58.6656 66.4383L20.3995 45.1527C19.0926 44.4258 19.2406 42.5006 20.6433 41.982Z" />
      </mask>
      <path
        d="M119.456 5.44839L120.366 7.9105L119.456 5.44839ZM20.6433 41.982L21.5536 44.4441H21.5536L20.6433 41.982ZM20.3995 45.1527L19.1234 47.4467H19.1234L20.3995 45.1527ZM58.6656 66.4383L57.3896 68.7322L57.3896 68.7322L58.6656 66.4383ZM59.3872 67.1991L57.0288 68.3518L57.0288 68.3518L59.3872 67.1991ZM80.239 109.861L82.5974 108.709L80.239 109.861ZM83.4498 109.707L85.9077 110.629L83.4498 109.707ZM121.701 7.70426L124.159 8.62595V8.62595L121.701 7.70426ZM118.545 2.98628L19.733 39.5199L21.5536 44.4441L120.366 7.9105L118.545 2.98628ZM19.1234 47.4467L57.3896 68.7322L59.9416 64.1443L21.6755 42.8587L19.1234 47.4467ZM57.0288 68.3518L77.8806 111.014L82.5974 108.709L61.7456 66.0464L57.0288 68.3518ZM85.9077 110.629L124.159 8.62595L119.243 6.78256L80.992 108.786L85.9077 110.629ZM77.8806 111.014C79.5664 114.463 84.5598 114.224 85.9077 110.629L80.992 108.786C81.2616 108.067 82.2602 108.019 82.5974 108.709L77.8806 111.014ZM57.3896 68.7322C57.2333 68.6453 57.1073 68.5125 57.0288 68.3518L61.7456 66.0464C61.3528 65.2429 60.7232 64.579 59.9416 64.1443L57.3896 68.7322ZM19.733 39.5199C16.2264 40.8164 15.8563 45.6293 19.1234 47.4467L21.6755 42.8587C22.3289 43.2222 22.2549 44.1848 21.5536 44.4441L19.733 39.5199ZM120.366 7.9105C119.663 8.17035 118.98 7.48417 119.243 6.78256L124.159 8.62595C125.474 5.11789 122.059 1.68702 118.545 2.98628L120.366 7.9105Z"
        fill="#080808"
        mask="url(#path-5-outside-2)"
      />
      <path
        d="M20.6433 41.982L119.456 5.44839C120.861 4.92868 122.227 6.30103 121.701 7.70426L83.4498 109.707C82.9107 111.145 80.9133 111.241 80.239 109.861L59.3872 67.1991C59.2301 66.8777 58.9783 66.6122 58.6656 66.4383L20.3995 45.1527C19.0926 44.4258 19.2406 42.5006 20.6433 41.982Z"
        fill="url(#paint3_linear)"
      />
      <text x="55%" y="50%" dominantBaseline="middle" textAnchor="middle" fontWeight={600} fill="#A6A6A6">
        {point}
      </text>
    </g>
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="70.6582"
        y1="84.6622"
        x2="70.6582"
        y2="-0.894061"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#484848" />
        <stop offset="1" stop-color="#2C2C2C" />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="140.686"
        y1="46.2658"
        x2="114.014"
        y2="46.2791"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#515151" />
        <stop offset="0.71875" stop-color="#BBBBBB" />
      </linearGradient>
      <linearGradient
        id="paint2_linear"
        x1="-6.23499"
        y1="46.7498"
        x2="20.4375"
        y2="46.7365"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#515151" />
        <stop offset="0.71875" stop-color="#BBBBBB" />
      </linearGradient>
      <linearGradient
        id="paint3_linear"
        x1="80.7159"
        y1="16.2501"
        x2="96.994"
        y2="38.7474"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#484848" />
        <stop offset="1" stop-color="#313131" />
      </linearGradient>
      <radialGradient
        id="paint4_radial"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(72.5 46.7694) rotate(180) scale(47 202.227)"
      >
        <stop stop-color="#BBBBBB" />
        <stop offset="1" stop-color="#A6A6A6" />
      </radialGradient>
    </defs>
  </svg>
);

export default SVGComponent;
