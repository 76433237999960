import React from 'react';
import styled from '@emotion/styled';
import { Image } from 'components/atoms';

export const CommentBoxWrapper = styled.div`
  border: 1px solid #fdb913;
  border-radius: 1rem;
  padding: 1rem;
  color: white;
`;
export const MakeComment = styled.textarea`
  resize: none;
  border: 1px solid transparent;
  background-color: transparent;
  outline: none;
  padding: 5px 0;
  color: white;
  width: 100%;
  font-weight: 700;
`;

export const Comment = styled.div`
  display: grid;
  grid-template-columns: 1fr 11fr;
`;
export const CommentContent = styled.div``;
export const Username = styled.p`
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
`;
export const Avatar = styled(Image)`
  width: 25px;
  display: block;
  object-fit: cover;
  height: 25px;
  margin-right: 1.25rem;
  border-radius: 50%;
`;

export default function CommentBox({ input_id, image, username, content, onchange = () => {} }) {
  return (
    <CommentBoxWrapper>
      <Comment>
        <Avatar src={image} alt={'user name'} />
        <CommentContent>
          <Username>{username}</Username>
          <MakeComment id={input_id} onChange={onchange} value={content || ''} />
        </CommentContent>
      </Comment>
    </CommentBoxWrapper>
  );
}
