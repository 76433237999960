import React from 'react';
import styled from '@emotion/styled/macro';
import { Box } from 'rebass';
import PropTypes from 'prop-types';
import { OutlineButton } from './Button';
import { Text } from './Typography';

const IconButtonTitle = styled(Text)`
  font-size: 0.8rem;

  &,
  &:visited {
    color: ${({ theme: { colors } }) => colors.white};
  }
`;

const IconButtonSubtitle = styled(Text)`
  font-size: ${({ theme }) => theme.font.size.small};
  font-weight: bold;

  &,
  &:visited {
    color: ${({ theme: { colors } }) => colors.white};
  }
`;

const Wrapper = styled(OutlineButton)`
  background-color: ${({ theme: { colors } }) => colors.black};

  border-radius: 0.5rem;
  border-width: 0.2rem;
  padding: 0.5rem 1rem;

  svg {
    height: 2rem;
    margin-right: 0.5rem;
    width: 2rem;
  }
`;

Wrapper.defaultProps = {
  color: 'white',
};

export const IconButton = ({ icon: Icon, title, subtitle, ...props }) => {
  return (
    <Wrapper {...props} aria-label="icon-button">
      <Icon />
      <Box display="inline-flex" flexDirection="column">
        <IconButtonTitle size="atom">{title}</IconButtonTitle>
        <IconButtonSubtitle size="small">{subtitle}</IconButtonSubtitle>
      </Box>
    </Wrapper>
  );
};

IconButton.propTypes = {
  icon: PropTypes.func.isRequired,
  subtitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};
