import { Progressbar, Typography } from 'components/atoms';
import React, { useEffect, useRef, memo } from 'react';
import { Box } from 'rebass';
import PropTypes from 'prop-types';
import { useSlider } from 'react-use';
import styled from '@emotion/styled/macro';
import { formatTime } from 'lib/utils';
import { TimeBubble } from './TimeBubble';

const Duration = styled(Typography.Text)`
  color: #fff;
  font-size: ${({ theme }) => theme.font.size.tiny};
  font-weight: ${({ theme }) => theme.font.weight.light};
  opacity: 0.7;
  user-select: none;
`;

const Wrapper = styled(Box)`
  flex-direction: column;
  width: 100%;
  margin: 3rem 0 !important;
  padding: 0 !important;
`;

const Badge = styled.span`
  background: ${({ theme }) => theme.colors.primary};
  border-radius: 50%;
  border: none;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0), 0px 0px 1px rgba(13, 13, 13, 0);
  display: inline-flex;
  height: 1.5rem;
  opacity: 1;
  position: absolute;
  top: -0.5rem;
  transform: translateX(-50%);
  transition: all 0.1s;
  width: 1.5rem;
  display: none;

  &:active,
  &:focus {
    height: 2rem;
    opacity: 1;
    top: -0.75rem;
    width: 2rem;
  }
`;

const SeekWrapper = styled.div`
  position: relative;
  margin-bottom: 0.3rem;

  &:hover {
    ${Badge} {
      opacity: 1;
      display: block;
    }
  }
`;

export const MainSeekbar = memo(({ className, duration, onSeek, played, progress, seekable, textColor, ...props }) => {
  const node = useRef();
  const unseekableNode = useRef();

  const toPercentage = (v, maxValue) => {
    return (v / maxValue) * 100;
  };

  const { isSliding, value } = useSlider(seekable ? node : unseekableNode);
  const position = isSliding && value !== null ? `calc(${toPercentage(value, 1)}% + 0.5rem)` : `${progress}%`;
  const newProgress = isSliding && value !== null ? value * 100 : progress;

  useEffect(() => {
    if (isSliding) {
      onSeek(value * 100);
    }
    // eslint-disable-next-line
  }, [isSliding, value]);

  return (
    <>
      <Wrapper className={className} {...props}>
        <SeekWrapper ref={node}>
          <Progressbar progress={newProgress} />
          {seekable && (
            <>
              <Badge style={{ left: position }}>
                <TimeBubble time={formatTime(played)} />
              </Badge>
            </>
          )}
        </SeekWrapper>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Duration color={textColor}>{formatTime(played)}</Duration>
          <Duration color={textColor}>{formatTime(duration)}</Duration>
        </Box>
      </Wrapper>
      <span style={{ display: 'none' }} ref={unseekableNode} />
    </>
  );
});

MainSeekbar.propTypes = {
  className: PropTypes.string,
  duration: PropTypes.number,
  onSeek: PropTypes.func,
  played: PropTypes.number,
  progress: PropTypes.number,
  seekable: PropTypes.bool,
  textColor: PropTypes.string,
};

MainSeekbar.defaultProps = {
  className: '',
  duration: 0,
  onSeek: () => {},
  played: 0,
  progress: 0,
  seekable: false,
  textColor: 'white',
};
