import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Typography, Spinner } from 'components/atoms';
import { Modal } from 'components/molecules';
import { useDispatch, useSelector } from 'react-redux';
import { artistMagicPlaylistAction, userMagicPlaylistAction } from 'redux/actions';
import { useUser } from 'hooks';

export const MagicContext = React.createContext({});

const MagicModal = ({ isOpen, toggle, error }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} size="sm">
      <Modal.Header justifyContent="center">
        <Typography.Paragraph>Preparing your music</Typography.Paragraph>
      </Modal.Header>
      <Modal.Body display="flex" justifyContent="center" alignItems="center">
        <Spinner size="5rem" style={{ marginBottom: '1rem' }} />
        {error && (
          <Typography.Paragraph align="center" color="primary">
            {error}
          </Typography.Paragraph>
        )}
      </Modal.Body>
    </Modal>
  );
};

export const MagicProvider = ({ children }) => {
  const dispatch = useDispatch();
  const { user } = useUser();
  const [modal, setModal] = useState(false);
  const [id, setId] = useState('');
  const [error, setError] = useState('');
  const toggle = () => {
    if (modal) {
      setError('');
      setId('');
      setModal(false);
    } else {
      setModal(true);
    }
  };
  const magicPlaylist = cb => dispatch(userMagicPlaylistAction(cb));
  const artistMagicPlaylist = (artistID, cb) => dispatch(artistMagicPlaylistAction(artistID, cb));

  const loading = useSelector(state => state.player.magicLoading[id] || false);

  const callback = (success, e) => {
    if (!success) {
      setError(e?.error || !user ? 'Please login to experience the magic' : 'An error occured');
    }
  };

  const loadMagic = newId => {
    setId(newId);
    magicPlaylist(callback);
  };

  const loadArtistMagic = newId => {
    setId(newId);
    artistMagicPlaylist(newId, callback);
  };

  useEffect(() => {
    setModal(loading);
  }, [loading]);

  useEffect(() => {
    let timeout = null;
    if (error) {
      setModal(true);
      timeout = setTimeout(() => {
        setModal(false);
        setError('');
      }, 3000);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [error]);

  return (
    <MagicContext.Provider
      value={{
        loadArtistMagic,
        loadMagic,
        modal,
        setModal,
        toggle,
      }}
    >
      {children}
      <MagicModal isOpen={modal} toggle={toggle} error={error} />
    </MagicContext.Provider>
  );
};

MagicProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

MagicModal.propTypes = {
  error: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
};
