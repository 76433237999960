import api from 'services/api';
import { convertArrayToObject } from 'lib/utils';
import * as t from '../types';
import { getUserArtistsAction } from './artist.actions';
import { getUserTracksAction } from './track.actions';
import { getUserAlbumsAction } from './album.actions';
import { getUserPlaylistsAction } from './playlist.actions';
import { updateUserByIdAction } from './user.actions';

const reloadConnections = target => dispatch => {
  switch (target) {
    case 'artist':
      dispatch(getUserArtistsAction());
      break;

    case 'track':
      dispatch(getUserTracksAction());
      break;

    case 'album':
      dispatch(getUserAlbumsAction());
      break;

    case 'mood':
    case 'playlist':
      dispatch(getUserPlaylistsAction());
      break;

    default:
      break;
  }
};

const reloadTarget = (target, id) => async dispatch => {
  switch (target) {
    case 'user':
      await dispatch(updateUserByIdAction(id));
      break;

    default:
      break;
  }
};

export const getConnectionsAction = target => async (dispatch, getState) => {
  try {
    dispatch({ type: t.GET_CONNECTIONS_REQUEST, payload: target });
    const resp = await api.connectionsByTarget(target);

    if (resp.status === 200 || resp.status === 201) {
      const { connect } = getState();
      const filteredData = resp.data.filter(x => x.active);

      const targetIdObj = convertArrayToObject(filteredData, 'targetId');

      const byTargetId = {
        ...connect.byTargetId,
        [target]: targetIdObj,
      };
      const connectedTargetIds = Object.values(byTargetId).reduce((acc, val) => {
        return {
          ...acc,
          ...val,
        };
      }, {});
      const fetching = { ...connect.fetching, [target]: false };
      const payload = {
        byTargetId,
        connectedTargetIds,
        fetching,
      };

      dispatch({
        type: t.GET_CONNECTIONS_SUCCESS,
        payload,
      });
    } else {
      throw resp.data;
    }
  } catch (e) {
    dispatch({ type: t.GET_CONNECTIONS_FAILURE, payload: target });
  }
};

export const connectAction = (item, target) => async (dispatch, getState) => {
  try {
    const pluralTarget = `${target}s`;
    const { connect } = getState();
    const connection = (connect.byTargetId[pluralTarget] && connect.byTargetId[pluralTarget][item.id]) || false;
    dispatch({ type: t.CONNECT_REQUEST, payload: item.id, target: pluralTarget });

    let resp;
    if (connection && connection.active) {
      resp = await api.disconnect(connection.id);
    } else {
      const data = {
        name: `like_${target}`,
        targetId: item.id,
        targetName: item.name,
        target: `${target}s`,
      };
      resp = await api.connect(data);
    }

    if (resp.status === 200 || resp.status === 201) {
      await dispatch(getConnectionsAction(pluralTarget));
      dispatch({ type: t.CONNECT_SUCCESS, payload: item.id });
      dispatch(reloadConnections(target));
    } else {
      throw resp.data;
    }
  } catch (e) {
    dispatch({ type: t.CONNECT_FAILURE, payload: item.id });
  }
};

export const connectAltAction = data => async dispatch => {
  try {
    dispatch({ type: t.CONNECT_REQUEST, payload: data.targetId });
    const resp = await api.connectAlt(data);
    if (resp.status === 200 || resp.status === 201) {
      await dispatch(reloadTarget(data.target, data.targetId));
      dispatch({ type: t.CONNECT_SUCCESS, payload: data.targetId });
    } else {
      throw resp.data;
    }
  } catch (e) {
    dispatch({ type: t.CONNECT_FAILURE, payload: data.targetId });
  }
};
