import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { firebase } from 'services/firebase';
import { useDispatch } from 'react-redux';

export const UserContext = React.createContext({});

export const UserProvider = ({ children }) => {
  const dispatch = useDispatch();
  const [state, setState] = useState({
    loaded: false,
    user: null,
  });

  const logout = useCallback(() => dispatch({ type: 'LOGOUT' }), [dispatch]);

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(user => {
      if (user) {
        setState({
          loaded: true,
          user,
        });
      } else {
        logout();
        setState({
          loaded: true,
          user: null,
        });
      }
    });
    return () => {
      unsubscribe();
    };
    // eslint-disable-next-line
  }, []);

  return (
    <UserContext.Provider
      value={{
        ...state,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

UserProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
