import React from 'react';
import PropTypes from 'prop-types';

function SvgDeletePlaylist({ fill, ...props }) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 155.043 145.617" {...props}>
      <g data-name="Group 1852" transform="translate(0 .001)" fill={fill}>
        <rect
          data-name="Rectangle 319"
          width={15.513}
          height={93.08}
          rx={7.757}
          transform="rotate(45 -11.05 208.328)"
          opacity={0.996}
        />
        <rect
          data-name="Rectangle 320"
          width={15.514}
          height={93.08}
          rx={7.757}
          transform="rotate(135 49.635 99.434)"
          opacity={0.996}
        />
        <path
          data-name="Path 888"
          d="M65.936 78.755H5.072C2.029 78.755 0 81.861 0 86.522s2.029 7.767 5.072 7.767h60.864c3.043 0 5.072-3.106 5.072-7.767s-2.029-7.767-5.072-7.767z"
        />
        <path
          data-name="Path 889"
          d="M100.973 39.378H7.767a7.768 7.768 0 000 15.535h93.206a7.768 7.768 0 100-15.535z"
        />
        <path data-name="Path 890" d="M100.973 0H7.767a7.768 7.768 0 100 15.535h93.206a7.768 7.768 0 100-15.535z" />
      </g>
    </svg>
  );
}

SvgDeletePlaylist.propTypes = {
  fill: PropTypes.string,
};

SvgDeletePlaylist.defaultProps = {
  fill: '#fff',
};

export default SvgDeletePlaylist;
