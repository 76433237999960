import api from 'services/api';
import { firebase } from 'services/firebase';
import { firstLetterUc } from 'lib/utils';
import * as t from '../types';

export const searchAction = (query, requests, callback = () => {}) => async dispatch => {
  try {
    dispatch({ type: t.SEARCH_REQUEST });
    const resp = await api.search(requests);
    if (resp.status === 200 || resp.status === 201) {
      firebase.analytics().logEvent('search', {
        search_term: query,
      });
      let data = resp.data.results[0];
      data = data.map(item => ({
        id: item.indexName,
        items: item.hits,
        name: item.indexName,
        target: item.indexName,
        title: firstLetterUc(item.indexName),
        button: null,
      }));
      dispatch({ type: t.SEARCH_SUCCESS, payload: { query, data } });
      callback(data, null);
    } else {
      throw resp.data;
    }
  } catch (e) {
    callback(null, e);
    dispatch({ type: t.SEARCH_FAILURE });
  }
};
