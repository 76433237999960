import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useMeasure } from 'react-use';
import { MoreOptions } from 'components/organisms/MoreOptions';
import styled from '@emotion/styled/macro';
import { Wrapper, Poster, Details, Title, PlayBox, DetailBox } from './styled';

const CardContainer = styled.div`
  width: 28rem;
  height: 14rem;
  border-radius: 6px;
  background: ${props => props.color};
  position: relative;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  @media (max-width: ${props => props.theme.breakpoints[1]}) {
    width: 20rem;
    height: 10rem;
  }
`;

const MoodType = styled.p`
  padding: 0 2rem;
  position: absolute;
  z-index: 2;
  color: white;
  font-size: ${props => props.theme.font.size.big};
  font-weight: 700;
  @media (max-width: 750px) {
    font-size: ${props => props.theme.font.size.normal};
  }
`;

export const MoodTile = ({ item, listen, ...props }) => {
  const { name, id } = item;

  const changeColor = () => {
    switch (name) {
      case 'Beast Mode':
        return '#1E4384';
      case 'Work Flow':
        return '#20992C';
      case 'Bed Time':
        return '#DB7B5D';
      case 'Kick Back':
        return '#1C9CEA';
      case 'Love Struck':
        return '#EC1B52';
      case 'Feels':
        return '#4F4F4F';
      case 'Feel Good':
        return '#FCB816';
      default:
        return 'linear-gradient(90deg, #f2994a 0%, #fdb913 100%);';
    }
  };

  return (
    <CardContainer color={changeColor} as={Link} to={`/moods/${id}`}>
      <MoodType {...props} to={`/moods/${id}`}>
        {name}
      </MoodType>
    </CardContainer>
  );
};

MoodTile.propTypes = {
  item: PropTypes.shape({
    artwork: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  listen: PropTypes.bool.isRequired,
};
