import React, { Suspense, useEffect, useState, useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useUser } from 'hooks';
import { AuthModalContext } from 'contexts/AuthModalContext';
import { Loader } from '../Loader';

export const AuthRoute = ({ component: Component, ...rest }) => {
  const { user, loaded } = useUser();
  const { toggleAuthModal } = useContext(AuthModalContext);

  const [state, setState] = useState({
    pending: true,
    loggedIn: false,
  });

  useEffect(() => {
    if (loaded) {
      if (user) {
        setState({
          pending: false,
          loggedIn: true,
        });
      } else {
        toggleAuthModal({ link: rest.location.pathname });
        setState({
          pending: false,
          loggedIn: false,
        });
      }
    }
    // eslint-disable-next-line
  }, [loaded, user]);

  const { pending, loggedIn } = state;
  return pending ? (
    <Loader />
  ) : (
    <Route
      {...rest}
      render={renderProps =>
        loggedIn ? (
          <Suspense fallback={<Loader />}>
            <Component {...renderProps} />
          </Suspense>
        ) : (
          <Redirect
            to={{
              pathname: '/',
            }}
          />
        )
      }
    />
  );
};

AuthRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
};
