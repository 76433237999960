import { RelativeBox } from 'components/atoms';
import React, { memo, useRef, useEffect } from 'react';
import { connectAction, playTracksAction, shufflePlayTracksAction } from 'redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from 'rebass';
import { CameraIcon } from 'components/icons';
import { CommentIcon2 } from 'components/icons';
import { EditPenIcon } from 'components/icons';
import { HeadphonesIcon } from 'components/icons';
import { LikeIcon } from 'components/icons';
import { Link } from 'react-router-dom';
import { MoreOptions } from 'components/organisms/MoreOptions';
import PropTypes from 'prop-types';
import { ShuffleIcon2 } from 'components/icons';
import { WhitePlayIcon } from 'components/icons';
import { commarize } from 'lib/utils';
import { useMedia } from 'react-use';
import moment from 'moment';
import { useToast } from 'hooks';
import { firstLetterUc } from 'lib/utils';
import {
  ArtistName,
  Artwork,
  AvatarOverlay,
  AvatarWrapper,
  BlurBackground,
  // Description,
  DetailBox,
  Details,
  EditArtwork,
  Genre,
  Icons,
  ListenCountText,
  MixButton,
  Name,
  OutlineBtn,
  PictureAndLeft,
  PlayButtons,
  TrackDetails,
  Wrapper,
  InnerWrapper,
  Left,
  ButtonsWrapper,
} from './styled';

//TRACK HEADER

export const TrackHeader = memo(({ className, item, tracks, type }) => {
  const {
    /* commentCount, */
    artwork_full,
    artwork,
    artist_name,
    artist,
    // created,
    listenCount,
    name,
    owner = '',
    year,
    genre_name,
  } = item;
  const dispatch = useDispatch();
  const playTracks = () => dispatch(playTracksAction(tracks, 0, type, item.id));
  const shuffleTracks = () => dispatch(shufflePlayTracksAction(tracks, type, item.id));
  const connect = () => dispatch(connectAction(item, type));
  const isWide = useMedia('(max-width: 400px)');
  const isConnecting = useSelector(state => state.connect.fetching[item.id] || false);
  const { addToast } = useToast();
  const isMounted = useRef(false);
  const isConnected = useSelector(
    state =>
      (state.connect.byTargetId[`${type}s`] &&
        state.connect.byTargetId[`${type}s`][item.id] &&
        state.connect.byTargetId[`${type}s`][item.id].active) ||
      false,
  );

  useEffect(() => {
    if (isMounted.current) {
      if (isConnecting === false && isConnected === true) {
        addToast(`${firstLetterUc(type)} has been added to library!`, { type: 'success' });
      } else if (isConnecting === false && isConnected === false) {
        addToast(`${firstLetterUc(type)} has been removed from library`, { type: 'info' });
      }
    } else {
      isMounted.current = true;
    }
    // eslint-disable-next-line
  }, [isConnected, isConnecting]);

  const toBottom = () => {
    window.scrollTo({ top: document.documentElement.scrollHeight, behavior: 'smooth' });
  };

  return (
    <div>
      <BlurBackground src={artwork} placeholder={type} />
      <RelativeBox style={{ top: isWide ? '-10rem' : '-6rem' }}>
        <Wrapper className={className}>
          <InnerWrapper>
            <Left>
              <PictureAndLeft>
                <Artwork
                  src={artwork_full || artwork}
                  placeholder={type}
                  alt={`${type} ${name} artwork`}
                  useThumbnail={false}
                />
              </PictureAndLeft>
              <Details>
                <Box alignItems="center" justifyContent="space-between" mb="1rem" width="100%">
                  <DetailBox>
                    <TrackDetails>
                      <Genre>
                        {genre_name} {genre_name && year ? `-` : ``} {year}
                      </Genre>
                      {artist_name && <Name ellipsize>{name}</Name>}
                      <ArtistName as={Link} to={owner ? `/users/${owner}` : `/artists/${artist}`}>
                        {artist_name}
                      </ArtistName>
                      {/* {description && <Description>{description}</Description>} */}
                    </TrackDetails>
                    {/* {(created && owner && (
              <Date>
                Added on {format(created, 'MMM do, yyyy')}
                {(owner_name && (
                  <>
                    <Typography.Text>{' by '}</Typography.Text>
                    <Typography.Text color="primary" as={Link} to={`/users/${owner}`}>
                      {owner_name}
                    </Typography.Text>
                  </>
                )) ||
                  null}
              </Date>
            )) ||
              null} */}
                    <Icons>
                      <LikeIcon
                        fill={isConnected ? '#ffa700' : 'transparent'}
                        height="2.5rem"
                        onClick={connect}
                        style={{ cursor: 'pointer', marginRight: '2rem' }}
                      />
                      <CommentIcon2 style={{ cursor: 'pointer', marginRight: '1.5rem' }} onClick={() => toBottom()} />
                      <MoreOptions type="Track" item={item} style={{ cursor: 'pointer' }} />
                    </Icons>
                  </DetailBox>
                </Box>
                {(listenCount && (
                  <Box alignItems="center" mb="1rem">
                    {(listenCount && (
                      <ListenCountText size="small" color="white">
                        <HeadphonesIcon />
                        {commarize(listenCount)}
                      </ListenCountText>
                    )) ||
                      null}

                    {/* {(commentCount && (
              <CommentButton size="small">
                <CommentIcon />
                {commarize(commentCount)}
              </CommentButton>
            )) ||
              null} */}
                  </Box>
                )) ||
                  null}
              </Details>
            </Left>
            {(tracks.length && (
              <ButtonsWrapper>
                <PlayButtons ml={[0, 0, '-0.75rem']}>
                  <MixButton size={isWide ? 'small' : 'normal'} effect onClick={playTracks}>
                    <WhitePlayIcon width="1.5rem" height="1.5rem" /> Play
                  </MixButton>

                  <OutlineBtn size={isWide ? 'small' : 'normal'} effect onClick={shuffleTracks}>
                    <ShuffleIcon2 width="1.5rem" height="1.5rem" /> Shuffle
                  </OutlineBtn>
                </PlayButtons>
              </ButtonsWrapper>
            )) ||
              null}
          </InnerWrapper>
        </Wrapper>
      </RelativeBox>
    </div>
  );
});

//ALBUM HEADER

export const Header = memo(({ className, item, tracks, type }) => {
  const { artwork, artist_name, artist, listenCount, name, owner = '', year, genre_name, artwork_full } = item;
  const dispatch = useDispatch();
  const playTracks = () => dispatch(playTracksAction(tracks, 0, type, item.id));
  const shuffleTracks = () => dispatch(shufflePlayTracksAction(tracks, type, item.id));
  const connect = () => dispatch(connectAction(item, type));
  const isWide = useMedia('(max-width: 400px)');
  const isConnecting = useSelector(state => state.connect.fetching[item.id] || false);
  const { addToast } = useToast();
  const isMounted = useRef(false);

  const isConnected = useSelector(
    state =>
      (state.connect.byTargetId[`${type}s`] &&
        state.connect.byTargetId[`${type}s`][item.id] &&
        state.connect.byTargetId[`${type}s`][item.id].active) ||
      false,
  );

  useEffect(() => {
    if (isMounted.current) {
      if (isConnecting === false && isConnected === true) {
        addToast(`${firstLetterUc(type)} has been added to library!`, { type: 'success' });
      } else if (isConnecting === false && isConnected === false) {
        addToast(`${firstLetterUc(type)} has been removed from library`, { type: 'error' });
      }
    } else {
      isMounted.current = true;
    }
    // eslint-disable-next-line
  }, [isConnected, isConnecting]);

  const toBottom = () => {
    window.scrollTo({ top: document.documentElement.scrollHeight, behavior: 'smooth' });
  };

  return (
    <div>
      <BlurBackground src={artwork} placeholder={type} />

      <Wrapper className={className}>
        <InnerWrapper>
          <Left>
            <PictureAndLeft>
              <Artwork
                src={artwork_full || artwork}
                placeholder={type}
                alt={`${type} ${name} artwork`}
                useThumbnail={false}
              />
            </PictureAndLeft>
            <Details>
              <DetailBox>
                <Box flexDirection="column">
                  <TrackDetails>
                    <Genre>
                      {genre_name} {genre_name && year ? `-` : ``} {year}
                    </Genre>
                    {artist_name && <Name ellipsize>{name}</Name>}
                    {type === 'mood' && <Name ellipsize>{name}</Name>}
                    <ArtistName as={Link} to={owner ? `/users/${owner}` : `/artists/${artist}`}>
                      {artist_name}
                    </ArtistName>
                    {/* {description && <Description>{description}</Description>} */}
                  </TrackDetails>
                  {/* {(created && owner && (
              <Date>
                Added on {format(created, 'MMM do, yyyy')}
                {(owner_name && (
                  <>
                    <Typography.Text>{' by '}</Typography.Text>
                    <Typography.Text color="primary" as={Link} to={`/users/${owner}`}>
                      {owner_name}
                    </Typography.Text>
                  </>
                )) ||
                  null}
              </Date>
            )) ||
              null} */}
                  <Icons>
                    <LikeIcon
                      fill={isConnected ? '#ffa700' : 'transparent'}
                      height="2.5rem"
                      onClick={connect}
                      style={{ cursor: 'pointer', marginRight: '5rem' }}
                    />
                    <CommentIcon2 style={{ cursor: 'pointer', marginRight: '5rem' }} onClick={() => toBottom()} />
                    <MoreOptions type="album" item={item} style={{ cursor: 'pointer' }} />
                  </Icons>
                </Box>
              </DetailBox>
              {(listenCount && (
                <Box alignItems="center" mb="1rem">
                  {(listenCount && (
                    <ListenCountText size="small" color="white">
                      <HeadphonesIcon />
                      {commarize(listenCount)}
                    </ListenCountText>
                  )) ||
                    null}

                  {/* {(commentCount && (
              <CommentButton size="small">
                <CommentIcon />
                {commarize(commentCount)}
              </CommentButton>
            )) ||
              null} */}
                </Box>
              )) ||
                null}
            </Details>
          </Left>
          {(tracks.length && (
            <ButtonsWrapper>
              <PlayButtons ml={[0, 0, '-0.75rem']}>
                <MixButton size={isWide ? 'small' : 'normal'} effect onClick={playTracks}>
                  <WhitePlayIcon width="1.5rem" height="1.5rem" /> Play
                </MixButton>

                <OutlineBtn size={isWide ? 'small' : 'normal'} effect onClick={shuffleTracks}>
                  <ShuffleIcon2 width="1.5rem" height="1.5rem" /> Shuffle
                </OutlineBtn>
              </PlayButtons>
            </ButtonsWrapper>
          )) ||
            null}
        </InnerWrapper>
      </Wrapper>
    </div>
  );
});

//PLAYLIST HEADER
export const PlaylistHeader = memo(({ className, item, tracks, type }) => {
  const {
    /* commentCount, */
    // artwork_full,
    artwork,
    artist,
    modified,
    // description,
    listenCount,
    name,
    owner = '',
    playlistOwner,
  } = item;
  const dispatch = useDispatch();

  const playTracks = () => dispatch(playTracksAction(tracks, 0, type, item.id));
  const shuffleTracks = () => dispatch(shufflePlayTracksAction(tracks, type, item.id));
  const connect = () => dispatch(connectAction(item, type));
  const isWide = useMedia('(max-width: 400px)');
  const isConnecting = useSelector(state => state.connect.fetching[item.id] || false);
  const { addToast } = useToast();
  const isMounted = useRef(false);

  const isConnected = useSelector(
    state =>
      (state.connect.byTargetId[`${type}s`] &&
        state.connect.byTargetId[`${type}s`][item.id] &&
        state.connect.byTargetId[`${type}s`][item.id].active) ||
      false,
  );

  useEffect(() => {
    if (isMounted.current) {
      if (isConnecting === false && isConnected === true) {
        addToast(`${firstLetterUc(type)} has been added to library!`, { type: 'success' });
      } else if (isConnecting === false && isConnected === false) {
        addToast(`${firstLetterUc(type)} has been removed from library`, { type: 'info' });
      }
    } else {
      isMounted.current = true;
    }
    // eslint-disable-next-line
  }, [isConnected, isConnecting]);

  const toBottom = () => {
    window.scrollTo({ top: document.documentElement.scrollHeight, behavior: 'smooth' });
  };
  const today = moment().format('MMM Do YY');
  const dateChecker = moment(modified).format('MMM Do YY');
  const yesterday = moment(modified)
    .subtract(1, 'days')
    .format('MMM Do YY');

  const renderUpdata = () => {
    if (today === dateChecker) {
      return 'today';
    } else if (dateChecker === yesterday) {
      return 'yesterday';
    } else {
      return 'recently';
    }
  };

  return (
    <div>
      <BlurBackground src={artwork} placeholder={type} />

      <Wrapper className={className}>
        <InnerWrapper>
          <Left>
            <PictureAndLeft>
              <Artwork src={artwork} placeholder={type} alt={`${type} ${name} artwork`} useThumbnail={false} />
            </PictureAndLeft>
            <Details>
              <Box alignItems="center" justifyContent="space-between" mb="1rem" width="100%">
                <DetailBox>
                  <TrackDetails>
                    <Genre>
                      Updated
                      <span style={{ fontWeight: '500', marginLeft: '0.5rem' }}>{renderUpdata()}</span>
                    </Genre>
                    {<Name ellipsize>{name}</Name>}
                    <ArtistName as={Link} to={owner ? `/users/${owner}` : `/artists/${artist}`}>
                      by <span style={{ fontWeight: '700' }}>{playlistOwner}</span>
                    </ArtistName>
                    {/* {description && <Description>{description}</Description>} */}
                  </TrackDetails>
                  {/* {(created && owner && (
              <Date>
                Added on {format(created, 'MMM do, yyyy')}
                {(owner_name && (
                  <>
                    <Typography.Text>{' by '}</Typography.Text>
                    <Typography.Text color="primary" as={Link} to={`/users/${owner}`}>
                      {owner_name}
                    </Typography.Text>
                  </>
                )) ||
                  null}
              </Date>
            )) ||
              null} */}
                  <Icons>
                    <LikeIcon
                      fill={isConnected ? '#ffa700' : 'transparent'}
                      height="2.5rem"
                      onClick={connect}
                      style={{ cursor: 'pointer', marginRight: '5rem' }}
                    />
                    <CommentIcon2 style={{ cursor: 'pointer', marginRight: '5rem' }} onClick={toBottom} />
                    <MoreOptions type="playlist" item={item} style={{ cursor: 'pointer' }} />
                  </Icons>
                </DetailBox>
              </Box>
              {(listenCount && (
                <Box alignItems="center" mb="1rem">
                  {(listenCount && (
                    <ListenCountText size="small" color="white">
                      <HeadphonesIcon />
                      {commarize(listenCount)}
                    </ListenCountText>
                  )) ||
                    null}

                  {/* {(commentCount && (
              <CommentButton size="small">
                <CommentIcon />
                {commarize(commentCount)}
              </CommentButton>
            )) ||
              null} */}
                </Box>
              )) ||
                null}
            </Details>
          </Left>
          {(tracks.length && (
            <ButtonsWrapper>
              <PlayButtons ml={[0, 0, '-0.75rem']}>
                <MixButton size={isWide ? 'small' : 'normal'} effect onClick={playTracks}>
                  <WhitePlayIcon width="1.5rem" height="1.5rem" /> Play
                </MixButton>

                <OutlineBtn size={isWide ? 'small' : 'normal'} effect onClick={shuffleTracks}>
                  <ShuffleIcon2 width="1.5rem" height="1.5rem" /> Shuffle
                </OutlineBtn>
              </PlayButtons>
            </ButtonsWrapper>
          )) ||
            null}
        </InnerWrapper>
      </Wrapper>
    </div>
  );
});

//PERSONAL PLAYLIST HEADER

export const PersonalPlaylistHeader = memo(({ className, item, tracks, type }) => {
  const {
    /* commentCount, */

    artwork,
    artist,
    modified,
    // description,
    name,
    owner = '',
    playlistOwner,
    id,
  } = item;
  const dispatch = useDispatch();
  const playTracks = () => dispatch(playTracksAction(tracks, 0, type, item.id));
  const shuffleTracks = () => dispatch(shufflePlayTracksAction(tracks, type, item.id));
  const connect = () => dispatch(connectAction(item, type));
  const isWide = useMedia('(max-width: 400px)');
  const isConnecting = useSelector(state => state.connect.fetching[item.id] || false);
  const { addToast } = useToast();
  const isMounted = useRef(false);
  // const posterRef = useRef();
  // const [poster, setPoster] = useState(null);

  const isConnected = useSelector(
    state =>
      (state.connect.byTargetId[`${type}s`] &&
        state.connect.byTargetId[`${type}s`][item.id] &&
        state.connect.byTargetId[`${type}s`][item.id].active) ||
      false,
  );

  useEffect(() => {
    if (isMounted.current) {
      if (isConnecting === false && isConnected === true) {
        addToast(`${firstLetterUc(type)} has been added to library!`, { type: 'success' });
      } else if (isConnecting === false && isConnected === false) {
        addToast(`${firstLetterUc(type)} has been removed from library`, { type: 'info' });
      }
    } else {
      isMounted.current = true;
    }
    // eslint-disable-next-line
  }, [isConnected, isConnecting]);

  // const toBottom = () => {
  //   window.scrollTo({ top: document.documentElement.scrollHeight, behavior: 'smooth' });
  // };

  return (
    <div>
      <BlurBackground src={artwork} placeholder={type} />

      <Wrapper className={className}>
        <InnerWrapper>
          <Left>
            <PictureAndLeft>
              <Artwork src={artwork} placeholder={type} alt={`${type} ${name} artwork`} useThumbnail={false} />
            </PictureAndLeft>
            <Details>
              <Box alignItems="center" justifyContent="space-between" mb="1rem" width="100%">
                <DetailBox>
                  <TrackDetails>
                    <Genre>
                      Updated on
                      <span style={{ fontWeight: '500', marginLeft: '1rem' }}>
                        {modified && moment(modified).format('DD-MM-YYYY')}
                      </span>
                    </Genre>
                    <Name ellipsize>{name}</Name>
                    <ArtistName
                      as={Link}
                      to={owner ? `/users/${owner}` : `/artists/${artist}`}
                      style={{ display: playlistOwner === 'uduX' ? 'block' : 'none' }}
                    >
                      by <span style={{ fontWeight: '400' }}>{playlistOwner}</span>
                    </ArtistName>
                    {/* {description && <Description>{description}</Description>} */}
                  </TrackDetails>
                  {/* {(created && owner && (
              <Date>
                Added on {format(created, 'MMM do, yyyy')}
                {(owner_name && (
                  <>
                    <Typography.Text>{' by '}</Typography.Text>
                    <Typography.Text color="primary" as={Link} to={`/users/${owner}`}>
                      {owner_name}
                    </Typography.Text>
                  </>
                )) ||
                  null}
              </Date>
            )) ||
              null} */}
                  <Icons>
                    <LikeIcon
                      fill={isConnected ? '#ffa700' : 'transparent'}
                      height="2.5rem"
                      onClick={connect}
                      style={{ cursor: 'pointer', marginRight: '2rem' }}
                    />
                    <Link to={`/edit-playlist/${id}`}>
                      <EditPenIcon style={{ cursor: 'pointer', marginRight: '1.5rem' }} />
                    </Link>
                    {/* <CommentIcon2 style={{ cursor: 'pointer' }} onClick={() => toBottom()} /> */}
                    <MoreOptions type="playlist" item={item} style={{ cursor: 'pointer' }} />
                  </Icons>
                </DetailBox>
              </Box>
            </Details>
          </Left>
          {(tracks.length && (
            <ButtonsWrapper>
              <PlayButtons ml={[0, 0, '-0.75rem']}>
                <MixButton
                  size={isWide ? 'small' : 'normal'}
                  effect
                  onClick={playTracks}
                  style={{ padding: '0.7rem 3rem' }}
                >
                  <WhitePlayIcon width="1.5rem" height="1.5rem" /> Play
                </MixButton>

                <OutlineBtn size={isWide ? 'small' : 'normal'} effect onClick={shuffleTracks}>
                  <ShuffleIcon2 width="1.5rem" height="1.5rem" /> Shuffle
                </OutlineBtn>
              </PlayButtons>
            </ButtonsWrapper>
          )) ||
            null}
        </InnerWrapper>
      </Wrapper>
    </div>
  );
});

//NEW PLAYLIST HEADER
export const NewPlaylistHeader = memo(({ className, item, type }) => {
  const {
    /* commentCount, */
    artwork,
    name,
  } = item;

  const avatarRef = useRef();
  // const isWide = useMedia('(max-width: 400px)');

  return (
    <div style={{ marginBottom: '-100px' }}>
      <BlurBackground src={artwork} placeholder={type} />

      <Wrapper className={className} style={{ top: '-18px' }}>
        <InnerWrapper>
          <Left>
            <PictureAndLeft>
              <AvatarWrapper>
                <EditArtwork src={artwork} placeholder={type} alt={`${type} ${name} artwork`} />
                <AvatarOverlay>
                  <input ref={avatarRef} type="file" accept="image/*" />
                  <CameraIcon />
                </AvatarOverlay>
              </AvatarWrapper>
            </PictureAndLeft>
            <Details>
              <Box alignItems="center" justifyContent="space-between" mb="1rem" width="100%">
                <DetailBox>
                  <TrackDetails>
                    {/* <Genre>Updated {modified && moment(modified).format('DD-MM-YYYY')}</Genre> */}
                    {<Name ellipsize>{name}</Name>}
                    {/* <ArtistName as={Link} to={owner ? `/users/${owner}` : `/artists/${artist}`}>
                    by {playlistOwner}
                  </ArtistName> */}
                    {/* {description && <Description>{description}</Description>} */}
                  </TrackDetails>
                  {/* {(created && owner && (
              <Date>
                Added on {format(created, 'MMM do, yyyy')}
                {(owner_name && (
                  <>
                    <Typography.Text>{' by '}</Typography.Text>
                    <Typography.Text color="primary" as={Link} to={`/users/${owner}`}>
                      {owner_name}
                    </Typography.Text>
                  </>
                )) ||
                  null}
              </Date>
            )) ||
              null} */}
                  {/* <Icons>
                  <LikeIcon
                    fill={isConnected ? '#ffa700' : 'transparent'}
                    height="2.5rem"
                    onClick={connect}
                    style={{ cursor: 'pointer' }}
                  />
                  <CommentIcon2 style={{ cursor: 'pointer' }} />
                  <MoreOptions type="track" item={item} />
                </Icons> */}
                </DetailBox>
              </Box>
              {/* {(listenCount && (
              <Box alignItems="center" mb="1rem">
                {(listenCount && (
                  <ListenCountText size="small" color="white">
                    <HeadphonesIcon />
                    {commarize(listenCount)}
                  </ListenCountText>
                )) ||
                  null}

                {(commentCount && (
              <CommentButton size="small">
                <CommentIcon />
                {commarize(commentCount)}
              </CommentButton>
            )) ||
              null}
              </Box>
            )) ||
              null} */}
            </Details>
          </Left>
          {/* {(tracks.length && (
            <PlayButtons ml={[0, 0, '-0.75rem']}>
              <MixButton onClick={playTracks} style={{ padding: '0.7rem 3rem' }}>
                <WhitePlayIcon /> Play
              </MixButton>

              <OutlineBtn onClick={shuffleTracks} textColor="switchingBg">
                <ShuffleIcon2 /> Shuffle
              </OutlineBtn>
            </PlayButtons>
          )) ||
            null} */}
        </InnerWrapper>
      </Wrapper>
    </div>
  );
});

Header.propTypes = {
  className: PropTypes.string,
  error: PropTypes.string,
  item: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  tracks: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired,
};

PlaylistHeader.propTypes = {
  className: PropTypes.string,
  error: PropTypes.string,
  item: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  tracks: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired,
};

NewPlaylistHeader.propTypes = {
  className: PropTypes.string,
  error: PropTypes.string,
  item: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  tracks: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired,
};

Header.defaultProps = {
  className: '',
  error: '',
};

PlaylistHeader.defaultProps = {
  className: '',
  error: '',
};

NewPlaylistHeader.defaultProps = {
  className: '',
  error: '',
};

// linear-gradient(361.2deg,#08080a 0%,rgba(8,8,10,0) 52.83%
