export const GET_ARTIST_REQUEST = 'GET_ARTIST_REQUEST';
export const GET_ARTIST_SUCCESS = 'GET_ARTIST_SUCCESS';
export const GET_ARTIST_FAILURE = 'GET_ARTIST_FAILURE';

export const GET_SIMILAR_ARTIST_REQUEST = 'GET_SIMILAR_ARTIST_REQUEST';
export const GET_SIMILAR_ARTIST_SUCCESS = 'GET_SIMILAR_ARTIST_SUCCESS';
export const GET_SIMILAR_ARTIST_FAILURE = 'GET_SIMILAR_ARTIST_FAILURE';

export const GET_USER_ARTISTS_REQUEST = 'GET_USER_ARTISTS_REQUEST';
export const GET_USER_ARTISTS_SUCCESS = 'GET_USER_ARTISTS_SUCCESS';
export const GET_USER_ARTISTS_FAILURE = 'GET_USER_ARTISTS_FAILURE';
