import { remoteConfig } from 'services/firebase';
import * as t from '../types';

const getConfig = async () => {
  await remoteConfig.fetchAndActivate();
  const values = remoteConfig.getAll();
  const data = Object.entries(values).reduce((acc, [key, value]) => {
    acc[key] = value._value || '';
    return acc;
  }, {});
  return data;
};

export const getRemoteConfigAction = (callback = () => {}) => async dispatch => {
  try {
    dispatch({ type: t.GET_REMOTE_CONFIG_REQUEST });
    const data = await getConfig();
    dispatch({ type: t.GET_REMOTE_CONFIG_SUCCESS, payload: data });
    callback(data, null);
  } catch (e) {
    dispatch({ type: t.GET_REMOTE_CONFIG_FAILURE });
    callback(null, e);
  }
};
