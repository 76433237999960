import React, { memo, useRef } from 'react';
import { animated, useTransition } from 'react-spring';
import { Box } from 'rebass';
import { CancelIcon } from 'components/icons';
import Portal from 'components/organisms/Portal';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import styled from '@emotion/styled/macro';

Box.defaultProps = {
  display: 'flex',
};

const Content = styled(animated.div)`
  background: #131516;
  border-radius: 12px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
  margin: 6rem 1rem 6rem;
  position: relative;
  width: 100%;
  z-index: 10;
  opacity: 0.97;
  ${({ theme }) => theme.mq.md`
  margin-top: 10rem;
  `}

  ${({ size }) => {
    if (size === 'sm') {
      return css`
        max-width: 36rem;
      `;
    }
    if (size === 'md') {
      return css`
        max-width: 50rem;
      `;
    }
    if (size === 'lg') {
      return css`
        max-width: 72rem;
      `;
    }
  }}
`;

const Wrapper = styled(animated.div)`
  align-items: ${({ centered }) => (centered ? 'center' : 'flex-start')};
  backdrop-filter: blur(10px);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  opacity: 1;
  overflow: scroll;
  pointer-events: auto;
  position: fixed;
  right: 0;
  top: 0;
  visibility: visible;
  z-index: 10;
`;

const ModalHeader = styled(Box)`
  align-items: center;
  padding-bottom: 0;
  padding: 1.5rem 2rem;
  position: relative;
  font-size: 2rem;
  h1,
  h2,
  h3,
  p {
    color: ${({ theme }) => theme.colors.switchingText};
  }

  ${({ theme }) => theme.mq.md`
    padding: 2rem 3.5rem;
    padding-bottom: 0;
  `}
`;

const ModalBody = styled(Box)`
  flex-direction: column;
  width: 100%;
`;

ModalBody.defaultProps = { py: ['2rem', '2.5rem'], px: ['2rem', '3.5rem'] };

const ModalFooter = styled.div`
  padding: 1.5rem 2rem;
  position: relative;
`;

const CloseContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  svg {
    position: absolute;
    top: -18.5px;
    cursor: pointer;
  }
`;

const CloseIcon = props => <CancelIcon width={28} height={28} {...props} />;

const Modal = memo(({ close, children, size, isOpen, toggle, showCloseIcon, ...rest }) => {
  const node = useRef(null);
  const onToggle = (e, force) => {
    e.stopPropagation();
    if (force || !node.current.contains(e.target)) {
      toggle();
    }
  };

  const transition = useTransition(isOpen, null, {
    from: { opacity: 0, transform: 'translateY(-50px)' },
    enter: { opacity: 1, transform: 'translateY(0)' },
    leave: { opacity: 0, transform: 'translateY(-50px)' },
    config: {
      duration: 300,
    },
    trail: 100,
  });

  return transition.map(({ item, key, props }) => {
    return (
      item && (
        <Portal>
          <Wrapper onClick={onToggle} key={key} style={{ opacity: props.opacity }} {...rest}>
            <Content ref={node} style={{ transform: props.transform }} size={size}>
              <CloseContainer onClick={e => onToggle(e, true)}>{showCloseIcon && <CloseIcon />}</CloseContainer>
              {children}
            </Content>
          </Wrapper>
        </Portal>
      )
    );
  });
});

Modal.Header = ModalHeader;
Modal.Body = ModalBody;
Modal.Footer = ModalFooter;

Modal.defaultProps = {
  close: () => {},
  showCloseIcon: false,
  size: 'sm',
};

Modal.propTypes = {
  close: PropTypes.func,
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool.isRequired,
  showCloseIcon: PropTypes.bool,
  size: PropTypes.string,
  toggle: PropTypes.func.isRequired,
};

export { Modal };
