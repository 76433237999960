import styled from '@emotion/styled';
import { Button } from 'components/atoms';

// Payment confirmation
export const BuyTicketWrapper = styled.section`
  display: block;
  width: 100%;
  margin: 5rem 0;
  padding: 0 2rem;
`;
export const EventTitle = styled.p`
  margin: 1rem 0;
  font-size: 2rem;
  font-weight: 700;
`;

export const PaymentSuccessfulWrapper = styled.section`
  display: block;
  width: 100%;
  text-align: center;
  margin-bottom: 8rem;
  p {
    width: 60%;
    margin: 1rem auto;
  }
`;

export const ActionButton = styled(Button)`
  margin: 10rem auto 0 auto;
  display: flex;
  width: 100%;
  justify-content: center;
  marginbottom: 1.5rem;
  background: linear-gradient(270.02deg, #fdb913 0.02%, #f2994a 99.98%);
`;
