import React from 'react';
import styled from '@emotion/styled/macro';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import { Button } from './Button';
import UilBars from 'components/icons/Bars';
import UilMultiply from 'components/icons/Multiply';
// import { useMedia } from 'react-use';

const HamburgerBar = styled.span`
  background: ${({ color, theme }) => theme.colors[color]};
  border-radius: 2px;
  content: '';
  display: block;
  height: 0.2rem;
  position: relative;
  transition: 0.3s all;
  width: 1.8rem;
  &:not(:last-child) {
    margin-bottom: 3px;
  }
`;

const HamBurgerButton = styled(Button)`
  cursor: pointer;
  display: block;
  padding: 0;
  position: relative;
  border-radius: 0;
  padding: 0.1rem 1rem;
  transition: 0.3s all;
  svg {
    width: 2rem;
  }
  // background: linear-gradient(270.02deg, #fdb913 0.02%, #f2994a 99.98%);
  ${({ active }) =>
    active &&
    css`
      ${HamburgerBar} {
        &:first-child {
          transform: rotate(45deg);
          top: 6px;
        }
        &:nth-of-type(2) {
          opacity: 0;
        }
        &:last-child {
          transform: rotate(-45deg);
          top: -4px;
        }
      }
    `}
  &:hover {
    background: ${({ theme }) => theme.colors.primary};
  }
  @media (max-width: 550px) {
    svg {
      width: 1.5rem;
    }
  }
`;

export const Hamburger = ({ textColor, showSidebar, ...props }) => {
  // const [sidebar, setSidebar] = useState(false);
  // const isWide = useMedia('(max-width: 1160px)');

  return (
    <HamBurgerButton {...props} aria-label="Hamburger">
      {showSidebar ? <UilMultiply color="#000" /> : <UilBars color="#000" />}
    </HamBurgerButton>
  );
};

Hamburger.propTypes = {
  textColor: PropTypes.string,
};

Hamburger.defaultProps = {
  textColor: 'black',
};
