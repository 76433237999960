import React, { useCallback, useMemo, useState } from 'react';
import { animated, useTransition } from 'react-spring';

import PropTypes from 'prop-types';
import { Toast } from 'components/molecules/Toast';
import { generateID } from 'lib/utils';

const DEFAULT_OPTIONS = {
  autoClose: 4,
  closeOnClick: true,
  pauseOnHover: true,
  type: 'default',
};

export const ToastContext = React.createContext({});

export const ToastProvider = ({ children }) => {
  const [toasts, setToasts] = useState([]);
  const addToast = useCallback(
    (text, options = {}) => {
      const id = generateID();
      if (typeof text === 'string') setToasts([{ id, text, options: { ...DEFAULT_OPTIONS, ...options } }, ...toasts]);
    },
    [toasts],
  );

  const remove = useCallback(id => setToasts(toasts.filter(t => t.id !== id)), [toasts]);

  const providerValue = useMemo(() => {
    return { addToast, remove };
  }, [addToast, remove]);

  const transitions = useTransition(toasts, item => item.id, {
    from: { transform: 'translateX(27rem)', opacity: '0' },
    enter: { transform: 'translateX(0)', opacity: '1' },
    leave: { transform: 'translateX(27rem)', opacity: '0' },
  });

  return (
    <ToastContext.Provider value={providerValue}>
      {children}
      {(toasts.length && (
        <Toast.Container active={toasts.length}>
          {transitions.map(({ item, props, key }) => (
            <animated.div key={key} style={props}>
              <Toast.Item remove={() => remove(item.id)} {...item} />
            </animated.div>
          ))}
        </Toast.Container>
      )) ||
        null}
    </ToastContext.Provider>
  );
};

ToastProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
