import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import ReduxThunk from 'redux-thunk';
import storage from 'redux-persist/lib/storage';
import reducers from './reducers';
let composeEnhancers = compose;
if (process.env.NODE_ENV === 'development') {
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
}

const rootPersistConfig = {
  key: 'uduX',
  storage,
  whitelist: [
    // 'album',
    // 'artist',
    'auth',
    // 'comment',
    // 'connect',
    // 'mood',
    'player',
    //'playlist',
    'profile',
    // 'screen',
    // 'search',
    // 'statistics',
    // 'track',
    // 'upload',
  ],
};

const persistedReducer = persistReducer(rootPersistConfig, reducers);

export default () => {
  const store = createStore(persistedReducer, {}, composeEnhancers(applyMiddleware(ReduxThunk)));
  const persistor = persistStore(store);
  return { store, persistor };
};
