export const GET_VIDEO_REQUEST = 'GET_VIDEO_REQUEST';
export const GET_VIDEO_SUCCESS = 'GET_VIDEO_SUCCESS';
export const GET_VIDEO_FAILURE = 'GET_VIDEO_FAILURE';

export const GET_VIDEO_SCREEN_REQUEST = 'GET_VIDEO_SCREEN_REQUEST';
export const GET_VIDEO_SCREEN_SUCCESS = 'GET_VIDEO_SCREEN_SUCCESS';
export const GET_VIDEO_SCREEN_FAILURE = 'GET_VIDEO_SCREEN_FAILURE';

export const GET_VIDEO_BY_TAG_SUCCESS = 'GET_VIDEO_BY_TAG_SUCCESS';

export const GET_VIDEO_PLAYLIST_REQUEST = 'GET_VIDEO_PLAYLIST_REQUEST';
export const GET_VIDEO_PLAYLIST_SUCCESS = 'GET_VIDEO_PLAYLIST_SUCCESS';
export const GET_VIDEO_PLAYLIST_FAILURE = 'GET_VIDEO_PLAYLIST_FAILURE';
