import React, { useState } from 'react';
import styled from '@emotion/styled/macro';

const Range = styled.input`
${({ progress }) =>
  `background: linear-gradient(to right, #F2994A 0%, #FDB913 ${progress}%, #787880 ${progress}%, #787880 100%);`}
 border: none;
 border-radius: 8px;
 height: 4px;
 width: 100%;
 outline: none;
 transition: background 450ms ease-in;
 -webkit-appearance: none;

   &::-webkit-slider-runnable-track {
       height: 10px;
       -webkit-appearance: auto;
       color: red;
       margin-top: -1px;
   }

   &::-webkit-slider-thumb {
       width: 20px;
       height: 20px;
       -webkit-appearance: none;
       cursor: ew-resize;
       background: #fff;
       margin-top: -5px;
       border-radius: 50%;
       // box-shadow: -200px 0 0 200px #fdb913;
 }
}
`;
const RangeInput = ({ onChange, value, max, ...props }) => {
  const [amount, setAmount] = useState(Math.floor(max / 2));
  const [progress, setProgress] = useState((max / 2 / max) * 100);
  const handleInputChange = e => {
    const value = e.target.value;
    setAmount(Math.floor(value));
    onChange(value);
    setProgress((value / max) * 100);
  };
  return <Range type="range" progress={progress} value={amount} max={max} {...props} onChange={handleInputChange} />;
};
export default RangeInput;
