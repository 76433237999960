import React from 'react';
import PropTypes from 'prop-types';

function SvgOptions({ fill, ...props }) {
  return (
    <svg width="7" height="24" viewBox="0 0 7 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect
        x="0.961426"
        y="17.9231"
        width="5.07692"
        height="5.07692"
        rx="2"
        stroke="url(#paint0_linear)"
        strokeLinecap="round"
      />
      <rect
        x="0.961426"
        y="9.46155"
        width="5.07692"
        height="5.07692"
        rx="2"
        stroke="url(#paint1_linear)"
        strokeLinecap="round"
      />
      <rect
        x="0.961426"
        y="1"
        width="5.07692"
        height="5.07692"
        rx="2"
        stroke="url(#paint2_linear)"
        strokeLinecap="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="3.49989"
          y1="17.9231"
          x2="3.49989"
          y2="23"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDB913" />
          <stop offset="1" stopColor="#F2994A" />
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="3.49989"
          y1="9.46155"
          x2="3.49989"
          y2="14.5385"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDB913" />
          <stop offset="1" stopColor="#F2994A" />
        </linearGradient>
        <linearGradient id="paint2_linear" x1="3.49989" y1="1" x2="3.49989" y2="6.07692" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FDB913" />
          <stop offset="1" stopColor="#F2994A" />
        </linearGradient>
      </defs>
    </svg>
  );
}

SvgOptions.propTypes = {
  fill: PropTypes.string,
};

SvgOptions.defaultProps = {
  fill: '#fcb316',
};

export default SvgOptions;
