export const OPEN_STREAMERS = 'OPEN_STREAMERS';
export const OPEN_COMMENTS = 'OPEN_COMMENTS';
export const OPEN_CART = 'OPEN_CART';
export const OPEN_SHARE = 'OPEN_SHARE';
export const LIKE_LIVESTREAM = 'LIKE_LIVESTREAM';
export const MAKE_IT_RAIN = 'MAKE_IT_RAIN';
export const HIDE_PANEL = 'HIDE_PANEL';
export const OPEN_BADGE = 'OPEN_BADGE';
export const BUY_TICKET = 'BUY_TICKET';
export const MAKE_PAYMENT = 'MAKE_PAYMENT';
export const SET_REMINDER_REQUEST = 'SET_REMINDER_REQUEST';
export const SET_REMINDER_SUCCESS = 'SET_REMINDER_SUCCESS';
export const SET_REMINDER_FAILURE = 'SET_REMINDER_FAILURE';
