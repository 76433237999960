import * as t from '../types';

const INITIAL_STATE = {
  data: {},
  fetching: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case t.STATISTICS_REQUEST:
      return { ...state, fetching: true };

    case t.STATISTICS_SUCCESS:
      return { ...state, fetching: false };

    case t.STATISTICS_FAILURE:
      return { ...state, fetching: false };

    case t.LOGOUT:
      return INITIAL_STATE;

    default:
      return { ...state };
  }
};
