import { Artist, Artwork, Title } from './styled';
import React, { useEffect, useRef } from 'react';
import { formatTime, isObjEmpty } from 'lib/utils';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from 'rebass';
import { Draggable } from 'react-beautiful-dnd';
import { LikeIcon } from 'components/icons';
import { MoreOptions } from 'components/organisms/MoreOptions';
import { MoveIcon } from 'components/icons';
import PropTypes from 'prop-types';
import { connectAction } from 'redux/actions';
import styled from '@emotion/styled/macro';
import { ExplicitIcon } from 'components/icons';

const Wrapper = styled(Box)`
  align-items: center;
  cursor: pointer;
  display: flex;
  position: relative;
  user-select: none;

  &:not(:last-child) {
    margin-bottom: 1rem;
  }
  @media (max-width: 480px) {
    svg {
      width: 1.5rem;
    }
  }
`;

export const QueueItem = ({ active, item, index, onPlay, close }) => {
  const node = useRef();
  const { artist_name, artwork, duration, id, name, parental_warning } = item;
  const dispatch = useDispatch();
  const connect = () => dispatch(connectAction(item, 'track'));
  const currentItem = useSelector(state => state.player.currentItem);
  const isConnected = useSelector(
    state =>
      (state.connect.byTargetId.tracks &&
        state.connect.byTargetId.tracks[id] &&
        state.connect.byTargetId.tracks[id].active) ||
      false,
  );
  const currentIndex = useSelector(state => state.player.currentIndex);
  const isCurrent = !isObjEmpty(currentItem) && id === currentItem.id;

  useEffect(() => {
    if (index === currentIndex) {
      if (node && node.current) {
        node.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [active, currentIndex, index]);

  return (
    <Draggable id={id} draggableId={`queue-list-${id}`} key={id} index={index} shouldRespectForcePress>
      {provided => (
        <Wrapper
          prev={index < currentIndex}
          onClick={onPlay}
          active={isCurrent}
          ref={ref => {
            provided.innerRef(ref);
            node.current = ref;
          }}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <Box mr={['1rem', '1.5rem']} flexShrink="0">
            <MoveIcon height="2rem" width="2rem" style={{ marginRight: '1.5rem', flexShrink: 0 }} />
          </Box>

          <Box mr={['1rem', '1.5rem']} flexShrink="0">
            <Artwork src={artwork} placeholder="track" spinnerSize="1rem" alt={name} />
          </Box>

          <Box flexDirection="column" mr="5rem">
            <Title ellipsize onClick={close} style={{ color: currentItem.title === name ? '#ffa700' : '#fff' }}>
              {name}
              {parental_warning === 'Explicit' && <ExplicitIcon />}
            </Title>
            <Artist ellipsize onClick={close}>
              {artist_name} - {formatTime(duration)}
            </Artist>
          </Box>

          <Box ml="auto" mr={['1rem', '1.5rem']} flexShrink="0">
            <LikeIcon
              onClick={e => {
                e.stopPropagation();
                connect();
              }}
              fill={isConnected === true ? 'url(#paint0_linear)' : 'transparent'}
              style={{ cursor: 'pointer' }}
            />
          </Box>
          <Box flexShrink="0">
            <MoreOptions type="queue" item={item} />
          </Box>
        </Wrapper>
      )}
    </Draggable>
  );
};

QueueItem.propTypes = {
  active: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  item: PropTypes.shape({
    artist_name: PropTypes.string.isRequired,
    artwork: PropTypes.string.isRequired,
    duration: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  onPlay: PropTypes.func.isRequired,
};
