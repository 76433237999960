import PropTypes from 'prop-types';
import React from 'react';

function SvgYellowFacebook({ fill, ...props }) {
  return (
    <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.47742 0H15.4248C19.0819 0 22.0476 3.07862 22.0476 6.875V15.125C22.0476 18.9214 19.0819 22 15.4248 22H7.47742C3.82031 22 0.854614 18.9214 0.854614 15.125V6.875C0.854614 3.07862 3.82031 0 7.47742 0ZM15.4248 19.9375C17.9812 19.9375 20.0608 17.7787 20.0608 15.125V6.875C20.0608 4.22125 17.9812 2.0625 15.4248 2.0625H7.47746C4.92105 2.0625 2.84149 4.22125 2.84149 6.875V15.125C2.84149 17.7787 4.92105 19.9375 7.47746 19.9375H15.4248Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.15283 11C6.15283 7.96263 8.52512 5.5 11.4511 5.5C14.377 5.5 16.7493 7.96263 16.7493 11C16.7493 14.0374 14.377 16.5 11.4511 16.5C8.52512 16.5 6.15283 14.0374 6.15283 11ZM8.13971 11C8.13971 12.8948 9.62587 14.4375 11.4511 14.4375C13.2764 14.4375 14.7625 12.8948 14.7625 11C14.7625 9.10388 13.2764 7.5625 11.4511 7.5625C9.62587 7.5625 8.13971 9.10388 8.13971 11Z"
        fill={fill}
      />
      <ellipse cx="17.1467" cy="5.08737" rx="0.706024" ry="0.732875" fill="#FDB913" />
    </svg>
  );
}

SvgYellowFacebook.propTypes = {
  fill: PropTypes.string,
};

SvgYellowFacebook.defaultProps = {
  fill: 'FDB913',
};

export default SvgYellowFacebook;
