import React, { useContext, useState } from 'react';
import RangeInput from 'components/atoms/Form/RangeInput';
import { PinIcon } from 'components/icons';
import { AddMoneyIcon } from 'components/icons';
import {
  Balance,
  // CommentBox,
  RainAmountWrapper,
  AmountToRain,
  TimeStamp,
  InitMakeItRainWrapper,
  // MakeComment,
  // Comment,
  // Avatar,
  // CommentContent,
  // Username,
} from './styled';
import { LivestreamContext } from 'contexts/LivestreamContext';
import { useSelector } from 'react-redux';
import CommentBox from 'components/molecules/CommentBox';

const InitMakeItRain = ({ amount, maxValue, callback }) => {
  const {
    user: { firstName, lastName },
  } = useSelector(state => state.profile);
  const [position, setPosition] = useState(0);
  const {
    LivestreamModel: { comment },
    setComment,
  } = useContext(LivestreamContext);
  const updateAmount = value => {
    const x = (value / maxValue) * 100;
    setPosition(x - 50);
    callback(value);
  };

  const handleInputChange = ({ target: { value } }) => {
    setComment(value);
  };
  return (
    <InitMakeItRainWrapper>
      <TimeStamp>
        <PinIcon />5 minutes
      </TimeStamp>
      {/* <CommentBox>
        <Comment>
          <Avatar alt={'user name'} />
          <CommentContent>
            <Username>{firstName + ' ' + lastName}</Username>
            <MakeComment id="mir-comment-input" onChange={handleInputChange} value={comment} />
          </CommentContent>
        </Comment>
      </CommentBox> */}
      <CommentBox
        input_id={'mir-comment-input'}
        username={firstName + ' ' + lastName}
        onchange={handleInputChange}
        content={comment}
      />
      <RainAmountWrapper>
        <p>How much do you want to rain?</p>
        <AmountToRain position={position}>
          <Balance>
            <AddMoneyIcon />
            {Math.floor(amount)}
          </Balance>
        </AmountToRain>
        <RangeInput
          min="1"
          max={maxValue}
          onChange={value => {
            updateAmount(value);
          }}
          type="range"
        />
      </RainAmountWrapper>
    </InitMakeItRainWrapper>
  );
};

export default InitMakeItRain;
