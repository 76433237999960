import * as React from 'react';

function SvgUnionBadge(props) {
  return (
    <svg width="134" height="19" viewBox="0 0 134 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M133.902 18.0129L125.598 9.2917L133.902 0.567879H67.2056V18.0129H133.902Z"
        fill="#FDB913"
        stroke="EA244C"
      />
      <path
        d="M0.550316 1.01343L8.85482 9.73466L0.550316 18.4585L67.2471 18.4585V1.01343L0.550316 1.01343Z"
        fill="#FDB913"
        stroke="EA244C"
      />
      {/* <defs>
        <linearGradient id="paint0_linear" x1="141.193" y1="9.15622" x2="112.531" y2="9.17048" gradientUnits="userSpaceOnUse">
          <stop stopColor="#EA244C" />
          <stop offset="0.71875" stopColor="#FDB913" />
        </linearGradient>
        <linearGradient id="paint1_linear" x1="-6.7406" y1="9.87015" x2="21.9213" y2="9.85589" gradientUnits="userSpaceOnUse">
          <stop stopColor="#EA244C" />
          <stop offset="0.71875" stopColor="#FDB913" />
        </linearGradient>
      </defs> */}
    </svg>
  );
}

export default SvgUnionBadge;
