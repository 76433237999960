import React from 'react';
import PropTypes from 'prop-types';

function SvgPlaystore({ fill, ...props }) {
  return (
    <svg id="playstore_svg__playstore" width="1em" height="1em" viewBox="0 0 25.363 27.746" {...props}>
      <defs>
        <style>{`.playstore_svg__cls-1{fill:${fill}}`}</style>
      </defs>
      <g id="playstore_svg__Group_9" data-name="Group 9" transform="translate(2.103)">
        <g id="playstore_svg__Group_8" data-name="Group 8">
          <path
            id="playstore_svg__Path_4"
            d="M62.905.436A2.72 2.72 0 0060.8.073l12.517 12.514 4.056-4.056z"
            className="playstore_svg__cls-1"
            data-name="Path 4"
            transform="translate(-60.802)"
          />
        </g>
      </g>
      <g id="playstore_svg__Group_11" data-name="Group 11" transform="translate(0 1.09)">
        <g id="playstore_svg__Group_10" data-name="Group 10">
          <path
            id="playstore_svg__Path_5"
            d="M22.541 20.116a2.719 2.719 0 00-.55 1.637v22.292a2.72 2.72 0 00.55 1.637L35.324 32.9z"
            className="playstore_svg__cls-1"
            data-name="Path 5"
            transform="translate(-21.991 -20.116)"
          />
        </g>
      </g>
      <g id="playstore_svg__Group_13" data-name="Group 13" transform="translate(15.903 9.454)">
        <g id="playstore_svg__Group_12" data-name="Group 12">
          <path
            id="playstore_svg__Path_6"
            d="M323.515 176.49l-3.645-2.04-4.419 4.419 4.419 4.419 3.646-2.04a2.727 2.727 0 000-4.759z"
            className="playstore_svg__cls-1"
            data-name="Path 6"
            transform="translate(-315.451 -174.45)"
          />
        </g>
      </g>
      <g id="playstore_svg__Group_15" data-name="Group 15" transform="translate(2.104 15.158)">
        <g id="playstore_svg__Group_14" data-name="Group 14">
          <path
            id="playstore_svg__Path_7"
            d="M73.329 279.711l-12.514 12.514a2.7 2.7 0 002.1-.363l14.467-8.095z"
            className="playstore_svg__cls-1"
            data-name="Path 7"
            transform="translate(-60.815 -279.711)"
          />
        </g>
      </g>
    </svg>
  );
}

SvgPlaystore.propTypes = {
  fill: PropTypes.string,
};

SvgPlaystore.defaultProps = {
  fill: '#fff',
};

export default SvgPlaystore;
