import React from 'react';
import PropTypes from 'prop-types';

function SvgYoutube({ fill, ...props }) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28.271 19.438" {...props}>
      <g data-name="002-youtube">
        <path
          data-name="Exclusion 1"
          d="M14.141 19.438c-3.46 0-7.886-.054-9.648-.156-1.639-.111-2.5-.318-3.3-1.726-.816-1.395-1.2-3.881-1.2-7.827v-.01c0-4 .369-6.42 1.2-7.845A2.968 2.968 0 012.498.542 5.406 5.406 0 014.492.163C6.262.043 10.792 0 14.137 0s7.862.043 9.636.163a5.393 5.393 0 011.994.379 2.954 2.954 0 011.3 1.331c.831 1.418 1.2 3.836 1.2 7.843v.016c0 3.954-.381 6.44-1.2 7.826a2.964 2.964 0 01-1.3 1.331 5.555 5.555 0 01-1.995.4c-1.779.096-6.194.149-9.631.149zm-3.539-15.02v10.6l8.836-5.3-8.836-5.3z"
          fill={fill}
        />
      </g>
    </svg>
  );
}

SvgYoutube.propTypes = {
  fill: PropTypes.string,
};

SvgYoutube.defaultProps = {
  fill: '#fff',
};

export default SvgYoutube;
