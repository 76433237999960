import React, { useContext } from 'react';

import { PlaylistContext } from 'contexts/PlaylistContext';
import Plus from '../../assets/img/icons/Subtract.svg';
import { Subtitle, Wrapper } from 'components/molecules/Tile/styled';
import styled from '@emotion/styled/macro';
import { useMeasure } from 'react-use';

const CardContent = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
`;

const PlusButton = styled.img`
  width: 20%;
  margin-bottom: 2rem;
`;

const Text = styled(Subtitle)`
  color: #fdb913 !important;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  text-align: center;
  font-weight: 700;
`;

export const NewPlaylist = ({ listen, height, ...props }) => {
  const { toggleCreatePlaylist } = useContext(PlaylistContext);
  const [ref, { width }] = useMeasure();

  return (
    <Wrapper
      alt
      height={listen ? [`${width}px`] : height}
      {...props}
      ref={ref}
      onClick={() => {
        toggleCreatePlaylist();
      }}
    >
      <CardContent>
        <PlusButton src={Plus} />
        <Text>New Playlist</Text>
      </CardContent>
    </Wrapper>
  );
};
