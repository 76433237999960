import React from 'react';
import PropTypes from 'prop-types';

function SvgRemove({ fill, ...props }) {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M18.4233 12L6.57715 12" stroke="url(#paint0_linear)" strokeLinecap="round" strokeLinejoin="round" />
      <circle cx="12.5" cy="12" r="11" stroke="url(#paint1_linear)" />
      <defs>
        <linearGradient id="paint0_linear" x1="6.57715" y1="11.5" x2="18.4233" y2="11.5" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FDB913" />
          <stop offset="1" stopColor="#F2994A" />
        </linearGradient>
        <linearGradient id="paint1_linear" x1="12.5" y1="1" x2="12.5" y2="23" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FDB913" />
          <stop offset="1" stopColor="#F2994A" />
        </linearGradient>
      </defs>
    </svg>
  );
}

SvgRemove.propTypes = {
  fill: PropTypes.string,
};

SvgRemove.defaultProps = {
  fill: '#fff',
};

export default SvgRemove;
