import React, { useState, useMemo, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import TextModal from 'components/organisms/TextModal';
import GetAppModal from 'components/organisms/GetAppModal';
import LogoutModal from 'components/organisms/LogoutModal';
import { isMobile } from 'react-device-detect';

export const TextModalContext = React.createContext({});

export const TextModalProvider = ({ children }) => {
  const [modal, setModal] = useState(false);
  const [getAppModal, setGetAppModal] = useState(false);
  const [logoutModal, setLogoutModal] = useState(false);
  // eslint-disable-next-line
  const [mode, setMode] = useState('');
  const toggle = useCallback(
    newMode => {
      if (modal) {
        setModal(false);
        setMode('');
      } else {
        setModal(true);
        setMode(newMode);
      }
    },
    [modal],
  );

  const toggleGetAppModal = useCallback(() => setGetAppModal(v => !v), []);
  const toggleLogoutModal = useCallback(() => setLogoutModal(v => !v), []);

  const providerValue = useMemo(
    () => ({
      getAppModal,
      modal,
      setModal,
      toggle,
      toggleGetAppModal,
      toggleLogoutModal,
    }),
    [getAppModal, modal, setModal, toggle, toggleGetAppModal, toggleLogoutModal],
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      const getAppCount = parseInt(localStorage.getItem('getAppCount') || 0, 10);
      if (getAppCount < 1 && isMobile === true) {
        toggleGetAppModal();
        localStorage.setItem('getAppCount', getAppCount + 1);
      }
    }, 300000);

    return () => {
      clearTimeout(timeout);
    };
  }, [toggleGetAppModal]);

  return (
    <TextModalContext.Provider value={providerValue}>
      {children}
      <TextModal isOpen={modal} toggle={toggle} close={() => setModal(false)} />
      <GetAppModal isOpen={getAppModal} toggle={toggleGetAppModal} close={() => setGetAppModal(false)} />
      <LogoutModal isOpen={logoutModal} toggle={toggleLogoutModal} close={() => setLogoutModal(false)} />
    </TextModalContext.Provider>
  );
};

TextModalProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
