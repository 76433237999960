import { useEffect, useState } from 'react';

export function useBreakpoints() {
  const [isMobile, setIsMobile] = useState(false);
  const [sm, setSm] = useState(false);
  const [md, setMd] = useState(false);
  const [lg, setLg] = useState(false);
  const [xl, setXl] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const width = document.body.clientWidth;
      setIsMobile(width <= 578);
      setSm(width > 578 && width < 768);
      setMd(width >= 768 && width < 992);
      setLg(width >= 992 && width < 1200);
      setXl(width >= 1200);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return [isMobile, sm, md, lg, xl];
}
