import styled from '@emotion/styled/macro';
import { Box } from 'rebass';
import { Button, Image, OutlineButton, Typography } from 'components/atoms';

export const Wrapper = styled(Box)`
  align-items: center;
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0;
  right: 0;
  top: 60px;
  margin: 0 auto;
  // ${({ theme }) => theme.mq.md`
  //   top: -13rem;
  // `}
`;

export const AvatarOverlay = styled.div`
  border-radius: 0.5rem;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white}80;
  cursor: pointer;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0px;
  transition: all 0.2s;
  width: 100%;
  height: 17rem;
  width: 17rem;

  ${({ theme }) => theme.mq.sm`
   
    width: 20rem;
    height: 20rem;
  `}

  ${({ theme }) => theme.mq.md`
    height: 34rem;
    width: 34rem;
  `}

  input {
    display: none;
  }
  svg {
    width: 20%;
  }
`;

export const AvatarWrapper = styled.div`
  position: relative;
  border-radius: 0.5rem;
  display: inline-block;
  overflow: hidden;
  // &:hover {
  //   ${AvatarOverlay} {
  //     top: 0px;
  //   }
  // }
`;

export const Artwork = styled(Image)`
  border-radius: 0.8rem;
  box-shadow: 0 2px 30px rgba(0, 0, 0, 0.15);
  flex-shrink: 0;
  height: 17rem;
  margin-bottom: 2rem;
  object-fit: cover;
  width: 17rem;

  img {
    box-shadow: none;
  }

  ${({ theme }) => theme.mq.sm`
    // margin-right: 2rem;
    width: 20rem;
    height: 20rem;
  `}

  ${({ theme }) => theme.mq.md`
    height: 30rem;
    margin-right: 3rem;
    margin-top: -5rem;
    width: 30rem;

    img {
      margin-top: 0;
    }

  `}
`;

export const EditArtwork = styled(Image)`
  border-radius: 0.5rem;
  box-shadow: 0 2px 30px rgba(0, 0, 0, 0.15);
  flex-shrink: 0;
  height: 17rem;

  object-fit: cover;
  width: 17rem;

  img {
    box-shadow: none;
  }

  ${({ theme }) => theme.mq.sm`
    // margin-right: 2rem;
    width: 20rem;
    height: 20rem;
  `}

  ${({ theme }) => theme.mq.md`
    height: 34rem;
    margin-right: 3rem;
    
    width: 34rem;

    img {
      margin-top: 0;
    }

  `}
`;

export const Details = styled(Box)`
  align-items: center;
  flex-direction: column;
  margin-bottom: 1rem;
  text-align: center;
  width: 50%;
  @media (max-width: 767px) {
    width: 100%;
  }
  ${({ theme }) => theme.mq.md`
    align-items: flex-start;
    margin-bottom: 0;
  `}
`;

// export const CommentButton = styled(OutlineButton)`
//   border-radius: 0.3rem;
//   font-size: ${({ theme }) => theme.font.size.tiniest};
//   padding: 0.4rem;

//   &,
//   &:visited {
//     color: ${({ theme }) => theme.colors.white};
//   }

//   svg {
//     margin-right: 0.5rem;
//   }
// `;

export const ListenCountText = styled(Typography.Text)`
  background: rgba(39, 39, 41, 0.8);
  padding: 1rem;
  border-radius: 30px;
  display: inline-flex;
  &:not(:last-child) {
    margin-right: 2rem;
  }

  svg {
    height: 1.6rem;
    margin-right: 0.5rem;
    width: 1.6rem;
  }
`;

export const ArtistName = styled(Typography.Paragraph)`
  color: ${({ theme }) => theme.colors.switchingText} !important;
  font-weight: 400;
  font-size: ${({ theme }) => theme.font.size.tiniest};
  line-height: 1.2;
  margin-bottom: 0.5rem;
  opacity: 0.7;
  text-align: left;
  margin-left: 2rem;

  ${({ theme }) => theme.mq.md`
    font-size: ${theme.font.size.medium};
    line-height: ${theme.font.size.big};
    text-align: left;
    margin-left: 0rem;
`};
`;

export const Name = styled.h1`
  font-family: BwModelica, sans-serif;
  color: ${({ theme }) => theme.colors.white};
  font-weight: 800;
  font-size: ${({ theme }) => theme.font.size.small};
  line-height: 1.2;
  text-align: left;
  margin-left: 2rem;
  margin-bottom: 2rem;
  word-break: break-word;
  overflow: hidden;
  text-decoration: none;
  line-height: 1.2;
  font-family: BwModelica, sans-serif;
  ${({ theme }) => theme.mq.md`
    font-size: ${theme.font.size.big};
    margin-left: 0rem;
`}
`;

export const Description = styled(Typography.Text)`
  color: #868687;
  display: inline-block;
  margin-top: 0.4rem;
  font-size: ${({ theme }) => theme.font.size.tiny};
  line-height: ${({ theme }) => theme.font.size.tiny};
  margin-bottom: 0.2rem;
  text-align: center;
  text-align: left;
  margin-left: 2rem;
  ${({ theme }) => theme.mq.md`
  text-align: left;
  margin-left: 0rem;
`}
`;

export const PlayButtons = styled(Box)`
  button {
    svg {
      margin-right: 1rem;
    }
    &:not(:last-child) {
      margin-right: 1rem;
    }
  }
`;

export const BlurBackground = styled(Image)`
  z-index: 0;
  width: 100%;
  object-fit: cover;
  filter: blur(3.3rem);
  height: 20rem;
  top: -79px;
  ${({ theme }) => theme.mq.sm`
  height: 25rem;
  `}
`;

export const PictureAndLeft = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Icons = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  align-self: end;
  margin-top: 1rem;
  svg {
    width: 2rem;
  }
  @media (max-width: 480px) {
    svg {
      width: 1.5rem;
    }
  }
  @media (max-width: 767px) {
    align-self: center;
    margin-top: 1rem;
    width: 80%;
  }
`;

export const TrackDetails = styled.div`
  text-align: initial;
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 11rem;
  margin-top: 6rem;
  @media (max-width: 767px) {
    margin-bottom: 1rem;
    margin-top: 1rem;
  }
`;

export const Genre = styled.div`
  font-family: BwModelicaSS02, sans-serif;
  margin-bottom: 2rem;
  font-weight: 500;
  opacity: 0.8;
  font-size: ${({ theme }) => theme.font.size.small};
  color: ${({ theme }) => theme.colors.switchingText};
  text-transform: uppercase;
  text-align: left;
  @media (max-width: 767px) {
    text-align: left;
    margin-left: 2rem;
    font-size: ${({ theme }) => theme.font.size.tiniest};
  }
`;

export const MixButton = styled(Button)`
  color: white !important;
  background: linear-gradient(270.02deg, #fdb913 0.02%, #f2994a 99.98%);
  @media (max-width: 440px) {
    width: 100%;
  }
`;

export const OutlineBtn = styled(OutlineButton)`
  color: ${({ theme }) => theme.colors.switchingText} !important;
  border: 2px solid #272729;
  border-radius: 175.5px;
  font-weight: 500;
  font-size: 15px;
  @media (max-width: 440px) {
    width: 100%;
  }
`;

export const DetailBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  alignitems: center;
  margin-bottom: 11rem;
  @media (max-width: 767px) {
    margin-bottom: 0;
  }
`;

export const InnerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  ${({ theme }) => theme.mq.md`
  flex-direction: row;
  align-items: center;
  `}
`;

export const Left = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  ${({ theme }) => theme.mq.md`
  width: 65%;
  `}
`;

export const ButtonsWrapper = styled.div`
  @media (max-width: 440px) {
    width: 100%;
  }
`;
