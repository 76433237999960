import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import PromoBannerModal from 'components/organisms/PromoBannerModal';
import { useLocation } from 'react-router-dom';
import PromoCreditModal from 'components/organisms/PromoCreditModal';
import { useSelector } from 'react-redux';

export const PromoContext = React.createContext({});

export const PromoProvider = ({ children }) => {
  const [banner, setBanner] = useState(false);
  const [creditModal, setCreditModal] = useState(false);
  const { fetchingConfig, bannerEndtime, bannerStarttime } = useSelector(({ remoteConfig }) => ({
    fetchingConfig: remoteConfig.fetching || false,
    bannerStarttime: parseInt(remoteConfig.data.xswitch_banner_starttime || 0, 10),
    bannerEndtime: parseInt(remoteConfig.data.xswitch_banner_endtime || 0, 10),
  }));
  const toggleBanner = () => setBanner(v => !v);
  const toggleCreditModal = () => setCreditModal(v => !v);
  const { pathname } = useLocation();

  useEffect(() => {
    let timeout;
    const now = Date.now();
    if (!fetchingConfig && bannerStarttime < now && bannerEndtime > now && !pathname.includes('livestream')) {
      timeout = setTimeout(() => {
        setBanner(true);
      }, 2000);
    }

    return () => {
      if (timeout) clearTimeout(timeout);
    };
    // eslint-disable-next-line
  }, [fetchingConfig, bannerEndtime, bannerStarttime]);

  return (
    <PromoContext.Provider
      value={{
        creditModal,
        toggleCreditModal,
      }}
    >
      {children}
      <PromoBannerModal isOpen={banner} toggle={toggleBanner} />
      <PromoCreditModal isOpen={creditModal} toggle={toggleCreditModal} />
    </PromoContext.Provider>
  );
};

PromoProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
