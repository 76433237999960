import React from 'react';
import PropTypes from 'prop-types';

function SvgUpload({ fill, ...props }) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 52.994 50.208" {...props}>
      <g data-name="Group 57" fillRule="evenodd">
        <path
          fill={fill}
          d="M42.212 37.186l-4.887 4.908-5.795-4.907-5.32 4.888-5.873-4.895-5.538 4.922-4.877-4.892-4.282 3.806a28.272 28.272 0 0041.617.119zm5.37.7l-2.276-2.294h4.031zm-10.679 0l-2.274-2.299h4.55zm-10.852 0l-2.276-2.294h4.552zm-11.2 0l-2.276-2.294h4.552zm-9.8 0l-1.56-2.294h3.83zm47.231-6.867H.682a.683.683 0 00-.638.928 28.215 28.215 0 004.815 8.186l5.112-4.541 4.872 4.89 5.47-4.859 5.859 4.885 5.327-4.897 5.757 4.876 4.858-4.876 5.93 4.639a28.326 28.326 0 004.9-8.262.716.716 0 00-.668-.973z"
        />
        <path
          fill={fill}
          data-name="Fill-1"
          d="M17.746 11.475a.541.541 0 00.488.3h4.721v14.158a.676.676 0 00.677.677h6.371a.676.676 0 00.677-.677V11.778h3.84a.544.544 0 00.486-.3.765.765 0 00.057-.289.417.417 0 00-.113-.284L26.829.21a.542.542 0 00-.863 0L17.8 10.9a.537.537 0 00-.054.575z"
        />
      </g>
    </svg>
  );
}

SvgUpload.propTypes = {
  fill: PropTypes.string,
};

SvgUpload.defaultProps = {
  fill: '#000',
};

export default SvgUpload;
