import React from 'react';
import PropTypes from 'prop-types';

function SvgPlaylist({ fill, ...props }) {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0)">
        <rect y="4.65283" width="20" height="2" rx="1" fill={fill} />
        <rect y="8.65283" width="20" height="2" rx="1" fill={fill} />
        <rect y="12.6528" width="14" height="2" rx="1" fill={fill} />
        <rect y="16.6528" width="14" height="2" rx="1" fill={fill} />
      </g>
      <circle cx="19.1428" cy="17.6052" r="3.04762" fill={fill} stroke={fill} strokeWidth="2" />
      <path
        d="M22.1904 17.2243V4.65283"
        stroke={fill}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill={fill}
      />
      <defs>
        <clipPath id="clip0">
          <rect width="24" height="16" fill="white" transform="translate(0 4.65283)" />
        </clipPath>
      </defs>
    </svg>
  );
}

SvgPlaylist.propTypes = {
  fill: PropTypes.string,
};

SvgPlaylist.defaultProps = {
  fill: '#fcb316',
};

export default SvgPlaylist;
