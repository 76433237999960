import {
  Artist,
  Body,
  Close,
  ControlWrapper,
  // Grid,
  Header,
  InnerWrapper,
  Next,
  NowPlaying,
  PlayerArtwork,
  PlayerContainer,
  RightSide,
  Seeker,
  TabHead,
  TabTitle,
  Title,
  TrackDetails,
  Wrapper,
} from './styled';
import {
  DownIcon,
  LikeIcon,
  NextIcon,
  PauseIcon,
  PlayIcon,
  PlaylistIcon3,
  PreviousIcon,
  QueueIcon2,
  RepeatIcon2,
  RepeatOneIcon,
  ShuffleIcon2,
  ExplicitIcon,
} from 'components/icons';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { Marquee, Tile } from 'components/molecules';
import React, { memo, useContext, useEffect, useState, useCallback, Fragment } from 'react';
import {
  connectAction,
  nextTrackAction,
  orderQueueAction,
  pauseAction,
  playAction,
  playTracksAction,
  previousTrackAction,
  toggleRepeatAction,
  toggleShuffleAction,
  // clearQueueAction,
} from 'redux/actions';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Flex } from 'rebass';
// import Carousel from 'components/organisms/Carousel';
import { Link } from 'react-router-dom';
import { NewPlaylist } from 'components/atoms/NewPlaylist';
import { PlayerContext } from 'contexts/PlayerContext';
import { PlayerTabs, Spinner } from 'components/atoms';
import Portal from 'components/organisms/Portal';
import PropTypes from 'prop-types';
import { QueueItem } from './Item';
import { reorder } from 'lib/utils';
import { useTransition } from 'react-spring';
// import { Button } from 'components/atoms';

const FullScreenPlayer = memo(({ active, close, queue }) => {
  const dispatch = useDispatch();

  const pause = () => dispatch(pauseAction());
  const play = () => dispatch(playAction());
  const playTracks = (index = 0) => dispatch(playTracksAction(queue, index));
  const reorderQueue = tracks => dispatch(orderQueueAction(tracks));
  //const clearQueue = () => dispatch(clearQueueAction());
  const toggleRepeat = () => dispatch(toggleRepeatAction());
  const toggleShuffle = () => dispatch(toggleShuffleAction());
  const nextTrack = () => dispatch(nextTrackAction());
  const previousTrack = () => dispatch(previousTrackAction());
  const userPlaylist = useSelector(state => state.playlist.byUser.user || []);
  const [activeTab, setActiveTab] = useState(2);
  const [hasPlayed, setHasPlayed] = useState(false);
  const { playerRef, state } = useContext(PlayerContext);
  const { currentIndex, currentItem, magic, playing, progress, repeat, shuffle } = useSelector(({ player }) => player);

  const isConnected = useSelector(
    ({ connect }) =>
      (connect.byTargetId.tracks &&
        connect.byTargetId.tracks[currentItem?.id] &&
        connect.byTargetId.tracks[currentItem?.id].active) ||
      false,
  );
  const connect = () => dispatch(connectAction(currentItem, 'track'));

  const onDragEnd = result => {
    if (!result.destination) {
      return;
    }
    if (result.destination.index === result.source.index) {
      return;
    }
    const list = reorder(queue, result.source.index, result.destination.index);
    reorderQueue(list);
  };

  const renderRepeatIcon = () => {
    switch (repeat) {
      case 0:
        return <RepeatIcon2 size="2.5rem" onClick={toggleRepeat} className="xs-hide repeat" fill="#fff" />;
      case 1:
        return <RepeatIcon2 size="2.5rem" onClick={toggleRepeat} className="xs-hide repeat" fill="#ffa700" />;
      case 2:
        return <RepeatOneIcon size="2.5rem" onClick={toggleRepeat} className="xs-hide repeat" fill="#ffa700" />;
      default:
        return <RepeatIcon2 size="2.5rem" onClick={toggleRepeat} className="xs-hide repeat" fill="#fff" />;
    }
  };

  useEffect(() => {
    (async () => {
      if (!hasPlayed && playing) {
        setHasPlayed(true);
        await pause();
        await play();
      }
    })();
    // eslint-disable-next-line
  }, [playing, hasPlayed]);

  const canGo = () => {
    let next = false;
    let prev = false;
    const item = queue[currentIndex] || {};
    const { index } = item;
    if (index !== 0 && index < queue.length) {
      next = true;
      prev = true;
    }
    if (index === 0) {
      next = true;
    }
    if (index >= queue.length - 1) {
      next = false;
    }
    return {
      next,
      prev,
    };
  };

  const goToNextTrack = () => {
    if (canGo().next) {
      nextTrack();
    }
  };

  const goToPreviousTrack = () => {
    if (canGo().prev) {
      previousTrack();
    }
  };

  const memorizedSeek = useCallback(x => {
    if (playerRef && playerRef.current) {
      playerRef.current.seekTo(x / 100, 'fraction');
    }
    // eslint-disable-next-line
  }, []);

  const renderPlayIcon = () => {
    return playing ? <PauseIcon onClick={pause} className="pause" /> : <PlayIcon onClick={play} className="play" />;
  };

  const renderSpinner = () => {
    if (state.isBuffering) {
      return <Spinner style={{ marginRight: '2.7rem' }} />;
    }
    return renderPlayIcon();
  };

  const transition = useTransition(active, null, {
    from: { opacity: 0, transform: 'translateY(100%)' },
    enter: { opacity: 1, transform: 'translateY(0)' },
    leave: { opacity: 0, transform: 'translateY(100%)' },
    config: {
      duration: 200,
    },
    trail: 100,
  });

  return transition.map(({ item: open, key, props }) => {
    return (
      open && (
        <Portal>
          <Wrapper
            key={key}
            style={{
              ...props,
              background:
                currentItem?.hasColor || currentItem?.color
                  ? `rgb(${currentItem?.color?.r}, ${currentItem?.color?.g}, ${currentItem?.color?.b})`
                  : '#131516',
            }}
          >
            <Header>
              <Box alignItems="center">
                <Close onClick={close}>
                  <DownIcon width="2rem" height="2rem" style={{ marginRight: '1rem' }} />
                </Close>
                <NowPlaying>Now Playing</NowPlaying>
              </Box>

              {/* <Box>
                <Button
                  size="small"
                  onClick={() => {
                    clearQueue();
                    close();
                  }}
                >
                  Clear
                </Button>
              </Box> */}
            </Header>

            <InnerWrapper>
              <PlayerContainer>
                {currentItem?.artwork && (
                  <PlayerArtwork
                    src={currentItem?.artwork}
                    placeholder="track"
                    alt={currentItem?.name}
                    useThumbnail={false}
                  />
                )}
                <TrackDetails>
                  <Box flexGrow="1" display="flex" flexDirection="column" style={{ overflow: 'hidden' }}>
                    <Marquee>
                      <Title
                        ellipsize
                        as={Link}
                        to={`/albums/${currentItem?.album}`}
                        style={{ fontWeight: 'bold' }}
                        onClick={close}
                      >
                        {currentItem?.name}
                        {currentItem?.parental_warning === 'Explicit' && <ExplicitIcon size="1rem" />}
                      </Title>
                    </Marquee>

                    <Marquee>
                      <Artist
                        aria-label={`${currentItem?.artist_name} artist link`}
                        ellipsize
                        as={Link}
                        to={`/artists/${currentItem?.artist}`}
                        onClick={close}
                      >
                        {currentItem?.artist_name}
                      </Artist>
                    </Marquee>
                  </Box>
                  <LikeIcon
                    onClick={e => {
                      e.stopPropagation();
                      connect();
                    }}
                    fill={isConnected === true ? 'url(#paint0_linear)' : 'transparent'}
                    style={{ cursor: 'pointer', height: '2rem', width: '2rem' }}
                  />
                </TrackDetails>
                <Seeker
                  video={false}
                  duration={state.duration}
                  onSeek={memorizedSeek}
                  played={progress.playedSeconds}
                  progress={progress.played * 100}
                  seekable
                  textColor="switchingText"
                />
                <ControlWrapper>
                  {!magic && (
                    <ShuffleIcon2
                      onClick={toggleShuffle}
                      className="xs-hide shuffle"
                      fill={shuffle ? '#FFA700' : '#fff'}
                    />
                  )}
                  {magic ? (
                    <PreviousIcon fill="#fff" onClick={goToNextTrack} className="prev" />
                  ) : (
                    <PreviousIcon onClick={goToPreviousTrack} fill="#fff" className="prev" />
                  )}

                  <Fragment>{renderSpinner()}</Fragment>

                  <NextIcon onClick={goToNextTrack} fill="#fff" className="next" />
                  {renderRepeatIcon()}
                </ControlWrapper>
              </PlayerContainer>
              {/* TABS */}
              <RightSide>
                <TabHead>
                  <PlayerTabs>
                    <PlayerTabs.Item active={activeTab === 0} onClick={() => setActiveTab(0)}>
                      <TabTitle style={{ background: activeTab === 0 ? 'rgba(39, 39, 41, 0.8)' : 'transparent' }}>
                        <PlaylistIcon3 fill={activeTab === 0 ? '#ffa700' : '#fff'} /> Add to Playlist
                      </TabTitle>
                    </PlayerTabs.Item>
                    <PlayerTabs.Item active={activeTab === 2} onClick={() => setActiveTab(2)}>
                      <TabTitle style={{ background: activeTab === 2 ? 'rgba(39, 39, 41, 0.8)' : 'transparent' }}>
                        <QueueIcon2 fill={activeTab === 2 ? '#ffa700' : '#fff'} style={{ marginRight: '0.8rem' }} />{' '}
                        Queue
                      </TabTitle>
                    </PlayerTabs.Item>

                    {/* <PlayerTabs.Item active={activeTab === 1} onClick={() => setActiveTab(1)}>
                      <TabTitle style={{ background: activeTab === 1 ? 'rgba(39, 39, 41, 0.8)' : 'transparent' }}>
                        <LyricsIcon fill={activeTab === 1 ? '#ffa700' : '#fff'} /> Lyrics
                      </TabTitle>
                    </PlayerTabs.Item> */}
                  </PlayerTabs>
                </TabHead>
                {activeTab === 0 && (
                  <Flex flexWrap="wrap" mx="-1.5rem" overflowY="auto" height="60vh">
                    <div>
                      <NewPlaylist style={{ cursor: 'pointer' }} />
                    </div>

                    {userPlaylist?.slice(0, 15).map(item => (
                      // <TrackItem key={item.id} item={item} onPlay={() => playTracks(index)} />
                      <Box mb="3rem" mx="1rem" key={item.id}>
                        <Tile.PlayerPersonalPlaylist item={item} />
                      </Box>
                    ))}
                  </Flex>
                )}
                {activeTab === 1 && <div />}
                {activeTab === 2 && (
                  <>
                    <Next>Up Next</Next>
                    <DragDropContext onDragEnd={onDragEnd}>
                      <Droppable droppableId="queue-list">
                        {provided => (
                          <Body
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            style={{
                              width: '100%',
                              margin: '0 auto',
                              padding: '0.9rem 2rem',
                              overflowY: 'scroll',
                              height: '55vh',
                            }}
                          >
                            {queue.map((item, index) => (
                              <QueueItem
                                active={active}
                                item={item}
                                key={item.id}
                                index={index}
                                onPlay={() => playTracks(index)}
                                close={close}
                              />
                            ))}
                            {provided.placeholder}
                          </Body>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </>
                )}
              </RightSide>
            </InnerWrapper>
          </Wrapper>
        </Portal>
      )
    );
  });
});

FullScreenPlayer.propTypes = {
  active: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  queue: PropTypes.array.isRequired,
};

export default FullScreenPlayer;
