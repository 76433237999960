import styled from '@emotion/styled/macro';
import { CheckBox } from './CheckBox';
import { DayPicker } from './DayPicker';
import { FormError } from './Error';
import { FormGroup } from './Group';
import { Input, InputGroup } from './Input';
import { Label } from './Label';
import { Radio } from './Radio';
import { Searchbar } from './Searchbar';
import { Select } from './Select';
import { Switch } from './Switch';
import { Textarea } from './Textarea';

const Form = styled.form`
  margin: 0;
  padding: 0;
  width: 100%;
  max-width: 750px;
`;

Form.CheckBox = CheckBox;
Form.DayPicker = DayPicker;
Form.Error = FormError;
Form.Group = FormGroup;
Form.Input = Input;
Form.InputGroup = InputGroup;
Form.Label = Label;
Form.Radio = Radio;
Form.Searchbar = Searchbar;
Form.Select = Select;
Form.Switch = Switch;
Form.Textarea = Textarea;

export { Form };
