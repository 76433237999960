import React, { useEffect, useState, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from '@emotion/styled/macro';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import { SearchIcon2 } from '../../icons';
import { CancelIcon } from '../../icons';
import { InputGroup } from './Input';

const SearchComponent = styled(InputGroup)`
  border-radius: 8px;
  overflow: hidden;
  padding: 0 1rem;
  input {
    padding-left: 1rem;
  }
`;

export const Searchbar = ({ className, style, basePath, ...props }) => {
  const [query, setQuery] = useState('');
  const [route, setRoute] = useState('/'); // eslint-disable-line
  const { push } = useHistory();
  const { search, pathname } = useLocation(); // eslint-disable-line
  useEffect(() => {
    if (pathname !== '/search') {
      setQuery('');
    }
  }, [pathname]);

  const onChange = useCallback(
    e => {
      if (pathname !== basePath) {
        setRoute(pathname);
      }

      if (query.length < 80) {
        const { value } = e.target;
        setQuery(value);
        if (value) {
          push({
            pathname: basePath,
            search: `?query=${encodeURI(value)}&doNotScroll=1`,
          });
        }
      }
    },
    // eslint-disable-next-line
    [query],
  );

  return (
    <SearchComponent
      addon={
        <SearchIcon2 width={20} height={19} style={{ cursor: 'pointer' }} fill={useTheme().colors.switchingText} />
      }
      secondAddon={
        <CancelIcon
          width={20}
          height={19}
          style={{ cursor: 'pointer', visibility: query === '' || pathname !== '/search' ? 'hidden' : 'visible' }}
          fill="#868687"
          onClick={() => setQuery('')}
        />
      }
      secondAddonPosition="right"
      addonPosition="left"
      backgroundColor="searchbar"
      className={className}
      inputProps={{
        'aria-label': 'Searchbar',
        name: 'searchbar',
        placeholder: 'Search song, album or artiste',
        onChange,
        value: query,
        style: {
          color: useTheme().colors.switchingText,
        },
        ...props,
      }}
      size="sm"
      style={{ visibility: pathname !== '/search' ? 'hidden' : 'visible', ...style }}
    />
  );
};

Searchbar.defaultProps = {
  className: '',
  style: {},
  props: {},
  basePath: '/search',
};

Searchbar.propTypes = {
  className: PropTypes.string,
  style: PropTypes.shape({}),
  props: PropTypes.shape({}),
  basePath: PropTypes.string,
};
