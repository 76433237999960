import React from 'react';

function SvgWarning(props) {
  return (
    <svg width="111" height="110" viewBox="0 0 111 110" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="55.5" cy="55" r="51.5" stroke="url(#paint0_linear)" strokeWidth="7" strokeLinecap="round" />
      <path d="M55.5 76.154V80.3848" stroke="url(#paint1_linear)" strokeWidth="7" strokeLinecap="round" />
      <path d="M55.5 25.3843L55.5 59.2305" stroke="url(#paint2_linear)" strokeWidth="7" strokeLinecap="round" />
      <defs>
        <linearGradient id="paint0_linear" x1="55.5" y1="0" x2="55.5" y2="110" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FDB913" />
          <stop offset="1" stopColor="#F2994A" />
        </linearGradient>
        <linearGradient id="paint1_linear" x1="55" y1="80.3848" x2="55" y2="76.154" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FDB913" />
          <stop offset="1" stopColor="#F2994A" />
        </linearGradient>
        <linearGradient id="paint2_linear" x1="55" y1="59.2305" x2="55" y2="25.3843" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FDB913" />
          <stop offset="1" stopColor="#F2994A" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default SvgWarning;
