import React from 'react';

const SVGBadge = ({ point }) => (
  <svg width={150} height={150} viewBox="0 0 134 103" fill="none">
    <g opacity={0.5}>
      <mask
        id="path-1-outside-1"
        maskUnits="userSpaceOnUse"
        x={15.7505}
        y={0.324214}
        width={104}
        height={103}
        fill="black"
      >
        <rect fill="white" x={15.7505} y={0.324214} width={104} height={103} />
        <path d="M21.4173 96.7559L21.4173 9.32422C21.4173 7.11508 23.2081 5.32422 25.4173 5.32422L110.75 5.32439C112.96 5.32439 114.75 7.11525 114.75 9.32439L114.75 77.3735C114.75 79.5827 112.96 81.3735 110.75 81.3735L46.5454 81.3735L22.984 97.5798C22.3205 98.0362 21.4173 97.5612 21.4173 96.7559Z" />
      </mask>
      <path
        d="M21.4173 96.7559L21.4173 9.32422C21.4173 7.11508 23.2081 5.32422 25.4173 5.32422L110.75 5.32439C112.96 5.32439 114.75 7.11525 114.75 9.32439L114.75 77.3735C114.75 79.5827 112.96 81.3735 110.75 81.3735L46.5454 81.3735L22.984 97.5798C22.3205 98.0362 21.4173 97.5612 21.4173 96.7559Z"
        fill="url(#paint0_linear)"
      />
      <path
        d="M114.75 9.32439L109.75 9.32439L114.75 9.32439ZM114.75 77.3735L119.75 77.3735L114.75 77.3735ZM110.75 5.32439L110.75 10.3244L110.75 5.32439ZM25.4173 5.32422L25.4173 10.3242L25.4173 5.32422ZM46.5454 81.3735L46.5454 76.3735L44.9919 76.3735L43.7119 77.2539L46.5454 81.3735ZM22.984 97.5798L20.1504 93.4603L20.1504 93.4603L22.984 97.5798ZM110.75 81.3735L110.75 76.3735L110.75 76.3735L110.75 81.3735ZM16.4173 9.32422L16.4173 96.7559L26.4173 96.7559L26.4173 9.32422L16.4173 9.32422ZM109.75 9.32439L109.75 77.3735L119.75 77.3735L119.75 9.32439L109.75 9.32439ZM110.751 0.324389L25.4173 0.324222L25.4173 10.3242L110.75 10.3244L110.751 0.324389ZM43.7119 77.2539L20.1504 93.4603L25.8175 101.699L49.379 85.4931L43.7119 77.2539ZM110.75 76.3735L46.5454 76.3735L46.5454 86.3735L110.75 86.3735L110.75 76.3735ZM119.75 9.32439C119.75 4.35383 115.721 0.324398 110.751 0.324389L110.75 10.3244C110.198 10.3244 109.75 9.87667 109.75 9.32439L119.75 9.32439ZM26.4173 9.32422C26.4173 9.87651 25.9695 10.3242 25.4173 10.3242L25.4173 0.324222C20.4467 0.324213 16.4173 4.35365 16.4173 9.32422L26.4173 9.32422ZM16.4173 96.7559C16.4173 101.588 21.8366 104.438 25.8176 101.699L20.1504 93.4603C22.8043 91.6348 26.4173 93.5348 26.4173 96.7559L16.4173 96.7559ZM109.75 77.3735C109.75 76.8212 110.198 76.3735 110.75 76.3735L110.75 86.3735C115.721 86.3735 119.75 82.3441 119.75 77.3735L109.75 77.3735Z"
        fill="url(#paint1_linear)"
        mask="url(#path-1-outside-1)"
      />
      <path d="M133.902 55L125.598 46.2788L133.902 37.5549H67.2056V55H133.902Z" fill="#A6A6A6" />
      <path
        d="M0.550316 38L8.85482 46.7212L0.550316 55.4451L67.2471 55.4451V38L0.550316 38Z"
        fill="url(#paint3_linear)"
      />
      <mask
        id="path-5-outside-2"
        maskUnits="userSpaceOnUse"
        x={17.7505}
        y={2.32421}
        width={100}
        height={99}
        fill="black"
      >
        <rect fill="white" x={17.7505} y={2.32421} width={100} height={99} />
        <path d="M21.4173 96.7559L21.4173 9.32422C21.4173 7.11508 23.2081 5.32422 25.4173 5.32422L110.75 5.32439C112.96 5.32439 114.75 7.11525 114.75 9.32439L114.75 77.3735C114.75 79.5827 112.96 81.3735 110.75 81.3735L46.5454 81.3735L22.984 97.5798C22.3205 98.0362 21.4173 97.5612 21.4173 96.7559Z" />
      </mask>
      <path
        d="M21.4173 96.7559L21.4173 9.32422C21.4173 7.11508 23.2081 5.32422 25.4173 5.32422L110.75 5.32439C112.96 5.32439 114.75 7.11525 114.75 9.32439L114.75 77.3735C114.75 79.5827 112.96 81.3735 110.75 81.3735L46.5454 81.3735L22.984 97.5798C22.3205 98.0362 21.4173 97.5612 21.4173 96.7559Z"
        fill="url(#paint4_linear)"
      />
      <path
        d="M114.75 9.32439L111.75 9.32439L114.75 9.32439ZM114.75 77.3735L117.75 77.3735L114.75 77.3735ZM110.75 5.32439L110.75 8.32439L110.75 5.32439ZM25.4173 5.32422L25.4173 8.32422L25.4173 5.32422ZM46.5454 81.3735L46.5454 78.3735L45.6133 78.3735L44.8453 78.9018L46.5454 81.3735ZM22.984 97.5798L21.2838 95.1081L21.2838 95.1081L22.984 97.5798ZM110.75 81.3735L110.75 78.3735L110.75 78.3735L110.75 81.3735ZM18.4173 9.32422L18.4173 96.7559L24.4173 96.7559L24.4173 9.32422L18.4173 9.32422ZM111.75 9.32439L111.75 77.3735L117.75 77.3735L117.75 9.32439L111.75 9.32439ZM110.75 2.32439L25.4173 2.32422L25.4173 8.32422L110.75 8.32439L110.75 2.32439ZM44.8453 78.9018L21.2838 95.1081L24.6841 100.052L48.2456 83.8453L44.8453 78.9018ZM110.75 78.3735L46.5454 78.3735L46.5454 84.3735L110.75 84.3735L110.75 78.3735ZM117.75 9.32439C117.75 5.4584 114.616 2.3244 110.75 2.32439L110.75 8.32439C111.303 8.32439 111.75 8.7721 111.75 9.32439L117.75 9.32439ZM24.4173 9.32422C24.4173 8.77194 24.865 8.32422 25.4173 8.32422L25.4173 2.32422C21.5513 2.32421 18.4173 5.45822 18.4173 9.32422L24.4173 9.32422ZM18.4173 96.7559C18.4173 99.977 22.0302 101.877 24.6841 100.052L21.2838 95.1081C22.6108 94.1953 24.4173 95.1454 24.4173 96.7559L18.4173 96.7559ZM111.75 77.3735C111.75 77.9258 111.303 78.3735 110.75 78.3735L110.75 84.3735C114.616 84.3735 117.75 81.2395 117.75 77.3735L111.75 77.3735Z"
        fill="black"
        mask="url(#path-5-outside-2)"
      />
      <text x="50%" y="50%" dominantBaseline="middle" textAnchor="middle" font-weight="600" fill="url(#paint5_radial)">
        {point}
      </text>
    </g>
    <defs>
      <linearGradient
        id="paint0_linear"
        x1={68.0839}
        y1={-4.59244}
        x2={68.0839}
        y2={98.6575}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#898989" />
        <stop offset={1} stopColor="#242424" />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1={68.0839}
        y1={-4.00911}
        x2={68.0839}
        y2={98.6575}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#454545" />
        <stop offset={1} stopColor="#898989" />
      </linearGradient>
      <linearGradient
        id="paint2_linear"
        x1={141.193}
        y1={46.1433}
        x2={112.531}
        y2={46.1575}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#515151" />
        <stop offset={0.71875} stopColor="#BBBBBB" />
      </linearGradient>
      <linearGradient
        id="paint3_linear"
        x1={-6.7406}
        y1={46.8567}
        x2={21.9213}
        y2={46.8425}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#515151" />
        <stop offset={0.71875} stopColor="#BBBBBB" />
      </linearGradient>
      <linearGradient
        id="paint4_linear"
        x1={68.0839}
        y1={-4.59244}
        x2={68.0839}
        y2={98.6575}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#898989" />
        <stop offset={1} stopColor="#242424" />
      </linearGradient>
      <radialGradient
        id="paint5_radial"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(67.5 47.2615) rotate(180) scale(47 202.227)"
      >
        <stop stopColor="#BBBBBB" />
        <stop offset={1} stopColor="#A6A6A6" />
      </radialGradient>
    </defs>
  </svg>
);

export default SVGBadge;
