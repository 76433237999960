import React from 'react';
import styled from '@emotion/styled/macro';
import { css, keyframes } from '@emotion/core';
import PropTypes from 'prop-types';

const bounceKeyframes = keyframes`
 fill: linear-gradient(270.02deg, #fdb913 0.02%, #f2994a 99.98%);
`;

const Svg = styled.svg`
  ${({ bounce }) =>
    bounce &&
    css`
      animation: ${bounceKeyframes};
    `}
`;

function SvgFavorite({ fill, ...props }) {
  return (
    <Svg width="1em" height="1em" viewBox="0 0 25.505 21.5" {...props}>
      <path
        d="M16.7 6.451h.859l-.085-.251h-.69zm-3.576 10.988l.823.615.266-.215-.982-.729zm-.343 1.052l.315.252.185-.15-.426-.328zM16.1 8.293l2.082-.052-.114-.328-1.855.034zm.957-2.935l-.176.539h.484l-.258-.742zM17.03 15.5l.876.607-1.48-3.634 2.645-1.8h-.909L15.754 12.3l1.287 3.2zm-5.965-4.828h-.285l-.25.084.206.353.246.171-.206-.475zm3.889 6.583l.309-.226-1.617-1.189-.111.341zM18.8 13.1l3.356-2.428h-.972L17.945 12.9l1.6 4.338.9.626zm-5.057 2.434l1.777 1.306.3-.218-1.969-1.415zm7.015-1.763l.513 1.471 3.182-4.17zm-8.2 5.411l.3-.24-.173-.139zm5.019-6.393l3.073-2.117h-1.036L16.8 12.586l1.563 3.84.738.512zM18.688 0a6.826 6.826 0 00-5.936 3.46A6.818 6.818 0 000 6.8a10.757 10.757 0 002 5.978 21.952 21.952 0 004.25 4.568 36.125 36.125 0 005.111 3.521l2.239-6.854-.055-.117L7.59 9.919h7.345l.5-1.537V8.31h.025l1.016-3.119.541-2.177 2.369 6.905h5.66A11.351 11.351 0 0025.5 6.8 6.818 6.818 0 0018.688 0zm-5.733 17.958l.57.439.177-.143-.678-.508zm.521-4.922l.587.407-.828-1.843 1.389-.925h-.733l-1.151.791zm2.9 3.18L14.06 14.57l-.108.332 2.129 1.529zm3.659-2.672l3.933-2.873H22.68l-3.514 2.542 1.7 4.948 1.024.71zm-4.857-2.873l-1.558 1.036.93 2.073.674.468-.994-2.383 1.729-1.194zm-.035 7.2L11.412 20.9c.556.314.906.49.946.511a.885.885 0 00.788 0c.028-.014.2-.1.472-.248a30.65 30.65 0 003.464-2.183 27.25 27.25 0 001.7-1.345l-1.669-1.2zM13.331 16.8l1.127.835.242-.2-1.266-.957zm3.6-1l-1.99-1.38-.685-.475-.1.314 2.477 1.761zm-1.258-6.186l2.965-.074-.123-.353-2.725.068zm-3.341 1.057h-.542l-.62.275.381.788.41.275-.4-.933zm3.121-.383h3.464l-.144-.367h-3.2zM16.492 7.1l1.284-.027-.109-.313H16.6zm-4.061 5.19l.561.389-.624-1.332.984-.676h-.487l-.9.569zm3.459-3.344l2.519-.063-.119-.34L16 8.6zm.425-1.306l1.649-.031-.083-.237-1.49.032zm-.936 4.543l1.213 3.017-.9-.624-1.084-2.6 1.889-1.3h1.122z"
        fill={fill}
        fillRule="evenodd"
      />
    </Svg>
  );
}

SvgFavorite.propTypes = {
  fill: PropTypes.string,
};

SvgFavorite.defaultProps = {
  fill: '#898989',
};

export default SvgFavorite;
