import React from 'react';
import PropTypes from 'prop-types';

function SvgAddToPlaylist({ fill, ...props }) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 216.805 166.596" {...props}>
      <g data-name="Group 1846" fill={fill}>
        <path
          data-name="Add to Playlist _ +"
          d="M147.831 156.746v-39.41h-39.414a9.853 9.853 0 010-19.706h39.414V58.22a9.853 9.853 0 0119.706 0v39.41h39.412a9.853 9.853 0 110 19.706h-39.412v39.41a9.853 9.853 0 01-19.706 0z"
        />
        <path
          data-name="Path 888"
          d="M83.756 98.447H6.442c-3.866 0-6.443 3.946-6.443 9.866s2.577 9.866 6.443 9.866h77.314c3.865 0 6.443-3.946 6.443-9.866s-2.578-9.866-6.443-9.866z"
        />
        <path
          data-name="Path 889"
          d="M128.262 49.225H9.866c-5.919 0-9.867 3.946-9.867 9.866s3.946 9.866 9.867 9.866h118.4c5.919 0 9.867-3.946 9.867-9.866s-3.951-9.866-9.871-9.866z"
        />
        <path
          data-name="Path 890"
          d="M128.262.001H9.866C3.947.001-.001 3.947-.001 9.867s3.946 9.866 9.867 9.866h118.4c5.919 0 9.867-3.946 9.867-9.866S134.182.001 128.262.001z"
        />
      </g>
    </svg>
  );
}

SvgAddToPlaylist.propTypes = {
  fill: PropTypes.string,
};

SvgAddToPlaylist.defaultProps = {
  fill: '#fff',
};

export default SvgAddToPlaylist;
