import * as t from '../types';

const INITIAL_STATE = {
  data: {},
  fetching: {},
  byTag: {},
  playlist: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case t.GET_VIDEO_SCREEN_REQUEST:
      return { ...state, fetching: { ...state.fetching, all: true } };

    case t.GET_VIDEO_SCREEN_SUCCESS:
      return { ...state, fetching: { ...state.fetching, all: false } };

    case t.GET_VIDEO_SCREEN_FAILURE:
      return { ...state, fetching: { ...state.fetching, all: false } };

    case t.GET_VIDEO_BY_TAG_SUCCESS:
      return { ...state, byTag: { ...state.byTag, [action.payload.tag]: action.payload.data } };

    case t.GET_VIDEO_REQUEST:
      return { ...state, fetching: { ...state.fetching, [action.payload]: true } };

    case t.GET_VIDEO_SUCCESS:
      return {
        ...state,
        data: { ...state.data, [action.payload.id]: action.payload.data },
        fetching: { ...state.fetching, [action.payload.id]: false },
      };

    case t.GET_VIDEO_FAILURE:
      return { ...state, fetching: { ...state.fetching, [action.payload]: false } };

    case t.GET_VIDEO_PLAYLIST_REQUEST:
      return { ...state, fetching: { ...state.fetching, [action.payload]: true } };

    case t.GET_VIDEO_PLAYLIST_SUCCESS:
      return {
        ...state,
        playlist: { ...state.playlist, [action.payload.id]: action.payload.data },
        fetching: { ...state.fetching, [action.payload.id]: false },
      };

    case t.GET_VIDEO_PLAYLIST_FAILURE:
      return { ...state, fetching: { ...state.fetching, [action.payload]: false } };

    case t.LOGOUT:
      return { ...INITIAL_STATE };
    default:
      return { ...state };
  }
};
