import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

export const useTimer = (stopAtSeconds, onStop) => {
  const [startTime, setStartTime] = useState();
  const [seconds, _setSeconds] = useState(0);
  const [prevSeconds, setPrevSeconds] = useState(0);
  const [isActive, setIsActive] = useState(false);
  const [lastElapsed, setLastElapsed] = useState(0);

  const reset = () => {
    _setSeconds(0);
    setPrevSeconds(0);
    setLastElapsed(0);
    setIsActive(false);
  };

  const pause = () => {
    setIsActive(false);
    // eslint-disable-next-line no-underscore-dangle
    const _lastElapsed = seconds - prevSeconds;
    setLastElapsed(_lastElapsed);
    setPrevSeconds(seconds);

    return _lastElapsed;
  };

  const start = () => {
    setStartTime(Date.now());
    setIsActive(true);
    setLastElapsed(0);
  };

  const setSeconds = next => {
    _setSeconds(next);
    setPrevSeconds(next);
  };

  useEffect(() => {
    if (stopAtSeconds && seconds >= stopAtSeconds) {
      reset();
      if (onStop) {
        onStop();
      }
    }

    let interval = null;
    if (isActive && startTime) {
      interval = setInterval(() => {
        _setSeconds((Date.now() - startTime) / 1000 + prevSeconds);
      }, 10);
    } else if (!isActive && seconds !== 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, [isActive, seconds]);

  return { seconds, pause, start, reset, isActive, setSeconds, lastElapsed };
};

useTimer.defaultProps = {
  stopAtSeconds: 0,
  onStop: () => {},
};

useTimer.propTypes = {
  stopAtSeconds: PropTypes.number,
  onStop: PropTypes.func,
};
