import * as Sentry from '@sentry/browser';

import { Navbar, Sidebar } from 'components/molecules';
import React, { useContext, useEffect, useState, useCallback, memo } from 'react';
import { getConnectionsAction, getProfileAction, getRemoteConfigAction, logoutAction } from 'redux/actions';
import { useAlert, useUser } from 'hooks';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { AuthModalContext } from 'contexts/AuthModalContext';
import NetworkDetector from './NetworkDetector';
import Player from 'components/organisms/Player';
import { PushNotificationContext } from 'contexts/PushNotificationContext';
import { css } from '@emotion/core';
import { isObjEmpty } from 'lib/utils';
import styled from '@emotion/styled/macro';
import { useMedia } from 'react-use';
// import { useHistory } from 'react-router-dom';

const PageContent = styled.div`
  background-color: ${({ theme }) => theme.colors.black};
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-top: ${props => (props.loggedIn === true ? '6rem' : '0rem')};
  position: relative;
  transition: all 0.8s;
  width: 100%;
  overflow-x: hidden;
  scroll-behavior: smooth;
  

  ${({ sidebarActive, theme }) =>
    sidebarActive &&
    css`
      ${theme.mq.lg`
        padding-left: 22rem;
      `}
    `}

    ${({ searchBarActive }) =>
      searchBarActive &&
      css`
        padding-top: 11rem;
      `}

  ${({ playerVisible }) =>
    playerVisible &&
    css`
      padding-bottom: 8rem;
    `}
`;

const Main = styled.main`
  display: flex;
  flex-direction: column;
  max-width: 1600px;
  width: 100%;
  margin: 0 auto;
  padding-bottom: ${props => (props.showSubAlert === true ? '8rem' : '0rem')};
  // @media (max-width: 500px) {
  //   padding-left: 10px;
  //   padding-right: 10px;
  // }
`;

const PageWrapper = withRouter(({ children }) => {
  const dispatch = useDispatch();
  const { addAlert, alert, removeAlert } = useAlert();
  const { toggleAuthModal } = useContext(AuthModalContext);
  const { togglePermissionModal, refreshNotificationToken } = useContext(PushNotificationContext);
  const [showSidebar, setShowSidebar] = useState(false);
  const [searchBar, setSearchBar] = useState(false);
  const [sub, setSub] = useState(false);
  const toggleSearchBar = useCallback(() => setSearchBar(v => !v), []);
  const { user: fireUser, loaded, user } = useUser();
  const loggedIn = useSelector(state => state.auth.loggedIn);
  // const { location } = useHistory();
  // const [isReady, cancel] = useTimeout(90000);
  const showPlayer = useSelector(
    ({ player: { queue, shuffleQueue, magicQueue } }) => !!(queue.length || shuffleQueue.length || magicQueue.length),
  );
  const verificationMessage = 'A verification mail has been sent to your email address.';
  const getConnections = type => dispatch(getConnectionsAction(type));
  const getRemoteConfig = () => dispatch(getRemoteConfigAction());
  const logout = () => dispatch(logoutAction());
  const getProfile = () => dispatch(getProfileAction());
  const toggleSidebar = useCallback(() => setShowSidebar(v => !v), []);
  const userSub = useSelector(({ profile }) => profile.user || {});
  const isWide = useMedia('(max-width: 1160px)');

  useEffect(() => {
    if (isWide) {
      setShowSidebar(false);
    } else {
      setShowSidebar(true);
    }
  }, [isWide]);

  useEffect(() => {
    if (fireUser) {
      Sentry.configureScope(scope => {
        scope.setUser({ id: fireUser.uid });
      });
    } else {
      Sentry.configureScope(scope => {
        scope.clear();
      });
    }
  }, [fireUser]);

  useEffect(() => {
    (async () => {
      if (fireUser) {
        await getProfile();
        getRemoteConfig();
        getConnections('tracks');
        getConnections('albums');
        getConnections('playlists');
        getConnections('artists');
      }
    })();
    // eslint-disable-next-line
  }, [fireUser]);

  // Push notifications permission alert
  useEffect(() => {
    let timeout;
    if (fireUser) {
      if ('Notification' in window) {
        if (Notification && Notification.permission !== 'granted') {
          timeout = setTimeout(() => {
            togglePermissionModal();
          }, 30000);
        } else {
          refreshNotificationToken();
        }
      }
    }
    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [fireUser, refreshNotificationToken, togglePermissionModal]);

  const [freshLoad, setFreshLoad] = useState(true);

  // Verification email alert
  useEffect(() => {
    if (!freshLoad) {
      return;
    }
    if (isObjEmpty(alert) && fireUser && fireUser?.email && !fireUser?.emailVerified) {
      addAlert({
        message: 'A verification mail has been sent to your email address......',
        type: 'success',
        dismissable: true,
        duration: 30000,
      });
    }
    return () => {
      setFreshLoad(false);
    };
    // eslint-disable-next-line
  }, [alert, fireUser]);

  useEffect(() => {
    if (!fireUser && alert.message === verificationMessage) {
      removeAlert();
    }
    // eslint-disable-next-line
  }, [fireUser, alert]);

  useEffect(() => {
    if (user !== null && !isObjEmpty(userSub) && userSub?.subscription?.status !== 'ACTIVE') {
      setSub(true);
    } else {
      setSub(false);
    }

    // eslint-disable-next-line
  }, []);

  return (
    <PageContent loggedIn={loggedIn} playerVisible={showPlayer} sidebarActive={showSidebar} searchBarActive={searchBar}>
      {loaded && <Main showSubAlert={sub}>{children}</Main>}
      <Sidebar
        active={showSidebar}
        closeSidebar={() => setShowSidebar(false)}
        loggedIn={loggedIn}
        playerVisible={showPlayer}
        toggleSidebar={toggleSidebar}
        showTheSideBar={() => setShowSidebar(true)}
      />
      {loaded && <Player visible={showPlayer} />}
      {/* {(location.pathname !== '/auth/login' || '/auth/signup') && user === null && isReady() && (
        <LoginPrompt text="Want to listen to more music?" />
      )} */}
      <NetworkDetector />
      <Navbar
        active={showSidebar}
        loggedIn={loggedIn}
        logout={logout}
        searchBar={searchBar}
        toggleAuthModal={toggleAuthModal}
        toggleSearchBar={toggleSearchBar}
        toggleSidebar={toggleSidebar}
        showSidebar={showSidebar}
      />
    </PageContent>
  );
});

export default memo(PageWrapper);
