import React from 'react';
import PropTypes from 'prop-types';

function SvgFile({ fill, ...props }) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 387.467 508.41" {...props}>
      <path
        d="M283.149 284.115h-178.83c-8.937 0-14.894-5.978-14.894-14.945s5.958-14.965 14.894-14.965h178.83c8.937 0 14.914 6 14.914 14.965s-5.978 14.945-14.914 14.945zm-193.724 59.82c0-8.967 5.958-14.965 14.894-14.965h178.83c8.937 0 14.914 6 14.914 14.965s-5.977 14.947-14.914 14.947h-178.83c-8.937 0-14.894-5.983-14.894-14.947zm14.894 59.82h178.83c8.937 0 14.914 5.978 14.914 14.945s-5.977 14.945-14.914 14.945h-178.83c-8.937 0-14.894-5.976-14.894-14.945s5.957-14.945 14.894-14.945zM208.638 47.863l131.148 131.576H253.34c-23.831 0-44.7-20.923-44.7-44.855zm166.914 125.6L214.6 11.977C205.659 5.999 193.744-.001 181.808-.001H74.511C32.787-.001 0 32.899 0 74.784v358.861c0 41.886 32.787 74.765 74.511 74.765h238.446c41.724 0 74.511-32.879 74.511-74.765V206.36c0-11.956-2.979-23.932-11.916-32.899z"
        fill={fill}
        fillRule="evenodd"
      />
    </svg>
  );
}

SvgFile.propTypes = {
  fill: PropTypes.string,
};

SvgFile.defaultProps = {
  fill: '#fefefe',
};

export default SvgFile;
