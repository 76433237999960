import api from 'services/api';
import { arrayOfObjectsEqual } from 'lib/utils';
import * as t from '../types';

export const getScreenAction = id => async (dispatch, getState) => {
  try {
    const screen = getState().screen.data[id] || [];
    dispatch({ type: t.GET_SCREEN_REQUEST, payload: id });
    const resp = await api.getDynamicScreen(id);
    if (resp.status === 200 || resp.status === 201) {
      const changed = !arrayOfObjectsEqual(screen, resp.data) ? { id, data: resp.data } : { id };
      dispatch({
        type: t.GET_SCREEN_SUCCESS,
        payload: changed,
      });
      return resp.data;
    }
    throw resp.data;
  } catch (e) {
    dispatch({ type: t.GET_SCREEN_FAILURE, payload: id });
    return Promise.reject(e);
  }
};

export const getScreenSectionAction = (id, callback = () => {}) => async dispatch => {
  try {
    dispatch({ type: t.GET_SCREEN_SECTION_REQUEST, payload: id });
    const resp = await api.getSectionById(id);

    if (resp.status === 200 || resp.status === 201) {
      dispatch({
        type: t.GET_SCREEN_SECTION_SUCCESS,
        payload: { id, data: resp.data },
      });
      callback(resp.data, null);
    } else {
      throw resp.data;
    }
  } catch (e) {
    callback(null, e);
    dispatch({ type: t.GET_SCREEN_SECTION_FAILURE, payload: id });
  }
};

export const getScreenSectionByIdAction = (id, target, callback = () => {}) => async dispatch => {
  try {
    dispatch({ type: t.GET_SCREEN_SECTION_REQUEST, payload: [id, target] });
    const resp = await api.getScreenSectionById(id, target);

    if (resp.status === 200 || resp.status === 201) {
      dispatch({
        type: t.GET_SCREEN_SECTION_SUCCESS,
        payload: { id, target, data: resp.data },
      });
      callback(resp.data, null);
    } else {
      throw resp.data;
    }
  } catch (e) {
    callback(null, e);
    dispatch({ type: t.GET_SCREEN_SECTION_FAILURE });
  }
};

export const getArtistScreenAction = (id, callback = () => {}) => async dispatch => {
  try {
    dispatch({ type: t.GET_ARTIST_SCREEN_REQUEST, payload: id });
    const resp = await api.getArtistScreen(id);

    if (resp.status === 200 || resp.status === 201) {
      dispatch({
        type: t.GET_ARTIST_SCREEN_SUCCESS,
        payload: { id, data: resp.data },
      });
      callback(resp.data, null);
    } else {
      throw resp.data;
    }
  } catch (e) {
    callback(null, e);
    dispatch({ type: t.GET_ARTIST_SCREEN_FAILURE, payload: id });
  }
};
