import React from 'react';
import styled from '@emotion/styled/macro';
import { Box } from 'rebass';
import PropTypes from 'prop-types';
import { Typography } from 'components/atoms';
import { CheckBox } from 'components/atoms/Form/CheckBox';

const Wrapper = styled(Box)`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.metadataBg};
  border-radius: 0.5rem;
  cursor: pointer;
  justify-content: space-between;
  padding-bottom: 1.5rem;
  padding-right: 3rem;
  padding-top: 1.5rem;

  svg {
    height: 3rem;
    margin-left: -1rem;
    width: 3rem;
  }

  &:not(:last-child) {
    margin-bottom: 3rem;
  }
`;

const Text = styled(Typography.Text)`
  color: ${({ theme }) => theme.colors.switchingText};
  display: inline-block;
`;

export const SelectUploadBox = ({ icon: Icon, onClick, selected, text }) => {
  return (
    <Wrapper onClick={onClick}>
      <Icon />
      <Text>{text}</Text>
      <CheckBox checked={selected} />
    </Wrapper>
  );
};

SelectUploadBox.propTypes = {
  icon: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
};
