import api from 'services/api';

export const getBeatsAction = async cb => {
  try {
    const resp = await api.getStudioxBeats();
    if (resp.status === 200 || resp.status === 201) {
      return cb(null, resp.data);
    } else {
      throw resp.data;
    }
  } catch (error) {
    cb(error);
  }
};

export const joinStudioxCompetitionAction = async cb => {
  try {
    const resp = await api.joinStudioxCompetition();
    if (resp.status === 200 || resp.status === 201) {
      return cb(null, resp.data);
    } else {
      throw resp.data;
    }
  } catch (error) {
    cb(error);
  }
};
