import React from 'react';

function SvgDone(props) {
  return (
    <svg width="24" height="15" viewBox="0 0 24 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M1 6.43529L9.14815 14.2L23 1"
        stroke="url(#paint0_linear)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient id="paint0_linear" x1="12" y1="1" x2="12" y2="14.2" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FDB913" />
          <stop offset="1" stopColor="#F2994A" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default SvgDone;
