import api from 'services/api';
import { firstLetterUc } from 'lib/utils';
import * as t from '../types';

export const updateUserByIdAction = id => async dispatch => {
  try {
    dispatch({ type: t.UPDATE_USER_BY_ID_REQUEST, payload: id });
    const resp = await api.getUserById(id);
    if (resp.status === 200 || resp.status === 201) {
      const user = resp.data;
      dispatch({
        type: t.UPDATE_USER_BY_ID_SUCCESS,
        payload: { id, data: user },
      });
    } else {
      throw resp.data;
    }
  } catch (e) {
    dispatch({ type: t.UPDATE_USER_BY_ID_FAILURE, payload: id });
  }
};

export const getUserByIdAction = (id, callback = () => {}) => async dispatch => {
  try {
    dispatch({ type: t.GET_USER_BY_ID_REQUEST, payload: id });
    const resp = await api.getUserById(id);

    if (resp.status === 200 || resp.status === 201) {
      const user = resp.data;
      const screenResp = await api.getDynamicScreen('profile', { owner: id });
      let sections = [];
      if (screenResp.status === 200 || screenResp.status === 201) {
        sections = screenResp.data.map(x => ({ ...x, title: x.title || firstLetterUc(x.target) }));
      }
      callback(user, null);
      dispatch({
        type: t.GET_USER_BY_ID_SUCCESS,
        payload: { id, data: user, sections },
      });
    } else {
      throw resp.data;
    }
  } catch (e) {
    dispatch({ type: t.GET_USER_BY_ID_FAILURE, payload: id });
    callback(null, e);
  }
};
