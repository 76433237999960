import React from 'react';
import PropTypes from 'prop-types';

function SvgTextBubble({ fill, ...props }) {
  return (
    <svg
      id="text-bubble_svg__speech-bubble-with-text-lines"
      width="1em"
      height="1em"
      viewBox="0 0 26.012 24.957"
      {...props}
    >
      <defs>
        <style>{`.text-bubble_svg__cls-1{fill:${fill}}`}</style>
      </defs>
      <g id="text-bubble_svg__Group_24" data-name="Group 24">
        <path
          id="text-bubble_svg__Path_12"
          d="M21.451 7.23H4.56A4.566 4.566 0 000 11.791v10.418a4.566 4.566 0 004.56 4.56h10.759l5.15 5.15a.912.912 0 001.557-.645v-4.541a4.567 4.567 0 003.986-4.524V11.791a4.566 4.566 0 00-4.561-4.561zm2.736 14.979a2.739 2.739 0 01-2.736 2.736h-.337a.912.912 0 00-.912.912v3.215l-3.86-3.86a.912.912 0 00-.645-.267H4.56a2.739 2.739 0 01-2.736-2.736V11.791A2.739 2.739 0 014.56 9.054h16.891a2.739 2.739 0 012.736 2.736z"
          className="text-bubble_svg__cls-1"
          data-name="Path 12"
          transform="translate(0 -7.23)"
        />
        <path
          id="text-bubble_svg__Path_13"
          d="M88.08 95.743H74.654a.912.912 0 000 1.824H88.08a.912.912 0 100-1.824z"
          className="text-bubble_svg__cls-1"
          data-name="Path 13"
          transform="translate(-68.361 -89.284)"
        />
        <path
          id="text-bubble_svg__Path_14"
          d="M88.08 165.743H74.654a.912.912 0 100 1.824H88.08a.912.912 0 100-1.824z"
          className="text-bubble_svg__cls-1"
          data-name="Path 14"
          transform="translate(-68.361 -154.177)"
        />
      </g>
    </svg>
  );
}

SvgTextBubble.propTypes = {
  fill: PropTypes.string,
};

SvgTextBubble.defaultProps = {
  fill: '#fff',
};

export default SvgTextBubble;
