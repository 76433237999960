import React from 'react';
import PropTypes from 'prop-types';

function SvgHome({ fill, ...props }) {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M0 22.8135V13.3144C0 12.3908 0.347435 11.501 0.973281 10.8217L9.25012 1.83877C10.7071 0.257515 13.2038 0.257521 14.6608 1.83878L22.9376 10.8218C23.5634 11.501 23.9109 12.3908 23.9109 13.3144V22.8135C23.9109 23.8293 23.0874 24.6528 22.0716 24.6528L15.634 24.6528V15.4564C15.634 14.9484 15.2223 14.5367 14.7144 14.5367L9.1965 14.5367C8.68859 14.5367 8.27684 14.9484 8.27684 15.4563V24.6528H1.8393C0.823481 24.6528 0 23.8293 0 22.8135Z"
        fill={fill}
      />
    </svg>
  );
}

SvgHome.propTypes = {
  fill: PropTypes.string,
};

SvgHome.defaultProps = {
  fill: '#fcb316',
};

export default SvgHome;
