export const ADD_TO_QUEUE = 'ADD_TO_QUEUE';
export const CLEAR_QUEUE = 'CLEAR_QUEUE';
export const GET_THEN_ADD_FAILURE = 'GET_THEN_ADD_FAILURE';
export const GET_THEN_ADD_REQUEST = 'GET_THEN_ADD_REQUEST';
export const GET_THEN_ADD_SUCCESS = 'GET_THEN_ADD_SUCCESS';
export const MAGIC_PLAYLIST_FAILURE = 'MAGIC_PLAYLIST_FAILURE';
export const MAGIC_PLAYLIST_REQUEST = 'MAGIC_PLAYLIST_REQUEST';
export const MAGIC_PLAYLIST_SUCCESS = 'MAGIC_PLAYLIST_SUCCESS';
export const NEXT_TRACK = 'NEXT_TRACK';
export const ORDER_QUEUE = 'ORDER_QUEUE';
export const PAUSE = 'PAUSE';
export const PLAY = 'PLAY';
export const PLAY_NEXT = 'PLAY_NEXT';
export const PLAY_TRACK = 'PLAY_TRACK';
export const PLAY_TRACKS = 'PLAY_TRACKS';
export const PLAYER_STATE_CHANGE = 'PLAYER_STATE_CHANGE';
export const PREVIOUS_TRACK = 'PREVIOUS_TRACK';
export const QUEUE_ENDED = 'QUEUE_ENDED';
export const REMOVE_FROM_QUEUE = 'REMOVE_FROM_QUEUE';
export const REPEAT_TRACK = 'REPEAT_TRACK';
export const REPEAT_TRACKS = 'REPEAT_TRACKS';
export const RESET_ITEM = 'RESET_ITEM';
export const SHUFFLE_TRACKS = 'SHUFFLE_TRACKS';
export const TOGGLE_MUTE = 'TOGGLE_MUTE';
export const TOGGLE_PLAY = 'TOGGLE_PLAY';
export const TOGGLE_REPEAT = 'TOGGLE_REPEAT';
export const TOGGLE_SHUFFLE = 'TOGGLE_SHUFFLE';
export const UPDATE_PROGRESS = 'UPDATE_PROGRESS';
export const VALID_PLAY = 'VALID_PLAY';
export const VALID_PLAY_FAILURE = 'VALID_PLAY_FAILURE';
export const VOLUME_CHANGE = 'VOLUME_CHANGE';
