import React from 'react';
import styled from '@emotion/styled/macro';
import PropTypes from 'prop-types';
import { Typography, Progressbar } from 'components/atoms';
import { CloseIcon } from 'components/icons';
import { Box } from 'rebass';

function bytesToSize(bytes) {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return '0 Byte';
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
  return `${Math.round(bytes / 1024 ** i, 2)} ${sizes[i]}`;
}

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.metadataBg};
  border-radius: 0.5rem;
  margin-bottom: 2rem;
  padding: 2rem 2rem;
  width: 100%;

  ${({ theme }) => theme.mq.sm`
  padding: 2rem 5rem;
  `}

  ${({ theme }) => theme.mq.sm`
  padding: 2rem 10rem;
  `}
`;

const Title = styled(Typography.Paragraph)`
  font-weight: bold;
  margin-bottom: 0.2rem;
`;

const Size = styled(Typography.Text)`
  color: ${({ theme }) => theme.colors['brownish-grey-five']};
  display: inline-block;
  font-size: ${({ theme }) => theme.font.size.small};
  margin-bottom: 1.5rem;
`;

const Percentage = styled(Typography.Text)`
  color: ${({ theme }) => theme.colors['brownish-grey-five']};
  display: inline-block;
  font-size: ${({ theme }) => theme.font.size.tiny};
`;

const Button = styled.button`
  margin-left: 3rem;
  height: 100%;
  text-align: center;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
`;

export const Progress = ({ done, progress, removeItem, showRemove, size, track, uploading }) => {
  const renderPercentage = () => {
    if (uploading || !done) {
      if (progress < 100) {
        return <Percentage>{parseInt(progress, 10)}% complete</Percentage>;
      }
    }
    return null;
  };

  const renderText = () => {
    if (done) {
      return 'Uploaded';
    }
    if (progress >= 100) {
      return 'Processing...';
    }
    return `${bytesToSize((size * progress) / 100) || 0}/${bytesToSize(size)}`;
  };

  return (
    <Wrapper>
      <Box flexDirection="row">
        <Box flexDirection="column" flex="1">
          <Title color="switchingText">{track}</Title>
          <Size>{renderText()}</Size>
          <Progressbar progress={done ? 100 : progress} animate={!done && progress >= 100} />
          {renderPercentage()}
        </Box>
        {showRemove && (
          <Button onClick={removeItem}>
            <CloseIcon width="1.6rem" />
          </Button>
        )}
      </Box>
    </Wrapper>
  );
};

Progress.propTypes = {
  done: PropTypes.bool,
  progress: PropTypes.number,
  size: PropTypes.number,
  track: PropTypes.string.isRequired,
  uploading: PropTypes.bool.isRequired,
  removeItem: PropTypes.func,
  showRemove: PropTypes.bool,
};

Progress.defaultProps = {
  showRemove: false,
  done: false,
  progress: 0,
  removeItem: () => {},
  size: 0,
};
