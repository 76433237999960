import React from 'react';
import { EnabledIcon } from 'components/icons';
import { PaymentSuccessfulWrapper } from './styled';
const PaymentSuccessful = () => {
  return (
    <PaymentSuccessfulWrapper>
      <EnabledIcon />
      <p>Done! Your balance has been updated</p>
    </PaymentSuccessfulWrapper>
  );
};

export default PaymentSuccessful;
