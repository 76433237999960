import { Box, Flex } from 'rebass';
import { Button, Form, Image, Typography } from 'components/atoms';
import { LikeIcon } from 'components/icons';
import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import distanceInWordsToNow from 'date-fns/formatDistanceToNow';
import { ellipsize } from 'lib/utils';
import styled from '@emotion/styled/macro';
import { useSelector } from 'react-redux';

const MainWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;

  &:not(:last-child) {
    margin-bottom: 1rem;
  }
`;

const Wrapper = styled(Flex)`
  align-items: center;
  width: 100%;
  justify-content: center;
`;

Wrapper.defaultProps = {
  mx: ['-0.5rem', '-1rem'],
};

export const Avatar = styled(Image)`
  border-radius: 50%;
  flex-shrink: 0;
  height: 2rem;
  object-fit: cover;
  width: 2rem;

  ${({ theme }) => theme.mq.sm`
  height: 3rem;
  width: 3rem;
  `}
`;

const Username = styled(Typography.Paragraph)`
  color: ${({ theme }) => theme.colors.switchingText} !important;
  font-size: ${({ theme }) => theme.font.size.tiny};
`;

const CommentText = styled(Typography.Text)`
  color: ${({ theme }) => theme.colors.switchingText};
  font-size: ${({ theme }) => theme.font.size.tiny};
  margin-bottom: 0.2rem;
  opacity: 0.8;
`;

const CommentInfo = styled(Typography.Text)`
  color: ${({ theme }) => theme.colors.switchingText};
  font-size: ${({ theme }) => theme.font.size.tiniest};
  opacity: 0.6;
  margin-right: 1rem;
`;

const CommentAction = styled(Box)`
  align-items: center;
  cursor: pointer;
  display: flex;

  span {
    color: grey;
    font-size: ${({ theme }) => theme.font.size.tiniest};
  }

  svg + span,
  span + svg {
    margin-left: 0.5rem;
  }

  &:not(:last-child) {
    margin-right: 1rem;
  }
`;

const CommentActions = styled(Box)`
  display: flex;
`;

export const CommentButton = styled(Button)`
  padding: 0.5rem;
  background-color: transparent;
  position: absolute;
  right: 4px;
  top: -35px;
  font-size: ${props => props.theme.font.size.normal};
  ${({ theme }) => theme.mq.sm`
      padding: 0.75rem 1rem;
      top: -39px;
      font-size: ${props => props.theme.font.size.small};
`};
`;

export const ReplyPrompt = styled(Box)`
  cursor: pointer;
  display: inline-flex;
  padding-left: 2.5rem;
  position: relative;
  &:before {
    background-color: ${({ theme }) => theme.colors['brown-grey-two']};
    content: '';
    height: 2px;
    left: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50) %;
    width: 2rem;
  }
`;

export const Comment = memo(({ comment, onDislike, onLike, onReply, parent, rootComment }) => {
  const {
    comment: commentText,
    created = 0,
    // disliked,
    // dislikes_count,
    id,
    liked,
    likes_count,
    owner,
    replies,
    replyCount,
  } = comment;
  const { avatar, firstName = '', lastName = '' } = owner;
  const name = `${firstName} ${lastName}`;

  const [more, setMore] = useState(false);
  const [error, setError] = useState('');
  const [text, setText] = useState('');
  const [showReply, setShowReply] = useState(false);
  const [showReplies, setShowReplies] = useState(false);
  const user = useSelector(state => state.profile.user);
  const liking = useSelector(state => state.comment.liking[id] || false);
  // const replying = useSelector(state => state.comment.replying[id] || false);
  const toggleMore = () => setMore(v => !v);
  const toggleReply = () => setShowReply(v => !v);
  const toggleReplies = () => setShowReplies(v => !v);

  const onSubmit = event => {
    event.preventDefault();
    setError('');
    if (!text) {
      setError('Please type a comment');
    } else {
      onReply(id, text, (success, e) => {
        if (success) {
          setText('');
          setShowReply(false);
        } else {
          setError(e?.message || 'An error occured');
        }
      });
    }
  };

  return (
    <MainWrapper>
      <Wrapper>
        <Box px={['0.5rem', '1rem']}>
          <Avatar src={avatar} />
        </Box>

        <Box px={['0.5rem', '1rem']} display="flex" flexDirection="column" pt="0.5rem" flex="1">
          <Username style={{ color: 'white', fontWeight: 'bold' }}>{name}</Username>

          <CommentText>
            {more ? commentText : ellipsize(commentText, 90)}
            {commentText.length > 90 ? (
              <Typography.Text onClick={toggleMore} color="primary" size="tiny" style={{ cursor: 'pointer' }}>
                See {more ? 'less' : 'more'}
              </Typography.Text>
            ) : null}
          </CommentText>

          <CommentActions>
            <CommentInfo>{created && distanceInWordsToNow(created)}</CommentInfo>
            <CommentAction>
              <Typography.Text>{likes_count}likes</Typography.Text>
            </CommentAction>
            {!rootComment && (
              <CommentAction onClick={toggleReply}>
                <Typography.Text color="primary">Reply</Typography.Text>
              </CommentAction>
            )}
          </CommentActions>
        </Box>

        <Box ml="auto" px={['0.5rem', '1rem']} pt="1rem">
          <LikeIcon
            onClick={() => !liking && onLike(id, rootComment)}
            fill={liked === true ? 'url(#paint0_linear)' : 'transparent'}
            style={{ cursor: 'pointer' }}
          />
        </Box>
      </Wrapper>

      <Box display="flex" pl={['4rem', '7rem']} width={1} pt="1rem" flexDirection="column">
        {showReply && (
          <Flex alignItems="flex-start" mx={['-0.5rem', '-1rem']} width={1} mb="1rem">
            <Box mx={['0.5rem', '1rem']} flexShrink="0">
              <Avatar src={user.avatar} alt="Me" />
            </Box>
            <Box
              flex="1"
              mx={['0.5rem', '1rem']}
              display="flex"
              flexDirection="column"
              style={{ position: 'relative' }}
            >
              <Form.Input
                placeholder="Write your comment..."
                value={text}
                onChange={e => setText(e.target.value)}
                style={{
                  borderRadius: '100px',
                  resize: 'none',
                  border: '2px solid #fff',
                  opacity: '0.7',
                  padding: '0.5rem 11rem 0.5rem 2rem',
                }}
              />
              {error && (
                <Typography.Text color="error" size="tiniest">
                  {error}
                </Typography.Text>
              )}
              <Box mx={['0.5rem', '1rem']} style={{ position: 'relative' }}>
                <CommentButton
                  onClick={onSubmit}
                  size="small"
                  fontSize="tiny"
                  style={{ color: '#fdb913' }}
                  aria-label="comment-button"
                >
                  Comment
                </CommentButton>
              </Box>
            </Box>
          </Flex>
        )}
        {replyCount ? (
          <>
            <ReplyPrompt onClick={toggleReplies} mb={showReplies ? '1rem' : '0'}>
              <Typography.Paragraph size="tiny" color="brown-grey-two">
                {showReplies ? 'Hide' : 'Show'} Replies ({replyCount})
              </Typography.Paragraph>
            </ReplyPrompt>
            {showReplies &&
              replies?.map(reply => (
                <Comment
                  comment={reply}
                  key={reply.id}
                  onLike={onLike}
                  onReply={onReply}
                  parent={parent}
                  rootComment={id}
                />
              ))}
          </>
        ) : null}
      </Box>
    </MainWrapper>
  );
});

Comment.propTypes = {
  comment: PropTypes.shape({
    comment: PropTypes.string,
    created: PropTypes.number,
    disliked: PropTypes.bool,
    dislikes_count: PropTypes.number,
    id: PropTypes.string,
    liked: PropTypes.bool,
    likes_count: PropTypes.number,
    owner: PropTypes.shape({
      avatar: PropTypes.string,
      firstName: PropTypes.string,
      id: PropTypes.string,
      lastName: PropTypes.string,
    }),
    replies: PropTypes.array.isRequired,
    replyCount: PropTypes.number.isRequired,
  }),
  onDislike: PropTypes.func.isRequired,
  onLike: PropTypes.func.isRequired,
  onReply: PropTypes.func.isRequired,
  parent: PropTypes.shape({
    id: PropTypes.string,
    target: PropTypes.string,
  }).isRequired,
  rootComment: PropTypes.string,
};

Comment.defaultProps = {
  comment: {},
  rootComment: '',
};
