export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE';

export const CLAIM_REWARD_REQUEST = 'CLAIM_REWARD_REQUEST';
export const CLAIM_REWARD_SUCCESS = 'CLAIM_REWARD_SUCCESS';
export const CLAIM_REWARD_FAILURE = 'CLAIM_REWARD_FAILURE';

export const GET_PROFILE_REQUEST = 'GET_PROFILE_REQUEST';
export const GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS';
export const GET_PROFILE_FAILURE = 'GET_PROFILE_FAILURE';

export const GET_LISTENING_ACTIVITY_REQUEST = 'GET_LISTENING_ACTIVITY_REQUEST';
export const GET_LISTENING_ACTIVITY_SUCCESS = 'GET_LISTENING_ACTIVITY_SUCCESS';
export const GET_LISTENING_ACTIVITY_FAILURE = 'GET_LISTENING_ACTIVITY_FAILURE';

export const UPDATE_PROFILE_REQUEST = 'UPDATE_PROFILE_REQUEST';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_FAILURE = 'UPDATE_PROFILE_FAILURE';

export const UPDATE_USER_AVATAR_REQUEST = 'UPDATE_USER_AVATAR_REQUEST';
export const UPDATE_USER_AVATAR_SUCCESS = 'UPDATE_USER_AVATAR_SUCCESS';
export const UPDATE_USER_AVATAR_FAILURE = 'UPDATE_USER_AVATAR_FAILURE';

export const SET_FCM_TOKEN_REQUEST = 'SET_FCM_TOKEN_REQUEST';
export const SET_FCM_TOKEN_SUCCESS = 'SET_FCM_TOKEN_SUCCESS';
export const SET_FCM_TOKEN_FAILURE = 'SET_FCM_TOKEN_FAILURE';

export const SUBSCRIBE_TO_TOPIC_REQUEST = 'SUBSCRIBE_TO_TOPIC_REQUEST';
export const SUBSCRIBE_TO_TOPIC_SUCCESS = 'SUBSCRIBE_TO_TOPIC_SUCCESS';
export const SUBSCRIBE_TO_TOPIC_FAILURE = 'SUBSCRIBE_TO_TOPIC_FAILURE';
