import { Image } from 'components/atoms';
import styled from '@emotion/styled';

export const Wrapper = styled.section``;
export const CommentsBox = styled.div`
  width: 100%;
  height: 80vh;
  padding: 2rem;
  margin-top: 5rem;
  overflow-y: auto;
  overflow-x: hidden;
  scroll-behavior: smooth;
  &::-webkit-scrollbar {
    display: none;
  }
`;
export const Comment = styled.div`
  display: grid;
  grid-template-columns: 1fr 11fr;
  margin-bottom: 1rem;
`;
export const CommentContent = styled.div``;
export const Username = styled.p`
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
`;

export const UserComment = styled.p`
  font-size: 14px;
  font-weight: 700;
  font-style: normal;
  word-break: break-all;
`;
export const Avatar = styled(Image)`
  width: 25px;
  display: block;
  object-fit: cover;
  height: 25px;
  margin-right: 1.25rem;
  border-radius: 50%;
`;
export const CommentForm = styled.form`
  border: 1px solid #fff;
  border-radius: 50px;
  position: absolute;
  bottom: 1rem;
  left: 2rem;
  right: 2rem;
  background-color: rgba(39, 39, 41, 0.8);
`;
export const CommentInput = styled.input`
  border: none;
  outline: none;
  background-color: transparent;
  width: 100%;
  color: white;
  padding: 2rem 2.5rem;
  &::placeholder {
    color: white;
  }
`;
export const CommentLoader = styled.p`
  position: absolute;
  top: 30%;
  right: 2%;
`;
