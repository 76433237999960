import { formatDuration } from './utils';

export const trackID = (currentItem, ix = 0) => {
  return `${currentItem.id}_${ix}`;
};

export const trackToPlayer = (currentItem, ix = 0) => {
  const item = {
    ...currentItem,
    queueId: trackID(currentItem, ix),
    title: currentItem.name,
    artwork: currentItem.artwork,
    genre: currentItem.genre_name,
    duration: formatDuration(currentItem.duration),
    index: ix,
  };
  return item;
};
