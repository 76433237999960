import {
  DeletePlaylistIcon,
  EditIcon,
  ManageTracksIcon,
  RemoveFromPlaylistIcon,
  FlagIcon,
  BinIcon,
  DownloadIcon,
  PlayNext2,
  AddToQueue,
  PlaylistIcon3,
  UserIcon,
  SendIcon,
  AlbumIcon2,
  LyricsIcon,
} from 'components/icons';

export const trackOptions = (connected, isOwner) => {
  const options = [
    // {
    //   code: 'connect',
    //   text: connected ? 'Unlike' : 'Like',
    //   icon: WhiteLikeIcon,
    // },
    {
      code: 'add-queue-next',
      text: 'Play next',
      icon: PlayNext2,
    },
    {
      code: 'add-queue-last',
      text: 'Add to queue',
      icon: AddToQueue,
    },

    {
      code: 'go-album',
      text: 'Go to album',
      icon: AlbumIcon2,
    },
    {
      code: 'add-playlist',
      text: 'Add to playlist',
      icon: PlaylistIcon3,
    },

    {
      code: 'go-artist',
      text: 'Go to artist',
      icon: UserIcon,
    },
    {
      code: 'share',
      text: 'Share',
      icon: SendIcon,
    },
  ];

  if (isOwner)
    options.push({
      code: 'edit-track',
      text: 'Edit Track',
      icon: EditIcon,
    });
  return options;
};

export const queueOptions = () => {
  const options = [
    {
      code: 'q-go-album',
      text: 'Go to album',
      icon: AlbumIcon2,
    },
    {
      code: 'q-add-playlist',
      text: 'Add to playlist',
      icon: PlaylistIcon3,
    },

    {
      code: 'q-go-artist',
      text: 'Go to artist',
      icon: UserIcon,
    },
    {
      code: 'q-share',
      text: 'Share',
      icon: SendIcon,
    },
  ];
  return options;
};

export const albumOptions = (connected, isOwner) => {
  const options = [
    {
      code: 'add-queue-next',
      text: 'Play Next',
      icon: PlayNext2,
    },
    {
      code: 'add-queue-last',
      text: 'Add to Queue',
      icon: AddToQueue,
    },
    // {
    //   code: 'connect',
    //   text: connected ? 'Remove from Favorites' : 'Add to Favorites',
    //   icon: WhiteLikeIcon,
    // },
    {
      code: 'add-playlist',
      text: 'Add to Playlist',
      icon: PlaylistIcon3,
    },
    {
      code: 'go-artist',
      text: 'Go to Artist',
      icon: UserIcon,
    },
    {
      code: 'share',
      text: 'Share',
      icon: SendIcon,
    },
  ];
  if (isOwner)
    options.push({
      code: 'edit-album',
      text: 'Edit Album',
      icon: EditIcon,
    });
  return options;
};

export const playlistOptions = connected => [
  {
    code: 'add-queue-next',
    text: 'Play Next',
    icon: PlayNext2,
  },
  {
    code: 'add-queue-last',
    text: 'Add to Queue',
    icon: AddToQueue,
  },
  // {
  //   code: 'connect',
  //   text: connected ? 'Remove from Favorites' : 'Add to Favorites',
  //   icon: WhiteLikeIcon,
  // },
  {
    code: 'add-playlist',
    text: 'Add to Playlist',
    icon: PlaylistIcon3,
  },
  {
    code: 'share',
    text: 'Share',
    icon: SendIcon,
  },
];

export const userPlaylistOptions = [
  {
    code: 'add-queue-next',
    text: 'Play Next',
    icon: PlayNext2,
  },
  {
    code: 'add-queue-last',
    text: 'Add to Queue',
    icon: AddToQueue,
  },
  {
    code: 'manage-tracks',
    text: 'Manage Tracks',
    icon: ManageTracksIcon,
  },
  {
    code: 'edit-playlist',
    text: 'Edit Playlist',
    icon: EditIcon,
  },
  {
    code: 'delete-playlist',
    text: 'Delete Playlist',
    icon: DeletePlaylistIcon,
  },
  {
    code: 'share',
    text: 'Share',
    icon: SendIcon,
  },
];

export const trackUploadOptions = [
  {
    code: 'add-queue-next',
    text: 'Play Next',
    icon: PlayNext2,
  },
  {
    code: 'add-queue-last',
    text: 'Add to Queue',
    icon: AddToQueue,
  },
  {
    code: 'edit-track',
    text: 'Edit Track',
    icon: EditIcon,
  },
  {
    code: 'share',
    text: 'Share',
    icon: SendIcon,
  },
];

export const queueTrackOptions = [
  {
    code: 'remove-queue',
    text: 'Remove from Queue',
    icon: RemoveFromPlaylistIcon,
  },
  {
    code: 'go-album',
    text: 'Go to album',
    icon: AlbumIcon2,
  },
  {
    code: 'go-artist',
    text: 'Go to Artist',
    icon: UserIcon,
  },
  {
    code: 'add-playlist',
    text: 'Add to Playlist',
    icon: PlaylistIcon3,
  },
  {
    code: 'track-info',
    text: 'Download',
    icon: DownloadIcon,
  },
  {
    code: 'share',
    text: 'Share',
    icon: SendIcon,
  },
];

export const commentOptions = isOwner => {
  const options = [
    {
      code: 'report-comment',
      text: 'Report Comment',
      icon: FlagIcon,
    },
  ];

  if (isOwner)
    options.push({
      code: 'delete-comment',
      text: 'Delete Comment',
      icon: BinIcon,
    });
  return options;
};

export const sortOptions = [
  {
    code: 'by-title',
    text: 'Title',
    icon: LyricsIcon,
  },
  {
    code: 'by-artist',
    text: 'Artist',
    icon: UserIcon,
  },
  // {
  //   code: 'by-recent',
  //   text: 'Recently Added',
  //   icon: RecentIcon,
  // },
];
