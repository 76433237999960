import styled from '@emotion/styled/macro';
import { css } from '@emotion/core';

export const Label = styled.label`
  color: ${({ theme }) => theme.colors.switchingText};
  display: inline-block;
  font-size: ${props => props.theme.font.size.small};
  font-weight: bold;
  margin-bottom: 1rem;

  ${({ required, theme }) =>
    required &&
    css`
      &:after {
        content: '*';
        color: ${theme.colors.secondary};
      }
    `}
`;

Label.defaultProps = {
  color: 'switchingText',
};
