import React from 'react';
import PropTypes from 'prop-types';

function SvgSearch({ fill, ...props }) {
  return (
    <svg width={19.466} height={19.465} viewBox="0 0 19.466 19.465" {...props}>
      <path
        data-name="Path 1"
        d="M13.912 12.242h-.879l-.312-.3a7.245 7.245 0 10-.779.779l.3.312v.879l5.565 5.554 1.658-1.658zm-6.678 0a5.008 5.008 0 115.008-5.008 5 5 0 01-5.008 5.008z"
        fill={fill}
      />
    </svg>
  );
}

SvgSearch.defaultProps = {
  fill: '#fff',
};

SvgSearch.propTypes = {
  fill: PropTypes.string,
};

export default SvgSearch;
