import styled from '@emotion/styled/macro';
import { css } from '@emotion/core';
import { Typography } from 'components/atoms';

export const Container = styled.div`
  padding: 2rem 1rem 0rem 1rem;
  pointer-events: none;
  position: fixed;
  right: 0;
  top: 8px;
  z-index: 2000;
 }

  ${({ active }) =>
    active &&
    css`
      padding: 1rem;
      pointer-events: auto;
    `}
`;

export const ItemWrapper = styled.div`
  align-items: center;
  background-color: ${({ theme, color }) => theme.colors[color]};
  border-radius: 0.5rem;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 15px 0 rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  padding: 1rem;

  svg {
    flex-shrink: 0;
    cursor: pointer;
  }
`;

export const ItemText = styled(Typography.Text)`
  font-size: ${({ theme }) => theme.font.size.small};
  line-height: 1.2;
  margin-right: 1rem;
  font-weight: 500;
  &,
  &:visited {
    color: ${props => props.colorShade};
  }
`;
