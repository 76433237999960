import React from 'react';
import PropTypes from 'prop-types';

function SvgBin({ fill, ...props }) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 122.447 146.936" {...props}>
      <g data-name="Group 1536">
        <g data-name="Group 1535">
          <path
            data-name="Path 1050"
            d="M42.856 18.367H30.611A18.039 18.039 0 0148.978 0v12.245a5.784 5.784 0 00-6.122 6.122z"
            fill={fill}
          />
        </g>
      </g>
      <g data-name="Group 1538">
        <g data-name="Group 1537">
          <path
            data-name="Path 1051"
            d="M91.835 18.367H79.59a5.784 5.784 0 00-6.122-6.122V0a18.039 18.039 0 0118.367 18.367z"
            fill={fill}
          />
        </g>
      </g>
      <g data-name="Group 1540">
        <g data-name="Group 1539">
          <path data-name="Path 1052" d="M91.834 36.734H30.611V18.367h12.245v6.122H79.59v-6.122h12.244z" fill={fill} />
        </g>
      </g>
      <g data-name="Group 1542">
        <g data-name="Group 1541">
          <path data-name="Rectangle 441" fill={fill} d="M48.978 0h24.489v12.245H48.978z" />
        </g>
      </g>
      <g data-name="Group 1544">
        <g data-name="Group 1543">
          <path
            data-name="Path 1053"
            d="M116.324 36.734H6.121a5.784 5.784 0 01-6.122-6.123 5.784 5.784 0 016.122-6.122h110.2a5.784 5.784 0 016.122 6.122 5.784 5.784 0 01-6.119 6.123z"
            fill={fill}
          />
        </g>
      </g>
      <g data-name="Group 1546">
        <g data-name="Group 1545">
          <path
            data-name="Path 1054"
            d="M104.079 146.937H18.366a5.784 5.784 0 01-6.122-6.123V55.101a5.784 5.784 0 016.122-6.122h85.713a5.784 5.784 0 016.122 6.122v85.713a5.784 5.784 0 01-6.122 6.123z"
            fill={fill}
          />
        </g>
      </g>
    </svg>
  );
}

SvgBin.propTypes = {
  fill: PropTypes.string,
};

SvgBin.defaultProps = {
  fill: '#fff',
};

export default SvgBin;
