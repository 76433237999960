/* eslint-disable import/no-dynamic-require */
/* eslint-disable global-require */
export const webpSupported = () => {
  const webp = JSON.parse(localStorage.getItem('webp'));
  if (webp !== null) {
    return webp;
  }
  return false;
};

export const backgrounds = {
  footer: require(`assets/img/banner-bg.${webpSupported() ? 'webp' : 'jpg'}`),
};

export const banners = {
  promo: require(`assets/img/promo-banner.${webpSupported() ? 'webp' : 'jpg'}`),
};

export const posters = {
  footer: require(`assets/img/footer-banner.${webpSupported() ? 'webp' : 'png'}`),
};

export const buttonImages = {
  apple: require(`assets/img/apple-btn.${webpSupported() ? 'webp' : 'png'}`),
  google: require(`assets/img/google-btn.${webpSupported() ? 'webp' : 'jpg'}`),
  whatsapp: require(`assets/img/whatsapp-btn.${webpSupported() ? 'webp' : 'png'}`),
};

export const placeholders = {
  album: require(`assets/img/placeholders/album.${webpSupported() ? 'webp' : 'jpg'}`),
  artist: require(`assets/img/placeholders/artist.${webpSupported() ? 'webp' : 'jpg'}`),
  event: require(`assets/img/placeholders/event.${webpSupported() ? 'webp' : 'jpg'}`),
  mood: require(`assets/img/placeholders/mood.${webpSupported() ? 'webp' : 'jpg'}`),
  none: '',
  playlist: require(`assets/img/placeholders/playlist.${webpSupported() ? 'webp' : 'jpg'}`),
  track: require(`assets/img/placeholders/track.${webpSupported() ? 'webp' : 'jpg'}`),
  video: require(`assets/img/placeholders/video.${webpSupported() ? 'webp' : 'jpg'}`),
};
