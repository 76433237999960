import PropTypes from 'prop-types';
import React from 'react';
import styled from '@emotion/styled/macro';

const Path = styled.path`
  &:hover {
    fill: #fdb913;
  }
`;

const SvgLike = ({ fill, ...props }) => {
  return (
    <svg width="1em" viewBox="0 0 25 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <mask id="path-1-inside-1" fill="white">
        <path d="M22.7295 1.77243C20.3689 -0.590811 16.5416 -0.590811 14.181 1.77243L12.5 3.45531L10.819 1.77243C8.45838 -0.590809 4.63107 -0.59081 2.27046 1.77243C-0.0901535 4.13568 -0.0901532 7.96725 2.27046 10.3305L11.7647 20.6308C12.1607 21.0605 12.8393 21.0605 13.2353 20.6308L22.7295 10.3305C25.0902 7.96725 25.0902 4.13568 22.7295 1.77243Z" />
      </mask>
      <path
        d="M14.181 1.77243L14.8885 2.47915V2.47915L14.181 1.77243ZM22.7295 1.77243L23.437 1.06572V1.06572L22.7295 1.77243ZM22.7295 10.3305L22.022 9.62378C22.0126 9.63325 22.0033 9.64291 21.9942 9.65275L22.7295 10.3305ZM13.2353 20.6308L13.9706 21.3086H13.9706L13.2353 20.6308ZM2.27046 1.77243L1.56296 1.06572H1.56296L2.27046 1.77243ZM2.27046 10.3305L3.00575 9.65275C2.99668 9.64291 2.98742 9.63325 2.97796 9.62378L2.27046 10.3305ZM11.7647 20.6308L11.0294 21.3086H11.0294L11.7647 20.6308ZM12.5 3.45531L11.7925 4.16202C11.9801 4.3498 12.2346 4.45531 12.5 4.45531C12.7654 4.45531 13.0199 4.3498 13.2075 4.16202L12.5 3.45531ZM10.819 1.77243L10.1115 2.47915L10.1115 2.47915L10.819 1.77243ZM14.8885 2.47915C16.8585 0.506952 20.052 0.506952 22.022 2.47915L23.437 1.06572C20.6858 -1.68857 16.2247 -1.68857 13.4735 1.06572L14.8885 2.47915ZM22.022 2.47915C23.9927 4.45196 23.9927 7.65097 22.022 9.62378L23.437 11.0372C26.1877 8.28353 26.1877 3.8194 23.437 1.06572L22.022 2.47915ZM21.9942 9.65275L12.5 19.9531L13.9706 21.3086L23.4648 11.0082L21.9942 9.65275ZM1.56296 1.06572C-1.18765 3.8194 -1.18765 8.28353 1.56296 11.0372L2.97796 9.62378C1.00735 7.65097 1.00735 4.45196 2.97796 2.47915L1.56296 1.06572ZM1.53517 11.0082L11.0294 21.3086L12.5 19.9531L3.00575 9.65275L1.53517 11.0082ZM13.4735 1.06572L11.7925 2.74859L13.2075 4.16202L14.8885 2.47915L13.4735 1.06572ZM11.5265 1.06572C8.77527 -1.68857 4.31419 -1.68857 1.56296 1.06572L2.97796 2.47915C4.94796 0.506953 8.1415 0.506953 10.1115 2.47915L11.5265 1.06572ZM13.2075 2.74859L11.5265 1.06572L10.1115 2.47915L11.7925 4.16202L13.2075 2.74859ZM12.5 19.9531L11.0294 21.3086C11.8215 22.1679 13.1785 22.1679 13.9706 21.3086L12.5 19.9531Z"
        fill="url(#paint0_linear)"
        mask="url(#path-1-inside-1)"
      />
      <defs>
        <linearGradient id="paint0_linear" x1="12.5" y1="0" x2="12.5" y2="21.4286" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FDB913" />
          <stop offset="1" stopColor="#F2994A" />
        </linearGradient>
      </defs>
      <Path
        d="M22.2295 1.77255C19.8689 -0.59085 16.0416 -0.59085 13.681 1.77255L12.7075 2.74718C12.3169 3.13826 11.6831 3.13826 11.2925 2.74718L10.319 1.77255C7.95838 -0.590848 4.13107 -0.590849 1.77046 1.77255C-0.590153 4.13595 -0.590153 7.96778 1.77046 10.3312L11.2647 20.6322C11.6607 21.0619 12.3393 21.0619 12.7353 20.6322L22.2295 10.3312C24.5902 7.96778 24.5902 4.13595 22.2295 1.77255Z"
        fill={fill}
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="24"
          y1="10.8798"
          x2="5.94016e-06"
          y2="10.8689"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDB913" />
          <stop offset="1" stopColor="#F2994A" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default SvgLike;

export function SvgLike1({ fill, ...props }) {
  return (
    <svg width="25" height="21" viewBox="0 0 25 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="path-1-inside-1" fill="white">
        <path d="M22.7295 1.77243C20.3689 -0.590811 16.5416 -0.590811 14.181 1.77243L12.5 3.45531L10.819 1.77243C8.45838 -0.590809 4.63107 -0.59081 2.27046 1.77243C-0.0901535 4.13568 -0.0901532 7.96725 2.27046 10.3305L11.7647 20.6308C12.1607 21.0605 12.8393 21.0605 13.2353 20.6308L22.7295 10.3305C25.0902 7.96725 25.0902 4.13568 22.7295 1.77243Z" />
      </mask>
      <path
        d="M14.181 1.77243L14.8885 2.47915V2.47915L14.181 1.77243ZM22.7295 1.77243L23.437 1.06572V1.06572L22.7295 1.77243ZM22.7295 10.3305L22.022 9.62378C22.0126 9.63325 22.0033 9.64291 21.9942 9.65275L22.7295 10.3305ZM13.2353 20.6308L13.9706 21.3086H13.9706L13.2353 20.6308ZM2.27046 1.77243L1.56296 1.06572H1.56296L2.27046 1.77243ZM2.27046 10.3305L3.00575 9.65275C2.99668 9.64291 2.98742 9.63325 2.97796 9.62378L2.27046 10.3305ZM11.7647 20.6308L11.0294 21.3086H11.0294L11.7647 20.6308ZM12.5 3.45531L11.7925 4.16202C11.9801 4.3498 12.2346 4.45531 12.5 4.45531C12.7654 4.45531 13.0199 4.3498 13.2075 4.16202L12.5 3.45531ZM10.819 1.77243L10.1115 2.47915L10.1115 2.47915L10.819 1.77243ZM14.8885 2.47915C16.8585 0.506952 20.052 0.506952 22.022 2.47915L23.437 1.06572C20.6858 -1.68857 16.2247 -1.68857 13.4735 1.06572L14.8885 2.47915ZM22.022 2.47915C23.9927 4.45196 23.9927 7.65097 22.022 9.62378L23.437 11.0372C26.1877 8.28353 26.1877 3.8194 23.437 1.06572L22.022 2.47915ZM21.9942 9.65275L12.5 19.9531L13.9706 21.3086L23.4648 11.0082L21.9942 9.65275ZM1.56296 1.06572C-1.18765 3.8194 -1.18765 8.28353 1.56296 11.0372L2.97796 9.62378C1.00735 7.65097 1.00735 4.45196 2.97796 2.47915L1.56296 1.06572ZM1.53517 11.0082L11.0294 21.3086L12.5 19.9531L3.00575 9.65275L1.53517 11.0082ZM13.4735 1.06572L11.7925 2.74859L13.2075 4.16202L14.8885 2.47915L13.4735 1.06572ZM11.5265 1.06572C8.77527 -1.68857 4.31419 -1.68857 1.56296 1.06572L2.97796 2.47915C4.94796 0.506953 8.1415 0.506953 10.1115 2.47915L11.5265 1.06572ZM13.2075 2.74859L11.5265 1.06572L10.1115 2.47915L11.7925 4.16202L13.2075 2.74859ZM12.5 19.9531L11.0294 21.3086C11.8215 22.1679 13.1785 22.1679 13.9706 21.3086L12.5 19.9531Z"
        fill="#fff"
        mask="url(#path-1-inside-1)"
      />
      <defs>
        <linearGradient id="paint0_linear" x1="12.5" y1="0" x2="12.5" y2="21.4286" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FDB913" />
          <stop offset="1" stopColor="#F2994A" />
        </linearGradient>
      </defs>
    </svg>
  );
}

SvgLike1.propTypes = {
  fill: PropTypes.string,
};

SvgLike1.defaultProps = {
  fill: '#fff',
};
