import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled/macro';
import { Modal } from 'components/molecules';
import { Typography } from 'components/atoms';
import { getMobileOperatingSystem } from 'lib/utils';
import Apple from '../../assets/img/apple.svg';
import Google from '../../assets/img/google.svg';

const Badges = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Store = styled.img`
  width: 95%;
  margin: 0 1rem;
  cursor: pointer;
  @media (max-width: ${props => props.theme.breakpoints[1]}) {
    width: 80%;
  }
`;

const Appstore = styled.a``;
const Playstore = styled.a``;

const GetAppModal = memo(({ close, isOpen, toggle }) => {
  const os = getMobileOperatingSystem();

  const showAndroid = os === 'android' || os === 'unknown';
  const showiOS = os === 'ios' || os === 'unknown';

  return (
    <Modal isOpen={isOpen} close={close} toggle={toggle} size="sm" showCloseIcon>
      <Modal.Header style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Typography.Heading
          type="h3"
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontWeight: '500' }}
        >
          Get the app
        </Typography.Heading>
      </Modal.Header>
      <Modal.Body>
        <Typography.Paragraph color="switchingText" size="tiny" style={{ marginBottom: '2rem' }}>
          Loving the web? We have better vibes on the app.
        </Typography.Paragraph>

        <Badges>
          {showAndroid && (
            <Playstore href="https://play.google.com/store/apps/details?id=ng.groove.udux">
              <Store src={Google}></Store>
            </Playstore>
          )}
          {showiOS && (
            <Appstore href="https://apps.apple.com/app/udux/id1372897101">
              <Store src={Apple}></Store>
            </Appstore>
          )}
        </Badges>
      </Modal.Body>
    </Modal>
  );
});

GetAppModal.propTypes = {
  close: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
};

export default GetAppModal;
