import PropTypes from 'prop-types';
import React from 'react';

function SvgAlbum({ fill, ...props }) {
  return (
    <svg width="2.8em" height="2.8em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="12" cy="12" r="11" stroke={fill} />
      <circle cx="12" cy="12.0004" r="2.53846" stroke={fill} />
      <path d="M5.23077 11.9997C5.23077 8.26116 8.26146 5.23047 12 5.23047" stroke={fill} strokeLinecap="round" />
    </svg>
  );
}

SvgAlbum.propTypes = {
  fill: PropTypes.string,
};

SvgAlbum.defaultProps = {
  fill: '#FCB316',
};

export default SvgAlbum;
