import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { buyEventAction } from 'redux/actions';
import MakePaymentModal from 'components/organisms/MakePaymentModal';
import { getProfileAction } from 'redux/actions';
import { getBillItems, fundWallet } from 'redux/actions';

export const WalletContext = React.createContext({});

export const WalletProvider = ({ children }) => {
  const user = useSelector(state => state.profile.user);
  const [openModal, toggleModalOpen] = useState(false);
  const [paymentLink, setPaymentLink] = useState(null);
  const dispatch = useDispatch();
  const getProfile = () => dispatch(getProfileAction());
  const fetchPaymentLink = (product, cb) => fundWallet(product, cb);
  const sprayMoneyHandler = (id, price, itemType, comment, cb) =>
    dispatch(buyEventAction(id, price, itemType, comment, cb));
  const getBalance = () => {
    if (user) {
      return user?.wallet?.balance || 0;
    } else {
      return 0;
    }
  };
  const updateBalanceStorage = addValue => {
    const balance = WalletModel.balance + addValue;
    user.wallet.balance = balance;
    return balance;
  };
  const $balance = getBalance();
  const [WalletModel, SetWalletModel] = useState({
    maxValue: $balance > 1000 ? $balance : 2000,
    balance: $balance,
    currency: '₦',
    amount: ($balance > 1000 ? $balance : 2000) / 2,
    amountFunded: 0,
    priceAmount: 2,
    activePriceItem: {
      key: 1,
      amount: 100,
      price: 2,
    },
    paymentMethod: {
      name: 'Udux Account',
      value: 'Udux Wallet - ₦' + getBalance(),
    },
  });
  const getWalletBalance = () => {
    return getBalance();
  };
  const updateBalance = addValue => {
    const balance = updateBalanceStorage(addValue);

    SetWalletModel({
      ...WalletModel,
      balance,
    });
  };

  const updatePaymentMethod = method => {
    SetWalletModel({
      ...WalletModel,
      paymentMethod: { ...WalletModel.paymentMethod, ...method },
    });
  };

  const updateAmountFunded = pricing => {
    SetWalletModel({
      ...WalletModel,
      amountFunded: pricing.amount,
      priceAmount: pricing.price,
      activePriceItem: pricing,
    });
  };

  const getNewPaymentLink = (product, cb) => {
    fetchPaymentLink(product, (err, data) => {
      if (data) {
        cb(null, data?.authorization_url);
        return updatePaymentLink(data?.authorization_url);
      }
      console.log(err, data);
      cb(err, data);
    });
  };
  const setSprayMoneyStatus = status => {
    return SetWalletModel({
      ...WalletModel,
      sprayMoney: status,
      // balance,
    });
  };
  const spendTheMoney = (status, event, spentAmount, pinnedComment, callback = () => {}) => {
    // const balance = updateBalanceStorage(-spentAmount);

    // if (!event?.id || !status) {
    //   return
    //   // SetWalletModel({
    //   //   ...WalletModel,
    //   //   sprayMoney: false,
    //   //   // balance,
    //   // });
    // }
    sprayMoneyHandler(event, spentAmount, `make-it-rain`, pinnedComment, err => {
      if (err) {
        return callback(err);
      }
      callback();
      getProfile();
      // SetWalletModel({
      //   ...WalletModel,
      //   sprayMoney: status,
      //   // balance: balance,
      // });
    });
  };
  const openPaymentModal = $paymentLink => {
    setPaymentLink($paymentLink);
    toggleModalOpen(!openModal);
  };
  const updatePaymentLink = $paymentLink => {
    setPaymentLink($paymentLink);
  };

  const getAllBills = cb => {
    getBillItems(cb);
  };

  return (
    <WalletContext.Provider
      value={{
        WalletModel,
        paymentLink,
        updateBalance,
        updateAmountFunded,
        updatePaymentMethod,
        spendTheMoney,
        getWalletBalance,
        openPaymentModal,
        updatePaymentLink,
        getNewPaymentLink,
        getAllBills,
        setSprayMoneyStatus,
      }}
    >
      <MakePaymentModal target={paymentLink} close={openPaymentModal} isOpen={openModal} toggle={openPaymentModal} />
      {children}
    </WalletContext.Provider>
  );
};

WalletProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
