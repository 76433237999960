import * as t from '../types';

const INITIAL_STATE = {
  byTargetId: {},
  connectedTargetIds: {},
  data: {},
  fetching: {},
};
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case t.CONNECT_REQUEST:
      if (action.target) {
        const _data = {
          ...state,
          fetching: { ...state.fetching, [action.payload]: true },
          byTargetId: {
            ...state.byTargetId,
            [action.target]: {
              ...state.byTargetId[action.target],
              [action.payload]: {
                active:
                  state.byTargetId &&
                  state.byTargetId[action.target] &&
                  state.byTargetId[action.target][action.payload] &&
                  state.byTargetId[action.target][action.payload].active
                    ? false
                    : true,
              },
            },
          },
        };
        return _data;
        // return {
        //   ...state,
        //   fetching: { ...state.fetching, [action.payload]: true },
        //   ['byTargetId']: {
        //     ...state.byTargetId,
        //     [action.target]: {
        //       ...state.byTargetId[action.target],
        //       [action.payload]: {
        //         active:
        //           (state.byTargetId[action.target][action.payload] &&
        //             state.byTargetId[action.target][action.payload].active) === true
        //             ? false
        //             : true,
        //       },
        //     },
        //   },
        // };
      }
      return { ...state, fetching: { ...state.fetching, [action.payload]: true } };

    case t.CONNECT_SUCCESS:
      return { ...state, ...action.payload, fetching: { ...state.fetching, [action.payload]: false } };

    case t.CONNECT_FAILURE:
      return { ...state, fetching: { ...state.fetching, [action.payload]: false } };

    case t.GET_CONNECTIONS_REQUEST:
      return { ...state, fetching: { ...state.fetching, [action.payload]: true } };

    case t.GET_CONNECTIONS_SUCCESS:
      return { ...state, ...action.payload };

    case t.GET_CONNECTIONS_FAILURE:
      return { ...state, fetching: { ...state.fetching, [action.payload]: false } };

    case t.LOGOUT:
      return INITIAL_STATE;

    default:
      return { ...state };
  }
};
