import React from 'react';
import PropTypes from 'prop-types';

function SvgCommentIcon({ fill, ...props }) {
  return (
    <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M21.2949 4.90847L19.4167 6.78672L15.5486 2.91863L17.4233 1.0439C17.4238 1.04339 17.4243 1.04289 17.4248 1.04239C17.5607 0.910037 17.7428 0.835938 17.9325 0.835938C18.1222 0.835938 18.3044 0.910015 18.4402 1.04233C18.4407 1.04285 18.4412 1.04337 18.4418 1.0439L21.2949 3.89709C21.5684 4.1705 21.5684 4.63505 21.2949 4.90847ZM0.5 21.8353V17.9449L13.5247 4.92018L17.3934 8.78885L4.38992 21.8353H0.5Z"
        stroke="url(#paint0_linear)"
      />
      <defs>
        <linearGradient id="paint0_linear" x1="11" y1="0.335937" x2="11" y2="22.3353" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FDB913" />
          <stop offset="1" stopColor="#F2994A" />
        </linearGradient>
      </defs>
    </svg>
  );
}

SvgCommentIcon.propTypes = {
  fill: PropTypes.string,
};

SvgCommentIcon.defaultProps = {
  fill: '#ffa700',
};

export default SvgCommentIcon;
