import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'components/molecules';
import { Typography, Form, Button } from 'components/atoms';
import { textAppAction } from 'redux/actions';
import { useDispatch } from 'react-redux';

const phoneRegex = /^[+][0-9]{3,14}$/;

const TextModal = memo(({ close, isOpen, toggle }) => {
  const dispatch = useDispatch();
  const [phone, setPhone] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const textApp = (number, cb) => dispatch(textAppAction(number, cb));

  const sendSMS = event => {
    event.preventDefault();
    if (phone.length === 0 || !`${phone}`.match(phoneRegex)) {
      setError('Please enter a valid phone number');
      return;
    }
    setLoading(true);
    textApp(phone, (s, e) => {
      setLoading(false);
      if (e) {
        if (e && e.msg) {
          setError(e.msg);
        } else {
          setError('Your message was not sent');
        }
        return;
      }
      setError(`A link has been sent to ${phone}`);
      toggle();
    });
  };
  return (
    <Modal isOpen={isOpen} close={close} toggle={toggle} size="md" showCloseIcon>
      <Modal.Header>
        <Typography.Heading type="h3">Text Me</Typography.Heading>
      </Modal.Header>
      <Modal.Body>
        <Typography.Paragraph size="tiny" style={{ marginBottom: '1rem' }}>
          You will receive a one time SMS to download the app.If you are outside the US, make sure to include your
          country's international dialing code prefix
        </Typography.Paragraph>
        <Form>
          <Form.Group>
            <Form.Label>Phone Number</Form.Label>
            <Form.Input placeholder="+2348033993399" value={phone} onChange={v => setPhone(v.target.value)} />
          </Form.Group>

          <Form.Error error={error} />

          <Form.Group>
            <Button
              style={{ background: 'linear-gradient(270.02deg, #fdb913 0.02%, #f2994a 99.98%)' }}
              effect
              fontSize="tiny"
              onClick={sendSMS}
              loading={loading}
            >
              Submit
            </Button>
          </Form.Group>
        </Form>
      </Modal.Body>
    </Modal>
  );
});

TextModal.propTypes = {
  close: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
};

export default TextModal;
