import React from 'react';
import { format } from 'date-fns';
import styled from '@emotion/styled/macro';
import { css } from '@emotion/core';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Image, Typography } from 'components/atoms';
import { Box } from 'rebass';
import {
  // TwitterIcon,
  // WhatsappIcon,
  FavoriteIcon,
  // FacebookColoredIcon,
  HeadphonesIcon,
  // CommentIcon,
  PlayIcon,
  PauseIcon,
} from 'components/icons';
import { commarize, isObjEmpty } from 'lib/utils';
import { useSelector, useDispatch } from 'react-redux';
import { togglePlayAction, connectAction } from 'redux/actions';
import { MoreOptions } from 'components/organisms/MoreOptions';
import { Seekbar } from './Seekbar';

const Artwork = styled(Image)`
  border-radius: 0.5rem;
  box-shadow: 0 2px 30px rgba(0, 0, 0, 0.15);
  flex-shrink: 0;
  height: 12rem;
  margin-bottom: 2rem;
  width: 12rem;
`;

const Wrapper = styled(Box)`
  align-items: center;
  background-color: ${({ backgroundColor, theme }) => theme.colors[backgroundColor]};
  flex-direction: column;
  position: relative;

  ${({ theme }) => theme.mq.md`
    flex-direction: row;
    ${Artwork}{
      margin-right: 2rem;
      img{
        margin-right: 0;
      }
    }
  `}

  ${({ floating, theme }) =>
    floating &&
    css`
      border-radius: 0.5rem;
      padding: 1.5rem;
      ${Artwork} {
        ${theme.mq.md`
          margin-top: -3rem;
          img{
            margin-top: 0;
          }
        `}
      }
    `}
`;

const Details = styled(Box)`
  width: 100%;
  flex-direction: column;
`;

// const SocialButton = styled(Button)`
//   height: 2rem;
//   padding: 0;
//   width: 2rem;

//   svg {
//     height: 1rem;
//     width: 1rem;
//   }

//   &:not(:last-child) {
//     margin-right: 0.75rem;
//   }
// `;

const ArtistName = styled(Typography.Paragraph)`
  line-height: unset;
  margin-bottom: 0.2rem;
  opacity: 0.6;
  text-align: center;

  ${({ theme }) => theme.mq.md`
      text-align:left;
        `}
`;

const Name = styled(Typography.Paragraph)`
  line-height: unset;
  text-align: center;

  ${({ theme }) => theme.mq.md`
      text-align:left;
        `}
`;

const AlbumName = styled(Typography.Paragraph)`
  line-height: unset;
  opacity: 0.6;
  text-align: center;

  ${({ theme }) => theme.mq.md`
      text-align:left;
        `}
`;

// const CommentButton = styled(OutlineButton)`
//   border-radius: 0.3rem;
//   font-size: ${({ theme }) => theme.font.size.tiniest};
//   padding: 0.2rem 0.4rem;

//   &,
//   &:visited {
//     color: ${({ theme }) => theme.colors.white};
//   }

//   svg {
//     margin-right: 0.5rem;
//   }
// `;

const ListenCountText = styled(Typography.Text)`
  display: inline-flex;
  &:not(:last-child) {
    margin-right: 2rem;
  }

  svg {
    height: 1.6rem;
    margin-right: 0.5rem;
    width: 1.6rem;
  }
`;

const Icon = styled.svg`
  width: 3rem;
  height: 3rem;
  margin-right: 2rem;
  cursor: pointer;
`;

const MoreButton = styled(Box)`
  flex-direction: column;
  position: absolute;
  right: 1.5rem;
  top: 1.5rem;

  ${({ theme }) => theme.mq.sm`
    flex-direction: row;
    position: static;
    margin-left: 1rem;
  `}
`;

export const TrackItemPlayer = ({ backgroundColor, className, floating, item, moreType, onPlay, showAlbum }) => {
  const {
    // commentCount = 0,
    album_name = '',
    album,
    artist_name = '',
    artist,
    artwork = '',
    created = 0,
    duration,
    id,
    listenCount = 0,
    name = '',
    owner = '',
    owner_name = '',
  } = item;
  const dispatch = useDispatch();
  const currentItem = useSelector(state => state.player.currentItem);
  const progress = useSelector(state => state.player.progress);
  const togglePlay = () => dispatch(togglePlayAction());
  const connect = () => dispatch(connectAction(item, 'track'));
  const playing = useSelector(state => state.player.playing);
  const isCurrent = !isObjEmpty(currentItem) && id === currentItem.id;

  const isConnected = useSelector(
    state =>
      (state.connect.byTargetId.tracks &&
        state.connect.byTargetId.tracks[id] &&
        state.connect.byTargetId.tracks[id].active) ||
      false,
  );
  const connecting = useSelector(state => state.connect.fetching[id] || false);

  const renderIcon = () => {
    if (isCurrent) {
      return <Icon as={playing ? PauseIcon : PlayIcon} onClick={togglePlay} fill="#FFA700" />;
    }
    return <Icon as={PlayIcon} onClick={onPlay} fill="#FFA700" />;
  };

  return (
    <Wrapper className={className} backgroundColor={backgroundColor} floating={floating}>
      <Artwork src={artwork} placeholder="track" alt={name} />
      <Details>
        <Box alignItems="center" justifyContent="space-between" flexDirection={['column', 'row']}>
          <Box flexDirection="column" mb={['1rem', 0]} width="100%">
            <ArtistName
              as={Link}
              ellipsize
              size="tiny"
              to={owner ? `/users/${owner}` : `/artists/${artist}`}
              color="switchingText"
            >
              {artist_name}
            </ArtistName>
            <Name size="big" weight="medium" ellipsize color="switchingText">
              {name}
            </Name>
            {showAlbum && album && (
              <AlbumName as={Link} ellipsize size="tiny" to={`/albums/${album}`} color="switchingText">
                {album_name}
              </AlbumName>
            )}

            {(((owner && created) || listenCount) && (
              <Box alignItems="center" justifyContent="space-between">
                {owner && created && (
                  <Typography.Paragraph size="tiniest" color="switchingText">
                    Added on {format(created, 'MMM do, yyyy')}
                    {(owner_name && (
                      <>
                        <Typography.Text>{' by '}</Typography.Text>
                        <Typography.Text color="primary" as={Link} to={`/users/${owner}`}>
                          {owner_name}
                        </Typography.Text>
                      </>
                    )) ||
                      null}
                  </Typography.Paragraph>
                )}

                <Box alignItems="center">
                  {(listenCount && (
                    <ListenCountText size="tiniest" color="white">
                      <HeadphonesIcon />
                      {commarize(listenCount)}
                    </ListenCountText>
                  )) ||
                    null}

                  {/* {(commentCount && (
                <CommentButton size="small">
                  <CommentIcon />
                  {commarize(commentCount)}
                </CommentButton>
              )) ||
                null} */}
                </Box>
              </Box>
            )) ||
              null}
          </Box>
          <Box alignItems="center">
            <FavoriteIcon
              bounce={connecting}
              fill={isConnected ? '#FFA700' : '#555555'}
              height="2.5rem"
              onClick={connect}
              width="2.5rem"
            />
            <MoreButton>
              <MoreOptions type={moreType} item={item} />
            </MoreButton>
          </Box>
        </Box>

        <Box alignItems="center" mt="2rem">
          {renderIcon()}
          <Seekbar
            duration={duration}
            played={isCurrent ? progress.playedSeconds : 0}
            progress={isCurrent ? progress.played * 100 : 0}
            textColor="switchingText"
          />
        </Box>
      </Details>
    </Wrapper>
  );
};

TrackItemPlayer.propTypes = {
  backgroundColor: PropTypes.string,
  className: PropTypes.string,
  floating: PropTypes.bool,
  item: PropTypes.object,
  moreType: PropTypes.string,
  onPlay: PropTypes.func,
  showAlbum: PropTypes.bool,
};

TrackItemPlayer.defaultProps = {
  backgroundColor: 'transparent',
  className: '',
  floating: false,
  item: {},
  moreType: 'track',
  onPlay: () => {},
  showAlbum: true,
};
