import styled from '@emotion/styled';
import React from 'react';
import { ExternalRedirectIcon } from 'components/icons';
const CartWrapper = styled.section`
  width: 100%;
  height: 100%;
`;
const IframeWrapper = styled.iframe`
  width: 100%;
  height: 100%;
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  padding: 3rem 0 2rem 0;

  svg {
    cursor: pointer;
    margin-left: 2rem;
  }
`;

const LiveStreamCart = () => {
  const openStore = () => {
    window.location.assign('https://google.com');
  };
  return (
    <CartWrapper>
      <HeaderWrapper>
        Store <ExternalRedirectIcon onClick={openStore} />
      </HeaderWrapper>
      <IframeWrapper src="https://staging-udux-payments.web.app/get-premium"></IframeWrapper>
    </CartWrapper>
  );
};
export default LiveStreamCart;
