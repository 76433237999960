import React from 'react';
import styled from '@emotion/styled/macro';
import PropTypes from 'prop-types';
import { Typography } from 'components/atoms';
import { Box } from 'rebass';
import { CheckBox } from 'components/atoms/Form/CheckBox';
import { Input } from 'components/atoms/Form/Input';

const Wrapper = styled(Box)`
  background-color: ${({ theme }) => theme.colors.metadataBg};
  border-radius: 0.5rem;
  flex-direction: column;
  margin-bottom: 2rem;
  padding: 6rem 4rem;
`;

const HoverBox = styled(Box)`
  cursor: pointer;
  ${CheckBox} {
    margin-right: 2rem;
  }

  &:not(:last-child) {
    margin-bottom: 1.5rem;
  }
`;

const Text = styled(Typography.Paragraph)`
  color: ${({ theme }) => theme.colors.switchingText};
`;

export const ReleaseType = ({ onChange, value, schedule, onScheduleChange }) => {
  return (
    <Wrapper>
      <HoverBox onClick={() => onChange('public')}>
        <CheckBox checked={value === 'public'} />
        <Text>Public</Text>
      </HoverBox>

      <HoverBox onClick={() => onChange('private')}>
        <CheckBox checked={value === 'private'} />
        <Text>Private</Text>
      </HoverBox>

      <HoverBox onClick={() => onChange('schedule')}>
        <CheckBox checked={value === 'schedule'} />
        <Text>Schedule</Text>
      </HoverBox>

      {schedule && <Input type="date" onChange={onScheduleChange} />}
    </Wrapper>
  );
};

ReleaseType.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};
