import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Spinner } from 'components/atoms';
import { MoneyBundleIcon } from 'components/icons';
// import { MoneyBagIcon } from 'components/icons';
// import { MoneyNotesIcon } from 'components/icons';
// import { LivestreamContext } from 'contexts/LivestreamContext';
import { WalletContext } from 'contexts/WalletContext';
import {
  FundsValue,
  MoneyIconWrapper,
  NotEnoughFundMessage,
  NotEnoughFundsWrapper,
  PricingAmount,
  PricingBox,
  PricingCard,
  PricingCardWrapper,
  SavedAmount,
} from './styled';

const NotEnoughFunds = () => {
  const {
    WalletModel: { currency, activePriceItem },
    updateAmountFunded,
    getAllBills,
  } = useContext(WalletContext);
  const [bills, setBills] = useState([]);
  const [activeCard, setActiveCard] = useState(activePriceItem);

  const selectPriceItem = useCallback(
    item => {
      setActiveCard(item);
      updateAmountFunded(item);
    },
    [updateAmountFunded],
  );

  const getBills = useCallback(() => {
    getAllBills((err, data) => {
      if (err) {
        return;
      }
      data = data.splice(0, 3).sort((a, b) => a.price - b.price);
      setBills(data);
      selectPriceItem(data[0]);
    });
  }, [getAllBills, selectPriceItem]);

  useEffect(() => {
    getBills();
  }, [getBills]);

  return (
    <NotEnoughFundsWrapper>
      <NotEnoughFundMessage>You are missing U20, please fill up your wallet to make it rain!</NotEnoughFundMessage>
      {bills.length === 0 && (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Spinner />
        </div>
      )}
      <PricingBox>
        {bills.map(price => (
          <PricingCardWrapper key={price.id}>
            <PricingCard active={activeCard.id === price.id} onClick={() => selectPriceItem(price)}>
              <MoneyIconWrapper>{price.icon || <MoneyBundleIcon />}</MoneyIconWrapper>
              <FundsValue> {price.amount}</FundsValue>
              <PricingAmount>{currency + price.price}</PricingAmount>
            </PricingCard>
            {price.save && <SavedAmount> Save {price.save}$ </SavedAmount>}
          </PricingCardWrapper>
        ))}
      </PricingBox>
    </NotEnoughFundsWrapper>
  );
};

export default NotEnoughFunds;
