import * as t from '../types';

const INITIAL_STATE = {
  leaderboard: {},
  fetching: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case t.GET_LEADERBOARD_REQUEST:
      return { ...state, fetching: true };
    case t.GET_LEADERBOARD_SUCCESS:
      return {
        ...state,
        leaderboard: { ...state, leaderboard: action.payload, fetching: false },
      };
    case t.GET_LEADERBOARD_FAILURE:
      return { ...state, fetching: false };
    case t.LOGOUT:
      return INITIAL_STATE;
    default:
      return { ...state };
  }
};
