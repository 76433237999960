import { AlbumTile } from './AlbumTile';
import { ArtistTile } from './ArtistTile';
import { FeedTile } from './FeedTile';
import { MoodTile } from './MoodTile';
import { PlaylistTile } from './PlaylistTile';
import { TrackTile } from './TrackTile';
import { UtilityTile } from './UtilityTile';
import { VideoTile } from './VideoTile';
import { VideoPlaylistTile } from './VideoPlaylistTile';
import { PersonalPlaylistTile, PlayerPersonalPlaylistTile } from './PersonalPlaylistTile';

export const Tile = {
  Album: AlbumTile,
  Artist: ArtistTile,
  Feed: FeedTile,
  Mood: MoodTile,
  Playlist: PlaylistTile,
  Track: TrackTile,
  Utility: UtilityTile,
  Video: VideoTile,
  VideoPlaylist: VideoPlaylistTile,
  PersonalPlaylist: PersonalPlaylistTile,
  PlayerPersonalPlaylist: PlayerPersonalPlaylistTile,
};
