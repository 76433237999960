import React from 'react';
import PropTypes from 'prop-types';

function SvgDrag({ fill, ...props }) {
  return (
    <svg id="drag_svg__menu-lines" width="1em" height="1em" viewBox="0 0 13.313 9.319" {...props}>
      <defs>
        <style>{`.drag_svg__cls-1{fill:${fill}}`}</style>
      </defs>
      <g id="drag_svg__Group_45" data-name="Group 45">
        <g id="drag_svg__Group_44" data-name="Group 44">
          <g id="drag_svg__Group_43" data-name="Group 43">
            <path
              id="drag_svg__Path_1027"
              d="M12.647 143.021H.666a.666.666 0 000 1.331h11.981a.666.666 0 000-1.331z"
              className="drag_svg__cls-1"
              data-name="Path 1027"
              transform="translate(0 -139.027)"
            />
            <path
              id="drag_svg__Path_1028"
              d="M.666 49.005h11.981a.666.666 0 000-1.331H.666a.666.666 0 000 1.331z"
              className="drag_svg__cls-1"
              data-name="Path 1028"
              transform="translate(0 -47.674)"
            />
            <path
              id="drag_svg__Path_1029"
              d="M12.647 238.369H.666a.666.666 0 000 1.331h11.981a.666.666 0 000-1.331z"
              className="drag_svg__cls-1"
              data-name="Path 1029"
              transform="translate(0 -230.381)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

SvgDrag.propTypes = {
  fill: PropTypes.string,
};

SvgDrag.defaultProps = {
  fill: '#fcb316',
};

export default SvgDrag;
