import api from 'services/api';
import * as t from '../types';
import sortBy from 'lodash.sortby';

export const getArtistAlbumsAction = id => async dispatch => {
  try {
    dispatch({ type: t.GET_ARTIST_ALBUMS_REQUEST });
    const resp = await api.getAlbums({ artist: id });
    if (resp.status === 200 || resp.status === 201) {
      dispatch({
        type: t.GET_ARTIST_ALBUMS_SUCCESS,
        payload: { id, data: resp.data },
      });
    } else {
      throw resp.data;
    }
  } catch (e) {
    dispatch({ type: t.GET_ARTIST_ALBUMS_FAILURE });
  }
};

export const getAlbumAction = (id, callback = () => {}) => async dispatch => {
  try {
    dispatch({ type: t.GET_ALBUM_REQUEST, payload: id });
    const resp = await api.getAlbum(id);
    if (resp.status === 200 || resp.status === 201) {
      dispatch({ type: t.GET_ALBUM_SUCCESS, payload: { id, data: resp.data } });
      callback(true, null);
    } else {
      throw resp.data;
    }
  } catch (e) {
    callback(null, e);
    dispatch({ type: t.GET_ALBUM_FAILURE, payload: id });
  }
};

export const getAlbumTracksAction = (id, callback = () => {}) => async dispatch => {
  try {
    dispatch({ type: t.GET_ALBUM_TRACKS_REQUEST, payload: id });
    const resp = await api.getAlbumTracks(id);
    if (resp.status === 200 || resp.status === 201) {
      dispatch({
        type: t.GET_ALBUM_TRACKS_SUCCESS,
        payload: { id, data: resp.data },
      });
      callback(resp.data, null);
    } else {
      throw resp.data;
    }
  } catch (e) {
    callback(null, e);
    dispatch({ type: t.GET_ALBUM_TRACKS_FAILURE, payload: id });
  }
};

export const getUserAlbumsAction = (callback = () => {}) => async dispatch => {
  try {
    dispatch({ type: t.GET_USER_ALBUMS_REQUEST });
    const resp = await api.getUserAlbums();
    if (resp.status === 200 || resp.status === 201) {
      dispatch({ type: t.GET_USER_ALBUMS_SUCCESS, payload: resp.data });
      callback(true, null);
    } else {
      throw resp.data;
    }
  } catch (e) {
    callback(null, e);
    dispatch({ type: t.GET_USER_ALBUMS_FAILURE });
  }
};

export const getMyAlbumAction = (id, callback = () => {}) => async dispatch => {
  try {
    dispatch({ type: t.GET_ALBUM_REQUEST, payload: id });
    const resp = await api.getMyAlbum(id);
    if (resp.status === 200 || resp.status === 201) {
      dispatch({ type: t.GET_ALBUM_SUCCESS, payload: { id, data: resp.data } });
      callback(true, null);
    } else {
      throw resp.data;
    }
  } catch (e) {
    callback(null, e);
    dispatch({ type: t.GET_ALBUM_FAILURE, payload: id });
  }
};

export const createAlbumAction = (data, callback = () => {}) => async dispatch => {
  try {
    dispatch({ type: t.UPDATE_ALBUM_REQUEST, payload: { data } });
    const resp = await api.createAlbum(data);
    if (resp.status === 200 || resp.status === 201) {
      dispatch({
        type: t.UPDATE_ALBUM_SUCCESS,
        payload: { id: resp.data.id, data: resp.data },
      });
      callback(true, resp.data);
    } else {
      dispatch({ type: t.UPDATE_ALBUM_FAILURE, payload: { data } });
      callback(false, resp.data);
    }
  } catch (e) {
    callback(false, e);
    dispatch({ type: t.UPDATE_ALBUM_FAILURE, payload: { data } });
  }
};

export const updateAlbumAction = (data, callback = () => {}) => async dispatch => {
  try {
    dispatch({ type: t.UPDATE_ALBUM_REQUEST, payload: { data } });
    const resp = await api.updateAlbum(data);
    if (resp.status === 200 || resp.status === 201) {
      dispatch({
        type: t.UPDATE_ALBUM_SUCCESS,
        payload: { id: resp.data.id, data: resp.data },
      });
      callback(true, resp.data);
    } else {
      dispatch({
        type: t.UPDATE_ALBUM_FAILURE,
        payload: { id: resp.data.id, data },
      });
      callback(false, resp.data);
    }
  } catch (e) {
    callback(false, e);
    dispatch({ type: t.UPDATE_ALBUM_FAILURE, payload: { data } });
  }
};

export const sortAlbumsByTitleAction = item => async dispatch => {
  const sortTitle = sortBy(item, ['name']);
  dispatch({ type: t.SORT_ALBUMS_BY_TITLE, payload: sortTitle });
};
export const sortAlbumsByArtistAction = item => async dispatch => {
  const sortArtist = sortBy(item, ['artist_name']);
  dispatch({ type: t.SORT_ALBUMS_BY_ARTIST, payload: sortArtist });
};
