import React, { useRef, useState } from 'react';
import styled from '@emotion/styled/macro';
import PropTypes from 'prop-types';
import { Box, Flex } from 'rebass';
import { Typography, Form, Image } from 'components/atoms';
import { CameraIcon } from 'components/icons';

const Wrapper = styled(Box)`
  background-color: ${({ theme }) => theme.colors.metadataBg};
  border-radius: 0.5rem;
  flex-direction: column;
  margin-bottom: 2rem;
  padding: 3rem;
`;

const Title = styled(Typography.Paragraph)`
  font-weight: bold;
  margin-bottom: 2rem;
  text-align: center;
`;

const PosterPicker = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.secondary};
  border-radius: 0.5rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  left: 50%;
  padding: 0.75rem 0.75rem;
  position: absolute;
  top: calc(100% - 1.5rem);
  transform: translateX(-50%);

  ${Typography.Text} {
    white-space: nowrap;
  }
`;

const PosterWrapper = styled.div`
  height: 15rem;
  position: relative;
  width: 15rem;

  input {
    display: none;
  }
`;

const Poster = styled(Image)`
  border-radius: 0.5rem;
  height: 15rem;
  width: 15rem;
`;

const Metadata = ({ onChange, track, fields }) => {
  const posterRef = useRef();
  const [poster, setPoster] = useState(track.artwork);
  const changePoster = e => {
    if (!e.target.files || e.target.files.length === 0) return;
    setPoster(URL.createObjectURL(e.target.files[0]));
    onChange('poster', e.target.files[0]);
  };
  return (
    <Wrapper>
      <Title color="switchingText">Basic Information</Title>
      <Flex mx="-1.5rem" flexWrap={['wrap', 'nowrap', 'wrap', 'nowrap']}>
        <Box
          px="1.5rem"
          mb={['3rem', null, '3rem', 0]}
          flexShrink={0}
          flexGrow={['1', '1', '1', '0']}
          justifyContent={['center', 'flex-start']}
        >
          <PosterWrapper>
            <input type="file" accept="image/*" ref={posterRef} onChange={changePoster} />
            <Poster src={poster} placeholder="album" alt="Upload poster" />
            <PosterPicker onClick={() => posterRef.current.click()}>
              <CameraIcon style={{ marginRight: '0.5rem' }} />
              <Typography.Text size="tiny" color="white">
                Change Photo
              </Typography.Text>
            </PosterPicker>
          </PosterWrapper>
        </Box>

        <Box px="1.5rem" flexGrow={['1', '1,', '1', '1', 0]}>
          <Form>
            <Flex flexWrap="wrap" mx="-1.5rem">
              {fields.map(({ title, field }) => (
                <Box px="1.5rem" mb="2rem" width={[1, 1 / 2, 1, 1, 1 / 2]}>
                  <Form.Group>
                    <Form.Label required>{title}</Form.Label>
                    <Form.Input {...{ value: track[field], onChange: v => onChange(field, v.target.value) }} />
                  </Form.Group>
                </Box>
              ))}
            </Flex>
          </Form>
        </Box>
      </Flex>
    </Wrapper>
  );
};

Metadata.propTypes = {
  onChange: PropTypes.func.isRequired,
  track: PropTypes.object.isRequired,
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      field: PropTypes.string,
    }).isRequired,
  ),
};

Metadata.defaultProps = {
  fields: [
    { title: 'Artist Name', field: 'artist_name' },
    { title: 'Featuring', field: 'featuring' },
    { title: 'Song Title', field: 'name' },
    { title: 'Album Title', field: 'album_name' },
    { title: 'Producer(s)', field: 'producer_name' },
    { title: 'Genre(s)', field: 'genre_name' },
  ],
};

export { Metadata };
