import React, { useCallback, useContext, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { CartIcon, Send1Icon, Like1Icon } from 'components/icons';
import { HideCommentIcon } from 'components/icons';
import { LIKE_LIVESTREAM, OPEN_CART, OPEN_SHARE, OPEN_COMMENTS } from 'redux/types';
import LikeAnimation from 'components/atoms/LikeAnimation';
import { LivestreamContext } from 'contexts/LivestreamContext';
import { LikeIcon } from 'components/icons';
import { useDispatch } from 'react-redux';
import { livestreamActions } from 'redux/actions';

const IconBoxWrapper = styled.div`
  display: inline-flex;
  background-color: rgba(39, 39, 41, 0.8);
  border-radius: 100px;
  color: #ffffff;
  width: auto;
  margin: 0 3rem;
`;
const Icon = styled.span`
  margin: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  svg {
    height: 3rem;
    cursor: pointer;
    width: 3rem;
  }
`;

const IconText = styled.p`
  margin-top: 0.875rem;
  font-weight: 300;
  font-size: 12px;
  font-style: normal;
  text-align: center;
`;

export const IconBox = ({ icons, callback, ...rest }) => {
  const {
    LivestreamModel: { event },
    defaultLists,
    getLoveContainer,
    loveList,
    // showLove,
    resetLoveList,
    livestreamStats = {},
    currentCount,
    // hasTicketHandler,
  } = useContext(LivestreamContext);
  const dispatch = useDispatch();
  const likeHandler = (payload, cb) => dispatch(livestreamActions(payload, cb));
  const [boxIcons, setBoxIcons] = useState(icons);

  const getTextValue = target => {
    switch (target) {
      case LIKE_LIVESTREAM:
        return livestreamStats.likes;
      case OPEN_SHARE:
        return livestreamStats.shares;
      case OPEN_COMMENTS:
        return livestreamStats.views;
      default:
        return 0;
    }
  };
  const handleClick = (e, action) => {
    // if (!hasTicketHandler()) {
    //   return;
    // }
    if (callback) {
      // let timeout;
      if (action === LIKE_LIVESTREAM) {
        likeHandler({ targetID: event?.id });
        icons.map(icon => {
          if (icon.callback === LIKE_LIVESTREAM) {
            icon.icon = LikeIcon;
          }
          return null;
        });
        setBoxIcons([...icons]);
        //     handleLikeAnimation();
      }
      callback(e, action);
    }
  };

  const createLikeAnimationIcons = () => {
    return defaultLists.map((_, i) => {
      return <LikeAnimation key={i + 'iconbox'} show={loveList.length > i} />;
    });
  };

  const resetLoveListHandler = useCallback(() => {
    resetLoveList();
  }, [resetLoveList]);
  useEffect(() => {
    resetLoveListHandler();
    getLoveContainer();
    // eslint-disable-next-line
  }, [getLoveContainer]);
  return (
    <IconBoxWrapper {...rest}>
      {boxIcons.map(icon => (
        <Icon key={icon.callback} id={icon.id || 'c-icon'} onClick={e => handleClick(e, icon.callback)}>
          {icon.callback === LIKE_LIVESTREAM && <>{createLikeAnimationIcons()}</>}
          <icon.icon id={icon.id || ''} />
          <IconText> {icon.computeText ? getTextValue(icon.callback) : icon.text}</IconText>
        </Icon>
      ))}
      <p style={{ display: 'none' }}>{currentCount}</p>
    </IconBoxWrapper>
  );
};

IconBox.defaultProps = {
  icons: [
    {
      key: 1,
      text: 'Store',
      computeText: false,
      icon: CartIcon,
      callback: OPEN_CART,
    },
    {
      key: 2,
      text: 'Hide',
      computeText: false,
      callback: OPEN_COMMENTS,
      icon: HideCommentIcon,
    },
    {
      key: 3,
      text: '10k',
      computeText: true,
      callback: OPEN_SHARE,
      icon: Send1Icon,
    },
    {
      key: 4,
      text: '11k',
      id: 'like-stream',
      computeText: true,
      callback: LIKE_LIVESTREAM,
      icon: Like1Icon,
    },
  ],
  style: { padding: '0 2rem' },
};
