import React from 'react';

function SvgFacebook({ ...props }) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 13 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M8.08879 24V13.0677H11.7743L12.3264 8.78866H8.08879V6.06336C8.08879 4.8273 8.43193 3.98508 10.2044 3.98508H12.4507V0.168229C12.0598 0.11625 10.719 0 9.15877 0C5.90117 0 3.6717 1.98769 3.6717 5.63955V8.78866H0V13.0677H3.6717V24H8.08879Z"
        fill="white"
      />
    </svg>
  );
}

export default SvgFacebook;
