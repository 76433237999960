import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { animated, useSpring } from 'react-spring';
import { TimesIcon } from 'components/icons';
import { Flex } from 'rebass';
import PropTypes from 'prop-types';
import { isObjEmpty } from 'lib/utils';
import styled from '@emotion/styled/macro';

const types = {
  error: 'error',
  info: 'azure-two',
  success: 'dark-green',
};

const Alert = styled(Flex)`
  align-items: center;
  display: flex;
  height: 4rem;
  justify-content: space-between;
  left: 0;
  overflow: hidden;
  padding: 1rem;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1300;
  border-radius: 5.9px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  svg {
    cursor: pointer;
    fill: #ffffff;
    opacity: 0.6;
  }
  @media (max-width: 768px) {
    svg {
      width: 2rem;
    }
  }
`;

const Text = styled.p`
  color: #ffffff;
  display: inline;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.2;
  text-align: initial;
  text-decoration: none;
  text-transform: uppercase;
  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const AlertContext = React.createContext({});

export const AlertProvider = ({ children }) => {
  const [alert, setAlert] = useState({});
  const addAlert = useCallback(
    newAlert => setAlert({ type: 'success', dismissable: false, duration: 10000, ...newAlert }),
    [],
  );

  const removeAlert = useCallback(() => setAlert({}), []);

  const style = useSpring({
    config: { duration: 200 },
    height: alert?.message ? `4rem` : '0rem',
    opacity: alert?.message ? 1 : 0,
  });

  useEffect(() => {
    let timeout;
    if (!isObjEmpty(alert)) {
      timeout = setTimeout(() => {
        setAlert({});
      }, alert.duration);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [alert]);

  const providerValue = useMemo(() => ({ alert, addAlert, removeAlert }), [alert, addAlert, removeAlert]);

  return (
    <AlertContext.Provider value={providerValue}>
      <Container>
        <Alert as={animated.div} backgroundColor={types[alert?.type]} style={style}>
          <Text size="small" color="white" weight="medium">
            {alert.message}
          </Text>
          {alert?.dismissable && <TimesIcon size="2.5rem" onClick={removeAlert} />}
        </Alert>
      </Container>
      {children}
    </AlertContext.Provider>
  );
};

AlertProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
