import { Box, Flex } from 'rebass';
import { Typography } from 'components/atoms';
import { FooterSocial } from './Social';
import { Link } from 'react-router-dom';
import { LogoIcon } from 'components/icons';
import React from 'react';
import styled from '@emotion/styled/macro';

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.switchingBg};
  padding-top: 5rem;
  position: relative;
`;

const List = styled.ul`
  margin-top: 1.5rem;
`;

const ListHeader = styled(Typography.Paragraph)`
  font-weight: bold;
  font-size: ${({ theme }) => theme.font.size.small};
  margin-bottom: 1rem;
`;

const ListItem = styled.li`
  &:not(:last-child) {
    margin-bottom: 0.5rem;
  }
`;

const ListLink = styled(Link)`
  font-size: ${({ theme }) => theme.font.size.tiniest};
  opacity: 0.6;
  text-decoration: none;
  &,
  &:visited {
    color: ${({ theme }) => theme.colors.switchingText};
  }

  &:hover {
    text-decoration: underline;
  }
`;

const AboutText = styled(Typography.Paragraph)`
  color: ${({ theme }) => theme.colors.switchingText};
  font-size: ${({ theme }) => theme.font.size.tiniest};
  opacity: 0.6;

  ${Typography.Text} {
    display: inline-block;

    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }
`;

ListHeader.defaultProps = {
  as: 'li',
};

ListLink.defaultProps = {
  to: '#',
};

export const FooterLinks = () => {
  return (
    <Wrapper>
      <LogoIcon style={{ marginBottom: '3rem', padding: '0 2rem' }} width="10rem" height="2.8rem" />
      <Flex
        flexWrap="wrap"
        mx="-1.5rem"
        justifyContent="space-between"
        style={{ borderTop: '1.3px solid rgba(255, 255, 255, 0.2)' }}
        padding="2rem 2rem"
      >
        <Box width={[1, 1 / 2, 1 / 2, 1 / 4]} mb="2rem" flexDirection="column">
          <AboutText>
            <Typography.Text>
              Music enthusiasts around the world are loving-up on uduX as the music streaming service for this age.
            </Typography.Text>
            <Typography.Text>
              The platform enjoys popular rating as Africa's largest single repository of local and International
              content..
            </Typography.Text>
          </AboutText>
        </Box>
        {/* <Box pr="1.5rem" pl={['1.5rem', null, null, '5rem']} width={[1, 1 / 2, 1 / 2, 1 / 4]} mb="2rem">
            <List>
              <ListItem>
                <ListLink to="/">Home</ListLink>
              </ListItem>

              <ListItem>
                <ListLink to="/trending">Trending</ListLink>
              </ListItem>

              <ListItem>
                <ListLink to="/recently-added">Recently Added</ListLink>
              </ListItem>

              <ListItem>
                <ListLink to="/moods">Moods For You</ListLink>
              </ListItem>

              <ListItem>
                <ListLink to="/playlists">Playlists</ListLink>
              </ListItem>
            </List>
          </Box> */}
        <Box width={[1, 1 / 2, 1 / 2, 1 / 4]} mb="2rem">
          <List>
            <ListItem>
              <ListLink to="/about-us" style={{ color: '#FDB913', fontWeight: 'bold', opacity: '1' }}>
                ABOUT
              </ListLink>
            </ListItem>
            <ListItem>
              <ListLink to="/about-us">Our Story</ListLink>
            </ListItem>
          </List>
        </Box>
        <Box width={[1, 1 / 2, 1 / 2, 1 / 4]} mb="2rem">
          <List>
            <ListItem>
              <ListLink style={{ color: '#FDB913', fontWeight: 'bold', opacity: '1' }}>HELP</ListLink>
            </ListItem>
            <ListItem>
              <ListLink>FAQs</ListLink>
            </ListItem>
            <ListItem>
              <ListLink to="/toc.html" target="_blank" rel="noopener noreferrer">
                Terms & Conditions
              </ListLink>
            </ListItem>

            <ListItem>
              <ListLink to="/privacy.html" target="_blank" rel="noopener noreferrer">
                Privacy Policy
              </ListLink>
            </ListItem>
          </List>
        </Box>
      </Flex>
      <FooterSocial />
    </Wrapper>
  );
};
