import * as React from 'react';

function SvgUnionBadge(props) {
  return (
    <svg width="103" height="107" viewBox="0 0 103 107" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.64328 37.4905L100.456 0.956935C101.861 0.437229 103.227 1.80957 102.701 3.2128L64.4498 105.216C63.9107 106.654 61.9133 106.75 61.239 105.37L40.3872 62.7077C40.2301 62.3862 39.9783 62.1207 39.6656 61.9468L1.39947 40.6612C0.0925944 39.9343 0.240633 38.0091 1.64328 37.4905Z"
        fill="url(#paint0_linear)"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="61.7159"
          y1="11.7586"
          x2="77.994"
          y2="34.2559"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ED1651" />
          <stop offset="1" stopColor="#A30F35" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default SvgUnionBadge;
