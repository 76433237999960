import React, { useState, useRef, useContext } from 'react';
import styled from '@emotion/styled/macro';
import { css } from '@emotion/core';
import { useTheme } from 'emotion-theming';
import { Box as FlexBox } from 'rebass';
import PropTypes from 'prop-types';
import { EyeIcon, EyeHideIcon2 } from 'components/icons';
import PhoneInputComponent from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { SpacebarContext } from 'contexts/SpacebarContext';
import ReactDOM from 'react-dom';

const InputComponent = styled.input`
  -moz-appearance: none;
  -webkit-appearance: none;
  appearence: none;
  background-color: #272729;
  border-radius: ${props => (props.bordered ? '100px !important' : '5px !important')};
  border: none;
  color: ${({ theme, textColor }) => theme.colors[textColor]};
  display: block;
  font-size: 1.4rem;
  height: 4.5rem;
  line-height: 1.2;
  margin: 0;
  outline: none;
  font-weight: 100;
  padding: 0.5rem 2rem;
  width: 100%;
  caret-color: #fd9113;

  &:disabled {
    background-color: rgba(128, 128, 128, 0.12);
  }

  &::placeholder {
    color: ${props => props.theme.colors['brownish-grey-two']};
    font-size: 1.2rem;
  }

  ${props => {
    if (props.addonPosition) {
      return css`
        padding-${props.addonPosition}: 0.5rem;
      `;
    }
  }}

  ${({ size }) => {
    if (size === 'sm') {
      return css`
        height: 4rem;
      `;
    }
  }}
`;

const InputGroupContainer = styled(FlexBox)`
  background-color: ${props => props.theme.colors[props.backgroundColor]};
  border-radius: 5px !important;
  width: 100%;

  input {
    background-color: transparent;
  }
`;

const InputAddon = styled(FlexBox)`
  padding: 0 0.1rem;
  position: relative;
  width: 4rem;
`;

const InputSecondAddon = styled(FlexBox)`
  padding: 0 0.1rem;
  position: relative;
  width: 4rem;
`;

export const InputGroup = ({
  addon,
  addonPosition,
  secondAddon,
  secondAddonPosition,
  backgroundColor,
  className,
  style,
  size,
  inputProps,
  textColor,
}) => {
  const inputRef = useRef(null);
  const [value, setValue] = useContext(SpacebarContext);

  const handleMouseOut = currentRef => {
    if (document.activeElement === ReactDOM.findDOMNode(currentRef)) {
      setValue(true);
    } else {
      setValue(false);
    }
  };

  return (
    <InputGroupContainer
      backgroundColor={backgroundColor}
      className={className}
      display="flex"
      flexDirection="row"
      style={style}
      size={size}
    >
      {addonPosition === 'left' && (
        <InputAddon display="flex" alignItems="center" justifyContent="center">
          {addon}
        </InputAddon>
      )}
      <InputComponent
        backgroundColor="transparent"
        textColor={textColor}
        addonPosition={addonPosition}
        secondAddonPosition={secondAddonPosition}
        size={size}
        {...inputProps}
        autoComplete="on"
        ref={inputRef}
        onFocus={() => handleMouseOut(inputRef.current)}
        focus={value}
      />
      {addonPosition === 'right' && (
        <InputAddon display="flex" alignItems="center" justifyContent="center">
          {addon}
        </InputAddon>
      )}
      {secondAddonPosition === 'right' && (
        <InputSecondAddon display="flex" alignItems="center" justifyContent="center">
          {secondAddon}
        </InputSecondAddon>
      )}
    </InputGroupContainer>
  );
};

const Icon = styled.svg``;

export const Input = ({ type, onChange, bordered, ...props }) => {
  const [hide, setHide] = useState(true);
  const { inputText } = useTheme().colors;

  const toggleHide = () => setHide(v => !v);
  if (type === 'password') {
    return (
      <InputGroup
        style={{ background: '#272729' }}
        addon={
          <Icon
            fill={inputText}
            as={!hide ? EyeIcon : EyeHideIcon2}
            width="2rem"
            height="2rem"
            onClick={toggleHide}
            style={{ cursor: 'pointer' }}
          />
        }
        addonPosition="right"
        inputProps={{ placeholder: '', type: hide ? 'password' : 'text', onChange, ...props }}
      />
    );
  }

  if (type === 'phone') {
    return (
      <PhoneInputComponent
        country="ng"
        onChange={onChange}
        autoFormat
        inputProps={{ style: { color: inputText }, ...props }}
        {...props}
      />
    );
  }

  return <InputComponent onChange={onChange} type={type} bordered={bordered} {...props} />;
};

Input.propTypes = {
  backgroundColor: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  textColor: PropTypes.string,
  type: PropTypes.string,
};

Input.defaultProps = {
  backgroundColor: 'input',
  textColor: 'inputText',
  type: 'text',
};

InputGroup.defaultProps = {
  addonPosition: 'left',
  backgroundColor: 'input',
  className: '',
  style: {},
  textColor: 'inputText',
};

InputGroup.propTypes = {
  addon: PropTypes.node.isRequired,
  addonPosition: PropTypes.string,
  // SecondAddon: PropTypes.node.isRequired,
  SecondAddonPosition: PropTypes.string,
  backgroundColor: PropTypes.string,
  className: PropTypes.string,
  inputProps: PropTypes.objectOf(PropTypes.any).isRequired,
  size: PropTypes.string.isRequired,
  style: PropTypes.objectOf(PropTypes.any),
  textColor: PropTypes.string,
};
