import * as React from 'react';

function SvgBlueHeartBadge(props) {
  return (
    <svg width="101" height="94" viewBox="0 0 101 94" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M51.6026 88.2499L91.364 45.1097C101.108 35.3314 101.1 19.4972 91.3424 9.72775C81.5763 -0.0498722 65.7417 -0.0498729 55.9756 9.72774L50.4996 15.2101L45.0237 9.72774C35.2576 -0.0498636 19.4229 -0.0498695 9.65684 9.72774C-0.101079 19.4972 -0.108263 35.3314 9.63528 45.1097L49.3967 88.2499L50.4996 89.4466L51.6026 88.2499Z"
        fill="#0055B9"
        stroke="#08080A"
        strokeWidth="3"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="50.4996"
          y1="3.92617"
          x2="50.6607"
          y2="86.7812"
          gradientUnits="userSpaceOnUse"
        >
          <start stopColor="#0055B9" />
          <stop offset="1" stopColor="#00295A" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default SvgBlueHeartBadge;
