import React from 'react';
import PropTypes from 'prop-types';
import { Wrapper, Poster, Details, Title, Description, Subtitle, DetailBox } from './styled';

export const FeedTile = ({ item }) => {
  const { artwork, description, name, artist_name } = item;
  return (
    <Wrapper width={['100%', '32rem', '45rem']}>
      <Poster src={artwork} placeholder="event" alt={name} />
      <Details>
        <DetailBox>
          <Title>{name}</Title>
          <Description>{description}</Description>
          <Subtitle size="tiny" color="brownish-grey-two">
            {artist_name}
          </Subtitle>
        </DetailBox>
      </Details>
    </Wrapper>
  );
};

FeedTile.propTypes = {
  item: PropTypes.shape({
    artwork: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    artist_name: PropTypes.string.isRequired,
  }).isRequired,
};
