import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { PlayIcon } from 'components/icons';
import { Wrapper, Poster, Details, Title, DetailBox, PlayBox } from './styled';

export const VideoTile = ({ item, target, ...props }) => {
  const { name, pictures, id = '' } = item;
  const sizes = (pictures && pictures.sizes) || [];
  const artwork = sizes.length && sizes.length >= 4 ? sizes[3].link : '';
  let link = '';

  switch (target) {
    case 'videos':
      link = `/videos/${id}`;
      break;
    case 'livestreams':
      link = `/livestreams/${id}`;
      break;
    default:
      break;
  }

  return (
    <Wrapper width={['20rem', '28rem', '32rem']} {...props}>
      <PlayBox position="relative" as={Link} to={link}>
        <Poster src={artwork} alt={name} placeholder="video" />
        <PlayIcon fill="#FFA700" width="6rem" height="6rem" />
      </PlayBox>
      <Details>
        <DetailBox>
          <Title as={Link} to={link} ellipsize>
            {name}
          </Title>
        </DetailBox>
      </Details>
    </Wrapper>
  );
};

VideoTile.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    pictures: PropTypes.object.isRequired,
  }).isRequired,
  target: PropTypes.string,
};

VideoTile.defaultProps = {
  target: 'videos',
};
