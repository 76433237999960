import { Spinner } from 'components/atoms';
import { LivestreamContext } from 'contexts/LivestreamContext';
// import { objectsEqual } from 'lib/utils';
import { formatName } from 'lib/utils';
import React, {
  useState,
  useRef,
  useContext,
  //  useCallback,
  useEffect,
} from 'react';
import { useDispatch } from 'react-redux';
import { createLivestreamCommentAction } from 'redux/actions';
// import { getCommentsAction } from 'redux/actions';
import {
  Avatar,
  CommentsBox,
  Wrapper,
  Comment,
  CommentContent,
  Username,
  UserComment,
  CommentForm,
  CommentInput,
  CommentLoader,
} from './styled';

const LiveStreamComment = () => {
  const {
    LivestreamModel: { event, allComments },
    getLiveStreamComment,
  } = useContext(LivestreamContext);
  const {
    id,
    // type = 'livestream', subtype
  } = event;
  // const fetching = useSelector(state => state.comment.fetching[id] || false);
  // const comments = useSelector(state => state.comment.data[id] || [], objectsEqual);
  const dispatch = useDispatch();
  // const getComments = useCallback(() => dispatch(getCommentsAction(id, type, subtype)), [id, type, subtype, dispatch]);
  const createComment = callback => dispatch(createLivestreamCommentAction(id, comment, callback));
  const [creatingComment, isCreatingComment] = useState(false);
  const commentBoxRef = useRef();
  const [comment, setComment] = useState('');

  const createCommentHandler = e => {
    e.preventDefault();
    const keepComment = comment;
    setComment('');
    isCreatingComment(true);
    createComment((success, error) => {
      isCreatingComment(false);
      if (success) {
        console.log(success);
      } else {
        console.log(error);
        setComment(keepComment);
      }
    });
  };

  const getCommentPosterName = user => {
    const fullname = formatName(user);
    return fullname === ' ' ? 'Anonymous' : fullname;
  };

  // const getCommentHandler = useCallback(() => {
  //   getComments();
  // }, [getComments]);
  // useEffect(() => {
  //   let interval;
  //   getCommentHandler();
  //   interval = setInterval(() => {
  //     getCommentHandler();
  //   }, 20000);

  //   return () => clearInterval(interval);
  // }, [event, comments, getCommentHandler]);
  useEffect(() => {
    getLiveStreamComment(event.id);
    // eslint-disable-next-line
  }, [event.id]);
  useEffect(() => {
    const commentBox = document.querySelector('.comment-box');
    commentBox.scrollTop = commentBox.scrollHeight;
    // eslint-disable-next-line
  }, []);

  return (
    <Wrapper>
      {allComments === null && <Spinner />}
      <CommentsBox className="comment-box">
        {allComments &&
          allComments.map(comment => (
            <Comment key={Math.random()} ref={commentBoxRef}>
              <Avatar src={comment.owner.avatar} spinnerSize="1rem" alt={`${formatName(comment.owner)}'s avatar`} />
              <CommentContent>
                <Username>{getCommentPosterName(comment.owner)}</Username>
                <UserComment>{comment.comment}</UserComment>
              </CommentContent>
            </Comment>
          ))}
      </CommentsBox>
      <CommentForm onSubmit={createCommentHandler}>
        <CommentInput
          name="comment"
          onChange={e => {
            setComment(e.target.value);
          }}
          value={comment}
          disabled={creatingComment}
          placeholder={'Send message'}
        />
        {creatingComment && (
          <CommentLoader>
            <Spinner />
          </CommentLoader>
        )}
      </CommentForm>
    </Wrapper>
  );
};
export default LiveStreamComment;
