import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled/macro';
import { Typography, OutlineButton } from 'components/atoms';
import { Modal } from 'components/molecules';
import { Box } from 'rebass';

// const AllowButton = styled(Button)`
//   color: white !important;
//   background: linear-gradient(270.02deg, #fdb913 0.02%, #f2994a 99.98%);
//   margin: 0.5rem;
//   width: 100%;
// `;
const CancelButton = styled(OutlineButton)`
  color: white !important;
  border: 2px solid #272729;
  border-radius: 175.5px;
  font-weight: 500;
  margin: 0.5rem;
  width: 100%;
`;

const PushPermissionModal = ({ close, isOpen, notificationData, toggle }) => {
  const { data, message, title } = notificationData; // eslint-disable-line
  return (
    <>
      <Modal isOpen={isOpen} toggle={toggle} size="sm" showCloseIcon>
        <Modal.Header justifyContent="center">
          <Typography.Paragraph textTransform="uppercase" size="small" weight="bold">
            {title}
          </Typography.Paragraph>
        </Modal.Header>
        <Modal.Body>
          <Box mb="2rem" alignItems="center" justifyContent="center">
            <Typography.Paragraph size="small" color="switchingText" align="center">
              {message}
            </Typography.Paragraph>
          </Box>
          <Box display="flex">
            {/* <AllowButton
              onClick={notificationData}
              size="small"
              effect
              style={{ background: 'linear-gradient(270.02deg, #FDB913 0.02%, #F2994A 99.98%)' }}
            >
              Ok
            </AllowButton> */}

            <CancelButton onClick={close} effect size="small">
              Close
            </CancelButton>
          </Box>
        </Modal.Body>
      </Modal>
    </>
  );
};

PushPermissionModal.propTypes = {
  close: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  notificationData: PropTypes.shape({}),
  toggle: PropTypes.array.isRequired,
};

export default PushPermissionModal;
