import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Wrapper, Poster, Details, Title, DetailBox } from './styled';

export const VideoPlaylistTile = ({ item }) => {
  const { artwork, name, id } = item;

  return (
    <Wrapper width={['20rem', '28rem', '32rem']} as={Link} to={`/videos/playlist/${id}`}>
      <Poster src={artwork} alt={name} placeholder="video" />
      <Details float>
        <DetailBox>
          <Title ellipsize>{name}</Title>
        </DetailBox>
      </Details>
    </Wrapper>
  );
};

VideoPlaylistTile.propTypes = {
  item: PropTypes.shape({
    artwork: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
};
