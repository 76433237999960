import { FacebookIcon2 } from 'components/icons';
import { ClipboardIcon } from 'components/icons';
import { TwitterIcon } from 'components/icons';
import { LivestreamContext } from 'contexts/LivestreamContext';
import { getDayAndMonth } from 'lib/utils';
import { getHighResolutionPicture } from 'lib/utils';
import { copyText } from 'lib/utils';
import React, { useContext, useState } from 'react';
import { twitterShare, facebookShare, openInNewTab } from 'lib/share';

import {
  ComponentTitle,
  LiveStreamCover,
  CoverImage,
  LiveStreamDetails,
  ButtonsBox,
  SocialButton,
  ShareStreamBox,
  CopyFeedback,
} from './styled';

const ShareLiveStream = () => {
  const {
    LivestreamModel: { event },
  } = useContext(LivestreamContext);
  const artistName = event.artists[0]?.name;
  const [copied, setCopied] = useState(false);
  const handleShareClick = target => {
    switch (target) {
      case 'tweet':
        tweet();
        break;
      case 'facebook':
        faceBookShare();
        break;
      case 'copy':
        copytoClipboard();
        break;
      default:
        break;
    }
  };
  const getContent = () => {
    return `Watch a livestream event coming up on ${getDayAndMonth(event.startDate)} by ${
      event?.artists[0]?.name
    } titled ${event.name} on uduX`;
  };
  const tweet = () => {
    openInNewTab(twitterShare(event.sharelink, getContent()));
  };
  const faceBookShare = () => {
    openInNewTab(facebookShare(event.sharelink, getContent()));
  };
  const copytoClipboard = () => {
    copyText(event.sharelink);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };
  return (
    <ShareStreamBox>
      <ComponentTitle>Share</ComponentTitle>
      <LiveStreamCover>
        <CoverImage src={getHighResolutionPicture(event?.pictures).link} alt={`${event.name}'s cover`} />
        <LiveStreamDetails>
          <p>{event.name}</p>
          <span>
            {artistName} - {getDayAndMonth(event.startDate)}
          </span>
        </LiveStreamDetails>
      </LiveStreamCover>
      <ButtonsBox>
        <SocialButton onClick={() => handleShareClick('tweet')} color="#1DA1F3">
          <TwitterIcon fill="#fff" style={{ marginRight: '2rem' }} />
          TWEET
        </SocialButton>
        <SocialButton onClick={() => handleShareClick('facebook')} color="#3B5998">
          <FacebookIcon2 fill="#fff" style={{ marginRight: '2rem' }} />
          POST
        </SocialButton>
        <SocialButton onClick={() => handleShareClick('copy')} color="#000">
          <ClipboardIcon fill="#fff" style={{ marginRight: '2rem' }} />
          COPY TO CLIPBOARD
          <CopyFeedback>{copied ? 'copied!' : ''}</CopyFeedback>
        </SocialButton>
      </ButtonsBox>
    </ShareStreamBox>
  );
};
export default ShareLiveStream;
