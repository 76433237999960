import React from 'react';
import PropTypes from 'prop-types';

function SvgRepeat({ fill, ...props }) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 21.313 19.552" {...props}>
      <path
        d="M15.164 15.976a1.068 1.068 0 00-.326-.607L12.172 12.7a.888.888 0 00-1.256 1.256l.521.521.01.628H7.1a5.326 5.326 0 01-5.324-5.329A4.825 4.825 0 013.2 6.358.89.89 0 001.94 5.1 6.6 6.6 0 000 9.776a7.1 7.1 0 007.094 7.11h4.354l-.011.629-.52.521a.888.888 0 101.256 1.257l2.664-2.666a1.105 1.105 0 00.326-.649m6.15-6.2a6.6 6.6 0 01-1.94 4.674.888.888 0 11-1.256-1.256 4.824 4.824 0 001.421-3.418 5.326 5.326 0 00-5.329-5.332H9.954v.7l.444.443A.89.89 0 119.14 6.85L6.477 4.183c-.01-.01-.4-.329-.4-.637s.394-.609.4-.619L9.14.261a.89.89 0 111.26 1.256l-.442.443v.707h4.265a7.1 7.1 0 017.094 7.109"
        fill={fill}
        fillRule="evenodd"
      />
    </svg>
  );
}

SvgRepeat.propTypes = {
  fill: PropTypes.string,
};

SvgRepeat.defaultProps = {
  fill: '#fefefe',
};

export default SvgRepeat;
