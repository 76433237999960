import React from 'react';
import PropTypes from 'prop-types';

function SvgThumbDown({ fill, ...props }) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 10.07 9.154" {...props}>
      <g transform="translate(0 -25.5)">
        <g transform="translate(0 25.5)">
          <path
            id="Path_1344"
            data-name="Path 1344"
            d="M0,34.654H1.831V29.162H0Zm10.07-5.035a.918.918,0,0,0-.915-.915H6.271L6.728,26.6v-.137a.96.96,0,0,0-.183-.5l-.5-.458L3.021,28.521a.768.768,0,0,0-.275.641v4.577a.918.918,0,0,0,.915.915H7.781a.9.9,0,0,0,.824-.549l1.373-3.25a.779.779,0,0,0,.046-.32v-.915h.046Z"
            transform="translate(0 -25.5)"
            fill={fill}
          />
        </g>
      </g>
    </svg>
  );
}

SvgThumbDown.propTypes = {
  fill: PropTypes.string,
};

SvgThumbDown.defaultProps = {
  fill: 'gray',
};

export default SvgThumbDown;
