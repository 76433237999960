import React, { useCallback, useContext, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { paymentRedirectURL } from 'lib/utils';
import { WalletContext } from 'contexts/WalletContext';
import { Spinner } from 'components/atoms';

const ExternalPaymentHandlerWrapper = styled.section`
  width: 100%;
  height: 100%;
`;

const Iframe = styled.iframe`
  width: 100%;
  height: 100%;
`;

const ErrorMessage = styled.p`
  color: red;
`;

const SpinnerWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ExternalPaymentHandler = ({ fetchLink }) => {
  const {
    WalletModel: { activePriceItem },
    paymentLink,
    getNewPaymentLink,
  } = useContext(WalletContext);
  const [paymentUrl, setPaymentUrl] = useState(null);
  const [error, setError] = useState(null);
  const getPaymentLink = useCallback(async () => {
    if (paymentLink) {
      return setPaymentUrl(paymentLink);
    } else if (fetchLink) {
      getNewPaymentLink(activePriceItem, (err, data) => {
        if (err) {
          setError('An error occured, please contact support.');
          setPaymentUrl(' ');
        }
        if (data) {
          setPaymentUrl(data);
        }
      });
      return;
    }
    try {
      const url = await paymentRedirectURL();
      setPaymentUrl(url);
    } catch (error) {
      console.log(error);
      setError('Something went wrong!');
    }
  }, [activePriceItem, fetchLink, getNewPaymentLink, paymentLink]);

  useEffect(() => {
    getPaymentLink();
  }, [getPaymentLink]);
  return (
    <ExternalPaymentHandlerWrapper>
      {error && <ErrorMessage>{error}</ErrorMessage>}
      {!paymentUrl && (
        <SpinnerWrapper>
          <Spinner />
        </SpinnerWrapper>
      )}
      {paymentUrl && !error && <Iframe src={paymentUrl} />}
    </ExternalPaymentHandlerWrapper>
  );
};

export default ExternalPaymentHandler;
