import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled/macro';
import LazyLoad from 'vanilla-lazyload';
import cx from 'classnames';
import { placeholders } from 'lib/images';
import appConfig from 'config/app.config';
import { Spinner } from './Spinner';

const lazyloadConfig = {
  elements_selector: '.lazy-image',
};

if (!document.lazyLoadInstance) {
  document.lazyLoadInstance = new LazyLoad(lazyloadConfig);
}

const StyledSpinner = styled(Spinner)`
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const Wrapper = styled.div`
  display: inline-block;
  overflow: hidden;
  position: relative;
`;

const StlyedImage = styled.img`
  display: inline-block;
`;

const Placeholder = styled.img`
  height: 100%;
  left: 0;
  object-fit: cover;
  object-position: center;
  position: absolute;
  top: 0;
  width: 100%;
`;

export const Image = ({
  alt,
  className,
  placeholder,
  size,
  sizes,
  spin,
  spinnerSize,
  src,
  srcset,
  useThumbnail,
  ...rest
}) => {
  const [loading, setLoading] = useState(!!src);
  const [error, setError] = useState(!src);

  const thumbnail = () => {
    if (src) {
      if (src.includes(appConfig.imageServer)) {
        const replaced = src.replace(appConfig.imageServer, '');
        const filename = replaced.split('?')[0];
        return `${appConfig.baseURL}thumbnailer/${size}/${filename}?api=${appConfig.imageApiKey}`;
      }
      return src;
    }
    return '';
  };

  useEffect(() => {
    document.lazyLoadInstance.update();
  });

  return (
    <Wrapper className={className}>
      <StlyedImage
        alt={alt}
        className={cx('lazy-image', className)}
        data-sizes={sizes}
        data-src={useThumbnail ? thumbnail() : src}
        data-srcset={srcset}
        onLoad={() => {
          setError(false);
          setLoading(false);
        }}
        onError={() => {
          setError(true);
          setLoading(false);
        }}
        src={useThumbnail ? thumbnail() : src}
        {...rest}
      />
      {(loading || error) && <Placeholder src={placeholders[placeholder]} alt={`${placeholder} placeholder`} />}
      {loading && spin && <StyledSpinner size={spinnerSize} />}
    </Wrapper>
  );
};

// Image.propTypes = {
//   alt: PropTypes.string.isRequired,
//   className: PropTypes.string,
//   placeholder: PropTypes.string,
//   size: PropTypes.string,
//   sizes: PropTypes.string,
//   spin: PropTypes.bool,
//   spinnerSize: PropTypes.string,
//   src: PropTypes.string.isRequired,
//   srcset: PropTypes.string,
//   useThumbnail: PropTypes.bool,
// };

Image.defaultProps = {
  className: '',
  placeholder: 'artist',
  size: '300x300',
  sizes: '',
  spin: true,
  spinnerSize: '2.5rem',
  srcset: '',
  useThumbnail: true,
};
