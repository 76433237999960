import api from 'services/api';
import { getArtistAlbumsAction } from './album.actions';
import { getArtistPlaylistsAction } from './playlist.actions';
import { getArtistTracksAction } from './track.actions';
import * as t from '../types';

export const getSimilarArtistsAction = id => async dispatch => {
  try {
    dispatch({ type: t.GET_SIMILAR_ARTIST_REQUEST });
    const resp = await api.getSimilarArtists({ artist: id });
    if (resp.status === 200 || resp.status === 201) {
      dispatch({ type: t.GET_SIMILAR_ARTIST_SUCCESS, payload: { id, data: resp.data } });
    } else {
      throw resp.data;
    }
  } catch (e) {
    dispatch({ type: t.GET_SIMILAR_ARTIST_FAILURE });
  }
};

export const getArtistAction = (id, callback = () => {}) => async dispatch => {
  try {
    dispatch({ type: t.GET_ARTIST_REQUEST, payload: id });
    const resp = await api.getArtist(id);
    if (resp.status === 200 || resp.status === 201) {
      dispatch({ type: t.GET_ARTIST_SUCCESS, payload: { id, data: resp.data } });
      dispatch(getArtistTracksAction(id));
      dispatch(getArtistAlbumsAction(id));
      dispatch(getArtistPlaylistsAction(id));
      dispatch(getSimilarArtistsAction(id));
    } else {
      throw resp.data;
    }
  } catch (e) {
    callback(e);
    dispatch({ type: t.GET_ARTIST_FAILURE, payload: id });
  }
};

export const getUserArtistsAction = (callback = () => {}) => async dispatch => {
  try {
    dispatch({ type: t.GET_USER_ARTISTS_REQUEST });
    const resp = await api.getUserArtists();
    if (resp.status === 200 || resp.status === 201) {
      dispatch({ type: t.GET_USER_ARTISTS_SUCCESS, payload: resp.data });
      callback(true, null);
    } else {
      throw resp.data;
    }
  } catch (e) {
    callback(null, e);
    dispatch({ type: t.GET_USER_ARTISTS_FAILURE });
  }
};
