import { Image, Typography } from 'components/atoms';

import { Box } from 'rebass';
import { Seekbar } from 'components/molecules';
import { css } from '@emotion/core';
import styled from '@emotion/styled/macro';

export const Main = styled.div``;

export const Wrapper = styled(Box)`
  align-items: center;
  bottom: -15rem;
  display: flex;
  flex-direction: row;
  height: 8rem;
  justify-content: space-between;
  left: 0;
  padding: 0;
  position: fixed;
  transition: all 0.1s;
  width: 100%;
  z-index: 6;
  box-shadow: rgb(255 255 255 / 8%) 0px 0px 15px, rgb(255 255 255 / 15%) 0px 0px 3px 1px;

  ${({ visible, theme }) =>
    visible &&
    css`
      bottom: 0;
      transition: transition: all 300ms;
      padding: 1rem 1.5rem;

      ${theme.mq.sm`
        padding: 0rem 3rem 0rem 0rem;
      `}
    `}
`;

export const Artwork = styled(Image)`
  border-radius: 0.5rem;
  flex-shrink: 0;
  height: 4rem;
  margin-right: 1.5rem;
  width: 4rem;
  border-radius: 0;
  cursor: pointer;
  ${({ theme }) => theme.mq.sm`
  height: 8rem;
  width: 8rem;
  `}

  &:hover {
    filter: brightness(0.8);
  }
`;

export const Title = styled(Typography.Paragraph)`
  &,
  &:visited {
    color: #ffa700;
  }
  color: #fff;
  font-size: ${({ theme }) => theme.font.size.small};
  font-weight: 600;
  margin-bottom: 0.5rem;
  position: relative;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    margin-left: 0.4rem;
  }
  &:hover {
    text-decoration: underline;
  }
`;

export const Artist = styled(Typography.Paragraph)`
  font-size: ${({ theme }) => theme.font.size.tiny};
  position: relative;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

export const InfoWrapper = styled(Box)`
  align-items: center;
  justify-content: space-between;
  .xs-hide {
    display: none;

    ${({ theme }) => theme.mq.sm`
    display: block;
      `}
  }
  svg {
    width: 2.2rem;
  }
  @media (max-width: 480px) {
    svg {
      width: 2rem;
    }
  }
`;

export const Seeker = styled(Seekbar)`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  margin: 0 auto;
  padding: 0 2rem;

  z-index: 3;
    position: absolute;
    top: -1px;
    width: 100%;

  ${({ theme }) => theme.mq.md`
    display: flex;
    
    z-index: 3;
    position: absolute;
    top: -1px;
    width: 100%;
  `}

  ${({ theme }) => theme.mq.lg`
  display: flex;
 
  z-index: 3;
    position: absolute;
    top: -1px;
    width: 100%;
  `}

  ${({ theme }) => theme.mq.xl`
    
    z-index: 3;
    position: absolute;
    top: -1px;
    width: 100%;
  `}
`;

export const ControlWrapper = styled(Box)`
  align-items: center;
  flex-shrink: 0;
  .xs-hide {
    display: none;

    ${({ theme }) => theme.mq.sm`
    display: block;
      `}
  }

  > svg {
    cursor: pointer;
    font-size: 1.5rem;
    &:not(:last-child) {
      margin-right: 2rem;
      ${({ theme }) => theme.mq.sm`
      margin-right: 3rem;
      `}
    }
    ${({ theme }) => theme.mq.sm`
    font-size: 2rem;
    `}
  }
  .play {
    &:hover {
      transform: scale(1.4);
      transition-duration: 0.15s;
    }
  }
  .pause {
    &:hover {
      transform: scale(1.4);
      transition-duration: 0.15s;
    }
  }
  .next {
    &:hover {
      transform: scale(1.4);
      transition-duration: 0.15s;
    }
  }
  .prev {
    &:hover {
      transform: scale(1.4);
      transition-duration: 0.15s;
    }
  }
  .shuffle {
    &:hover {
      transform: scale(1.4);
      transition-duration: 0.15s;
    }
  }
  .repeat {
    &:hover {
      transform: scale(1.4);
      transition-duration: 0.15s;
    }
  }
`;

export const RightWrapper = styled(Box)`
  align-items: center;
  flex-shrink: 0;

  display: none;

  ${({ theme }) => theme.mq.md`
    display: block;
      `}

  > svg {
    height: 2rem;
    width: 2rem;
    cursor: pointer;
    &:not(:last-child) {
      margin-right: 2rem;

      ${({ theme }) => theme.mq.sm`
      margin-right: 3rem;
      `}
    }
  }
`;

export const Duration = styled(Typography.Text)`
  color: #fff !important;
  font-size: ${({ theme }) => theme.font.size.tiny};
  font-weight: ${({ theme }) => theme.font.weight.light};

  user-select: none;
  margin: 0 0.5rem 1rem 0.5rem !important;
`;

export const Timer = styled.div``;
