import * as t from '../types';

const INITIAL_STATE = {
  onBoardingData: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case t.ADD_ONBAORDING_DATA_REQUEST:
      return { ...state, onBoardingData: action.payload };
    case t.LOGOUT:
      return INITIAL_STATE;
    default:
      return { ...state };
  }
};
