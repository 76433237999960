import React from 'react';
import { AlertProvider } from 'contexts/AlertContext';
import { AuthModalProvider } from 'contexts/AuthModalContext';
import { BroadcastProvider } from 'contexts/BroadcastContext';
import { MagicProvider } from 'contexts/MagicContext';
import { PlaylistProvider } from 'contexts/PlaylistContext';
import { PromoProvider } from 'contexts/PromoContext';
import { PushNotificationProvider } from 'contexts/PushNotificationContext';
import { ShareProvider } from 'contexts/ShareContext';
import { TextModalProvider } from 'contexts/TextModalContext';
import { ToastProvider } from 'contexts/ToastContext';
import { TrackInfoProvider } from 'contexts/TrackInfoContext';
import { TrackProvider } from 'contexts/TrackContext';
import { UserProvider } from 'contexts/UserContext';
import { LivestreamProvider } from 'contexts/LivestreamContext';
import { OTPProvider } from 'contexts/OTPContext';
import { EmailProvider } from 'contexts/EmailContext';
import { WalletProvider } from 'contexts/WalletContext';
import { StudioxContextProvider } from 'contexts/StudioxContext';
import { DeleteProvider } from 'contexts/DeleteContext';
import { AddSongProvider } from 'contexts/AddSongContext';
import { SpacebarProvider } from 'contexts/SpacebarContext';
import { FullPlayerProvider } from 'contexts/FullPlayerContext';
import { PlayerProvider } from 'contexts/PlayerContext';

const ContextWrapper = ({ children }) => {
  return (
    <FullPlayerProvider>
      <SpacebarProvider>
        <AddSongProvider>
          <DeleteProvider>
            <StudioxContextProvider>
              <EmailProvider>
                <WalletProvider>
                  <LivestreamProvider>
                    <OTPProvider>
                      <UserProvider>
                        <AlertProvider>
                          <ToastProvider>
                            <PushNotificationProvider>
                              <BroadcastProvider>
                                <PlayerProvider>
                                  <MagicProvider>
                                    <AuthModalProvider>
                                      <PlaylistProvider>
                                        <ShareProvider>
                                          <TextModalProvider>
                                            <TrackProvider>
                                              <TrackInfoProvider>
                                                <PromoProvider>{children}</PromoProvider>
                                              </TrackInfoProvider>
                                            </TrackProvider>
                                          </TextModalProvider>
                                        </ShareProvider>
                                      </PlaylistProvider>
                                    </AuthModalProvider>
                                  </MagicProvider>
                                </PlayerProvider>
                              </BroadcastProvider>
                            </PushNotificationProvider>
                          </ToastProvider>
                        </AlertProvider>
                      </UserProvider>
                    </OTPProvider>
                  </LivestreamProvider>
                </WalletProvider>
              </EmailProvider>
            </StudioxContextProvider>
          </DeleteProvider>
        </AddSongProvider>
      </SpacebarProvider>
    </FullPlayerProvider>
  );
};

export default ContextWrapper;
