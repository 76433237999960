import React from 'react';
import PropTypes from 'prop-types';

function SvgArtist({ fill, size, ...props }) {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="11.1536" cy="6.72975" r="5.07692" fill={fill} stroke={fill} strokeWidth="2" />
      <path
        d="M11.1538 23.6528C16.1829 23.6528 19.0804 22.3556 20.4245 21.5206C21.0534 21.1299 21.3634 20.4106 21.2994 19.6653C21.2036 18.5484 20.7846 17.433 20.3886 16.5953C19.9603 15.6892 19.0135 15.1913 18.0113 15.1913H4.29637C3.29413 15.1913 2.34741 15.6892 1.91907 16.5953C1.52306 17.433 1.10411 18.5484 1.00824 19.6653C0.944277 20.4106 1.25427 21.1299 1.88321 21.5206C3.22729 22.3556 6.12475 23.6528 11.1538 23.6528Z"
        fill={fill}
      />
      <path
        d="M20.4245 21.5206L20.9521 22.3701V22.3701L20.4245 21.5206ZM1.88321 21.5206L2.41089 20.6712L2.41089 20.6712L1.88321 21.5206ZM1.00824 19.6653L2.00458 19.7509L1.00824 19.6653ZM21.2994 19.6653L20.3031 19.7509V19.7509L21.2994 19.6653ZM20.3886 16.5953L19.4845 17.0226V17.0226L20.3886 16.5953ZM1.91907 16.5953L1.015 16.1679L1.91907 16.5953ZM19.8968 20.6712C18.7149 21.4054 16.0009 22.6528 11.1538 22.6528V24.6528C16.3649 24.6528 19.4459 23.3058 20.9521 22.3701L19.8968 20.6712ZM11.1538 22.6528C6.30675 22.6528 3.59278 21.4054 2.41089 20.6712L1.35553 22.3701C2.8618 23.3058 5.94276 24.6528 11.1538 24.6528V22.6528ZM0.0119032 19.5798C-0.0796696 20.6467 0.363797 21.754 1.35553 22.3701L2.41089 20.6712C2.14474 20.5059 1.96822 20.1744 2.00458 19.7509L0.0119032 19.5798ZM20.3031 19.7509C20.3394 20.1744 20.1629 20.5059 19.8968 20.6712L20.9521 22.3701C21.9439 21.754 22.3873 20.6467 22.2958 19.5798L20.3031 19.7509ZM19.4845 17.0226C19.8583 17.8133 20.2219 18.8046 20.3031 19.7509L22.2958 19.5798C22.1853 18.2922 21.7109 17.0527 21.2927 16.1679L19.4845 17.0226ZM2.00458 19.7509C2.0858 18.8046 2.44938 17.8133 2.82314 17.0226L1.015 16.1679C0.596737 17.0527 0.122418 18.2922 0.0119032 19.5798L2.00458 19.7509ZM18.0113 14.1913H4.29637V16.1913H18.0113V14.1913ZM2.82314 17.0226C3.05699 16.528 3.60749 16.1913 4.29637 16.1913V14.1913C2.98077 14.1913 1.63782 14.8504 1.015 16.1679L2.82314 17.0226ZM21.2927 16.1679C20.6699 14.8504 19.3269 14.1913 18.0113 14.1913V16.1913C18.7002 16.1913 19.2507 16.528 19.4845 17.0226L21.2927 16.1679Z"
        fill={fill}
      />
    </svg>
  );
}

SvgArtist.propTypes = {
  fill: PropTypes.string,
  size: PropTypes.string,
};

SvgArtist.defaultProps = {
  fill: '#fcb316',
  size: '24',
};

export default SvgArtist;
