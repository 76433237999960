import React from 'react';

function SvgMix({ fill, ...props }) {
  return (
    <svg width="24" height="18" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M16.665 4.11367C17.2788 4.72676 17.7657 5.45482 18.0979 6.25622C18.4301 7.05761 18.6011 7.91664 18.6011 8.78416C18.6011 9.65169 18.4301 10.5107 18.0979 11.3121C17.7657 12.1135 17.2788 12.8416 16.665 13.4547M7.335 13.4437C6.72123 12.8306 6.23432 12.1025 5.9021 11.3011C5.56989 10.4997 5.3989 9.64069 5.3989 8.77316C5.3989 7.90563 5.56989 7.04661 5.9021 6.24521C6.23432 5.44382 6.72123 4.71576 7.335 4.10266M19.7787 1C21.8413 3.06325 23 5.86123 23 8.77866C23 11.6961 21.8413 14.4941 19.7787 16.5573M4.22134 16.5573C2.15872 14.4941 1 11.6961 1 8.77866C1 5.86123 2.15872 3.06325 4.22134 1"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.7002 11V7C10.7002 6.58798 11.1706 6.35279 11.5002 6.6L14.1669 8.6C14.4335 8.8 14.4335 9.2 14.1669 9.4L11.5002 11.4C11.1706 11.6472 10.7002 11.412 10.7002 11Z"
        fill="white"
        stroke="white"
      />
    </svg>
  );
}

export default SvgMix;
