export const GET_COMMENTS_REQUEST = 'GET_COMMENTS_REQUEST';
export const GET_COMMENTS_SUCCESS = 'GET_COMMENTS_SUCCESS';
export const GET_COMMENTS_FAILURE = 'GET_COMMENTS_FAILURE';

export const CREATE_COMMENT_REQUEST = 'CREATE_COMMENT_REQUEST';
export const CREATE_COMMENT_SUCCESS = 'CREATE_COMMENT_SUCCESS';
export const CREATE_COMMENT_FAILURE = 'CREATE_COMMENT_FAILURE';

export const DELETE_COMMENT_REQUEST = 'DELETE_COMMENT_REQUEST';
export const DELETE_COMMENT_SUCCESS = 'DELETE_COMMENT_SUCCESS';
export const DELETE_COMMENT_FAILURE = 'DELETE_COMMENT_FAILURE';

export const DISLIKE_COMMENT_REQUEST = 'DISLIKE_COMMENT_REQUEST';
export const DISLIKE_COMMENT_SUCCESS = 'DISLIKE_COMMENT_SUCCESS';
export const DISLIKE_COMMENT_FAILURE = 'DISLIKE_COMMENT_FAILURE';

export const LIKE_COMMENT_REQUEST = 'LIKE_COMMENT_REQUEST';
export const LIKE_COMMENT_SUCCESS = 'LIKE_COMMENT_SUCCESS';
export const LIKE_COMMENT_FAILURE = 'LIKE_COMMENT_FAILURE';

export const LOAD_MORE_COMMENTS_REQUEST = 'LOAD_MORE_COMMENTS_REQUEST';
export const LOAD_MORE_COMMENTS_SUCCESS = 'LOAD_MORE_COMMENTS_SUCCESS';
export const LOAD_MORE_COMMENTS_FAILURE = 'LOAD_MORE_COMMENTS_FAILURE';

export const REPLY_COMMENT_REQUEST = 'REPLY_COMMENT_REQUEST';
export const REPLY_COMMENT_SUCCESS = 'REPLY_COMMENT_SUCCESS';
export const REPLY_COMMENT_FAILURE = 'REPLY_COMMENT_FAILURE';
