import React from 'react';
import PropTypes from 'prop-types';

function SvgPlus({ fill, ...props }) {
  return (
    <svg width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M22.5 12L0.5 12" stroke="#FDB913" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M11.5 23L11.5 1" stroke="#FDB913" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

SvgPlus.propTypes = {
  fill: PropTypes.string,
};

SvgPlus.defaultProps = {
  fill: '#fff',
};

export default SvgPlus;
