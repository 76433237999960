import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { BroadcastChannel } from 'broadcast-channel';
import { generateID } from 'lib/utils';
import { useDispatch } from 'react-redux';

export const BroadcastContext = React.createContext({});
export const playerBC = new BroadcastChannel('player');

export const BroadcastProvider = ({ children }) => {
  const dispatch = useDispatch();
  const [tabId, setTabId] = useState('');

  useEffect(() => {
    const sessionId = sessionStorage.getItem('tabId');
    if (sessionId) {
      setTabId(sessionId);
    } else {
      const id = generateID();
      sessionStorage.setItem('tabId', id);
      setTabId(id);
    }
  }, []);

  useEffect(() => {
    const handler = msg => {
      const { source, ...action } = msg;
      dispatch(action);
    };

    playerBC.addEventListener('message', handler);
    return () => {
      playerBC.removeEventListener('message', handler);
      playerBC.close();
    };
    // eslint-disable-next-line
  }, []);

  const sendMessage = msg => playerBC.postMessage(msg);

  return <BroadcastContext.Provider value={{ tabId, sendMessage }}>{children}</BroadcastContext.Provider>;
};

BroadcastProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
