import React from 'react';

function SvgGoogle({ ...props }) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width="24" height="24" rx="12" fill="white" />
      <path
        d="M19.8444 10.4332H19.2V10.4H12V13.6H16.5212C15.8616 15.4628 14.0892 16.8 12 16.8C9.3492 16.8 7.2 14.6508 7.2 12C7.2 9.3492 9.3492 7.2 12 7.2C13.2236 7.2 14.3368 7.6616 15.1844 8.4156L17.4472 6.1528C16.0184 4.8212 14.1072 4 12 4C7.582 4 4 7.582 4 12C4 16.418 7.582 20 12 20C16.418 20 20 16.418 20 12C20 11.4636 19.9448 10.94 19.8444 10.4332Z"
        fill="#FFC107"
      />
      <path
        d="M4.92346 8.2764L7.55186 10.204C8.26306 8.4432 9.98546 7.2 12.0011 7.2C13.2247 7.2 14.3379 7.6616 15.1855 8.4156L17.4483 6.1528C16.0195 4.8212 14.1083 4 12.0011 4C8.92826 4 6.26346 5.7348 4.92346 8.2764Z"
        fill="#FF3D00"
      />
      <path
        d="M11.9996 20C14.066 20 15.9436 19.2092 17.3632 17.9232L14.8872 15.828C14.057 16.4593 13.0425 16.8008 11.9996 16.8C9.91876 16.8 8.15196 15.4732 7.48636 13.6216L4.87756 15.6316C6.20156 18.2224 8.89036 20 11.9996 20Z"
        fill="#4CAF50"
      />
      <path
        d="M19.8444 10.4331H19.2V10.3999H12V13.5999H16.5212C16.2057 14.4865 15.6373 15.2612 14.8864 15.8283L14.8876 15.8275L17.3636 17.9227C17.1884 18.0819 20 15.9999 20 11.9999C20 11.4635 19.9448 10.9399 19.8444 10.4331Z"
        fill="#1976D2"
      />
    </svg>
  );
}

export default SvgGoogle;
