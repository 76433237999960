export const GET_ARTIST_ALBUMS_REQUEST = 'GET_ARTIST_ALBUMS_REQUEST';
export const GET_ARTIST_ALBUMS_SUCCESS = 'GET_ARTIST_ALBUMS_SUCCESS';
export const GET_ARTIST_ALBUMS_FAILURE = 'GET_ARTIST_ALBUMS_FAILURE';

export const GET_ALBUM_REQUEST = 'GET_ALBUM_REQUEST';
export const GET_ALBUM_SUCCESS = 'GET_ALBUM_SUCCESS';
export const GET_ALBUM_FAILURE = 'GET_ALBUM_FAILURE';

export const GET_ALBUM_TRACKS_REQUEST = 'GET_ALBUM_TRACKS_REQUEST';
export const GET_ALBUM_TRACKS_SUCCESS = 'GET_ALBUM_TRACKS_SUCCESS';
export const GET_ALBUM_TRACKS_FAILURE = 'GET_ALBUM_TRACKS_FAILURE';

export const UPDATE_ALBUM_REQUEST = 'UPDATE_ALBUM_REQUEST';
export const UPDATE_ALBUM_SUCCESS = 'UPDATE_ALBUM_SUCCESS';
export const UPDATE_ALBUM_FAILURE = 'UPDATE_ALBUM_FAILURE';

export const GET_USER_ALBUMS_REQUEST = 'GET_USER_ALBUMS_REQUEST';
export const GET_USER_ALBUMS_SUCCESS = 'GET_USER_ALBUMS_SUCCESS';
export const GET_USER_ALBUMS_FAILURE = 'GET_USER_ALBUMS_FAILURE';

export const SORT_ALBUMS_BY_TITLE = 'SORT_ALBUMS_BY_TITLE';
export const SORT_ALBUMS_BY_ARTIST = 'SORT_ALBUMS_BY_ARTIST';
