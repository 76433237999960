import React from 'react';
import PropTypes from 'prop-types';

function SvgCart({ fill, ...props }) {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.26647 8.41241C3.23912 8.13762 2.99419 7.93704 2.7194 7.96439C2.44462 7.99175 2.24404 8.23668 2.27139 8.51146L3.26647 8.41241ZM22.728 8.51144C22.7554 8.23666 22.5548 7.99173 22.28 7.96437C22.0052 7.93702 21.7603 8.1376 21.7329 8.41239L22.728 8.51144ZM17.1118 23.5004L17.1118 23.0004L17.1118 23.5004ZM21.0922 19.8966L20.5946 19.8471L21.0922 19.8966ZM17.1118 23.0004L7.88755 23.0004L7.88755 24.0004L17.1118 24.0004L17.1118 23.0004ZM21.7329 8.41239L20.5946 19.8471L21.5897 19.9461L22.728 8.51144L21.7329 8.41239ZM2.27139 8.51146L3.40968 19.9461L4.40477 19.8471L3.26647 8.41241L2.27139 8.51146ZM7.88755 23.0004C6.08882 23.0004 4.58295 21.637 4.40477 19.8471L3.40968 19.9461C3.63877 22.2474 5.5749 24.0004 7.88755 24.0004L7.88755 23.0004ZM17.1118 24.0004C19.4245 24.0004 21.3606 22.2474 21.5897 19.9461L20.5946 19.8471C20.4165 21.637 18.9106 23.0004 17.1118 23.0004L17.1118 24.0004Z"
        fill="white"
      />
      <path d="M12.5 14.6543V18.1928" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M16.9238 14.6543V18.1928" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8.07617 14.6543V18.1928" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M9.84531 0.500161L7.19148 7.57709" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M1 8.46191H24" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M15.1547 0.500225L17.8086 7.57715" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

SvgCart.propTypes = {
  fill: PropTypes.string,
};

SvgCart.defaultProps = {
  fill: '#fcb316',
};

export default SvgCart;
