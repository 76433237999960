import React from 'react';
import PropTypes from 'prop-types';

function SvgTrack({ fill, ...props }) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 55.444 63.787" {...props}>
      <path
        d="M43.546 6.483l8.8 4.175-15.861 2.526zm11.894-4.6v-.222A1.656 1.656 0 0053.532.02L22.051 4.808a1.661 1.661 0 00-1.409 1.641v4.488l1.02-.968 8.8 4.175-9.822 1.566v30.505a11.081 11.081 0 00-6.114-1.67 16.114 16.114 0 00-5.9 1.172 15 15 0 00-7 5.441A8.107 8.107 0 00.5 58.731c1.24 3.118 4.666 5.057 8.943 5.057a16.114 16.114 0 005.9-1.172 15.021 15.021 0 007-5.441 8.914 8.914 0 001.621-5.214V17.832l28.159-4.285v27.877a11.117 11.117 0 00-6.114-1.667 16.175 16.175 0 00-5.9 1.169 14.992 14.992 0 00-7 5.443 8.1 8.1 0 00-1.13 7.573c1.238 3.121 4.665 5.059 8.939 5.059a16.2 16.2 0 005.905-1.169 15.018 15.018 0 007-5.443 8.94 8.94 0 001.62-5.216V9.223l-.728.692-11.694-5.543-9.376 8.9L21.72 7.616l.454-.431L42.22 3.991l.8.381.642-.611zM43.768 7.874l-3.956 3.755 8.888-1.414zm-10.623 2.27l-4.077-1.933 7.346-1.17zM24.8 7.793l8.619 4.086 6.915-6.563zm30.64-4.882l-9.572 1.524 8.619 4.089.952-.9v-2l-1.228 1.164-4.076-1.933 5.305-.845zm-33.556 8.45l-1.242 1.179v2.145l6.177-.984zm11.26-1.216l-4.076-1.934 7.346-1.17zM24.8 7.793l8.619 4.086 6.915-6.563zm18.966.081l-3.956 3.755 8.89-1.414zm0 0l-3.956 3.755 8.89-1.414z"
        fill={fill}
        fillRule="evenodd"
      />
      <path
        data-name="Subtraction 1"
        d="M33.483 11.85l-8.627-4.038 15.527-2.487-6.9 6.524zm2.945-4.8l-7.441 1.156 4.156 1.937 3.285-3.1z"
        fill={fill}
      />
    </svg>
  );
}

SvgTrack.propTypes = {
  fill: PropTypes.string,
};

SvgTrack.defaultProps = {
  fill: '#fcb316',
};

export default SvgTrack;
