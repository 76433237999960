import React, { useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';

export const AuthModalContext = React.createContext({});

export const AuthModalProvider = ({ children }) => {
  const [modal, setModal] = useState(false);
  const [linkTo, setLinkTo] = useState('');
  const [isFunc, setIsFunc] = useState(false);
  const [activeTab, setTab] = useState(0);
  const [phoneAuthModal, setPhoneAuthModal] = useState(false);
  const [verificationModal, setVerificationModal] = useState(false);
  const closeAuthModal = useCallback(() => setModal(false), []);
  const closePhoneAuth = useCallback(() => setPhoneAuthModal(false), []);
  const closeVerificationModal = useCallback(() => setVerificationModal(false), []);
  const togglePhoneAuthModal = useCallback(() => setPhoneAuthModal(v => !v), []);
  const toggleVerificationModal = useCallback(() => setVerificationModal(v => !v), []);

  const toggleAuthModal = useCallback(
    ({ link = '', isFunction = false, tab = 0 } = {}) => {
      if (modal) {
        setModal(false);
        setIsFunc(false);
        setLinkTo('');
      } else {
        setTab(tab);
        setIsFunc(isFunction);
        setLinkTo(link);
        setModal(true);
      }
    },
    [modal],
  );

  const providerValue = useMemo(
    () => ({
      activeTab,
      closeAuthModal,
      closePhoneAuth,
      closeVerificationModal,
      isFunc,
      isOpen: modal,
      link: linkTo,
      phoneAuthModal,
      setModal,
      toggleAuthModal,
      togglePhoneAuthModal,
      toggleVerificationModal,
      verificationModal,
    }),
    [
      activeTab,
      closeAuthModal,
      closePhoneAuth,
      closeVerificationModal,
      isFunc,
      linkTo,
      modal,
      phoneAuthModal,
      setModal,
      toggleAuthModal,
      togglePhoneAuthModal,
      toggleVerificationModal,
      verificationModal,
    ],
  );

  return <AuthModalContext.Provider value={providerValue}>{children}</AuthModalContext.Provider>;
};

AuthModalProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
