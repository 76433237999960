import React from 'react';

function SvgUser({ fill, ...props }) {
  return (
    <svg width={40} height={40} viewBox="0 0 24 24" fill="none" {...props}>
      <path
        fill={fill}
        d="M10.7852 1.31045C11.2346 0.229849 12.7654 0.229848 13.2148 1.31046L15.1947 6.07063C15.5282 6.87242 16.2822 7.42025 17.1478 7.48965L22.2868 7.90164C23.4534 7.99517 23.9265 9.45104 23.0377 10.2124L19.1223 13.5664C18.4628 14.1313 18.1748 15.0177 18.3762 15.8624L19.5725 20.8772C19.844 22.0156 18.6056 22.9154 17.6068 22.3053L13.2071 19.618C12.466 19.1653 11.534 19.1653 10.7929 19.618L6.3932 22.3053C5.39443 22.9154 4.15599 22.0156 4.42754 20.8772L5.62375 15.8624C5.82524 15.0177 5.53723 14.1313 4.87773 13.5664L0.962345 10.2124C0.0735174 9.45104 0.546558 7.99517 1.71317 7.90164L6.85218 7.48965C7.71778 7.42025 8.4718 6.87242 8.80528 6.07063L10.7852 1.31045Z"
        stroke="url(#paint0_linear)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <stop stopColor="#FDB913" />
      <stop offset={1} stopColor="#F2994A" />
    </svg>
  );
}

export default SvgUser;
