import PropTypes from 'prop-types';
import React from 'react';

function SvgShuffle({ fill, size, ...props }) {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M20.25 15L23 17.75L20.25 20.5" stroke={fill} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M20.25 4L23 6.75L20.25 9.5" stroke={fill} strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M1 17.75H5.88068C6.63516 17.75 7.37798 17.5637 8.04319 17.2077C8.70841 16.8517 9.27546 16.337 9.69401 15.7093L12 12.25"
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 6.75H5.88068C6.63516 6.75001 7.37798 6.93628 8.04319 7.29228C8.70841 7.64827 9.27546 8.16298 9.69401 8.79073L14.306 15.7093C14.7245 16.337 15.2916 16.8517 15.9568 17.2077C16.622 17.5637 17.3648 17.75 18.1193 17.75H22"
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.9998 6.75H18.1195C17.365 6.75001 16.6222 6.93628 15.957 7.29228C15.2917 7.64827 14.7247 8.16298 14.3062 8.79073L13.8335 9.5"
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

SvgShuffle.propTypes = {
  fill: PropTypes.string,
  size: PropTypes.string,
};

SvgShuffle.defaultProps = {
  fill: '#fff',
  size: '1em',
};

export default SvgShuffle;
