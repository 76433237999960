import React, { Suspense, lazy, memo, useEffect } from 'react';
import { Route, BrowserRouter as Router, Switch, withRouter, useHistory } from 'react-router-dom';
import { AuthRoute } from 'components/molecules/Routes';
import ContextWrapper from 'components/organisms/ContextWrapper';
import ErrorBoundary from 'components/organisms/ErrorBoundary';
import { Loader } from 'components/molecules';
import PageWrapper from 'components/organisms/PageWrapper';
import PropTypes from 'prop-types';
import { QueryClient, QueryClientProvider } from 'react-query';

const queryClient = new QueryClient();

// const StudioX = lazy(() => import('pages/StudioX'));
// const StudioxCompetitionDetails = lazy(() => import('pages/StudioX/CompetitionDetails'));
const About = lazy(() => import('pages/About'));
const Album = lazy(() => import('pages/Album'));
const Artist = lazy(() => import('pages/Artist'));
const DynamicScreen = lazy(() => import('pages/DynamicScreen'));
const EditPlaylist = lazy(() => import('pages/EditPlaylist'));
const EditProfile = lazy(() => import('pages/Profile/Edit'));
const EmailSent = lazy(() => import('pages/EmailSent'));
const Explore = lazy(() => import('pages/Explore'));
const Feedback = lazy(() => import('pages/Feedback'));
const ForgetPass = lazy(() => import('pages/ForgetPass'));
const Home = lazy(() => import('pages/Home'));
// const Leaderboard = lazy(() => import('pages/Leaderboard'));
const Library = lazy(() => import('pages/Profile/index'));
const Livestream = lazy(() => import('pages/Livestream'));
const LivestreamEventDetails = lazy(() => import('pages/Livestreams/EventDetails'));
const Login = lazy(() => import('pages/Login'));
const ManageSub = lazy(() => import('pages/ManageSub'));
const Mood = lazy(() => import('pages/Mood'));
const Moods = lazy(() => import('pages/Moods'));
const MyAccount = lazy(() => import('pages/MyAccount'));
const NewPassword = lazy(() => import('pages/NewPassword'));
const NewPlaylist = lazy(() => import('pages/NewPlaylist'));
const Onboarding = lazy(() => import('pages/Onboarding'));
const PasswordUpdated = lazy(() => import('pages/PasswordUpdated'));
const PersonalPlaylist = lazy(() => import('pages/PersonalPlaylist'));
const PhoneSignup = lazy(() => import('pages/PhoneSignup'));
const PhoneVerify = lazy(() => import('pages/SmsCode'));
const Playlist = lazy(() => import('pages/Playlist'));
const Playlists = lazy(() => import('pages/Playlists'));
const Premium = lazy(() => import('pages/Premium'));
const Privacy = lazy(() => import('pages/Privacy'));
const RecentlyAdded = lazy(() => import('pages/RecentlyAdded'));
const Search = lazy(() => import('pages/Search'));
const Section = lazy(() => import('pages/Section'));
const Signup = lazy(() => import('pages/Signup'));
const Sso = lazy(() => import('pages/Sso'));
const PiggyvestSSO = lazy(() => import('pages/piggyvestSSO'));
// const StreamLive = lazy(() => import('pages/Livestreams'));
const StudioxDynamicPage = lazy(() => import('pages/StudioX/StudioxDynamicPage'));
const StudioXHome = lazy(() => import('pages/StudioX/Home'));
const Terms = lazy(() => import('pages/Terms'));
const Track = lazy(() => import('pages/Track'));
const Trending = lazy(() => import('pages/Trending'));
const UploadRouter = lazy(() => import('pages/Upload'));
const User = lazy(() => import('pages/User'));
const UserProfile = lazy(() => import('pages/Profile/UserProfile'));
const Vibez = lazy(() => import('pages/Vibez'));
const Video = lazy(() => import('pages/Video'));
const VideoPlaylist = lazy(() => import('pages/VideoPlaylist'));
const Videos = lazy(() => import('pages/Videos'));
const Scroll = props => {
  const hist = useHistory();
  const doNotScroll = hist.location.search.split('&')[1] && hist.location.search.split('&')[1].split('=')[1];

  useEffect(() => {
    if (doNotScroll === '1') return;
    window.scrollTo(0, 0);
    return () => {};
    // eslint-disable-next-line
  }, [props.location]);
  return props.children;
};

// useEffect(() => {

// }, []);

Scroll.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  location: PropTypes.objectOf(PropTypes.any),
};

const ScrollToTop = withRouter(Scroll);

const MainRouter = () => (
  <PageWrapper>
    <ErrorBoundary>
      <ScrollToTop>
        <Suspense fallback={<Loader />}>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/artists/:id" component={Artist} />
            <Route path="/albums/:id" component={Album} />
            <Route exact path="/playlists" component={Playlists} />
            <Route path="/premium" component={Premium} />
            <Route path="/playlists/:id" component={Playlist} />

            <Route exact path="/moods" component={Moods} />
            <Route path="/moods/:id" component={Mood} />
            <Route path="/tracks/:id" component={Track} />
            <Route path="/search" component={Search} />
            <Route path="/explore" component={Explore} />
            <Route path="/studiox" component={StudioXHome} />
            {/* <Route path="/studiox-competition/:id" component={StudioxCompetitionDetails} /> */}
            <Route path="/studiox-competition/:screen/:id" component={StudioxDynamicPage} />
            <Route path="/recently-added" component={RecentlyAdded} />
            <Route path="/vibez-weekly" component={Vibez} />
            <Route path="/trending" component={Trending} />
            <Route path="/section_detail/:id" component={Section} />
            <Route exact path="/videos" component={Videos} />
            <Route path="/videos/playlist/:id" component={VideoPlaylist} />
            <Route path="/videos/:id" component={Video} />
            <Route exact path="/livestreams" component={Livestream} />
            {/* <Route path="/leaderboard" component={Leaderboard} /> */}
            {/* <Route path="/livestreams/:id" component={StreamLive} /> */}
            <Route path="/livestreams-details/:id" component={LivestreamEventDetails} />
            <Route path="/users/:id" component={User} />
            <AuthRoute path="/library/:id" component={Library} />
            <AuthRoute path="/profile/:id" component={UserProfile} />
            <AuthRoute path="/edit-profile" component={EditProfile} />
            <AuthRoute path="/manage-subscription" component={ManageSub} />
            <AuthRoute path="/my-account" component={MyAccount} />
            <AuthRoute path="/feedback" component={Feedback} />
            <AuthRoute path="/edit-playlist/:id" component={EditPlaylist} />
            <AuthRoute path="/new-playlist" component={NewPlaylist} />
            <AuthRoute path="/playlist/:id" component={PersonalPlaylist} />
            <Route path="/users/:id" component={User} />
            <Route path="/upload" component={UploadRouter} />
            <Route path="/about-us" component={About} />
            <Route path="/sso" component={Sso} />
            <Route path="/piggyvest" component={PiggyvestSSO} />
            <Route path="/terms-conditions" component={Terms} />
            <Route path="/privacy-policy" component={Privacy} />
            <Route path="/:screen" component={DynamicScreen} />
          </Switch>
        </Suspense>
      </ScrollToTop>
    </ErrorBoundary>
  </PageWrapper>
);

const AuthRouter = () => (
  <ErrorBoundary>
    <ScrollToTop>
      <Suspense fallback={<Loader />}>
        <Switch>
          <Route path="/auth/login" component={Login} />
          <Route path="/auth/signup" component={Signup} />
          <Route path="/auth/passwordreset" component={ForgetPass} />
          <Route path="/auth/passwordresetemail" component={EmailSent} />
          <Route path="/auth/password-updated" component={PasswordUpdated} />
          <Route path="/auth/phone-number" component={PhoneSignup} />
          <Route path="/auth/otp-verify" component={PhoneVerify} />
          <Route path="/auth/set-new-password" component={NewPassword} />
          <AuthRoute path="/auth/onboarding" component={Onboarding} />
        </Switch>
      </Suspense>
    </ScrollToTop>
  </ErrorBoundary>
);

const RouterComponent = memo(() => {
  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <ContextWrapper>
          <div>
            <Switch>
              <Route path="/auth" component={AuthRouter} />
              <Route path="/" component={MainRouter} />
            </Switch>
          </div>
        </ContextWrapper>
      </Router>
    </QueryClientProvider>
  );
});

export default RouterComponent;
