const black = '#000';
const dark = '#1D1D1D';
const light = '#F2F2F2';
const white = '#ffffff';

const colors = {
  'almost-black-three': '#0a0a0b',
  'almost-black-two': '#0d0e11',
  'almost-black': '#070709',
  'azure-two': '#2196f3',
  'black-five': '#3b3b3b',
  'black-nine': '#868687E6',
  'black-seven': '#383838',
  'black-six': '#141313',
  'black-three': '#1d1d1d',
  'black-two': '#121212',
  'brown-grey-six': '#d8d8d8',
  'brown-grey-three': '#afafaf',
  'brown-grey-two': '#909090',
  'brown-grey': '#adadad',
  'brownish-grey-five': '#6c6c6c',
  'brownish-grey-four': '#5a5a5a',
  'brownish-grey-two': '#676767',
  'brownish-grey': ' #717171',
  'dark-green': '#349D34',
  'dark-grey': '#141517',
  'dark-two': '#1b1a1f',
  'greyish-brown-three': '#707070',
  'greyish-brown-two': '#555555',
  'greyish-brown': '#404040',
  'light-grey': '#EEEEEE',
  'very-light-pink-two': '#bfbfbf',
  'vibrant-green': '#00e000',
  'white-three': '#fefefe',
  'white-two': '#f8f8f8',
  'brownish-black': '#131516',
  'alert-green': '#EAF7F1',
  'alert-red': '#FDE8E1',
  'alert-yellow': '#FEF7E6',
  'alert-info': '#DCEEFF',
  azure: '#03a9f4',
  background: black,
  twitter: '#1DA1F3',
  facebook: '#3B5998',
  whatsapp: '#25D366',
  clipboardBlack: '#08080A',
  black,
  dark: '#121316',
  ebony: '#08080a',
  error: 'rgb(200, 0, 0)',
  grape: '#431c31',
  primary: '#FFA700',
  secondary: '#ed1651',
  transparent: 'transparent',
  whiteOne: '#fff',
  buttonYellowGradient: 'linear-gradient(270.02deg, #FDB913 0.02%, #F2994A 99.98%)',
  white,
};

export const darkColors = {
  ...colors,
  footer: colors['black'],
  sidebar: '#272729',
  icon: '#818181',
  input: colors['black-three'],
  inputText: white,
  metadataBg: colors['black-two'],
  modal: 'rgb(33, 33, 33)',
  progressBar: colors['black-nine'],
  searchbar: colors['dark-two'],
  switchingBg: black,
  sidebarBg: colors['brownish-black'],
  sidebarText: colors['whiteOne'],
  switchingText: white,
  tileBg: colors.dark,
  editProfile: colors.ebony,
};

export const lightColors = {
  ...colors,
  footer: '#f9f9f9',
  sidebar: '#f9f9f9',
  icon: '#000000',
  input: colors['light-grey'],
  inputText: black,
  metadataBg: colors.white,
  modal: colors.white,
  progressBar: '#C0C0C0',
  searchbar: colors.white,
  switchingBg: light,
  sidebarBg: light,
  switchingText: dark,
  tileBg: colors.white,
};
