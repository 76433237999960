import React from 'react';
import PropTypes from 'prop-types';

function SvgCancel({ fill, size, ...props }) {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM7.35355 6.64645C7.15829 6.45118 6.84171 6.45118 6.64645 6.64645C6.45118 6.84171 6.45118 7.15829 6.64645 7.35355L11.3551 12.0622L6.64645 16.7708C6.45118 16.9661 6.45118 17.2826 6.64645 17.4779C6.84171 17.6732 7.15829 17.6732 7.35355 17.4779L12.0622 12.7693L16.7708 17.4779C16.9661 17.6732 17.2826 17.6732 17.4779 17.4779C17.6732 17.2826 17.6732 16.9661 17.4779 16.7708L12.7693 12.0622L17.4779 7.35357C17.6732 7.1583 17.6732 6.84172 17.4779 6.64646C17.2826 6.4512 16.9661 6.4512 16.7708 6.64646L12.0622 11.3551L7.35355 6.64645Z"
        fill={fill}
      />
    </svg>
  );
}

SvgCancel.propTypes = {
  fill: PropTypes.string,
  size: PropTypes.string,
};

SvgCancel.defaultProps = {
  fill: '#868687',
  size: '24',
};

export default SvgCancel;
