import React from 'react';
import PropTypes from 'prop-types';

function SvgThumbDown({ fill, ...props }) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 10.07 9.154" {...props}>
      <g>
        <path
          id="Path_1344"
          data-name="Path 1344"
          d="M10.07,25.5H8.239v5.493H10.07ZM0,30.535a.918.918,0,0,0,.915.915H3.8l-.458,2.105v.137a.96.96,0,0,0,.183.5l.5.458,3.021-3.021a.768.768,0,0,0,.275-.641V26.415a.918.918,0,0,0-.915-.915H2.289a.9.9,0,0,0-.824.549L.092,29.3a.779.779,0,0,0-.046.32v.915H0Z"
          transform="translate(0 -25.5)"
          fill={fill}
        />
      </g>
    </svg>
  );
}

SvgThumbDown.propTypes = {
  fill: PropTypes.string,
};

SvgThumbDown.defaultProps = {
  fill: 'gray',
};

export default SvgThumbDown;
