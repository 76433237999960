import React from 'react';
import PropTypes from 'prop-types';

function Close({ fill, ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 42 52.5" {...props}>
      <path
        fill={fill}
        fillRule="evenodd"
        d="M35.6 6.6c-7.9-7.9-20.8-7.9-28.7 0-7.9 7.9-7.9 20.8 0 28.7 4 4 9.1 5.9 14.3 5.9 5.2 0 10.4-2 14.3-5.9 8.1-8 8.1-20.8.1-28.7zm-2.1 26.5c-6.7 6.7-17.7 6.7-24.4 0-6.7-6.7-6.7-17.7 0-24.4 3.4-3.4 7.8-5.1 12.2-5.1 4.4 0 8.9 1.7 12.2 5.1 6.8 6.7 6.8 17.7 0 24.4zm-5.7-18.7c-.6-.6-1.5-.6-2.1 0l-4.4 4.4-4.4-4.4c-.6-.6-1.5-.6-2.1 0-.6.6-.6 1.5 0 2.1l4.4 4.4-4.4 4.4c-.6.6-.6 1.5 0 2.1.3.3.7.4 1.1.4.4 0 .8-.1 1.1-.4l4.4-4.4 4.4 4.4c.3.3.7.4 1.1.4.4 0 .8-.1 1.1-.4.6-.6.6-1.5 0-2.1l-4.4-4.4 4.4-4.4c.4-.6.4-1.5-.2-2.1z"
      />
    </svg>
  );
}

Close.propTypes = {
  fill: PropTypes.string,
};

Close.defaultProps = {
  fill: '#fff',
};

export default Close;
