import React from 'react';
import PropTypes from 'prop-types';

function SvgPin({ fill, ...props }) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0)">
        <path
          d="M8.34321 13.8624L15.6376 13.8624C17.1429 13.8624 17.8111 11.9695 16.6393 11.0245C16.2637 10.7216 16.0453 10.265 16.0453 9.78239L16.0453 4.06881C16.0454 2.96423 15.1499 2.06879 14.0453 2.06879L9.90775 2.06879C8.80319 2.06879 7.90776 2.96422 7.90775 4.06878L7.90772 9.7903C7.90771 10.2685 7.69396 10.7217 7.32493 11.0258C6.166 11.981 6.8414 13.8624 8.34321 13.8624Z"
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M11.9902 22.3389L11.9902 14.2308" stroke={fill} strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="24" height="24" fill={fill} />
        </clipPath>
      </defs>
    </svg>
  );
}

SvgPin.propTypes = {
  fill: PropTypes.string,
};

SvgPin.defaultProps = {
  fill: '#fff',
};

export default SvgPin;
