import * as t from '../types';

const INITIAL_STATE = {
  data: {},
  fetching: {},
  sections: {},
  updating: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case t.GET_USER_BY_ID_REQUEST:
      return { ...state, fetching: { ...state.fetching, [action.payload]: true } };

    case t.GET_USER_BY_ID_SUCCESS:
      return {
        ...state,
        fetching: { ...state.fetching, [action.payload.id]: false },
        data: { ...state.data, [action.payload.id]: action.payload.data },
        sections: { ...state.sections, [action.payload.id]: action.payload.sections },
      };

    case t.GET_USER_BY_ID_FAILURE:
      return { ...state, fetching: { ...state.fetching, [action.payload]: false } };

    case t.UPDATE_USER_BY_ID_REQUEST:
      return { ...state, updating: { ...state.updating, [action.payload]: true } };

    case t.UPDATE_USER_BY_ID_SUCCESS:
      return {
        ...state,
        updating: { ...state.updating, [action.payload.id]: false },
        data: { ...state.data, [action.payload.id]: action.payload.data },
      };

    case t.UPDATE_USER_BY_ID_FAILURE:
      return { ...state, updating: { ...state.updating, [action.payload]: false } };

    case t.LOGOUT:
      return INITIAL_STATE;
    default:
      return { ...state };
  }
};
