import api from 'services/api';
import { firebase } from 'services/firebase';
import * as t from '../types';
import { getProfileAction } from './profile.actions';

let retryCount = 0;

export const loggedInAction = () => dispatch => {
  dispatch({ type: t.LOGGED_IN });
  localStorage.clear();
};

export const completeLoginAction = (callback = () => {}) => async dispatch => {
  dispatch(
    getProfileAction((data, error) => {
      if (data) {
        callback(data, null);
        firebase.analytics().logEvent('login', { method: 'email' });
        firebase.analytics().setUserId(data.uid);
      } else {
        callback(null, error);
      }
    }),
  );
};

export const completeEmailRegistrationAction = (
  username,
  firstName,
  lastName,
  user,
  callback = () => {},
) => async dispatch => {
  try {
    dispatch({ type: t.COMPLETE_REGISTRATION_REQUEST });
    let resp = await api.updateProfile({
      username,
      firstName,
      lastName,
    });

    if (resp.status === 200 || resp.status === 201) {
      resp = await api.getProfile();
      if (resp.status === 200 || resp.status === 201) {
        const mergedUser = {
          ...user,
          ...resp.data,
        };
        callback(mergedUser, null);
        dispatch({
          type: t.COMPLETE_REGISTRATION_SUCCESS,
          payload: mergedUser,
        });
        firebase.analytics().logEvent('sign_up', { method: 'email' });
        firebase.analytics().setUserId(mergedUser.uid);
      } else {
        throw resp;
      }
    } else {
      throw resp;
    }
  } catch (e) {
    const { data, status } = e;

    if (status === 404 && retryCount < 5) {
      setTimeout(() => {
        dispatch(completeEmailRegistrationAction(username, firstName, lastName, user, callback));
        retryCount += 1;
      }, 2000);
    } else {
      retryCount = 0;
      callback(null, data?.error);
      dispatch({ type: t.COMPLETE_REGISTRATION_FAILURE });
    }
  }
};

export const completeSocialRegistrationAction = (user, event, callback = () => {}) => async dispatch => {
  try {
    let firstName = '';
    let lastName = '';
    const { displayName } = user;
    if (displayName) {
      const unsplitName = displayName.split(' ');
      if (unsplitName.length > 0) {
        [firstName] = unsplitName;
        lastName = unsplitName[unsplitName.length - 1];
      }
    }

    dispatch({ type: t.COMPLETE_REGISTRATION_REQUEST });
    dispatch(
      getProfileAction(async data => {
        if (data && data.firstName && data.lastName) {
          const mergedUser = {
            ...user,
            ...data,
          };
          callback(mergedUser, null);
          dispatch({
            type: t.COMPLETE_REGISTRATION_SUCCESS,
            payload: mergedUser,
          });
          firebase.analytics().logEvent('login', { method: 'social' });
          firebase.analytics().setUserId(mergedUser.uid);
        } else {
          const resp = await api.updateProfile({
            firstName,
            lastName,
          });
          if ((resp.status === 200 || resp.status === 201) && !resp.data.error) {
            dispatch(
              getProfileAction((newData, error) => {
                if (data) {
                  const mergedUser = {
                    ...user,
                    ...newData,
                  };
                  callback(mergedUser, null);
                  dispatch({
                    type: t.COMPLETE_REGISTRATION_SUCCESS,
                    payload: mergedUser,
                  });
                  firebase.analytics().logEvent('sign_up', { method: 'social' });
                  firebase.analytics().setUserId(mergedUser.uid);
                } else {
                  callback(null, error);
                  dispatch({ type: t.COMPLETE_REGISTRATION_FAILURE });
                }
              }),
            );
          }
        }
      }),
    );
  } catch (e) {
    callback(null, e?.message);
    dispatch({ type: t.COMPLETE_REGISTRATION_FAILURE });
  }
};

/**
 * Sign in using a custom token
 *
 * @param {string} token jwt token with custom claims
 * @param {function} cb a callback triggered after sign in completion
 */
export const loginWithCustomToken = (token, cb) => async dispatch => {
  try {
    await firebase.auth().signInWithCustomToken(token);
    await dispatch(completeLoginAction());
  } catch (error) {
    firebase.analytics().logEvent('loginWithCustomToken', { error: error?.message });
  }
  cb();
};

export const logoutAction = cb => async dispatch => {
  await firebase.auth().signOut();
  window.localStorage.clear();
  dispatch({ type: t.LOGOUT });
  cb();
};

export const resetPasswordAction = (email, cb) => async dispatch => {
  firebase.analytics().logEvent('reset_password', { method: 'email' });
  const resp = await api.resetPassword(email);
  if (resp.status === 200) {
    cb && cb();
  }
};
