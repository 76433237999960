import React, { useContext, useState } from 'react';
import { AddMoneyIcon } from 'components/icons';
import { LivestreamContext } from 'contexts/LivestreamContext';
import { WalletContext } from 'contexts/WalletContext';
import InitMakeItRain from './InitMakeItRain';
import NotEnoughFunds from './NotEnoughFunds';
// import PaymentConfirmation from './PaymentConfirmation';
// import PaymentDetails from './PaymentDetails';
// import CardPayment from './PaymentDetails/CardPayment';
import PaymentSuccessful from './PaymentSuccessful';
import {
  MakeItRainWrapper,
  MakeItRainTitle,
  Balance,
  MakeItRainTitleBox,
  MakeItRainContent,
  MakeItRainActionButton,
  Goback,
  ActionButtonWrapper,
} from './styled';
import { useToast } from 'hooks';
import ExternalPaymentHandler from 'components/organisms/ExternalPaymentHandler';
import { Spinner } from 'components/atoms';

const MakeItRain = () => {
  const {
    LivestreamModel: { event, comment },
    // createComment,
    // hideMagicComment,
    setsidePanelWidth,
  } = useContext(LivestreamContext);
  const {
    WalletModel: { maxValue, amount, amountFunded, currency, priceAmount },
    updateBalance,
    spendTheMoney,
    getWalletBalance,
  } = useContext(WalletContext);
  const balance = getWalletBalance();
  const [amountToSpray, setAmountToSpray] = useState(amount);
  const { addToast } = useToast();
  const [state, setState] = useState({
    buttonText: 'MAKE IT RAIN',
    title: 'Make it rain',
    activeTab: 'init',
  });

  const goBack = () => {
    switch (state.activeTab) {
      case 'payment_successful':
      case 'confirm_payment':
      case 'mobile_number':
      case 'bank_selected':
        return updateState('payment_method', null, 'Payment Method', true);
      case 'card_payment':
        return setPaymentMethod('bank_selected');
      case 'payment_method':
        return updateState('insufficient_fund', 'PURCHASE', 'Make it rain', false);
      case 'insufficient_fund':
        return updateState('init', null, 'Make it rain', false);
      default:
        break;
    }
  };

  const setBalance = newValue => {
    updateBalance(newValue);
  };

  const setPaymentMethod = method => {
    updateState(method, `Pay $${priceAmount}`, 'Payment Details', false);
  };

  const getActiveTab = tab => {
    switch (tab) {
      case 'init':
        return <InitMakeItRain amount={amountToSpray} callback={setAmountToSpray} maxValue={maxValue} />;
      case 'insufficient_fund':
        return <NotEnoughFunds />;
      case 'payment_method':
        return <ExternalPaymentHandler fetchLink />;
      // case 'mobile_number':
      //   return <PaymentDetails type={tab} />;
      // case 'card_payment':
      //   return <CardPayment type={tab} />;
      // case 'bank_selected':
      //   return <CardPayment type={tab} />;
      // case 'confirm_payment':
      //   return <PaymentConfirmation />;
      case 'payment_successful':
        return <PaymentSuccessful />;
      default:
        return;
    }
  };

  const updateState = (activeTab, buttonText, title, hideButton) => {
    setState({
      buttonText,
      title,
      activeTab: activeTab,
      hideActionButton: hideButton,
    });
  };
  const handleMakeItRain = () => {
    if (!comment) {
      const makeItrainCommentInput = document.getElementById('mir-comment-input');
      if (makeItrainCommentInput) {
        makeItrainCommentInput.focus();
      }
      return;
    }
    if (balance === 0 || balance < amountToSpray) {
      updateState('insufficient_fund', 'PURCHASE U100', 'Make it rain', false);
      spendTheMoney(false, null, 0);
      return;
    } else {
      setState({ ...state, buttonText: 'loading' });
      spendTheMoney(true, event, amountToSpray, comment, err => {
        setState({ ...state, buttonText: 'MAKE IT RAIN' });
        if (err) {
          return addToast(err || 'Something went wrong!', { type: 'error' });
        }
        setsidePanelWidth(null);
      });
    }
  };
  const makeItRainInit = () => {
    switch (state.activeTab) {
      case 'init':
        handleMakeItRain();
        break;
      case 'insufficient_fund':
        updateState('payment_method', null, 'Payment Method', true);
        break;
      case 'mobile_number':
      case 'bank_selected':
        updateState('confirm_payment', 'BUY TICKET', 'Payment Confirmation', false);
        break;
      case 'card_payment':
        return setPaymentMethod('bank_selected');
      case 'confirm_payment':
        updateState('payment_successful', 'OK!', '', false);
        setBalance(amountFunded);
        break;
      case 'payment_successful':
        updateState('init', null, 'Make it rain', false);
        break;
      default:
        break;
    }
  };

  return (
    <MakeItRainWrapper>
      <MakeItRainTitleBox>
        <Goback onClick={goBack} ishidden={state.activeTab} />
        <MakeItRainTitle>{state.title}</MakeItRainTitle>
        <Balance>
          <AddMoneyIcon />
          {currency + balance}
        </Balance>
      </MakeItRainTitleBox>

      <MakeItRainContent>{getActiveTab(state.activeTab)}</MakeItRainContent>
      {!state.hideActionButton && (
        <ActionButtonWrapper>
          <MakeItRainActionButton onClick={makeItRainInit} textColor="white">
            {state.buttonText === 'loading' && (
              <p style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Spinner color="secondary" />
              </p>
            )}
            {state.buttonText !== 'loading' && (state.buttonText || 'MAKE IT RAIN!')}
          </MakeItRainActionButton>
        </ActionButtonWrapper>
      )}
    </MakeItRainWrapper>
  );
};

export default MakeItRain;
