import PropTypes from 'prop-types';
import React from 'react';

function SvgYellowFacebook({ fill, ...props }) {
  return (
    <svg width="10" height="20" viewBox="0 0 10 20" fill={fill} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M7.81405 3.29509H9.55927V0.139742C9.25817 0.0967442 8.22267 0 7.01671 0C4.50045 0 2.77674 1.643 2.77674 4.66274V7.44186H0V10.9693H2.77674V19.845H6.18114V10.9701H8.84557L9.26853 7.44269H6.18035V5.01251C6.18114 3.99297 6.44559 3.29509 7.81405 3.29509Z"
        fill={fill}
      />
    </svg>
  );
}

SvgYellowFacebook.propTypes = {
  fill: PropTypes.string,
};

SvgYellowFacebook.defaultProps = {
  fill: 'FDB913',
};

export default SvgYellowFacebook;
