import React from 'react';
import PropTypes from 'prop-types';

function SvgClipboard({ fill, ...props }) {
  return (
    <svg width="20" height="20" {...props} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.1013 19.1672L14.1013 18.7505L14.1013 19.1672ZM17.0179 5.76973L17.0179 15.8338H17.8513L17.8513 5.76973H17.0179ZM6.66538 19.5838H14.1013L14.1013 18.7505L6.66538 18.7505L6.66538 19.5838ZM2.91537 5.76973L2.91538 15.8338L3.74871 15.8338L3.7487 5.76973L2.91537 5.76973ZM3.7487 5.76973C3.7487 4.44212 4.82494 3.36589 6.15254 3.36589L6.15254 2.53255C4.3647 2.53255 2.91537 3.98189 2.91537 5.76973L3.7487 5.76973ZM6.66538 18.7505C5.05455 18.7505 3.74871 17.4447 3.74871 15.8338L2.91538 15.8338C2.91538 17.9049 4.59431 19.5838 6.66538 19.5838L6.66538 18.7505ZM17.0179 15.8338C17.0179 17.4447 15.7121 18.7505 14.1013 18.7505L14.1013 19.5838C16.1723 19.5838 17.8513 17.9049 17.8513 15.8338H17.0179ZM17.8513 5.76973C17.8513 3.98189 16.4019 2.53255 14.6141 2.53255L14.6141 3.36589C15.9417 3.36589 17.0179 4.44212 17.0179 5.76973H17.8513Z"
        fill={fill}
      />
      <path
        d="M12.6267 2.24424C12.1665 2.24424 11.8111 1.8405 11.5544 1.45853C11.3012 1.08184 10.8711 0.833984 10.3831 0.833984C9.89512 0.833984 9.46502 1.08184 9.21183 1.45853C8.95509 1.8405 8.59976 2.24424 8.13952 2.24424H6.98568C6.52544 2.24424 6.15234 2.61734 6.15234 3.07757V4.23142C6.15234 4.69166 6.52544 5.06475 6.98568 5.06475H13.7805C14.2408 5.06475 14.6139 4.69166 14.6139 4.23142V3.07757C14.6139 2.61734 14.2408 2.24424 13.7805 2.24424H12.6267Z"
        stroke={fill}
        strokeWidth="0.833333"
      />
    </svg>
  );
}

SvgClipboard.propTypes = {
  fill: PropTypes.string,
};

SvgClipboard.defaultProps = {
  fill: '#white',
};

export default SvgClipboard;
