import React from 'react';
import styled from '@emotion/styled/macro';
// import PropTypes from 'prop-types';
import { Box } from 'rebass';
import { Typography, Button, Image } from 'components/atoms';
import { YellowFacebook, YellowTwitter, WhatsappIcon, DocumentIcon } from 'components/icons';
import { twitterShare, whatsappShare, facebookShare, openInNewTab } from 'lib/share';
import { copyText } from 'lib/utils';
import { useToast } from 'hooks';
import { ButtonsWrapper } from './ActionButtons';

const Wrapper = styled(Box)`
  border-radius: 0.5rem;
  flex-direction: column;
  margin-bottom: 2rem;
  padding: 6rem 4rem;
`;

const SocialButtons = styled(ButtonsWrapper)`
  flex-wrap: wrap;
  width: 100%;
  ${Button} {
    padding: 1rem 2rem;
    margin-bottom: 0.5rem;
    svg {
      height: 1.5rem;
      margin-right: 0.5rem;
      width: 1.5rem;
    }
  }
`;

const Preview = styled(Box)`
  align-items: center;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.colors['brownish-grey-four']};
  flex-wrap: wrap;
  margin-bottom: 3rem;
  padding: 3rem;
  width: 100%;

  ${({ theme }) => theme.mq.md`
    flex-wrap: nowrap;
  `}
`;

const Poster = styled(Image)`
  flex-shrink: 0;
  height: 10rem;
  margin-bottom: 2rem;
  margin-right: 5rem;
  width: 10rem;
`;

const Link = styled.a`
  color: ${({ theme }) => theme.colors['brown-grey-three']};
  word-break: break-word;
`;

export const Share = ({ artist_name, artwork, content, name, sharelink }) => {
  const { addToast } = useToast();
  const getContent = () => {
    return `Listen to the brand new ${content} release by ${artist_name} titled ${name} on uduX`;
  };

  return (
    <>
      <Wrapper>
        <Preview>
          <Poster src={artwork} placeholder="track" alt={`${name} poster`} />

          <Box flexDirection="column">
            <Typography.Paragraph color="switchingText" style={{ marginBottom: '1rem' }}>
              {artist_name && `${artist_name} - `}
              {name}
            </Typography.Paragraph>
            <Typography.Paragraph size="small" color="brown-grey-three" style={{ marginBottom: '1rem' }}>
              {getContent()};
            </Typography.Paragraph>
            <Typography.Paragraph
              as={Link}
              color="switchingText"
              href={sharelink}
              rel="noopener noreferrer"
              size="small"
              target="_blank"
            >
              {sharelink}
            </Typography.Paragraph>
          </Box>
        </Preview>

        <SocialButtons>
          <Button
            effect
            color="twitter"
            textColor="white"
            onClick={() => openInNewTab(twitterShare(sharelink, getContent()))}
          >
            <YellowTwitter fill="white" />
            Tweet it!
          </Button>

          <Button
            effect
            color="facebook"
            textColor="white"
            onClick={() => openInNewTab(facebookShare(sharelink, getContent()))}
          >
            <YellowFacebook fill="white" />
            Post It!
          </Button>

          <Button
            effect
            color="whatsapp"
            textColor="white"
            onClick={() => openInNewTab(whatsappShare(sharelink, getContent()))}
          >
            <WhatsappIcon />
            Share it!
          </Button>

          <Button
            effect
            color="clipboardBlack"
            style={{
              color: 'white',
            }}
            onClick={() => {
              copyText(sharelink);
              addToast('Link copied to your clipboard!', { type: 'success' });
            }}
          >
            <DocumentIcon fill="white" />
            Copy to clipboard
          </Button>
        </SocialButtons>
      </Wrapper>
    </>
  );
};

// Share.propTypes = {
//   artist_name: PropTypes.string.isRequired,
//   artwork: PropTypes.string.isRequired,
//   content: PropTypes.string,
//   name: PropTypes.string.isRequired,
//   sharelink: PropTypes.string.isRequired,
// };

Share.defaultProps = {
  content: 'track',
};
