import React, { useState } from 'react';
import styled from '@emotion/styled/macro';
import PropTypes from 'prop-types';
import { Box } from 'rebass';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Typography } from 'components/atoms';
import { OrderIcon, DragIcon, EditIcon } from 'components/icons';
import { reorder } from 'lib/utils';

const Wrapper = styled(Box)`
  background-color: ${({ theme }) => theme.colors['black-two']};
  border-radius: 0.5rem;
  flex-direction: column;
  margin-bottom: 2rem;
  padding: 3rem;
`;

const Title = styled(Typography.Paragraph)`
  margin-bottom: 2rem;
`;

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  user-select: none;
  width: 100%;
`;

const Table = styled.table`
  width: 100%;
`;

const TableRow = styled.div`
  border-bottom: 1px solid #313131;
  display: flex;
  flex-direction: row;
  outline: none !important;
  width: 100%;
  margin-bottom: 8px;
`;

const Th = styled.div`
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.font.size.tiny};
  font-weight: normal;
  padding: 1rem;
  text-align: left;

  &:nth-of-type(3) {
    flex: 1;
  }
  &:not(:nth-of-type(3)) {
    width: 3rem;
  }
  &:last-child {
    text-align: right;
    width: 7rem;
  }
`;

const Td = styled.div`
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.font.size.tiny};
  font-weight: normal;
  padding: 1rem;
  text-align: left;
  &:nth-of-type(3) {
    flex: 1;
  }
  &:not(:nth-of-type(3)) {
    width: 3rem;
  }
  &:last-child {
    width: 7rem;
    text-align: right;
  }
`;

const Row = ({ index, track, onClick }) => {
  return (
    <Draggable id={track.id} draggableId={track.id} index={index} shouldRespectForcePress>
      {provided => (
        <TableRow ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
          <Td>
            <DragIcon width="1rem" heigh="1rem" />
          </Td>
          <Td>{index + 1}</Td>
          <Td>{track.name}</Td>
          <Td>
            <EditIcon width="1rem" heigh="1rem" onClick={onClick} />
          </Td>
        </TableRow>
      )}
    </Draggable>
  );
};

const TableComponent = React.memo(function TableComponent({ tracks, onClickTrack }) {
  return (
    <TableWrapper>
      <Table>
        <TableRow>
          <Th>
            <OrderIcon />
          </Th>
          <Th>#</Th>
          <Th>SONG NAME</Th>
          <Th>ACTIONS</Th>
        </TableRow>
        {tracks.map((track, index) => (
          <Row key={track.id} track={track} index={index} onClick={() => onClickTrack(track)} />
        ))}
      </Table>
    </TableWrapper>
  );
});

export const Order = ({ onChangeOrder, onClickTrack, tracks }) => {
  const [state, setState] = useState({ tracks: tracks || [] });

  const onDragEnd = result => {
    if (!result.destination) {
      return;
    }
    if (result.destination.index === result.source.index) {
      return;
    }
    const newTracks = reorder(state.tracks, result.source.index, result.destination.index);
    setState({ tracks: newTracks });
    onChangeOrder(newTracks);
  };
  return (
    <Wrapper>
      <Title>Album Track List</Title>

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="upload-order">
          {provided => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              <TableComponent tracks={state.tracks} onClickTrack={onClickTrack} />
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </Wrapper>
  );
};

Order.propTypes = {
  onChangeOrder: PropTypes.func.isRequired,
  onClickTrack: PropTypes.func.isRequired,
  tracks: PropTypes.array.isRequired,
};

Row.propTypes = {
  index: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  track: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
};

TableComponent.propTypes = {
  onClickTrack: PropTypes.func.isRequired,
  tracks: PropTypes.array.isRequired,
};
