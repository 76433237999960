import * as React from 'react';

function SvgCreditCard(props) {
  return (
    <svg viewBox="0 0 512 512" width="1em" height="1em" {...props}>
      <path d="M458.667 85.333H53.333C23.936 85.333 0 109.269 0 138.667v234.667c0 29.397 23.936 53.333 53.333 53.333h405.333c29.397 0 53.333-23.936 53.333-53.333V138.667c.001-29.398-23.935-53.334-53.332-53.334zm32 288c0 17.643-14.357 32-32 32H53.333c-17.643 0-32-14.357-32-32V138.667c0-17.643 14.357-32 32-32h405.333c17.643 0 32 14.357 32 32v234.666z" />
      <path d="M501.333 149.333H10.667C4.779 149.333 0 154.112 0 160v64c0 5.888 4.779 10.667 10.667 10.667h490.667c5.888 0 10.667-4.779 10.667-10.667v-64c-.001-5.888-4.78-10.667-10.668-10.667zm-10.666 64H21.333v-42.667h469.333v42.667zM202.667 298.667h-128c-5.888 0-10.667 4.779-10.667 10.667S68.779 320 74.667 320h128c5.888 0 10.667-4.779 10.667-10.667s-4.779-10.666-10.667-10.666zM202.667 341.333h-128C68.779 341.333 64 346.112 64 352s4.779 10.667 10.667 10.667h128c5.888 0 10.667-4.779 10.667-10.667-.001-5.888-4.779-10.667-10.667-10.667zM416 277.333h-21.333c-17.643 0-32 14.357-32 32v21.333c0 17.643 14.357 32 32 32H416c17.643 0 32-14.357 32-32v-21.333c0-17.642-14.357-32-32-32zm10.667 53.334c0 5.888-4.779 10.667-10.667 10.667h-21.333c-5.888 0-10.667-4.779-10.667-10.667v-21.333c0-5.888 4.779-10.667 10.667-10.667H416c5.888 0 10.667 4.779 10.667 10.667v21.333z" />
    </svg>
  );
}

export default SvgCreditCard;
